import styled from 'styled-components';

export const KitsRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden;
    ${({ edit }) => edit && `
        border-bottom: solid 1px #f2f2f2;
    `}
`


export const StatusBar = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  }
`


export const StatusCounter = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black, theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
    padding: 15px;
    border: 1px solid #30735f;
    border-radius: 4px;
    
`

export const BackIcon = styled.img`
    width: 12px;
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
`
export const Br = styled.div`
    width: 100%;
    border-bottom: solid 1px #f2f2f2;
`
export const Quantity = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2.3px;
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5.3px;
    height: 9.3px;
  }
  p {
    margin: 0px 10px;
  }
`
export const TrashIcon = styled.img`
    width: 18px;
    height: 20px;
`
export const PlusIcon = styled.img`
    width: 26px;
    height: 26px;
`
export const CheckIcon = styled.img`
    width: 25px;
    height: 19px;
`
export const StyledSelect = styled.div`
    width: 230px;
    & > div > div:first-child {
        height: 40px;
    }
    & span {
        margin: 0px;
    }
`
export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom:10px;
`
export const Column = styled.div`
    width: 65%;
    display: flex;
    flex-direction: row;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const LabelWrapper = styled.div`
  width: 50%;
  margin: 1%;
  display: flex;
  justify-content: space-between;
  align-self: center;
`


export const Imagen = styled.img`
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    margin: 1%
`

export const Ahref = styled.a`
    cursor: pointer;
`
export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
`

export const FormContainer = styled.form`
  position: relative;
  transition: all 0.2s ease;
  z-index: 999;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  height:${({ height }) => height || 100}%;
  margin:  ${({ margin }) => margin || 0};
  display: ${({ display }) => display || 'block'};
  flex-direction: ${({ flexdirection }) => flexdirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'start'};
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
`

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  width:  18%;
  padding-bottom: 5px;
  padding-right: 20px;
  display: block;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black, theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '17px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 1px 10px;
`


export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`
