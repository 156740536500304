import { createSelector } from 'reselect'

const mapNeighbourhoods = createSelector(
  lotsList => lotsList,
  lotsList =>
    lotsList.map(
      (e, i) => ({
        label: e.name,
        value: e.name,
        guid: e.guid
      })
    )
)

export default mapNeighbourhoods
