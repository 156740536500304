/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import MenuItems from "../../components/MenuItems";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AllCommunities from './AllCommunities';
import { fetchCommunityStates,fetchCommunities,fetchCommunityStatesReset } from "../../store/actions/communities.actions";
const mapStateToProps = state => {
  const { communityStates, loadingFetchCommunityStates } = state.communities;
  return {
    loadingFetchCommunityStates: loadingFetchCommunityStates,
    communityStates: communityStates,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({fetchCommunityStates,fetchCommunities,fetchCommunityStatesReset}, dispatch);

function Communities(props) {
  const { communityStates, loadingFetchCommunityStates } = props;

  const ALL_COMMUNITIES_VIEW = "Comunidades";


  const [view, setView] = useState("Comunidades");
  const [stateDropValue, setStateDropValue] = useState({
    label: 'Todas',
    value: ''
  });

  const getStates = () => {
    let states = communityStates.map(s => ({ label: s.name, value: s.name }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));

    states.unshift({ label: 'Todas', value: '' });

    return states;
  };

  let states = getStates();

  var menulistItems = [
    { title: "Comunidades", route: ALL_COMMUNITIES_VIEW, available: true },
  ];

  useEffect(() => {
    props.fetchCommunityStatesReset();
    props.fetchCommunityStates();
  }, []);

  //  Items del menú
  const menuListComponent = menulistItems.map((item, key) => (
    <MenuItems
      quantity={item.quantity}
      onClick={item.available ? () => setView(item.route) : null}
      active={view === item.route}
      disabled={!item.available}
      key={key}
    >
      {item.title}
    </MenuItems>
  ));

  // Devuelvo la vista en base a la selección en el menú
  const selectedView = (() => {
    switch (view) {
      case ALL_COMMUNITIES_VIEW:
        return (
          <AllCommunities
            selectedState={stateDropValue}
            loadingFetchCommunityStates={loadingFetchCommunityStates}
            setStateDropValue={setStateDropValue}
            communityStates={states}
          />
        );

      default:
        return (
          <AllCommunities

          />
        );
    }
  })();

  return (
    <NavBar menuListComponent={menuListComponent} title="Comunidades">
      {selectedView}
    </NavBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Communities);