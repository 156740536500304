import { createSelector } from 'reselect'

let mapEmergencyPhones = createSelector(  
  emergencyPhones => emergencyPhones,
  emergencyPhones => 
  (emergencyPhones.map(emergencyPhone => ({
    id:emergencyPhone.id,
    description:emergencyPhone.description,
    phone_number:emergencyPhone.phone_number.substring(0,3) + ' ' + emergencyPhone.phone_number.substring(3,4) + ' '+emergencyPhone.phone_number.substring(4,6) + ' ' + emergencyPhone.phone_number.substring(6,10) + ' ' + emergencyPhone.phone_number.substring(10,14),
    notification_types:emergencyPhone.notification_types,
    neighbourhood:emergencyPhone.neighbourhood.name
  })) )
)
export default mapEmergencyPhones
