import React from 'react';
import SectionHeader from '../../components/SectionHeader';
import RitzTable from '../../components/RitzTable';
import Select from 'react-select';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import mapInvitation from '../../selectors/mapInvitation';
import mapExportInvitation from '../../selectors/mapExportInvitation';
import mapNeighbourhoods from '../../selectors/mapNeighbourhoods'
import { exportFromJson } from '../../utils/xlsxExportHelper'
import { fetchNeighbourhoods } from '../../store/actions/proveedores.actions';
import { fetchInvitados } from "../../store/actions/invitados.actions";
import { setNeighbourhood } from "../../store/actions/userInfo.actions";

import { FormContainer, Row, InputWrapper, Column, InputLabel, ErrorLabel, Label, Imagen, LabelWrapper, Container } from './styled';

const columns = [
  { title: 'Nombre y apellido propietario', field: 'host_full_name' },
  { title: 'Dni propietario', field: 'host_dni' },
  { title: 'Lote', field: 'property.code' },
  { title: 'Telefono propietario', field: 'host_phone_number' },
  { title: 'Nombre y apellido invitado', field: 'invitations[0].guest.full_name' },
  { title: 'Dni invitado', field: 'invitations[0].guest.dni' },
  { title: 'Fecha de la invitación', field: 'to' }
];

class Invitados extends React.Component {

  state = {
    openInvitation: false,
  }

  getInitialValues = () => ({
    barrio: {
      label: 'Seleccione un barrio...',
      value: 'Seleccione un barrio...',
      guid: 'Seleccione un barrio...'
    }
  })

  handleSubmit = data => {
    console.log("ejecuto submit", data)
    this.props.setNeighbourhood(data.barrio)
    this.props.fetchInvitados()
  }

  getValidationSchema = () =>
    // Yup.lazy(values =>
    //   Yup.object().shape({
    //     barrio: Yup.string().required('Campo requerido')
    //   })

    Yup.lazy(values =>
      // Yup.object().shape({
      //     label: Yup.string(),
      //     value: Yup.string(),
      //     guid: Yup.string()
      // })
      Yup.object().shape({
        barrio: Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
          guid: Yup.string()
        }).test('notVoid', 'Ingrese un barrio', items => {
          return items.label != 'Seleccione un barrio...' && items != null && items != {};
        }).nullable(),
      })
    )

  // componentDidMount() {
  //   this.props.fetchInvitados()
  // }

  handleOpenInvitation(invitation) {
    console.log("llego esto", invitation)
    this.setState({ openInvitation: invitation })
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <Row>
          <InputLabel>{'Barrio'}</InputLabel>
          <Column>
            {/* {console.log("lista de barrios", this.props.loadingListBarrios)} */}
            <InputWrapper width={'40'} margin={'0 15px 0 0'}>
              <Select
                name={'barrio'}
                value={values.barrio}
                isSearchable
                onChange={values => setFieldValue('barrio', values)}
                error={errors.barrio}
                options={this.props.listBarrios}
                placeholder="Seleccione un barrio..."
                isLoading={this.props.loadingListBarrios}
                autoFocus
                width={'400px'}
              />
              {errors.barrio && <ErrorLabel>{errors.barrio}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={'20'}>
              <Button type={'submit'} medium loading={this.props.loading}>
                {'Filtrar'}
              </Button>
            </InputWrapper>
            <InputWrapper width={'20'}>
              <Button medium loading={this.props.loading} onClick={() => exportFromJson(
                this.props.invitadosList.values.length > 0 ? mapExportInvitation(this.props.invitadosList.values) : [], columns, 'Invitaciones.')}>
                {'Exportar'}
              </Button>
            </InputWrapper>
          </Column>
        </Row>
      </FormContainer>

    </>
  );


  render() {
    // const { openInvitation } = this.state
    // console.log(this.state.openInvitation)
    return (
      <Container>
        <SectionHeader title='Invitados' />
        {this.state.openInvitation !== false ? (
          <Modal title={"Invitacion Nº" + this.state.openInvitation.invitations[0].id} onClose={() => this.handleOpenInvitation(false)}>
            <LabelWrapper>
              <Label>Email: </Label>
              <Label>{this.state.openInvitation.invitations[0].guest.email}</Label>
            </LabelWrapper>
            <LabelWrapper>
              <Label>Usuario: </Label>
              <Label>{this.state.openInvitation.host_username}</Label>
            </LabelWrapper>
            <LabelWrapper>
              <Label>Usuario: </Label>
              <Label>{this.state.openInvitation.invitations[0].guest.email}</Label>
            </LabelWrapper>
            <LabelWrapper>
              <Label>Telefono: </Label>
              <Label>{this.state.openInvitation.invitations[0].guest.phone_number}</Label>
            </LabelWrapper>
            <LabelWrapper>
              <Label>¿Está dentro? </Label>
              <Label>{this.state.openInvitation.invitations[0].is_inside ? "Si" : "No"}</Label>
            </LabelWrapper>
            <LabelWrapper>
              <Label>Licencia:</Label>
              <Imagen src={this.state.openInvitation.invitations[0].guest.profile_picture_url} />
            </LabelWrapper>
            <LabelWrapper>
              <Label>Licencia:</Label>
              <Imagen src={this.state.openInvitation.invitations[0].guest.drive_license_front_picture_url} />
            </LabelWrapper>
            <LabelWrapper>
              <Label>Dni atras:</Label>
              <Imagen src={this.state.openInvitation.invitations[0].guest.dni_back_picture_url} />
            </LabelWrapper>
            <LabelWrapper>
              <Label>Dni adelante:</Label>
              <Imagen src={this.state.openInvitation.invitations[0].guest.dni_front_picture_url} />
            </LabelWrapper>


          </Modal>
        ) : <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={(d) => this.handleSubmit(d)}
          render={e => this.getFormContent(e)}
        />}


        <RitzTable
          paging={true}
          columns={columns.concat({
            title: 'Invitación', field: 'invitation',
            render: rowData => rowData.invitations.length !== 0 ? (<Button onClick={() => this.handleOpenInvitation(rowData)}>Ver Invitacion</Button>) : (<></>)
          })}
          isLoading={this.props.loading}
          options={{
            toolbar: false,
            selection: false,
            search: false,
            paging: true,
            serverSide: false,
            exportButton: false,
            exportAllData: false,
          }}
          pagination={{
            pageSize: 10
          }}
          data={this.props.invitadosList.values.length > 0 ? mapInvitation(this.props.invitadosList.values) : []}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    invitadosList: state.invitados.invitadosList,
    loading: state.invitados.loading,
    listBarrios: mapNeighbourhoods(state.userInfo.neighbourhoods),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvitados, fetchNeighbourhoods, setNeighbourhood
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Invitados)
