import { createSelector } from 'reselect'
var moment = require('moment');
const mapBookings = createSelector(
    bookings => bookings,
    bookings =>
        bookings.map(booking => ({
            user_full_name: booking.user_full_name,
            from: booking.from,
            to: booking.to,
            price: booking.price === 0 ? "-" : booking.price,
            activity: booking.activity,
            space: booking.space,
            dni: booking.dni,
            date: moment(booking.date).format('DD-MM-YYYY'),
            property_code: booking.property_code,
            neighbourhood_name: booking.neighbourhood_name,
            made_by: booking.made_by
        }))
)

export default mapBookings