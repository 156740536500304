import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost};
  cursor: pointer;
  transition: background-color 0.15s ease;
  border-left: 5px solid ${({ theme }) => theme.colors.transparent};
  background-color: ${({ selected }) => (selected ? '#d2d2d2' : 'transparent')};
  &:hover {
    background-color: #f9f9f9;
  }
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  padding: ${({ padding }) => (padding ? padding : "0px 20px" )};
  `

export const Batch = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.waterloo};
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: #909191;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

export const Content = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
  cursor: pointer;
`

export const ContentExtraColumnInfo = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  cursor: pointer;
  border: 2px solid #227462;
  border-radius: 15px;
  box-shadow: 0 1px 1px 0 #227462;
  margin: 8px 0 0 0;
`

export const ContentExtraColumnInfoContainer = styled.div`
 margin: 20px 0 0 0;
`
