import styled from 'styled-components'

export const Container = styled.div`
    top: 20%;
    left: 25%;
    height: 100px;
    padding: 30px;
    border-radius: 6px;
    color: white;
    background-color: ${({ theme, success }) => success ? "#81be3b" : theme.colors.pomegranate};
    text-align: center; 
`
export const Items = styled.div`
    height: 50px;   
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Wrapper = styled.div`
position:${(props) => props.position ? props.position : 'absolute'};
top:50%;
left:50%;
transform: translate(-50%, -50%);
z-index: 999999;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
`