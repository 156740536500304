const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    red: '#FF565A',
    salmon: '#ffd8d9',
    redOpaced: 'rgba(208, 2, 27, 0.25)',
    persimmon: '#FF565A',
    alabaster: '#F8F8F8',
    blueRibbon: '#1873f3',
    blueRibbonTransparent1: 'rgba(24,115,243,0.1)',
    blueRibbonTransparent2: 'rgba(24,115,243,0.2)',
    transparent: 'transparent',
    dustyGray: '#9B9B9B',
    dustyGrayTransparent: 'rgba(31, 31, 31, 0.04)',
    tundora: '#4A4A4A',
    ghost: '#eaf5ef',
    midGray: '#30735f',
    transparentBlack: 'rgba(0, 0, 0, 0.25)',
    transparentWhite: 'rgba(255, 255, 255, 0.65)',
    invisibleWhite : 'rgba(255, 255, 255, 0.00)',
    athensGray: '#F2F2F4',
    selago: '#E5F1FB',
    royalBlue: '#3771EB',
    disabledGray: 'rgba(120, 132, 158, 0.09)',
    mineShaft: '#2D2D2D',
    alto: '#d2d2d2',
    zumthor: '#EFF6FF',
    steelGray: '#222231',
    wildSand: '#FBFCFD',
    pomegranate: '#F44336',
    hawkesBlue: '#E3F2FD',
    waterloo: '#787993',
    mercury: '#CDCACA',
    greyBorder: 'rgba(205, 205, 211, 0.45)',
    mystic: '#DADFEA',
    warmGrey: '#808080',
    border: '#e5e5e5', 
    grass: '#c8e39c',
    oceanGreen: 'rgb(48,115,95)', 
    greenTransparent: 'rgba(45,199,109,0.25)',
    backgroundTransparent: 'rgba(45,199,109,0.03)',
    mariGold: '#F5A827',
    secure: "#0c2c3b",
    friendly: "#7dbe55",
    simple: "#227462",
    stable: "#0e5352",
    pendingLabelText: '#F5A623',
    pendingLabel: '#FEF6E9',
    rejectedLabelText: '#D0021B',
    rejectedLabel: '#F9DBDF',
    activeLabel: '#598f7f',
    completeLabelText: '#81BE3B',
    completeLabel: '#F2F8EB',
    cancelledStateLabel: '#8C1108',
    lightViolet: '#efeaf5',
    violet: '#5f3073',
    pink: '#f5efea',
    borderPink: '#735f30',
    softViolet: '#D4C6DB',
    lightPink:'#F2C9AC',
    appleGreen:'#7dbe5599',
    //Botones
    defaultButtonBackground: '#30735f',
    secondaryButtonBackground: '#15994C',
    //Texto
    defaultTextColor: '#FFFFFF',
    secondarTextColor: '#000000',
    //Label
    errorLabelTextColor: '#F44336',
    errorLabelBackgroundColor: '#FFFFFF',

    //Funnel users Listado
    lighterGreen: '#79ab9c',
    lightGreen: '#6e9d8f',
    darkGreen: '#22614e',
  },
  fonts: {
    roboto: `'Roboto', sans-serif`
  },
  shadows: {
    black13: 'rgba(0, 0, 0, 0.13)',
    portGore01: '0 2px 12px 0 rgba(37, 38, 94, 0.1)'

  },
  spacing: {
    unit: '10px',
    unit2: '20px',
    unit3: '30px',
    unit4: '40px',
  },

}


const invertTheme = ({ blueRibbon, royalBlue }) => ({
  oceanGreen: royalBlue,
  greenTransparent: blueRibbon
});



export default theme
