import React from 'react';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik } from 'formik'
import * as Yup from 'yup'

import mapCategoriesByRole from '../../../selectors/mapCategoriesByRole'
import mapNeighborhood from '../../../selectors/mapNeighbourhoods';
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
//import mapSpaces from '../../../selectors/mapSpaces';

import { fetchReporteUserProperty } from '../../../store/actions/reportes.actions';

import {
  FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel
} from '../styled';

const Barrios = [
  { label: "Nordelta", value: "100" },
  { label: "Puertos", value: "200" },
]


class Usuarios extends React.Component {

  state = {
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,
    properties: [],
    categories: [],
    types: [],
    typesEnabled: false
  }

  getInitialValues = () => ({
    property: '',
    category: '',
    type: '',
    startDate: new Date(),
    endDate: new Date()
  })

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        //  property: Yup.string().required('Ingrese un lote'),
      })
    )

  onChangeCategory(setFieldValue, values) {
    setFieldValue('category', values);
    if (values.value == '') {
      setFieldValue('type', this.state.types[0]);
      this.setState({ typesEnabled: false });
    }
    else {
      this.props.fetchTypesByCategory(values.value);
      this.setState({ typesEnabled: true });
    }
  }


  handleSubmit = data => {
    if(data.barrio !== null && data.hasOwnProperty("barrio")){
      const Guids = data.barrio.map((barrio)=> barrio.guid)
      this.props.fetchReporteUserProperty(Guids)
    }
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <Row>
          <InputWrapper margin={'0px 0px'}>
            <Select
              name={'barrio'}
              value={values.barrio}
              isSearchable={false}
              onChange={values => setFieldValue('barrio', values)}
              error={errors.barrio}
              isMulti
              isSearchable
              options={this.props.barriosMapeados}
              placeholder="Seleccione un barrio..."
              isLoading={this.props.loading}
              autoFocus 
            />
            {errors.barrio && <ErrorLabel>{errors.barrio}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'0px 0px'}>
            <Button type={'submit'} medium width={'60%'}>
              {'Buscar reporte '}
            </Button>
          </InputWrapper>
        </Row>        
      </FormContainer>

    </>
  );

  
  render() {
    return (
      <>
        <SectionHeader title='Unidades/usuarios por Barrios'/>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
           <RitzTable
             columns={[
               { title: 'Nombre', field: 'NeighbourhoodName' },
               { title: 'ID de barrio', field: 'NeighbourhoodId' },
               { title: 'Unidad funcionales', field: 'FunctionalUnits' },
               { title: 'Usuarios del barrio', field: 'UsersByNeighbourhood' },
               // { title: 'Activo', field: 'activedescription', }
             ]}
             data={this.props.barriosMapeados}
             options={{
              toolbar: true,
              selection: false,
              search: false,
              paging: true,
              serverSide: false,
              exportButton: true,
              exportAllData: true,
              exportFileName: "Reporte Barrios." + (new Date().toISOString().split('.')[0]),
            }}
            isLoading={this.props.loading}
           />
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    reporteProperty: state.reportes.reporteProperty,
    loading: state.reportes.loadingReporteProperty,
    barriosMapeados: mapNeighborhood(state.userInfo.neighbourhoods) 
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReporteUserProperty
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios)
