import React from 'react'
import { Container, Row, Title, Subtitle, Content, ContentExtraColumnInfo, ContentExtraColumnInfoContainer } from './styled'
import { Avatar, Grid } from '@material-ui/core'

const formatContent = (content, prevLength) => content ? (content.length - prevLength  > 36 ? `${content.substring(0, 36)}...` : content) : null

const Resource = ({
  title,
  onClick,
  subtitle,
  extraInfo,
  detail,
  selected,
  avatar,
  withAvatar,
  avatarWithPadding,
  avatarCentered,
  hasMoreColumnInfo,
  extraColumnsContent,
  titlePadding
  
}) => (
    <Container onClick={onClick} selected={selected}>
      {withAvatar ? (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Grid xs={2}>
            {avatar ? (
              <Avatar
                src={avatar}
                style={
                  avatarWithPadding
                    ? {
                      background: '#1873f3',
                      width: '30px',
                      height: '30px',
                      padding: '10px',
                      marginLeft: avatarCentered ? 'auto' : '0px',
                      marginRight: 'auto'
                    }
                    : {
                      background: '#1873f3',
                      width: '45px',
                      height: '45px',
                      marginLeft: avatarCentered ? 'auto' : '0px',
                      marginRight: 'auto'
                    }
                }
              />
            ) : (
                <Avatar
                  style={{ background: '#1873f3', width: '30px', height: '30px', padding: '10px' }}
                >
                  {title.charAt(0).toUpperCase()}
                </Avatar>
              )}
          </Grid>
          <Grid xs={10}>
            <Row justify={'space-between'}>
              <Row>
                <Title padding={titlePadding}>{title}</Title>
              </Row>
              <Subtitle>{formatContent(extraInfo,0)}</Subtitle>
            </Row>
            
              <Row> 
               <Content>{formatContent(subtitle,0)}</Content>
              </Row>
              {hasMoreColumnInfo && 
                <ContentExtraColumnInfoContainer>
                  {extraColumnsContent.map((item,index)=>
                    <Row>
                      <Content key={index} style={{fontSize: 12 + "px", margin: 0}}>{item.label}</Content>
                      <Content key={index} style={{fontSize: 12 + "px", margin: 0}}>{` : ${formatContent(item.info,item.label.length)}`}</Content>
                    </Row>)
                  }
                </ContentExtraColumnInfoContainer>
              }
            <Row>
              <Content>{formatContent(detail,0)}</Content>
            </Row>
          </Grid>
        </div>
      ) : (
          <div style={{ width: '100%' }}>
            <Row justify={'space-between'}>
              <Row>
                <Title padding={titlePadding}>{title}</Title>
              </Row>
              <Subtitle>{formatContent(extraInfo,0)}</Subtitle>
            </Row>
             
              {/* <Row style={{justifyContent: "flex-end"}}> 
               <Content>{formatContent(subtitle)}</Content>
              </Row> */}
           
            {hasMoreColumnInfo && 
            <ContentExtraColumnInfoContainer>
              {extraColumnsContent.map((item,index)=>
                <Row>
                  <Content key={index} style={{fontSize: 12 + "px", margin: 0}}>{item.label}</Content>
                  <Content key={index} style={{fontSize: 12 + "px", margin: 0}}>{` : ${formatContent(item.info,item.label.length)}`}</Content>
                </Row>)
              }
            </ContentExtraColumnInfoContainer>
            }
            <Row>
              <Content>{formatContent(detail,0)}</Content>
            </Row>
          </div>
        )}
    </Container>
  )

export default Resource
