import { plus15Minutes } from "./dateParser";

export const generateReservationsTableRows = reservations => {
  const completedColumnDays = fillReservationsDays(reservations);
  const completedRowDays = reservationsColumnsToRows(completedColumnDays);
  return completedRowDays;
};

const fillReservationsDays = reservations => {
  let reservationDays = [];
  for (let index = 0; index < reservations.length; index++) {
    let reservation = reservations[index];
    let days = completeDay(reservation.calendar_times.sort((a, b) => (a.from > b.from ? 1 : -1)),index,reservations);
    reservationDays.push({
      ...reservation,
      calendar_times: days
    });
  } 
  return reservationDays;
};

const completeDay = (calendar_times, currentIndex, reservations) => {
  const initialTimeSpan = "00:00";
  const lastTimeSpan = "00:00";
  let actualTimeSpan = calendar_times.length > 0 ? calendar_times[0].from : lastTimeSpan;
  let formattedReservations = [];

  //Completo los horarios desde el principio hasta el primer horario cargado.
  formattedReservations = completeTimeRange(initialTimeSpan, actualTimeSpan);

  calendar_times.forEach(reservation => {
    /*
     Completo la tabla con los horarios vacios entre los horarios cargados.
     Por ejemplo si tengo un horario de 08:00 a 09:00 y otro de 10:00 a 11:00 
     cargo los espacios entre las 09:00 y las 10:00
     */
    if (actualTimeSpan < reservation.from) {
      formattedReservations = [
        ...formattedReservations,
        ...completeTimeRange(actualTimeSpan, reservation.from)
      ];
      actualTimeSpan = reservation.from;
    }

    let to = reservation.to;
    if (reservation.ends_tomorrow) {
      to = "00:00";
      const nextIndex = ++currentIndex; 
      const nextReservation = reservations[nextIndex];
      const splittedReservation = { ...reservation, from: "00:00",ends_tomorrow:0,started_yesterday:1 };
      nextReservation.calendar_times.push(splittedReservation); 
    }

    //Cargo los horarios que vienen de la api.
    formattedReservations = [
      ...formattedReservations,
      ...completeTimeRange(actualTimeSpan, to, reservation)
    ];

    actualTimeSpan = to;
  });

  if (lastTimeSpan != actualTimeSpan) {
    //Completo el final
    formattedReservations = [
      ...formattedReservations,
      ...completeTimeRange(actualTimeSpan, lastTimeSpan)
    ];
  }

  return formattedReservations;
};

const completeTimeRange = (from, to, reservationData) => {
  let timesCompleted = [];

  let lastTimeSpan = from;

  let index = 0;

  if (to == "00:00") {
    let updatedTimeSpan = plus15Minutes(lastTimeSpan);
    let timeSpan = generateTimeRange(lastTimeSpan, updatedTimeSpan, reservationData, index);
    timesCompleted.push(timeSpan);
    lastTimeSpan = updatedTimeSpan;
    index = index + 1;
  }

  while ((to != "00:00" && lastTimeSpan < to) || (to == "00:00" && lastTimeSpan != to)) {
    let updatedTimeSpan = plus15Minutes(lastTimeSpan);
    let timeSpan = generateTimeRange(lastTimeSpan, updatedTimeSpan, reservationData, index);
    timesCompleted.push(timeSpan);
    lastTimeSpan = updatedTimeSpan;
    index = index + 1;
  }

  if (reservationData && timesCompleted.length) {
    timesCompleted[timesCompleted.length - 1].final = !reservationData.ends_tomorrow;
  }

  return timesCompleted;
};

const generateTimeRange = (lastTimeSpan, updatedTimeSpan, reservationData, pos) => {
  let timeRange = {
    from: lastTimeSpan,
    to: updatedTimeSpan
  };

  if (reservationData) {
    if (pos === 0) { 
      timeRange.initial = !reservationData.started_yesterday;
    }
    timeRange.schedule_id = reservationData.schedule_id;
    timeRange.space_id = reservationData.space_id;
    timeRange.is_cancelled = reservationData.is_cancelled;
    timeRange.is_booked = reservationData.is_booked;
    timeRange.booking = reservationData.booking;
    timeRange.price = reservationData.price;
    timeRange.originalData = reservationData;
  }

  return timeRange;
};

const reservationsColumnsToRows = columnDays => {
  let rowTimeSlots = {};
  columnDays.forEach(day => {
    day.calendar_times.forEach(timeSlot => {
      if (!rowTimeSlots[timeSlot.from]) {
        rowTimeSlots[timeSlot.from] = {
          rowLabel: timeSlot.from
        };
      }
      rowTimeSlots[timeSlot.from][day.day_of_week] = timeSlot;
    });
  });
  return rowTimeSlots;
};

export const addTimeRange = (timeRange, dayPos, originalData) => {
  let updatedData = Array.from(originalData);
  let isDateValid = false;
  updatedData.forEach(day => {
    if (day.day_of_week === dayPos) {
      //Check first range
      if (!day.calendar_times.length) {
        day.calendar_times.push(timeRange);
        isDateValid = true;
      } else {
        //Check middle range
        let ActiveTimesUpdated = [];

        day.calendar_times.forEach((range, i) => {
          const prevRange = day.calendar_times[i - 1] || { to: "00:00" };

          if (prevRange.to <= timeRange.from && range.from >= timeRange.to) {
            ActiveTimesUpdated.push(timeRange);
            isDateValid = true;
          }

          ActiveTimesUpdated.push(range);
        });

        //Check latest range
        if (!isDateValid) {
          const lastRange = day.calendar_times[day.calendar_times.length - 1];
          if (lastRange.to <= timeRange.from && "23:45" >= timeRange.to) {
            ActiveTimesUpdated.push(timeRange);
            isDateValid = true;
          }
        }

        day.calendar_times = ActiveTimesUpdated;
      }
    }
  });
  return updatedData;
};

export const isTimeRangeValid = (timeRange, dayPos, originalData) => {
  const dataCopy = Array.from(originalData);
  let isDateValid = false;
  dataCopy.forEach(day => {
    if (day.day_of_week === dayPos) {
      //Check first range
      if (!day.calendar_times.length) {
        isDateValid = true;
      } else {
        //Check middle range
        day.calendar_times.forEach((range, i) => {
          const prevRange = day.calendar_times[i - 1] || { to: "00:00" };

          if (prevRange.to <= timeRange.from && range.from >= timeRange.to) {
            isDateValid = true;
          }
        });
        //Check latest range
        if (!isDateValid) {
          const lastRange = day.calendar_times[day.calendar_times.length - 1];
          if (lastRange.to <= timeRange.from && "23:45" >= timeRange.to) {
            isDateValid = true;
          }
        }
      }
    }
  });

  return isDateValid;
};
