import XLSX from "xlsx";
var moment = require("moment");

export const exportFromJson = (data, columns, name) => {
    let columnsObject = {};
    columns.map(column => {
        columnsObject[`${column.field}`] = column.title
    })

    let formattedData = [columnsObject];
    formattedData = formattedData.concat(data);
    var ws = XLSX.utils.json_to_sheet(formattedData, {
        columns,
        skipHeader: true
    });
    let wscols = fitToColumn(formattedData);
    ws["!cols"] = wscols;
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name + moment().format("YYYY-MM-DD"));
    XLSX.writeFile(wb, name + moment().format("YYYY-MM-DD") + `.xlsx`);
};

const fitToColumn = data => {
    const columnWidths = [];
    for (const property in data[0]) {
        columnWidths.push({
            wch: Math.max(
                property ? property.toString().length : 0,
                ...data.map(obj => obj[property] ? obj[property].toString().length : 0))
        });
    }
    return columnWidths;
};

export const exportFromJsonToList = (data,columnsTitle, name, sheetNames) => {

    //Convierte en arreglo el objeto que llega 
    const formmatedData = Object.values(data);
    const wb = XLSX.utils.book_new();
    let workSheets = [];

    //Carga las hojas con sus titulos
    for (let i = 0; i < sheetNames.length; i++) {
        
        workSheets[i] = XLSX.utils.json_to_sheet([]);
        if(i === 0){
            XLSX.utils.sheet_add_aoa(workSheets[i], columnsTitle[0]); 
            let wcols = filtToColumnList(formmatedData[i]);
            workSheets[i]["!cols"] = wcols;
        } else if(i === 1) {
            XLSX.utils.sheet_add_aoa(workSheets[i], columnsTitle[1]); 
            let wcols = filtToColumnList(formmatedData[i]);
            workSheets[i]["!cols"] = wcols;
        }
    }
 
    //Agrega la data al excel y las hojas al libro
    sheetNames.forEach((element, index) => {
        if(index === 0){
            XLSX.utils.sheet_add_json(workSheets[index], formmatedData[index], {origin: 'A2', skipHeader: true});
            XLSX.utils.book_append_sheet(wb, workSheets[index], sheetNames[index]);
        } else {
            XLSX.utils.sheet_add_json(workSheets[index], formmatedData[index], {origin: 'A2', skipHeader: true});
            XLSX.utils.book_append_sheet(wb, workSheets[index], sheetNames[index]);
        }
        
    });

    //Crea el archivo
    XLSX.writeFile(wb, name + moment().format("YYYY-MM-DD") + `.xlsx`);  
    ;
}

const filtToColumnList = data => {
    const columnWidths = [];
   data.forEach(element => {
    for (const property in element) {
        columnWidths.push({
            wch: Math.max(
                property ? property.toString().length : 0,
                ...data.map(obj => obj[property] ? obj[property].toString().length : 0))
        });
    }
   }); 
    return columnWidths;
}
