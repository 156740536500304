import React from 'react';
import Spinner from 'react-spinner-material';

import Button from '../../../components/Button';

import { RowItem, RowLeft, RowRight, TituloLeft, DescRight, RowItemNoBorder, TituloLeftSub, DescRightSub, SubtitleText, Subtitle } from './styled'
import Modal from '../../../components/Modal';
import CarrouselImagenes from '../../../components/Carrouselphotos';

class ModalDetail extends React.Component {
    render() {
        return (

            <Modal onClose={() => this.props.close()} height={'auto'}>
                {!this.props.loading ?
                    <>
                        <RowItem style={{ justifyContent: 'center' }}>
                            <CarrouselImagenes width='200px' images={this.props.producto.shopitemimagesurls} />
                        </RowItem>

                        <RowItem>
                            <RowLeft>
                                <TituloLeft>{this.props.producto.title}</TituloLeft>
                            </RowLeft>
                            <RowRight>
                                {this.props.producto.prices[0].currency}{this.props.producto.prices[0].price}
                            </RowRight>
                        </RowItem>
                        <RowItem>
                            <RowLeft>
                                <TituloLeftSub>Estado</TituloLeftSub>
                            </RowLeft>
                            <RowRight>
                                <DescRightSub>{this.props.producto.shopitemstatename}</DescRightSub>
                            </RowRight>
                        </RowItem>
                        <RowItem>
                            <RowLeft>
                                <TituloLeftSub>Condición Uso</TituloLeftSub>
                            </RowLeft>
                            <RowRight>
                                <DescRightSub>{this.props.producto.shopitemconditionname}</DescRightSub>
                            </RowRight>
                        </RowItem>
                        <RowItemNoBorder>
                            <Subtitle inputColor='#30a05f'>Descripción</Subtitle>
                        </RowItemNoBorder>
                        <RowItemNoBorder>
                            <SubtitleText>{this.props.producto.description}</SubtitleText>
                        </RowItemNoBorder>
                    </>
                    :
                    <Spinner />
                }
            </Modal>

        );
    }
}

export default ModalDetail