import React from 'react';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Button from '../../../components/Button';
import Select from 'react-select'


import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik } from 'formik'
import * as Yup from 'yup'

import mapExpenses from '../../../selectors/mapExpenses'
import mapExpensesDetail from '../../../selectors/mapExpensesDetail'
import mapNeighbourhoods from '../../../selectors/mapNeighbourhoods'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { setNeighbourhood } from '../../../store/actions/userInfo.actions';
import { fetchReporteExpensas } from '../../../store/actions/reportes.actions';

import { SelectWrapper,RowDetailContainer } from './styled';
import { FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel } from '../styled';


class Expensas extends React.Component {

  state = {
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    }
  }

  getInitialValues = () => ({
    startDate: new Date() - 30,
    endDate: new Date()
  })

  handleSubmit = data => {   
    const NeighbourhoodGuidList = this.state.neighbourhoodSelected.map((barrio, i) => (barrio.guid ))
    const formattedBody = { 
      DateFrom: data.startDate, 
      DateTo: data.endDate, 
      NeighbourhoodGuidList 
    } 
    console.log(formattedBody)
    this.props.fetchReporteExpensas(formattedBody)
  }

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha')
      })
    )

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <Row align="flex-end">
        <InputWrapper margin='7vh 15px'>
            <Select
              value={this.state.neighbourhoodSelected}
              onChange={this.handleChange}
              options={this.props.neighbourhoods}
              isMulti
            />
          </InputWrapper>
          <InputWrapper margin='20px 15px'>
            <InputLabel>{'Desde'}</InputLabel>
            <DatePickerStyled
              selected={values.startDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsStart
              dateFormat="dd/MM/yyy"
              onChange={startDate => setFieldValue('startDate', startDate)}
              // minDate={new Date()}
              // maxDate={addMonths(new Date(), 12)}
              placeholderText="Fecha desde"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Hasta'}</InputLabel>
            <DatePickerStyled
              selected={values.endDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsEnd
              dateFormat="dd/MM/yyy"
              onChange={endDate => setFieldValue('endDate', endDate)}
              minDate={values.startDate || null}
              placeholderText="Fecha hasta"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputWrapper margin={'20px 15px'}>
              <Button type={'submit'} medium width={'100%'}>
                {'Filtrar'}
              </Button>
            </InputWrapper>
          </InputWrapper>
        </Row>
      </FormContainer>

    </>
  );

  
  handleChange = (neighbourhoodSelected) => {
    if(neighbourhoodSelected !== null){
      if(neighbourhoodSelected.length === 1){        
        this.props.setNeighbourhood(neighbourhoodSelected[0])
      }
      this.setState({ neighbourhoodSelected: neighbourhoodSelected });
    }
  }

  render() {

    return (
      <>
        <SectionHeader title='Expensas'>
        
          </SectionHeader>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />

        <RitzTable
          title=""
          columns={[
            { title: 'Periodo', field: 'fecha' },
            { title: 'Barrio', field: 'barrio' },
            { title: 'Importe', field: 'pago' },

          ]}
          data={this.props.reporteExpensas}

          options={{
            toolbar: true,
            selection: false,
            search: false,
            paging: true,
            serverSide: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: "expensa." + (new Date().toISOString().split('.')[0]),
          }}

          pagination={{
            pageSize: 10
          }}


          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}'
            },
            toolbar: {
              nRowsSelected: '{0} fila(s)',
              searchPlaceholder: "Buscar",
              exportTitle: 'Exportar',
              exportAriaLabel: 'Exportar',
              exportName: 'Exportar a CSV'
            },
            header: {
              actions: 'Acciones'
            },
            body: {
              emptyDataSourceMessage: 'No hay registros para mostrar',
              filterRow: {
                filterTooltip: 'Filtros'
              }
            }
          }}
          
          onRowClick={(event, rowData, togglePanel) => togglePanel()}

          detailPanel={[
            {
              icon: () => (<ArrowForwardIosIcon />),
              tooltip: 'Ver detalles',
              render: rowData => {


                return (
                  <RowDetailContainer>
                    <Label bold>Detalle:</Label>
                    <RitzTable
                      paging={true}
                      columns={[
                        { title: 'Nombre Completo', field: 'full_name' },
                        { title: 'CUIT', field: 'cuit' },
                        { title: 'Importe', field: 'amount' },
                        { title: 'Barrio', field: 'neighbourhood_name' },
                        { title: 'Código Propiedad', field: 'property_code' }
                      ]}
                      data={this.props.reporteExpensasDetail[rowData.pos]}
                    />

                  </RowDetailContainer>
                )
              },
            }]}

          isLoading={this.props.loadingreporteExpensas}
        />

      </>
    );
  }
}


const mapStateToProps = ({
  reportes: {
    reporteExpensas,
    loadingreporteExpensas
  },
  userInfo: { neighbourhoods, selectedNeighbourhood },
  
}) => {

  return {
    selectedNeighbourhood,
    neighbourhoods: mapNeighbourhoods(neighbourhoods),
    reporteExpensas: reporteExpensas ? mapExpenses(reporteExpensas) : [],
    reporteExpensasDetail: reporteExpensas ? mapExpensesDetail(reporteExpensas) : [],
    loadingreporteExpensas: loadingreporteExpensas,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchReporteExpensas,setNeighbourhood },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Expensas)
