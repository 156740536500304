/* eslint-disable react/prop-types */
import SectionHeader from '../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RitzTable from '../../../components/RitzTable';
import { bindActionCreators } from 'redux';
import Button from '../../../components/Button';
import {
    fetchEmergencyPhones,
    createEmergencyPhoneReset,
    createEmergencyPhone,
    deleteEmergencyPhoneReset,
    deleteEmergencyPhone,
    fetchAlertMotives,
    fetchEmergencyPhone,
    fetchEmergencyPhoneReset,
    fetchNotificationTypes,
    updateEmergencyPhone,
    updateEmergencyPhoneReset,
    selectEmergencyPhonesRows
} from '../../../store/actions/alerts.actions';
import DeleteIcon from '../../../assets/icons/deletetrash.svg';
import EditIcon from '../../../assets/icons/edit.svg';
import SlideBar from './SlideBar/index';
import ModalMessage from '../../../components/ModalMessage';
import ModalDelete from '../../../components/ModalDelete';
import EmergencyPhoneTableFooter from '../Components/EmergencyPhoneTableFooter';
import Loader from '../../../components/Loader';
import Select from 'react-select'

import {
    Imagen,
    NotificationTypesLabel,
    TableWrapper,
    LoaderWrapper,
    LabelSection,
    InputWrapper,
    InputLabel,
    SelectWrapper
} from './styled';


const mapStateToProps = state => {
    const {
        notificationTypes,
        loadingFetchNotificationTypes,
        emergencyPhones,
        emergencyPhone,
        // alertMotives,
        // loadingFetchAlertMotives,
        loadingFetchEmergencyPhones,
        loadingCreateEmergencyPhone,
        errorCreateEmergencyPhone,
        createEmergencyPhoneSuccess,
        loadingDeleteEmergencyPhone,
        deleteEmergencyPhoneStart,
        deleteEmergencyPhoneSuccess,
        deleteEmergencyPhoneFail,
        detailEmergencyPhone,
        loadingFetchEmergencyPhone,
        loadingUpdateEmergencyPhone,
        errorUpdateEmergencyPhone,
        updateEmergencyPhoneSuccess
    } = state.alerts;

    const {
        user
    } = state.auth

    return {
        isLoading: loadingFetchEmergencyPhones,
        emergencyPhones: emergencyPhones,
        detailEmergencyPhone: detailEmergencyPhone,
        loadingFetchEmergencyPhone: loadingFetchEmergencyPhone,
        loadingCreateEmergencyPhone: loadingCreateEmergencyPhone,
        createEmergencyPhoneSuccess: createEmergencyPhoneSuccess,
        loadingDeleteEmergencyPhone: loadingDeleteEmergencyPhone,
        deleteEmergencyPhoneSuccess: deleteEmergencyPhoneSuccess,
        deleteEmergencyPhoneFail: deleteEmergencyPhoneFail,
        loadingUpdateEmergencyPhone: loadingUpdateEmergencyPhone,
        notificationTypes: notificationTypes,
        loadingFetchNotificationTypes: loadingFetchNotificationTypes,
        errorUpdateEmergencyPhone: errorUpdateEmergencyPhone,
        updateEmergencyPhoneSuccess: updateEmergencyPhoneSuccess,
        user: user
    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEmergencyPhones,
            createEmergencyPhoneReset,
            createEmergencyPhone,
            deleteEmergencyPhone,
            deleteEmergencyPhoneReset,
            fetchAlertMotives,
            fetchEmergencyPhone,
            fetchEmergencyPhoneReset,
            fetchNotificationTypes,
            updateEmergencyPhone,
            updateEmergencyPhoneReset,
            selectEmergencyPhonesRows
        },
        dispatch
    )

function TelefonosGuardia(props) {

    const {
        isLoading,
        emergencyPhones,
        loadingFetchAlertMotives,
        loadingCreateEmergencyPhone,
        errorCreateEmergencyPhone,
        createEmergencyPhoneSuccess,
        loadingDeleteEmergencyPhone,
        deleteEmergencyPhoneStart,
        deleteEmergencyPhoneSuccess,
        deleteEmergencyPhoneFail,
        detailEmergencyPhone,
        loadingFetchEmergencyPhone,
        loadingUpdateEmergencyPhone,
        notificationTypes,
        loadingFetchNotificationTypes,
        errorUpdateEmergencyPhone,
        updateEmergencyPhoneSuccess,
        selectEmergencyPhonesRows,
        selectedNeighbourhood,
        setNeighbourhoodDropValue,
        neighbourhoods,
        user
    } = props;

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [orderField, setOrderField] = useState('id');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [addEmergencyPhone, setAddEmergencyPhone] = useState(false);
    const [editEmergencyPhone, setEditEmergencyPhone] = useState(false);
    const [deleteEmergencyPhone, setDeleteEmergencyPhone] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const totalRows = emergencyPhones.length;

    var columns = [
        { title: 'Referencia', field: 'description', customSort: (a, b) => a.description.toLowerCase() - b.description.toLowerCase() },
        { title: 'Número de telefono', field: 'phone_number' },
        {
            title: 'Barrio', field: 'neighbourhood',
            hidden: user.neighbourhoods.length === 1,
            customSort:
                (a, b) => a.neighbourhood.toLowerCase() - b.neighbourhood.toLowerCase()
        },
        {
            title: 'Tipo de notificación', field: 'notification_types',
            cellStyle: {
                width: "30%",
            },
            headerStyle: {
                width: "30%",
            }, render: rowData =>
                <LabelSection>
                    {rowData.notification_types.map((item, i) => <NotificationTypesLabel key={i}>{item.name} </NotificationTypesLabel>)}
                </LabelSection>

        },
        {
            title: 'Acciones',
            field: 'actions',
            cellStyle: {
                width: "10%",
            },
            headerStyle: {
                width: "10%",
            },
            render: rowData =>

                <>
                    <Imagen onClick={() => toggleEdit(rowData.id)} src={EditIcon} />
                    <Imagen onClick={() => toggleDelete(rowData.id)} src={DeleteIcon} />
                </>
        },
    ];

    const refreshGrid = () => {
        if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {

            props.fetchEmergencyPhones(orderField, orderDirection, pageNumber, pageSize, neighbourhoods.map(x => x.value));
        } else {
            props.fetchEmergencyPhones(orderField, orderDirection, pageNumber, pageSize, selectedNeighbourhood.value);
        }
    }

    useEffect(() => {
        refreshGrid();
    }, []);

    useEffect(() => {
        if (!addEmergencyPhone && !editEmergencyPhone && !deleteEmergencyPhone) {
            refreshGrid();
        }
    }, [addEmergencyPhone,
        editEmergencyPhone,
        deleteEmergencyPhone]);

    const onChangePageHandler = (e, page) => {
        props.fetchEmergencyPhones(orderField, orderDirection, page, pageSize, selectedNeighbourhood.value);

    }

    const toggleAdd = async () => {
        props.fetchNotificationTypes(false);
        setAddEmergencyPhone(!addEmergencyPhone);
        props.createEmergencyPhoneReset();
    };

    const toggleEdit = async (selectedId) => {
        props.updateEmergencyPhoneReset();
        setEditEmergencyPhone(!editEmergencyPhone);
        props.fetchEmergencyPhoneReset();
        if (!editEmergencyPhone) {
            await props.fetchEmergencyPhone(selectedId);
            props.fetchNotificationTypes(false);
        }
    };

    const renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const onCreateEmergencyPhoneSubmit = async (data) => {
        let formattedData = {
            id: data.id,
            description: data.description,
            alert_motive_ids: data.alert_motives ? data.alert_motives.map(alert_motive => { return alert_motive.id }) : [],
            phone_number: data.phone_number,
            notification_type_ids: data.notification_types ? data.notification_types.map(notification_type => { return notification_type.value }) : [],
            neighbourhood: data.neighbourhood ? data.neighbourhood[0] ? data.neighbourhood[0].value : data.neighbourhood.value : selectedNeighbourhood.value
        };
        props.createEmergencyPhone(formattedData);
    }

    const onUpdateEmergencyPhoneSubmit = async (data) => {

        let formattedData = {
            id: data.id,
            description: data.description,
            alert_motive_ids: data.alert_motives ? data.alert_motives.map(alert_motive => { return alert_motive.id }) : [],
            phone_number: data.phone_number,
            notification_type_ids: data.notification_types ? data.notification_types.map(notification_type => { return notification_type.value }) : [],
            neighbourhood: data.neighbourhood ? data.neighbourhood[0] ? data.neighbourhood[0].value : data.neighbourhood.value : selectedNeighbourhood.value
        };

        props.updateEmergencyPhone(formattedData);
    }

    const onDeleteEmergencyPhoneSubmit = async () => {

        props.deleteEmergencyPhone(selectedId);
    }


    const addSlideBar = (() => {
        if (createEmergencyPhoneSuccess) {
            return renderModalMessageSuccess('Se ha creado el teléfono de emergencia correctamente.', toggleAdd);
        }
        else if (props.errorCreateEmergencyPhone) {
            return renderModalMessageFail('Hubo un error al intentar crear el teléfono de emergencia.', toggleAdd);
        }
        if (addEmergencyPhone) {
            return (
                <SlideBar
                    title="Nuevo Teléfono"
                    onClose={toggleAdd}
                    headerBackgroundColor={'#30735f'}
                    isSubmitting={loadingCreateEmergencyPhone}
                    isLoading={loadingFetchNotificationTypes}
                    notificationTypes={notificationTypes}
                    onSubmit={onCreateEmergencyPhoneSubmit}
                    neighbourhoods={user.neighbourhoods}
                >
                </SlideBar>
            );
        }
    }
    )();

    const editSlideBar = (() => {
        if (updateEmergencyPhoneSuccess) {
            return renderModalMessageSuccess('Se ha editado el teléfono de emergencia correctamente.', toggleEdit);
        }
        else if (props.errorUpdateEmergencyPhone) {
            return renderModalMessageFail('Hubo un error al intentar editar el teléfono de emergencia.', toggleEdit);
        }

        if (editEmergencyPhone) {
            return (
                <SlideBar
                    title="Editar Teléfono"
                    detailEmergencyPhone={detailEmergencyPhone}
                    onClose={toggleEdit}
                    headerBackgroundColor={'#30735f'}
                    onSubmit={onUpdateEmergencyPhoneSubmit}
                    notificationTypes={notificationTypes}
                    isSubmitting={loadingUpdateEmergencyPhone}
                    isLoading={loadingFetchAlertMotives || loadingFetchEmergencyPhone || loadingFetchNotificationTypes}
                    neighbourhoods={user.neighbourhoods}
                >
                </SlideBar>
            );
        }
    }
    )();

    const toggleDelete = (selectedId) => {
        setSelectedId(selectedId);
        setDeleteEmergencyPhone(!deleteEmergencyPhone);
        props.deleteEmergencyPhoneReset();
    };

    const deleteModal = (() => {
        if (props.deleteEmergencyPhoneSuccess) {
            return renderModalMessageSuccess('Se ha eliminado el teléfono de emergencia correctamente.', toggleDelete);
        }
        // else if (props.errorD) {
        //     return renderModalMessageFail('Hubo un error al intentar crear el teléfono de emergencia.', toggleAdd);
        // }
        if (deleteEmergencyPhone) {
            return (
                <ModalDelete
                    title="Eliminar teléfono de emergencia"
                    subTitle="¿Estás seguro que querés eliminarlo?"
                    buttonMessage="Eliminar teléfono de emergencia"
                    successMessage="¡Se ha eliminado el teléfono de emergencia!"
                    isLoading={loadingDeleteEmergencyPhone}
                    onClose={toggleDelete}
                    success={false}
                    onSubmit={onDeleteEmergencyPhoneSubmit}
                >
                </ModalDelete>
            );
        }
    }
    )();

    const getCheckboxIds = rows => {
        selectEmergencyPhonesRows(rows.map(x => x.id));
    };

    const onNeighbourhoodChange = data => {
        setNeighbourhoodDropValue(data);
        if (data.value === "00000000-0000-0000-0000-000000000000") {
            props.fetchEmergencyPhones(
                orderField,
                orderDirection,
                pageNumber,
                pageSize,
                neighbourhoods.map(x => x.value)
            );
        } else {
            props.fetchEmergencyPhones(orderField, orderDirection, pageNumber, pageSize, data.value);
        }
    };

    return (
        <>
            <SectionHeader
                title="Teléfonos de guardia"
                width="100%"
                justifyContent={"space-between"}
                fontWeight={"bold"}
            >
                {user.neighbourhoods.length > 1 ? (
                    <InputWrapper>
                        <InputLabel>{"Barrio"}</InputLabel>
                        <SelectWrapper>
                            <Select
                                value={selectedNeighbourhood}
                                onChange={value => onNeighbourhoodChange(value)}
                                options={neighbourhoods}
                                placeholder="Seleccione un barrio"
                                isLoading={props.isLoading}
                            />
                        </SelectWrapper>
                    </InputWrapper>
                ) : null}
                <Button loading={isLoading} onClick={() => toggleAdd()} width={"180px"}>
                    Nuevo teléfono
        </Button>
            </SectionHeader>
            <SectionHeader
                title="Ingresá los teléfónos de guardia que recibirán las alertas de los vecinos."
                subtitle={true}
                width="100%"
            />
            <TableWrapper>
                {isLoading ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : null}
                <RitzTable
                    columns={columns}
                    title=""
                    isLoading={isLoading}
                    options={{
                        toolbar: false,
                        paging: true,
                        serverSide: true,
                        selection: true,
                        exportButton: false,
                        exportAllData: false,
                        exportFileName: "TelefonoDeEmergencia." + new Date().toISOString().split(".")[0]
                    }}
                    onSelectionChange={getCheckboxIds}
                    pagination={{
                        totalRow: totalRows,
                        page: pageNumber,
                        pageSize: pageSize,
                        onChangePage: onChangePageHandler
                    }}
                    data={emergencyPhones}
                />
                {addSlideBar}
                {editSlideBar}
                {deleteModal}
            </TableWrapper>

            <EmergencyPhoneTableFooter refreshGrid={refreshGrid} />
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TelefonosGuardia);
