/* eslint-disable react/prop-types */
import React from "react";
import { Container, ErrorLabel, Charge, Pay, Pages, Number, InputWrapper, Column, Label, AuthWrapper, ExtremeButton } from "./styled";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import RitzTable from "../../../../components/RitzTable";
import {
  fetchTransactionData,
  setPageTransactionList,
  fetchTransactionList,
  fetchMovementsLast24HoursReport,
  fetchMovementsLast24HoursReportReset,
  fetchMovementsLast7DaysReport,
  fetchMovementsLast7DaysReportReset,
  hasMoreTransactionList
} from "../../../../store/actions/billetera.actions";

import DayJS from "react-dayjs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../../../../components/Loader";
import ReactPaginate from "react-paginate";
import ModalDetailTransfer from "./components/ModalDetailTransfer";

import todo from "../../../../assets/icons/creditCard.svg";
import transfer from "../../../../assets/icons/transfer.svg";
import multas from "../../../../assets/icons/noun-bill-1712977.svg";
import otros from "../../../../assets/icons/noun-more-1002227.svg";
import qr from "../../../../assets/icons/qr-code.svg";

import Button from "../../../../components/Button";

import { exportFromJsonToList } from "../../../../utils/xlsxExportHelper";

const concepto = [
  { value: "todo", label: "Todos", src: todo },
  { value: "Debito Directo Expensa", label: "Deb. directo", src: multas },
  { value: "Transferencia bancaria", label: "Transf. Banc", src: transfer },
  { value: "QR", label: "QR", src: qr },
  { value: "REVERSO - ", label: "Reversos", src: otros }
];

var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });

let startDate1 = new Date();
startDate1.setDate(startDate1.getDate() - 1);
let startDate7 = new Date();
startDate7.setDate(startDate7.getDate() - 7);

class MovementsList extends React.Component {
  state = {
    selectedOption: null,
    current_page: 0
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  fetchMoreContent = page => {
    this.props.setPageTransactionList(page);
    this.props.fetchTransactionList(false, this.props.selectedBankAccount.Id);
  };

  onMovementClick = rowData => {
    this.props.fetchTransactionData(rowData.id);
    this.setState({ detailTransferOpen: true });
  };

  handlePageClick = (data, isDobleArrow) => {
    const finalData = data.selected != undefined ? data.selected : data;
    if (isDobleArrow == undefined) {
      if (this.props.page != finalData) {
        this.fetchMoreContent(finalData);
        this.props.hasMoreTransactionList(false, this.props.selectedBankAccount.Id);
      }
    } else {
      if (this.props.page - 1 != finalData && this.props.page != finalData) {
        this.fetchMoreContent(finalData);
        this.props.hasMoreTransactionList(false, this.props.selectedBankAccount.Id);
      }
    }

    // if(this.props.hasMore){
    //   var btn = document.getElementById("nextBtn")
    //   btn.disabled = false;
    // }else{
    //   var btn = document.getElementById("nextBtn")
    //   btn.disabled = true;
    // }
    // if((data.selected != undefined ? data.selected == 1 || data.selected == 0  : data == 1 || data == 0) ){
    //   var btn = document.getElementById("prevBtn")
    //   btn.disabled = true;
    // }else{
    //   var btn = document.getElementById("prevBtn")
    //   btn.disabled = false;
    // }
  };

  componentWillUpdate() {
    if (this.props.errorDownloadReport)
      this.props.fetchMovementsLast24HoursReportReset();
  }

  componentDidUpdate = async prevProps => {
    if (this.props.fetchMovementsLast24HoursReportSuccess) {
      exportExcel(this.props.movementsLast24HoursReportData);
      this.props.fetchMovementsLast24HoursReportReset();
    }

    if (this.props.fetchMovementsLast7DaysReportSuccess) {
      exportExcel(this.props.movementsLast7DaysReportData);
      this.props.fetchMovementsLast7DaysReportReset();
    }
  };

  componentWillUnmount() {
    this.props.fetchMovementsLast7DaysReportReset();
    this.props.fetchMovementsLast24HoursReportReset();
  }

  render() {
    console.log(this.state)
    const props = this.props;
    if (props.isLoading === true) {
      return <Loader />;
    } else {
      return (
        <InfiniteScroll
          scrollableTarget={"scrollContainer"}
          dataLength={props.movements.length}
          next={this.fetchMoreContent}
          hasMore={false}
          loader={<Loader />}
        >
          <Container className={classNames("portal-hide-scrollbars")}>
            {props.errorDownloadReport ? (
              <ErrorLabel>No se pudo exportar: {props.errorDownloadReportMessage}</ErrorLabel>
            ) : (
              <></>
            )}
            <>
              <RitzTable
                isLoading={props.isLoading || props.loadingFetchMovementsLast24HoursReport || props.loadingFetchMovementsLast7DaysReport}
                columns={[
                  {
                    title: "Fecha",
                    field: "date",
                    render: rowData => <DayJS format="DD-MM-YY HH:mm">{rowData.date}</DayJS>,
                    headerStyle: {
                      borderRadius: "10px 0px 0px 0px "
                    }
                  },
                  {
                    title: "Concepto",
                    field: "description"
                  },
                  { title: "Estado", field: "state_name" },
                  {
                    title: "Monto ",
                    field: "to",
                    render: rowData =>
                      rowData.amount > 0 ? (
                        <Charge>{formatter.format(rowData.amount)}</Charge>
                      ) : (
                        <Pay>{formatter.format(rowData.amount)}</Pay>
                      ),
                    headerStyle: {
                      borderRadius: "0px 10px 0px 0px "
                    },
                    cellStyle: {
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }
                  }

                ]}
                onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
                onSelectionChange={rows => this.onMovementClick(rows)}
                data={props.movements}
                options={{
                  headerStyle: {
                    backgroundColor: "#7dbe55",
                    color: "#FFFFFF",
                    zIndex: "0"
                  },
                  whiteSpace: "nowrap",
                  rowStyle: rowData => {
                    if (rowData.tableData.id % 2 !== 0) {
                      return { backgroundColor: "rgba(125,189,86,0.2)" };
                    }
                  }
                }}
              />
              {console.log(this.props.hasMore)}
              <Pages>
                <ExtremeButton disabled="" id="prevBtn" onClick={() => this.handlePageClick((this.props.page - 2) <= 0 ? 0 : this.props.page - 2, true)} children={"«"} />
                <ReactPaginate
                  previousLabel={'‹'}
                  nextLabel={'›'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.props.hasMore ? this.props.page + 4 : this.props.page + 1}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  forcePage={this.props.page}
                  containerClassName={'pagination2'}
                  activeClassName={'active'}
                  pageLabelBuilder={(page) => { return <div style={{display:"flex",flexDirection:"column"}}><span style={{}} >{page}</span><span style={{display:"flex",alignItems:"center",justifyContent:"center",fontSize:30+"px",marginTop: -22+"px",height: 10}}>.</span></div> }}
                />
                <ExtremeButton disabled="" id="nextBtn" onClick={() => this.handlePageClick(this.props.hasMore ? this.props.page + 2 : this.props.page)} children={"»"} />
              </Pages>
              <InputWrapper>
                <AuthWrapper
                  loading={
                    props.loadingFetchMovementsLast24HoursReport
                  }
                  onClick={() => this.props.selectedBankAccount === null ? () => false :
                    props.fetchMovementsLast24HoursReport(
                      startDate1.toISOString(),
                      new Date().toISOString(),
                      this.props.selectedBankAccount.Id
                    )
                  }
                >
                  {props.billetera.loadingFetchMovementsLast24HoursReport ? <Loader small /> : <Label>Exportar últimas 24 horas</Label>}
                </AuthWrapper>

                <AuthWrapper
                  loading={
                    props.loadingFetchMovementsLast7DaysReport
                  }
                  onClick={() => this.props.selectedBankAccount === null ? () => false :
                    props.fetchMovementsLast7DaysReport(
                      startDate7.toISOString(),
                      new Date().toISOString(),
                      this.props.selectedBankAccount.Id
                    )
                  }
                >
                  {props.billetera.loadingFetchMovementsLast7DaysReport ? <Loader small /> : <Label>Exportar últimos 7 días</Label>}
                </AuthWrapper>
              </InputWrapper>
            </>
            {this.state.detailTransferOpen ? (
              <ModalDetailTransfer onClose={() => this.setState({ detailTransferOpen: false })} />
            ) : (
              <></>
            )}
            {props.errorTransactionsList && props.TransactionsList.length === 0 ? (
              <>
                <ErrorLabel>{props.errorTransactionsList}</ErrorLabel>
              </>
            ) : (
              <></>
            )}
          </Container>
        </InfiniteScroll>
      );
    }
  }
}

const exportExcel = async data => {
  var columnsTitle = [[["Fecha", "Titular Origen", "CUIT Titular Origen", 
  "Usuario Cliente Origen", "Cod. Transacción","Medio Pago", "Marca Tarjeta", "Nro Tarjeta",
  "Monto", "Estado", "Comentarios", "Id Transaccion Billetera", "Id Banco", "Cod Autorizacion Tarjeta"]],
  [["Fecha", "Titular Destino", "CUIT Titular Destino", "Usuario Cliente Destino", "Cod. Transacción", "Medio Pago",
  "Marca Tarjeta", "Nro. Tarjeta", "Monto", "Estado", "Comentarios", "Id Transacción Billetera", "Id Banco"
  ]]
  ];;

  exportFromJsonToList(data, columnsTitle, "Movements_", ['Ingresos', 'Salidas']);
  };

const mapStateToProps = state => {
  return {
    billetera: state.billetera,
    NumberOfTransferRequest: state.billetera.NumberOfTransferRequest,
    TransactionsList: state.billetera.TransactionsList,
    errorTransactionsList: state.billetera.errorTransactionsList,
    selectedBankAccount: state.billetera.selectedBankAccount,
    isLoading: state.billetera.loadingTransactionsList,
    page: state.billetera.page,
    hasMore: state.billetera.hasMoreTransactionsList,
    movementsLast24HoursReportData: state.billetera.movementsLast24HoursReportData,
    fetchMovementsLast24HoursReportSuccess: state.billetera.fetchMovementsLast24HoursReportSuccess,
    fetchMovementsLast7DaysReportSuccess: state.billetera.fetchMovementsLast7DaysReportSuccess,
    movementsLast7DaysReportData: state.billetera.movementsLast7DaysReportData,
    errorDownloadReport: state.billetera.errorDownloadReport,
    errorDownloadReportMessage: state.billetera.errorDownloadReportMessage,
    loadingFetchMovementsLast24HoursReport: state.loadingFetchMovementsLast24HoursReport,
    loadingFetchMovementsLast7DaysReport: state.loadingFetchMovementsLast7DaysReport
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionData,
      setPageTransactionList,
      fetchTransactionList,
      fetchMovementsLast24HoursReport,
      fetchMovementsLast24HoursReportReset,
      fetchMovementsLast7DaysReport,
      fetchMovementsLast7DaysReportReset,
      hasMoreTransactionList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MovementsList);
