import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
export const Wrapper = styled.div`
  min-height: 560px;
  border-radius: 10px;
  padding: 10px;
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 33% 75%;
`

export const SecondWrapper = styled.div`
  min-height: 560px;
  padding: 10px;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
  flex-grow: 1;
  order: 1;
  align-items: center;
`

export const ListMovementsWrapper = styled.div`
  min-height: 560px;
  padding-right: 13px;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  flex-grow: 2;
  order: 1;
`

export const HeaderMovementsWrapper = styled.div`
  min-height: 20px;
  padding-right: 13px;

  max-height: 100px;
  display: flex;
  // justify-content: space-around;
  flex-direction: column;
  flex-wrap: nowrap;
`

export const RowContentWrapper = styled.div`
  min-height: 20px;
  padding-top: 20px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content:center;
`

export const AccountInfoData = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: white;
  padding: 14px;
  margin-top: 10px;
  align-items: center;
`

export const TituloAccountInfoData = styled.div`
  text-align: left;
`
export const DetalleAccountInfoData = styled.div`
  text-align: right;
  color: #2f735d;
  font-weight: 600;
  font-size: 14px;
`

export const VolverBtn = styled.a`
  float: left;
  cursor: pointer;
  padding: 1px 6px 21px;
`
export const TitleWrapper = styled.div`
  flex-grow: 2;
  order: 1;
`
export const LabelTitleWrapper = styled.div`
  flex-grow: 2;
  order: 1;
  align-self:center;
`
export const SelectWrapper = styled.div`
  flex-grow: 1;
  order: 2;
  width: 75%;
  margin-left: auto;
  margin-top:10px;
  margin: 0px 0px 0px 0px;
`

export const SelectBankAccountWrapper = styled.div`
  flex-grow: 1;
  order: 2;
  margin: 0px;
  margin-left: auto;
  width: 71.5%;
`

export const FormControlstyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: start;
`
export const Refresh = styled.img`
  width: 30px;
  order: 3;
  margin: 0% 4%;
  
:hover {
  cursor: pointer;
}
`

export const Label = styled.label`
  text-align: center;
  cursor: pointer;
  color: ${({ theme,red }) => red ? "red" : theme.colors.oceanGreen};
  font-weight: ${({red})=> red ? "800" : "400"};
`

export const LoaderWrapper = styled.div`
  min-height: 20px;
  max-height: 80px;
  padding-bottom: 40px;
  margin-top: 15px;
  background-color: ${({ theme }) => theme.colors.backgroundTransparent};
  border-color: ${({ theme }) => theme.colors.oceanGreen};
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const AuthWrapper = styled.div`
  min-height: 20px;
  max-height: 80px;
  padding: 15px 0px;
  margin-top: 15px;
  background-color: ${({ theme }) => theme.colors.backgroundTransparent};
  color: ${({ theme }) => theme.colors.oceanGreen};
  border-color: ${({ theme }) => theme.colors.oceanGreen};
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`


export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`
