import { createSelector } from 'reselect'

let mapVendors = createSelector(  
  vendors => vendors,
  vendors => 
  (vendors.map(vendor => ({
    ...vendor,
    clientName: vendor.client.name
  })) )
)
export default mapVendors