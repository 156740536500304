
import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import mapExpensasHome from '../../selectors/mapExpensasHome'
import mapNeighbourhoods from '../../selectors/mapNeighbourhoods'
import Ingresos from './Ingresos';
import Egresos from './Egresos';
import Mensajeria from './Mensajeria';
import Reportes from './Reportes';
import { fetchHomeExpenses, fetchExpensesCliente, fetchgetFileDetailClient } from '../../store/actions/expenses.actions'
const menulistItems = [
    { title: 'Ingreso', route: 'ingresos', available: true },
    { title: 'Salida', route: 'egresos', available: true },
    // { title: 'Mensajería', route: 'mensajeria', available: true },
    // { title: 'Reportes e Informes', route: 'reportes', available: true },
];

class Accesos extends React.Component {

    state = {

        view: 'ingresos'


    };


    buildNavBarItems = () => {
        const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
        return menuToRender;
    }

    switchView = (view) => {
        this.setState({ view });
    }

    render() {

        return (

            <>

                <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Accesos'>
                    {(() => {
                        switch (this.state.view) {
                            case 'ingresos':
                                return (
                                    <>
                                        <Ingresos />
                                    </>
                                );

                            case 'egresos':
                                return (
                                    <>
                                        <Egresos 
                                        
                                        
                                        
                                        />
                                    </>
                                );

                            case 'mensajeria':
                                return (
                                    <Mensajeria />
                                );

                                case 'reportes':
                                    return (
                                        <Reportes />
                                    );
                        }
                    })()}
                </NavBar>

            </>
        );
    }

}

const mapStateToProps = ({
    userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
    expenses,
}) => {
    return {
        expensesCabecera: mapExpensasHome(expenses),
        neighbourhoods: mapNeighbourhoods(neighbourhoods),

        //expensasDetails: mapExpensesHomeDetail(expenses),

        fetchExpensesClienteSuccess: expenses.fetchExpensesClienteSuccess,
        fetchExpensesClienteFail: expenses.fetchExpensesClienteFail,
        loadingfetchExpensesCliente: expenses.loadingfetchExpensesCliente,
        ExpensesCliente: expenses.ExpensesCliente,
        fileDetailClient: expenses.fileDetailClient
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        fetchHomeExpenses, fetchExpensesCliente, fetchgetFileDetailClient

    }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Accesos)
)
