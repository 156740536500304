import styled from 'styled-components';
import React from 'react';

const StyledRadioButton = styled.div`  
  width: 48px;
  height: 28px;
  border-radius: 16px;
  box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.07);
  background-color: #f4f0ea;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s;
  ${({ active }) => active && `
    justify-content: flex-end;
  `}
`
const Oval = styled.div`
  border-radius: 100%;
  width: 25px;
  height: 24px;
  background-color: #ff2424;
`


const RadioButton = props => <StyledRadioButton {...props}><Oval/></StyledRadioButton>

export default RadioButton
