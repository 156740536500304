import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import ModalDelete from "../../../components/ModalDelete";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import EventIcon from "@material-ui/icons/Event";
import SectionHeader from "../../../components/SectionHeader";
import RitzTable from "../../../components/RitzTable";
import { useHistory } from "react-router-dom";
import SearchInput from "../../../components/SearchInput";
import ModalMessage from "../../../components/ModalMessage";
import Loader from "../../../components/Loader";
import StyledSelect from "../../../components/StyledSelect";
import DetailModal from "./DetailModal";
import SchedulesModal from "./SchedulesModal";
import {
  Ahref,
  TableWrapper,
  LoaderWrapper,
  SelectWrapper,
  InputLabel,
  InputWrapper
} from "./styled.js";
import {
  fetchComboActivities,
  fetchSpaces,
  fetchComboSpacesToBlock,
  fetchComboSpacesToBlockReset,
  fetchNeighbourhoodSpaceDetail,
  createNeighbourhoodSpace,
  createNeighbourhoodSpaceReset,
  updateNeighbourhoodSpace,
  updateNeighbourhoodSpaceReset,
  deleteNeighbourhoodSpace,
  deleteNeighbourhoodSpaceReset
} from "../../../store/actions/bookings.actions";

const mapStateToProps = state => {
  const {
    isLoading,
    spaces,
    comboActivities,
    selectedComboActivities,
    loadingfetchComboActivities,
    loadingfetchSpaces,
    fetchSpacesSearchText,
    comboSpacesToBlock,
    neighbourhoodSpaceDetail,
    loadingFetchNeighbourhoodSpaceDetail,
    loadingCreateNeighbourhoodSpace,
    createNeighbourhoodSpaceSuccess,
    errorCreateNeighbourhoodSpace,
    loadingfetchComboSpaceToBlock,
    loadingUpdateNeighbourhoodSpace,
    updateNeighbourhoodSpaceSuccess,
    errorUpdateNeighbourhoodSpace,
    loadingDeleteNeighbourhoodSpace,
    deleteNeighbourhoodSpaceSuccess,
    errorDeleteNeighbourhoodSpace
  } = state.bookings;
  return {
    isLoading,
    spaces,
    loadingfetchComboActivities,
    comboActivities,
    selectedComboActivities,
    fetchSpacesSearchText,
    loadingfetchSpaces,
    comboSpacesToBlock,
    neighbourhoodSpaceDetail,
    loadingFetchNeighbourhoodSpaceDetail,
    loadingCreateNeighbourhoodSpace,
    createNeighbourhoodSpaceSuccess,
    errorCreateNeighbourhoodSpace,
    loadingfetchComboSpaceToBlock,
    loadingUpdateNeighbourhoodSpace,
    updateNeighbourhoodSpaceSuccess,
    errorUpdateNeighbourhoodSpace,
    loadingDeleteNeighbourhoodSpace,
    deleteNeighbourhoodSpaceSuccess,
    errorDeleteNeighbourhoodSpace
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchComboActivities,
      fetchSpaces,
      fetchNeighbourhoodSpaceDetail,
      createNeighbourhoodSpace,
      updateNeighbourhoodSpace,
      deleteNeighbourhoodSpace,
      deleteNeighbourhoodSpaceReset,
      createNeighbourhoodSpaceReset,
      updateNeighbourhoodSpaceReset,
      fetchComboSpacesToBlockReset
    },
    dispatch
  );

function SpacesList(props) {
  const history = useHistory();
  const {
    spaces,
    comboActivities,
    neighbourhoods,
    selectedComboActivities,
    loadingfetchSpaces,
    fetchSpacesSearchText,
    comboSpacesToBlock,
    loadingfetchComboActivities,
    neighbourhoodSpaceDetail,
    loadingFetchNeighbourhoodSpaceDetail,
    loadingCreateNeighbourhoodSpace,
    createNeighbourhoodSpaceSuccess,
    errorCreateNeighbourhoodSpace,
    loadingfetchComboSpaceToBlock,
    loadingUpdateNeighbourhoodSpace,
    updateNeighbourhoodSpaceSuccess,
    errorUpdateNeighbourhoodSpace,
    loadingDeleteNeighbourhoodSpace,
    deleteNeighbourhoodSpaceSuccess,
    errorDeleteNeighbourhoodSpace,
    selectedNeighbourhood
  } = props;

  const isLoading = loadingfetchComboActivities || loadingfetchSpaces;

  const [showDetail, setShowDetail] = useState(false);
  const [addSpace, setAddSpace] = useState(false);
  const [deleteSpace, setDeleteSpace] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showSchedulesModal, setShowSchedulesModal] = useState();

  useEffect(() => {
    if (neighbourhoods.length > 0) {
      if (!selectedNeighbourhood)
        props.setSelectedNeighbourhood(neighbourhoods[0]);
      props.fetchComboActivities(selectedNeighbourhood ? selectedNeighbourhood.value : neighbourhoods[0].value);
    }
  }, []);

  useEffect(() => {
    if (!addSpace && !showDetail && !deleteSpace && selectedNeighbourhood != null) {
      props.fetchSpaces(
        selectedNeighbourhood.value,
        selectedComboActivities,
        fetchSpacesSearchText
      );
    }
  }, [addSpace, showDetail, deleteSpace]);

  const [spaceId, setSpaceId] = useState(0);

  const onNeighbourhoodChange = async data => {
    props.setSelectedNeighbourhood(data);
    await props.fetchComboActivities(data.value);
  };

  const onActivityChange = data => {
    props.fetchSpaces(selectedNeighbourhood.value, data, fetchSpacesSearchText);
  };

  const onSearchChangeHandler = e => {
    if (e.target.value === "") {
      props.fetchSpaces(selectedNeighbourhood.value, selectedComboActivities, "");
    }
  };

  const onSearchKeyDownHandler = e => {
    if (e.keyCode === 13) {
      props.fetchSpaces(selectedNeighbourhood.value, selectedComboActivities, e.target.value);
    }
  };

  const toggleDelete = async neighbourhoodSpaceId => {
    props.deleteNeighbourhoodSpaceReset();
    setDeleteSpace(!deleteSpace);
    setSelectedId(neighbourhoodSpaceId);
  };

  const toggleShowDetail = neighbourhoodSpaceId => {
    props.updateNeighbourhoodSpaceReset();
    setShowDetail(!showDetail);
    if (!showDetail) {
      props.fetchNeighbourhoodSpaceDetail(neighbourhoodSpaceId);
    }
  };

  const toggleAddSpace = () => {
    if (!addSpace) props.fetchComboSpacesToBlockReset();
    props.createNeighbourhoodSpaceReset();
    setAddSpace(!addSpace);
  };

  const renderModalMessageSuccess = (message, callback) => (
    <ModalMessage success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalMessageFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const onCreateNeighbourhoodSpaceSubmit = async data => {
    let formattedData = {
      name: data.name,
      activity_id: data.activity.value,
      max_reservation_time: data.max_reservation_time,
      min_reservation_time:data.min_reservation_time,
      ignore_max_reservation: data.ignore_max_reservation,
      blocked_spaces_list: data.blocked_spaces
        ? data.blocked_spaces.map(blocked_space => {
          return blocked_space.value;
        })
        : [],
      neighbourhood_list: data.neighborhoods_to_provide
        ? data.neighborhoods_to_provide.map(neighbourhood => {
          return neighbourhood.value;
        })
        : [],
      neighbourhood_guid: data.neighbourhood_guid
    };
    props.createNeighbourhoodSpace(formattedData);
  };

  const onUpdateNeighbourhoodSpaceSubmit = async data => {
    let formattedData = {
      id: data.id,
      name: data.name,
      activity_id: data.activity.value,
      max_reservation_time: data.max_reservation_time,
      min_reservation_time: data.min_reservation_time,
      ignore_max_reservation: data.ignore_max_reservation,
      blocked_spaces_list:
        data.blocked_spaces && data.blocked_spaces.length > 0
          ? data.blocked_spaces.map(blocked_space => {
            return blocked_space.value;
          })
          : [],
      neighbourhood_list:
        data.neighborhoods_to_provide && data.neighborhoods_to_provide.length > 0
          ? data.neighborhoods_to_provide.map(neighbourhood => {
            return neighbourhood.value;
          })
          : [],
      neighbourhood_guid: data.neighbourhood_guid
    };
    props.updateNeighbourhoodSpace(formattedData);
  };

  const onDeleteNeighbourhoodSpaceSubmit = async () => {
    props.deleteNeighbourhoodSpace(selectedId);
  };
  const toggleSchedulesModal = spaceId => {
    setSpaceId(spaceId);
    setShowSchedulesModal(!showSchedulesModal);
  };

  var columns = [
    {
      title: "#",
      field: "id",
      cellStyle: {
        width: "1em"
      }
    },

    { title: "Nombre", field: "name" },
    { title: "Barrio", field: "neighbourhood_name" },
    { title: "Actividad", field: "activity" }
  ];

  const activityOptions = comboActivities.filter(function (value, index, arr) {
    return value.value != null;
  });
  const spaceConfiguration = (() => {
    if (props.updateNeighbourhoodSpaceSuccess) {
      return renderModalMessageSuccess("Cambios guardados correctamente.", toggleShowDetail);
    } else if (props.errorUpdateNeighbourhoodSpace) {
      return renderModalMessageFail(
        "Hubo un error al intentar realizar los cambios.",
        toggleShowDetail
      );
    }
    if (showDetail) {
      return (
        <DetailModal
          title="Configuración"
          activities={activityOptions}
          selectedActivity={selectedComboActivities}
          selectedNeighbourhood={selectedNeighbourhood}
          neighbourhoods={neighbourhoods.filter(neighbourhood => neighbourhood.value !== selectedNeighbourhood.value)}
          spacesToBlock={comboSpacesToBlock}
          onClose={toggleShowDetail}
          isNew={false}
          isLoading={loadingFetchNeighbourhoodSpaceDetail || loadingfetchComboSpaceToBlock}
          spaceDetail={neighbourhoodSpaceDetail}
          isSubmitting={loadingUpdateNeighbourhoodSpace}
          onSubmit={onUpdateNeighbourhoodSpaceSubmit}
        />
      );
    }
  })();

  const addModal = (() => {
    if (props.createNeighbourhoodSpaceSuccess) {
      return renderModalMessageSuccess("Se ha creado el espacio correctamente.", toggleAddSpace);
    } else if (props.errorCreateNeighbourhoodSpace) {
      return renderModalMessageFail("Hubo un error al intentar crear el espacio.", toggleAddSpace);
    }
    if (addSpace) {
      return (
        <DetailModal
          title="Alta de nuevo espacio"
          selectedActivity={selectedComboActivities}
          selectedNeighbourhood={selectedNeighbourhood}
          activities={activityOptions.filter(activity => !activity.is_shared)}
          neighbourhoods={neighbourhoods.filter(neighbourhood => neighbourhood.value !== selectedNeighbourhood.value)}
          onClose={toggleAddSpace}
          isNew={true}
          isSubmitting={loadingCreateNeighbourhoodSpace}
          onSubmit={onCreateNeighbourhoodSpaceSubmit}
        />
      );
    }
  })();
  const deleteModal = (() => {
    if (deleteNeighbourhoodSpaceSuccess) {
      return renderModalMessageSuccess("Se ha eliminado el espacio correctamente.", toggleDelete);
    } else if (errorDeleteNeighbourhoodSpace) {
      return renderModalMessageFail("Hubo un error al intentar eliminar el espacio", toggleDelete);
    }
    if (deleteSpace) {
      return (
        <ModalDelete
          title="Eliminar espacio"
          subTitle="¿Estás seguro que querés eliminarlo?"
          buttonMessage="Eliminar espacio"
          successMessage="¡Se ha eliminado el espacio!"
          isLoading={loadingDeleteNeighbourhoodSpace}
          onClose={toggleDelete}
          success={false}
          onSubmit={onDeleteNeighbourhoodSpaceSubmit}
        ></ModalDelete>
      );
    }
  })();

  const schedulesModal = (() =>
    showSchedulesModal ? (
      <>
        <SchedulesModal title="Horarios" spaceId={spaceId} onClose={toggleSchedulesModal} />
      </>
    ) : null)();

  const mainForm = (() => (
    <>
      <SectionHeader
        title="Espacios"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
        alignItems={"baseline"}
      >
        <InputWrapper>
          <InputLabel>{"Barrio"}</InputLabel>
          <SelectWrapper>
            <StyledSelect
              value={selectedNeighbourhood}
              onChange={value => onNeighbourhoodChange(value)}
              options={neighbourhoods}
              placeholder="Seleccione un barrio"
              isLoading={isLoading}
              isDisabled={isLoading}
            />
          </SelectWrapper>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>{"Actividad"}</InputLabel>
          <SelectWrapper>
            <StyledSelect
              value={selectedComboActivities}
              onChange={value => onActivityChange(value)}
              options={comboActivities}
              placeholder=""
              isLoading={isLoading}
              isDisabled={isLoading}
            />
          </SelectWrapper>
        </InputWrapper>
        <Button width="9.8em" loading={isLoading} onClick={() => toggleAddSpace()}>
          Agregar Espacio
        </Button>
      </SectionHeader>
      <SearchInput
        type="text"
        search={true}
        defaultValue={fetchSpacesSearchText}
        placeholder={"Buscar"}
        disabled={isLoading}
        onChange={onSearchChangeHandler}
        onKeyDown={onSearchKeyDownHandler}
      />
      <TableWrapper>
        <RitzTable
          columns={columns}
          paging={true}
          title=""
          isLoading={loadingfetchSpaces}
          options={{
            toolbar: false,
            paging: false,
            serverSide: false,
            selection: false,
            exportButton: false,
            exportAllData: false,
            exportFileName: "execution_date." + new Date().toISOString().split(".")[0],
            actionsCellStyle: { display: "flex", justifyContent: "center" },
          }}
          actions={[
            rowData => ({
              icon: () => <SettingsIcon />,
              tooltip: "Ver detalle",
              isFreeAction: false,
              onClick: () => toggleShowDetail(rowData.id),
              hidden: rowData.is_shared
            }),
            {
              icon: EventIcon,
              tooltip: "Ver Horarios",
              isFreeAction: false,
              onClick: (event, rowData) => toggleSchedulesModal(rowData.id)
            },
            rowData => ({
              icon: () => <DeleteIcon />,
              tooltip: "Eliminar",
              isFreeAction: false,
              onClick: () => toggleDelete(rowData.id),
              hidden: rowData.is_shared
            }),
          ]}
          data={spaces}
        />
      </TableWrapper>
    </>
  ))();

  return (
    <>
      {
        <>
          {!showSchedulesModal ? mainForm : null}
          {spaceConfiguration}
          {addModal}
          {deleteModal}
          {schedulesModal}
        </>
      }
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SpacesList);
