import styled from 'styled-components'

export const Container = styled.div`
    padding: 5%;
    height: auto;
    width: 40vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 10px;
    background-color: ${({ blue,theme }) => blue ? theme.colors.blueRibbon : theme.colors.white};
`
export const Status = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    background-color: ${({ background }) => background || 'white'};
    color: white;
    display: flex;
    TEXT-ALIGN: center;
    padding: 3%;
    border-radius: 20px;
    font-size: 18px;
    align-self: center;
    font-weight: 500;
`


export const Header = styled.div`
    width: 100%;
    height: 150px;
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
    border-radius: 10px;
`

export const Body = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
`

export const Title = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: #000;
    align-self: left;
`

export const Subtitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    align-self: right;
    text-align: right!important;
    color: ${props => props.inputColor || '#4a4a4a'};
`

export const DireccionLabel = styled.div`
    font-size: 15px;
    font-weight: 500;
    align-self: right;
    text-align: right!important;
    color: ${props => props.inputColor || '#4a4a4a'};
`

export const SubtitleText = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: #4a4a4a;
    align-self: right;
    text-align: right!important;
`
export const ContainerCenter = styled.div`
  align-items: center;
  display: flex;
`

export const SubtitleSpan = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: #4a4a4a;
    align-self: right;
    text-align: right;
`

export const RowItem = styled.div`
    width: 100%;
    margin-bottom: 2px;
    color: black;
    font-weight: 400;
    display: flex;
    max-height: 160px;
    
    border-bottom: 1px solid #d0cbcb;
    padding-bottom: 12px;
    justify-content: ${({ justify }) => justify ? justify : "space-between"};
`

export const RowItemNoBorder = styled.div`
    width: 100%;
    margin: 10px 0px;
    color: black;
    font-weight: 400;
    display: flex;
    padding-bottom: 12px;
    justify-content: ${({ justify }) => justify ? justify : "space-around"};
`

export const StyledButton = styled.button`
    background-color: ${({ theme,invert }) => invert ? theme.colors.white  : theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme,invert }) => invert ? theme.colors.blueRibbon  : theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    width: 45%;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    margin: 2%;
    padding: 2%;
    border: none;

    &:hover{
        border: ${({ theme }) => "solid 0.5px "+theme.colors.blueRibbon };
        transition: all 0.3s ease;
    }
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const CheckIcon = styled.img`
    width: 80px;
    color: white;
`

export const CloseIcon = styled.img`
    width: 20px;
    color: #000;
    align-self: flex-end;
    cursor: pointer;
`

export const FormContainer = styled.form`
  width: 100%;
  height: 85vh;
  max-height: 670px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 5%;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
`

export const UserImg = styled.img`
  width: ${({ small }) => (small ? 30 : 70)}px;
  height: ${({ small }) => (small ? 30 : 70)}px;
  border-radius: 50%;
`
export const RowLeft = styled.div`
  width: auto;
  display: flex;
  align-items: left;
  margin: 0%;
`
export const ContainerLabel = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5%
`

export const RowRight = styled.div`
  width: 30%;
  display: flex;
  align-items: right;
  margin: 0%;
`

export const TituloLeft = styled.h2`
  width: 100%;
  text-align: left;
  margin: 0%;
`

export const DescRight = styled.h5`
  width: 100%;
  text-align: right;
  margin: 0%;
  padding: 4px;
`



export const TituloLeftSub = styled.h3`
  width: 100%;
  text-align: left;
  margin: 0%;
  font-weight: 500;
  font-size: 15px;
  color: #1873f3;
`

export const DescRightSub = styled.h5`
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  margin: 0%;
  padding: 4px;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
`

export const InputWrapperMargin = styled.div`
  width: ${({ width }) => width || 100}%;
  margin-right: 25px;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  padding: 10px 0;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: unset;
  margin-left: 95%;
  cursor: pointer;

`

export const ErrorMessage = styled.label`
  margin-right: auto;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.red};
`