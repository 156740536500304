import React from 'react';
import styled from 'styled-components';
import MaterialTable from 'material-table';
import Close from '../assets/icons/close.png';

const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
`
const SideModalContainer = styled.div`
  z-index: 3;
  height: 100%;
  width: 420px;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`
const ModalHeader = styled.div`
  padding: 20px;
  font-size: 22px;
  min-height: 15%;
  font-weight: bold;
  background-color: #001441;
  position: relative;
  color: #ffffff;
  display: flex;
  align-items: center; 
`
const CloseModal = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  background-color: #001441;
  padding: 10px 15px;
  border-radius: 100% 0px 0px 100%;
  width: 40px;
  display: flex;
  left: -40px;
  box-sizing: border-box;
  cursor: pointer;
`
const ModalBody = styled.div`
  padding: 20px;
  flex: 1;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
`
const KitList = styled.div`
  flex: 1;
`
const Quantity = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2.3px;
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CloseIcon = styled.img`
  width: 21px;
  height: 21px;
`
class KitDetails extends React.Component {
  render() {
    return (
      <GrayedArea>
        <SideModalContainer>
          <ModalHeader><CloseModal onClick={() => this.props.onClose()}><CloseIcon src={Close} /></CloseModal>Party Kit</ModalHeader>
          <ModalBody>
            <p style={{marginBottom: '5px'}}>What's in the Kit</p>
            <KitList id={'KitList'}>
              <MaterialTable 
                options={{
                  draggable: false,
                  toolbar: false,
                  paging: false,
                  sorting: false,
                  headerStyle: { display: 'none'},
                  rowStyle: {
                  
                  }
                }} 
                columns={[
                  { title: '', field: 'name', cellStyle: {border: 'solid 1px #e1e1e1', borderRight: 'none', borderRadius: '6px 0px 0px 6px'}},
                  { title: '', field: 'price', cellStyle: {borderTop: 'solid 1px #e1e1e1', borderBottom: 'solid 1px #e1e1e1', color: '#ff2424', fontWeight: '600'}},
                  { title: '', field: 'quantity', cellStyle: {border: 'solid 1px #e1e1e1', borderLeft: 'none', borderRadius: '0px 6px 6px 0px'}}
                ]}
                data={[
                  {
                    name: 'Saint Geron Mineral Water',
                    price: '$30',
                    quantity: <Quantity>5</Quantity>,
                  },
                  {
                    name: 'Frito-lay Classic Pack',
                    price: '$45',
                    quantity: <Quantity>5</Quantity>,
                  },
                  {
                    name: 'Premium Ginger Beer',
                    price: '$60',
                    quantity: <Quantity>5</Quantity>,      
                  },
                  {
                    name: 'Diet Coke Soda',
                    price: '$60',
                    quantity: <Quantity>5</Quantity>,      
                  },
                  {
                    name: 'Pringles Sour & Cream',
                    price: '$60',
                    quantity: <Quantity>5</Quantity>,      
                  },
                  {
                    name: 'Tostitos Cantina Thin & Crispy Tortilla Chips',
                    price: '$60',
                    quantity: <Quantity>5</Quantity>,      
                  }
                ]}
              />
            </KitList>
          </ModalBody>
        </SideModalContainer>
      </GrayedArea>
    ); 
  }
}

export default KitDetails

