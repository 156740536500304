import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from '../../../components/Modal'
import CrossIcon from '../../../assets/icons/close.png'
import Button from '../../../components/Button';
import { createNewEvent, createNewArticle, editEvent, editArticle } from '../../../store/actions/feed.actions'
import { 
  ErrorLabel,
  ButtonWrapperPop,
  StyledButton,
  CancelButton,
  Container,
  LabelChecked,
  InputLabel,
  CrossImage,
  Barrio,
  ResetIcon
} from '../NewPublication/styled'

class PopUp extends React.Component {
  state={
    sendsnotification: false
  }

  render() {
    return (
      <Modal onClose={() => this.props.onClose(false)}>
      <Container>
       <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
        <InputLabel>Esta seguro que quiere publicar en el/los barrios {this.props.neighbourhoods.map((barrio)=> <Barrio>{barrio.label}</Barrio>)}</InputLabel>
        <InputLabel>Esta publicación enviara PUSH/SMS/LLAMADOS a los usuarios</InputLabel>
       <ButtonWrapperPop> 
            <CancelButton onClick={() => this.props.onClose(false)}>
               Cancelar
            </CancelButton>
            <Button medium loading={this.props.isSubmittingEvent || this.props.isSubmittingArticle} onClick={() => this.props.onHandleSubmit()} > Publicar </Button>
        </ButtonWrapperPop>
        <ErrorLabel>
            {this.props.errorNew ? this.props.errorNew : "" }
            {this.props.submitErrorArticle ? this.props.submitErrorArticle : "" }
        </ErrorLabel>
        </Container>
      </Modal>
    )
  }
}

const mapStateToProps = ({ userInfo: { neighbourhoods, selectedNeighbourhood }, feed }) => ({
  isSubmittingEvent: feed.loadingNewEvent,
  submitErrorEvent: feed.errorNewEvent,
  isSubmittingArticle: feed.loadingNewArticle,
  submitErrorArticle: feed.errorNewArticle,
  errorNew: feed.errorNewEventMessage || feed.errorNewArticleMessage,
  selectedNeighbourhood,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createNewEvent, createNewArticle, editEvent, editArticle }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopUp)
