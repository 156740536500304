import { createSelector } from 'reselect'


const whatMonth = (date) => {
  var date = new Date(date);
  var dateFormat = date.getMonth() + 1;
  switch (dateFormat) {
    case 1:
      return "Enero " + date.getFullYear()
    case 2:
      return "Febrero " + date.getFullYear()
    case 3:
      return "Marzo " + date.getFullYear()
    case 4:
      return "Abril " + date.getFullYear()
    case 5:
      return "Mayo " + date.getFullYear()
    case 6:
      return "Junio " + date.getFullYear()
    case 7:
      return "Julio " + date.getFullYear()
    case 8:
      return "Agosto " + date.getFullYear()
    case 9:
      return "Septiembre " + date.getFullYear()
    case 10:
      return "Octubre " + date.getFullYear()
    case 11:
      return "Noviembre " + date.getFullYear()
    case 12:
      return "Diciembre " + date.getFullYear()

    default:
      return null;
  }
}

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapExportExpensas = createSelector(
  expensas => expensas,
  expensas =>
    expensas ? (expensas.map(expensa => ({
      month: whatMonth(expensa.period_start_date),
      amount: expensa.amount,
      neighborhood_name: expensa.neighborhood_name,
      debit_account_bank_name: expensa.debit_account_bank_name,
      external_payment_code: expensa.external_payment_code,
      motivo_rechazo: expensa.motivo_rechazo,
      payment_date: expensa.payment_date,
      status: expensa.status_name
    }))) : (null)
)
export default mapExportExpensas
