import React from 'react';
import SideWindow from '../../components/SideWindow';
import StatusBadge from '../../components/StatusBadge';
import {
  Column,
  Row,
  Br,
  VehicleInformation,
  Header,
  Body
} from './styled';

const ResidentDetail = (props) => {
  console.log("props padron detyalle", props)
  return (
    <SideWindow
      headerBackgroundColor={'#30735f'}
      title={'Información del propietario'} onClose={() => props.onClose('memberDetails')}>
      <Column>
        <Row>
          <img src={props.rowData.avatar} alt={props.rowData.fullname} />
          <h1>{props.rowData.fullname}</h1>
        </Row>
        <Row>
          <Column flex={1}>
            <span>Lote</span>
            <span>{props.rowData.subtitle}</span>
          </Column>
        </Row>
        <Br />
        <Row>
          <Column flex={1}>
            <span>DNI</span>
            <span>{props.rowData.dni}</span>
          </Column>
          <Column flex={1}>
            <span>Numero de telefono</span>
            <span>{props.rowData.phone_number}</span>
          </Column>
        </Row>
        <Row>
          <Column flex={1}>
            <span>Mail</span>
            <span>{props.rowData.email}</span>
          </Column>
        </Row>
      </Column>
    </SideWindow>
  );
}

export default ResidentDetail