import React, { Component } from 'react';
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import Spinner from '@material-ui/core/CircularProgress'

import {
    Container,
    Header,
    RowItem,
    Body,
    Title,
    StyledButton,
    LabelStyled,
    UserImg,
    CloseIcon,
    ErrorLabel,
    ButtonWrapper
} from './styled'


class ModalInfo extends Component {
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose()
        }
    }

    render() {
        const invitation = this.props.accesos ? this.props.accesos.getGuestInvitation : null;
        return (
            <Modal style={{ width: '30%' }}>
                <h3>Acceso autorizado</h3>
                {invitation ?
                    <Body>
                        <Title>Datos del Invitado</Title>
                        <RowItem>
                            <label style={{ flexGrow: 2 }}>Invitado</label>
                            <LabelStyled>{invitation.name}</LabelStyled>
                            <UserImg src={invitation.avatar} />
                        </RowItem>
                        {invitation.car ? (
                            <>
                                <RowItem>
                                    <label>Patente</label>
                                    <LabelStyled>{invitation.car.vehicle_license_plate}</LabelStyled>
                                </RowItem>
                                <RowItem>
                                    <label>Marca / Modelo </label>
                                    <LabelStyled>{invitation.car.vehicle_brand_name + ' ' + invitation.car.vehicle_model_name}</LabelStyled>
                                </RowItem>
                                <RowItem>
                                    <label>Seguro</label>
                                    <LabelStyled>
                                        {invitation.insurance.insurance_expire_date}
                                    </LabelStyled>
                                </RowItem>
                            </>
                        ) : (
                                <></>
                            )}
                        <RowItem>
                            <label>Lote</label>
                            <LabelStyled>
                                {invitation.funcional_unit.property_code}
                            </LabelStyled>
                        </RowItem>
                        <ButtonWrapper>
                            <Button  onClick={() => this.props.editMode()}>
                                Actualizar datos
                        </Button>
                            <Button onClick={() => this.props.checkin(true)}>
                                Permitir ingreso
                        </Button>
                        </ButtonWrapper>

                        {this.props.error ? (<ErrorLabel>{this.props.error}</ErrorLabel>) : (<></>)}
                        {this.props.loading ? ( <Spinner />) : (<></>)}

                    </Body>
                    :
                    <Spinner />

                }
            </Modal>
        )
    }
}

export default ModalInfo