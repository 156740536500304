/* eslint-disable react/prop-types */
import Button from "../../../components/Button";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import ActivityModal from "./ActivityModal";
import ActivityRules from "./ActivityRules";
import TyCModal from "./TyCModal";
import SectionHeader from "../../../components/SectionHeader";
import { TableWrapper, SelectWrapper, InputLabel, InputWrapper, MainContainer } from "./styled.js";
import RitzTable from "../../../components/RitzTable";
import { connect } from "react-redux";
import StyledSelect from "../../../components/StyledSelect";
import ModalMessage from "../../../components/ModalMessage";
import SearchInput from "../../../components/SearchInput";
import ModalDelete from "../../../components/ModalDelete";
import DeleteIcon from "@material-ui/icons/Delete";
import RulesIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { base64FormatterAplication } from "../../../utils/base64formatter";
import {
  fetchBookingActivities,
  fetchBookingActivity,
  createBookingActivity,
  createBookingActivityReset,
  updateBookingActivity,
  updateBookingActivityReset,
  deleteBookingActivity,
  deleteBookingActivityReset,
  createTyC,
  createTyCReset
} from "../../../store/actions/bookings.actions";

const mapStateToProps = state => {
  const {
    isLoading,
    bookingActivities,
    bookingActivity,
    fetchActivitiesSearchText,
    createBookingActivitySuccess,
    deleteBookingActivitySuccess,
    updateBookingActivitySuccess,
    createTyCSuccess,
    loadingFetchBookingActivities,
    loadingCreateBookingActivity,
    loadingDeleteBookingActivity,
    loadingUpdateBookingActivity,
    loadingFetchBookingActivity,
    loadingCreateTyC,
    errorCreateBookingActivity,
    errorUpdateBookingActivity,
    errorDeleteBookingActivity,
    errorCreateTyC
  } = state.bookings;

  return {
    isLoading,
    bookingActivities,
    bookingActivity,
    fetchActivitiesSearchText,
    createBookingActivitySuccess,
    deleteBookingActivitySuccess,
    updateBookingActivitySuccess,
    createTyCSuccess,
    loadingFetchBookingActivities,
    loadingCreateBookingActivity,
    loadingDeleteBookingActivity,
    loadingUpdateBookingActivity,
    loadingFetchBookingActivity,
    loadingCreateTyC,
    errorCreateBookingActivity,
    errorUpdateBookingActivity,
    errorDeleteBookingActivity,
    errorCreateTyC
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchBookingActivities,
      fetchBookingActivity,
      createBookingActivity,
      createBookingActivityReset,
      updateBookingActivity,
      updateBookingActivityReset,
      deleteBookingActivity,
      deleteBookingActivityReset,
      createTyC,
      createTyCReset
    },
    dispatch
  );

function ActivitiesList(props) {
  const {
    neighbourhoods,
    bookingActivities,
    bookingActivity,
    fetchActivitiesSearchText,
    errorCreateBookingActivity,
    errorUpdateBookingActivity,
    errorDeleteBookingActivity,
    errorCreateTyC,
    deleteBookingActivitySuccess,
    updateBookingActivitySuccess,
    createBookingActivitySuccess,
    createTyCSuccess,
    loadingFetchBookingActivities,
    loadingCreateBookingActivity,
    loadingDeleteBookingActivity,
    loadingUpdateBookingActivity,
    loadingFetchBookingActivity,
    loadingCreateTyC,
    selectedNeighbourhood
  } = props;

  const [addActivity, setAddActivity] = useState(false);
  const [editActivity, setEditActivity] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [activityName, setActivityName] = useState(false);
  const [deleteActivity, setDeleteActivity] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [isSharedActivity, setIsSharedActivity] = useState(false);
  const [addTyC, setAddTyC] = useState(false);
  const [deleteTyC, setDeleteTyC] = useState(false);

  useEffect(() => {
    if (neighbourhoods.length > 0) {
      if (!selectedNeighbourhood)
        props.setSelectedNeighbourhood(neighbourhoods[0]);
      props.fetchBookingActivities(selectedNeighbourhood ? selectedNeighbourhood.value : neighbourhoods[0].value, "");
    }
  }, []);

  useEffect(() => {
    if (
      !addActivity &&
      !editActivity &&
      !deleteActivity &&
      !addTyC &&
      selectedNeighbourhood != null
    ) {
      props.fetchBookingActivities(selectedNeighbourhood.value, fetchActivitiesSearchText);
    }
  }, [addActivity, editActivity, deleteActivity, addTyC]);

  useEffect(() => {

    if(!addTyC && deleteTyC){
      setDeleteTyC(false);
    }

  }, [deleteTyC])

  const onNeighbourhoodChange = async data => {
    props.setSelectedNeighbourhood(data);
    await props.fetchBookingActivities(data.value, fetchActivitiesSearchText);
  };

  const onSearchChangeHandler = e => {
    if (e.target.value === "") {
      props.fetchBookingActivities(selectedNeighbourhood.value, "");
    }
  };

  const onSearchKeyDownHandler = e => {
    if (e.keyCode === 13) {
      props.fetchBookingActivities(selectedNeighbourhood.value, e.target.value);
    }
  };

  const renderModalActivitySuccess = (message, callback) => (
    <ModalMessage success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalActivityFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  //Add Activity
  const toggleAddActivity = async () => {
    props.createBookingActivityReset();
    setAddActivity(!addActivity);
  };

  const onCreateActivitySubmit = async data => {
    let formattedData = {
      description: data.description,
      neighbourhood_guid: data.neighbourhood
        ? data.neighbourhood[0]
          ? data.neighbourhood[0]
          : data.neighbourhood.value
        : selectedNeighbourhood.value,
      validation_by_property: data.validationByProperty
    };
    props.createBookingActivity(formattedData);
  };

  const addModal = (() => {
    if (createBookingActivitySuccess) {
      return renderModalActivitySuccess(
        "Se ha creado la actividad correctamente.",
        toggleAddActivity
      );
    } else if (errorCreateBookingActivity) {
      return renderModalActivityFail("Hubo un error al crear la actividad.", toggleAddActivity);
    }
    if (addActivity) {
      return (
        <ActivityModal
          title="Alta de nueva actividad"
          isReadOnly={false}
          onClose={toggleAddActivity}
          onSubmit={onCreateActivitySubmit}
          isLoading={loadingCreateBookingActivity}
        />
      );
    }
  })();

  //Edit Activity
  const toggleEdit = async activityId => {
    setEditActivity(!editActivity);
    props.updateBookingActivityReset();

    if (!editActivity) {
      await props.fetchBookingActivity(activityId);
    }
  };

  const onEditActivitySubmit = async data => {
    let formattedData = {
      id: data.id,
      description: data.description,
      neighbourhood_guid: data.neighbourhood
        ? data.neighbourhood[0]
          ? data.neighbourhood[0]
          : data.neighbourhood.value
        : selectedNeighbourhood.value,
      validation_by_property: data.validationByProperty
    };

    props.updateBookingActivity(formattedData);
  };

  const editModal = (() => {
    if (updateBookingActivitySuccess) {
      return renderModalActivitySuccess("Se ha editado la actividad correctamente.", toggleEdit);
    } else if (errorUpdateBookingActivity) {
      return renderModalActivityFail("Hubo un error al editar la actividad.", toggleEdit);
    }
    if (editActivity) {
      return (
        <ActivityModal
          title="Edición de actividad"
          onClose={toggleEdit}
          onSubmit={onEditActivitySubmit}
          isLoading={loadingFetchBookingActivity}
          bookingActivity={bookingActivity}
        />
      );
    }
  })();

  //Delete Activity
  const toggleDelete = selectedId => {
    props.deleteBookingActivityReset();
    setSelectedId(selectedId); 
    setDeleteActivity(!deleteActivity);
  };

  const onDeleteActivitySubmit = async () => {
    await props.deleteBookingActivity(selectedId);
  };

  const deleteModal = (() => {
    if (deleteBookingActivitySuccess) {
      return renderModalActivitySuccess(
        "Se ha eliminado la actividad correctamente.",
        toggleDelete
      );
    } else if (errorDeleteBookingActivity) {
      return renderModalActivityFail("Hubo un error al eliminar la actividad.", toggleDelete);
    }
    if (deleteActivity) {
      return (
        <ModalDelete
          title="Eliminar actividad"
          adviceMessage="*Se eliminarán las reservas activas"
          subTitle="¿Estás seguro que querés eliminarla?"
          buttonMessage="Eliminar actividad"
          isLoading={loadingDeleteBookingActivity}
          onClose={toggleDelete}
          success={false}
          onSubmit={onDeleteActivitySubmit}
        />
      );
    }
  })();

  //Términos y Condiciones
  const toggleTyC = async (selectedId, isShared) => {
    setIsSharedActivity(isShared);
    setSelectedId(selectedId);
    props.createTyCReset();

    if (!addTyC) {
      await props.fetchBookingActivity(selectedId);
    }

    setAddTyC(!addTyC);
    setDeleteTyC(!deleteTyC);
  };

  const onCreateTyC = async data => {
    console.log(data);
    let formattedData = {
      file_pair: {
        file_base64: data.tyc != null ? base64FormatterAplication(data.tyc.image_base_64) : null,
        extension: data.tyc != null ? data.tyc.extension : null
      }
    };

    if (data.tyc == null) {
      setDeleteTyC(!deleteTyC);
    }

    
    props.createTyC(selectedId, formattedData);
  };

  const tyCModal = (() => {
    if (createTyCSuccess) {
      if (!deleteTyC) {
        return renderModalActivitySuccess(
          "Se han eliminado los Términos y Condiciones correctamente.",
          toggleTyC
        );
      }
      return renderModalActivitySuccess(
        "Se han agregado los Términos y Condiciones correctamente.",
        toggleTyC
      );
    } else if (errorCreateTyC) {
      if (!deleteTyC) {
        return renderModalActivityFail(
          "Hubo un error al eliminar los Términos y Condiciones.",
          toggleTyC
        );
      }
      return renderModalActivityFail(
        "Hubo un error al agregar los Términos y Condiciones.",
        toggleTyC
      );
    }
    if (addTyC) {
      return (
        <TyCModal
          title="Términos y Condiciones"
          onClose={toggleTyC}
          onSubmit={onCreateTyC}
          isSubmitting ={ loadingCreateTyC }
          isLoading={loadingFetchBookingActivity}
          isSharedActivity = {isSharedActivity}
          bookingActivity={bookingActivity}
        />
      );
    }
  })();

  //Reglas
  const toggleRules = (selectedId, activityName) => {
    setSelectedId(selectedId);
    setActivityName(activityName);
    setShowRules(!showRules);
  };

  const activityRules = (() => {
    if (showRules) {
      return (
        <ActivityRules activityId={selectedId} activityName={activityName} onClose={toggleRules} />
      );
    }
  })();

  var columns = [
    { title: "#", field: "id", cellStyle: { width: "1em" } },
    { title: "Actividad", field: "description" },
    { title: "Barrio", field: "client_name" }
  ];

  const main = (() => {
    if (!showRules)
      return (
        <>
          <SectionHeader
            title="Actividades"
            width="100%"
            fontWeight={"bold"}
            justifyContent={"space-between"}
          >
            <InputWrapper>
              <InputLabel fontWeight={"bold"}>{"Barrio"}</InputLabel>
              <SelectWrapper>
                <StyledSelect
                  value={selectedNeighbourhood}
                  onChange={value => onNeighbourhoodChange(value)}
                  options={neighbourhoods}
                  placeholder="Seleccione un barrio"
                  isLoading={loadingFetchBookingActivities}
                  isDisabled={loadingFetchBookingActivities}
                />
              </SelectWrapper>
            </InputWrapper>
            <Button
              width="9.8em"
              loading={loadingFetchBookingActivities}
              onClick={() => toggleAddActivity()}
            >
              Agregar Actividad
            </Button>
          </SectionHeader>
          <SearchInput
            type="text"
            search={true}
            defaultValue={fetchActivitiesSearchText}
            placeholder={"Buscar"}
            disabled={loadingFetchBookingActivities}
            onChange={onSearchChangeHandler}
            onKeyDown={onSearchKeyDownHandler}
          />
          <TableWrapper>
            <RitzTable
              columns={columns}
              title=""
              isLoading={loadingFetchBookingActivities}
              options={{
                toolbar: false,
                paging: true,
                serverSide: false,
                selection: false,
                exportButton: false,
                exportAllData: false,
                exportFileName: "execution_date." + new Date().toISOString().split(".")[0]
              }}
              actions={[
                rowData => ({
                  icon: EditIcon,
                  tooltip: "Editar",
                  isFreeAction: false,
                  onClick: (event, rowData) => toggleEdit(rowData.id),
                  hidden: rowData.is_shared
                }),
                rowData => ({
                  icon: InsertDriveFileIcon,
                  tooltip: "Términos y Condiciones",
                  isFreeAction: false,
                  onClick: (event, rowData) => toggleTyC(rowData.id,rowData.is_shared)
                }),
                rowData => ({
                  icon: RulesIcon,
                  tooltip: "Configurar Reglas",
                  isFreeAction: false,
                  onClick: (event, rowData) => toggleRules(rowData.id, rowData.description),
                  hidden: rowData.is_shared
                }),
                rowData => ({
                  icon: DeleteIcon,
                  tooltip: "Eliminar",
                  isFreeAction: false,
                  onClick: (event, rowData) => toggleDelete(rowData.id),
                  hidden: rowData.is_shared
                })
              ]}
              data={bookingActivities}
            />
          </TableWrapper>
        </>
      );
  })();

  return (
    <>
      {main}
      {addModal}
      {editModal}
      {deleteModal}
      {tyCModal}
      {activityRules}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList);
