
import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import MenuItems from '../../../components/MenuItems';
import RitzTable from '../../../components/RitzTable';
import SectionHeader from '../../../components/SectionHeader'
import Button from '../../../components/Button';
import Input from '../../../components/Input';

import mapSecurityMembers from '../../../selectors/mapSecurityMembers'

import NewStuff from './NewStuff'

import { fetchPadron, fetchPropietaryFamily, fetchPropietaryVehicle, fetchSecurityStaff } from '../../../store/actions/padron.actions'
import { setNeighbourhood } from '../../../store/actions/userInfo.actions';
import Select from 'react-select';
import { Label, SelectWrapper } from '../styled';


const menulistItems = [
    { title: 'Lista de propietarios', route: 'propietarios', available: true },
    { title: 'Personal de Seguridad', route: 'seguridad', available: true },
];

class PadronSeguridad extends React.Component {

    state = {
        newSecurityStuff: false, 
        search: "",
    };

    searchHandleChange = (event) => {
        this.setState({ search: event.target.value });
    }

    toggleState = (state) => {
        this.setState({ [state]: !this.state[state] });
    }

    buildNavBarItems = () => {
        const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
        return menuToRender;
    }

    handleChange = (neighbourhoodSelected) => {
        this.props.setNeighbourhood(neighbourhoodSelected)
        this.setState({ neighbourhoodSelected: neighbourhoodSelected });
        this.props.fetchPadron()
    }

    componentDidMount() {
        this.props.fetchSecurityStaff()

    }
    switchView = (view) => {
        this.setState({ view });
    }

    render() {

        return (
            <>
                <SectionHeader title='Personal de Seguridad'>
                    <Button medium onClick={() => this.toggleState('newSecurityStuff')}>Agregar</Button>
                </SectionHeader>
                <RitzTable
                    paging={true}


                    columns={[
                        { title: 'Nombre Completo', field: 'fullname', cellStyle: { borderRadius: '6px 0px 0px 6px' } },
                        { title: 'Barrio', field: 'neighbourhoodname' },
                        { title: 'DNI', field: 'email' },
                        { title: 'Email', field: 'email' },
                        { title: 'Telefono', field: 'phone_number' },
                        { title: 'Usuario Plataforma', field: 'username' },



                    ]}
                    options={{
                        toolbar: true,
                        selection: false,
                        search: false,
                        paging: true,
                        serverSide: false,
                        exportButton: true,
                        exportAllData: true,
                        exportFileName: "reporte." + (new Date().toISOString().split('.')[0]),
                    }}

                    isLoading={this.props.loadingStuff}
                    data={this.props.staffList}

                />
                {this.state.newSecurityStuff && <NewStuff onClose={() => this.toggleState("newSecurityStuff")} />}
            </>
        )


    }
}



const mapStateToProps = ({
    userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
    padron,
}) => {
    return {
        staffList: mapSecurityMembers(padron.staffList),
        loadingStuff: padron.loadingStuff


    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators({

        fetchSecurityStaff
    }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(PadronSeguridad)
)
