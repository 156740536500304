import styled from 'styled-components';

export const Message = styled.div`
    width: 100%;
    padding: ${({ responsive }) => responsive ?  "15" : "0"}%;
    margin: ${({ responsive }) => responsive ?  "0" : "3"}%;
    font-size: ${({ responsive }) => responsive ?  "20" : "30"}px;
`

export const Phone = styled.a`
    width: 90%;
    margin: ${({ responsive }) => responsive ?  "5" : "3"}%;
    font-size: ${({ responsive }) => responsive ?  "20" : "30"}px;
    text-align: center;
    color: green;
`
