import styled from 'styled-components';

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const Imagen = styled.img`
    cursor: pointer;
    padding-right: inherit;
`

export const TableWrapper = styled.div`
    position:relative;
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`

export const LabelSection = styled.div`
  display: flex;
  align-items: flex-start;
`

export const NotificationTypesLabel = styled.div`
  color: #598f7f;
  background-color: #e0eae7;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  margin: 2px;
  width:min-content;
  border-radius:10px;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: flex-start;
  align-items: center;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  margin-right: 10px;
`

export const SelectWrapper = styled.div`
  width: 300px;
  font-weight: normal;
`