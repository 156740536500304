import styled from 'styled-components'
import theme from "../../../../config/theme"
export const FormContainer = styled.div`
  width: 25%;
  padding: 10px 15px;
  position: absolute;
  z-index:999;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: auto;

`

export const Row = styled.div`
width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ error }) => (error ? 0 : 10)}px;
`
export const FlexWrapper = styled.div`
width: ${({ width }) => width || 100}%;
  display: ${({ hidden }) => hidden ? 'hidden' : 'flex'};
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const InputWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin*2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  padding-bottom: 10px;
`

export const ErrorLabel = styled.label`
  text-align:${({textAlign})=>textAlign || 'left'};
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`


export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin:  ${({ margin }) => margin || 0.5}em;
`
export const SpinerWrapper = styled.div`
  padding-top:4em;
  padding-bottom:4em;
  .react-spinner-material {
    margin:auto;
    border-color: ${({ theme }) => theme.colors.midGray} !important;
    border-width:4px !important;
  }
`

export const ResetIcon = styled.img`
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
`

export const LoaderWrapper = styled.div`
    text-align:center;
    margin: auto;
`


export const SelectWrapper = styled.div`
width: 200px;
font-weight: normal;
`


export const Close = styled.img`
  width: auto;
  align-self:right;
  cursor: pointer;
  margin-bottom:10px;

`

export const GrayedArea = styled.div`
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'}; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom:5%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
`

export const TitleModal = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-weight: 500;
  font-size: 20px;
  width: 80%;
  text-align: left;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '10px'};  
`