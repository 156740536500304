import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 40px;
    justify-content: space-around;
`
export const StyledParagraph = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 40px;
    justify-content: space-around;
    font-size: 1.3em;
`

export const CloseButton = styled.button`
  background-color: #30735f;
  color: white;
  border: solid 1px #30735f;
  box-sizing: border-box;
  font-weight: normal;
  cursor: pointer;
  text-align: center;  
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Roboto, sans-serif;
  align-items: center;
  font-size: 1.3em;
  width: 20%
`

