import React from 'react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import FileInput from '../../../components/InputFile'

import NoFilePdf from '../../../assets/icons/upload-pdf.svg'
import NoFileExcel from '../../../assets/icons/upload-excel.svg'

import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'

class ModalAddItem extends React.Component {

  state = {
    errorFile: false,
    loadingFile: false
  }

  componentWillUnmount() {
    this.setState({ loadingFile: false, errorFile: false })
  }

  getInitialValues = () => ({
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      file: Yup.string().required('El campo nombre es requerido')
    })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <InputWrapper>
          <FileInput onChange={file => setFieldValue('file', file)} pdf={true} nofile={NoFilePdf} />
          {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper>
          <FileInput onChange={file => setFieldValue('file', file)} nofile={NoFileExcel} />
          {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {this.props.error || this.state.errorFile ? (
        <ButtonWrapper>
          <ErrorLabel>Error al cargar el archivo,verifique si es el correcto</ErrorLabel>
        </ButtonWrapper>
      ) : (<> </>)}
      {this.props.loading ?
        (
          <ButtonWrapper>
            Cargando
          </ButtonWrapper>
        ) :
        (
          <ButtonWrapper>
            <Button medium type={'submit'} width={'180px'}>Subir Productos</Button>
          </ButtonWrapper>
        )}
    </FormContainer>
  )

  handleSubmit = async data => {
    this.setState({ loadingFile: true })
    try {
      let fileinBase64 = btoa(data.file)
      await this.props.onChange(fileinBase64)
      if (!this.props.error) this.props.onOpenResult(true)
      if (!this.props.error) this.props.onClose(true)
      if (!this.props.error) this.props.openPeriod(0)
    } catch (e) {
      this.setState({ errorFile: true })
    }
  }

  render() {
    return (
      <Modal onClose={() => this.props.close()}  height={'min-height'} widht={'430px'}>
        <div style={{ textAlign: 'center' }}>
          <h2>Subir Productos</h2>
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={false}
            validationSchema={this.getSignUpSchema()}
            onSubmit={this.handleSubmit}
            render={e => this.getFormContent(e)}
          />
        </div>
      </Modal>
    )
  }
}

export default ModalAddItem