import React from 'react';
import SectionHeader from '../../../components/SectionHeader';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCreateTransactionRequest, fetchGetNumberOfTransferPending, fetchBalance } from '../../../store/actions/billetera.actions'
import FirstStep from "./FirstStep/index"
import SecondStep from "./SecondStep/index"
import ThirdStep from "./ThirdStep/index"
import FourStep from "./FourStep/index"
import {
  FormContainer, Row, PaperLayout, InputWrapper, Label, InputLabel, ErrorLabel
} from './styled';


class GenerarQR extends React.Component {

  state = {
    completed: false,
    step: {
      name: 'Creation',
      number: 1,
      percentage: 30,
    }
  }

  setDestinatario = async (person) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        DestinationBankAccount: {
          Id: person.Id,
          Cvbu: person.Cvbu,
          BankId: person.BankId,
          BankName: person.BankName,
          Number: person.Number,
          IsMiiiiClient: person.IsMiiiiClient,
          HolderName: person.HolderName,
          HolderCuit: person.HolderCuit,
          UserName: person.ClientAppUserName,
          Alias: person.Alias,
          Currency: 'ARS',
        },
        SourceBankAccount: {
          Id: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.Id : null,
          Alias: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.alias : null,
          BankId: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.BankId : null,
          Cvbu: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.Cvbu : null,
          HolderCuit: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.HolderCuit : null,
          HolderName: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.HolderName : null,
          Number: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.Number : null,
          IsMiiiiClient: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.IsMiiiiClient : null,
          originClientUserName: this.props.userInfo.username,
          Currency: 'ARS',
        },
        //Si las dos cuentas son MIII es Inner, si no.. outgoing
        type: this.props.billetera.selectedBankAccount ? person.IsMiiiiClient === this.props.billetera.selectedBankAccount.IsMiiiiClient || person.IsMiiiiClient === true ? 'INNER_TRANSACTION' : 'OUTGOING_DEBIT' : null,
        Description: ' ',
      }
    }))
  }
  updateTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        Amount: transaction.amount,
        ExecutionDate: transaction.date,
        Concept: transaction.concepto.codigo,
        Currency: 'ARS',
      }
    }))
  }

  NextStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number + 1,
        percentage: prevState.step.percentage + 30
      }
    }))
  }
  PreviousStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number - 1,
        percentage: prevState.step.percentage - 30
      }
    }))
  }

  createTransactionRequest = (toggleSendMail) => {
    let formattedBody = this.state.transaction;
    let decimal = formattedBody.Amount.toString().split(",")
    decimal[0] = decimal[0].split('.').join('')
    formattedBody.Amount = `${decimal[0]}.${decimal[1]==''||decimal[1]==undefined? "00":decimal[1]}`
    this.props.fetchCreateTransactionRequest(formattedBody)
    this.props.fetchBalance()
    //si se confirmo la transferencia mostrar ok
    this.NextStep()
  }

  render() {

    return (
      <>
        <SectionHeader title='Nueva transferencia' />
        {this.state.completed === false ? (

          <PaperLayout Step={this.state.step}>
            {(() => {
              switch (this.state.step.number) {
                case 1:
                  return <FirstStep
                    NextStep={() => this.NextStep()}
                    neighbourhood={this.props.neighbourhood}
                    setDestinatario={this.setDestinatario}
                  />;
                case 2:
                  return <SecondStep
                    NextStep={() => { this.NextStep() }}
                    PreviousStep={() => { this.PreviousStep() }}
                    transactionProgress={this.state.transaction}
                    updateTransaction={this.updateTransaction}
                    totalBalance={this.props.totalBalance}
                  />;
                case 3:
                  return <ThirdStep
                    PreviousStep={() => { this.PreviousStep() }}
                    confirmTransaction={this.createTransactionRequest}
                    transactionProgress={this.state.transaction}
                    errorMessageCreateTransactionRequest={this.props.billetera.errorMessageCreateTransactionRequest ? this.props.billetera.errorMessageCreateTransactionRequest : false}
                  />;
                case 4:
                  return this.setState({ completed: true })
              }
            })()}
          </PaperLayout >
        ) :
          (
            <FourStep onClose={() => { this.props.switchView("home") }} transactionProgress={this.state.transaction} billetera={this.props.billetera} />
          )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    idTransaction: state.billetera.idTransaction,
    billetera: state.billetera,
    totalBalance: state.billetera.balance,
    userInfo: state.userInfo
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCreateTransactionRequest,
      fetchGetNumberOfTransferPending,
      fetchBalance
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerarQR)