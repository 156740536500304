import React from 'react'
import styled from 'styled-components';

export const SectionHeaderContainer = styled.div`
  margin: 20px 0px;
  height: auto;
  
  justify-content: space-between;
  align-items: center;
`
export const Left = styled.div`
  font-size: ${({ subtitle }) => (subtitle ? '1.2rem' : '1.5rem')};
  white-space: nowrap;
`
export const Right = styled.div`
  display: flex;
  & >* {
    margin-left: 10px;
  }
`

class SectionHeader extends React.Component {
  render() {
    return (
      <SectionHeaderContainer>
        <Left subtitle={this.props.subtitle}>{this.props.title}</Left>
        <Right>{this.props.children}</Right>
      </SectionHeaderContainer>
    )
  }
}

export default SectionHeader;