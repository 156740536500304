import React from 'react'
import weekDays from '../../constants/weekDays'
import Table from './Table'
import Calendar from '../../assets/icons/calendar.png'
import TableCell from './TableCell'
import { generateReservationsTableRows, addTimeRange } from '../../utils/timeFormatter'
import styled from 'styled-components'

const HeaderCell = styled.div`
  width:100%;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 14px; 
  font-weight: ${({ today }) => today ? 'bold' : 'initial'};
  color: ${({ theme, today }) => today ? theme.colors.white : theme.colors.black};
  background-color: ${({ theme, white, today }) =>
    white ? theme.colors.white : today ? theme.colors.midGray : theme.colors.dustyGrayTransparent};
  border-left: 1px solid ${({ theme }) => theme.colors.mystic};
  outline: none;
`

const CalendarImage = styled.img`
  width: 25px;
`

class ReservationsTable extends React.Component {
  state = {
    reservationDataFormatted: {}
  }
  componentDidMount = () => {
    this.generateReservationsDataFormatted(this.props.data)
  }

  generateReservationsDataFormatted = data => {
    if (this.props.edit) {
      this.props.onChange(data)
    }
    let reservationDataFormatted = generateReservationsTableRows(data)
    this.setState({
      reservationDataFormatted
    })
  }

  createRange = (timeRange, dayPos) => {
    const updatedData = addTimeRange(timeRange, dayPos, this.props.data)
    this.generateReservationsDataFormatted(updatedData)
  }

  updateRange = (timeRange, dayPos) => {
    let updatedData = [...this.props.data]
    updatedData.forEach(day => {
      if (day.day_of_week === dayPos) {
        let updatedTimes = []
        day.calendar_times.forEach(range => {
          if (range.from !== timeRange.from) {
            updatedTimes.push(range)
          } else {
            updatedTimes.push(timeRange)
          }
        })
        day.calendar_times = updatedTimes
      }
    })
    this.generateReservationsDataFormatted(updatedData)
  }

  deleteRange = (timeRange, dayPos) => {
    let updatedData = [...this.props.data]
    updatedData.forEach(day => {
      if (day.day_of_week === dayPos) {
        let updatedTimes = []
        day.calendar_times.forEach(range => {
          if (range.from !== timeRange.from) {
            updatedTimes.push(range)
          }
        })
        day.calendar_times = updatedTimes
      }
    })
    if (timeRange.id) {
      this.props.onDeleteRange(timeRange.id)
    }
    this.generateReservationsDataFormatted(updatedData)
  }

  generateColumns = () => {
    const firstColumn = {
      width: 90,
      Header: (
        <HeaderCell white>
          <CalendarImage src={Calendar} />
        </HeaderCell>
      ),
      accessor: 'rowLabel',
      Cell: props => <TableCell createScheduleSuccess={this.props.createScheduleSuccess}
        createScheduleReset={this.props.createScheduleReset}>{props.value}</TableCell>
    }

    const dayColumns = this.props.data.map((day, pos) => ({
      Header: <HeaderCell today={day.today}>{`${weekDays[day.day_of_week]} ${day.day_of_month}`}</HeaderCell>,
      accessor: `${day.day_of_week}`,
      Cell: props => (
        <TableCell
          dayPos={day.day_of_week}
          data={this.props.data}
          timeRange={props.value}
          isSchedule={props.value && props.value.schedule_id && props.value.schedule_id > 0}
          isBooked={props.value && props.value.is_booked}
          from_app={props.value && props.value.from_app}
          isCancelled={props.value && props.value.is_cancelled}
          booking={props.value ? props.value.booking : null}
          price={props.value ? props.value.price : null}
          edit={this.props.edit}
          initial={props.value ? props.value.initial : null}
          final={props.value ? props.value.final : null}
          originalData={props.value ? props.value.originalData : null}
          onRangeDelete={dayRange => this.deleteRange(dayRange, day.day_of_week)}
          onRangeCreation={dayRange => this.createRange(dayRange, day.day_of_week)}
          onRangeChange={dayRange => this.updateRange(dayRange, day.day_of_week)}
          leftPopUp={pos > 3}
          date={day.date}
          selectedSpace={this.props.selectedSpace}
          spaceId={this.props.spaceId}
          startDate={this.props.startDate}
          errorCreateSchedule={this.props.errorCreateSchedule}
          loadingCreateSchedule={this.props.loadingCreateSchedule}
          createScheduleSuccess={this.props.createScheduleSuccess}
          createSchedule={this.props.createSchedule}
          fetchSchedulesCalendar={this.props.fetchSchedulesCalendar}
          selectedCalendarDateTime={this.props.selectedCalendarDateTime}
          createScheduleReset={this.props.createScheduleReset}
          fetchScheduleDetail={this.props.fetchScheduleDetail}
          loadingFetchScheduleDetail={this.props.loadingFetchScheduleDetail}
          fetchScheduleDetailSuccess={this.props.fetchScheduleDetailSuccess}
          fetchScheduleDetailReset={this.props.fetchScheduleDetailReset}
          scheduleDetail={this.props.scheduleDetail}
          handleAvailableCellClick={this.props.handleAvailableCellClick}
          handleBookingCellClick={this.props.handleBookingCellClick}
          createBookingNeighbourhoodReset={this.props.createBookingNeighbourhoodReset}
          updateScheduleReset={this.props.updateScheduleReset}
          isValidCell={true}
        />
      )
    }))

    return [firstColumn, ...dayColumns]
  }

  generatedColumns = this.generateColumns();
  render() {
    return (
      <Table data={Object.values(this.state.reservationDataFormatted)} columns={this.generatedColumns} />
    )
  }
}

export default ReservationsTable
