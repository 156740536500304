import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Select from 'react-select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import StyledButton from '../../../components/Button'
import {
    Container,
    SelectContainer,
    FormStyled,
    Label,
    WrapperSelectContainer,
    Page,
    TitlePageContainer,
    BarrioWrapper,
    BarrioLabel,
    BarrioWrapperContainer,
    LoaderWrapper,
    ErrorLabel,
    FormContainerF
} from './styled';
import { fetchDownloadSummary, resetDownloadSummary, fetchDebitAccounts, fetchDebitAccountsWithGuid } from "../../../store/actions/billetera.actions";
import { bindActionCreators } from "redux";
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
import Loader from "../../../components/Loader";


let today = new Date();
let anio = today.getFullYear();

var años = [];
for (var i = 4; i >= 0; i--) {
    años.push({ label: anio - i, value: anio - i });
}

const meses = [
    { label: 'Enero', value: '0' },
    { label: 'Febrero', value: '1' },
    { label: 'Marzo', value: '2' },
    { label: 'Abril', value: '3' },
    { label: 'Mayo', value: '4' },
    { label: 'Junio', value: '5' },
    { label: 'Julio', value: '6' },
    { label: 'Agosto', value: '7' },
    { label: 'Septiembre', value: '8' },
    { label: 'Octubre', value: '9' },
    { label: 'Noviembre', value: '10' },
    { label: 'Diciembre', value: '11' },

];


function Summary({ wallet, neighbourhoods, selectedNeighbourhood, HolderCuit, summary, setNeighbourhood, fetchDownloadSummary, resetDownloadSummary, loadingDownloadSummary, fetchDebitAccounts, fetchDebitAccountsWithGuid, summaryError,
    summaryErrorMsj }) {

    useEffect(() => {
        let formattedNeighbourhoods = neighbourhoods.map(item => {
            let neighbourhood = {
                value: item.name,
                label: item.name,
                guid: item.guid
            };
            return neighbourhood;
        });
        setNeighbourhoodsOptions(formattedNeighbourhoods);
    }, [])
    const [choicedNeighbourhood, setChoicedNeighbourhood] = useState({
        value: selectedNeighbourhood?.value || selectedNeighbourhood?.name,
        label: selectedNeighbourhood?.label || selectedNeighbourhood?.name,
        guid: selectedNeighbourhood?.guid
    });
    const today = new Date();

    const [month, setMonth] = useState({ label: meses[today.getMonth()]?.label, value: today.getMonth() });
    const [year, setYear] = useState({ label: today.getFullYear().toString(), value: today.getFullYear() });
    const [neighbourhoodsOptions, setNeighbourhoodsOptions] = useState([])


    const getInitialValues = () => ({
        neighbourhood: choicedNeighbourhood,
        month: month,
        year: year,
    })

    const getSignUpSchema = () =>
        Yup.object().shape({
            neighbourhood: Yup.object().required('El campo es requerido'),
            month: Yup.string().required('El campo es requerido'),
            year: Yup.string().required('El campo es requerido'),
        })

    const getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
        <FormStyled onSubmit={handleSubmit}>
            <WrapperSelectContainer>
                <SelectContainer>
                    {console.log("currentValues", values)}
                    <Label>Cuenta</Label>
                    <Select
                        value={values.neighbourhood}
                        onChange={(e) => {
                            setFieldValue('neighbourhood', e);
                            onChangeNeighbourhoodsOptions(e);
                        }}
                        options={neighbourhoodsOptions}
                        placeholder="Selecciona un barrio"
                        disabled={true}
                    />
                </SelectContainer>
                {/* <SelectContainer style={{display:"flex",justifyContent:"center"}}>
                <Label>Cuenta</Label>
                <BarrioWrapperContainer>
                    <BarrioWrapper style={{height:40+ "px"}}>
                        <BarrioLabel size={"19px"} >{values.neighbourhood.label}</BarrioLabel>
                    </BarrioWrapper>
                </BarrioWrapperContainer>
            </SelectContainer> */}

                <SelectContainer>
                    <Label>Año</Label>
                    <Select
                        value={values.year}
                        onChange={e => setFieldValue('year', e)}
                        options={años}
                        placeholder="Selecciona un año"
                    />
                </SelectContainer>
                {console.log(HolderCuit)}
                <SelectContainer>
                    <Label>Mes</Label>
                    <Select
                        value={values.month}
                        onChange={e => setFieldValue('month', e)}
                        options={values.year.value < today.getFullYear() ? meses : meses.filter((item, index) => index <= today.getMonth())}
                        placeholder="Selecciona un mes"
                    />
                </SelectContainer>

                <SelectContainer>
                    {!loadingDownloadSummary ?
                        <StyledButton style={{ marginTop: 30 + "px" }} onClick={() => onHandleSubmit(values)} type="submit" disabled={loadingDownloadSummary}>Buscar</StyledButton>
                        :
                        <LoaderWrapper>
                            <Loader small />
                        </LoaderWrapper>}
                </SelectContainer>
                {summaryError && <ErrorLabel>{summaryErrorMsj[0]}</ErrorLabel>}
            </WrapperSelectContainer>
            {<a
                id='download'
                href={summary[0]}
                hidden
                download="Documentos adjunto articulo.pdf"
                target="_blank"
            ></a>}
        </FormStyled>

    )
    const onChangeNeighbourhoodsOptions = (value) => {
        const asyncFunc = async () => {
            //En caso de querer que el selector cambien global la cuenta descomentar
            //setNeighbourhood y fetchDebitAccounts. Y comentar fetchDebitAccountsWithGuid
            //await setNeighbourhood(value);
            await resetDownloadSummary();
            //await fetchDebitAccounts();
            await fetchDebitAccountsWithGuid(value.guid);

        }
        asyncFunc();
    }

    const downloadBlob = () => {
        document.getElementById('download').click();
    }

    const fetchSumary = async (data) => {
        let monthAdjust = Number(data.month.value) + 1;
        await fetchDownloadSummary(data.year.value, monthAdjust, HolderCuit);
    };

    const onHandleSubmit = async formData => {
        const asyncFunc = async () => {
            await fetchSumary(formData);
            if (summary && summary != [] && summary[0] != "") downloadBlob();
        }
        asyncFunc();
    };

    return (
        <Page>
            <TitlePageContainer>
                <h2>Extracto</h2>
            </TitlePageContainer>
            <FormContainerF>
                <Container>
                    <Formik
                        initialValues={getInitialValues()}
                        validateOnChange={false}
                        validationSchema={getSignUpSchema()}
                        onSubmit={onHandleSubmit}
                        render={e => getFormContent(e)}
                    />
                </Container>
            </FormContainerF>
        </Page>
    )
}

const mapStateToProps = ({ state, userInfo: { neighbourhoods, selectedNeighbourhood }, billetera }) => ({
    // wallet: state.wallet,
    HolderCuit: billetera.selectedBankAccount != null ? billetera.selectedBankAccount.HolderCuit : null,
    neighbourhoods,
    selectedNeighbourhood,
    loadingDownloadSummary: billetera.loadingDownloadSummary,
    summary: billetera.summary,
    summaryError: billetera.errorDownloadSummary,
    summaryErrorMsj: billetera.errorDownloadSummaryMessage
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDownloadSummary,
            setNeighbourhood,
            resetDownloadSummary,
            fetchDebitAccounts,
            fetchDebitAccountsWithGuid,
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(Summary)