import React, { Component } from 'react'
import { Borde, BorderLineal, BorderCurbo, DestinatarioDetailItemColumnContainer, Container, ContainerError, AvatarItem, TitleCodigo, Row, Label, DestinatarioDetailContainer, DestinatarioDetailItemContainer, DestinatarioDetailItem, StyledButton, StyledButtonError, IconBack, CheckContainer, TyTitle, PaperStyled, GridButtonBack, ButtonBack, DestinatarioDetailContainerError } from './styled';
import cancel from '../../../../assets/icons/cancel.png'
import tic from '../../../../assets/icons/tic.svg'
import { getExtendedDate } from '../../../../utils/dateParser'
import PageLoader from "../../../../components/Loader"

const formatContent = content => content ? (content.length > 15 ? `${content.substring(0, 15)}...` : content) : null

export default class FourStep extends Component {
    state = {
        selectedOption: true,
        hoy: Date.now()
    }

    componentDidMount() {
    }

    handleFormSubmit = formSubmitEvent => {
        formSubmitEvent.preventDefault();
    };

    handleOptionChange = changeEvent => {
        this.setState(prevState => ({
            selectedOption: !prevState.selectedOption
        }))
    };

    render() {
        if (this.props.billetera.loadingCreateTransactionRequest === true) {
            return (
                <GridButtonBack item >
                    <ButtonBack onClick={this.props.onClose} variant="fab">
                        <IconBack />
                    </ButtonBack>
                    <TyTitle ><PageLoader /></TyTitle>
                </GridButtonBack>
            )
        } else if (this.props.billetera.errorMessageCreateTransactionRequest) {
            return (
                <div>
                    <GridButtonBack item >
                        <ButtonBack onClick={this.props.onClose} variant="fab">
                            <IconBack />
                        </ButtonBack>
                    </GridButtonBack>
                    <PaperStyled >
                        <ContainerError>
                            <form onSubmit={this.handleFormSubmit}>
                                <CheckContainer>
                                    <AvatarItem>
                                        <Label>{' La transferencia no se pudo generar'}</Label>
                                    </AvatarItem>
                                </CheckContainer>

                                <DestinatarioDetailContainerError>
                                    <Row>
                                        <TitleCodigo margin={"3% 0%"} size={'20px'} bold={true} >Mensaje de error: </TitleCodigo>
                                        <TitleCodigo size={'17px'} margin={"0% 0%"}> {this.props.billetera.errorMessageCreateTransactionRequest}
                                        </TitleCodigo>
                                    </Row>
                                </DestinatarioDetailContainerError>

                                <StyledButtonError onClick={() => this.props.onClose()} >Volver a la billetera</StyledButtonError>
                            </form>
                        </ContainerError>
                    </PaperStyled>
                </div>
            )
        } else if (this.props.billetera.errorMessageCreateTransactionRequest === false || this.props.billetera.loadingCreateTransactionRequest === false) {
            return (
                <div>
                    <GridButtonBack item >
                        <ButtonBack onClick={this.props.onClose} variant="fab">
                            <IconBack />
                        </ButtonBack>
                    </GridButtonBack>
                    <PaperStyled >
                        <Container>
                            <form onSubmit={this.handleFormSubmit}>
                                <CheckContainer>
                                    <AvatarItem>
                                        <Label>{'La transferencia se preparó con éxito'}</Label>
                                    </AvatarItem>
                                </CheckContainer>
                                <DestinatarioDetailContainer>
                                    <DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItem justify="flex-start"> Destinatario</DestinatarioDetailItem>
                                        <DestinatarioDetailItem justify="flex-end" bold={true} >{formatContent(this.props.transactionProgress.DestinationBankAccount.HolderName)}</DestinatarioDetailItem>
                                    </DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItem justify="flex-start"> Monto</DestinatarioDetailItem>
                                        <DestinatarioDetailItem justify="flex-end" bold={true} >{this.props.transactionProgress.Currency + " "}${this.props.transactionProgress.Amount}</DestinatarioDetailItem>
                                    </DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItem justify="flex-start"> Fecha y hora </DestinatarioDetailItem>
                                        <DestinatarioDetailItem fontSize="13.5px" justify="flex-end" bold={true} >{getExtendedDate(this.state.hoy)} </DestinatarioDetailItem>
                                    </DestinatarioDetailItemContainer>
                                    <DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItem justify="flex-start"> Cuenta de origen</DestinatarioDetailItem>
                                        <DestinatarioDetailItem justify="flex-end" bold={true} >{formatContent(this.props.transactionProgress.SourceBankAccount.HolderName)}</DestinatarioDetailItem>
                                    </DestinatarioDetailItemContainer>
                                    <Row margin={"0% 0% 2% 0%"}></Row>
                                    <Row >
                                        <DestinatarioDetailItemContainer >Pendiente de autorización: </DestinatarioDetailItemContainer>

                                        <DestinatarioDetailItemContainer justify={"space-around"}>
                                            <DestinatarioDetailItem > Administrador</DestinatarioDetailItem>
                                            <DestinatarioDetailItem color={"#F7B343"} > Falta firmar </DestinatarioDetailItem>
                                        </DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItemContainer justify={"space-around"}>
                                            <DestinatarioDetailItem > Operador</DestinatarioDetailItem>
                                            <DestinatarioDetailItem color={"#F7B343"}  > Falta firmar </DestinatarioDetailItem>
                                        </DestinatarioDetailItemContainer>
                                        <DestinatarioDetailItemContainer justify={"space-around"}>
                                            <DestinatarioDetailItem > Aprobador</DestinatarioDetailItem>
                                            <DestinatarioDetailItem color={"#F7B343"}  > Falta firmar </DestinatarioDetailItem>
                                        </DestinatarioDetailItemContainer>
                                    </Row>
                                    <Borde>
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                        <BorderLineal />
                                        <BorderCurbo />
                                    </Borde>
                                </DestinatarioDetailContainer>

                                <StyledButton onClick={() => this.props.onClose()} >Volver a la billetera</StyledButton>
                            </form>

                        </Container>
                    </PaperStyled>
                </div>
            )
        }
    }

}
