import { createSelector } from 'reselect'
var moment = require('moment');


//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapTransferPending = createSelector(  
    TransferRequest => TransferRequest,
    TransferRequest => 
    TransferRequest ? ( TransferRequest.map(transfer => ({
    datePay: moment(transfer.pay_date).format('DD-MM-YYYY HH:mm'),
    alias: transfer.alias,
    category: transfer.category,
    subcategory: transfer.subcategory,
    destination: transfer.destination_account_client,
    dateRequest: moment(transfer.creation_date).format('DD-MM-YYYY HH:mm'),
    origin: transfer.source_account_client,
    amount: transfer.amount,
    tranfer_request_id: transfer.tranfer_request_id,
    status: transfer.state_code
  })) ) : (TransferRequest)
)
export default mapTransferPending
