import React, { Component } from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';

import {
  AccordionItem,
  Title,
  AccordionToggleIcon,
  AccordionHeader,
  AccordionBody,
} from './styled'

class Accordion extends Component {
  render() {
    const { active, index, formDataValidation } = this.props;
    return (
      <AccordionItem valid={formDataValidation}>
        <AccordionHeader active={active} onClick={() => this.props.changeIndex(index)}>
          <Title>{this.props.title}{formDataValidation ? <CheckIcon style={{ 'marginLeft': '20px' }} /> : null}</Title>
          <AccordionToggleIcon>
            {active ? <RemoveIcon /> : <AddIcon />}
          </AccordionToggleIcon>
        </AccordionHeader>
        <AccordionBody active={active}>
          {this.props.children}
        </AccordionBody>
      </AccordionItem>
    );
  }
}

export default Accordion;