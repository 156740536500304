import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {


  neighbourhoodList: [],
  loadingNeighbourhood: false,
  errorFetchNeighbourhoods: false,

}


const fetchNeighbourhoodsSuccess = (state, action) => {
  return updateObject(state, {
    neighbourhoodList: action.neighbourhood,
    loadingNeighbourhood: false
  })
}

const fetchNeighbourhoodsFail = state => {
  return updateObject(state, { loadingNeighbourhood: false, errorFetchNeighbourhoods: true })
}

const fetchNeighbourhoodsStart = state => {
  return updateObject(state, { loadingNeighbourhood: true })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {


    case actionTypes.FETCH_NEIGHBOURHOOD_SUCCESS:
      return fetchNeighbourhoodsSuccess(state, action)
    case actionTypes.FETCH_NEIGHBOURHOOD_FAIL:
      return fetchNeighbourhoodsFail(state, action)
    case actionTypes.FETCH_NEIGHBOURHOOD_START:
      return fetchNeighbourhoodsStart(state, action)


    default:
      return state
  }
}

export default reducer
