import styled from 'styled-components'
import { Button,} from '@material-ui/core'

export const Header = styled.div`
    width: 100%;
    min-height: 20px;
    max-height: 50px;    
    margin: 30px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`
export const ContentWrapper = styled.div`
    width: 100%;
    max-height: 550px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-wrap: normal;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`
export const LabelContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    align-self: center;
    justify-content: space-evenly;
    width: ${({ width }) => (width ? width : "25%")};
    margin-left: ${({ margin }) => (margin ? margin : "0px")};
`

export const SelectContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    margin: 0px 5px;
    width: 15%;
`
export const TableContainer = styled.div`
    width: 100%;
`

export const Row = styled.div`
    min-height: 20px;
    max-height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: ${({ justify }) => ( justify ? justify  : "flex-start")  }; 
`
export const Circle = styled.div`
    border-color: ${({ status, theme }) => {
        if (status === "aprobado") {
            return theme.colors.oceanGreen;
        } else if (status === "pendiente") {
            return theme.colors.mariGold;
        } else { 
            return theme.colors.pomegranate;
        }
        }};
    background-color:  ${({ status, theme }) => {
        if (status === "aprobado") {
            return theme.colors.oceanGreen;
        } else if (status === "pendiente") {
            return theme.colors.mariGold;
        } else { 
            return theme.colors.pomegranate;
        }
        }};
    border-radius: 10px;
    border-width: 5px;
    align-self: center;
    height: 5px;
    width: 5px;
    border-style: solid;
`



export const Status = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ status, theme }) => {
        if (status === "Abonado") {
            return theme.colors.oceanGreen;
        } else if (status === "Pendiente") {
            return theme.colors.mariGold;
        } else { 
            return theme.colors.pomegranate;
        }
        }};
    font-size: 18px;
    align-self: center;
    font-weight: 500;
`

export const StyledButton = styled.button`
    background-color: ${({ color,theme }) => ( color ? theme.colors.redOpaced : theme.colors.greenTransparent )};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ color, theme }) => ( color ? theme.colors.pomegranate  : theme.colors.oceanGreen )  };      
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 65px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 25px 20px 0px 100px;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`
export const Container = styled.div`
    width: 37vw;
    height: 550px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 10px;
    background-color:  ${({theme})=> theme.colors.oceanGreen};
`

export const CloseIcon = styled.img`
    width: 15px;
    height: 15px;
    padding: 2%;
    align-self: flex-end;
    cursor: pointer;
    right: 14%;
    top: 8%;

`
export const RowItem = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: ${props => (props.justify ? props.justify  : 'space-between')};
    width: 90%;
    margin: 2% 0%;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`
export const Description = styled.div`
    font-size: 15px;
    width: 90%;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    color: ${props => (props.color ? props.color  : 'black')};
`
export const WrapperItems = styled.div`
    width: 80%;
    align-self: center;
    
    
    background-color: white;
    border-radius: 10px;
    padding: 1% 1%;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || '350px'};
  padding:0.3em;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  margin:0 !important;
`