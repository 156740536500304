import RitzTable from "../../../../components/RitzTable";

import SectionHeader from "../../../../components/SectionHeader";

import React, { useEffect, useState } from "react";
import XLSX from 'xlsx';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader";
import Button from "../../../../components/Button";
import { LoaderWrapper, TableWrapper } from "./styled";
import theme from "../../../../config/theme"
import { fetchUsersFunnelData } from "../../../../store/actions/reportes.actions";
var moment = require('moment');
const tableRef = React.createRef();
const mapStateToProps = state => {
  const { usersFunnelData, loadingFetchUsersFunnelData } = state.reportes;

  return {
    usersFunnelData: usersFunnelData,
    loadingFetchUsersFunnelData: loadingFetchUsersFunnelData
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsersFunnelData
    },
    dispatch
  );

function UsersFunnel(props) {
  const { usersFunnelData, loadingFetchUsersFunnelData } = props;

  const columns = [
    {
      title: "Barrio",
      field: "neighbourhood_name",
      headerStyle: { maxWidth: "150px", minWidth: '150px',textAlign:"center" },
      cellStyle: { maxWidth: "150px", minWidth: '150px' ,textAlign:"center",paddingLeft:'0px'}
    },
    {
      title: 'Total usuarios registrados', field: 'data.total_users',
      headerStyle: { minWidth: '170px',textAlign:"center" },
      cellStyle: { maxWidth: '170px', minWidth: '170px',textAlign:"center" ,paddingLeft:'0px'},
    },
    {
      title: 'Total CVU Miiiiers', field: 'data.total_cvus_miiiiers',
      headerStyle: { maxWidth: '300px', minWidth: '140px' ,textAlign:"center" },
      cellStyle: { maxWidth: '300px', minWidth: '140px',textAlign:"center"  ,paddingLeft:'0px' },
    },
    {
      title: '%s/R', field: 'percentages_data.cvus_miiiiers_by_total_users',
      headerStyle: { maxWidth: '60px' ,textAlign:"center" },
      cellStyle: { maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: 'Total CVU AR$', field: 'data.total_cvus_ars',
      headerStyle: { maxWidth: '300px', minWidth: '140px',textAlign:"center"  },
      cellStyle: { maxWidth: '300px', minWidth: '140px',textAlign:"center"  ,paddingLeft:'0px' },

    },
    {
      title: '%s/R', field: 'percentages_data.cvus_ars_by_total_user',
      headerStyle: { maxWidth: '60px' ,textAlign:"center" },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: '%s/MI', field: 'percentages_data.cvus_ars_by_cvus_miiiiers',
      headerStyle: { maxWidth: '60px',textAlign:"center" },
      cellStyle: { maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: 'Total Ctas Itaú', field: 'data.total_itau_accounts',
      headerStyle: { maxWidth: '300px', minWidth: '140px',textAlign:"center"  },
      cellStyle: { maxWidth: '300px', minWidth: '140px',textAlign:"center" ,paddingLeft:'0px'  },
    },
    {
      title: '%s/R', field: 'percentages_data.itau_accounts_by_total_users',
      headerStyle: { maxWidth: '60px',textAlign:"center"  },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },

    {
      title: '%s/MI', field: 'percentages_data.itau_accounts_by_cvus_miiiiers',
      headerStyle: { maxWidth: '60px',textAlign:"center"  },
      cellStyle: { maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: '%s/CV', field: 'percentages_data.itau_accounts_by_cvus_ars',
      headerStyle: { minWidth: '30px',textAlign:"center"  },
      cellStyle: { maxWidth: '60px', minWidth: '30px' ,textAlign:"center" },

    },
  ];

  useEffect(() => {
    props.fetchUsersFunnelData();
  }, []);

  const getTotalData = () => {
    var totalData = usersFunnelData.neighbourhoods.concat(
      [{
        neighbourhood_name: 'Total Barrios Miiii', id: 10000, parentId: null,
        data: {
          total_users: usersFunnelData.global_nighbourhoods_data.total_users,
          total_cvus_ars: usersFunnelData.global_nighbourhoods_data.total_cvus_ars,
          total_cvus_miiiiers: usersFunnelData.global_nighbourhoods_data.total_cvus_miiiiers,
          total_itau_accounts: usersFunnelData.global_nighbourhoods_data.total_itau_accounts
        },
        percentages_data: {
          cvus_miiiiers_by_total_users: usersFunnelData.global_nighbourhoods_percentages_data.cvus_miiiiers_by_total_users,
          cvus_ars_by_total_user: usersFunnelData.global_nighbourhoods_percentages_data.cvus_ars_by_total_user,
          cvus_ars_by_cvus_miiiiers: usersFunnelData.global_nighbourhoods_percentages_data.cvus_ars_by_cvus_miiiiers,
          itau_accounts_by_total_users: usersFunnelData.global_nighbourhoods_percentages_data.itau_accounts_by_total_users,
          itau_accounts_by_cvus_ars: usersFunnelData.global_nighbourhoods_percentages_data.itau_accounts_by_cvus_ars,
          itau_accounts_by_cvus_miiiiers: usersFunnelData.global_nighbourhoods_percentages_data.itau_accounts_by_cvus_miiiiers,
        }
      },
      {
        neighbourhood_name: 'Mercado abierto', id: 10001, parentId: null,
        data: {
          total_users: usersFunnelData.open_market_data.total_users,
          total_cvus_ars: usersFunnelData.open_market_data.total_cvus_ars,
          total_cvus_miiiiers: usersFunnelData.open_market_data.total_cvus_miiiiers,
          total_itau_accounts: usersFunnelData.open_market_data.total_itau_accounts
        },
        percentages_data: {
          cvus_miiiiers_by_total_users: usersFunnelData.open_market_percentages_data.cvus_miiiiers_by_total_users,
          cvus_ars_by_total_user: usersFunnelData.open_market_percentages_data.cvus_ars_by_total_user,
          cvus_ars_by_cvus_miiiiers: usersFunnelData.open_market_percentages_data.cvus_ars_by_cvus_miiiiers,
          itau_accounts_by_total_users: usersFunnelData.open_market_percentages_data.itau_accounts_by_total_users,
          itau_accounts_by_cvus_ars: usersFunnelData.open_market_percentages_data.itau_accounts_by_cvus_ars,
          itau_accounts_by_cvus_miiiiers: usersFunnelData.open_market_percentages_data.itau_accounts_by_cvus_miiiiers,

        }
      },
      {
        neighbourhood_name: 'Total Universo Miiii', id: 10002, parentId: null,
        data: {
          total_users: usersFunnelData.global_miiii_data.total_users,
          total_cvus_ars: usersFunnelData.global_miiii_data.total_cvus_ars,
          total_cvus_miiiiers: usersFunnelData.global_miiii_data.total_cvus_miiiiers,
          total_itau_accounts: usersFunnelData.global_miiii_data.total_itau_accounts
        },
        percentages_data: {
          cvus_miiiiers_by_total_users: usersFunnelData.global_miiii_percentages_data.cvus_miiiiers_by_total_users,
          cvus_ars_by_total_user: usersFunnelData.global_miiii_percentages_data.cvus_ars_by_total_user,
          cvus_ars_by_cvus_miiiiers: usersFunnelData.global_miiii_percentages_data.cvus_ars_by_cvus_miiiiers,
          itau_accounts_by_total_users: usersFunnelData.global_miiii_percentages_data.itau_accounts_by_total_users,
          itau_accounts_by_cvus_ars: usersFunnelData.global_miiii_percentages_data.itau_accounts_by_cvus_ars,
          itau_accounts_by_cvus_miiiiers: usersFunnelData.global_miiii_percentages_data.itau_accounts_by_cvus_miiiiers,

        }
      }
      ]
    );
    return totalData;

  };
  const exportExcel = async() => {
    const allColumns = tableRef.current.props.columns;
    const allData = tableRef.current.dataManager.pagedData;

    const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
    var neighbourhoodsDataRows = [];
    neighbourhoodsDataRows.push(columns.map(col => { return col.title }));
    allData.map(rowData => {
        var neighbourhoodParent = columns.map(columnDef => {
            var splittedColumn = columnDef.field.split('.');
            if (splittedColumn[1] != null) {
                return rowData[splittedColumn[0]][splittedColumn[1]];
            }
            return rowData[splittedColumn[0]];
        });
        neighbourhoodsDataRows.push(neighbourhoodParent);
        if (rowData.tableData.childRows != null) {
            rowData.tableData.childRows.map(childNeighbourhoodData => {
                var childNeighbourhoodRow = columns.map(columnDef => {
                    var splittedColumn = columnDef.field.split('.');
                    if (splittedColumn[1] != null) {
                        return childNeighbourhoodData[splittedColumn[0]][splittedColumn[1]];
                    }
                    return childNeighbourhoodData[splittedColumn[0]];
                });
                neighbourhoodsDataRows.push(childNeighbourhoodRow);
            })

        }
    })
    var ws = XLSX.utils.aoa_to_sheet(neighbourhoodsDataRows);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'UsersFunnel' + moment().format('YYYY-MM-DD'));
    XLSX.writeFile(wb, `UsersFunnel_` + moment().format('YYYY-MM-DD') + `.xlsx`);
};
  return (
    <>
      <SectionHeader
        title="Funnel usuarios"
        width="100%"
        justifyContent={"space-between"}
      >
        <Button loading={loadingFetchUsersFunnelData} onClick={() => exportExcel()} > Exportar a excel </Button>
      </SectionHeader>
        <TableWrapper>
          <RitzTable
            columns={columns}
            title=""
            isLoading={loadingFetchUsersFunnelData}
            tableRef={tableRef}
            options={{
              // toolbar: true,
              paging: false,
              serverSide: false,
              exportButton: true,
              search: false,
              exportName: 'Exportar a XLSX',
              rowStyle: rowData => ({
                backgroundColor: rowData.id == 10000 ? theme.colors.lightGreen : rowData.id == 10001 ? theme.colors.midGray : rowData.id == 10002 ? theme.colors.darkGreen :  rowData.id == 20000 ||  rowData.id == 30000 ||  rowData.id == 40000 ? theme.colors.lighterGreen : (((!rowData.parentId ? theme.colors.ghost : theme.colors.white))),
                color: rowData.id == 10000 || rowData.id == 10001 || rowData.id == 10002 || rowData.id == 20000 || rowData.id == 30000 || rowData.id == 40000 ? theme.colors.white : !rowData.parentId ? theme.colors.black : theme.colors.midGray,
              })
            }}
            data={getTotalData()}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          />


        </TableWrapper>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersFunnel);
