import * as actionTypes from './actionTypes'

import {
  postNewProprietaryAPI,
  getPadronAPI,
  getFamiliesByPropietaryIdAPI,
  getVehicleByPropietaryIdAPI,
  postImportMasiveProprietariesAPI,
  editPadronAPI,
  postNewSecurityStaffAPI,
  getSearchedSecurityStaffListAPI,
  getSecurityStaffListAPI
} from '../../apiConstants'

import getApi from '../../shared/api'


export const fetchSecurityStaffSuccess = staff => {
  return {
    type: actionTypes.FETCH_SECURITYSTAFF_SUCCESS,
    staff: staff
  }
}

export const fetchSecurityStaffFail = error => {
  return {
    type: actionTypes.FETCH_SECURITYSTAFF_FAIL,
    error: error
  }
}

export const fetchSecurityStaffStart = () => {
  return {
    type: actionTypes.FETCH_SECURITYSTAFF_START
  }
}

//-------------------------------------------------------------

export const createSecurityStaffSuccess = newStaff => {
  return {
    type: actionTypes.CREATE_SECURITYSTAFF_SUCCESS,
    newStaffId: newStaff.id,
    newStaffData: newStaff
  }
}

export const createSecurityStaffFail = error => {
  return {
    type: actionTypes.CREATE_SECURITYSTAFF_FAIL,
    error: error
  }
}

export const createSecurityStaffStart = () => {
  return {
    type: actionTypes.CREATE_SECURITYSTAFF_START
  }
}


export const createSecurityStaff = newStaff => {
  return async (dispatch, getState) => {
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    dispatch(createSecurityStaffStart())
    return await api
      .post(postNewSecurityStaffAPI, newStaff)
      .then(res => {
        dispatch(createSecurityStaffSuccess(res.data))
      })
      .catch(err => {
        dispatch(createSecurityStaffFail(err))
      })
  }
}


export const fetchSecurityStaff = () => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchSecurityStaffStart())
    api
      .get(getSecurityStaffListAPI)
      .then(res => {
        dispatch(fetchSecurityStaffSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchSecurityStaffFail(err))
      })
  }
}

// PADRON GET FAMMILY
export const fetchPropietaryFamilySuccess = data => {
  return {
    type: actionTypes.FETCH_PROPIETARY_FAMILY_SUCCESS,
    data: data
  }
}

export const fetchPropietaryFamilyFail = error => {
  return {
    type: actionTypes.FETCH_PROPIETARY_FAMILY_FAIL,
    error: error.response
  }
}

export const fetchPropietaryFamilyStart = () => {
  return {
    type: actionTypes.FETCH_PROPIETARY_FAMILY_START
  }
}

export const fetchPropietaryFamily = personId => {
  return async (dispatch, getState) => {
    dispatch(fetchPropietaryFamilyStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(getFamiliesByPropietaryIdAPI + personId)
      .then(res => {
        dispatch(fetchPropietaryFamilySuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchPropietaryFamilyFail(error))
      })
  }
}

// PADRON GET VEHICLE

export const fetchPropietaryVehicleSuccess = data => {
  return {
    type: actionTypes.FETCH_PROPIETARY_VEHICLE_SUCCESS,
    data: data
  }
}

export const fetchPropietaryVehicleFail = error => {
  return {
    type: actionTypes.FETCH_PROPIETARY_VEHICLE_FAIL,
    error: error.response
  }
}

export const fetchPropietaryVehicleStart = () => {
  return {
    type: actionTypes.FETCH_PROPIETARY_VEHICLE_START
  }
}

export const fetchPropietaryVehicle = id => {
  return async (dispatch, getState) => {
    dispatch(fetchPropietaryVehicleStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .get(getVehicleByPropietaryIdAPI + id)
      .then(res => {
        dispatch(fetchPropietaryVehicleSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchPropietaryVehicleFail(error))
      })
  }
}


export const fetchPadronSuccess = padron => {
  return {
    type: actionTypes.FETCH_PADRON_SUCCESS,
    padron: padron
  }
}

export const fetchPadronFail = error => {
  
  console.log("padron fail",error)
  return {
    type: actionTypes.FETCH_PADRON_FAIL,
    error: error
  }
}

export const fetchPadronStart = () => {
  return {
    type: actionTypes.FETCH_PADRON_START
  }
}

export const createProprietarySuccess = (id, newProprietaryData) => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_SUCCESS,
    newPadronId: id,
    newProprietaryData: newProprietaryData
  }
}

export const createProprietaryFail = error => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_FAIL,
    error: error
  }
}

export const createProprietaryStart = () => {
  return {
    type: actionTypes.CREATE_PROPRIETARY_START
  }
}

export const newProprietary = (newProprietary, guid) => {
  return async dispatch => {
    const api = await getApi(guid, true)
    dispatch(createProprietaryStart())
    return api
      .post(postNewProprietaryAPI, newProprietary)
      .then(() => {
        dispatch(createProprietarySuccess())
      })
      .catch(error => {
        dispatch(createProprietaryFail(error.response.data.message))
      })
  }
}

export const importProprietarySuccess = (id, importProprietaryData) => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_SUCCESS,
    newPadronId: id,
    importProprietaryData: importProprietaryData
  }
}

export const importProprietaryFail = error => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_FAIL,
    error: error
  }
}

export const importProprietaryStart = () => {
  return {
    type: actionTypes.IMPORT_PROPRIETARY_START
  }
}

export const importProprietary = (importProprietary, guid) => {
  return async dispatch => {
    const api = await getApi(guid, true)
    dispatch(importProprietaryStart())
    return api
      .post(postImportMasiveProprietariesAPI, importProprietary)
      .then((res) => {
        dispatch(importProprietarySuccess())
      })
      .catch(err => {
        dispatch(importProprietaryFail(err))
      })
  }
}


export const fetchPadron = textoABuscar => {
  return async (dispatch, getState) => {
    dispatch(fetchPadronStart())
    const {
      padron,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    const request = {
      searchValue: textoABuscar ? textoABuscar : "",
      pageSize: null,
      pageNumber: null
    }

    return api
      .post(getPadronAPI, request)
      .then(response => {
        dispatch(fetchPadronSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchPadronFail(err))
      })
  }
}


//------------------Request Edit Person -----------------------------------
export const fetchEditPersonSuccess = editPersonResponse => {
  return {
    type: actionTypes.FETCH_EDIT_PERSON_SUCCESS,
    editPersonResponse: editPersonResponse
  }
}

export const fetchEditPersonFail = error => {
  return {
    type: actionTypes.FETCH_EDIT_PERSON_FAIL,
    error: error
  }
}

export const fetchEditPersonStart = () => {
  return {
    type: actionTypes.FETCH_EDIT_PERSON_START
  }
}

export const fetchEditPerson = person => {
  return async (dispatch, getState) => {
    dispatch(fetchEditPersonStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .put(editPadronAPI, person)
      .then(res => {
        dispatch(fetchEditPersonSuccess(res.data))
        dispatch(fetchPadron())
      })
      .catch(error => {
        dispatch(fetchEditPersonFail(error.response.data.message))
      })
  }
}