import { createSelector } from 'reselect'

const mapProprietary = createSelector(
  proprietaryList => proprietaryList,
  proprietaryList =>
    proprietaryList.map(
      ({ id, name, surname, dni, username, property_code, phone_number, properties, properties_id, email, neighbourhoods, picture_url, cuil }, i) => ({
        pos: i,
        id,
        fullname: `${name} ${surname}`,
        subtitle: 'Lote: ' + properties ? properties.map((data, i) => (data.property_code + ' ')) : '',
        extraInfo: username,
        avatar: picture_url,
        withAvatar: true,
        username,
        name,
        surname,
        neighbourhoods_name: neighbourhoods ? neighbourhoods.map((barrioData, i) => (barrioData.neighbourhood_name + ' ')) : null,
        propiedades: properties ? properties.map((data, i) => (data.property_code + ' ')) : null,
        neighbourhoods_id: neighbourhoods ? neighbourhoods.map((barrioData, i) => (barrioData.neighbourhood_id + ' ')) : null,
        property_code: properties ? properties.map((data, i) => (data.property_code )) : null,
        properties_id: properties ? properties.map((data, i) => (data.property_id )) : null,
        dni,
        phone_number,
        email,
        picture_url,
        cuil
      })
    )
)

export default mapProprietary
