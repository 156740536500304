import styled, { keyframes }from 'styled-components';
import theme from '../../../config/theme';
export const TableWrapper = styled.div`
position:relative;
`

export const LoaderWrapper = styled.div`
position:absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
z-index: 999999;
padding-top:4em;
padding-bottom:4em;
`


export const Ahref = styled.a`
    cursor: pointer;
    color: #30735f;
    text-decoration:underline
`


export const LabelSection = styled.div`
  display: flex;
    align-items: center;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: flex-start;
  align-items: center;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  margin-right: 10px;
`

export const SelectWrapper = styled.div`
  width: 300px;
  font-weight: normal;
  
`
export const ActiveLabel = styled.div`
  color:  ${({ theme }) => theme.colors.activeLabel};;
  background-color: #e0eae7;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:6px 12px;
  margin: 2px;
  width: 100px;
  // width:min-content;
  border-radius:20px;
`
export const PendingLabel = styled.div`
  color: ${({ theme }) => theme.colors.pendingLabelText};
  background-color: ${({ theme }) => theme.colors.pendingLabel};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:6px 12px;
  width: 100px;
  font-weight: bold;
  border-radius:20px;
`

export const RejectedLabel = styled.div`
  color: ${({ theme }) => theme.colors.rejectedLabelText};
  background-color: ${({ theme }) => theme.colors.rejectedLabel};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:6px 12px;
  width: 100px;
  font-weight: bold;
  border-radius:20px;
`