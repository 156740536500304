/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import SectionHeader from "../../../components/SectionHeader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RitzTable from '../../../components/RitzTable';
import AlertsTable from "../../../components/ReceivedAlertsTable";
import DayJS from "react-dayjs";
import publish from '../../../assets/icons/publish.png'
import eye from "../../../assets/icons/eye.svg";
import {
  LineSeparator,
  EstadoLabelAlerta,
  EstadoLabelResuelto,
  TableWrapper,
  LoaderWrapper,
  InputWrapper,
  InputLabel,
  SelectWrapper,
  Imagen
} from "./styled";
import { fetchAlerts, selectRows, fetchAlert } from "../../../store/actions/alerts.actions";
import PublishModal from './PublishModal';
import AlertsFooter from "../Components/AlertsFooter";
import ModalAlertMap from "../Components/ModalAlertMap";
import Loader from "../../../components/Loader";
import Select from "react-select";

var moment = require("moment");
require("moment/locale/es");

const mapStateToProps = state => {
  const { receivedAlerts, detailAlert, loadingAlerts, loadingAlert } = state.alerts;
  const { user } = state.auth;

  return {
    detailAlert: detailAlert,
    isLoading: loadingAlerts,
    loadingAlert: loadingAlert,
    receivedAlerts: receivedAlerts,
    user: user
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchAlerts, selectRows, fetchAlert }, dispatch);

function Recibidas(props) {
  const {
    receivedAlerts,
    loadingAlert,
    detailAlert,
    isLoading,
    user,
    selectedNeighbourhood,
    setNeighbourhoodDropValue,
    neighbourhoods
  } = props;

  const [showModalMap, setShowModalMap] = useState(false);

  var columns = [
    { title: "Motivo", field: "alert_motive" },
    { title: "Emitida por", field: "user" },
    { title: "Unidad", field: "property_code" },
    {
      title: "Barrio",
      field: "neighbourhood",
      hidden: user.neighbourhoods.length === 1,
      customSort: (a, b) => a.description.toLowerCase() - b.description.toLowerCase()
    },
    {
      title: "Fecha",
      field: "date",
      render: rowData => <DayJS format="DD-MM-YY">{rowData.date}</DayJS>
    },
    { title: "Hora", field: "date", render: rowData => moment(rowData.date).format("LT") },
    {
      title: "Estado",
      field: "alert_state",
      render: rowData =>
        rowData.alert_state === "En alerta" ? (
          <EstadoLabelAlerta>{rowData.alert_state}</EstadoLabelAlerta>
        ) : (
            <EstadoLabelResuelto>{rowData.alert_state}</EstadoLabelResuelto>
          )
    },
    {
      title: "Acciones",
      field: "acciones",
      render: rowData => (
        <>
          <Imagen onClick={() => toggleMap(rowData.id)} src={eye} />
        </>
      )
    }
  ];

  useEffect(() => {
    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchAlerts(neighbourhoods.map(x => x.value));
    } else {
      props.fetchAlerts(selectedNeighbourhood.value);
    }
  }, []);

  const refreshGrids = () => {
    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchAlerts(neighbourhoods.map(x => x.value));
    } else {
      props.fetchAlerts(selectedNeighbourhood.value);
    }
  };

  let newAlerts = receivedAlerts.filter(receivedAlert => receivedAlert.alert_state === "En alerta"),
    cantAlerts = newAlerts.length;

  const getCheckboxIds = rows => {
    props.selectRows(rows.map(x => x.id));
  };

  const onNeighbourhoodChange = data => {
    setNeighbourhoodDropValue(data);
    if (data.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchAlerts(neighbourhoods.map(x => x.value));
    } else {
      props.fetchAlerts(data.value);
    }
  };

  const toggleMap = async alertId => {
    setShowModalMap(!showModalMap);
    await props.fetchAlert(alertId);
  };

  return (
    <>
      <SectionHeader
        title="Alertas recibidas"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
      >
        {user.neighbourhoods.length > 1 ? (
          <InputWrapper>
            <InputLabel>{"Barrio"}</InputLabel>
            <SelectWrapper>
              <Select
                value={selectedNeighbourhood}
                onChange={value => onNeighbourhoodChange(value)}
                options={neighbourhoods}
                placeholder="Seleccione un barrio"
                isLoading={props.isLoading}
              />
            </SelectWrapper>
          </InputWrapper>
        ) : null}
      </SectionHeader>
      <SectionHeader title={`Tenes ${cantAlerts} alertas nuevas`} width="100%" />
      <TableWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}

        <AlertsTable
          columns={columns}
          title=""
          isLoading={isLoading}
          onSelectionChange={getCheckboxIds}
          options={{
            toolbar: false,
            paging: true,
            selection: true,
            serverSide: true,
            exportButton: true,
            exportAllData: true,
            exportFileName: "id." + new Date().toISOString().split(".")[0],
            headerStyle: {
              backgroundColor: "#F8F8F8",
              color: "#9B9A9B",
              height: "2vh",
              width: "auto"
            },
            rowStyle: {
              border: "2px solid #D2D2D2",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "25px !important",
              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2), inset -3px 0 0 0 rgba(228, 228, 228, 0.2)"
            }
          }}
          pagination={{
            totalRow: 10,
            page: 5,
            pageSize: 5,
            onChangePage: true
          }}
          data={receivedAlerts.filter(receivedAlert => receivedAlert.alert_state === "En alerta")}
        />
      </TableWrapper>
      <LineSeparator />
      <SectionHeader
        title="Alertas anteriores"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
      />
      <TableWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}

        <AlertsTable
          columns={columns}
          title=""
          isLoading={isLoading}
          //onSelectionChange={(rows) => this.handleVentasSelected(rows)}
          options={{
            toolbar: false,
            paging: true,
            selection: false,
            serverSide: true,
            exportButton: true,
            exportAllData: true,
            exportFileName: "id." + new Date().toISOString().split(".")[0],
            headerStyle: {
              backgroundColor: "#F8F8F8",
              color: "#9B9A9B",
              height: "2vh",
              width: "auto"
            },
            rowStyle: {
              border: "2px solid #D2D2D2",
              backgroundColor: "#FFFFFF",
              borderRadius: "6px",
              marginTop: "25px !important",
              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2), inset -3px 0 0 0 rgba(228, 228, 228, 0.2)"
            }
          }}
          pagination={{
            totalRow: 10,
            page: 5,
            pageSize: 5,
            onChangePage: true
          }}
          data={receivedAlerts.filter(receivedAlert => receivedAlert.alert_state === "Resuelta")}
        />
      </TableWrapper>

      <AlertsFooter refreshGrids={refreshGrids} />

      {showModalMap ? (
        <ModalAlertMap onClose={toggleMap} isLoading={loadingAlert} alert={detailAlert} />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Recibidas);
