import { createSelector } from 'reselect'
var moment = require('moment');

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapPaymentOperationsReport = createSelector(
  operations => operations,
  operations =>
  (operations.map(operation => ({
    ...operation,
    creation_date: moment(operation.creation_date).format('DD-MM-YYYY HH:mm'),
    payment_methods: !operation.payment_methods || operation.payment_methods == "" ? "-" : operation.payment_methods,
    installments_quantity: !operation.installments_quantity || operation.installments_quantity == "" ? "-" : operation.installments_quantity,
    payment_methods_description: !operation.payment_methods_description || operation.payment_methods_description == "" ? "-" : operation.payment_methods_description,
    vendors_name: !operation.vendors_name || operation.vendors_name == "" ? "-" : operation.vendors_name,
    document_number: !operation.document_number || operation.document_number == "" ? "-" : operation.document_number,
    card_holder_name: !operation.card_holder_name || operation.card_holder_name == "" ? "-" : operation.card_holder_name,
  })))
)
export default mapPaymentOperationsReport
