import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import PaperLayout from '../../../components/PaperLayout'
import Modal from '../../../components/Modal'
import { getFullDateTimePlus, getFullDateTime } from '../../../utils/dateParser'
import Loader from 'react-spinner-material'


import Paso1 from './paso1';
import Paso2 from './paso2';
import Paso3 from './paso3';


import {
    fetchAddProduct,
    fetchEditProduct,
    fetchGetShippingMethods,
    fetchEditProductReset
} from '../../../store/actions/clasificados.actions'

import compose from 'recompose/compose'


const talles = [{ label: 'XS', value: '0' },
{ label: 'S', value: '1' },
{ label: 'M', value: '2' },
{ label: 'L', value: '3' },
{ label: 'X', value: '4' },
{ label: 'XL', value: '5' }];


const colores = [{ label: 'Rojo', value: '0' },
{ label: 'Verde', value: '1' },
{ label: 'Amarillo', value: '2' },
{ label: 'Azul', value: '3' },
{ label: 'Violeta', value: '4' },
{ label: 'Blanco', value: '5' }];


class EditProduct extends Component {

    state = {
        step: {
            name: 'Crear',
            number: 1,
            percentage: 33,
        },
        activeIndex: 0,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }

    NextStep = () => {
        this.setState(prevState => ({
            step: {
                ...prevState.step,
                number: prevState.step.number + 1,
                percentage: prevState.step.percentage + 33
            }
        }))
    }


    BackStep = () => {
        this.setState(prevState => ({
            step: {
                ...prevState.step,
                number: prevState.step.number - 1,
                percentage: prevState.step.percentage - 33
            }
        }))
    }

    handleContinuar = () => {
        this.setState({ step: this.state.step + 1 });
    }

    handleAddProduct = () => {
        
        const { formData } = this.state;
        const paso1 = formData[0];
        const paso2 = formData[1];
        const paso3 = formData[2];

        const formPost = {
            'title': paso1.titulo,
            'id': this.props.producto.values.id,

            'description': paso1.descripcion,
            'shopitemconditionid': paso2.itemCondition.value,
            'shopitemcategoryid': paso1.categoria.value,
            'quantity': paso2.stock,
            'publicationstartdate': getFullDateTime(),
            'publicationenddate': getFullDateTimePlus(30),
            'neighbourhoods': this.props.neighbourhoods,
            'keywords':
                [],
            'prices':
                [{
                    'price': paso2.importe,
                    'currency': 'ARS'
                }],

        };


        //acceptedpaymentmethodlist
        formPost.deliverymethods = paso3.deliverymethods.map(deliver => ({
            id: deliver.value
        }))

        //acceptedpaymentmethodlist
        formPost.acceptedpaymentmethodlist = paso3.acceptedpaymentmethodlist.map(pay => ({
            paymentmethodcode: pay.value,
            paymentmethodname: pay.label
        }))

        // procesamos imagenes     
        if (paso3.images !== undefined) {
            formPost.newimages = paso3.images.map(image => ({ ImageBase64: image, extension: this.guessImageMime(image)}))
        }

        if (paso3.file !== undefined) {
            formPost.images = paso3.file.map(image => (image))
        }

        this.props.fetchEditProduct(formPost)

    }


    guessImageMime(data) {
        if (data.charAt(0) == '/') {
            return "JPEG";
        } else if (data.charAt(0) == 'R') {
            return "GIF";
        } else if (data.charAt(0) == 'i') {
            return "PNG";
        }
    }

    getFormData = (data, index) => {
        const currentState = [...this.state.formData];
        currentState[index] = { validated: true, ...data };
        this.setState({
            formData: currentState
        });
        this.changeIndex(index, true);
    }

    changeIndex = (index, next) => {
        const { activeIndex } = this.state
        let newIndex;
        if (next) {
            newIndex = activeIndex;
            newIndex++;
        } else {
            newIndex = activeIndex === index ? -1 : index
        }
        this.setState({ activeIndex: newIndex })
    }

    handleVolver = () => {
        this.setState({ step: this.state.step - 1 });
    }

    openWebcamModal = () => {
        this.setState({ cameraModalOpen: !this.state.cameraModalOpen, });
    }

    closeAndResetRedux() {
        this.props.fetchEditProductReset()
        this.props.toggleEditProd()
    }

    render() {

        return (
            <Modal onClose={() => this.closeAndResetRedux()}>
                <PaperLayout title={'Editar Producto'} Step={this.state.step} style={{ bakcgroundColor: 'none' }}>
                    {!this.props.clasificados.loadingDetailProduct ?
                        (() => {
                            switch (this.state.step.number) {
                                case 1:
                                    return (
                                        <Paso1
                                            formDataValidation={this.state.formData[0].validated}
                                            dataSaved={this.state.formData[0]}

                                            changeIndex={this.changeIndex.bind(this)}
                                            getFormData={this.getFormData}
                                            index={0}
                                            active={this.state.activeIndex === 0}
                                            handleContinuar={() => this.NextStep()}
                                            handleVolver={() => this.BackStep()}
                                            producto={this.props.producto}
                                        />
                                    )
                                case 2:
                                    return (
                                        <Paso2
                                            formDataValidation={this.state.formData[1].validated}
                                            dataSaved={this.state.formData[1]}
                                            changeIndex={this.changeIndex.bind(this)}
                                            getFormData={this.getFormData}
                                            index={1}
                                            producto={this.props.producto}
                                            active={this.state.activeIndex === 1}
                                            handleContinuar={() => this.NextStep()}
                                            handleVolver={() => this.BackStep()}
                                        />
                                    )
                                case 3:
                                    return (<Paso3
                                        formDataValidation={this.state.formData[2].validated}
                                        dataSaved={this.state.formData[2]}
                                        changeIndex={this.changeIndex.bind(this)}
                                        getFormData={this.getFormData}
                                        index={2}
                                        active={this.state.activeIndex === 2}
                                        producto={this.props.producto}
                                        handleAddProductAPI={() => this.handleAddProduct()}
                                        handleVolver={() => this.BackStep()}

                                        resetRedux={() => this.props.fetchEditProductReset()}
                                        backtoClasif={() => this.closeAndResetRedux()}
                                    />)
                                default:
                                    return null;
                            }
                        })()


                        :
                        <Loader />
                    }
                </PaperLayout >
            </Modal>
        )
    }
}
const mapStateToProps = ({
    clasificados,
    userInfo
}) => ({
    clasificados,
    fetchAddProductSuccess: clasificados.fetchAddProductSuccess,
    fetchAddProductFail: clasificados.fetchAddProductFail,
    loadingAddProduct: clasificados.loadingAddProduct,
    neighbourhoods: userInfo.neighbourhoods

})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchAddProduct, fetchEditProduct, fetchEditProductReset, fetchGetShippingMethods }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(EditProduct)
)