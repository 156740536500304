
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { logout } from '../../store/actions/auth.actions'
import { Message,Phone } from './styled'

import Button from '../../components/Button';
import Modal from '../../components/Modal';

class Error extends React.Component {
    state={
        redirect: false
    }
    
    handleRedirect=()=> {
        this.setState({redirect: true})

        localStorage.clear()
        this.props.logout()
    }
    render() {   
      const resolution= window.innerWidth;
      let responsive= false;
      if(resolution < 800){responsive= true}

      return (
            <Modal onClose={this.handleRedirect}>
                <Message responsive={responsive}>Usuario sin rol asignado, por favor comunicarse con soporte</Message>
                <Phone responsive={responsive} href={"https://api.whatsapp.com/send?phone=541130404433&text=Hola%20me%20comunico%20por%20un%20error%20en%20el%20backoffice%20mi%20usuario%20no%20tiene%20rol%20"+this.props.username}>+541130404433</Phone>
                <Button responsive={responsive} medium onClick={this.handleRedirect}>Entendido</Button>
            </Modal>
      );
    }
  }

  
const mapStateToProps = ({ userInfo }) => ({
    username: userInfo.username
  })
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
          logout
      },
      dispatch
    )
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Error))
  