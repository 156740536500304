import styled, { css } from "styled-components";
import theme from "../../../config/theme";

export const DetailContainer = styled.div`
  width: 230px;
  padding: 10px 15px;
  position: absolute;
  display: ${props => (props.display ? props.display : "flex")};

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  outline: none;
  border: none;
  z-index: 999;
  cursor: auto;

  ${({ bottomPopUp }) =>
    bottomPopUp
      ? css`
          bottom: 15px;
        `
      : css`
          top: 15px;
        `}

  ${({ leftPopUp }) =>
    leftPopUp
      ? css`
          right: 15px;
          border-right: 5px solid ${({ theme }) => theme.colors.midGray};
        `
      : css`
          left: 15px;
          border-left: 5px solid ${({ theme }) => theme.colors.midGray};
        `}
`;

export const AhrefWrapper = styled.div`
  margin:  ${({ margin }) => margin || 0.5}em;
  display:inline:
`;

export const Ahref = styled.a`
  cursor: pointer;
  color: ${({ color }) => color || theme.colors.midGray};
  text-decoration: underline;
`;

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
`;

export const SubTitle = styled.label`
  font-weight: bold;
`;

export const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 14px;
  margin-top: ${props => (props.margintop ? props.margintop : "10px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

export const Close = styled.img`
  width: 10px;
  cursor: pointer;
`;

export const CrossImage = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;
