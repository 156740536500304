import styled from 'styled-components';

export const KitsRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden;
    ${({ edit }) => edit && `
        border-bottom: solid 1px #f2f2f2;
    `}
`
export const BackIcon = styled.img`
    width: 12px;
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
`
export const Br = styled.div`
    width: 100%;
    border-bottom: solid 1px #f2f2f2;
`
export const Quantity = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2.3px;
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5.3px;
    height: 9.3px;
  }
  p {
    margin: 0px 10px;
  }
`
export const TrashIcon = styled.img`
    width: 18px;
    height: 20px;
`
export const PlusIcon = styled.img`
    width: 26px;
    height: 26px;
`
export const CheckIcon = styled.img`
    width: 25px;
    height: 19px;
`
export const StyledSelect = styled.div`
    width: 230px;
    & > div > div:first-child {
        height: 40px;
    }
    & span {
        margin: 0px;
    }
`
export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const Imagen = styled.img`
    cursor: pointer;
`

export const Ahref = styled.a`
    cursor: pointer;
`