import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'
import { fetchReporteExpensas } from '../actions/reportes.actions'


const initialState = {
  mascotas: [],
  loadingReporteMascotas: false,
  errorReporteMascotas: null,

  tickets: [],
  loadingReporteTickets: false,
  errorReporteTickets: null,

  reporteReservas: [],
  loadingReporteReserva: false,
  errorReporteReserva: false,

  reporteExpensas: null,
  loadingreporteExpensas: false,
  reporteExpensasSuccess: false,
  reporteExpensasFail: false,

  reporteClientsByNeighbourhood: null,
  loadingClientsByNeighbourhood: false,
  reporteClientsByNeighbourhoodSuccess: false,
  reporteClientsByNeighbourhoodFail: false,


  reporteBilleterasPorBarrio: null,
  loadingBilleterasPorBarrio: false,
  reporteBilleterasPorBarrioSuccess: false,
  reporteBilleterasPorBarrioFail: false,

  usersFunnelData:{
    neighbourhoods:[],
    global_nighbourhoods_data:{},
    global_nighbourhoods_percentages_data:{},
    open_market_data:{},
    open_market_percentages_data:{},
    global_miiii_data:{},
    global_miiii_percentages_data:{},
  },
  loadingFetchUsersFunnelData: false,
  fetchUsersFunnelDataSuccess: false,
  fetchUsersFunnelDataFail: false,
  errorFetchUsersFunnelData: null,

  propertiesFunnelData: {
    neighbourhoods:[],
    global_data:{},
    global_percentages_data:{}
  }
,
  loadingFetchPropertiesFunnelData: false,
  fetchPropertiesFunnelDataSuccess: false,
  fetchPropertiesFunnelDataFail: false,
  errorFetchPropertiesFunnelData: null

}


////////////////// Reporte Billetera por Barrio //////////////////
const fetchReporteBilleterasPorBarrioSuccess = (state, action) => {
  return updateObject(state, {
    reporteReporteBilleterasPorBarrio: action.data,
    loadingReporteBilleterasPorBarrio: false,
    reporteReporteBilleterasPorBarrioSuccess: true,
    reporteReporteBilleterasPorBarrioFail: false
  })
}

const fetchReporteBilleterasPorBarrioFail = (state, action) => {
  return updateObject(state, {
    reporteReporteBilleterasPorBarrio: null,
    loadingReporteBilleterasPorBarrio: false,
    reporteReporteBilleterasPorBarrioSuccess: false,
    reporteReporteBilleterasPorBarrioFail: action.error
  })
}

const fetchReporteBilleterasPorBarrioStart = (state) => {
  return updateObject(state, {
    reporteReporteBilleterasPorBarrio: null,
    loadingReporteBilleterasPorBarrio: true,
    reporteReporteBilleterasPorBarrioSuccess: false,
    reporteReporteBilleterasPorBarrioFail: false
  })
}


//////////////////reporteClientsByNeighbourhood//////////////////
const fetchReporteClientsByNeighbourhoodSuccess = (state, action) => {
  return updateObject(state, {
    reporteClientsByNeighbourhood: action.data,
    loadingClientsByNeighbourhood: false,
    reporteClientsByNeighbourhoodSuccess: true,
    reporteClientsByNeighbourhoodFail: false
  })
}

const fetchReporteClientsByNeighbourhoodFail = (state, action) => {
  return updateObject(state, {
    reporteClientsByNeighbourhood: null,
    loadingClientsByNeighbourhood: false,
    reporteClientsByNeighbourhoodSuccess: false,
    reporteClientsByNeighbourhoodFail: action.error
  })
}

const fetchReporteClientsByNeighbourhoodStart = (state) => {
  return updateObject(state, {
    reporteClientsByNeighbourhood: null,
    loadingClientsByNeighbourhood: true,
    reporteClientsByNeighbourhoodSuccess: false,
    reporteClientsByNeighbourhoodFail: false
  })
}


//////////////////reporteExpensas//////////////////
const fetchReporteExpensasSuccess = (state, action) => {
  return updateObject(state, {
    reporteExpensas: action.data,
    loadingreporteExpensas: false,
    reporteExpensasSuccess: true,
  })
}

const fetchReporteExpensasFail = (state, action) => {
  return updateObject(state, {
    reporteExpensas: null,
    loadingreporteExpensas: false,
    reporteExpensasSuccess: false,
    reporteExpensasFail: action.error,
  })
}

const fetchReporteExpensasStart = (state) => {
  return updateObject(state, {
    reporteExpensas: null,
    loadingreporteExpensas: true,
    reporteExpensasSuccess: false,
    reporteExpensasFail: false,
  })
}


//////////////////MASCOTAS//////////////////
const fetchReporteMascotasSuccess = (state, action) => {
  return updateObject(state, {
    mascotas: action.mascotas,
    loadingReporteMascotas: false
  })
}

const fetchReporteMascotasFail = (state, action) => {
  return updateObject(state, {
    loadingReporteMascotas: false,
    errorReporteMascotas: action.errorReporteMascotas
  })
}

const fetchReporteMascotasStart = (state) => {
  return updateObject(state, {
    loadingReporteMascotas: true,
    errorReporteMascotas: null
  })
}

//////////////////TICKETS//////////////////
const fetchReporteTicketsSuccess = (state, action) => {
  return updateObject(state, {
    tickets: action.tickets,
    loadingReporteTickets: false
  })
}

const fetchReporteTicketsFail = (state, action) => {
  return updateObject(state, {
    loadingReporteTickets: false,
    errorReporteTickets: action.errorReporteMascotas
  })
}

const fetchReporteTicketsStart = (state) => {
  return updateObject(state, {
    loadingReporteTickets: true,
    errorReporteTickets: null
  })
}

///////////////REPORTE RESERVA//////////////
const fetchReporteReservasSuccess = (state, action) => {
  return updateObject(state, {
    reporteReservas: action.reporteReservas,
    loadingReporteReserva: false
  })
}

const fetchReporteReservasFail = (state, action) => {
  return updateObject(state, {
    loadingReporteReserva: false,
    errorReporteReserva: action.errorReporteReserva
  })
}

const fetchReporteReservasStart = (state) => {
  return updateObject(state, {
    loadingReporteReserva: true,
    errorReporteReserva: null
  })
}

///////////////REPORTE RESERVA//////////////
const fetchReporteUserPropertySuccess = (state, action) => {
  return updateObject(state, {
    reporteProperty: action.reporteProperty,
    loadingReporteProperty: false
  })
}

const fetchReporteUserPropertyFail = (state, action) => {
  return updateObject(state, {
    loadingReporteProperty: false,
    errorReporteProperty: action.errorReporteProperty
  })
}

const fetchReporteUserPropertyStart = (state) => {
  return updateObject(state, {
    loadingReporteProperty: true,
    errorReporteProperty: null
  })
}

///////////////DOG REPORT - USERS FUNNEL//////////////
const fetchUsersFunnelDataSuccess = (state, action) => {
  return updateObject(state, {
    usersFunnelData: action.usersFunnelData,
    loadingFetchUsersFunnelData: false
  })
}

const fetchUsersFunnelDataFail = (state, action) => {
  return updateObject(state, {
    loadingFetchUsersFunnelData: false,
    errorFetchUsersFunnelData: action.errorFetchUsersFunnelData,
    fetchUsersFunnelDataFail: true
  })
}

const fetchUsersFunnelDataStart = (state) => {
  return updateObject(state, {
    loadingFetchUsersFunnelData: true,
    errorFetchUsersFunnelData: null,
    fetchUsersFunnelDataFail: false
  })
}

///////////////DOG REPORT - PROPERTIES FUNNEL//////////////
const fetchPropertiesFunnelDataSuccess = (state, action) => {
  return updateObject(state, {
    propertiesFunnelData: action.propertiesFunnelData,
    loadingFetchPropertiesFunnelData: false
  })
}

const fetchPropertiesFunnelDataFail = (state, action) => {
  return updateObject(state, {
    loadingFetchPropertiesFunnelData: false,
    errorFetchPropertiesFunnelData: action.errorFetchPropertiesFunnelData,
    fetchPropertiesFunnelDataFail: true
  })
}

const fetchPropertiesFunnelDataStart = (state) => {
  return updateObject(state, {
    loadingFetchPropertiesFunnelData: true,
    errorFetchPropertiesFunnelData: null,
    fetchPropertiesFunnelDataFail: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_REPORTE_MASCOTAS_SUCCESS:
      return fetchReporteMascotasSuccess(state, action)
    case actionTypes.FETCH_REPORTE_MASCOTAS_FAIL:
      return fetchReporteMascotasFail(state, action)
    case actionTypes.FETCH_REPORTE_MASCOTAS_START:
      return fetchReporteMascotasStart(state, action)

    case actionTypes.FETCH_REPORTE_TICKETS_SUCCESS:
      return fetchReporteTicketsSuccess(state, action)
    case actionTypes.FETCH_REPORTE_TICKETS_FAIL:
      return fetchReporteTicketsFail(state, action)
    case actionTypes.FETCH_REPORTE_TICKETS_START:
      return fetchReporteTicketsStart(state, action)

    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_SUCCESS:
      return fetchReporteReservasSuccess(state, action)
    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_FAIL:
      return fetchReporteReservasFail(state, action)
    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_START:
      return fetchReporteReservasStart(state, action)


    case actionTypes.FETCH_USERPROPERTY_REQUEST_SUCCESS:
      return fetchReporteUserPropertySuccess(state, action)
    case actionTypes.FETCH_USERPROPERTY_REQUEST_FAIL:
      return fetchReporteUserPropertyFail(state, action)
    case actionTypes.FETCH_USERPROPERTY_REQUEST_START:
      return fetchReporteUserPropertyStart(state, action)


    case actionTypes.FETCH_REPORTE_EXPENSES_SUCCESS:
      return fetchReporteExpensasSuccess(state, action)
    case actionTypes.FETCH_REPORTE_EXPENSES_FAIL:
      return fetchReporteExpensasFail(state, action)
    case actionTypes.FETCH_REPORTE_EXPENSES_START:
      return fetchReporteExpensasStart(state, action)


    case actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_SUCCESS:
      return fetchReporteClientsByNeighbourhoodSuccess(state, action)
    case actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_FAIL:
      return fetchReporteClientsByNeighbourhoodFail(state, action)
    case actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_START:
      return fetchReporteClientsByNeighbourhoodStart(state, action)


    case actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_SUCCESS:
      return fetchReporteBilleterasPorBarrioSuccess(state, action)
    case actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_FAIL:
      return fetchReporteBilleterasPorBarrioFail(state, action)
    case actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_START:
      return fetchReporteBilleterasPorBarrioStart(state, action)

    case actionTypes.FETCH_USERS_FUNNEL_DATA_SUCCESS:
      return fetchUsersFunnelDataSuccess(state, action)
    case actionTypes.FETCH_USERS_FUNNEL_DATA_FAIL:
      return fetchUsersFunnelDataFail(state, action)
    case actionTypes.FETCH_USERS_FUNNEL_DATA_START:
      return fetchUsersFunnelDataStart(state, action)

    case actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_SUCCESS:
      return fetchPropertiesFunnelDataSuccess(state, action)
    case actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_FAIL:
     return fetchPropertiesFunnelDataFail(state, action)
    case actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_START:
      return fetchPropertiesFunnelDataStart(state, action)

    default:
      return state
  }
}

export default reducer