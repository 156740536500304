import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'

const initialState = {
  rows: [],
  communitiesPage: 0,
  communitiesPageSize: 10,
  communitiesTotal: 0,
  // isLoading: false,
  communitiesOrderColumn: 'name',
  communitiesOrderDirection: 'asc',
  communitiesSearchText: '',
  //FETCH ALL COMMUNITIES
  communities: [],
  loadingFetchCommunities: false,
  errorFetchCommunities: null,
  fetchCommunitiesSuccess: false,

  //FETCH COMMUNITY
  communityDetail: [],
  loadingFetchCommunity: false,
  errorFetchCommunity: null,
  fetchCommunitySuccess: false,

  //FETCH COMMUNITY STATES
  communityStates: [],
  loadingFetchCommunityStates: false,
  errorFetchCommunityStates: null,
  fetchCommunityStatesSuccess: false,

  //ACTIVATE COMMUNITY
  loadingActivateCommunity: false,
  errorActivateCommunity: null,
  activateCommunitySuccess: false,
}

//////////////////COMMUNITTIES//////////////////
const fetchCommunitiesSuccess = (state, action) => {
  return updateObject(state, {
    communities: action.communities,
    loadingFetchCommunities: false
  })
}


const fetchCommunitiesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchCommunities: false,
    errorFetchCommunities: action.errorFetchCommunities,
    communities: []
  })
}

const fetchCommunitiesStart = (state) => {
  return updateObject(state, {
    loadingFetchCommunities: true,
    errorFetchCommunities: null
  })
}

const updateCommunitiesGrid = (state, action) => {
  return updateObject(state, {
    communitiesPage: action.pageNumber,
    communitiesPageSize: action.pageSize,
    communitiesTotal: action.total,
    communitiesOrderColumn: action.orderColumn,
    communitiesOrderDirection: action.orderDirection,
    communitiesSearchText: action.searchText
  })
}

//////////////////FETCH COMMUNITY//////////////////
const fetchCommunitySuccess = (state, action) => {
  return updateObject(state, {
    communityDetail: action.communityDetail,
    loadingFetchCommunity: false
  })
}


const fetchCommunityFail = (state, action) => {
  return updateObject(state, {
    loadingFetchCommunity: false,
    errorFetchCommunity: action.errorFetchCommunity
  })
}

const fetchCommunityStart = (state) => {
  return updateObject(state, {
    loadingFetchCommunity: true,
    errorFetchCommunity: null
  })
}

const fetchCommunityReset = (state) => {
  return updateObject(state, {
    loadingFetchCommunity: false,
    errorFetchCommunity: null,
    communityDetail: []
  })
}

//////////////////FETCH  COMMUNITY STATES//////////////////
const fetchCommunityStatesSuccess = (state, action) => {
  return updateObject(state, {
    communityStates: action.communityStates,
    loadingFetchCommunityStates: false
  })
}


const fetchCommunityStatesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchCommunityStates: false,
    errorFetchCommunityStates: action.errorFetchCommunityStates
  })
}

const fetchCommunityStatesStart = (state) => {
  return updateObject(state, {
    loadingFetchCommunityStates: true,
    errorFetchCommunityStates: null
  })
}

const fetchCommunityStatesReset = (state) => {
  return updateObject(state, {
    loadingFetchCommunityStates: false,
    errorFetchCommunityStates: null,
    communityStates: []
  })
}


////////////////// COMMUNITY ACTIVATE//////////////////
const activateCommunitySuccess = (state, action) => {
  return updateObject(state, {
    loadingActivateCommunity: false,
    activateCommunitySuccess: true
  })
}


const activateCommunityFail = (state, action) => {
  return updateObject(state, {
    loadingActivateCommunity: false,
    errorActivateCommunity: action.errorActivateCommunity
  })
}

const activateCommunityStart = (state) => {
  return updateObject(state, {
    loadingActivateCommunity: true,
    errorActivateCommunity: null
  })
}

const activateCommunityReset = (state) => {
  return updateObject(state, {
    loadingActivateCommunity: false,
    activateCommunitySuccess: false,
    errorActivateCommunity: null,
  })
}

const clearCommunitiesPage = (state) => {
  return updateObject(state, {
    communitiesPage: 0,
    communitiesPageSize: 10,
    communitiesTotal: 0,
    communitiesOrderColumn: 'name',
    communitiesOrderDirection: 'asc',
    communitiesSearchText: '',
    communities: [],
  })
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH COMMUNITTIES
    case actionTypes.FETCH_COMMUNITIES_START:
      return fetchCommunitiesStart(state, action)
    case actionTypes.FETCH_COMMUNITIES_FAIL:
      return fetchCommunitiesFail(state, action)
    case actionTypes.FETCH_COMMUNITIES_SUCCESS:
      return fetchCommunitiesSuccess(state, action)
    case actionTypes.UPDATE_COMMUNITIES_GRID:
      return updateCommunitiesGrid(state, action)

    //FETCH COMMUNITTY
    case actionTypes.FETCH_COMMUNITY_START:
      return fetchCommunityStart(state, action)
    case actionTypes.FETCH_COMMUNITY_FAIL:
      return fetchCommunityFail(state, action)
    case actionTypes.FETCH_COMMUNITY_SUCCESS:
      return fetchCommunitySuccess(state, action)
    case actionTypes.FETCH_COMMUNITY_RESET:
      return fetchCommunityReset(state, action)

    //FETCH COMMUNITY STATES
    case actionTypes.FETCH_COMMUNITY_STATES_START:
      return fetchCommunityStatesStart(state, action)
    case actionTypes.FETCH_COMMUNITY_STATES_FAIL:
      return fetchCommunityStatesFail(state, action)
    case actionTypes.FETCH_COMMUNITY_STATES_SUCCESS:
      return fetchCommunityStatesSuccess(state, action)
    case actionTypes.FETCH_COMMUNITY_STATES_RESET:
      return fetchCommunityStatesReset(state, action)

      //FETCH COMMUNITY ACTIVATE
    case actionTypes.ACTIVATE_COMMUNITY_START:
      return activateCommunityStart(state, action)
    case actionTypes.ACTIVATE_COMMUNITY_FAIL:
      return activateCommunityFail(state, action)
    case actionTypes.ACTIVATE_COMMUNITY_SUCCESS:
      return activateCommunitySuccess(state, action)
    case actionTypes.ACTIVATE_COMMUNITY_RESET:
      return activateCommunityReset(state, action)

      //CLEAR COMMUNITY PAGE
      case actionTypes.CLEAR_COMMUNITIES_PAGE:
        return clearCommunitiesPage(state, action)
    default:
      return state
  }
}

export default reducer