
import { createSelector } from 'reselect'
import { getFormattedDateYearNoDays } from '../utils/dateParser'

const mapExpenses = createSelector(
  data => data,
  data => data.expense_list.map((e, i) => ({
    fecha: getFormattedDateYearNoDays(e.month_year),
    barrio: e.neighbourhood_name,
    pos: i,
    pago: 'ARS ' + e.total_paid
  })
  )
)

export default mapExpenses

