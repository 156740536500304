import React from 'react';
import Input from '../../../components/Input';
import StatusBadge from '../../../components/StatusBadge';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik } from 'formik'
import * as Yup from 'yup'



import mapNeighbourhoodsMasterData from '../../../selectors/mapNeighbourhoodsMasterData'

import mapBilleterasPorBarrio from '../../../selectors/mapBilleterasPorBarrio'
import mapLots from '../../../selectors/mapLots';
//import mapSpaces from '../../../selectors/mapSpaces';


import { fetchAllCategories, fetchTypesByCategory } from '../../../store/actions/tickets.actions';
import { fetchLotsByNeighbourhood } from '../../../store/actions/lotes.actions';
import { fetchReporteBilleterasPorBarrio } from '../../../store/actions/reportes.actions';

import {
  FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel
} from '../styled';


class Billetera extends React.Component {

  state = {
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,

    typesEnabled: false
  }

  getInitialValues = () => ({

  })

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        date_from: Yup.string().required('Ingrese una fecha'),
        date_to: Yup.string().required('Ingrese una fecha')
      })
    )

  handleSubmit = data => {
    this.props.fetchReporteBilleterasPorBarrio(data)
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>

        <Row align="flex-end">
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Cuit'}</InputLabel>

            <Input
              type="text"

              onChange={({ target: { value } }) => setFieldValue('client_cuit', value)}

              placeholder={'CUIT'}
              value={values.client_cuit}
              style={{ width: '100%' }}
            />
            {errors.client_cuit && <ErrorLabel>{errors.client_cuit}</ErrorLabel>}
          </InputWrapper>


          <InputWrapper margin='20px 15px'>
            <InputLabel>{'Desde'}</InputLabel>
            <DatePickerStyled
              selected={values.date_from}
              startDate={values.date_from}
              endDate={values.date_}
              selectsStart
              showTimeSelect
              dateFormat="HH:mm dd/MM/yyy"
              onChange={date_from => setFieldValue('date_from', date_from)}

              placeholderText="Fecha desde"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.date_from && <ErrorLabel>{errors.date_from}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Hasta'}</InputLabel>
            <DatePickerStyled
              selected={values.date_to}
              startDate={values.date_to}
              endDate={values.date_to}
              selectsEnd
              showTimeSelect
              dateFormat="HH:mm dd/MM/yyy"
              onChange={date_to => setFieldValue('date_to', date_to)}
              minDate={values.date_from || null}
              placeholderText="Fecha hasta"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.date_to && <ErrorLabel>{errors.date_to}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputWrapper margin={'20px 15px'}>
              <Button type={'submit'} medium width={'100%'}>
                {'Filtrar'}
              </Button>
            </InputWrapper>
          </InputWrapper>
        </Row>
      </FormContainer>

    </>
  );

  componentDidMount() {
    //this.props.fetchReporteBilleterasPorBarrio()
  }

  render() {
    return (
      <>
        <SectionHeader title='Billetera por Barrio'>
          <Input
            type="text"
            search={true}
            placeholder={'Buscar'}
            value={this.props.search}
            onChange={(e) => this.props.searchHandleChange(e)}
          />
        </SectionHeader>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
        {this.props.loadingReporteTickets ? 'cargando...' :

          <RitzTable
            columns={[
              { title: 'Título', field: 'client_cuit' },
              { title: 'Periodo', field: 'client_full_name' },
              { title: 'Lote', field: 'client_full_name' },
              { title: 'Importe', field: 'importe_formatedd' },
              {
                title: 'Estado', field: 'state_code',
                render: rowData => {
                  return (
                    <>
                      {(() => {
                        switch (rowData.state_code) {
                          case 'PENDING':
                            return (
                              <StatusBadge red>Pendiente</StatusBadge>
                            )
                          case 'COMPLETED':
                            return (
                              <StatusBadge green>Completado</StatusBadge>
                            )
                          default:
                            return (
                              <StatusBadge>{rowData.state_code}</StatusBadge>
                            );
                        }
                      })()}
                    </>
                  )
                },
              }
            ]}
            data={this.props.reporteBilletera}
            isLoading={this.props.loadingReporteBilleterasPorBarrio}
            options={{
              toolbar: true,
              selection: false,
              search: false,
              paging: true,
              serverSide: false,
              exportButton: true,
              exportAllData: true,
              exportFileName: "Reporte Billeteras por Barrio." + (new Date().toISOString().split('.')[0]),
            }}

          />
        }
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    reporteBilletera: state.reportes.reporteReporteBilleterasPorBarrio ? mapBilleterasPorBarrio(state.reportes.reporteReporteBilleterasPorBarrio.result_list) : [],
    loadingReporteBilleterasPorBarrio: state.reportes.loadingReporteBilleterasPorBarrio
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReporteBilleterasPorBarrio
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Billetera)
