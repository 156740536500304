import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components';
import notification from './../assets/icons/notification.png';
import help from './../assets/icons/help.png';
import women from './../assets/images/women.png';
import UserMenu from './UserMenu';

export const Container = styled.div`
  width: 100%;
  padding: 0px 25px;
  // padding: 0px 3rem;
  height: 100vh;
  display: flex;
  overflow: scroll;
  overflow-x: hidden;
  flex-direction: column;
  background-color:  #f9f9fb;
  ${({ hideMenu }) => hideMenu && `
  width: 100%;
  `}
  @media (max-width: 350px ) {
    overflow: hidden;
  }
  @media (max-width: 400px ) and (min-width: 350px ) {
    width: 100.6%;
    overflow: hidden;
  }
  @media (max-width: 767px ) and (min-width: 400px ) {
    width: 103%;
    overflow: hidden;
  }
  `
export const Menu = styled.div`
  flex-direction: column;
  height: 100vh;
  min-width: 200px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
    ${({ hideMenu }) => hideMenu && `
      display: none;
    `}
  `
export const Page = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`
export const Title = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 30px;
  width: 100%;
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0.05mm;
  text-align: center;
`
export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  max-width: 300px;
  cursor: pointer;
  position: relative;
`
export const Header = styled.div`
  line-height: 40px;
  min-height: 60px;
  width: 100%;
  margin: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: solid 1px #f2f2f2;
`
export const HeaderContainer = styled.div`
  min-width: 260px;  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
export const ImgStyled = styled.img`
  height: 30px;
  width: 30px;
`
export const ElementContainer = styled.div`
  display: flex;
  // margin: ${({ margin }) => margin ? margin : "0% 10px"}; 
`
export const Avatar = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 100%; 
  margin-right: 10px;
`
class NavBar extends React.Component {

  state = { usermenu: false }

  toggleUserMenu = () => {
    this.setState({ usermenu: !this.state.usermenu })
  }

  render() {
    const { children, hideMenu, overflowHidden } = this.props;
    // Since Employees Tab doesnt use the Menu, I'll hide it with this, instead of making a stand alone Header Component.
    //const hideMenu = this.props.title === 'Employees' ? true : false;
    return (
      <>
        <Menu hideMenu={hideMenu}>
          <Title>{this.props.title}</Title>
          {this.props.menuListComponent}
        </Menu>
        <Container overflowHidden={overflowHidden} hideMenu={hideMenu}>
          <Header>
            <HeaderContainer>
              <ElementContainer margin={"0% 0px 0px 10px"}>
                <UserInfoContainer onClick={() => this.toggleUserMenu()}>
                  {this.state.usermenu ? <UserMenu /> : null}
                  {this.props.user.profile_picture ?
                    <Avatar src={this.props.user.profile_picture} /> : null}
                  {this.props.user.name + " " + this.props.user.family_name}
                </UserInfoContainer>
              </ElementContainer>
            </HeaderContainer>
          </Header>
          <Page>
            {children}
          </Page>
        </Container>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.userInfo
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)