import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CrossIcon from '../../../../assets/icons/close.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import TextArea from '../../../../components/TextArea'
import Button from '../../../../components/Button';
import Spinner from 'react-spinner-material';
import Select from 'react-select'
import FileInput from '../../../../components/InputFile'
import FileImageInput from '../../../../components/FileImageInput'
import NoFilePdf from '../../../../assets/icons/upload-pdf.svg'
import NoFile from '../../../../assets/icons/plus.png'
import StyledSelect from '../../../../components/StyledSelect';
import { fetchBankAccounts, fetchBankAccountsReset } from '../../../../store/actions/paymentClients.actions';
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  FlexWrapper,
  SpinerWrapper,
  Title,
  ResetIcon,
  ProgressBarWrapper,
  SelectWrapper
} from './styled'

const mapStateToProps = state => {
  return {
    bankAccounts: state.paymentClients.bankAccounts,
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchBankAccounts, fetchBankAccountsReset, },
    dispatch
  )

function ClientModal(props) {

  const { paymentClient, isReadOnly, isSubmitting, notificationRequestFormat, bankAccounts, merchantTypes, loadingFetchBankAccounts, fetchBankAccountsSuccess, errorFetchBankAccounts } = props;

  const getShopsOptions = () => {
    return props.shops.map(n => ({ label: n.name, value: n.shop_store_guid }))
  }

  const getNotificationOptions = () => {
    if (notificationRequestFormat != null && notificationRequestFormat.length > 0) {
      return props.notificationRequestFormat.map(n => ({ label: n.description, value: n.id }))
    }
  }

  const getMerchantTypeOptions = () => {
    var merchantTypes = [{ label: 'No aplica', value: 0 }];
    if (props.merchantTypes != null && props.merchantTypes.length > 0)
      merchantTypes = merchantTypes.concat(props.merchantTypes.map(n => ({ label: n.code + ' - ' + n.description, value: n.id })))
    return merchantTypes
  }

  const getBankAccountsOptions = () => {
    if (bankAccounts != null && bankAccounts.length > 0)
      return bankAccounts.map(n => ({ label: formatLabel(n), value: n.Id }))
  }

  const getCustodyBankAccountsOptions = () => {
    if (bankAccounts != null && bankAccounts.length > 0) {
      return bankAccounts.map(n => ({ label: formatLabel(n), value: n.Id }))
    }
  }


  const formatLabel = (bankAccount) => {
    let labelPhrase = "";
    if(bankAccount.ClientAppUserName && bankAccount.ClientAppUserName != ""){
        labelPhrase = `${bankAccount.ClientAppUserName} - CUIT: ${bankAccount.HolderCuit} - Moneda: ${bankAccount.Currency}`;
    }else {
        labelPhrase = `CUIT: ${bankAccount.HolderCuit} - Moneda: ${bankAccount.Currency}`;
    }
    return labelPhrase;
  }

  const fetchBankAccountsByShop = async (setFieldValue, option) => {
    setFieldValue('neighbourhoodGuid', option.value);
    await props.fetchBankAccounts(option.value);
    setFieldValue('bank_account_id', '');
    setFieldValue('bank_custody_account_id', '');
  }


  const getInitialValues = () => {
    return ({
      id: paymentClient ? paymentClient.id : 0,
      description: paymentClient ? paymentClient.description : '',
      name: paymentClient ? paymentClient.name : '',
      logo_url: paymentClient ? paymentClient.logo_url : '',
      notification_url: paymentClient ? paymentClient.notification_url : '',
      bank_account_id: paymentClient ? paymentClient.bank_account_id : 0,
      neighbourhoodGuid: paymentClient ? paymentClient.neighbourhoodGuid : '',
      token: paymentClient ? paymentClient.token : '',
      isEdit: paymentClient ? true : false,
      bank_custody_account_id: paymentClient ? paymentClient.bank_custody_account_id : null,
      notificationRequestFormat: paymentClient ? paymentClient.notification_request_format.id : 0,
      merchantType: paymentClient && paymentClient.merchant_number ? paymentClient.merchant_number.id : 0,
      appClientId: paymentClient && paymentClient.app_client_id ? paymentClient.app_client_id : '',
    })
  };


  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        description: Yup.string().required('Campo Obligatorio'),
        name: Yup.string().required('Campo Obligatorio'),
        // logo_url: Yup.string().required('Seleccione un logo'),
        logo_url: Yup.object().nullable().required('Seleccione un logo'),
        notification_url: Yup.string().required('Campo Obligatorio'),
        bank_account_id: Yup.number()
          .test('is-equal-to-bank-custody-account-id',
            'No debe ser igual a la cuenta custodia del shop',
            (value, context) => values.bank_custody_account_id !== value)
            .test('notZero','Campo Obligatorio', value=> value!=null && value !=0),
        neighbourhoodGuid: Yup.string().required('Campo Obligatorio'),
        bank_custody_account_id: Yup.number().nullable()
          .test('is-equal-to-bank-account-id',
            'No debe ser igual a la cuenta bancaria del shop',
            (value, context) => values.bank_account_id !== value),
        neighbourhoodGuid: Yup.string().required('Campo Obligatorio')
      })
    );




  const getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => {

    const shopOptions = getShopsOptions();
    const bankAccountOptions = getBankAccountsOptions();
    const selectedBankAccount = bankAccountOptions && bankAccountOptions.length > 0 && values.bank_account_id ? bankAccountOptions.find(option => option.value === values.bank_account_id) : ''
    const custodyBankAccountOptions = getCustodyBankAccountsOptions(selectedBankAccount);
    const selectedCustodyBankAccount = custodyBankAccountOptions && custodyBankAccountOptions.length > 0 && values.bank_custody_account_id ? custodyBankAccountOptions.find(option => option.value === values.bank_custody_account_id) : ''
    const merchantTypeOptions = getMerchantTypeOptions();
    const notificationOptions = getNotificationOptions();

    return (
      <Form onSubmit={handleSubmit}>
        <FlexWrapper>
          <FlexWrapper width={70}>
            <InputWrapper>
              <InputLabel>{'Nombre'}</InputLabel>
              <Input
                fullwidth={true}
                name={'nameInput'}
                label={'nameInput'}
                placeholder={'Ingrese un titulo...'}
                value={values.name}
                error={errors.name}
                onChange={({ target: value }) => setFieldValue('name', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              />
              {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Descripción'}</InputLabel>
              <TextArea
                height={'7.5em'}
                name={'descriptionInput'}
                label={'descriptionInput'}
                placeholder={'Ingrese una descripción...'}
                value={values.description}
                error={errors.description}
                onChange={({ target: value }) => setFieldValue('description', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              ></TextArea>
              {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper width={30}>
            <InputWrapper >
              <InputLabel>{'Logo'}</InputLabel>
              <FileImageInput
                width="100%"
                value={values.logo_url}
                onChange={logo_url => setFieldValue('logo_url', logo_url)}
                disabled={props.isReadOnly ? props.isReadOnly : false} />
              {errors.logo_url && <ErrorLabel textAlign="center">{errors.logo_url}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <InputWrapper>
              <InputLabel>{'Shop'}</InputLabel>
              <StyledSelect
                fullwidth={true}
                name={'shopGuidInput'}
                label={'shopGuidInput'}
                value={shopOptions ? shopOptions.find(option => option.value === values.neighbourhoodGuid) : ''}
                error={errors.neighbourhoodGuid}
                placeholder="Seleccione un shop..."
                isDisabled={props.isReadOnly ? props.isReadOnly : false}
                options={shopOptions}
                onChange={option => fetchBankAccountsByShop(setFieldValue, option, values)}
                margin="normal"
              />
              {errors.neighbourhoodGuid && <ErrorLabel>{errors.neighbourhoodGuid}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Cuenta Bancaria'}</InputLabel>
              <StyledSelect
                fullwidth={true}
                name={'bankAccountIdInput'}
                label={'bankAccountIdInput'}
                value={selectedBankAccount}
                // value={values.bank_account_id} 
                // defaultValue={setBankAccountField(setFieldValue)}
                error={errors.bank_account_id}
                placeholder="Seleccione una cuenta bancaria..."
                options={bankAccountOptions}
                isDisabled={props.isReadOnly ? true : false}
                isLoading={props.isLoading}
                enableReinitialize={false}
                onChange={option => setFieldValue('bank_account_id', option.value)}
                margin="normal"
              />
              {errors.bank_account_id && <ErrorLabel>{errors.bank_account_id}</ErrorLabel>}
            </InputWrapper>


            <InputWrapper>
              <InputLabel>{'Cuenta Custodia'}</InputLabel>
              <StyledSelect
                fullwidth={true}
                name={'custodyBankAccountIdInput'}
                label={'custodyBankAccountIdInput'}
                value={selectedCustodyBankAccount}

                error={errors.bank_custody_account_id}
                placeholder="Seleccione una cuenta custodia..."
                options={custodyBankAccountOptions}
                isDisabled={props.isReadOnly ? true : false}
                isLoading={props.isLoading}
                enableReinitialize={false}
                onChange={option => setFieldValue('bank_custody_account_id', option.value)}
                margin="normal"
              />
              {errors.bank_custody_account_id && <ErrorLabel>{errors.bank_custody_account_id}</ErrorLabel>}
            </InputWrapper>



            <InputWrapper width={50}>
              <InputLabel>{'Tipo de Notificación'}</InputLabel>
              <StyledSelect
                fullwidth={true}
                name={'notificationRequestFormatInput'}
                label={'notificationRequestFormatInput'}
                value={notificationOptions ? notificationOptions.find(option => option.value === values.notificationRequestFormat) : ''}
                error={errors.neighbourhoodGuid}
                placeholder="Seleccione un tipo de notificación..."
                isDisabled={props.isReadOnly ? props.isReadOnly : false}
                options={notificationOptions}
                onChange={option => setFieldValue('notificationRequestFormat', option.value)}
                margin="normal"
              />
              {errors.notificationRequestFormat && <ErrorLabel>{errors.notificationRequestFormat}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={50}>
              <InputLabel>{'Tipo de comercio'}</InputLabel>
              <StyledSelect
                fullwidth={true}
                name={'merchantTypeInput'}
                label={'merchantTypeInput'}
                value={merchantTypeOptions ? merchantTypeOptions.find(option => option.value === values.merchantType) : ''}
                error={errors.merchantType}
                placeholder="Seleccione un tipo de comercio..."
                isDisabled={props.isReadOnly ? props.isReadOnly : false}
                options={merchantTypeOptions}
                onChange={option => setFieldValue('merchantType', option.value)}
                margin="normal"
              />
              {errors.merchantType && <ErrorLabel>{errors.merchantType}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={50}>
              <InputLabel>{'Notificación URL'}</InputLabel>
              <Input
                fullwidth={true}
                width={'100%'}
                name={'notificationUrlInput'}
                label={'notificationUrlInput'}
                placeholder={'Ingrese una URL...'}
                value={values.notification_url}
                error={errors.notification_url}
                onChange={({ target: value }) => setFieldValue('notification_url', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              />
              {errors.notification_url && <ErrorLabel>{errors.notification_url}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={50}>
              <InputLabel>{'ClientCredentials AppClientId'}</InputLabel>
              <Input
                fullwidth={true}
                width={'100%'}
                name={'appClientIdInput'}
                label={'appClientIdInput'}
                placeholder={'Ingrese un ClientCredentials AppClientId...'}
                value={values.appClientId}
                error={errors.appClientId}
                onChange={({ target: value }) => setFieldValue('appClientId', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              />
              {errors.appClientId && <ErrorLabel>{errors.appClientId}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          {isReadOnly ?
            <InputWrapper>
              <InputLabel>{'Token'}</InputLabel>
              <Input
                fullwidth={true}
                width={'100%'}
                name={'token'}
                label={'token'}
                value={values.token}
                error={errors.token}
                onChange={({ target: value }) => setFieldValue('token', value.value)}
                margin="normal"
                disabled={true}
              />
            </InputWrapper> : null
          }
        </FlexWrapper>
        {
          !isReadOnly ? <FlexWrapper>
            <ButtonWrapper>
              <Button type={'submit'} loading={isSubmitting}>
                Publicar
              </Button>
            </ButtonWrapper>
          </FlexWrapper> : null
        }
      </Form >
    )
  }

  const form = (() => {
    if (props.isLoading) {
      return (<SpinerWrapper><Spinner /></SpinerWrapper>);
    }
    return (<Formik
      initialValues={getInitialValues()}
      validateOnChange={false}
      validationSchema={getSignUpSchema()}
      onSubmit={props.onSubmit}
      render={e => getFormContent(e)}
    />);
  });

  return (
    <Modal title={props.title} onClose={props.onClose} height={'min-height'} >
      {form()}
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientModal)