import React from 'react'
import styled from 'styled-components';
import ArrowBack from '@material-ui/icons/ArrowBack'
import CircularButton from '../components/CircularButton';
import theme from "../config/theme"

export const SectionHeaderContainer = styled.div`
  margin: 20px 0px;
  height: auto;
  display: flex;
  flex-direction: row;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'flex-normal')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
`
export const Left = styled.div`
  font-size: ${({ subtitle }) => (subtitle ? '1.125rem;' : '1.5rem')};
  white-space: nowrap;
`
export const Right = styled.div`
  display: flex;
  & >* {
    margin-left: 10px;
  }
`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.midGray};
        width: 35px;
    height: 35px;
`

class SectionHeader extends React.Component {
  render() {
    return (
      <SectionHeaderContainer justifyContent={this.props.justifyContent} 
      alignItems={this.props.alignItems}
       fontWeight={this.props.fontWeight}>
        {this.props.onClose ? (
          <CircularButton onClick={this.props.onClose}>
            <IconBack theme={theme}  />
          </CircularButton>
        ) : (<></>)
        }
        <Left subtitle={this.props.subtitle}>{this.props.title}</Left>
        <Right>{this.props.children}</Right>
      </SectionHeaderContainer>
    )
  }
}

export default SectionHeader;