import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 100%;
  // height: 90vh;
  height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};

`

export const Container = styled.div`
  position: relative;
  display: flex;
  padding: 5%;
  padding-bottom: 0;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ error }) => (error ? 0 : 6)}px;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  height: ${({ center }) => center ? "8vh" : "auto"};
  margin:  ${({ margin }) => margin || 0}; 
  display:  ${({ center }) => center ? "flex" : "auto"}; 
  justify-content: ${({ center }) => center ? "center" : "auto"};  
  align-items: ${({ center }) => center ? "center" : "auto"};  


`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
    margin: 5px 0px;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`
export const ErrorImages = styled.label`
display: block;
font-family: ${({ theme }) => theme.fonts.roboto};
color: ${({ theme }) => theme.colors.red};
font-size: 14px;
margin-top: -5%;
font-weight: 500;
`



export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.greenOcean};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`
export const CancelButton = styled.button`
  background-color: red;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: white;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 50px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
`

export const ButtonWrapperPop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`

export const ResetIcon = styled.img`
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
`

export const LabelChecked = styled.label`
    font-size: 20px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')}
`
export const Barrio = styled.label`
    font-size: 18x;
    font-weight: bold;
    margin-right: 5px;
`

export const DocumentName = styled.div`
   color: green;
   font-size: 16px;
   margin-top: 12px;
`


