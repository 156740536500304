import React, {useEffect} from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'

const TableWrapper = styled.div`
  height:100%;
  width: 100% !important;
  .ReactTable {
    width: 100% !important;
    height:100%;
  } 

  .rt-table {
    display: flex;
    width: 100% !important;
    height:100%;
    flex-direction: column;
  }

  .rt-tbody {
    max-height: 100vh;
    overflow-y: scroll;
    border-left: 1px solid ${({ theme }) => theme.colors.mystic};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mystic}; 

    .rt-tr-group:first-child {
      border-top: none;
      outline: none;
    }

    .rt-tr-group {
      border-top: 1px solid ${({ theme }) => theme.colors.mystic};
      outline: none;
    }

    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }  
  }

  .rt-thead {
    border-top: 1px solid ${({ theme }) => theme.colors.mystic};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mystic};
    border-right: 1px solid ${({ theme }) => theme.colors.mystic};
    outline: none;
  }

  .rt-thead,
  .rt-tbody {
    width: 100% !important;
    min-width: auto !important;

    .rt-tr {
      display: flex;
      width: 100% !important;
      min-width: auto !important;
      outline: none;

      .rt-th {
        flex: 1 !important;
        outline: none;
      }

      .rt-th > div {
        height: 100%;
        outline: none;
      }

      .rt-td {
        flex: 1 !important;
        outline: none;
      }
    }
  }

  .-loading {
    display: none;
  }
` 
const Table = ({ data, columns }) => { 
  const executeScroll = (scrollPosition) =>{ 
      let tableBody = document.getElementsByClassName('rt-tbody')[0];
      if (tableBody) {
        tableBody.scrollTop = scrollPosition;
      }
  };

  useEffect(()=>{executeScroll(832)},[])
return(
  <TableWrapper>
    <ReactTable 
      data={data}
      columns={columns}
      showPagination={false}
      sortable={false}
      defaultPageSize={97}
      loading={false}
    />
  </TableWrapper>
)}

export default Table
