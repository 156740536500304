import { createSelector } from "reselect";
var moment = require("moment");
const mapSchedule = createSelector(
  schedules => schedules,
  schedules =>
    schedules.map(schedule => ({
      id: schedule.id,
      space_id: schedule.space_id,
      schedule_type_id: schedule.schedule_type_id,
      start_date: schedule.start_date,
      end_date: schedule.end_date,
      price: schedule.price,
      parent_schedule_id: schedule.parent_schedule_id,
      creation_date: schedule.creation_date,
      created_by: schedule.created_by,
      description: schedule.description,
      is_serie: schedule.is_serie,
      available_places: schedule.available_places,
      bookings_quantity: schedule.bookings_quantity,
      from: schedule.from.substring(0, 5),
      to: schedule.to.substring(0, 5),
      schedule_type_id: schedule.schedule_type_id,
      schedule_settings: schedule.schedule_settings
    }))
);

export default mapSchedule;
