import React, { Component } from 'react'

import Select from 'react-select';
import styled, { css } from 'styled-components'

import { Wrapper, Title, StyledButton, Link,WrapperLink } from "../styled"
import Input from '../../../../components/Input'
import Button from '../../../../components/Button';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import {
    fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,

    fetchPaymentMethods, fetchShippingMethods, fetchItemConditions, fetchGetCategories,

    fetchAddProduct, fetchEditProduct
} from '../../../../store/actions/clasificados.actions'


const ErrorLabel = styled.div`  
    color: red;
`
const GridContainer = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-bottom: 25px;
    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const GridContainerImagenes = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`




const GridContainerDataMapped = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const talles = [{ label: 'XS', value: '0' },
{ label: 'S', value: '1' },
{ label: 'M', value: '2' },
{ label: 'L', value: '3' },
{ label: 'X', value: '4' },
{ label: 'XL', value: '5' }];


const colores = [{ label: 'Rojo', value: '0' },
{ label: 'Verde', value: '1' },
{ label: 'Amarillo', value: '2' },
{ label: 'Azul', value: '3' },
{ label: 'Violeta', value: '4' },
{ label: 'Blanco', value: '5' }];


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ARS',
});
class Paso2 extends Component {
    state = {
        step: 1,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }


    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (

        <form onSubmit={handleSubmit} key='1' style={{ width: '100%', marginTop: '4%' }}>

            <Wrapper>

                <GridContainer>
                    <Title>Importe</Title>
                    <Input
                        name={'importe'}
                        label={'importe'}
                        placeholder={'Importe'}
                        value={"ARS " + values.importe}
                        error={errors.importe}
                        onChange={({ target: { value } }) => setFieldValue('importe',  value.slice(4))}
                    />
                    <Title>Stock</Title>
                    <Input
                        name={'stock'}
                        label={'stock'}
                        placeholder={'Stock'}
                        value={values.stock}
                        error={errors.stock}
                        onChange={({ target: { value } }) => setFieldValue('stock', value)}
                    />

                    <Title>Condicion del producto</Title>
                    <GridContainer>
                        <Select
                            name={'itemCondition'}
                            label={'itemCondition'}
                            autosize={false}
                            value={values.itemCondition}
                            onChange={value => setFieldValue('itemCondition', value)}
                            placeholder="Condición del producto/item"
                            options={
                                this.props.clasificados.itemConditions.values.length > 0 ?
                                    this.props.clasificados.itemConditions.values.map(n =>
                                        ({ label: n.name, value: n.id })) : [{ value: null, label: "Loading..." }]}
                        />
                        {errors.itemCondition && <ErrorLabel>{errors.itemCondition}</ErrorLabel>}
                    </GridContainer>
                </GridContainer>


                <Button type={'submit'} >Continuar</Button>
                <WrapperLink>
                    <Link href="#" onClick={() => this.props.handleVolver()}>{'<< Volver'}</Link>
                </WrapperLink>

            </Wrapper>
        </form>
    )


    openWebcamModal = () => {
        this.setState({
            cameraModalOpen: !this.state.cameraModalOpen,
        });
    }

    handleSubmit = (values) => {
        const { index, getFormData } = this.props;
        getFormData(values, index);
        this.props.handleContinuar();
    }

    getInitialValues = () => ({
        'itemCondition': this.props.dataSaved ? this.props.dataSaved.itemCondition : null,
        'stock': this.props.dataSaved.stock ? this.props.dataSaved.stock : null,
        'importe': this.props.dataSaved.importe ? this.props.dataSaved.importe : '',
    })

    getSignUpSchema = () =>
        Yup.object().shape({
            importe: Yup.number()
                .required('Importe es requerido'),
            stock: Yup.number()
                .required('Stock es requerido'),
            itemCondition: Yup.string()
                .required('Condicion del Item/Producto es requerido'),
        })

    componentDidMount() {
        this.props.fetchItemConditions();
    }

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={false}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
}

const mapStateToProps = ({
    clasificados,
    selectedGuest
}) => ({
    clasificados,
    selectedGuest
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchGetCategories, fetchItemConditions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Paso2)
)