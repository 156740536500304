import styled from 'styled-components'

export const Page = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:100%;
    background-color: inherit;
`
export const TitlePageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    background-color: inherit;
    padding-bottom: 3.5rem;
`
export const Container = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    width:750px;
    height:370px;
    padding: 25px;
    border: 1px solid white;
    border-radius: 15px;
    background-color: white;
    // min-width: 750px;
    // min-height: 370px;
    // max-width:;
    // max:height:;
`
export const FormContainerF = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:100%;
    height:100%;
`
export const WrapperSelectContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items:space-between;
    flex-direction: column;
    width:75%;
`
export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: inherit;
    width: 100%;
    height:30%;
    margin-bottom: 5px;
`
export const Label = styled.div`
    display: flex;
    font-size: 17px;
    margin:4px;
`

export const FormStyled = styled.form`
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly ;
    padding: 3% ;
`

export const BarrioLabel = styled.label`
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${props => (props.size ? props.size : '15px')};
`
export const BarrioWrapperContainer = styled.div`
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0;
    
`
export const BarrioWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.wildSand};
    border-color: ${({ theme }) => theme.colors.greyBorder};
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding:0;
    
`
export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:12px;
    
`
export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
  text-align: center;
`
