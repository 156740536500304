import { createSelector } from 'reselect'
const NULL_VENDOR_NAME = '-';
let mapOperationDetail = createSelector(  
    operations => operations,
    operations => 
  (operations.map(operation => ({
    ...operation,
    vendorName: operation.vendor?operation.vendor.name:NULL_VENDOR_NAME,
    amountOperation: `$ ${operation.amount}`
  })) )
)
export default mapOperationDetail