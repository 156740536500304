
import { createSelector } from 'reselect'


const mapExpensesDetail = createSelector(
  data => data,
  data => data.details.map((detalle, i) =>
    ({
      id: detalle.id,
      client_name: detalle.client_name,
      cuit: detalle.client_cuit,
      amount: detalle.amount,
      debit_account_cvbu: detalle.debit_account_cvbu,
      neighborhood_name: detalle.neighborhood_name,
      property_code: detalle.property_code,
      status_code: detalle.status_code,
      client_id: detalle.client_id,
      motivo_rechazo:detalle.motivo_rechazo

    }
    )
  )
)


export default mapExpensesDetail