import * as actionTypes from './actionTypes'

import { getFunctionalUnitAPI } from '../../apiConstants'

import getApi from '../../shared/api'

export const fetchLotsByNeighbourhoodSuccess = lots => {
  return {
    type: actionTypes.FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS,
    lots: lots
  }
}

export const fetchLotsByNeighbourhoodFail = error => {
  return {
    type: actionTypes.FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL,
    error: error
  }
}

export const fetchLotsByNeighbourhoodStart = () => {
  return {
    type: actionTypes.FETCH_LOTS_BY_NEIGHBOURHOOD_START
  }
}

export const fetchLotsByNeighbourhood = guidParam => {
  return async (dispatch, getState) => {
    dispatch(fetchLotsByNeighbourhoodStart())

    var api;
    if (guidParam == null || guidParam == ''){
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      api = await getApi(guid)
    }
    else
      api = await getApi(guidParam)

    try {
      const response = await api.get(getFunctionalUnitAPI)
      dispatch(fetchLotsByNeighbourhoodSuccess(response.data))
    } catch (error) {
      dispatch(fetchLotsByNeighbourhoodFail(error))
    }
  }
}
