import { createSelector } from 'reselect'
var moment = require('moment');

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapPaymentOperations = createSelector(  
  operations => operations,
  operations => 
  (operations.map(operation => ({
    ...operation,
    creation_date : moment(operation.creation_date).format('DD-MM-YYYY HH:mm'),
    update_date :operation.update_date?moment(operation.update_date).format('YYYY-MM-DD HH:MM'):null,
    total_amount_operation: `$ ${operation.total_amount}`,
    installments_quantity: operation.installments_quantity === 0 ? '-' : operation.installments_quantity 
  })) )
)
export default mapPaymentOperations
