import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 100px;
  margin-top: 40px;
`
export const Title = styled.div`
    width:100%;
    font-size: 22px;
    font-weight: 500;
    color: ${({ blue, theme }) => blue ? theme.colors.white : theme.colors.blueRibbon};
    align-self: center;
    margin: 30px 0 10px 0;
    padding-bottom:10px;
    border-bottom:1px solid #d2d2d2;
`



// export const Row = styled.div`
// width: ${({ width }) => width || 100}%;
//   display: flex;
//   align-items: ${({ align }) => align || 'flex-start'};
//   justify-content: ${({ justify }) => justify || 'space-between'};
//   margin-bottom: ${({ error }) => (error ? 0 : 10)}px;
// `
export const FlexWrapper = styled.div`
width: ${({ width }) => width || 100}%;
  display: ${({ hidden }) => hidden ? 'hidden' : 'flex'};
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const ProgressBarWrapper = styled.div`
margin-bottom: 2em;
-webkit-transition: opacity ease-out 1s;
-moz-transition: opacity ease-out 1s;
-o-transition: opacity ease-out 1s;
transition: opacity ease-out 1s;
opacity: ${({ opacity }) => opacity || 0};
`

export const InputWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  padding-bottom: 10px;
`

export const ErrorLabel = styled.label`
  text-align:${({ textAlign }) => textAlign || 'left'};
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`

export const SpinerWrapper = styled.div`
  margin:auto;
  padding-top:4em;
  padding-bottom:4em;
`

export const ResetIcon = styled.img`
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
`

export const LoaderWrapper = styled.div`
    display:flex;
    align-items:center;
    text-align:center;
    margin: auto;
`

export const CalendarComponentsContainer = styled.div`
    width:100%;
`
export const Row = styled.div`
    width:${({ width }) => width || '100%'};
    height:${({ height }) => height || 'auto'};
    margin:${({ margin }) => margin || '0'}; 
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    justify-content:${({ justifyContent }) => justifyContent || 'center'};

    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input{
              border:solid 1px hsl(0,0%,80%);
        }
      }
    }
`

export const ColorReferenceWrapper = styled.div`
width:400px;
display: flex;
align-items: center;
justify-content: space-around; 
`

export const SelectWrapper = styled.div`
width: 200px;
font-weight: normal;
`