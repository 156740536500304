import { createActions } from 'redux-actions'
import * as actionTypes from './actionTypes'
import { getSpacesAPI, postNewSpaceAPI, delSpaceAPI } from '../../apiConstants'
import getApi from '../../shared/api'

const {
  fetchSpacesStart,
  fetchSpacesSuccess,
  fetchSpacesError,
  submitSpaceStart,
  submitSpaceSuccess,
  submitSpaceError
} = createActions({
  FETCH_SPACES_START: () => { },
  FETCH_SPACES_SUCCESS: data => ({ data }),
  FETCH_SPACES_ERROR: error => ({ error }),
  SUBMIT_SPACE_START: () => { },
  SUBMIT_SPACE_SUCCESS: data => ({ data }),
  SUBMIT_SPACE_ERROR: error => ({ error })
})

const fetchSpaces = id => {
  return async (dispatch, getState) => {
    dispatch(fetchSpacesStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(`${getSpacesAPI}/${id}`)
      console.log('fetchSpaces actions');
      console.log(response.data)
      dispatch(fetchSpacesSuccess(response.data))
    } catch (error) {
      dispatch(fetchSpacesError(error))
    }
  }
}

const submitSpace = (activityId, spaceName, times, blockedSpaces_id, maxReservationTime, NeighbourhoodSpacesGuid) => {
  return async (dispatch, getState) => {
    dispatch(submitSpaceStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const spaceData = {
        activeTimes: times,
        spaceName: spaceName,
        neighbourhoodSpacesGuid: NeighbourhoodSpacesGuid,
        blockedSpacesId: blockedSpaces_id,
        maxReservationTime: maxReservationTime
      }
      await api.post(`${postNewSpaceAPI}/activity-id/${activityId}/space-name/`, spaceData)
      dispatch(submitSpaceSuccess())
    } catch (error) {
      dispatch(submitSpaceError(error.response.data.message))
    }
  }
}

const editSpace = (space_id, open_times, delete_ids, blockedSpaces_id, maxReservationTime, NeighbourhoodSpacesGuid, activityId, spaceName) => {
  return async (dispatch, getState) => {
    dispatch(submitSpaceStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const spaceData = {
        spaceId: space_id,
        deleteIds: delete_ids,
        upsertActiveTimes: open_times,
        neighbourhoodSpacesGuid: NeighbourhoodSpacesGuid,
        blockedSpacesId: blockedSpaces_id,
        maxReservationTime: maxReservationTime,
        activityId,
        spaceName
      }
      await api.put(`${postNewSpaceAPI}/${space_id}`, spaceData)
      dispatch(submitSpaceSuccess())
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          submitSpaceError(
            'No es posible editar el espacio ya que posee reservas activas en los turnos modificados'
          )
        )
      } else {
        dispatch(submitSpaceError(error.response.data.message))
      }
    }
  }
}

const deleteSpace = id => {
  return async (dispatch, getState) => {
    dispatch(submitSpaceStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      await api.delete(`${delSpaceAPI}/${id}`)
      dispatch(submitSpaceSuccess())
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          submitSpaceError('No es posible eliminar el espacio ya que posee reservas activas')
        )
      } else {
        dispatch(submitSpaceError('Error al eliminar el espacio'))
      }
    }
  }
}

export {
  fetchSpaces,
  fetchSpacesStart,
  fetchSpacesSuccess,
  fetchSpacesError,
  submitSpace,
  submitSpaceStart,
  submitSpaceSuccess,
  submitSpaceError,
  editSpace,
  deleteSpace
}
