import styled from 'styled-components';

import Tab from '@material-ui/core/Tab';
import theme from '../../../../config/theme';
import Button from '../../../../components/Button';

export const StyledSelect = styled.div`
    width: 230px;
    & > div > div:first-child {
        height: 40px;
    }
    & span {
        margin: 0px;
    }
`
export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`
export const ChannelLabel = styled.div`
  color: #598f7f;
  background-color: #e0eae7;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  margin: 2px;
  width:min-content;
  border-radius:10px;
`
export const LabelSection = styled.div`
  display: flex;
  align-items: flex-start;
`




export const SelectWrapper = styled.div`
  width: 180px;
  font-weight: normal;
  margin-right:10px;
`

export const BorderSeparator = styled.div`
border-bottom: solid 2px #f2f2f2;
width: 100%;
margin: 2% 0%;
`

export const BorderTab = styled.div`
border-bottom: solid 1px #f2f2f2;
width: 100%;
`

export const TitleCommunityStyle = styled.div`
color:#30735f;
font-weight: bold;
align-self:center;
`

export const DocItem = styled.div`
width: 100%;
border: 1px solid #B3B3B3;
border-radius: 4px;
padding: 2%;
margin-bottom:2%;
display:flex;
justify-content: space-between;
`
export const SpinerWrapper = styled.div`
position:absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
  `
  export const WrapperSelect = styled.div`
  display: flex;
  justify-content: end;
  `
  export const Img = styled.img`
height: 280px;
max-width: 100%;
border-radius:6px 6px 0 0;
`

export const AlignLeft = styled.div`
display:flex;
align-items: center;
`
export const ActiveLabel = styled.div`
color:  ${({ theme }) => theme.colors.activeLabel};;
background-color: #e0eae7;
font-size: 14px;
font-weight: bold;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  margin: 2px;
  width: 100px;
  // width:min-content;
  border-radius:20px;
`
export const PendingLabel = styled.div`
color: ${({ theme }) => theme.colors.pendingLabelText};
background-color: ${({ theme }) => theme.colors.pendingLabel};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  width: 100px;
  font-weight: bold;
  border-radius:20px;
`

export const ButtonStyled = styled(Button)`
&& {
padding: 10px;
}
`



export const RejectedLabel = styled.div`
color: ${({ theme }) => theme.colors.rejectedLabelText};
background-color: ${({ theme }) => theme.colors.rejectedLabel};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  font-weight: bold;
  width: 100px;
  border-radius:20px;
`
 
export const TabStyled = styled(Tab)` 
&& {
  font-size: 14px;
  color: ${({ theme,selected }) =>  selected ? theme.colors.midGray : theme.colors.tundora};
  font-weight: ${({ selected }) =>  selected  ? 'bold' : ''};
  text-transform: capitalize;
}`


export const MessageWrapper = styled.div`
position:absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
z-index: 999999;
`