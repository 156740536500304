import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom/';
import styled from 'styled-components';
import routeList from '../../../routeList';
import { stack as Menu } from 'react-burger-menu'
import { Link  } from 'react-router-dom/'

const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: white;
  text-align: left;
  z-index: 9999;
`
const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`
const LinkMobileStyled = styled(Link)`
  height: 8%;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  font-weight: 200;
  color: black;
  border-bottom: 2px solid #f9f9fb;
  // margin: 10% 0;
  &:hover {
    color: black;
  }
  ${({ currentPage }) => currentPage && `
  border-right: 2px solid black;
  font-size: 22px;
  font-weight: 600;`}
`

var styles = {
  // bmBurgerButton: {
  //   position: 'fixed',
  //   width: '36px',
  //   height: '30px',
  //   left: '36px',
  //   top: '36px'
  // },
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '10px',
    top: '20px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
}
//* setear pagina inicial */
let current_page = "Dashboard";

class ToolbarLayout extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }
  render () {
   
    const { children } = this.props;

    
    const { menusAuth } = this.props.userInfo;
    const setCurrentPage = (page) =>{
      current_page = page;
    }
    return (
      <Container id="outer-container">
        <StyledMenu active={this.props.location.pathname === current_page ? true : false} styles={styles} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
        <LinkMobileStyled 
                currentPage={false}
                style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                to={""} 
                id="home" 
                className="menu-item" 
                href="/dashboard">{""}</LinkMobileStyled>
          {console.log(routeList)}
          {console.log(current_page)}
          {
          routeList.filter(x => x.showMenu).map((item,index) => ((
            menusAuth.filter(x => x.name === item.title).length > 0 ? 
            <LinkMobileStyled 
                currentPage={item.title == current_page ? true : false}
                style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                to={item.href} 
                id="home" 
                className="menu-item" 
                href="/dashboard"
                onClick={()=> setCurrentPage(item.title)}>{item.title}</LinkMobileStyled> : null
          ))) 
          }
        </StyledMenu>
        <div id="page-wrap">
          {this.props.children}
        </div>
      </Container>
      

    );
  }
}

const mapStateToProps = ({
  userInfo
}) => {
  return {
    userInfo
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(withRouter(ToolbarLayout))
)
