/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../../components/Button";
import InputMask from "../../../../../components/StyledInputMask";
import Loader from "../../../../../components/Loader";
import CloseIcon from "../../../../../assets/icons/close.svg";
import theme from "../../../../../config/theme"
import SaveIcon from '@material-ui/icons/Save';
import ResponseLabel from "../../../../../components/ResponseLabel";
import {
  InputWrapper,
  ErrorLabel,
  ErrorsWrapper,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper,
  GrayedArea,
  ScheduleContainer as MainContainer,
  HeaderWrapper,
  TitleModal,
  Close,
  StyledLabel,
  Row,
  FormContainer
} from "./styled";

function RuleValuesModal(props) {
  const { ruleValues, isSubmitting, updateRuleValueSuccess, errorUpdateRuleValue } = props;

  const [schemaErrors, setSchemaErrors] = useState([]);
  const onActivityRuleValueChange = (setFieldValue, value, key) => {
    setFieldValue(`ruleValues[${key}].activity_rule_value`, value)
  };

  const validateAndSubmit = (activityRuleValues) => {
    var errors = [];
    var regex;
    activityRuleValues.map(row => {
      regex = RegExp(`${row.validation_expression}`);
      if (!regex.test(row.activity_rule_value))
        errors.push({ id: row.id, message: 'El valor no es valido' });
    })
    setSchemaErrors(errors);
    if (errors.length == 0) {
      if (!props.isCreate)
        props.onSubmit(activityRuleValues[0]);
      else
        props.onSubmit(activityRuleValues);
    }
    return;
  };

  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        ruleValues: Yup.array()
      }
      )
    );

  const getInitialValues = () => {
    return {
      ruleValues: ruleValues
    };
  };

  const getFormContent = ({
    handleSubmit,
    values,
    setFieldValue,
    setValues,
    resetForm,
    errors
  }) => {
    return (
      <>
        <FormContainer onSubmit={handleSubmit}>
          {values.ruleValues.map((activityRuleValue, key) => (
            <FlexWrapper key={key}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{activityRuleValue.rule_value_name}</StyledLabel>
                </InputWrapper>
                <InputWrapper textAlign={'right'}>
                  <InputMask
                    width={"7em"}
                    mask={activityRuleValue.input_mask}
                    placeholder={activityRuleValue.placeholder}
                    maskChar={null}
                    disabled={false}
                    value={activityRuleValue.activity_rule_value}
                    onChange={({ target: value }) => onActivityRuleValueChange(setFieldValue, value.value, key)}
                  />
                </InputWrapper>
                {!props.isCreate && <SaveIcon onClick={() => validateAndSubmit([activityRuleValue])} style={{ color: theme.colors.midGray, cursor: 'pointer' }} />}
              </Row>
              {schemaErrors && schemaErrors.length > 0 && schemaErrors.find(error => error.id == activityRuleValue.id) &&
                <ErrorsWrapper>
                  <ErrorLabel>{schemaErrors.find(error => error.id == activityRuleValue.id).message}</ErrorLabel>
                </ErrorsWrapper>}
            </FlexWrapper>
          ))}
          <FlexWrapper>
            <ButtonWrapper>
              {props.isCreate && <Button type={"primary"}
                onClick={() => validateAndSubmit(values.ruleValues)}
                loading={isSubmitting}>
                Guardar
              </Button>}
            </ButtonWrapper>
            {(props.updateRuleValueSuccess || props.errorUpdateRuleValue) ?
              <ResponseLabel success={props.updateRuleValueSuccess ? true : false} onClose={() => props.resetResponseLabel}>
                {props.responseMessage}
              </ResponseLabel>
              : <></>}
          </FlexWrapper>
        </FormContainer>
      </>
    );
  };

  const form = () => {
    if (props.isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={() => { }}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <GrayedArea>
      <MainContainer>
        <HeaderWrapper>
          <TitleModal>{props.title}</TitleModal>
          <Close src={CloseIcon} onClick={props.onClose} />
        </HeaderWrapper>
        {form()}
      </MainContainer>
    </GrayedArea>
  );
}

export default RuleValuesModal;
