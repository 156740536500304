import styled from 'styled-components';

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const LoaderWrapper = styled.div`
    text-align:center;
    margin: auto;
` 

export const ReturnButton = styled.button`
    margin-top:1em;
    margin-bottom:1em;
    background-color: #30735f;
    color: white;
    border: solid 1px #30735f;
    box-sizing: border-box;
    font-weight: normal;
    cursor: pointer;
    text-align: center;  
    border-radius: 6px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Roboto, sans-serif;
    align-items: center;
    width: 10%
`

  
export const SpinerWrapper = styled.div`
  margin:auto;
  padding-top:4em;
  padding-bottom:4em;
`
export const StateWrapper = styled.div`
font-size: 14px;
line-height: 18px;
text-decoration: none;
text-align:center;
padding:10px;
border-radius:20px;
    background-color: #fef6e9;
    color: #f6b13f;
`

export const TableWrapper = styled.div`
position:relative;
`

export const StateGreenWrapper = styled.div`
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    text-align:center;
    padding:10px;
    border-radius:20px;
    background-color: #f2f8eb;
    color: #98ca5f;
`

export const StateRedWrapper = styled.div`
color: #FB2A2A;
background-color: #FEE9E9;
font-size: 14px;
line-height: 18px;
text-decoration: none;
text-align:center;
padding:10px;
border-radius:20px;
`