import styled from 'styled-components'

export const FileInputWrapper = styled.div`
  width:  ${(props) => props.width ? props.width : '80px'};
  padding:  ${(props) => props.padding ? props.padding : ''};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  grid-area: ${(props) => props.gridArea ? props.gridArea : ''};
`
export const FileInputContainer = styled.div`
  width:  ${(props) => props.width ? props.width : '80px'};
  height: auto;
  padding:  ${(props) => props.padding ? props.padding : ''};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '10px'};

  grid-area: ${(props) => props.gridArea ? props.gridArea : ''};
`

export const StyledFileInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
`

export const ImageAttachText = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
`

export const AttachedContainer = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.transparent};
  pointer-events: none;
`
export const CheckedElement = styled.img`
  position: absolute;
  top: -12px;
  right: -12px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const DocumentImg = styled.img`
  width: 100%;
  max-width: 100%;
  max-height:200px;
  border-radius: 8px;
  margin-bottom: 3px;
`
// export const DeleteButton = styled.div`
//   width: 18px;
//   height: 18px;
//   cursor: pointer;
//   border: 2px solid red;
//   border-radius: 9999px;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   align-text: center;
//   padding-bottom: 5.5px;
//   font-weight: 2000;
//   background-color: red;
// `
export const DeleteButton = styled.div`
width: 18px;
height: 19px;
margin-right: 100px;
cursor: pointer;
border-radius: 9999px;
color: #D3D3D3;
display: flex;
align-items: center;
justify-content: center;
align-text: center;
padding-bottom: 5.5px;
font-weight: 2000;
background-color: transparent;
&:hover {
  background-color: red;
  color: white;
}
`
export const TooLongMessage = styled.div`
  color:#B22222;
  font-size:16px;
  width: 180%;
  display:flex;
  visibility: ${({ visibility }) => visibility ? visibility : "hidden"};
  align-items: center;
  justify-content: center;
  margin-left:0;
  text-align:center;
`