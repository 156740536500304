/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Modal from "../../../../components/Modal";
import Loader from "../../../../components/Loader";
import {
  fetchBankAccounts,
  fetchBankAccountsReset
} from "../../../../store/actions/paymentClients.actions";
import { SpinerWrapper } from "./styled";

import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchBankAccounts, fetchBankAccountsReset }, dispatch);

function ModalAlertMap(props) {
  const centerPoint = { lat: -34.4145077, lng: -58.6558221}
  const { isLoading, alert } = props;

  const alertPoint = { lat: alert[0]?.latitude, lng: alert[0]?.longitude };

  const Map = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap defaultZoom={15} defaultCenter={alertPoint}>
        {props.isMarkerShown && <Marker position={alertPoint} />}
      </GoogleMap>
    ))
  );

  return (
    <Modal onClose={props.onClose} width={"800px"} minheight={true}>
      {isLoading ? (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      ) : (
        <Map
          containerElement={<div style={{ height: `600px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          isMarkerShown
          // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDozT8-YOrwIW21nlIT4ARBSTGjAKsOYcQ&v=3.exp&libraries=geometry,drawing,places"
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDozT8-YOrwIW21nlIT4ARBSTGjAKsOYcQ&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
        />
      )}
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlertMap);
