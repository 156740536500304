import { createSelector } from 'reselect'
var moment = require('moment');


let mapBCRAProcesses = createSelector(  
  processes => processes,
  processes => 
  (processes.map(process => ({
    ...process,
    process_state : process.process_state.name,
    process_type:  process.process_type.name,
    result_type : '',
    execution_date :process.execution_date?moment(process.execution_date).format('DD-MM-YYYY HH:mm'):null,
    report_date :process.report_date?moment(process.report_date).format('DD-MM-YYYY'):null,
    alias:process.user.alias
  })) )
)
export default mapBCRAProcesses