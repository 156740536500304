import * as actionTypes from './actionTypes'
import {
  getInvitationsAPI,
  getNewsListAPI,
  postNewEventAPI,
  postNewArticleAPI,
  deleteArticleAPI,
  deleteEventAPI
} from '../../apiConstants'
import getApi from '../../shared/api'


export const fetchInvitadosSuccess = invitados => {
    return {
      type: actionTypes.FETCH_INVITADOS_SUCCESS,
      invitados: invitados
    }
  }
  
  export const fetchInvitadosFail = error => {
    return {
      type: actionTypes.FETCH_INVITADOS_FAIL,
      error: error
    }
  }
  
  export const fetchInvitadosStart = () => {
    return {
      type: actionTypes.FETCH_INVITADOS_START
    }
  }
  
  export const fetchInvitados = reset => {
    return async (dispatch, getState) => {
      dispatch(fetchInvitadosStart())
      const {
        invitados,
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
    console.log("user info",guid )
    console.log("user info",invitados )
    const api = await getApi(guid, true)

    let take = reset ? 10 : invitados.take
    let page = invitados.page
    try {
      // Fetching content
      const response = await api.get(`${getInvitationsAPI}${page}/${take}`)

      // Analizing response 
      let hasMoreUpdated = response.data.length === take
      let takeUpdated = hasMoreUpdated ? take + 10 : take
      
      dispatch(
        fetchInvitadosSuccess({
          values: response.data,
          take: takeUpdated,
          hasMore: hasMoreUpdated
        })
      )
      } catch (error) {
        console.log(error)
        dispatch(fetchInvitadosFail(error))
      }
    }
  }

  