import React from 'react';
import styled from 'styled-components';
// import Button from './Button';
// import Cancel from './Cancel';

const ButtonSection = styled.div`
  width:100%;
  position:fixed;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color:#fff;
  padding-bottom:20px;
  padding-top:20px;
  align-self:flex-end;
  justify-content: flex-end;
`
const Section = styled.div`
  width:100%;
  display: flex;
  justify-content: flex-end;
 
`
const TextSection = styled.div`
  display: flex;
  left:20%;
  position: absolute;
  font-family: Roboto, sans-serif;
  font-size: 0.8750em;
  font-weight: bold;

`

export const Cancel = styled.button`
    background-color: white;
    color: black;
    border: solid 1px #30735f;
    box-sizing: border-box;
    font-weight: normal;
    cursor: pointer;
    text-align: center;  
    border-radius: 20px;
    padding: 12px;
    display: flex;
    font-size: 0.8750em;
    flex-direction: column;
    justify-content: center;
    font-family: Roboto, sans-serif;
    align-items: center;
    width: 10%;
    height: 40px;
    margin-right:20px;
`

export const Button = styled.button`
    background-color: #30735f;
    color: white;
    border: solid 1px #30735f;
    box-sizing: border-box;
    font-weight: normal;
    cursor: pointer;
    text-align: center;  
    border-radius: 20px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Roboto, sans-serif;
    align-items: center;
    font-size: 0.8750em;
    width: 10%;
    height: 40px;

`

const OnCheckSection = (props) => {
  return (
      <>
      <Section>
        <ButtonSection>
          <TextSection>
            {props.selectedRows + " " + props.description} 
          </TextSection>
          <Cancel onClick={props.onCancel}>
            {props.cancel}
          </Cancel>
          <Button onClick={props.onClick} >
            {props.title}
          </Button>
        </ButtonSection>
      </Section>
    </>
  );
}

export default OnCheckSection