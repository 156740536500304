import styled from 'styled-components';
import mediaQueries from '../../utils/media-queries'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const ImageWrapper = styled.div`
  max-height: 100vh;
  width: 50%;  
`
export const FormContainer = styled.form` 
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 70vh;
  width: 40%;
  padding: 1% 5%;
  top: 18%;
  left: 29%;
  position: fixed;
  border-radius: 15.5px;
  ${mediaQueries.mobile`
    height: 50vh;
    width: 80%;
    padding: 0% 5%;
    top: 10%;
    left: 10%;
    position: fixed;
  `}
`
export const ButtonWrapper = styled.div` 
  margin: 30px;
  width: 100%;
`
export const LogoImg = styled.img` 
  width: 30vh;
  margin: 5% 0%;
`
export const StyledImg = styled.img` 
  width: 100vw;
  position: fixed;
  top: -8%;
  ${mediaQueries.mobile`
    height: 95vh;
    width: auto;
    top: 0;
  `}
`
export const StyledLabel = styled.div` 
  font-family: Roboto;  
  color: #4a4a4a;
  font-size: 14px;
  align-self: flex-end;
  cursor:pointer;
`