import RitzTable from '../../../../components/RitzTable';

import SectionHeader from '../../../../components/SectionHeader';

import React, { useEffect } from 'react';
import XLSX from 'xlsx';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../../../components/Loader';
import Button from "../../../../components/Button";
import theme from "../../../../config/theme"
import { LoaderWrapper, TableWrapper } from "./styled";
import { fetchPropertiesFunnelData } from '../../../../store/actions/reportes.actions';
const tableRef = React.createRef();
var moment = require('moment');
const mapStateToProps = state => {
  const {
    propertiesFunnelData,
    loadingFetchPropertiesFunnelData
  } = state.reportes;

  return {
    propertiesFunnelData: propertiesFunnelData,
    loadingFetchPropertiesFunnelData: loadingFetchPropertiesFunnelData
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPropertiesFunnelData
    },
    dispatch
  )

function PropertiesFunnel(props) {

  const {
    propertiesFunnelData,
    loadingFetchPropertiesFunnelData
  } = props;

  const getTotalData = () => {
    var totalData = propertiesFunnelData.neighbourhoods.concat(
      [
        {
          neighbourhood_name: 'Total Barrios Miiii',
          id: 10000,
          parentId: null,
          data: {
            total_uf: propertiesFunnelData.global_data.total_uf,
            total_registered_uf: propertiesFunnelData.global_data.total_registered_uf,
            total_cvus_ars: propertiesFunnelData.global_data.total_cvus_ars,
            total_with_itau_account: propertiesFunnelData.global_data.total_with_itau_account,
            total_expenses: propertiesFunnelData.global_data.total_expenses,
            total_itau_expenses: propertiesFunnelData.global_data.total_itau_expenses
          },
          percentages_data: {
            registered_uf_by_total_uf: propertiesFunnelData.global_percentages_data.registered_uf_by_total_uf,
            cvus_ars_by_total_uf: propertiesFunnelData.global_percentages_data.cvus_ars_by_total_uf,
            cvus_ars_by_registered_uf: propertiesFunnelData.global_percentages_data.cvus_ars_by_registered_uf,
            with_itau_acc_by_total_uf: propertiesFunnelData.global_percentages_data.with_itau_acc_by_total_uf,
            with_itau_acc_by_registered_uf: propertiesFunnelData.global_percentages_data.with_itau_acc_by_registered_uf,
            with_itau_acc_by_cvus_ars: propertiesFunnelData.global_percentages_data.with_itau_acc_by_cvus_ars,
            expenses_by_total_uf: propertiesFunnelData.global_percentages_data.expenses_by_total_uf,
            expenses_by_registered_uf: propertiesFunnelData.global_percentages_data.expenses_by_registered_uf,
            expenses_by_cvus_ars: propertiesFunnelData.global_percentages_data.expenses_by_cvus_ars,
            expenses_by_with_itau_acc: propertiesFunnelData.global_percentages_data.expenses_by_with_itau_acc,
            itau_expenses_by_total_uf: propertiesFunnelData.global_percentages_data.itau_expenses_by_total_uf,
            itau_expenses_by_registered_uf: propertiesFunnelData.global_percentages_data.itau_expenses_by_registered_uf,
            itau_expenses_by_cvus_ars: propertiesFunnelData.global_percentages_data.itau_expenses_by_cvus_ars,
            itau_expenses_by_with_itau_acc: propertiesFunnelData.global_percentages_data.itau_expenses_by_with_itau_acc,
            itau_expenses_by_expenses: propertiesFunnelData.global_percentages_data.itau_expenses_by_expenses
          }
        },
      ]
    );

    return totalData;
  };

  const columns = [
    {
      title: 'Barrio', field: 'neighbourhood_name',
      headerStyle: { minWidth: '200px',textAlign:"center"  },
      cellStyle: { maxWidth: '200px' ,textAlign:"center",paddingLeft:'0px' },
    },
    {
      title: 'UF Totales', field: 'data.total_uf',
      headerStyle: { minWidth: '70px',textAlign:"center"   },
      cellStyle: { maxWidth: '70px',textAlign:"center" ,paddingLeft:'0px'  },
    },
    {
      title: 'UF Registradas', field: 'data.total_registered_uf',
      headerStyle: { minWidth: '100px',textAlign:"center"  },
      cellStyle: { maxWidth: '100px' ,textAlign:"center" ,paddingLeft:'0px' },
    },
    {
      title: '%s/T', field: 'percentages_data.registered_uf_by_total_uf',
      headerStyle: { maxWidth: '50px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '50px' ,textAlign:"center" },
    },
    {
      title: 'UF con al menos 1 CVU AR$', field: 'data.total_cvus_ars',
      headerStyle: { minWidth: '180px',textAlign:"center"   },
      cellStyle: { maxWidth: '200px',textAlign:"center" ,paddingLeft:'0px'  },
    },
    {
      title: '%s/T', field: 'percentages_data.cvus_ars_by_total_uf',
      headerStyle: { minWidth: '60px',maxWidth: '60px',textAlign:"center"   },
      cellStyle: {minWidth: '60px', maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: '%s/R', field: 'percentages_data.cvus_ars_by_registered_uf',
      headerStyle: { minWidth: '60px',maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { minWidth: '60px',maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: 'UF con al menos 1 cta. ITAU', field: 'data.total_with_itau_account',
      headerStyle: { minWidth: '180px' ,textAlign:"center" },
      cellStyle: { maxWidth: '100px' ,textAlign:"center" ,paddingLeft:'0px' },
    },
    {
      title: '%s/T', field: 'percentages_data.with_itau_acc_by_total_uf',
      headerStyle: {minWidth: '60px',maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: {minWidth: '60px',maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: '%s/R', field: 'percentages_data.with_itau_acc_by_registered_uf',
      headerStyle: {minWidth: '60px', maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { minWidth: '60px',maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: '%s/CV', field: 'percentages_data.with_itau_acc_by_cvus_ars',
      headerStyle: { minWidth: '60px',maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { minWidth: '60px',maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: 'Débitos Expensas Total', field: 'data.total_expenses',
      headerStyle: { minWidth: '180px',textAlign:"center"   },
      cellStyle: { maxWidth: '200px',textAlign:"center",paddingLeft:'0px'   },
    },
    {
      title: '%s/T', field: 'percentages_data.expenses_by_total_uf',
      headerStyle: { maxWidth: '50px',textAlign:"center"  },
      cellStyle: { maxWidth: '50px' ,textAlign:"center" },
    },
    {
      title: '%s/R', field: 'percentages_data.expenses_by_registered_uf',
      headerStyle: { maxWidth: '50px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '50px',textAlign:"center"  },
    },
    {
      title: '%s/CV', field: 'percentages_data.expenses_by_cvus_ars',
      headerStyle: { maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: '%s/CI', field: 'percentages_data.expenses_by_with_itau_acc',
      headerStyle: { maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: 'Débitos Expensas ITAU', field: 'data.total_itau_expenses',
      headerStyle: { minWidth: '180px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '200px' ,textAlign:"center" ,paddingLeft:'0px' },
    },
    {
      title: '%s/T', field: 'percentages_data.itau_expenses_by_total_uf',
      headerStyle: { maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: '%s/R', field: 'percentages_data.itau_expenses_by_registered_uf',
      headerStyle: { maxWidth: '60px',textAlign:"center"   },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: '%s/CV', field: 'percentages_data.itau_expenses_by_cvus_ars',
      headerStyle: { maxWidth: '60px' ,textAlign:"center"  },
      cellStyle: { maxWidth: '60px' ,textAlign:"center" },
    },
    {
      title: '%s/CI', field: 'percentages_data.itau_expenses_by_with_itau_acc',
      headerStyle: { maxWidth: '60px',textAlign:"center"   },
      cellStyle: { maxWidth: '60px',textAlign:"center"  },
    },
    {
      title: '%s/ET', field: 'percentages_data.itau_expenses_by_expenses',
      headerStyle: { maxWidth: '70px' ,textAlign:"center" },
      cellStyle: { maxWidth: '70px' ,textAlign:"center" },
    },
  ];

  const exportExcel = async() => {
    const allColumns = tableRef.current.props.columns;
    const allData = tableRef.current.dataManager.pagedData;

    const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
    var neighbourhoodsDataRows = [];
    neighbourhoodsDataRows.push(columns.map(col => { return col.title }));
    allData.map(rowData => {
        var neighbourhoodParent = columns.map(columnDef => {
            var splittedColumn = columnDef.field.split('.');
            if (splittedColumn[1] != null) {
                return rowData[splittedColumn[0]][splittedColumn[1]];
            }
            return rowData[splittedColumn[0]];
        });
        neighbourhoodsDataRows.push(neighbourhoodParent);
        if (rowData.tableData.childRows != null) {
            rowData.tableData.childRows.map(childNeighbourhoodData => {
                var childNeighbourhoodRow = columns.map(columnDef => {
                    var splittedColumn = columnDef.field.split('.');
                    if (splittedColumn[1] != null) {
                        return childNeighbourhoodData[splittedColumn[0]][splittedColumn[1]];
                    }
                    return childNeighbourhoodData[splittedColumn[0]];
                });
                neighbourhoodsDataRows.push(childNeighbourhoodRow);
            })

        }
    })
    var ws = XLSX.utils.aoa_to_sheet(neighbourhoodsDataRows);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PropertiesFunnel' + moment().format('YYYY-MM-DD'));
    XLSX.writeFile(wb, `PropertiesFunnel_` + moment().format('YYYY-MM-DD') + `.xlsx`);
};

  useEffect(() => {
    props.fetchPropertiesFunnelData()
  }, []);

  return (<>
    <SectionHeader
      title="Funnel Lotes"
      width="100%"
      justifyContent={"space-between"}
    >
    <Button loading={loadingFetchPropertiesFunnelData} onClick={() => exportExcel()} > Exportar a excel </Button>
    </SectionHeader>
    <TableWrapper>
      <RitzTable
        columns={columns}
        title=""
        isLoading={loadingFetchPropertiesFunnelData}
        tableRef={tableRef}
        options={{
          // toolbar: true,
          paging: false,
          serverSide: false,
          exportButton: true,
          search:false,
          rowStyle: rowData => ({
            backgroundColor: rowData.id == 10000 ? theme.colors.darkGreen : rowData.id == 20000 || rowData.id == 30000 || rowData.id == 40000 ? theme.colors.lightGreen : (((!rowData.parentId ? theme.colors.ghost : theme.colors.white))),
            color: rowData.id == 10000  || rowData.id ==  20000 || rowData.id == 30000 || rowData.id == 40000 ? theme.colors.white : !rowData.parentId ? theme.colors.black : theme.colors.midGray,
          })
        }}
        data={getTotalData()}
        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
      />
    </TableWrapper>

  </>);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesFunnel)