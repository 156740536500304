import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import MenuItems from "../../components/MenuItems";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ActivitiesList from "./ActivitiesList";
import BookingsList from "./BookingsList";
import SpacesList from "./SpacesList";
import {
  setSelectedNeighbourhood
} from "../../store/actions/bookings.actions";

const mapStateToProps = state => {
  const { user } = state.auth;
  const { selectedNeighbourhood } = state.bookings;
  return {
    user: user,
    selectedNeighbourhood: selectedNeighbourhood
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedNeighbourhood
    },
    dispatch
  );

function Bookings(props) {
  const BOOKINGS = "bookings";
  const ACTIVITIES = "activitiesList";
  const SPACES = "spacesList";
  const { user } = props;
  const [view, setView] = useState("bookings");

  var menulistItems = [
    { title: "Reservas", route: BOOKINGS, available: true, active: true },
    { title: "Espacios", route: SPACES, available: true },
    { title: "Actividades", route: ACTIVITIES, available: true, }
  ];

  const getNeighbourhoods = () => {
    let userNeigh = user.neighbourhoods;
    var neighs = userNeigh
      .map(n => ({ label: n.name, value: n.guid }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    return neighs;
  };


  //  Items del menú
  const menuListComponent = menulistItems.map((item, key) => (
    <MenuItems
      quantity={item.quantity}
      onClick={item.available ? () => setView(item.route) : null}
      active={view === item.route}
      disabled={!item.available}
      key={key}
    >
      {item.title}
    </MenuItems>
  ));
  const neighbourhoods = getNeighbourhoods();
  // Devuelvo la vista en base a la selección en el menú
  const selectedView = (() => {
    switch (view) {
      case BOOKINGS:
        return <BookingsList neighbourhoods={neighbourhoods} selectedNeighbourhood={props.selectedNeighbourhood} setSelectedNeighbourhood={props.setSelectedNeighbourhood} />
      case ACTIVITIES:
        return <ActivitiesList neighbourhoods={neighbourhoods} selectedNeighbourhood={props.selectedNeighbourhood} setSelectedNeighbourhood={props.setSelectedNeighbourhood} />
      case SPACES:
        return (
          <SpacesList
            neighbourhoods={neighbourhoods}
            selectedNeighbourhood={props.selectedNeighbourhood}
            setSelectedNeighbourhood={props.setSelectedNeighbourhood}
          />
        );
      default:
        return <BookingsList neighbourhoods={neighbourhoods} />
    }
  })();

  return (
    <NavBar menuListComponent={menuListComponent} title="Reservas">
      {selectedView}
    </NavBar>
  );
}

// export default Bookings

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
