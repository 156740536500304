import React from 'react'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
width: ${({ width }) => (width ? width : '100%')};
box-sizing: border-box;
height:${({ height }) => height || '80px'};
border-radius: 6px;
border: solid 1px hsl(0,0%,80%);
padding: 5px 8px;
${({ search }) => search && `
  padding: 5px 0px 5px 35px;
`} 
outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledGray};
    border: solid 2px ${({ theme }) => theme.colors.alto};
  }
`

const TextArea = props => <StyledTextArea {...props} />

export default TextArea
