import SectionHeader from '../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RitzTable from '../../../components/RitzTable';
// import Input from '../../../components/Input';
import SearchInput from '../Components/SearchInput';
import AddProductButton from '../../../components/Button';
import { fetchProcesses, fetchProcess, fetchProcessError } from '../../../store/actions/regulations.actions';
import { useHistory } from "react-router-dom";
import ImportFileModal from '../Components/ImportFileModal'
import ErrorDetailModal from '../Components/ErrorDetailModal'
import Loader from '../../../components/Loader';

import {
    Ahref,      
    StateGreenWrapper,
    StateRedWrapper,
    StateOrangeWrapper,
    LoaderWrapper,
    TableWrapper
} from './styled';
import ModalMessage from '../../../components/ModalMessage';


const mapStateToProps = state => {
    const {
        bcraPage,
        bcraPageSize,
        bcraTotal,
        loadingFetchProcesses,
        bcraOrderColumn,
        bcraOrderDirection,
        bcraSearchText,
        processes,
        errorFile,
        fileSuccess,
        processError
    } = state.regulations;

    return {
        processes: processes,
        processError: processError,
        page: bcraPage,
        pageSize: bcraPageSize,
        total: bcraTotal,
        isLoading: loadingFetchProcesses,
        orderColumn: bcraOrderColumn,
        orderDirection: bcraOrderDirection,
        searchText: bcraSearchText,
        errorFile: errorFile,
        fileSuccess: fileSuccess
    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchProcesses, fetchProcess, fetchProcessError },
        dispatch
    )



function ProcessesList(props) {
    const history = useHistory();
    const {
        searchText,
        processes,
        page,
        pageSize,
        total,
        isLoading,
        orderColumn,
        orderDirection,
        errorFile,
        fileSuccess,
        processError,
        loadingFetchProcess } = props;

    const processTypeId = 1;

    var columns = [
        { title: 'Fecha de reporte', field: 'report_date', },
        { title: 'Fecha de ejecución', field: 'execution_date', },
        { title: 'Usuario', field: 'alias', },
        { title: 'Resultado', field: 'process_state', 
            render: rowData => rowData.process_state.includes("Error") ?
            <StateRedWrapper > {rowData.process_state} </StateRedWrapper>
            : rowData.process_state.includes("No") ?
                <StateOrangeWrapper > {rowData.process_state} </StateOrangeWrapper>
                :
                <StateGreenWrapper > {rowData.process_state} </StateGreenWrapper>
        },
        {
            title: 'Acciones', field: 'actions', cellStyle: { textDecoration: 'underline' },
            render: rowData =>
                <Ahref to='' onClick={() => showDetail(rowData.id, rowData.process_state)} >  Ver Detalle </Ahref>
        },
    ];

    const [showImportFile, setShowImportFile] = useState(false);
    const [modalSubmit, setModalSubmit] = useState(true)
    const [showErrorModalDetail, setShowErrorModalDetail] = useState(false)
    // const [errorDetail, setErrorDetail] = useState("")
    const showDetail = async (selectedId, result) => {
        if(result.includes("Error")){
            await props.fetchProcessError(selectedId)
            setShowErrorModalDetail(true);
        }else{
            history.push({
                pathname: "/regulations/detail",
                state: { processId: selectedId }
              })
        }
    };

    const toggleErrorDetailModal = () => {        
        setShowErrorModalDetail(!showErrorModalDetail);
    }

    const toggleImportFile = () => {        
        setShowImportFile(!showImportFile);
    }

    const toggleModalSubmit = () => {        
        setModalSubmit(!modalSubmit);
        if(fileSuccess)
            props.fetchProcesses(processTypeId, page, pageSize, orderColumn, orderDirection, '', false)
    }

    const onChangePageHandler = (e, page) => {
        props.fetchProcesses(processTypeId, page, pageSize, orderColumn, orderDirection, searchText)
    }

    const onChangeRowsPerPageHandler = pageSize => {
        props.fetchProcesses(processTypeId, 0, pageSize, orderColumn, orderDirection, searchText)
    }

    const onKeyDownHanlder = (e) => {
        if (e.keyCode === 13) {
            props.fetchProcesses(processTypeId, 0, pageSize, orderColumn, orderDirection, e.target.value)
        }
    }

    const onChangeHandler = e => {
        if (e.target.value === '') {
            props.fetchProcesses(processTypeId, 0, pageSize, orderColumn, orderDirection, '')
        }
    }

    const onOrderChangeHandler = (orderedColumnId, orderDirection) => {
        var columnName = 'execution_date';
        var dir = 'desc';
        if (orderedColumnId !== null && orderedColumnId !== 6) {
            const column = columns[orderedColumnId];
            if (column != null)
                columnName = column.field;
        }

        if (orderDirection != null && orderDirection !== '')
            dir = orderDirection;

        props.fetchProcesses(processTypeId, page, pageSize, columnName, dir, searchText)
    }

    
    const renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    useEffect(() => {
        props.fetchProcesses(processTypeId, page, pageSize, orderColumn, orderDirection, '', false)
    }, []);
 
    return (<>
        <SectionHeader title='Comunicaciones D' width='100%' justifyContent={'space-between'}>
            <AddProductButton onClick={() => toggleImportFile()}>Adjuntar informe</AddProductButton>
        </SectionHeader>
        <SearchInput
                type="text"
                search={true}
                defaultValue={searchText}
                placeholder={'Buscar'}
                disabled={isLoading}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHanlder}
            />
        <TableWrapper>
        {isLoading?<LoaderWrapper>
                <Loader />
            </LoaderWrapper>:null}
        <RitzTable
            columns={columns}
            title=""
            isLoading={isLoading}
            options={{
                toolbar: false,
                paging: true,
                serverSide: true,
                selection: false,
                exportButton: false,
                exportAllData: false,
                exportFileName: 'execution_date.' + (new Date().toISOString().split('.')[0])
            }}
            onChangeRowsPerPage={onChangeRowsPerPageHandler}
            onOrderChange={onOrderChangeHandler}
            pagination={{
                totalRow: total,
                page: props.page,
                pageSize: props.pageSize,
                onChangePage: onChangePageHandler
            }}
            data={processes} />
        </TableWrapper>

        {showImportFile ? <ImportFileModal close={() => toggleImportFile()} verifySuccess={toggleImportFile} /> : null}
        {!errorFile && fileSuccess ?  renderModalMessageSuccess('Se ha importado el archivo correctamente.',toggleModalSubmit)
        : errorFile ?  renderModalMessageFail('Hubo un error al intentar importar el archivo.',toggleModalSubmit) : null }
        {showErrorModalDetail ? <ErrorDetailModal errorDetail={processError} close={() => toggleErrorDetailModal()}/> : null}
    </>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProcessesList)