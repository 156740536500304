import styled from 'styled-components';

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const TableWrapper = styled.div`
    position:relative;
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`

export const Ahref = styled.a`
    cursor: pointer;
    color: #30735f;
`
export const StateGreenWrapper = styled.div`
font-size: 14px;
line-height: 18px;
text-decoration: none;
text-align:center;
padding:10px;
border-radius:20px;
max-width: 50%
    background-color: #f2f8eb;
    color: #a7d177;
`

export const StateRedWrapper = styled.div`
font-size: 14px;
line-height: 18px;
text-decoration: none;
text-align:center;
padding:10px;
max-width: 50%
border-radius:20px;
    background-color: #fee9e9;
    color: #fb2a2a;
`

export const StateOrangeWrapper = styled.div`
font-size: 14px;
line-height: 18px;
text-decoration: none;
text-align:center;
padding:10px;
border-radius:20px;
max-width: 50%
    background-color: #fef6e9;
    color: #f6b13f;
`

