import styled from 'styled-components';
import theme from 'styled-theming';
import PropTypes from 'prop-types';

const backgroundColor = theme.variants('mode', 'variant', {
  default: { light: ({ theme }) => theme.colors.defaultLabelBackground, dark: 'darkgray' },
  secondary: { light: ({ theme }) => theme.colors.secondaryLabelBackground, dark: 'darkblue' },
  success: { light: 'green', dark: 'darkgreen' },
  warning: { light: 'white', dark: 'darkorange' },
  error: { light: ({ theme }) => theme.colors.errorLabelBackgroundColor, dark: 'darkorange' },
});

const textColor = theme.variants('mode', 'variant', {
  default: { light: ({ theme }) => theme.colors.defaultLabelTextColor, dark: 'darkgray' },
  secondary: { light: ({ theme }) => theme.colors.secondaryLabelTextColor, dark: 'darkblue' },
  success: { light: 'green', dark: 'darkgreen' },
  warning: { light: 'orange', dark: 'darkorange' },
  error: { light: ({ theme }) => theme.colors.errorLabelTextColor, dark: 'darkorange' },
});

const Label = styled.div`  
  background-color: ${backgroundColor};
  color: ${textColor};
  
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  ${({ small }) => small && `
    width: 100px;
    height: 31px;
    font-size: 10px;
  `}

  ${({ medium }) => medium && `
    width: 200px; 
    height: 40px;
    font-size: 16px;
  `}

  ${({ large }) => large && `
    width: 400px;  
    height: 50px;
    font-size: 20px;
  `}

  
${({ width }) => width && `
    width: `+width
  }
`

Label.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary', 'success', 'warning'])
};

Label.defaultProps = {
  variant: 'default',
};

export default Label
