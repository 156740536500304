/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Button from "../../../../components/Button";
import SideWindow from "../../../../components/SideWindow";
import TextArea from "../../../../components/TextArea"
import Select from "react-select"
import Loader from "../../../../components/Loader";
import { fetchAlertMotives } from "../../../../store/actions/alerts.actions";
import { bindActionCreators } from "redux";

import {
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper,
  TextLegend
} from "./styled";

const mapStateToProps = state => {
  const { alertMotives } = state.alerts;
  return {
    alertMotives: alertMotives
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertMotives
    },
    dispatch
  );

function MessageSlideBar(props) {
  const { isLoading, detailMessage, neighbourhoods, alertMotives } = props;

  useEffect(() => {
    if (neighbourhoods.length === 1) props.fetchAlertMotives(neighbourhoods[0].guid, true);
  }, []);

  const getNeighbourhoods = () => {
    return neighbourhoods.map(n => ({ label: n.name, value: n.guid }));
  };

  const getAlertMotivesOptions = () => {
    return alertMotives.map(n => ({ label: n.name, value: n.id, id: n.alert_motive_id }));
  };
  const getNotificationTypesOptions = () => {
    return props.notificationTypes.map(n => ({ label: n.name, value: n.id }));
  };

  const mapNotificationTypes = notificationTypes => {
    return notificationTypes.map(n => ({ label: n.name, value: n.id }));
  };

  const mapAlertMotive = (alertMotive, alertMotiveId) => {
    return { label: alertMotive, value: alertMotiveId, id: alertMotiveId };
  };

  const mapNeighbourhoods = neighbourhood => {
    return neighbourhood.map(n => ({ label: n.name, value: n.guid }));
  };

  const mapNeighbourhood = neighbourhood => {
    return { label: neighbourhood.name, value: neighbourhood.guid };
  };

  const onNeighbourhoodChange = async (data, setFieldValue) => {
    setFieldValue("neighbourhood", data);
    await props.fetchAlertMotives(data.value, true);
    setFieldValue("alert_motives", "");
  };

  const getInitialValues = () => {
    if (detailMessage?.length > 0) {
      return {
        id: detailMessage[0].id,
        description: detailMessage[0].description,
        alert_motive: mapAlertMotive(
          detailMessage[0].alert_motive,
          detailMessage[0].alert_motive_id
        ),
        notification_types: mapNotificationTypes(detailMessage[0].notification_types),
        neighbourhood: detailMessage
          ? mapNeighbourhood(detailMessage[0].neighbourhood)
          : neighbourhoods.length === 1
          ? mapNeighbourhoods(neighbourhoods)
          : ""
      };
    } else {
      return {
        id: 0,
        description: "",
        alert_motive: "",
        notification_types: ""
      };
    }
  };

  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        description: Yup.string().required("Campo obligatorio"),
        alert_motive: Yup.object().nullable().required("Campo obligatorio"),
        notification_types: Yup.array().nullable().required("Campo obligatorio")
      })
    );

  const getFormContent = ({
    handleSubmit,
    values,
    setFieldValue,
    setValues,
    resetForm,
    errors
  }) => {
    const neighbourhoodsDrop = getNeighbourhoods();
    const alertMotives = getAlertMotivesOptions();
    const notificationTypes = getNotificationTypesOptions();
    return (
      <Form onSubmit={handleSubmit}>
        <FlexWrapper>
          {neighbourhoods.length > 1 ? (
            <InputWrapper width={100}>
              <InputLabel>{"Barrio"}</InputLabel>
              <Select
                value={values.neighbourhood}
                onChange={value => onNeighbourhoodChange(value, setFieldValue)}
                options={neighbourhoodsDrop}
                placeholder="Seleccione un barrio"
                isLoading={props.isLoading}
              />
              {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
            </InputWrapper>
          ) : null}
          <InputWrapper width={100}>
            <InputLabel>{"Motivo de alerta"}</InputLabel>
            <Select
              value={values.alert_motive}
              onChange={value => setFieldValue("alert_motive", value)}
              options={alertMotives}
              placeholder="Seleccione un motivo de alerta"
              isLoading={props.isLoading}
            />
            {errors.alert_motive && <ErrorLabel>{errors.alert_motive}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper width={100}>
            <InputLabel>{"Seleccione el/los canal/es de envio"}</InputLabel>
            <Select
              value={values.notification_types}
              onChange={value => setFieldValue("notification_types", value)}
              options={notificationTypes}
              placeholder="Seleccione un tipo de notificación"
              isLoading={props.isLoading}
              isMulti
            />
            {errors.notification_types && <ErrorLabel>{errors.notification_types}</ErrorLabel>}
          </InputWrapper>

          <InputWrapper>
            <InputLabel>{"Escribí el texto del mensaje"}</InputLabel>
            <TextArea
              height={"18em"}
              name={"descriptionInput"}
              label={"descriptionInput"}
              placeholder={"Ingrese una descripción..."}
              value={values.description}
              error={errors.description}
              onChange={({ target: value }) => setFieldValue("description", value.value)}
              margin="normal"
            />
            {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
            <TextLegend>
              {
                "Se puede agregar al mensaje los tag {LOT} y/o {PROPIETARY} en caso de querer informar el lote y el propietario del mensaje enviado para la alerta. Por ej El propietario {PROPIETARY} envió una alarma de incendio del lote {LOT}"
              }
            </TextLegend>
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <ButtonWrapper>
            <Button type={"submit"} extraLarge={true} loading={false}>
              Guardar
            </Button>
          </ButtonWrapper>
        </FlexWrapper>
      </Form>
    );
  };

  const form = () => {
    if (isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <SideWindow title={props.title} onClose={props.onClose} headerBackgroundColor={"#30735f"}>
      {form()}
    </SideWindow>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageSlideBar);
