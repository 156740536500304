import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'


const initialState = {
  rows: [],
  page: 0,
  pageSize: 10,
  total: 80,
  isLoading: false,
  orderColumn: 'creation_date',
  orderDirection: 'desc',
  searchText: '',
  getNews: false,
  paymentOperations: [],
  totalPaymentOperations: [],
  loadingPaymentOperations: false,
  errorPaymentOperations: null,
  operationDetail: [],
  loadingOperationDetail: false,
  paymentMethodDetail: [],
  loadingMethodDetail: false,
  cancelPaymentOperation: null,
  loadingCancelPaymentOperation: false,
  errorCancelPaymentOperation: null,
  cancelPaymentOperationSuccess: null,
  paymentStates: [],

  //FETCH ERROR DETAIL
  errorDetail: null,
  loadingFetchErrorDetail: false,
  fetchErrorDetailSuccess: false,

  //FETCH PAYMENT OPERATIONS REPORT
  paymentOperationsReportData: null,
  loadingFetchPaymentOperationsReport: false,
  fetchOperationsReportSuccess: false,
  errorFetchOperationsReport: null,

  //FETCH PAYMENT OPERATION STATES
  paymentOperationStatesData: [],
  loadingFetchPaymentOperationStates: false,
  fetchPaymentOperationStatesSuccess:false,
  errorFetchPaymentOperationStates:null,

}

//////////////////MASCOTAS//////////////////
const fetchPaymentOperationsSuccess = (state, action) => {
  return updateObject(state, {
    paymentOperations: action.paymentOperations,
    loadingPaymentOperations: false
  })
}

const resetPage = (state) => {
  return updateObject(state, {
    searchText: ''
  })
}

const fetchPaymentOperationsFail = (state, action) => {
  return updateObject(state, {
    loadingPaymentOperations: false,
    errorPaymentOperations: action.errorPaymentOperations
  })
}

const fetchPaymentOperationsStart = (state) => {
  return updateObject(state, {
    loadingPaymentOperations: true,
    errorPaymentOperations: null
  })
}

const updateGrid = (state, action) => {
  return updateObject(state, {
    page: action.page,
    pageSize: action.pageSize,
    total: action.total,
    orderColumn: action.orderColumn,
    orderDirection: action.orderDirection,
    searchText: action.searchText,
    getNews: action.getNews
  })
}

const fetchOperationDetailSuccess = (state, action) => {
  return updateObject(state, {
    operationDetail: action.operationDetail,
    loadingOperationDetail: false
  })
}

const fetchOperationDetailStart = (state, action) => {
  return updateObject(state, {

    loadingOperationDetail: true
  })
}

const fetchOperationDetailFail = (state) => {
  return updateObject(state, {

    loadingOperationDetail: false,
    operationDetail: []
  })
}
/** PAYMENT METHOD DETAIL **/
const fetchPaymentMethodDetailSuccess = (state, action) => {

  return updateObject(state, {
    paymentMethodDetail: action.paymentMethodDetail,
    loadingMethodDetail: false
  })
}

const fetchPaymentMethodDetailStart = (state, action) => {
  return updateObject(state, {

    loadingMethodDetail: true
  })
}

const fetchPaymentMethodDetailFail = (state) => {
  return updateObject(state, {

    loadingMethodDetail: false,
    paymentMethodDetail: []
  })
}


/** Cancel Payment operation **/


const cancelPaymentOperationSuccess = (state, action) => {

  return updateObject(state, {
    cancelPaymentOperation: action.data,
    loadingCancelPaymentOperation: false,
    cancelPaymentOperationSuccess: true
  })
}

const cancelPaymentOperationStart = (state, action) => {
  return updateObject(state, {
    errorCancelPaymentOperation: null,
    loadingCancelPaymentOperation: true,
    cancelPaymentOperationSuccess: false
  })
}

const cancelPaymentOperationFail = (state, action) => {
  return updateObject(state, {

    loadingCancelPaymentOperation: false,
    cancelPaymentOperation: null,
    errorCancelPaymentOperation: action.error,
    cancelPaymentOperationSuccess: false
  })
}
const cancelPaymentOperationReset = (state, action) => {
  return updateObject(state, {

    loadingCancelPaymentOperation: false,
    cancelPaymentOperation: null,
    errorCancelPaymentOperation: null,
    cancelPaymentOperationSuccess: false
  })
}

// FETCH ERROR DETAIL


const fetchErrorDetailSuccess = (state, action) => {
  return updateObject(state, {
    fetchErrorDetailSuccess: true,
    loadingFetchErrorDetail: false,
    errorDetail: action.errorDetail
  })
}

const fetchErrorDetailStart = (state, action) => {
  return updateObject(state, {
    loadingFetchErrorDetail: true
  })
}

const fetchErrorDetailFail = (state) => {
  return updateObject(state, {
    fetchErrorDetailSuccess: false,
    loadingFetchErrorDetail: false,
  })
}


// FETCH PAYMENT OPERATIONS REPORT //

const fetchPaymentOperationsReportSuccess = (state, action) => {
  return updateObject(state, {
    fetchOperationsReportSuccess: true,
    loadingFetchPaymentOperationsReport: false,
    paymentOperationsReportData: action.paymentOperationsReportData
  })
}

const fetchPaymentOperationsReportStart = (state, action) => {
  return updateObject(state, {
    loadingFetchPaymentOperationsReport: true
  })
}

const fetchPaymentOperationsReportFail = (state, action) => {
  return updateObject(state, {
    fetchOperationsReportSuccess: false,
    loadingFetchPaymentOperationsReport: false,
    errorFetchOperationsReport:action.error
  })
}

const fetchPaymentOperationsReportReset = (state) => {
  return updateObject(state, {
    fetchOperationsReportSuccess: false,
    loadingFetchPaymentOperationsReport: false,
    paymentOperationsReportData: null
  })
}




//FETCH PAYMENT OPERATION STATES
const fetchPaymentOperationStatesSuccess = (state, action) => {
  return updateObject(state, {
    fetchPaymentOperationStatesSuccess: true,
    loadingFetchPaymentOperationStates: false,
    paymentOperationStatesData: action.paymentOperationStatesData
  })
}

const fetchPaymentOperationStatesFail = (state, action) => {
  return updateObject(state, {
    fetchPaymentOperationStatesSuccess: false,
    loadingFetchPaymentOperationStates:false,
    errorFetchPaymentOperationStates:action.error
  })
}

const fecthPaymentOperationStatesStart = (state, action) => {
  return updateObject(state, {
    loadingFetchPaymentOperationStates: true
  })
}

const fetchPaymentOperationStatesReset = (state, action) => {
  return updateObject(state, {
    fetchPaymentOperationStatesSuccess:false,
    loadingFetchPaymentOperationStates: false,
    errorFetchPaymentOperationStates: null,
    paymentOperationStatesData: null
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_OPERATIONS_SUCCESS:
      return fetchPaymentOperationsSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATIONS_FAIL:
      return fetchPaymentOperationsFail(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATIONS_START:
      return fetchPaymentOperationsStart(state, action)
    case actionTypes.PAYMENT_OPERATIONS_UPDATEGRID:
      return updateGrid(state, action)
    case actionTypes.RESET_PAYMENT_OPERATIONS_PAGE:
      return resetPage(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATION_DETAIL_SUCCESS:
      return fetchOperationDetailSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATION_DETAIL_START:
      return fetchOperationDetailStart(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATION_DETAIL_FAIL:
      return fetchOperationDetailFail(state, action)

    case actionTypes.FETCH_PAYMENT_METHOD_DETAIL_SUCCESS:
      return fetchPaymentMethodDetailSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_DETAIL_START:
      return fetchPaymentMethodDetailStart(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_DETAIL_FAIL:
      return fetchPaymentMethodDetailFail(state, action)

    case actionTypes.CANCEL_PAYMENT_OPERATION_SUCCESS:
      return cancelPaymentOperationSuccess(state, action)
    case actionTypes.CANCEL_PAYMENT_OPERATION_FAIL:
      return cancelPaymentOperationFail(state, action)
    case actionTypes.CANCEL_PAYMENT_OPERATION_START:
      return cancelPaymentOperationStart(state, action)
    case actionTypes.CANCEL_PAYMENT_OPERATION_RESET:
      return cancelPaymentOperationReset(state, action)

    case actionTypes.FETCH_ERROR_DETAIL_SUCCESS:
      return fetchErrorDetailSuccess(state, action)
    case actionTypes.FETCH_ERROR_DETAIL_FAIL:
      return fetchErrorDetailFail(state, action)
    case actionTypes.FETCH_ERROR_DETAIL_START:
      return fetchErrorDetailStart(state, action)

    case actionTypes.FETCH_PAYMENT_OPERATIONS_REPORT_SUCCESS:
      return fetchPaymentOperationsReportSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATIONS_REPORT_FAIL:
      return fetchPaymentOperationsReportFail(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATIONS_REPORT_START:
      return fetchPaymentOperationsReportStart(state, action)
    case actionTypes.FETCH_PAYMENT_OPERATIONS_REPORT_RESET:
      return fetchPaymentOperationsReportReset(state, action)
      
    case actionTypes.FETCH_PAYMENT_STATES_SUCCESS:
      return fetchPaymentOperationStatesSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_STATES_FAIL:
      return fetchPaymentOperationStatesFail(state,action)
    case actionTypes.FETCH_PAYMENT_STATES_START:
      return fecthPaymentOperationStatesStart(state, action)
    case actionTypes.FETCH_PAYMENT_STATES_RESET:
      return fetchPaymentOperationStatesReset(state, action)

    default:
      return state
  }
}




export default reducer