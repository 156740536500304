import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding:20px;
  
`
export const Header = styled.div`
  display: flex;
  justify-content:flex-end;
  width: 100%;
`
export const CloseIcon = styled.img`
  cursor: pointer;
  width: 20px;
`
export const Title = styled.h1`
  font-size: 34px;
`
export const Button = styled.button`
  cursor: pointer;
  width: 200px;
  background: #5178FF;
  font-size: 16px;
  border-radius: 20px;
  color: white;
  border: 1px solid #5178FF;
  margin: 0 1em;
  padding: 12px 30px; 
  transition: 0.5s all ease-out;
  &:focus{
    direction: none;
    outline:none;
  }
`
export const Footer = styled.div`
  display: flex;  
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`
export const Body = styled.div`
text-align: center;

width: 100%;
padding: 40px;
`
export const ErrorMsg = styled.label`
  font-weight: bold;
  color: red;
`
