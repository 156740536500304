
import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import RitzTable from '../../components/RitzTable';
import SectionHeader from '../../components/SectionHeader'
import Button from '../../components/Button';
import ResidentDetail from './ResidentDetail';
import FamilyMembersDetail from './FamilyMembersDetail';
import NewResident from './NewResident';
import EditResident from './EditResident';
import Person from '../../components/Person';
import mapProprietary from '../../selectors/mapProprietary'
import mapNeighbourhoods from '../../selectors/mapNeighbourhoods'
import { fetchPadron, fetchPropietaryFamily, fetchPropietaryVehicle } from '../../store/actions/padron.actions'
import { setNeighbourhood } from '../../store/actions/userInfo.actions';
import Select from 'react-select';
import { Label, SelectWrapper } from './styled';
import PersonalSeguridad from './PersonalSeguridad';


const menulistItems = [
  { title: 'Lista de propietarios', route: 'propietarios', available: true },
  { title: 'Personal de Seguridad', route: 'seguridad', available: true },

];

class Padron extends React.Component {

  state = {
    viewMembers: false, //Renders a different component 
    memberDetails: false, //Renders a side-window 
    newMemberModal: false, //Render a modal
    editMemberModal: false, //Render a modal
    search: "",
    view: 'propietarios',
    selectedResident: {},
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    }
  };

  componentDidMount() {
    this.props.fetchPadron()
  }

  searchHandleChange = (event) => {
    this.setState({ search: event.target.value });
  }

  toggleState = (state, resident) => {
    console.log("click",resident)
    this.setState({ [state]: !this.state[state] });
    if (state === 'memberDetails') {
      this.setState({ selectedResident: resident })
    } else {
      this.setState({ selectedResident: resident })
    }
  }

  toggleFamilyDetails(row, resident) {
    //console.log('row data', row)

    this.setState({ selectedResident: resident })
    this.setState({ viewMembersDetails: !this.state.viewMembersDetails })

    this.props.fetchPropietaryFamily(row.id)
    this.props.fetchPropietaryVehicle(row.username)
  }


  toggleEditMemberModal(row, resident) {
    console.log('row data', resident)

    this.setState({ selectedResident: resident })
    this.setState({ editMemberModal: !this.state.editMemberModal })


  }

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }

  handleChange = (neighbourhoodSelected) => {
    this.props.setNeighbourhood(neighbourhoodSelected)
    this.setState({ neighbourhoodSelected: neighbourhoodSelected });

    this.props.fetchPadron()
  }


  switchView = (view) => {
    this.setState({ view });
  }

  render() {
    console.log("this props ",this.props)

    if (!this.state.viewMembers) {
      return (
        <>
          <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Padron'>
            {(() => {
              switch (this.state.view) {
                case 'propietarios':
                  return (
                    <>
                      <SectionHeader title='Listado de Propietarios'>
                        <SelectWrapper>
                          <Select
                            value={this.state.neighbourhoodSelected}
                            onChange={this.handleChange}
                            options={this.props.neighbourhoods}
                          />
                        </SelectWrapper>
                        <Button medium onClick={() => this.toggleState('newMemberModal')}>Agregar</Button>
                      </SectionHeader>
                      <RitzTable
                        paging={true}
                        columns={[
                          { title: 'Nombre Completo', field: 'fullname', cellStyle: { borderRadius: '6px 0px 0px 6px' }, render: rowData => <Person fullname={rowData.fullname} picture={rowData.picture_url} /> },
                          { title: 'Lote', field: 'propiedades' },
                          { title: 'Barrio', field: 'neighbourhoods_name' },
                          { title: 'Email', field: 'email' },
                          { title: 'Ver detalles', field: 'additional_info', render: rowData => <Label onClick={() => this.toggleState('memberDetails', { rowData })}>Ver detalles</Label> },
                          { title: 'Miembros familiares', field: 'family_members', render: rowData => <Label onClick={() => this.toggleFamilyDetails(rowData, { rowData })}>Ver familiares</Label> },
                          { title: 'Acciónes', field: 'actions', render: rowData => <Label onClick={() => this.toggleEditMemberModal(rowData, { rowData })}>Editar</Label> },
                        ]}
                        data={this.props.mappedPropietaries}
                      />
                      {this.state.memberDetails && <ResidentDetail onClose={() => this.toggleState("memberDetails")} {...this.state.selectedResident} />}
                      {this.state.viewMembersDetails && <FamilyMembersDetail onClose={() => this.toggleState("viewMembersDetails")} {...this.state.selectedResident} familia={this.props.family} familiaLoading={this.props.loadingFamily} vehiculo={this.props.vehicle} vehiculoLoading={this.props.loadingVehicle} familySuccess={this.props.familySuccess} vehicleSuccess={this.props.vehicleSuccess} padron={this.props.padron} />}
                      {this.state.newMemberModal && <NewResident onClose={() => this.toggleState("newMemberModal")} />}
                      {this.state.editMemberModal && <EditResident {...this.state.selectedResident} onClose={() => this.toggleState("editMemberModal")} />}
                    </>
                  );

                case 'seguridad':
                  return (

                    <PersonalSeguridad />

                  );
              }
            })()}
          </NavBar>

        </>
      );

    }
  }


}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
  padron,
}) => {
  return {
    padron: padron,
    mappedPropietaries: mapProprietary(padron.proprietaryList),
    itemSearched: padron.proprietaryList,
    loading: padron.loading,
    roles: menusAuth,
    hasMore: padron.hasMore,
    neighbourhoods: mapNeighbourhoods(neighbourhoods),
    selectedNeighbourhood,

    loadingVehicle: padron.loadingFetchPropietaryFamily,
    loadingFamily: padron.loadingFetchPropietaryVehicle ,

    family: padron.family,
    vehicle: padron.vehicle,

    familySuccess: padron.fetchPropietaryFamilySuccess,
    vehicleSuccess: padron.fetchPropietaryVehicleSuccess,

  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchPadron,
    setNeighbourhood,
    fetchPropietaryFamily,
    fetchPropietaryVehicle,
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Padron)
)
