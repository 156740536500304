import * as actionTypes from "./actionTypes";

import {
  getTransactionUserAPI,
  postRequestedTransactionAPI,
  getCodeQRImageAPI,
  postCreateTransactionRequestAPI,
  getAccountWithUniqueKeyAPI,
  getAccountWithAliasAPI,
  getAccountWithLoteAPI,
  getDebitAccountInfo,
  getTransferPendingAPI,
  getNumberOfTransferPendingAPI,
  getApproveTransferAPI,
  getDeniedTransferAPI,
  getRegenerateOpenQR,
  getApproveTransferWithTokenAPI,
  postReSendToken,
  getAccountQR,
  getBalanceUserAPI,
  TransactionChargeWithCardAPI,
  getTransactionDataAPI,
  getDebitAccountsInfo,
  getTokenPayMi,
  generatePaymentPayMi,
  getDownloadsummary,
  lastMovementsAPI
} from "../../apiConstants";

import getApi from "../../shared/api";
import fetchData from "../../shared/fetchData";
import mapLastMovementsReport from '../../selectors/mapLastMovementsReport'

export const fetchTransactionListSuccess = transactions => {
  return {
    type: actionTypes.FETCH_TRANSACTION_LIST_SUCCESS,
    transactions: transactions
  };
};

export const fetchTransactionListFail = error => {
  return {
    type: actionTypes.FETCH_TRANSACTION_LIST_FAIL,
    error: error
  };
};

export const fetchTransactionListStart = () => {
  return {
    type: actionTypes.FETCH_TRANSACTION_LIST_START
  };
};

export const fetchTransactionList = (reset, selectedBankAccountId) => {
  return async (dispatch, getState) => {
    dispatch(fetchTransactionListStart());
    const {
      billetera,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid);
    let eventTake = reset ? 10 : billetera.eventTake;
    const page = billetera.page;
    try {
      const response = await api.get(`${getTransactionUserAPI}${page}/10` + `?bankAccountId=${selectedBankAccountId}`);

      let hasMoreUpdated = response.data.length === eventTake;
      let takeUpdated = hasMoreUpdated ? eventTake + 10 : eventTake;
      dispatch(
        fetchTransactionListSuccess({
          values: response.data,
          eventTake: takeUpdated,
          hasMoreTransactions: hasMoreUpdated
        })
      );
    } catch (error) {
      dispatch(fetchTransactionListFail(error));
    }
  };
};
//------------------Request HasMorePages -----------------------------------
export const hasMoreTransactionListSuccess = hasMore => {
  return {
    type: actionTypes.HAS_MORE_TRANSACTION_LIST_SUCCESS,
    hasMore: hasMore
  };
};
export const hasMoreTransactionList = (reset, selectedBankAccountId) => {
  return async (dispatch, getState) => {
    const {
      billetera,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid);
    const page = billetera.page + 1;
    try {
      const response = await api.get(`${getTransactionUserAPI}${page}/10` + `?bankAccountId=${selectedBankAccountId}`);
      dispatch(
        hasMoreTransactionListSuccess(response.data.length > 0 ? true : false)
      );
    } catch (error) {
    }
  };
};
//------------------Request Transaction -----------------------------------
export const fetchTransactionQRSuccess = transactions => {
  let transaction;
  if (transactions === '') {
    transaction = 'RegenerateFail';
  } else {
    transaction = transactions;
  }
  return {
    type: actionTypes.FETCH_TRANSACTION_QR_SUCCESS,
    transactions: transaction
  }
}

export const fetchTransactionQRFail = error => {
  return {
    type: actionTypes.FETCH_TRANSACTION_QR_FAIL,
    error: error
  }
}

export const fetchTransactionQRStart = () => {
  return {
    type: actionTypes.FETCH_TRANSACTION_QR_START
  }
}

export const fetchTransactionQR = transaction => {
  return async (dispatch, getState) => {
    dispatch(fetchTransactionQRStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(postRequestedTransactionAPI, transaction)
      .then(res => {
        dispatch(fetchTransactionQRSuccess(res.data))
        dispatch(fetchGetCodeQRImage(res.data.id))
      })
      .catch(err => {
        dispatch(fetchTransactionQRFail(err))
      })
  }
}

export const fetchOpenQR = () => {
  return async (dispatch, getState) => {
    dispatch(fetchTransactionQRStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .get(getRegenerateOpenQR)
      .then(res => {
        dispatch(fetchTransactionQRSuccess(res.data))
        dispatch(fetchGetCodeQRImageSuccess(res.data.qr_base64))
      })
      .catch(err => {
        dispatch(fetchTransactionQRFail(err))
      })
  }
}

//------------------QR IMAGE-----------------------------------
export const fetchGetCodeQRImageSuccess = codeQR => {
  return {
    type: actionTypes.FETCH_GET_CODE_QR_IMAGE_SUCCESS,
    GetCodeQRImage: codeQR
  }
}

export const fetchGetCodeQRImageFail = error => {
  return {
    type: actionTypes.FETCH_GET_CODE_QR_IMAGE_FAIL,
    error: error
  }
}

export const fetchGetCodeQRImageStart = () => {
  return {
    type: actionTypes.FETCH_GET_CODE_QR_IMAGE_START
  }
}

export const fetchGetCodeQRImage = id => {
  return async (dispatch, getState) => {
    dispatch(fetchGetCodeQRImageStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .get(`${getCodeQRImageAPI}${id}`)
      .then(res => {
        dispatch(fetchGetCodeQRImageSuccess(res.data.qr_base64))
      })
      .catch(err => {
        dispatch(fetchGetCodeQRImageFail(err))
      })
  }
}




//------------------Crear Transferencia-----------------------------------

export const fetchCreateTransactionRequestSuccess = requestTransaction => {
  return {
    type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS,
    requestTransaction: requestTransaction
  }
}

export const fetchCreateTransactionRequestFail = error => {
  return {
    type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_FAIL,
    errorMessageCreateTransactionRequest: error
  }
}

export const fetchCreateTransactionRequestStart = () => {
  return {
    type: actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_START
  }
}

export const fetchCreateTransactionRequest = body => {
  return async (dispatch, getState) => {
    dispatch(fetchCreateTransactionRequestStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(postCreateTransactionRequestAPI, body)
      .then(res => {
        dispatch(fetchCreateTransactionRequestSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchCreateTransactionRequestFail(err.response.data.message))
      })
  }
}

//------------------Get Account Info-----------------------------------

export const fetchGetAccountSuccess = AccountInfo => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_SUCCESS,
    AccountInfo: AccountInfo
  }
}

export const fetchGetAccountFail = error => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_FAIL,
    errorAccountInfo: error
  }
}

export const fetchGetAccountStart = () => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_START,
  }
}

export const fetchGetAccount = uniqueKey => {
  return async (dispatch, getState) => {
    dispatch(fetchGetAccountStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    var apiUrl;

    uniqueKey.length > 21 ? apiUrl = getAccountWithUniqueKeyAPI : apiUrl = getAccountWithAliasAPI

    return api
      .get(`${apiUrl}${uniqueKey}`)
      .then(res => {
        dispatch(fetchGetAccountSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountFail(err.response.data.message))
      })
  }
}

//------------------Get Account Info by LOTE-----------------------------------

export const fetchGetAccountByLoteSuccess = AccountInfoByLote => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS,
    AccountInfoByLote: AccountInfoByLote
  }
}

export const fetchGetAccountByLoteFail = error => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_FAIL,
    errorAccountInfoByLote: error
  }
}

export const fetchGetAccountByLoteStart = () => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_START,
  }
}

export const fetchGetAccountByLote = lote => {
  return async (dispatch, getState) => {
    dispatch(fetchGetAccountByLoteStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    let url = getAccountWithLoteAPI.concat(lote)
    return api
      .get(url)
      .then(res => {
        dispatch(fetchGetAccountByLoteSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountByLoteFail(err.response.data.message))
      })
  }
}

//------------------Get Account DEBIT info-----------------------------------

export const fetchGetAccountDebitSuccess = AccountDebitInfo => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_SUCCESS,
    loadingAccountDebitInfo: false,
    AccountDebitInfo: AccountDebitInfo
  }
}

export const fetchGetAccountDebitFail = error => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_FAIL,
    loadingAccountDebitInfo: false,
    errorAccountDebitInfo: error
  }
}

export const fetchGetAccountDebitStart = () => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_DEBIT_START,
    loadingAccountDebitInfo: true,
  }
}

export const fetchGetAccountDebit = uniqueKey => {
  return async (dispatch, getState) => {
    dispatch(fetchGetAccountDebitStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .get(`${getDebitAccountInfo}`)
      .then(res => {
        dispatch(fetchGetAccountDebitSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountDebitFail(err))
      })
  }
}


//------------------Get Account For QR-----------------------------------

export const fetchGetAccountQRSuccess = AccountQR => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_QR_SUCCESS,
    loadingAccountQR: false,
    AccountQR: AccountQR
  }
}

export const fetchGetAccountQRFail = error => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_QR_FAIL,
    loadingAccountQR: false,
    errorAccountQR: error
  }
}

export const fetchGetAccountQRStart = () => {
  return {
    type: actionTypes.FETCH_GET_ACCOUNT_QR_START,
    loadingAccountQR: true,
  }
}

export const fetchGetAccountQR = body => {
  return async (dispatch, getState) => {
    dispatch(fetchGetAccountQRStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(getAccountQR, body)
      .then(res => {
        dispatch(fetchGetAccountQRSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetAccountQRFail(err))
      })
  }
}


//------------------Get Transfer to aprove-----------------------------------

export const fetchGetTransferPendingSuccess = TransferPending => {
  return {
    type: actionTypes.FETCH_GET_TRANSFER_PENDING_SUCCESS,
    loadingTransferRequest: false,
    TransferPending: TransferPending
  }
}

export const fetchGetTransferPendingFail = error => {
  return {
    type: actionTypes.FETCH_GET_TRANSFER_PENDING_FAIL,
    loadingTransferRequest: false,
    errorTransferRequest: error
  }
}

export const fetchGetTransferPendingStart = () => {
  return {
    type: actionTypes.FETCH_GET_TRANSFER_PENDING_START,
    loadingTransferRequest: true,
  }
}

export const fetchGetTransferPending = body => {
  return async (dispatch, getState) => {
    dispatch(fetchGetTransferPendingStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(getTransferPendingAPI, body)
      dispatch(fetchGetTransferPendingSuccess(response.data))
    } catch (error) {
      dispatch(fetchGetTransferPendingFail(error))
    }

  }
}


//-------------Get Total number of Transfer to aprove-----------------------------------

export const fetchGetNumberOfTransferPendingSuccess = NumberOfTransferRequest => {
  return {
    type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS,
    loadingNumberOfTransferRequest: false,
    NumberOfTransferRequest: NumberOfTransferRequest
  }
}

export const fetchGetNumberOfTransferPendingFail = error => {
  return {
    type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL,
    loadingNumberOfTransferRequest: false,
    errorNumberOfTransferRequest: error
  }
}

export const fetchGetNumberOfTransferPendingStart = () => {
  return {
    type: actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START,
    loadingNumberOfTransferRequest: true,
  }
}

export const fetchGetNumberOfTransferPending = body => {
  return async (dispatch, getState) => {
    dispatch(fetchGetNumberOfTransferPendingStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(getNumberOfTransferPendingAPI, body)

      dispatch(fetchGetNumberOfTransferPendingSuccess(response.data))
    } catch (error) {
      dispatch(fetchGetNumberOfTransferPendingFail(error))
    }
  }
}


//-------------Approve Transaction-----------------------------------

export const fetchApproveTransactionSuccess = ApproveTransfer => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_SUCCESS,
    loadingApproveTransfer: false,
    ApproveTransfer: ApproveTransfer
  }
}

export const fetchApproveTransactionFail = error => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_FAIL,
    loadingApproveTransaction: false,
    errorApproveTransaction: true,
    errorApproveTransactionMessage: error
  }
}

export const fetchApproveTransactionStart = () => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_START,
    loadingApproveTransaction: true,
  }
}

export const fetchApproveTransaction = body => {
  return async (dispatch, getState) => {
    dispatch(fetchApproveTransactionStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(getApproveTransferAPI, body)
      .then(res => {
        if (res.data.message === "Token inválido") {
          dispatch(
            fetchApproveTransactionFail(res.data.message),
            fetchGetTransferPending()
          )
        } else {
          dispatch(
            fetchApproveTransactionSuccess(res.data),
            fetchGetTransferPending(),
            fetchBalance()
          )
        }
      })
      .catch(err => {
        dispatch(fetchApproveTransactionFail(err))
      })
  }
}
//-------------Reset transfer operation response-------------------------------

export const resetTransactionOperationResponse = () => {
  return {
    type: actionTypes.RESET_TRANSACTION_OPERATION_RESPONSE
  }
}

//-------------Denied Transaction-----------------------------------

export const fetchDeniedTransactionSuccess = DeniedTransfer => {
  return {
    type: actionTypes.FETCH_DENIED_TRANSACTION_SUCCESS,
    loadingDeniedTransfer: false,
    DeniedTransfer: DeniedTransfer
  }
}

export const fetchDeniedTransactionFail = error => {
  return {
    type: actionTypes.FETCH_DENIED_TRANSACTION_FAIL,
    errorDeniedTransactionMessage: error,
    errorDeniedTransfer: true
  }
}

export const fetchDeniedTransactionStart = () => {
  return {
    type: actionTypes.FETCH_DENIED_TRANSACTION_START,
    loadingDeniedTransfer: true,
  }
}

export const fetchDeniedTransaction = body => {
  return async (dispatch, getState) => {
    dispatch(fetchDeniedTransactionStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(getDeniedTransferAPI, body)
      .then(res => {
        if (res.data.message === 'Token inválido') {
          dispatch(fetchDeniedTransactionFail(res.data.message))
        } else {
          dispatch(
            fetchDeniedTransactionSuccess(res.data),
            fetchBalance()
          )
        }
      })
      .catch(err => {
        dispatch(fetchDeniedTransactionFail(err))
      })
  }
}


//-------------Approve Transaction-----------------------------------

export const fetchApproveTransactionWithTokenSuccess = ApproveTransfer => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_SUCCESS,
    loadingApproveTransfer: false,
    ApproveTransfer: ApproveTransfer
  }
}

export const fetchApproveTransactionWithTokenFail = error => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_FAIL,
    loadingApproveTransfer: false,
    errorApproveTransfer: true
  }
}

export const fetchApproveTransactionWithTokenStart = () => {
  return {
    type: actionTypes.FETCH_APPROVE_TRANSACTION_START,
    loadingApproveTransfer: true,
  }
}

export const fetchApproveTransactionWithToken = (body, token) => {
  return async (dispatch, getState) => {
    dispatch(
      fetchApproveTransactionWithTokenStart()
    )
    let url = getApproveTransferWithTokenAPI + token
    var response = await fetchData(url, 'POST', body)
    response.error ? (
      dispatch(
        fetchApproveTransactionWithTokenFail(response),
        fetchBalance()
      )
    ) : (
        dispatch(
          fetchApproveTransactionWithTokenSuccess(response),
          fetchBalance()
        )
      )
  }
}

//-------------Re send token--------------------------

export const fetchReSendTokenSuccess = token => {
  fetchGetTransferPending()
  return {
    type: actionTypes.FETCH_RESEND_TOKEN_SUCCESS,
    loadingReSendToken: false,
    token: token
  }
}

export const fetchReSendTokenFail = error => {
  return {
    type: actionTypes.FETCH_RESEND_TOKEN_FAIL,
    loadingReSendToken: false,
    errorReSendToken: true
  }
}

export const fetchReSendTokenStart = () => {
  return {
    type: actionTypes.FETCH_RESEND_TOKEN_START,
    loadingReSendToken: true,
  }
}

export const fetchReSendToken = () => {
  return async (dispatch, getState) => {
    dispatch(fetchReSendTokenStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(postReSendToken)
      .then(res => {
        dispatch(fetchReSendTokenSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchReSendTokenFail(err.response.data.message))
      })
  }
}



//-------------Get Balance-------------------------

export const fetchBalanceSuccess = balance => {
  fetchGetTransferPending()
  return {
    type: actionTypes.FETCH_BALANCE_SUCCESS,
    loadingBalance: false,
    balance: balance
  }
}

export const fetchBalanceFail = error => {
  return {
    type: actionTypes.FETCH_BALANCE_FAIL,
    loadingBalance: false,
    errorBalance: true
  }
}

export const fetchBalanceStart = () => {
  return {
    type: actionTypes.FETCH_BALANCE_START,
    loadingBalance: true,
  }
}

export const fetchBalance = (selectedBankAccountId) => {
  return async (dispatch, getState) => {
    dispatch(fetchBalanceStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(getBalanceUserAPI + `?bankAccountId=${selectedBankAccountId}`)

      dispatch(
        fetchBalanceSuccess(response)
      )
    } catch (error) {
      dispatch(fetchBalanceFail(error))
    }
  }
}

//-------------Transaction Charge With CardAPI-------------------------

export const fetchTransactionChargeWithCardSuccess = transactionChargeCard => {
  fetchGetTransferPending()
  return {
    type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_SUCCESS,
    loadingTransactionChargeCard: false,
    transactionChargeCard: transactionChargeCard
  }
}

export const fetchTransactionChargeWithCardFail = error => {
  return {
    type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_FAIL,
    loadingTransactionChargeCard: false,
    errorTransactionChargeCard: error
  }
}

export const fetchTransactionChargeWithCardStart = () => {
  return {
    type: actionTypes.FETCH_TRANSACTION_CHARGE_CARD_START,
    loadingTransactionChargeCard: true,
    errorTransactionChargeCard: false
  }
}

export const fetchTransactionChargeWithCard = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchTransactionChargeWithCardStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.post(TransactionChargeWithCardAPI, body)
      dispatch(
        fetchTransactionChargeWithCardSuccess(response.data)
      )
    } catch (err) {
      dispatch(fetchTransactionChargeWithCardFail(err.response.data.message))
    }
  }
}


//------------- Movements in last 24 hours Report -------------------------
export const fetchMovementsLast24HoursReportSuccess = movementsLast24Hours => {
  let ingresos = mapLastMovementsReport(movementsLast24Hours.input_movements);
  let salidas = mapLastMovementsReport(movementsLast24Hours.output_movements);
  const data = {
    ingresos,
    salidas,
  }
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_SUCCESS,
    movementsLast24HoursReportData: data
  };
};

export const fetchMovementsLast24HoursReportFail = error => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_FAIL,
    errorDownloadReport: error
  };
};

export const fetchMovementsLast24HoursReportStart = () => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_START
  };
};

export const fetchMovementsLast24HoursReportReset = () => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_RESET ,
    errorDownloadReport: false
  };
};

export const fetchMovementsLast24HoursReport = (
  startDate,
  endDate,
  bankAccountId,
  neighbourhoodId
) => {
  return async (dispatch, getState) => {
    dispatch(fetchMovementsLast24HoursReportStart());

    const {
      userInfo: { menusAuth, neighbourhoods }
    } = getState();
    let api = await getApi(neighbourhoods.filter(x => x.isparent)[0].guid);

    return api
      .get(
        `${lastMovementsAPI}?neighbourhoodGuid=&bankAccountId=${bankAccountId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then(res => {
          dispatch(fetchMovementsLast24HoursReportSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchMovementsLast24HoursReportFail(error.response.data.error_messages[0]));
      });
  };
};

//------------- Movements in last 7 days Report -------------------------
export const fetchMovementsLast7DaysReportSuccess = movementsLast7Days => {
  let ingresos = mapLastMovementsReport(movementsLast7Days.input_movements);
  let salidas= mapLastMovementsReport(movementsLast7Days.output_movements);
  const data = {
    ingresos, 
    salidas, 
  } 
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_SUCCESS,
    movementsLast7DaysReportData: data
  };
};

export const fetchMovementsLast7DaysReportFail = error => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_FAIL,
    errorDownloadReport: error
  };
};

export const fetchMovementsLast7DaysReportStart = () => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_START
  };
};

export const fetchMovementsLast7DaysReportReset = () => {
  return {
    type: actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_RESET ,
    errorDownloadReport: false
  };
};

export const fetchMovementsLast7DaysReport = (
  startDate,
  endDate,
  bankAccountId,
  neighbourhoodId
) => {
  return async (dispatch, getState) => {
    dispatch(fetchMovementsLast7DaysReportStart());

    const {
      userInfo: { menusAuth, neighbourhoods }
    } = getState();
    let api = await getApi(neighbourhoods.filter(x => x.isparent)[0].guid);
    
    return api
      .get(
        `${lastMovementsAPI}?neighbourhoodGuid=&bankAccountId=${bankAccountId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then(res => {
          dispatch(fetchMovementsLast7DaysReportSuccess(res.data));
      })
      .catch(error => {
        dispatch(fetchMovementsLast7DaysReportFail(error.response.data.error_messages[0]));
      });
  };
};

//-------------Transaction data-------------------------

export const fetchTransactionDataSuccess = TransactionData => {
  return {
    type: actionTypes.FETCH_TRANSACTION_DATA_SUCCESS,
    loadingTransactionData: false,
    TransactionData: TransactionData
  }
}

export const fetchTransactionDataFail = error => {
  return {
    type: actionTypes.FETCH_TRANSACTION_DATA_FAIL,
    loadingTransactionData: false,
    errorTransactionData: error
  }
}

export const fetchTransactionDataStart = () => {
  return {
    type: actionTypes.FETCH_TRANSACTION_DATA_START,
    loadingTransactionData: true,
    errorTransactionData: false
  }
}

export const fetchTransactionData = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchTransactionDataStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    const url = getTransactionDataAPI + id
    try {
      const response = await api.get(url)
      dispatch(
        fetchTransactionDataSuccess(response.data)
      )
    } catch (err) {
      dispatch(fetchTransactionDataFail(err.response.data.message))
    }
  }
}


export const fetchDebitAccountsSuccess = bankAccounts => {
  return {
    type: actionTypes.FETCH_DEBIT_ACCOUNTS_SUCCESS,
    bankAccounts: bankAccounts,
    selectedBankAccount: (bankAccounts != null && bankAccounts.length > 0) ? bankAccounts[0] : null
  }
}

export const fetchDebitAccountsFail = error => {
  return {
    type: actionTypes.FETCH_DEBIT_ACCOUNTS_FAIL,
    errorFetchDebitAccounts: error
  }
}

export const fetchDebitAccountsStart = () => {
  return {
    type: actionTypes.FETCH_DEBIT_ACCOUNTS_START,
  }
}

export const fetchDebitAccounts = () => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentPayMiStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    api
      .get(getDebitAccountsInfo)
      .then(res => {
        dispatch(fetchDebitAccountsSuccess(res.data))
        if (res.data != null && res.data.length > 0) {
          dispatch(fetchBalance(res.data[0].Id))
          dispatch(fetchTransactionList(false, res.data[0].Id))
        }
        else {
          dispatch(fetchBalance())
          dispatch(fetchTransactionList())
        }


      })
      .catch(err => {
        dispatch(fetchDebitAccountsFail(err))
      })
  }
}
export const fetchDebitAccountsWithGuid = (guid) => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentPayMiStart())
    const api = await getApi(guid)
    api
      .get(getDebitAccountsInfo)
      .then(res => {
        dispatch(fetchDebitAccountsSuccess(res.data))
        if (res.data != null && res.data.length > 0) {
          dispatch(fetchBalance(res.data[0].Id))
          dispatch(fetchTransactionList(false, res.data[0].Id))
        }
        else {
          dispatch(fetchBalance())
          dispatch(fetchTransactionList())
        }


      })
      .catch(err => {
        dispatch(fetchDebitAccountsFail(err))
      })
  }
}

export const fetchGetTokenPaymentSuccess = tokenPayment => {
  return {
    type: actionTypes.FETCH_GET_TOKEN_PAYMENT_SUCCESS,
    tokenPayment: tokenPayment,
  }
}

export const fetchGetTokenPaymentFail = error => {
  return {
    type: actionTypes.FETCH_GET_TOKEN_PAYMENT_FAIL,
    errorGetTokenPayment: error
  }
}

export const fetchGetTokenPaymentStart = () => {
  return {
    type: actionTypes.FETCH_GET_TOKEN_PAYMENT_START,
  }
}

export const fetchGetTokenPayment = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetTokenPaymentStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    api
      .get(getTokenPayMi)
      .then(res => {
        dispatch(fetchGetTokenPaymentSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetTokenPaymentFail(err))
      })
  }
}


export const fetchPaymentPayMiSuccess = tokenPayment => {
  return {
    type: actionTypes.FETCH_PAYMENT_PAYMI_SUCCESS,
    tokenPayment: tokenPayment,
  }
}

export const fetchPaymentPayMiFail = error => {
  return {
    type: actionTypes.FETCH_PAYMENT_PAYMI_FAIL,
    errorGetTokenPayment: error
  }
}

export const fetchPaymentPayMiStart = () => {
  return {
    type: actionTypes.FETCH_PAYMENT_PAYMI_START,
  }
}

export const fetchPaymentPayMi = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentPayMiStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    api
      .post(generatePaymentPayMi,body)
      .then(res => {
        dispatch(fetchPaymentPayMiSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchPaymentPayMiFail(err.response.data))
      })
  }
}

export const fetchDownloadSummarySuccess = summary => {
  console.log("succes",summary)
  return {
    type: actionTypes.FETCH_DOWNLOAD_SUMMARY_SUCCESS,
    summary: Object.values(summary.data.documents),
    errorDownloadSummary: summary.data.errors
  }
}

export const fetchDownloadSummaryFail = error => {
  return {
    type: actionTypes.FETCH_DOWNLOAD_SUMMARY_FAIL,
    errorDownloadSummary: error
  }
}

export const fetchDownloadSummaryStart = () => {
  return {
    type: actionTypes.FETCH_DOWNLOAD_SUMMARY_START,
  }
}

export const resetDownloadSummary = () => {
  return {
    type: actionTypes.RESET_DOWNLOAD_SUMMARY,
  }
}

export const fetchDownloadSummary = (year,month,cuit) => {
  return async (dispatch, getState) => {
    dispatch(fetchDownloadSummaryStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    await api
    .get(getDownloadsummary+`year=${year}&month=${month}&cuit=${cuit}`)
      .then(res => {
        dispatch(fetchDownloadSummarySuccess(res))
      })
      .catch(err => {
        dispatch(fetchDownloadSummaryFail(err.response.data))
      })
  }
}



export const saveTransactionAuth = transactions => {
  return {
    type: actionTypes.SAVE_TRANSACTION_AUTH,
    transactionAuth: transactions
  }
}



export const setPageTransactionList = page => {
  return {
    type: actionTypes.SET_PAGE,
    page: page
  }
}


export const setBankAccount = selectedBankAccount => {
  return {
    type: actionTypes.SET_BANK_ACCOUNT,
    selectedBankAccount: selectedBankAccount
  }
}

