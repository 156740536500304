export const base64Formatter = (base64Unformatted) => {
  const base64Formatted = base64Unformatted.replace(/^data:image\/[a-z]+;base64,/, "");
  return base64Formatted
}

export const base64FormatterAplication = (base64Unformatted) => {
  const base64Formatted = base64Unformatted.replace(/^data:application\/[a-z]+;base64,/, "");
  return base64Formatted
}

export const base64FormatterImage = (base64Unformatted) => {
  const base64Formatted = base64Unformatted.replace(`https://s3.us-east-2.amazonaws.com/miiiisa-app/feed/nwsrtclhdr/`, "");
  return base64Formatted
}
