import React, { Component } from 'react'
import Select from 'react-select';
import styled from 'styled-components'
import { Wrapper, Title, StyledButton } from "../styled"
import Input from '../../../../components/Input'
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import {
    fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,
    fetchPaymentMethods, fetchShippingMethods, fetchItemConditions, fetchGetCategories,
    fetchAddProduct, fetchEditProduct
} from '../../../../store/actions/clasificados.actions'

const GridContainer = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: minmax(220px, auto)
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    margin-bottom: 25px;
    
    @media (max-width: 650px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
`


const GridContainerImagenes = styled.div`  
    display: grid;
    width: 40%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    
    @media (max-width: 650px) {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const ErrorLabel = styled.div`  
    color: red;
`


class Paso1 extends Component {
    state = {
        step: {
            name: 'Creation',
            number: 1,
            percentage: 14,
        },
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }


    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit} key='0' style={{ width: '100%', marginTop: '4%' }}>
            <Wrapper>

                <GridContainer>
                    <Title>Categoria</Title>
                    <Select
                        name={'categoria'}
                        label={'categoria'}
                        autosize={false}
                        value={values.categoria}
                        onChange={value => setFieldValue('categoria', value)}
                        placeholder="Sel. Categoria"
                        options={this.props.clasificados.categorias ? this.props.clasificados.categorias.map(n => ({ label: n.name, value: n.id })) : { value: null, label: "Loading..." }}
                    />
                    {errors.categoria && <ErrorLabel>{errors.categoria}</ErrorLabel>}
                </GridContainer>
                <GridContainer>
                    <Title>Titulo de la publicación</Title>
                    <Input
                        name={'titulo'}
                        label={'titulo'}
                        placeholder={'Título'}
                        value={values.titulo}
                        error={errors.titulo}
                        onChange={({ target: { value } }) => setFieldValue('titulo', value)}
                    />
                    {errors.titulo && <ErrorLabel>{errors.titulo}</ErrorLabel>}
                </GridContainer>
                <GridContainer>
                    <Title>Descripcion</Title>
                    <TextArea
                        name={'descripcion'}
                        placeholder={'Descripción'}
                        value={values.descripcion}
                        onChange={({ target: { value } }) => setFieldValue('descripcion', value)}
                        minHeight={'220'}
                        minWidth={'0'}
                        maxHeight={'220'}
                        minWidth={'0'}></TextArea>
                    {errors.descripcion && <ErrorLabel>{errors.descripcion}</ErrorLabel>}
                </GridContainer>
                <Button type={'submit'} >Continuar</Button>
            </Wrapper>
        </form>
    )


    openWebcamModal = () => {
        this.setState({
            cameraModalOpen: !this.state.cameraModalOpen,
        });
    }

    handleSubmit = (values) => {
        const { index, getFormData } = this.props;
        getFormData(values, index);
        this.props.handleContinuar();
    }

    getInitialValues = () => ({
        'titulo': this.props.dataSaved ? this.props.dataSaved.titulo : null,
        'descripcion': this.props.dataSaved ? this.props.dataSaved.descripcion : null,
        'categoria': this.props.dataSaved ? this.props.dataSaved.categoria : null,
    })

    getSignUpSchema = () =>
        Yup.object().shape({
            categoria: Yup.string()
                .required('Categoria es requerido'),
            titulo: Yup.string()
                .min(2, 'El Titulo debe tener un mínimo de 2(dos) caracteres')
                .required('Titulo es requerido'),
            descripcion: Yup.string()
                .min(2, 'La Descripcion debe tener un mínimo de 2(dos) caracteres')
                .required('Descripcion es requerido'),
        })


    componentDidMount() {
        this.props.fetchGetCategories();
    }

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={true}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
}

const mapStateToProps = ({
    clasificados,
    selectedGuest
}) => ({
    clasificados,
    selectedGuest
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchGetCategories }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Paso1)
)