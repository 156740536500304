import React, { Component } from 'react'
import {  Container, TitleContainer,  LabelContainer,Label,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,RadioBtnContainer,ErrorLabel} from './styled';
import CheckBox from '../../../../components/Checkbox'

const formatContent = content => content ? (content.length > 20 ? `${content.substring(0, 20)}...` : content) : null
export default class ThirdStep extends Component {
    state = {
        selectedOption: false
    }

    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
    this.props.confirmTransaction()
  };

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption:  !this.state.selectedOption
    })
  
  };  

    render() {
        return (
            <Container onSubmit={this.handleFormSubmit}>
                <TitleContainer>Confirmá la información ingresada</TitleContainer>
                
                <Label>Tu transferencia </Label>
                <DestinatarioDetailContainer>
                  <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Alias Destinatario</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{formatContent(this.props.transactionProgress.DestinationBankAccount.Alias)}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer> 
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem>CBU Destinatario </DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Cvbu}  </DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Importe a transferir </DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true}>{this.props.transactionProgress.Currency+' '}${this.props.transactionProgress.Amount}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Concepto</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.Concept}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Cuenta de origen</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.SourceBankAccount.HolderName}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem>Plazo de acreditación</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.ExecutionDate} </DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                   
                </DestinatarioDetailContainer>
                {/* HABILITAR CUANDO SE PUEDA MANDAR MAIL EN TRANSFERENCIA
                 <RadioBtnContainer>                
                  <LabelChecked checked={this.state.selectedOption === true} onClick={this.handleOptionChange}>
                    <CheckBox
                      type="checkbox"
                      name="checkbox"
                      value="option1"
                      checked={this.state.selectedOption === true}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                      Enviar un mail de notificación al destinatario
                  </LabelChecked>
                </RadioBtnContainer>
                 */}
                 <StyledButton type="submit" value="submit">Preparar Transferencia</StyledButton>
            </Container>
        )
    }

}
  