import React from 'react';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import mapCategoriesByRole from '../../../selectors/mapCategoriesByRole'
import mapLots from '../../../selectors/mapLots';
import mapReportTickets from '../../../selectors/mapReportTickets';
//import mapSpaces from '../../../selectors/mapSpaces';
import { fetchAllCategories, fetchTypesByCategory } from '../../../store/actions/tickets.actions';
import { fetchLotsByNeighbourhood } from '../../../store/actions/lotes.actions';
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
import { fetchReporteTickets } from '../../../store/actions/reportes.actions';
import {
  FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel
} from '../styled';

const lotesAll = [{ label: "Todos", value: "" }]


class Tickets extends React.Component {

  state = {
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,
    properties: [],
    categories: [],
    types: [],
    typesEnabled: false,
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    },

  }

  componentDidMount = () => {
    let formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      let neighbourhood = {
        value: item.name,
        label: item.name,
        guid: item.guid
      };
      return neighbourhood;
    });

    this.setState({
      neighbourhoods: formattedNeighbourhoods
    });
  }

  getInitialValues = () => ({
    property: '',
    category: '',
    type: '',
    startDate: new Date(),
    endDate: new Date()
  })


  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        //  property: Yup.string().required('Ingrese un lote'),
        category: Yup.object(),
        type: Yup.string(),
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha')
      })
    )


  onChangeCategory(setFieldValue, values) {
    setFieldValue('category', values);
    if (values.value == '') {
      setFieldValue('type', this.state.types[0]);
      this.setState({ typesEnabled: false });
    }
    else {
      this.props.fetchTypesByCategory(values.value);
      this.setState({ typesEnabled: true });
    }
  }

  refreshData = () => {
    this.props.fetchAllCategories()
    this.props.fetchLotsByNeighbourhood()
  }

  handleChange = neighbourhoodSelected => {
    this.props.setNeighbourhood(neighbourhoodSelected);
    this.setState({ neighbourhoodSelected: neighbourhoodSelected }, this.refreshData());
  };

  handleSubmit = data => {
    const fetchData = {
      property: data.property.label !== "Todos" ? data.property.label : "",
      category: data.category.label !== "Todas" ? data.category.label : "",
      type: data.type !== undefined ? data.type : "",
      startDate: data.startDate,
      endDate: data.endDate
    }
    this.props.fetchReporteTickets(fetchData)
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <Row>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Barrio'}</InputLabel>
            <Select
              value={this.state.neighbourhoodSelected}
              onChange={this.handleChange}
              options={this.state.neighbourhoods}
            />
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Lote'}</InputLabel>
            <Select
              name={'property'}
              value={values.property}
              isSearchable={false}
              onChange={values => setFieldValue('property', values)}
              error={errors.property}
              options={this.props.properties.length > 0 ? this.props.properties : lotesAll}
              placeholder="Seleccione un lote..."
              isDisabled={this.props.lotes.loadingLots}
              isLoading={this.props.lotes.loadingLots}
              autoFocus
            />
            {errors.property && <ErrorLabel>{errors.property}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Categoría'}</InputLabel>
            <Select
              name={'category'}
              value={values.category}
              isSearchable={false}
              onChange={values => this.onChangeCategory(setFieldValue, values)}
              error={errors.category}
              options={this.props.categories}
              placeholder="Seleccione una categoría..."
              isDisabled={this.props.tickets.loadingCategories}
              isLoading={this.props.tickets.loadingCategories}
              autoFocus
            />
            {errors.category && <ErrorLabel>{errors.category}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Tipo'}</InputLabel>
            <Select
              name={'type'}
              value={values.type}
              isSearchable={false}
              onChange={values => setFieldValue('type', values)}
              error={errors.type}
              options={this.state.types}
              placeholder="Seleccione un tipo..."
              isDisabled={this.props.loadingTypes || !this.state.typesEnabled}
              isLoading={this.props.loadingTypes}
              autoFocus
            />
            {errors.type && <ErrorLabel>{errors.type}</ErrorLabel>}
          </InputWrapper>
        </Row>
        <Row align="flex-end">
          <InputWrapper margin='20px 15px'>
            <InputLabel>{'Desde'}</InputLabel>
            <DatePickerStyled
              selected={values.startDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsStart
              dateFormat="dd/MM/yyy"
              onChange={startDate => setFieldValue('startDate', startDate)}
              // minDate={new Date()}
              // maxDate={addMonths(new Date(), 12)}
              placeholderText="Fecha desde"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Hasta'}</InputLabel>
            <DatePickerStyled
              selected={values.endDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsEnd
              dateFormat="dd/MM/yyy"
              onChange={startDate => setFieldValue('endDate', startDate)}
              minDate={values.startDate || null}
              placeholderText="Fecha hasta"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
            {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputWrapper margin={'20px 15px'}>
              <Button type={'submit'} medium width={'100%'}>
                {'Filtrar'}
              </Button>
            </InputWrapper>
          </InputWrapper>
        </Row>
      </FormContainer>

    </>
  );

  componentDidMount() {
    this.refreshData()
  }

  render() {
    //console.log('propsreservas.', this.props)

    return (
      <>
        <SectionHeader title='Tickets' />
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
        {this.props.reportes.loadingReporteTickets ? <Loader small /> :

          <RitzTable
            columns={[
              { title: 'Título', field: 'title' },
              { title: 'Lote', field: 'lot' },
              { title: 'Categoría', field: 'categoryname' },
              { title: 'Tipo', field: 'typedescription' },
              { title: 'Creado por', field: 'createdbyname' },
              { title: 'DNI creador', field: 'createdbydni' },
              { title: 'Asignado a', field: 'assignedto' },
              { title: 'Abierto en', field: 'openingdate' },
              { title: 'Cerrado en', field: 'closingdate' },
              { title: 'Estado', field: 'statedescription', },
              { title: 'Comentario', field: 'closingcomment', },
              // { title: 'Activo', field: 'activedescription', }
            ]}
            data={this.props.ticketListMapped}
          />
        }
      </>
    );
  }
}


const mapStateToProps = state => ({
  userInfo: { neighbourhoods, selectedNeighbourhood, username },
  tickets,
  lotes,
  reportes
}) => {
  return {
    reportes,
    tickets,
    lotes,
    types: mapCategoriesByRole(tickets.typesList),
    categories: mapCategoriesByRole(tickets.allCategoriesList).concat({ label: "Todas", value: "" }).reverse(),
    properties: mapLots(lotes.lotsList).concat({ label: "Todos", value: "" }).reverse(),
    ticketListMapped: mapReportTickets(reportes.tickets),
    neighbourhoods,
    selectedNeighbourhood,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllCategories, fetchTypesByCategory, fetchLotsByNeighbourhood, fetchReporteTickets, setNeighbourhood,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
