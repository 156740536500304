import * as actionTypes from './actionTypes'

import {
  fetchProductsAPI, fetchDetailProductAPI, fetchDeleteProductAPI, fetchAddProductAPI,
  fetchPaymentMethodsAPI, fetchShippingMethodsAPI, fetchItemConditionsAPI, fetchCategoriesAPI,
  fetchDespachosAPI, fetchEditProductAPI, fetchGetReadyToDeliverAPI,
  fetchConversationsAPI, sendMsgConversationAPI, getMsgConversationAPI, newConversationAPI,
  fetchVentasAPI, fetchOrderQRAPI, fetchUploadProductsFileAPI, disclaimerMetodoPago, fetchDetailVentaAPI
} from '../../apiConstants'

import getApi from '../../shared/api'

import { createActions } from 'redux-actions'



// GET ALL
export const fetchGetProducts = UserName => {
  return async (dispatch, getState) => {
    dispatch(fetchGetProductsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    const request = {
      UserName: UserName ? UserName : '',
      OrderBy: "creationdate",
      OrderDescending: true,
      pageSize: 0,
      pageNumber: 0
    }

    return api
      .post(fetchProductsAPI, request)
      .then(response => {
        dispatch(fetchGetProductsSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetProductsFail(err))
      })
  }
}

export const fetchGetProductsSuccess = products => {
  return {
    type: actionTypes.FETCH_GET_PRODUCTS_SUCCESS,
    products: products.values
  }
}

export const fetchGetProductsFail = error => {
  return {
    type: actionTypes.FETCH_GET_PRODUCTS_FAIL,
    error: error
  }
}

export const fetchGetProductsStart = () => {
  return {
    type: actionTypes.FETCH_GET_PRODUCTS_START
  }
}

export const fetchDetailProduct = id => {
  return async (dispatch, getState) => {
    dispatch(fetchDetailProductStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    let url = fetchDetailProductAPI + id;

    return api
      .get(url)
      .then(response => {
        dispatch(fetchDetailProductSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchDetailProductFail(err))
      })
  }
}

export const fetchDetailProductSuccess = detail => {
  return {
    type: actionTypes.FETCH_DETAIL_PRODUCT_SUCCESS,
    productDetail: detail
  }
}

export const fetchDetailProductFail = error => {
  return {
    type: actionTypes.FETCH_DETAIL_PRODUCT_FAIL,
    error: error
  }
}

export const fetchDetailProductStart = () => {
  return {
    type: actionTypes.FETCH_DETAIL_PRODUCT_START
  }
}


// Delete Product

export const fetchDeleteProduct = (idProduct, reason) => {
  return async (dispatch, getState) => {
    dispatch(fetchDeleteProductStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    const request = {
      shopitemid: idProduct,
      inactivereasonid: reason
    }

    return api
      .post(fetchDeleteProductAPI, request)
      .then(res => {
        dispatch(fetchDeleteProductSuccess({ values: res.data }))
        dispatch(fetchGetProducts())
      })
      .catch(err => {
        dispatch(fetchDeleteProductFail(err))
      })
  }
}

export const fetchDeleteProductSuccess = detail => {
  return {
    type: actionTypes.FETCH_DELETE_PRODUCT_SUCCESS,
    productDelete: detail
  }
}

export const fetchDeleteProductFail = error => {
  return {
    type: actionTypes.FETCH_DELETE_PRODUCT_FAIL,
    error: error
  }
}

export const fetchDeleteProductStart = () => {
  return {
    type: actionTypes.FETCH_DELETE_PRODUCT_START
  }
}

export const fetchDeleteProductReset = () => {
  return {
    type: actionTypes.FETCH_DELETE_PRODUCT_RESET
  }
}





// ADD Product

export const fetchAddProduct = (data) => {
  return async (dispatch, getState) => {
    dispatch(fetchAddProductStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .post(fetchAddProductAPI, data)
      .then(res => {
        dispatch(fetchAddProductSuccess({ values: res.data }))
        dispatch(fetchGetProducts())
      })
      .catch(err => {
        dispatch(fetchAddProductFail(err))
      })
  }
}

export const fetchAddProductSuccess = detail => {
  return {
    type: actionTypes.FETCH_ADD_PRODUCT_SUCCESS,
    product: detail
  }
}

export const fetchAddProductFail = error => {
  return {
    type: actionTypes.FETCH_ADD_PRODUCT_FAIL,
    error: error
  }
}

export const fetchAddProductStart = () => {
  return {
    type: actionTypes.FETCH_ADD_PRODUCT_START
  }
}

export const fetchAddProductReset = () => {
  return {
    type: actionTypes.FETCH_ADD_PRODUCT_RESET
  }
}

// EDIT Product

export const fetchEditProduct = (data) => {
  return async (dispatch, getState) => {
    dispatch(fetchEditProductStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .put(fetchEditProductAPI, data)
      .then(res => {
        dispatch(fetchEditProductSuccess({ values: res.data }))
        dispatch(fetchGetProducts())
      })
      .catch(err => {
        dispatch(fetchEditProductFail(err))
      })
  }
}

export const fetchEditProductSuccess = detail => {
  return {
    type: actionTypes.FETCH_EDIT_PRODUCT_SUCCESS,
    product: detail
  }
}

export const fetchEditProductFail = error => {
  return {
    type: actionTypes.FETCH_EDIT_PRODUCT_FAIL,
    error: error
  }
}

export const fetchEditProductStart = () => {
  return {
    type: actionTypes.FETCH_EDIT_PRODUCT_START
  }
}

export const fetchEditProductReset = () => {
  return {
    type: actionTypes.FETCH_EDIT_PRODUCT_RESET
  }
}




// GET CATEGORIAS

export const fetchGetCategories = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetCategoriesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchCategoriesAPI)
      .then(response => {
        dispatch(fetchGetCategoriesSuccess({ values: response.data }))
      })
      .catch(err => {
        dispatch(fetchGetCategoriesFail(err))
      })
  }
}

export const fetchGetCategoriesSuccess = categorias => {
  return {
    type: actionTypes.FETCH_GET_CATEGORIES_SUCCESS,
    categorias: categorias
  }
}

export const fetchGetCategoriesFail = error => {
  return {
    type: actionTypes.FETCH_GET_CATEGORIES_FAIL,
    error: error
  }
}

export const fetchGetCategoriesStart = () => {
  return {
    type: actionTypes.FETCH_GET_CATEGORIES_START
  }
}



// GET fetchShippingMethodsAPI
export const fetchGetShippingMethods = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetShippingMethodsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchShippingMethodsAPI)
      .then(response => {
        dispatch(fetchGetShippingMethodsSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetShippingMethodsFail(err))
      })
  }
}

export const fetchGetShippingMethodsSuccess = shipping => {
  return {
    type: actionTypes.FETCH_GET_SHIPPING_SUCCESS,
    shipping: shipping
  }
}

export const fetchGetShippingMethodsFail = error => {
  return {
    type: actionTypes.FETCH_GET_SHIPPING_FAIL,
    error: error
  }
}

export const fetchGetShippingMethodsStart = () => {
  return {
    type: actionTypes.FETCH_GET_SHIPPING_START
  }
}

// GET fetchitemcondition\
export const fetchItemConditions = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetItemConditionsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchItemConditionsAPI)
      .then(response => {
        dispatch(fetchGetItemConditionsSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetItemConditionsFail(err))
      })
  }
}

export const fetchGetItemConditionsSuccess = itemConditions => {
  return {
    type: actionTypes.FETCH_GET_CONDITIONS_SUCCESS,
    itemConditions: itemConditions
  }
}

export const fetchGetItemConditionsFail = error => {
  return {
    type: actionTypes.FETCH_GET_CONDITIONS_FAIL,
    error: error
  }
}

export const fetchGetItemConditionsStart = () => {
  return {
    type: actionTypes.FETCH_GET_CONDITIONS_START
  }
}


// GET fetchPAYMENT
export const fetchGetPayment = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetPaymentStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchPaymentMethodsAPI)
      .then(response => {
        dispatch(fetchGetPaymentSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetPaymentFail(err))
      })
  }
}

export const fetchGetPaymentSuccess = payment => {
  return {
    type: actionTypes.FETCH_GET_PAYMENT_SUCCESS,
    payment: payment
  }
}

export const fetchGetPaymentFail = error => {
  return {
    type: actionTypes.FETCH_GET_PAYMENT_FAIL,
    error: error
  }
}

export const fetchGetPaymentStart = () => {
  return {
    type: actionTypes.FETCH_GET_PAYMENT_START
  }
}

// GET Despachos
export const fetchGetDespachos = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetDespachosStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchDespachosAPI)
      .then(response => {
        dispatch(fetchGetDespachosSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetDespachosFail(err))
      })
  }
}

export const fetchGetDespachosSuccess = despachos => {
  return {
    type: actionTypes.FETCH_DESPACHOS_SUCCESS,
    despachos: despachos.values
  }
}

export const fetchGetDespachosFail = error => {
  return {
    type: actionTypes.FETCH_DESPACHOS_FAIL,
    error: error
  }
}

export const fetchGetDespachosStart = () => {
  return {
    type: actionTypes.FETCH_DESPACHOS_START
  }
}



// Mensajeria SHOPS
// GET
export const fetchConversationsSuccess = data => {
  return { type: actionTypes.FETCH_MSG_SUCCESS, data: data }
}

export const fetchConversationsFail = error => {
  return { type: actionTypes.FETCH_MSG_FAIL, error: error }
}

export const fetchConversationsStart = () => {
  return { type: actionTypes.FETCH_MSG_START }
}

export const fetchConversations = () => {
  return async (dispatch, getState) => {
    dispatch(fetchConversationsStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid, true)
    try {
      const response = await api.get(`${fetchConversationsAPI}`)
      dispatch(fetchConversationsSuccess(response.data))
    } catch (error) {
      dispatch(fetchConversationsFail(error))
    }
  }
}

// Start Conversation
// POST 
export const newConversationSuccess = data => {
  return { type: actionTypes.NEW_CONVER_SUCCESS, data: data }
}

export const newConversationFail = error => {
  return { type: actionTypes.NEW_CONVER_FAIL, error: error }
}

export const newConversationStart = () => {
  return { type: actionTypes.NEW_CONVER_START }
}

export const newConversationReset = () => {
  return {
    type: actionTypes.NEW_CONVER_RESET
  }
}

export const newConversation = (data) => {
  return async (dispatch, getState) => {
    dispatch(newConversationStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid, true)
    return api
      .post(newConversationAPI, data)
      .then(res => {
        dispatch(newConversationSuccess(res.data))
      })
      .catch(err => {
        dispatch(newConversationFail(err))
      })
  }
}


// SEND MSG Conversation
// POST 

export const sendMsgSuccess = data => {
  return { type: actionTypes.SEND_MSG_SUCCESS, data: data }
}

export const sendMsgFail = error => {
  return { type: actionTypes.SEND_MSG_FAIL, error: error }
}

export const sendMsgStart = () => {
  return { type: actionTypes.SEND_MSG_START }
}

export const sendMsg = (data) => {
  return async (dispatch, getState) => {
    dispatch(sendMsgStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid, true)
    return api
      .post(sendMsgConversationAPI, data)
      .then(res => {
        dispatch(sendMsgSuccess(res.data))
      })
      .catch(err => {
        dispatch(sendMsgFail(err))
      })
  }
}

// GET Ventas
export const fetchGetVentas = () => {
  return async (dispatch, getState) => {
    dispatch(fetchGetVentasStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    return api
      .get(fetchVentasAPI)
      .then(response => {
        dispatch(fetchGetVentasSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetVentasFail(err))
      })
  }
}

export const fetchGetVentasFail = error => {
  return {
    type: actionTypes.FETCH_VENTAS_FAIL,
    error: error
  }
}

export const fetchGetVentasStart = () => {
  return {
    type: actionTypes.FETCH_VENTAS_START
  }
}

export const fetchGetVentasSuccess = ventas => {
  return {
    type: actionTypes.FETCH_VENTAS_SUCCESS,
    ventas: ventas.values
  }
}



// Mensajeria SHOPS
// GET
export const getMsgSuccess = data => {
  return { type: actionTypes.GET_MSG_SUCCESS, data: data }
}

export const getMsgFail = error => {
  return { type: actionTypes.GET_MSG_FAIL, error: error }
}

export const getMsgStart = () => {
  return { type: actionTypes.GET_MSG_START }
}

export const getMsg = converId => {
  return async (dispatch, getState) => {
    dispatch(getMsgStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid, true)
    try {
      const response = await api.get(getMsgConversationAPI + converId)
      dispatch(getMsgSuccess(response.data))
    } catch (error) {
      dispatch(getMsgFail(error))
    }
  }
}


/// SELECTORS Y RESET
const { resetConversation, selectConversation } = createActions({
  RESET_CONVERSATION: () => { "" },
  SELECT_CONVERSATION: data => ({ data })
})
const resetSelectedConversation = () => dispatch => dispatch(resetConversation())
const setSelectedConversation = conversation => dispatch => dispatch(selectConversation({ conversation }))
export { setSelectedConversation, selectConversation, resetSelectedConversation, resetConversation }


// GET ready to deliver
export const fetchGetReadyToDeliver = (orderId) => {
  return async (dispatch, getState) => {
    dispatch(fetchGetReadyToDeliverStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)
    const url = fetchGetReadyToDeliverAPI + orderId
    return api
      .post(url)
      .then(response => {
        dispatch(fetchGetReadyToDeliverSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetReadyToDeliverFail(err.response.data.message))
      })
  }
}

export const fetchGetReadyToDeliverSuccess = orderReadyToDeliver => {
  return {
    type: actionTypes.FETCH_READY_TO_DELIVER_SUCCESS,
    orderReadyToDeliver: orderReadyToDeliver.values
  }
}

export const fetchGetReadyToDeliverFail = error => {

  return {
    type: actionTypes.FETCH_READY_TO_DELIVER_FAIL,
    error: error
  }
}

export const fetchGetReadyToDeliverStart = () => {
  return {
    type: actionTypes.FETCH_READY_TO_DELIVER_START
  }
}

export const fetchGetReadyToDeliverReset = () => {
  return {
    type: actionTypes.FETCH_READY_TO_DELIVER_RESET
  }
}


export const fetchOrderQR = (orderId) => {
  return async (dispatch, getState) => {
    dispatch(fetchOrderQRStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    const url = fetchOrderQRAPI + orderId
    return api
      .get(url)
      .then(res => {
        dispatch(fetchOrderQRSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchOrderQRFail(err))
      })
  }
}

export const fetchOrderQRSuccess = orderQr => {
  return {
    type: actionTypes.FETCH_GET_QR_ORDER_SUCCESS,
    orderQr: orderQr
  }
}

export const fetchOrderQRReset = error => {
  return {
    type: actionTypes.FETCH_GET_QR_ORDER_RESET,
    error: error
  }
}
export const fetchOrderQRFail = error => {

  return {
    type: actionTypes.FETCH_GET_QR_ORDER_FAIL,
    error: error
  }
}

export const fetchOrderQRStart = () => {
  return {
    type: actionTypes.FETCH_GET_QR_ORDER_START
  }
}

//--------------- Post File Upload ------------------------
export const fetchUploadProductsFileSuccess = data => {
  return {
    type: actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS,
    data: data
  }
}

export const fetchUploadProductsFileFail = error => {
  return {
    type: actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_FAIL,
    error: error
  }
}

export const fetchUploadProductsFileStart = () => {
  return {
    type: actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_START
  }
}

export const fetchUploadProductsFileReset = () => {
  return {
    type: actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_RESET
  }
}

export const fetchUploadProductsFile = (data) => {
  return async (dispatch, getState) => {
    dispatch(fetchUploadProductsFileStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    const body = {
      FileString: data
    }

    await api.post(fetchUploadProductsFileAPI, body).then((res) => {
      dispatch(fetchUploadProductsFileSuccess(res.data));
    })
      .catch(err => {
        dispatch(fetchUploadProductsFileFail(err.response.data.message));
      });
  }
}



//--------------- Fetch disclaimer metodo de pago ------------------------
export const fetchDisclaimerMetodoPagoSuccess = data => {
  return {
    type: actionTypes.DISCLAIMER_METODO_PAGO_SUCCESS,
    data: data
  }
}

export const fetchDisclaimerMetodoPagoFail = error => {
  return {
    type: actionTypes.DISCLAIMER_METODO_PAGO_FAIL,
    error: error
  }
}

export const fetchDisclaimerMetodoPagoStart = () => {
  return {
    type: actionTypes.DISCLAIMER_METODO_PAGO_START
  }
}

export const fetchDisclaimerMetodoPagoReset = () => {
  return {
    type: actionTypes.DISCLAIMER_METODO_PAGO_RESET
  }
}

export const fetchDisclaimerMetodoPago = (data) => {
  return async (dispatch, getState) => {
    dispatch(fetchDisclaimerMetodoPagoStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    const body = {
      FileString: data
    }

    try {
      const response = await api.get(disclaimerMetodoPago, body)

      dispatch(
        fetchDisclaimerMetodoPagoSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchDisclaimerMetodoPagoFail(error.response.data.message))
    }
  }
}