import styled from 'styled-components'
import fondo from '../../../../assets/images/tarjeta.svg'

export const CardContainer = styled.div`

  width: 100%;
  min-height: 190px;
  max-height: 600px;
  background-image: url(${fondo});
  /* margin: 3% 0px 0px 3%; */
  background-position: center;
  margin-top: 2%;
  border-radius: -15px;
  padding: 2% 2%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-size: cover;
  border-radius: 9px;
`
export const CardTitle = styled.div`
  width: 100%;
  min-height: 40px;
  max-height: 80px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
`
export const CardMoney = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 35px;
`
export const TitleCard = styled.div`
  min-height: 40px;
  max-height: 150px;
  color: white;
  font-size: 35px;  
  align-self: start;
`

export const SubtitleCard = styled.div`
  color: white;
  font-size: 15px;  
  align-self: start;
`
export const LabelCVU = styled.div`
  color: white;
  font-size: 15px;  
  align-self: start;
  margin-left: 2%;
`
export const StyledLabel = styled.div`
  font-weight: ${({ bold }) => bold ? 600 : 400 };
  color: white;
  font-size: 15px;  
  align-self: start;
`
export const ContainerLabel = styled.div`
  margin-top: 6%;
  display: flex;
`
export const ContainerErrorLabel = styled.div`
  margin-top: 17%;
  display: flex;
`