import React from 'react'

import { Container } from './styled'

class ModalReservas extends React.Component {
  render() {
    const { children, withOutPage, width, height, top, left,display } = this.props
    return (
      <Container 
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      top={top ? top : '0%'}
      left={left ? left : '0%'}
      display={display ? display : 'flex'}
      >{children}
      </Container>
    )
  }
}

export default ModalReservas
