import { createSelector } from 'reselect'
var moment = require('moment');

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapReportInvitation = createSelector(
  Invitation => Invitation,
  Invitation =>
  (Invitation.map(invitation => ({
    from: moment(invitation.from).format('DD/MM/YYYY'),
    to: moment(invitation.to).format('DD/MM/YYYY'),
    host_full_name: invitation.host_full_name,
    host_username: invitation.host_username,
    host_dni: invitation.host_dni,
    host_phone_number: invitation.host_phone_number,
    NeighbourhoodName: invitation.NeighbourhoodName,
    property: invitation.property,
    property_guid: invitation.property_guid,
    latitude: invitation.latitude,
    longitude: invitation.longitude,
    invitations: invitation.invitations,
    dni: invitation ? invitation.invitations.map((invitation, i) => (invitation.guest.dni_front_picture_url + ' ')) : "",
  })))
)
export default mapReportInvitation
