
import {
  FETCH_COMMUNITIES_FAIL,
  FETCH_COMMUNITIES_RESET,
  FETCH_COMMUNITIES_START,
  FETCH_COMMUNITIES_SUCCESS,
  CLEAR_COMMUNITIES_PAGE,
  UPDATE_COMMUNITIES_GRID,
  FETCH_COMMUNITY_FAIL,
  FETCH_COMMUNITY_RESET,
  FETCH_COMMUNITY_START,
  FETCH_COMMUNITY_SUCCESS,
  FETCH_COMMUNITY_STATES_FAIL,
  FETCH_COMMUNITY_STATES_RESET,
  FETCH_COMMUNITY_STATES_START,
  FETCH_COMMUNITY_STATES_SUCCESS,
  ACTIVATE_COMMUNITY_FAIL,
  ACTIVATE_COMMUNITY_RESET,
  ACTIVATE_COMMUNITY_SUCCESS,
  ACTIVATE_COMMUNITY_START
} from './actionTypes'
import getApi from '../../shared/api'
import {
  communitiesAPI,
  communityStatesAPI
} from '../../apiConstants'
import mapCommunities from "../../selectors/mapCommunities";

////////////////////// FETCH COMMUNITIES //////////////////////
export const fetchCommunitiesSuccess = data => {
  let communities = mapCommunities(data.communities);
  return {
    type: FETCH_COMMUNITIES_SUCCESS,
    communities: communities
  };

};

export const fetchCommunitiesFail = error => {
  return {
    type: FETCH_COMMUNITIES_FAIL,
    errorFetchCommunities: error
  };
};

export const fetchCommunitiesStart = () => {
  return {
    type: FETCH_COMMUNITIES_START
  };
};

export const fetchCommunitiesReset = () => {
  return {
    type: FETCH_COMMUNITIES_RESET
  };
};

export function updateCommunitiesGrid(pageNumber, pageSize, total, orderColumn, orderDirection, searchText) {
  return {
    type: UPDATE_COMMUNITIES_GRID,
    pageNumber,
    pageSize,
    total,
    orderColumn,
    orderDirection,
    searchText
  };
}

export const fetchCommunities = (pageNumber, pageSize, orderColumn, orderDirection, searchText, communityState) => {
  return async dispatch => {
    dispatch(fetchCommunitiesStart());

    const api = await getApi();
    return api
      .get(`${communitiesAPI}?orderFIeld=${orderColumn}&orderDirection=${orderDirection}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}&communityState=${communityState}`)
      .then(res => {
        dispatch(fetchCommunitiesSuccess(res.data));
        dispatch(updateCommunitiesGrid(pageNumber, pageSize, res.data.total, orderColumn, orderDirection, searchText))
      })
      .catch(err => {
        dispatch(fetchCommunitiesFail(err));
      });
  };
};

export const clearCommunitiesPage = () => {
  return {
    type: CLEAR_COMMUNITIES_PAGE
  };
};

////////////////////// FETCH COMMUNITY //////////////////////
export const fetchCommunitySuccess = data => {
  let community = mapCommunities(data.communities);
  return {
    type: FETCH_COMMUNITY_SUCCESS,
    communityDetail: community[0]
  };
};

export const fetchCommunityFail = error => {
  return {
    type: FETCH_COMMUNITY_FAIL,
    errorFetchCommunity: error
  };
};

export const fetchCommunityStart = () => {
  return {
    type: FETCH_COMMUNITY_START
  };
};

export const fetchCommunityReset = () => {
  return {
    type: FETCH_COMMUNITY_RESET
  };
};



export const fetchCommunity = (communityId) => {
  return async dispatch => {
    dispatch(fetchCommunityStart());

    const api = await getApi();
    return api
      .get(`${communitiesAPI}/${communityId}`)
      .then(res => {
        dispatch(fetchCommunitySuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchCommunityFail(err));
      });
  };
};


////////////////////// FETCH COMMUNITY STATES//////////////////////
export const fetchCommunityStatesSuccess = communityStates => {
  console.log("COMMUNITY STATES", communityStates);
  return {
    type: FETCH_COMMUNITY_STATES_SUCCESS,
    communityStates: communityStates
  };
};

export const fetchCommunityStatesFail = error => {
  return {
    type: FETCH_COMMUNITY_STATES_FAIL,
    errorFetchCommunityStates: error
  };
};

export const fetchCommunityStatesStart = () => {
  return {
    type: FETCH_COMMUNITY_STATES_START
  };
};

export const fetchCommunityStatesReset = () => {
  return {
    type: FETCH_COMMUNITY_STATES_RESET
  };
};



export const fetchCommunityStates = () => {
  return async dispatch => {
    dispatch(fetchCommunityStatesStart());

    const api = await getApi();
    return api
      .get(`${communityStatesAPI}`)
      .then(res => {
        dispatch(fetchCommunityStatesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchCommunityStatesFail(err));
      });
  };
};


////////////////////// ACTIVATE COMMUNITY //////////////////////
export const activateCommunitySuccess = data => {
  return {
    type: ACTIVATE_COMMUNITY_SUCCESS,
  };
};

export const activateCommunityFail = error => {
  return {
    type: ACTIVATE_COMMUNITY_FAIL,
    errorActivateCommunity: error
  };
};

export const activateCommunityStart = () => {
  return {
    type: ACTIVATE_COMMUNITY_START
  };
};

export const activateCommunityReset = () => {
  return {
    type: ACTIVATE_COMMUNITY_RESET
  };
};



export const activateCommunity = (communityId, active) => {
  return async dispatch => {
    dispatch(activateCommunityStart());

    const api = await getApi();
    return api
      .put(`${communitiesAPI}/${communityId}/activate`,
        `${active}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      )
      .then(res => {
        dispatch(activateCommunitySuccess(res.data));
      })
      .catch(err => {
        dispatch(activateCommunityFail(err));
      });
  };
};

