import * as actionTypes from './actionTypes'
import {
  getEventListAPI,
  getNewsListAPI,
  postNewEventAPI,
  postNewArticleAPI,
  preSubmitFilesAPI,
  deleteArticleAPI,
  deleteEventAPI
} from '../../apiConstants'
import getApi from '../../shared/api'
import { file } from '@babel/types'

export const fetchEventosSuccess = event => {
  return {
    type: actionTypes.FETCH_EVENTS_SUCCESS,
    event: event
  }
}

export const fetchEventosFail = error => {
  return {
    type: actionTypes.FETCH_EVENTS_FAIL,
    error: error
  }
}

export const fetchEventosStart = () => {
  return {
    type: actionTypes.FETCH_EVENTS_START
  }
}

export const fetchFeedEvent = reset => {
  return async (dispatch, getState) => {
    dispatch(fetchEventosStart())
    const {
      feed,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    let eventTake = reset ? 10 : feed.eventTake
    const page = feed.page

    try {
      const response = await api.get(`${getEventListAPI}${page}/${eventTake}`)
      let hasMoreUpdated = response.data.length === eventTake
      let takeUpdated = hasMoreUpdated ? eventTake + 10 : eventTake
      dispatch(
        fetchEventosSuccess({
          values: response.data,
          eventTake: takeUpdated,
          hasMoreEvents: hasMoreUpdated
        })
      )
    } catch (error) {
      dispatch(fetchEventosFail(error))
    }
  }
}

export const fetchNoticiasSuccess = articles => {
  return {
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
    articles: articles
  }
}

export const fetchNoticiasFail = error => {
  return {
    type: actionTypes.FETCH_ARTICLES_FAIL,
    error: error
  }
}

export const fetchNoticiasStart = () => {
  return {
    type: actionTypes.FETCH_ARTICLES_START
  }
}

export const fetchFeedNews = reset => {
  return async (dispatch, getState) => {
    dispatch(fetchNoticiasStart())
    const {
      feed,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    let articlesTake = reset ? 10 : feed.articlesTake
    const page = feed.page
    try {
      const response = await api.get(`${getNewsListAPI}${page}/${articlesTake}`)
      let hasMoreUpdated = response.data.length === articlesTake
      let takeUpdated = hasMoreUpdated ? articlesTake + 10 : articlesTake

      dispatch(
        fetchNoticiasSuccess({
          values: response.data,
          articlesTake: takeUpdated,
          hasMoreArticles: hasMoreUpdated
        })
      )
    } catch (error) {
      dispatch(fetchNoticiasFail(error))
    }
  }
}

export const createNewEventSuccess = (newEvent, isEdit) => {
  return {
    type: actionTypes.CREATE_NEW_EVENT_SUCCESS,
    newEventId: newEvent.id,
    newEventData: newEvent,
    isEdit: isEdit
  }
}

export const createNewEventFail = error => {
  return {
    type: actionTypes.CREATE_NEW_EVENT_FAIL,
    error: error
  }
}

export const createNewEventStart = () => {
  return {
    type: actionTypes.CREATE_NEW_EVENT_START
  }
}

export const createNewEvent = (files, body, isEdit, id) => {
  return async (dispatch, getState) => {
    dispatch(createNewEventStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    let data = [];
    let preData = files?.map((preItem) => preItem?.data?.map(item => {
      console.log(item);
      data.push(item)
    }));

    let headerResponse = data?.filter(item => item.type === 0)
    let imagesResponse = data?.filter(item => item.type === 1)
    let videosResponse = data?.filter(item => item.type === 2)
    let documentResponse = data?.filter(item => item.type === 3)

    let formatBody = {
      headerimageurl: headerResponse?.length != 0 ? Object(headerResponse[0]).url : (body.headerimageurl != null ? body.headerimageurl : ""),
      images: Array(4).fill(undefined),
      video: videosResponse?.length != 0 ? Object(videosResponse[0]).url : (body.video != null ? body.video : ""),
      document: documentResponse?.length != 0 ? Object(documentResponse[0]).url : (body.document != null ? body.document : "")
    }
    if (isEdit) {
      body.images.map((item) => formatBody.images[item?.i] = item?.image)
      imagesResponse.map((item, index) => {
        if (formatBody.images[0] == undefined) {
          formatBody.images[0] = item.url;
        } else if (formatBody.images[1] == undefined) {
          formatBody.images[1] = item.url;
        } else if (formatBody.images[2] == undefined) {
          formatBody.images[2] = item.url;
        } else {
          formatBody.images[3] = item.url;
        }
      })
    } else {
      formatBody.images = imagesResponse.map(item => item.url);
    }
    const finalImage = formatBody.images.filter(item => item != undefined)
    formatBody.images = finalImage;

    let bodyFinish = Object.assign(body, formatBody);

    if (Object.keys(bodyFinish.video).length === 0) {
      delete bodyFinish.video;
    }
    if (Object.keys(bodyFinish.headerimageurl).length === 0) {
      delete bodyFinish.headerimageurl;
    }
    if (Object.keys(bodyFinish.document).length === 0) {
      delete bodyFinish.document;
    }
    if (bodyFinish.headerimageurl) {
      bodyFinish.pictureurl = bodyFinish.headerimageurl;
      delete bodyFinish.headerimageurl;
    }
    if (isEdit) {
      bodyFinish.id = id;
      return api
        .put(postNewEventAPI, bodyFinish)
        .then(res => {
          dispatch(createNewEventSuccess(res.data, isEdit))
        })
        .catch(err => {
          dispatch(createNewEventFail(err))
        })
    } else {
      delete bodyFinish.id
      return api
        .post(postNewEventAPI, bodyFinish)
        .then(res => {
          dispatch(createNewEventSuccess(res.data, isEdit))
        })
        .catch(err => {
          dispatch(createNewEventFail(err))
        })
    }
  }
}

export const createNewArticleSuccess = (newArticle, isEdit) => {
  return {
    type: actionTypes.CREATE_NEW_ARTICLE_SUCCESS,
    newArticleId: newArticle.id,
    newArticleData: newArticle,
    isEdit: isEdit
  }
}

export const createNewArticleFail = error => {
  return {
    type: actionTypes.CREATE_NEW_ARTICLE_FAIL,
    error: error
  }
}

export const createNewArticleStart = () => {
  return {
    type: actionTypes.CREATE_NEW_ARTICLE_START
  }
}
export const createNewArticle = (files, body, isEdit, id) => {
  return async (dispatch, getState) => {
    dispatch(createNewArticleStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    let data = [];
    let preData = files?.map((preItem) => preItem?.data?.map(item => {
      console.log(item);
      data.push(item)
    }));

    let headerResponse = data?.filter(item => item.type === 0)
    let imagesResponse = data?.filter(item => item.type === 1)
    let videosResponse = data?.filter(item => item.type === 2)
    let documentResponse = data?.filter(item => item.type === 3)

    let formatBody = {
      headerimageurl: headerResponse?.length != 0 ? Object(headerResponse[0]).url : (body.headerimageurl != null ? body.headerimageurl : ""),
      images: Array(4).fill(undefined),
      video: videosResponse?.length != 0 ? Object(videosResponse[0]).url : (body.video != null ? body.video : ""),
      document: documentResponse?.length != 0 ? Object(documentResponse[0]).url : (body.document != null ? body.document : "")
    }
    if (isEdit) {
      body.images.map((item) => formatBody.images[item?.i] = item?.image)
      imagesResponse.map((item, index) => {
        if (formatBody.images[0] == undefined) {
          formatBody.images[0] = item.url;
        } else if (formatBody.images[1] == undefined) {
          formatBody.images[1] = item.url;
        } else if (formatBody.images[2] == undefined) {
          formatBody.images[2] = item.url;
        } else {
          formatBody.images[3] = item.url;
        }
      })
    } else {
      formatBody.images = imagesResponse.map(item => item.url);
    }
    const finalImage = formatBody.images.filter(item => item != undefined)
    formatBody.images = finalImage;

    let bodyFinish = Object.assign(body, formatBody);

    if (Object.keys(bodyFinish.video).length === 0) {
      delete bodyFinish.video;
    }
    if (Object.keys(bodyFinish.headerimageurl).length === 0) {
      delete bodyFinish.headerimageurl;
    }
    if (Object.keys(bodyFinish.document).length === 0) {
      delete bodyFinish.document;
    }
    if (isEdit) {
      bodyFinish.id = id;
      return api
        .put(postNewArticleAPI, bodyFinish)
        .then(res => {
          dispatch(createNewArticleSuccess(res.data, isEdit))
        })
        .catch(err => {
          dispatch(createNewArticleFail(err))
        })
    } else {
      delete bodyFinish.id
      return api
        .post(postNewArticleAPI, bodyFinish)
        .then(res => {
          dispatch(createNewArticleSuccess(res.data, isEdit))
        })
        .catch(err => {
          dispatch(createNewArticleFail(err))
        })
    }
  }
}

export const preSubmitFilesSuccess = files => {
  return {
    type: actionTypes.PRESUBMIT_FILES_SUCCESS,
    files: files.data
  }
}

export const preSubmitFilesFail = error => {
  return {
    type: actionTypes.PRESUBMIT_FILES_FAIL,
    error: error
  }
}

export const preSubmitFilesStart = () => {
  return {
    type: actionTypes.PRESUBMIT_FILES_START
  }
}

export const preSubmitFiles = (files, body, isEdit, typePublication) => {
  return async (dispatch, getState) => {
    dispatch(preSubmitFilesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    let promises;
    if (isEdit) {
      const preImages = files?.files?.filter(file => file?.i != undefined)
      body.images = preImages;
      const prePromises = files?.files?.map((item) => {
        if (item.imagebase64 != undefined) {
          return item;
        }
      })
      const prePromisesFiltred = prePromises.filter(item => item != undefined)
      promises = prePromisesFiltred.map(file => api.post(preSubmitFilesAPI, [file]))
    } else {
      const prePromisesFiltred = files?.files?.filter(item => item.extension != undefined)
      promises = files?.files?.map(file => api.post(preSubmitFilesAPI, [file]))
    }

    if (typePublication == "Event") {
      return Promise.all(promises)
        .then(res => {
          dispatch(preSubmitFilesSuccess(res),
            dispatch(createNewEvent(res, body, isEdit, (files.articleid ? files.articleid : null)))
          )
        })
        .catch(err => {
          dispatch(preSubmitFilesFail(err))
        })
    }
    else {
      return Promise.all(promises)
        .then(res => {
          dispatch(preSubmitFilesSuccess(res),
            dispatch(createNewArticle(res, body, isEdit, (files.articleid ? files.articleid : null)))
          )
        })
        .catch(err => {
          dispatch(preSubmitFilesFail(err))
        })
    }
  }
}

export const editEventSuccess = (event) => {
  return {
    type: actionTypes.EDIT_EVENT_SUCCESS,
    oldEventId: event.id,
    newEventData: event
  }
}

export const editEventFail = error => {
  return {
    type: actionTypes.EDIT_EVENT_FAIL,
    error: error
  }
}

export const editEventStart = () => {
  return {
    type: actionTypes.EDIT_EVENT_START
  }
}

export const editEvent = (evento) => {
  return async (dispatch, getState) => {
    dispatch(editEventStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api.put(`${postNewEventAPI}`, evento).then(res => {
      dispatch(editEventSuccess(res.data))
    })
      .catch(err => {
        dispatch(editEventFail(err))
      })
  }
}

export const editArticleSuccess = (article) => {
  return {
    type: actionTypes.EDIT_ARTICLE_SUCCESS,
    oldArticleId: article.id,
    newArticleData: article
  }
}

export const editArticleFail = error => {
  return {
    type: actionTypes.EDIT_ARTICLE_FAIL,
    error: error
  }
}

export const editArticleStart = () => {
  return {
    type: actionTypes.EDIT_ARTICLE_START
  }
}

export const editArticle = (noticia) => {
  return async (dispatch, getState) => {
    dispatch(editArticleStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api.put(`${postNewArticleAPI}`, noticia).then(res => {
      dispatch(editArticleSuccess(res.data))
    })
      .catch(err => {
        dispatch(editArticleFail(err))
      })
  }
}

export const deleteArticleSuccess = () => {
  return {
    type: actionTypes.DELETE_ARTICLE_SUCCESS
  }
}

export const deleteArticleFail = error => {
  return {
    type: actionTypes.DELETE_ARTICLE_FAIL,
    success: error.success,
    errorMessage: error.message
  }
}

export const deleteArticleStart = () => {
  return {
    type: actionTypes.DELETE_ARTICLE_START
  }
}


export const deleteArticleReset = () => {
  return {
    type: actionTypes.DELETE_ARTICLE_RESET
  }
}


export const deleteArticleById = id => {
  return async (dispatch, getState) => {
    dispatch(deleteArticleStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .delete(deleteArticleAPI + `${id}`)
      .then(res => {
        dispatch(deleteArticleSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteArticleFail(err.response.data))
      })
  }
}

export const deleteEventSuccess = () => {
  return {
    type: actionTypes.DELETE_EVENT_SUCCESS
  }
}

export const deleteEventFail = error => {
  return {
    type: actionTypes.DELETE_EVENT_FAIL,
    success: error.success,
    errorMessage: error.message
  }
}

export const deleteEventStart = () => {
  return {
    type: actionTypes.DELETE_EVENT_START
  }
}

export const deleteEventReset = () => {
  return {
    type: actionTypes.DELETE_EVENT_RESET
  }
}

export const deleteEventById = id => {
  return async (dispatch, getState) => {
    dispatch(deleteEventStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .delete(deleteEventAPI + `${id}`)
      .then(res => {
        dispatch(deleteEventSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteEventFail(err.response.data))
      })
  }
}
