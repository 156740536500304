import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'


const initialState = {

  rows: [],
  bcraPage: 0,
  bcraPageSize: 10,
  bcraTotal: 0,
  isLoading: false,
  bcraOrderColumn: 'execution_date',
  bcraOrderDirection: 'desc',
  bcraSearchText: '',
  processes: [],
  loadingFetchProcesses: false,
  errorFetchProcesses: null,
  fetchProcessesSuccess: false,
  process: [],
  loadingFetchProcess: false,
  errorFetchProcess: null,
  fetchProcessSuccess: false,
  errorFile: "",
  fileSuccess: false,
  uploadingFile: false,
  processError: ""
}

///////////////FETCH PROCESSES///////////////////////
const fetchProcessesSuccess = (state, action) => {
  return updateObject(state, {
    processes: action.processes,
    loadingFetchProcesses: false,
    fetchProcessesSuccess: true
  })
}

const fetchProcessesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchProcesses: false,
    errorFetchProcesses: action.error,
    fetchProcessesSuccess: false
  })
}

const fetchProcessesStart = (state) => {
  return updateObject(state, {
    loadingFetchProcesses: true,
    errorFetchProcesses: null,
    fetchProcessesSuccess: false,
    fileSuccess: false,
    errorFile: ""
  })
}

const updateBCRAGrid = (state, action) => {  
  return updateObject(state, {
    bcraPage: action.page,
    bcraPageSize: action.pageSize,
    bcraTotal: action.total,
    bcraOrderColumn:action.orderColumn,
    bcraOrderDirection:action.orderDirection,
    bcraSearchText:action.searchText
  })
}

////////////////////// FETCH PROCESS //////////////////////
const fetchProcessSuccess = (state, action) => {
  return updateObject(state, {
    process: action.process,
    loadingFetchProcess: false
  })
}

const fetchProcessErrorSuccess = (state, action) => {
  return updateObject(state, {
    processError: action.processError,
    loadingFetchProcess: false
  })
}

const fetchProcessFail = (state, action) => {
  return updateObject(state, {
    loadingFetchProcess: false,
    errorFetchProcess: action.error
  })
}

const fetchProcessStart = (state) => {
  return updateObject(state, {
    process: [],
    processError: "",
    loadingFetchProcess: true,
    errorFetchProcess: null
  })
}

////////////////////// FETCH PROCESS //////////////////////
const importFileSuccess = (state, action) => {
  return updateObject(state, {
    uploadingFile: false,
    fileSuccess: true,
    errorFile: ""
  })
}

const impotFileFail = (state, action) => {
  return updateObject(state, {
    uploadingFile: false,
    errorFile: action.error,
    fileSuccess: false
  })
}

const importFileStart = (state) => {
  return updateObject(state, {
    uploadingFile: true,
    errorFile: "",
    fileSuccess:false
  })
}

/////////////////////////////////////////////////

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROCESSES_START:
      return fetchProcessesStart(state, action)
    case actionTypes.FETCH_PROCESSES_FAIL:
      return fetchProcessesFail(state, action)
    case actionTypes.FETCH_PROCESSES_SUCCESS:
      return fetchProcessesSuccess(state, action)
    case actionTypes.PROCESSES_UPDATE_BCRA_GRID:
      return updateBCRAGrid(state, action)
    ////////////////////// FETCH PAYMENT //////////////////////
    case actionTypes.FETCH_PROCESS_START:
       return fetchProcessStart(state, action)
    case actionTypes.FETCH_PROCESS_FAIL:
       return fetchProcessFail(state, action)
    case actionTypes.FETCH_PROCESS_SUCCESS:
       return fetchProcessSuccess(state, action)
    case actionTypes.FETCH_PROCESS_ERROR_SUCCESS:
      return fetchProcessErrorSuccess(state, action)
        ////////////////////// IMPORT FILE //////////////////////
    case actionTypes.IMPORT_FILE_START:
      return importFileStart(state, action)
    case actionTypes.IMPORT_FILE_FAIL:
      return impotFileFail(state, action)
    case actionTypes.IMPORT_FILE_SUCCESS:
      return importFileSuccess(state, action)
    default:
      return state
  }
}

export default reducer