import styled from 'styled-components'; 
import mediaQueries from '../../../utils/media-queries'

export const MainContainer = styled.div`
    width:70%
    ${mediaQueries.laptopL` 
    width:100%
    `} 
`
export const TableWrapper = styled.div`
    position:relative;
    min-height:500px
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`
export const SelectWrapper = styled.div`
  width: 300px;
  font-weight: normal;
`

export const InputLabel = styled.label`
font-family: ${({ theme }) => theme.fonts.roboto};
color: ${({ theme }) => theme.colors.black};
font-weight:${({ fontWeight }) => fontWeight || 'normal'};
padding-bottom: 5px;
margin-right:1em;
display: inline;
`
export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: flex-start;
  align-items: center;
`