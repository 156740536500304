import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'

const initialState = {

  error: null,
  isFetching: false,
  isSubmitting: false,


  spacesList: [],
  loadingSpaces: false,

}

const fetchSpacesSuccess = (state, action) => {
  return updateObject(state, { spacesList: action.payload.data, loadingSpaces: false })
}

const fetchSpacesFail = state => {
  return updateObject(state, { loadingSpaces: false })
}

const fetchSpacesStart = state => {
  return updateObject(state, { loadingSpaces: true })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SPACES_SUCCESS:
      return fetchSpacesSuccess(state, action)
    case actionTypes.FETCH_SPACES_FAIL:
      return fetchSpacesFail(state, action)
    case actionTypes.FETCH_SPACES_START:
      return fetchSpacesStart(state, action)
    default:
      return state
  }
}

export default reducer
