
import {
  FETCH_PROCESSES_FAIL,
  FETCH_PROCESSES_START,
  FETCH_PROCESSES_SUCCESS,
  FETCH_PROCESS_FAIL,
  FETCH_PROCESS_START,
  FETCH_PROCESS_SUCCESS,
  FETCH_PROCESS_ERROR_SUCCESS,
  IMPORT_FILE_FAIL,
  IMPORT_FILE_START,
  IMPORT_FILE_SUCCESS,
  PROCESSES_UPDATE_BCRA_GRID
} from './actionTypes'
import getApi from '../../shared/api'
import { fetchProcessesAPI, fetchProcessAPI, fetchPresignedFileUrl } from '../../apiConstants'
import mapBCRAProcesses from '../../selectors/mapBCRAProcesses'
import mapCommunicationD from '../../selectors/mapCommunicationD'
import mapCommunicationDError from '../../selectors/mapCommunicationDError'
import { ContactSupportRounded } from '@material-ui/icons'

////////////////////// FETCH PROCESSES  //////////////////////
export const fetchProcessesSuccess = processes => {
  let data = mapBCRAProcesses(processes);
  return {
    type: FETCH_PROCESSES_SUCCESS,
    processes: data
  }
}

export const fetchProcessesFail = error => {
  return {
    type: FETCH_PROCESSES_FAIL,
    error: error
  }
}

export const fetchProcessesStart = () => {
  return {
    type: FETCH_PROCESSES_START
  }
}

export function updateBCRAGrid(page, pageSize, total, orderColumn, orderDirection, searchText, getNews) {
  return {
    type: PROCESSES_UPDATE_BCRA_GRID,
    page,
    pageSize,
    total,
    orderColumn,
    orderDirection,
    searchText
  };
}

export const fetchProcesses = (processTypeId, page, pageSize, orderColumn, orderDirection, searchText) => {
  return async dispatch => {
    dispatch(fetchProcessesStart())
    const api = await getApi()
    return api
      .get(`${fetchProcessesAPI}?processTypeId=${processTypeId}&pageNumber=${page}&pageSize=${pageSize}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&searchText=${searchText}`)
      .then(res => {
        dispatch(fetchProcessesSuccess(res.data.processes))
        dispatch(updateBCRAGrid(page, pageSize, res.data.total, orderColumn, orderDirection, searchText))
      })
      .catch(error => {
        dispatch(fetchProcessesFail(error))
      })
  }
}

////////////////////// FETCH PROCESS  //////////////////////
export const fetchProcessSuccess = process => {
  let data = mapCommunicationD(process);
  return {
    type: FETCH_PROCESS_SUCCESS,
    process: data
  }
}

export const fetchProcessErrorSuccess = process => {
  let data = mapCommunicationDError(process);
  return {
    type: FETCH_PROCESS_ERROR_SUCCESS,
    processError: data[0].processError
  }
}

export const fetchProcessFail = error => {
  return {
    type: FETCH_PROCESS_FAIL,
    error: error
  }
}

export const fetchProcessStart = () => {
  return {
    type: FETCH_PROCESS_START
  }
}

export const fetchProcess = (id) => {
  return async dispatch => {
    dispatch(fetchProcessStart())
    const api = await getApi()
    let url = fetchProcessAPI + '?processId=' + id;
    return api
      .get(url)
      .then(res => {
        dispatch(fetchProcessSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchProcessFail(error))
      })
  }
}

export const fetchProcessError = (id) => {
  return async dispatch => {
    dispatch(fetchProcessStart())
    const api = await getApi()
    let url = fetchProcessAPI + '?processId=' + id;
    return api
      .get(url)
      .then(res => {
        dispatch(fetchProcessErrorSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchProcessFail(error))
      })
  }
}


////////////////////// IMPORT FILE PROCESS  //////////////////////
export const importFileSuccess = process => {
  //  let data = mapBCRAProcesses(processes);
  let data = process;
  return {
    type: IMPORT_FILE_SUCCESS,
    process: data
  }
}

export const importFileFail = error => {
  return {
    type: IMPORT_FILE_FAIL,
    error: error
  }
}

export const importFileStart = () => {
  return {
    type: IMPORT_FILE_START
  }
}

export const importFile = data => {
  return async dispatch => {
    dispatch(importFileStart())
    const api = await getApi();
    const externalApi = await getApi();
    delete externalApi.defaults.headers.common.Authorization; 

    var fileName = data.file.fileName;
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(fileName)[1];
    return api.get(`${fetchPresignedFileUrl}/${ext}`).then(response => { 
      const url = response.data;
      return externalApi.put(url, data.file.file)
        .then(s3Response => {
          const body = {
            fileUrl: url.split('?')[0],
            file_extension: ext,
            file_name: fileName,
            process_type_id: 1
          } 
          return api.post(fetchProcessesAPI, body)
            .then(res => {
              dispatch(importFileSuccess())
            })
            .catch(error => {
              dispatch(importFileFail(error))
            }) ;
        })
        .catch(error => { 
          dispatch(importFileFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar importar el archivo."));
        });
    }).catch(error => {
      dispatch(importFileFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar importar el archivo."));
    }); 
  }
}