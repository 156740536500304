import React from 'react';
import styled from 'styled-components';

const StatusContainer = styled.div`
    border-radius: 20px;
    padding: 5px 10px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ gray }) => gray && `
    color: #9b9b9b;
    background-color: #f6f6f6;
    `}
    ${({ green }) => green && `
    color: #8ecd46;
    background-color: rgba(142, 205, 70, 0.1);
    `}
    ${({ red }) => red && `
    color: #fb2a2a;
    background-color: rgba(251, 42, 42, 0.1);
    `}
    ${({ yellow }) => yellow && `
    color: #f5a623;
    background-color: rgba(245, 166, 35, 0.1);
    `}
`

const StatusBadge = (props) => <StatusContainer {...props}>{props.children}</StatusContainer>

export default StatusBadge