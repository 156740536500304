/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import TextArea from "../../../../components/TextArea";
import Button from "../../../../components/Button";
import Spinner from "react-spinner-material";
import FileImageInput from "../../../../components/FileImageInput";
import StyledSelect from "../../../../components/StyledSelect";
import { fetchComboSpacesToBlock } from "../../../../store/actions/bookings.actions";
import StyledSwitch from "../../../../components/StyledSwitch";

import {
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper
} from "./styled";
import Loader from "../../../../components/Loader";

const mapStateToProps = state => {
  const { comboSpacesToBlock } = state.bookings;
  return { comboSpacesToBlock: comboSpacesToBlock };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchComboSpacesToBlock }, dispatch);

function DetailModal(props) {
  const {
    spaceDetail,
    isSubmitting,
    spacesToBlock,
    neighbourhoods,
    selectedNeighbourhood,
    activities,
    selectedActivity,
    isNew,
    comboSpacesToBlock
  } = props;

  useEffect(() => {
    if (selectedActivity != null && selectedActivity.value != null) {
      props.fetchComboSpacesToBlock(
        spaceDetail ? spaceDetail.id : null,
        selectedActivity ? selectedActivity.value : null
      );
    }
  }, []);

  const sharedClientsFormattedOptions = (sharedClients) => {
    return sharedClients.map(sharedClient => ({ label: sharedClient.name, value: sharedClient.neighbourhood_guid }))
  };

  const blockedSpacesFormattedOptions = (blockedSpaces) => {
    let formattedOptions = blockedSpaces.map(blockedSpace => ({ label: blockedSpace.name, value: blockedSpace.id }));
    return formattedOptions;
  };

  const getInitialValues = () => {
    return {
      id: spaceDetail ? spaceDetail.id : 0,
      is_blocked: spaceDetail ? spaceDetail.is_blocked : false,
      name: spaceDetail ? spaceDetail.name : "",
      blocked_spaces: spaceDetail ? blockedSpacesFormattedOptions(spaceDetail.blocked_spaces) : [],
      neighborhoods_to_provide: spaceDetail
        ? sharedClientsFormattedOptions(spaceDetail.shared_clients)
        : [],
      max_reservation_time: spaceDetail ? spaceDetail.max_reservation_time : "7",
      min_reservation_time: spaceDetail ? spaceDetail.min_reservation_time : "7",
      ignore_max_reservation: spaceDetail ? spaceDetail.ignore_max_reservation : false,
      activity:
        selectedActivity && selectedActivity.value && !selectedActivity.is_shared
          ? selectedActivity
          : spaceDetail
            ? activities.find(activity => activity.value == spaceDetail.activity_id && !activity.is_shared)
            : null,
      neighbourhood_guid: selectedNeighbourhood.value
    };
  };

  const getSignUpSchema = () => {
    const onlyNumberRegex = /^[0-9]*$/;
    return Yup.lazy(values =>
      Yup.object().shape({
        name: Yup.string().required("Campo Obligatorio"),
        max_reservation_time: Yup.string()
          .matches(onlyNumberRegex, "Cantidad de días invalida.")
          .test(
            "max_reservation_time-validation",
            "La cantidad minima de dias es de 0.",
            value => value >= 0
          )
          .test(
            "max_reservation_time-validation",
            "La cantidad maxima de dias es de 100.",
            value => value <= 100
          )
          .required("Campo obligatorio"),
          min_reservation_time:Yup.string()
          .matches(onlyNumberRegex, "Cantidad de días invalida.")
          .test(
            "min_reservation_time-validation",
            "La cantidad minima de dias es de 0.",
            value => value >= 0
          )
          .test(
            "min_reservation_time-validation",
            "La cantidad maxima de dias es de 100.",
            value => value <= 100
          )
          .required("Campo obligatorio"),
        activity: Yup.object()
          .shape({
            label: Yup.string().required("Campo obligatorio"),
            value: Yup.number().required("Campo obligatorio")
          })
          .nullable()
          .required("Campo obligatorio")
      })
    );
  };

  const fetchSpacesToBlock = async (setFieldValue, option) => {
    setFieldValue("activity", option);
    setFieldValue("blocked_spaces", []);
    await props.fetchComboSpacesToBlock(null, option.value);
  };

  const getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <FlexWrapper>
          <InputWrapper>
            <InputLabel>{"Nombre"}</InputLabel>
            <Input
              fullwidth={true}
              name={"nameInput"}
              label={"nameInput"}
              placeholder={"Ingrese un nombre..."}
              value={values.name}
              onChange={({ target: value }) => setFieldValue("name", value.value)}
              margin="normal"
            />
            {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
          </InputWrapper>
          {isNew ? (
            <InputWrapper>
              <InputLabel>{"Actividad"}</InputLabel>
              <StyledSelect
                value={values.activity}
                onChange={option => fetchSpacesToBlock(setFieldValue, option, values)}
                options={activities}
                placeholder="Seleccione una actividad"
              />
              {errors.activity && <ErrorLabel>{errors.activity}</ErrorLabel>}
            </InputWrapper>
          ) : null}
          <InputWrapper>
            <InputLabel>{"Seleccione el/los espacios a bloquear"}</InputLabel>
            <StyledSelect
              value={values.blocked_spaces}
              onChange={value => setFieldValue("blocked_spaces", value)}
              isDisabled={values.is_blocked}
              options={
                spacesToBlock
                  ? spacesToBlock.filter(space => space.value != values.id)
                  : comboSpacesToBlock
                    ? props.comboSpacesToBlock
                    : []
              }
              placeholder={values.is_blocked ? "El espacio se encuentra bloqueado por otro/s espacios":"Seleccione los espacios a bloquear"}
              isMulti
            />
            {errors.blocked_spaces && <ErrorLabel>{errors.blocked_spaces}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{"Seleccione el/los barrios a prestar el espacio"}</InputLabel>
            <StyledSelect
              value={values.neighborhoods_to_provide}
              onChange={value => setFieldValue("neighborhoods_to_provide", value)}
              options={neighbourhoods}
              placeholder="Seleccione los barrios a prestar el espacio"
              isMulti
            />
            {errors.neighborhoods_to_provide && (
              <ErrorLabel>{errors.neighborhoods_to_provide}</ErrorLabel>
            )}
          </InputWrapper>
          <InputWrapper>
          <InputLabel>{"Días mínimos de anticipación permitidos"}</InputLabel>
          <Input
             fullwidth={true}
             name={"minReservationTimeInput"}
             label={"minReservationTimeInput"}
             placeholder={"Ingrese la cantidad de días..."}
             value={values.min_reservation_time}
             error={errors.min_reservation_time}
             onChange={({ target: value }) => setFieldValue("min_reservation_time", value.value)}
             margin="normal"
          />
          {errors.min_reservation_time && <ErrorLabel>{errors.min_reservation_time}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{"Días máximos de anticipación permitidos"}</InputLabel>
            <Input
              fullwidth={true}
              name={"maxReservationTimeInput"}
              label={"maxReservationTimeInput"}
              placeholder={"Ingrese la cantidad de días..."}
              value={values.max_reservation_time}
              error={errors.max_reservation_time}
              onChange={({ target: value }) => setFieldValue("max_reservation_time", value.value)}
              margin="normal"
            />
            {errors.max_reservation_time && <ErrorLabel>{errors.max_reservation_time}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{"Ignorar bloqueo de reservas simultáneas"}</InputLabel>
            <StyledSwitch
              checked={values.ignore_max_reservation}
              onChange={() => setFieldValue("ignore_max_reservation", !values.ignore_max_reservation)}
            />
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <ButtonWrapper>
            <Button type={"submit"} loading={isSubmitting}>
              Actualizar
            </Button>
          </ButtonWrapper>
        </FlexWrapper>
      </Form>
    );
  };

  const form = () => {
    if (props.isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <Modal title={props.title} onClose={props.onClose} width={50} height={"min-height"} width={"min-width"}>
      {form()}
    </Modal>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailModal);
