import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { auth, setAuthRedirectPath,fetchConfigBrand } from '../../store/actions/auth.actions'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Label from '../../components/Label';
import {
  Container,
  FormContainer,
  ImageWrapper,
  StyledImg,
  StyledLabel,
  LogoImg,
  ButtonWrapper,
} from './styled';
import Button from '../../components/Button';
import LoginInput from '../../components/LoginInput';
import backgroundImage from '../../assets/images/backgroundLogin.svg';
import logo from '../../assets/images/logoMiiii.svg';
import NewTokenRequest from '../../components/AuthPopUp'
import ForgotPass from './forgot-password/';
import {FormattedMessage} from 'react-intl';


class Login extends Component {
  state={
    isRecoveryPass: false,
    redirect: false
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to='/' />
  //   }
  // }

  toggleAuth = (user,pass) => this.setState({ isAuthOpen: !this.state.isAuthOpen,user: user,pass: pass })

  confirmLogin(token){
    this.props.auth(this.state.formData.user, this.state.formData.password,token,this.props.session) 
  }
  
  handleSubmit = async formData => {
    this.setState({formData: formData})
    await this.props.auth(formData.user, formData.password)
    if(await this.props.error === false){
      this.toggleAuth(formData.user, formData.password,this.props.session) 
    } 
  }

  toggleRecovery = () => {
    this.setState({isRecoveryPass: !this.state.isRecoveryPass})
    
  }
  
  
  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
    {this.props.subdomain[1] === "miiii" ? (
      <LogoImg src={logo} />
    ) : (
      <LogoImg src={this.props.bucketUrl+"/assets/image/logo.png"} />
    )}
      <LoginInput
        name={'user'}
        placeholder={'Username'}
        helperText={errors.user}
        value={values.user}
        error={errors.user}
        onChange={({ target: value }) => setFieldValue('user', value.value.trim().replace(/\s+/g, ''))}
        variant='outlined'
      />
      <LoginInput
        name={'password'}
        placeholder={'Password'}
        type='password'
        helperText={errors.password}
        value={values.password}
        onChange={({ target: value }) => setFieldValue('password', value.value.trim().replace(/\s+/g, ''))}
        error={errors.password}
        variant='outlined'
        autocomplete='off'
      />
      <StyledLabel 
        onClick={()=> this.toggleRecovery()} 
      >
        Olvidaste tu contraseña?
      </StyledLabel>
      {this.props.errorMessage.length > 0 ? <Label medium variant="warning">{this.props.errorMessage}</Label> : <></>}
      {this.props.error && this.props.errorMessage === false ? <Label medium variant="warning">Error al comunicarse al servidor</Label> : <></>}
      <ButtonWrapper>
        <Button
          large
          width='100%'
          type={'submit'}
          loading={this.props.loading}
        >
           <FormattedMessage
            id = "auth.title"
            defaultMessage="ingresar"
          />
        </Button> 
        
      </ButtonWrapper>
    </FormContainer>
  )

  render() {   
    const SignUpSchema = Yup.object().shape({
      user: Yup.string().required('Usuario requerido'),
      password: Yup.string().required('Contraseña requerida')
    })
    console.log(this.state)


    if(this.state.isRecoveryPass === true){
      return( 
        <ForgotPass OnClickBack={() => this.toggleRecovery}/>     
      ) 
  }else{
    return(
      <Container>
        {/* {this.renderRedirect()} */}
        <ImageWrapper>          
          <StyledImg src={backgroundImage} />
          {this.state.isAuthOpen ? ( <NewTokenRequest onClose={this.toggleAuth} pass={this.state.pass} error={this.props.errorMessage} user={this.state.user} 
          chargeToken={(t)=> this.confirmLogin(t)} fetchReSendToken={false} />) :(
            <Formik
              initialValues={{
                user: '',
                password: ''
              }}
              validateOnChange={false}
              validationSchema={SignUpSchema}
              onSubmit={this.handleSubmit}
              render={e => this.getFormContent(e)}
            />
          )}
         
        </ImageWrapper>
      </Container>
    )
    }
  }
}

const mapStateToProps = state => {
  return {
    authReducer: state.auth,
    loading: state.auth.loading,
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    session: state.auth.session,
    bucketUrl: state.auth.bucketUrl,
    subdomain: state.auth.subdomain
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      auth,setAuthRedirectPath,fetchConfigBrand
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
