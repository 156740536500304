import SectionHeader from "../../../components/SectionHeader";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import RitzTable from "../../../components/RitzTable";
import Switch from "@material-ui/core/Switch";
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
import {
  fetchAlertMotives,
  activateAlertMotive,
  activateAlertMotiveReset,
  fetchAlertMotivesReset
} from "../../../store/actions/alerts.actions";
import Loader from "../../../components/Loader";
import {
  SelectWrapper,
  TableWrapper,
  LoaderWrapper,
  InputWrapper,
  InputLabel
} from "./styled";

const mapStateToProps = state => {
  const { alertMotives, loadingFetchAlertMotives } = state.alerts;

  const { neighbourhoods } = state.userInfo;

  return {
    isLoading: loadingFetchAlertMotives,
    alertMotives: alertMotives,
    selectedOption: null,
    neighbourhoods: neighbourhoods
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertMotives,
      fetchAlertMotivesReset,
      // setNeighbourhood,
      activateAlertMotive,
      activateAlertMotiveReset
    },
    dispatch
  );

function Habilitadas(props) {
  const { alertMotives, isLoading, neighbourhoods } = props;

  const [neighbourhoodDropValue, setNeighbourhoodDropValue] = useState({
    label: "Seleccione",
    value: "00000000-0000-0000-0000-000000000000"
  });

  //Switch
  const [] = React.useState({
    checkedA: true,
    checkedB: true
  });

  useEffect(() => {
      if (neighbourhoods.length === 1) {
      setNeighbourhoodDropValue({
        label: neighbourhoods[0].name,
        value: neighbourhoods[0].guid
      });
      props.fetchAlertMotives(neighbourhoods[0].guid);
    } else props.fetchAlertMotivesReset();
  }, []);

  var columns = [
    {
      title: "Motivo",
      field: "name",
      cellStyle: {
        width: "30%"
      }
    },
    {
      title: "Habilitado",
      field: "active",
      render: rowData => (
        <>
          <Switch
            trackcolor={{ true: " ${({ theme }) => theme.colors.midGray}", false: "grey" }}
            checked={rowData.active}
            onChange={() => enableAlertMotive(rowData)}
          />
        </>
      )
    }
  ];

  const getNeighbourhoods = () => {
    return props.neighbourhoods.map(n => ({ label: n.name, value: n.guid, guid: n.guid }));
  };

  const neighbourhoodsDrop = getNeighbourhoods();

  const onNeighbourhoodChange = data => {
    setNeighbourhoodDropValue(data);
    props.fetchAlertMotives(data.value);
  };

  const filterBySelectedNeighbourhood = () => {
    props.fetchAlertMotives(props.selectedNeighbourhood.guid);
  };

  const enableAlertMotive = async e => {
    let formattedData = {
      id: e.id,
      active: !e.active
    };
    await props.activateAlertMotive(formattedData);
    props.fetchAlertMotives(neighbourhoodDropValue.value);
  };

  const [filter, setShowFilter] = useState(false);

  const filterIfTrue = () => {
    props.fetchAlertMotives(neighbourhoodDropValue.value);
  };

  // const showFilter = (() => {
  //   if (neighbourhoodsDrop.length > 1) {
  //     return (
  //       <>
  //         <ContentWrapper>
  //           <Wrapper>
  //             <HeaderMovementsWrapper>
  //               <TitleWrapper>
  //                 <h2>Barrio</h2>
  //               </TitleWrapper>
  //               <SelectWrapper>
  //                 <Select
  //                   onChange={option => onNeighbourhoodChange(option)}
  //                   options={neighbourhoodsDrop}
  //                   placeholder="Seleccione un barrio"
  //                 />
  //               </SelectWrapper>
  //             </HeaderMovementsWrapper>
  //           </Wrapper>

  //           <Wrapper>
  //             <HeaderMovementsWrapper>
  //               <SearchButton onClick={filterBySelectedNeighbourhood}>Filtrar</SearchButton>
  //             </HeaderMovementsWrapper>
  //           </Wrapper>
  //         </ContentWrapper>
  //       </>
  //     );
  //   } else {
  //     if (!filter) {
  //       props.setNeighbourhood(neighbourhoodsDrop[0]);
  //       setShowFilter(true);
  //       filterIfTrue();
  //     }
  //   }
  // })();

  return (
    <>
      <SectionHeader
        title="Alertas habilitadas"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
      >
        {neighbourhoods.length > 1 ? (
          <InputWrapper>
            <InputLabel>{"Barrio"}</InputLabel>
            <SelectWrapper>
              <Select
                onChange={value => onNeighbourhoodChange(value)}
                options={neighbourhoodsDrop}
                placeholder="Seleccione un barrio"
              />
            </SelectWrapper>
          </InputWrapper>
        ) : null}
      </SectionHeader>
      {/* {showFilter} */}
      <TableWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}
        <RitzTable
          columns={columns}
          title=""
          disabled={isLoading}
          options={{
            toolbar: false,
            paging: false,
            serverSide: true,
            selection: false
          }}
          data={alertMotives}
        />
      </TableWrapper>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Habilitadas);
