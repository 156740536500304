import React, { useState } from "react";
import MaterialTable from "material-table";
import TablePagination from "@material-ui/core/TablePagination";
import styled from "styled-components";
import Button from "./Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import theme from "../config/theme";
const Table = styled.div`
  overflow-y: auto;
  .MuiCircularProgress-colorPrimary {
    color:${theme.colors.midGray};
  }
`;

function RitzTable(props) {
  let paging = false;
  if (props.options) {
    if (props.options.paging) {
      paging = props.options.paging;
    }
  }

  let serverSide = false;
  if (props.options) {
    if (props.options.serverSide) {
      serverSide = props.options.serverSide;
    }
  }

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#30735f"
      }
    }
  });

  const firstLoad = true;

  const pagination = componentsProps =>
    props.options.paging && props.options.serverSide ? (
      <TablePagination
        {...componentsProps}
        rowsPerPageOptions={props.options ? props.options.rowsPerPageOptions : [5, 10, 20, 30]}
        page={props.pagination.page}
        rowsPerPage={props.pagination.pageSize}
        count={props.pagination.totalRow}
        onChangePage={props.pagination.onChangePage}
      />
    ) : (
      <TablePagination
        {...componentsProps}
        rowsPerPageOptions={props.options ? props.options.rowsPerPageOptions : [5, 10, 20, 30]}
      />
    );

  return (
    <Table id={"Ritz-table"}>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          style={{ flex: "1" }}
          columns={props.columns}
          data={props.data}
          tableRef={props.tableRef}
          parentChildData={props.parentChildData}
          isLoading={props.isLoading}
          title=""
          options={{
            draggable: false,
            toolbar: props.options ? props.options.toolbar : false,
            search: props.options ? props.options.search : false,
            paging: paging,
            sorting: true,
            pageSize: props.pagination ? props.pagination.pageSize : 10,
            selection: props.options ? props.options.selection : false,
            emptyRowsWhenPaging: false,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            actionsCellStyle: props.options && props.options.actionsCellStyle ? props.options.actionsCellStyle : null,
            headerStyle:props.options && props.options.headerStyle ? props.options.headerStyle : {
              zIndex: 0,
              backgroundColor: "#f9f9fb",
              color: "#9b9b9b"
            },

            rowStyle:
              props.options && props.options.rowStyle
                ? props.options.rowStyle
                : {
                  backgroundColor: "#ffffff",
                  borderRadius: "6px",
                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 1px 0px"
                },
            exportButton: props.options ? props.options.exportButton : false,
            exportAllData: props.options ? props.options.exportAllData : false,
            exportFileName: props.options ? props.options.exportFileName : "",
            exportCsv: props.options && props.options.exportCsv ? props.options.exportCsv : null
          }}
          onSelectionChange={props.onSelectionChange}
          onChangeRowsPerPage={paging && serverSide ? props.onChangeRowsPerPage : null}
          onOrderChange={paging && serverSide ? props.onOrderChange : null}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}"
            },
            toolbar: {
              nRowsSelected: "{0} fila(s)",
              searchPlaceholder: "Buscar",
              exportTitle: "Exportar",
              exportAriaLabel: "Exportar",
              exportName:
                props.options && props.options.exportName
                  ? props.options.exportName
                  : "Exportar a CSV"
            },
            header: {
              actions: "Acciones"
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtros"
              }
            }
          }}
          components={{
            Pagination: componentsProps => pagination(componentsProps)
          }}
          actions={props.actions}
          onRowClick={props.onRowClick ? props.onRowClick : null}
          detailPanel={props.detailPanel ? props.detailPanel : null}
        />
      </MuiThemeProvider>
    </Table>
  );
}

export default RitzTable;
