import React from 'react'
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import { Container, ImgStyled, ImgContainer } from "./styled"
import '@brainhubeu/react-carousel/lib/style.css';

export default class Carrousel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log("imagenes", this.props)
    return (
      <Container width={this.props.width}>
        <Carousel slidesPerPage={1} centered dots>
          {this.props.images.map((imagen) => (
            <ImgContainer width={this.props.width}>
              <ImgStyled src={imagen} width={this.props.width}></ImgStyled>
            </ImgContainer>
          ))}
        </Carousel>
      </Container>
    );
  }
}