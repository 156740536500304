import { createSelector } from 'reselect'

const mapVehicleBrands = createSelector(
  vehicleList => vehicleList,
  vehicleList =>
    vehicleList.map(
      (e, i) => ({
        label: e.name,
        value: e.id,
      })
    )
)

export default mapVehicleBrands
