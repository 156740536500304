import styled from 'styled-components'
import mediaQueries from '../../../config/media-queries'

export const Container = styled.form`
  display: flex;
  width: 49%;
  height: 53%;
  position: absolute;
  top: 20%;
  right: 25%; 
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;  
  background-color: white;
  z-index: 99;
`

export const Wrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: normal;

  ${mediaQueries.tablet`
    width: 100%;
  `}
`


export const StyledImg = styled.img` 
  width: 100vw;
  position: fixed;
  top: -8%;
  ${mediaQueries.mobile`
    height: 95vh;
    width: auto;
    top: 0;
  `}
`
export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 30px;
  margin-top: 10%;
  margin-bottom: 5%;
  text-align: center;
`

export const Subtitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 18px;
  text-align: center;
`
export const ButtonStyled = styled.button`
  margin: 3%;
  width: 40%;
  cursor: pointer; 
  max-height: 60px;
  min-height: 40px;
  background-color: ${({ theme, invert }) => (invert ? theme.colors.white : theme.colors.midGray)};
  color: ${({ theme, invert }) => (invert ? theme.colors.midGray : theme.colors.white)};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 14px;
  border-radius: 10px;
  border: 0px;

`
export const ButtonBack = styled.div`
  margin: 3%;
  width: 50%;
  margin-left: 25%;
  margin-top: 4%;
  cursor: pointer; 
  display: flex;
  justify-content: center;
  max-height: 60px;
  min-height: 40px;
  background-color: ${({ theme, invert }) => (theme.colors.white)};
  color: ${({ theme, invert }) => (theme.colors.midGray)};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 14px;
  border-radius: 10px;
  border: 0px;

`
export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 18px;
  font-weight: 500;

`

export const WrapperButton = styled.div`
margin-top: 12%;
margin-bottom: 12%;
`

export const WrapperContent = styled.div`
margin-bottom: 5%;
width: 60%;
`


