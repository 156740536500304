/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import CloseIcon from "../../../../../../assets/icons/close.svg";
import { Formik } from "formik";
import TextArea from "../../../../../../components/TextArea";
import * as Yup from "yup";
import Input from "../../../../../../components/Input";
import Button from "../../../../../../components/Button";
import StyledInputMask from "../../../../../../components/StyledInputMask";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
  ScheduleContainer,
  FormContainer,
  ErrorLabel,
  Row,
  TitleModal,
  Close,
  FlexWrapper,
  InputWrapper,
  InputLabel,
  HeaderWrapper,
  GrayedArea
} from "./styled";

const mapStateToProps = state => {
  const { createBookingNeighbourhoodSuccess, errorCreateBookingNeighbourhood } = state.bookings;

  return { createBookingNeighbourhoodSuccess, errorCreateBookingNeighbourhood };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export const BookingModal = props => {
  const {
    selectedCalendarDateTime,
    spaceId,
    schedule_id,
    to,
    from,
    date,
    title,
    createBookingNeighbourhood,
    createBookingNeighbourhoodSuccess,
    errorCreateBookingNeighbourhood,
    onClose,
    leftPopUp,
    bottomPopUp,
    fromMultiplebooking,
    fetchScheduleBookings,
    fetchSchedulesCalendar
  } = props;

  useEffect(() => {
    if (createBookingNeighbourhoodSuccess) {
      props.onClose();
      if (!fromMultiplebooking) fetchSchedulesCalendar(spaceId, selectedCalendarDateTime);
    }
  }, [createBookingNeighbourhoodSuccess]);

  const getInitialValues = () => { 
    return {
      property_code: "",
      description: "",
      date: moment.utc(date).format("DD-MM-YYYY"),
      to: to,
      from: from
    };
  };

  const getSignUpSchema = () => {
    return Yup.object().shape({});
  };

  const handleOnSubmit = async data => {
    const formattedData = {
      schedule_id: schedule_id,
      from: from,
      to: to,
      date: date,
      description: data.description,
      property_code: data.property_code
    };
    await createBookingNeighbourhood(formattedData);
    if (fromMultiplebooking) {
      await fetchScheduleBookings(formattedData.schedule_id, formattedData.date);
    }
  };

  const getFormContent = ({ handleSubmit, values, setFieldValue, errors, myFormRef }) => {
    return (
      <FormContainer ref={el => (myFormRef = el)} onSubmit={handleSubmit}>
        <FlexWrapper>
          <Row>
            <InputWrapper width={"40"}>
              <InputLabel>El día</InputLabel>
              <Input
                value={values.date}
                disabled={true}
                minWidth={"50%"}
                width={"100%"}
                placeholder={"Ingrese el código"}
                onChange={({ target: { value } }) => setFieldValue("date", value)}
              ></Input>
            </InputWrapper>
            <InputWrapper width={"30"}>
              <InputLabel>Inicio</InputLabel>
              <StyledInputMask
                mask={"99:99"}
                value={values.from}
                width={"100%"}
                placeholder={"HH:MM"}
                maskChar={null}
                disabled={true}
              />
            </InputWrapper>
            <InputWrapper width={"30"}>
              <InputLabel>Fin</InputLabel>
              <StyledInputMask
                mask={"99:99"}
                value={values.to}
                width={"100%"}
                placeholder={"HH:MM"}
                maskChar={null}
                disabled={true}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel>Número de propiedad</InputLabel>
              <Input
                value={values.property_code}
                minWidth={"50%"}
                width={"100%"}
                placeholder={"Ingrese el código"}
                onChange={({ target: { value } }) => setFieldValue("property_code", value)}
              ></Input>
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Descripción</InputLabel>
              <TextArea
                height={"4.5em"}
                name={"descriptionInput"}
                width={"100%"}
                label={"descriptionInput"}
                placeholder={"Ingrese una descripción..."}
                value={values.description}
                onChange={({ target: { value } }) => setFieldValue("description", value)}
                margin="normal"
              ></TextArea>
              {errors.to && <ErrorLabel>{errors.description}</ErrorLabel>}
            </InputWrapper>
            <ErrorLabel>{errorCreateBookingNeighbourhood}</ErrorLabel>
          </Row>
          <Row alignItems={"end"}>
            <Button type={"submit"} width={"8.8em"}>
              Reservar
            </Button>
          </Row>
        </FlexWrapper>
      </FormContainer>
    );
  };

  const form = () => {
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={handleOnSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <GrayedArea>
      <ScheduleContainer leftPopUp={leftPopUp} bottomPopUp={bottomPopUp}>
        <HeaderWrapper>
          <TitleModal>{title}</TitleModal>
          <Close src={CloseIcon} onClick={onClose} />
        </HeaderWrapper>
        {form()}
      </ScheduleContainer>
    </GrayedArea>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
