import React from 'react'
import styled from 'styled-components'
import checkBox from "../assets/icons/checkBox.png"

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  &:hover{
    cursor:pointer;
  }
`

const ImgStyled = styled.img`
  fill: none;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  visibility: hidden !important;
`

const StyledCheckbox = styled.div`
    margin-left: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: ${props => (props.checked ? 'white' : 'white')};
    border-radius: 15px;
    border-color: ${({ theme }) => theme.colors.greenTransparent };;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 0 0.3px ${({ theme }) => theme.colors.greenTransparent };
    transition: all 150ms;
    padding: ${props => (props.checked ? '9%' : '0%')};

    ${HiddenCheckbox}:focus + & {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.greenTransparent };;
    }

    ${ImgStyled} {
      visibility: ${props => (props.checked ? 'visible' : 'hidden')}
    }
`

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <ImgStyled src={checkBox}/>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
