import styled from 'styled-components';
import theme from '../../../config/theme';
import { withStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Input from "../../../components/Input";
import mediaQueries from '../../../utils/media-queries'

// export const Row = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// `;
// export const LoaderWrapper = styled.div`
//   text-align: center;
//   margin: auto;
// ` ;

export const Ahref = styled.a`
  cursor: pointer;
  color: #30735f;
  text-decoration: underline;
`;

// export const TableWrapper = styled.div`
//   position: relative;
// `;

export const EstadoLabelError = styled.div`
  color: #FB2A2A;
  background-color: #FEE9E9;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

export const EstadoLabelCompletado = styled.div`
  color: ${({ theme }) => theme.colors.completeLabelText};
  background-color: ${({ theme }) => theme.colors.completeLabel};
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

export const EstadoLabelNueva = styled.div`
  color: ${({ theme }) => theme.colors.pendingLabelText};
  background-color: ${({ theme }) => theme.colors.pendingLabel};
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  text-align:center;
  padding:10px;
  border-radius:20px;
`;

export const EstadoLabelPendiente = styled.div`
  color: ${({ theme }) => theme.colors.dustyGray};
  background-color: ${({ theme }) => theme.colors.dustyGrayTransparent};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

export const CancelledState = styled.div`
  color: ${({ theme }) => theme.colors.cancelledStateLabel};
  background-color: ${({ theme }) => theme.colors.dustyGrayTransparent};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;
export const LabelSection = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomInput = styled(Input)`
  margin-right: 10px;
`;

export const LabelDefault = styled.div`
  color: ${({ theme }) => theme.colors.dustyGray};
  background-color: ${({ theme }) => theme.colors.dustyGrayTransparent};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
`;

export const Divider = styled.div`
  width: 10px;
  padding: 2px;
`;

export const SelectWrapper = styled.div`
  width: ${({ width }) => width || "70%"};
  font-weight: normal;
`;

export const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex-wrap: wrap;
  ${({ paddingBottom })=> paddingBottom?'padding-bottom:' + paddingBottom:''}
`;

export const LabelWrapper = styled.div`
  width: 100%;
  justify-content: space-around;
`;

export const FormContainer =  styled.div`
  width: 85%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-bottom: 0.3em; 
  ${mediaQueries.laptopL` 
    width:80%
  `}
`;

export const InputWrapper = styled.div`
  width: ${({ width }) => width || "350px"};
  // ${mediaQueries.laptopL` 
  //   width: 350px
  // `}
  display: flex;
  padding: 0.3em;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  align-items: center;
  margin: 0 !important;
  .react-datepicker-wrapper {
    width: 70%;
    .react-datepicker__input-container {
      width: 100%;
      input{
        width: 100%;
      }
    }
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  padding-top: 4em;
  padding-bottom: 4em;
`;

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-weight:${({ fontWeight }) => fontWeight || 'normal'};
  // padding-bottom: 5px;
  margin-right: 1em;
  display: inline;
`;

export const TableWrapper = styled.div`
  position: relative;
  min-height: 250px
`;

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#30735f',
      "&:hover": {
        background: 'rgba(31, 31, 31, 0.04)'
      },
    },
    "'&:hover':": {
      background: '#30735f'
    }
  },
  checked: {},
  })

export const ErrorsWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin: ${({ margin }) => margin || 0.5}em;
`;

export const ErrorLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  margin: 5px;
  width: calc(100% - 1em);
`;

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
