/* eslint-disable react/prop-types */
import React from 'react'
import CloseIcon from '../../../../../../assets/icons/close.svg';
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormControl, FormControlLabel, Radio, RadioGroup, Box } from '@material-ui/core';
import StyledSelect from '../../../../../../components/StyledSelect'
import TextArea from '../../../../../../components/TextArea'
import DatePickerStyled from '../../../../../../components/DatePickerStyled'
import theme from "../../../../../../config/theme"
import { withStyles } from '@material-ui/core/styles';
import {
    RepetitionContainer,
    FormContainer,
    CancelButton,
    Row, TitleModal,
    Column,
    SaveButton,
    ErrorLabel,
    InputWrapper,
    FlexWrapper,
    InputLabel,
    HeaderWrapper,
    DayButton,
    DaysOfWeekWrapper,
    ButtonsWrapper,
    GrayedArea,
    ErrorsWrapper
} from './styled'
import { Modal } from '@material-ui/core';


function RepetitionModal(props) {

    const {
        recurringType,
        finishSerie,
        scheduleDetail,
        days,
        endDate
    } = props;

    const StyledRadio = withStyles({
        root: {
            color: theme.colors.midGray,
            '&$checked': {
                color: theme.colors.midGray,
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const recurringTypesOptions = [
        { label: 'Dia', value: 1 },
        { label: 'Semana', value: 2 },
    ];

    const daysOfWeek = [
        { label: 'D', value: '0', flag: false },
        { label: 'L', value: '1', flag: false },
        { label: 'M', value: '2', flag: false },
        { label: 'X', value: '3', flag: false },
        { label: 'J', value: '4', flag: false },
        { label: 'V', value: '5', flag: false },
        { label: 'S', value: '6', flag: false }
    ];

    const onEndDateChange = (setFieldValue, value) => {
        setFieldValue('endDate', value);
    };

    const onRecurringTypeChange = (setFieldValue, value) => {
        setFieldValue('recurringType', value);
        if (value.value === 2)
            setFieldValue('days', daysOfWeek);
    };

    const onFinishSerieChange = (setFieldValue, value) => {
        setFieldValue('finishSerie', value);
        if (value === "never");
        setFieldValue('endDate', null);
    };

    const onDayOfWeekChange = (setFieldValue, days, item) => {

        days[item.value] = { label: item.label, value: item.value, flag: !item.flag }
        setFieldValue('days', days);
    };

    const getDaysOfWeek = (days) => {
        var activeDays = daysOfWeek;
        days.map(day => {
            return activeDays[day].flag = true
        })
        return activeDays;
    };

  const renderDaysOfWeek = (setFieldValue, days) => {
    return (
      <InputWrapper>
        <InputLabel>El día</InputLabel>
        <DaysOfWeekWrapper>
          {days.map((item, i) => <DayButton key={item.label} name={item.label} active={item.flag} type={'button'} onClick={() => onDayOfWeekChange(setFieldValue, days, item)}>{item.label}</DayButton>)}
        </DaysOfWeekWrapper>
      </InputWrapper>
    );
  };
  const getFormContent = ({ handleSubmit, values, setFieldValue, errors, myFormRef }) => {
    return (
      <FormContainer ref={el => myFormRef = el} onSubmit={handleSubmit}>
        <FlexWrapper width={'100%'}>
          <Row>
            <InputWrapper>
              <InputLabel>Repetir Cada </InputLabel>
              <StyledSelect
                value={values.recurringType}
                onChange={(value) => onRecurringTypeChange(setFieldValue, value)}
                options={recurringTypesOptions}
                placeholder="seleccione una frecuencia"
              />
            </InputWrapper>
          </Row>
          <Row>
            {values.recurringType && values.recurringType.value == 2
              ? renderDaysOfWeek(setFieldValue, values.days)
              : null}
          </Row>
          <Row justifyContent={"flex-start"}>
            <Column>
              <InputLabel>Termina</InputLabel>
              <FormControl component="fieldset">
                <RadioGroup aria-label="finishSerie" name="finishSerie1" style={{ marginLeft: '2%' }} value={values.finishSerie} onChange={({ target: { value } }) => onFinishSerieChange(setFieldValue, value)}>
                  <FormControlLabel
                    value="never"
                    control={<StyledRadio />}
                    label={
                      <Box component="div" fontSize={12}>
                        Nunca
                      </Box>
                    }
                  />
                  <FormControlLabel
                    style={{ marginBottom: "7px" }}
                    value="day"
                    control={<StyledRadio />}
                    label={
                      <Box component="div" fontSize={12}>
                        El día
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Column>
            <Column width={"50%"}>
              <DatePickerStyled
                selected={values.endDate}
                startDate={values.endDate}
                onChange={value => onEndDateChange(setFieldValue, value)}
                selectsStart
                dateFormat={"dd/MM/yyyy"}
                placeholderText={"Ingrese una fecha"}
                padding={"8px 1%"}
                popperPlacement="bottom"
                disabled={values.finishSerie && values.finishSerie === "never"}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                  }
                }}
              />
            </Column>
          </Row>
          {errors.endDate && (
            <Row>
              <ErrorsWrapper>
                {errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
              </ErrorsWrapper>
            </Row>
          )}
          <ButtonsWrapper>
            <CancelButton type="button" onClick={props.onClose}>
              Cancelar
            </CancelButton>
            <SaveButton type="submit">Aceptar</SaveButton>
          </ButtonsWrapper>
        </FlexWrapper>
      </FormContainer>
    );
  };

  const getInitialValues = () => {
    return {
      recurringType:
        !props.isModified && recurringType
          ? recurringTypesOptions[recurringType]
          : recurringType
          ? recurringType
          : recurringTypesOptions[0],
      endDate: endDate ? endDate : null,
      days:
        !props.isModified && props.daysOfWeek
          ? getDaysOfWeek(props.daysOfWeek)
          : props.daysOfWeek
          ? props.daysOfWeek
          : daysOfWeek,
      finishSerie: finishSerie ? finishSerie : "never"
    };
  };
  const getSignUpSchema = () => {
    function isValidEndDateValue(ref, msg) {
      return this.test({
        name: "isValidEndDateValue",
        exclusive: false,
        message: "La fecha de Fin es obligatoria",
        params: {
          reference: ref.path
        },
        test: function(value) {
          if (this.resolve(ref) === "day" && value === null) return false;
          return true;
        }
      });
    }
    Yup.addMethod(Yup.string, "isValidEndDateValue", isValidEndDateValue);

    return Yup.object().shape({
      endDate: Yup.string()
        .isValidEndDateValue(Yup.ref("finishSerie"))
        .nullable()
    });
  };

  const form = () => {
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };
  return (
    <GrayedArea>
      <RepetitionContainer leftPopUp={props.leftPopUp} bottomPopUp={props.bottomPopUp}>
        <HeaderWrapper>
          <TitleModal>{props.title}</TitleModal>
        </HeaderWrapper>
        {form()}
      </RepetitionContainer>
    </GrayedArea>
  );
}

export default RepetitionModal;
