import React from "react";
import styled, { ThemeProvider } from 'styled-components';
import { backgroundColor, textColor } from './config/theme';

const ThemeToggleContext = React.createContext({
  toggle: () => {}
});


export const useTheme = 
   () => React.useContext(ThemeToggleContext);

export const MyThemeProvider = ({ children }) => {

;

const [themeState, setThemeState] = React.useState({
    mode: 'light'
 });
// define toggle function
const toggle = () => { 
    const mode = (themeState.mode === 'light' ? `dark` : `light`);
    setThemeState({ mode: mode });
    console.log('toggle coming next',mode);
};

return (
 <ThemeToggleContext.Provider
   value={{ toggle: toggle }}
 >
   <ThemeProvider
     theme={{
       mode: "light"
     }}
   >
        {children}
   </ThemeProvider>
 </ThemeToggleContext.Provider>
);
};
export default MyThemeProvider;