/* eslint-disable react/prop-types */
import { Form, Formik, Field } from "formik";
import React from "react";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Loader from "../../../../components/Loader";
import StyledSwitch from "../../../../components/StyledSwitch";
import theme from "../../../../config/theme";
import {
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper
} from "./styled";

function ActivityModal(props) {
  const { isSubmitting, bookingActivity } = props;

  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        description: Yup.string().required("Campo obligatorio")
      })
    );

  const getInitialValues = () => {
    if (bookingActivity?.length > 0) {
      return {
        id: bookingActivity[0].id,
        description: bookingActivity[0].description,
        validationByProperty: bookingActivity[0].validation_by_property
      };
    } else {
      return {
        description: "",
        validationByProperty: false
      };
    }
  };

  const getFormContent = ({
    handleSubmit,
    values,
    setFieldValue,
    setValues,
    resetForm,
    errors
  }) => {
    return (
      <>
        <Form onSubmit={handleSubmit}>
          <FlexWrapper>
            <FlexWrapper>
              <InputWrapper>
                <InputLabel>{"Nombre"}</InputLabel>
                <Input
                  width={"100%"}
                  name={"descriptionInput"}
                  label={"descriptionInput"}
                  placeholder={"Ingrese un nombre..."}
                  value={values.description}
                  error={errors.description}
                  onChange={({ target: value }) => setFieldValue("description", value.value)}
                  margin="normal"
                ></Input>
                {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
              </InputWrapper>
              <InputWrapper>
                <InputLabel>{"Reservas por Unidad Funcional"}</InputLabel>
                <StyledSwitch
                  checked={values.validationByProperty}
                  onChange={() => setFieldValue("validationByProperty", !values.validationByProperty)}
                />
              </InputWrapper>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <ButtonWrapper>
              <Button type={'submit'} loading={isSubmitting}>
                Guardar
              </Button>
            </ButtonWrapper>
          </FlexWrapper>
        </Form>
      </>
    );
  };

  const form = () => {
    if (props.isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <Modal title={props.title} onClose={props.onClose} height={"min-height"} width="auto">
      {form()}
    </Modal>
  );
}

export default ActivityModal;
