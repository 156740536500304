import { createSelector } from 'reselect'

const mapFilteredProducts = createSelector(
  FilteredProducts => FilteredProducts,
  FilteredProducts =>
    FilteredProducts.map(
      (
        {
            creationdate,
            sellerusername,
            currency,
            price,
            categoryname,
            images,
            productname,
            id
        },
        i
      ) => ({
        date: creationdate,
        title: productname,
        user: sellerusername,
        amount: price,
        category: categoryname,
        images,
        idProduct: id
      })
    )
)

export default mapFilteredProducts
