import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'
import { updateMerchantType } from '../actions/paymentClients.actions'


const initialState = {
  rows: [],
  page: 0,
  pageSize: 10,
  total: 80,
  isLoading: false,
  orderColumn: 'creation_date',
  orderDirection: 'desc',
  searchText: '',
  getNews: false,
  paymentClients: [],
  totalPaymentClients: [],
  shops: [],
  bankAccounts: [],
  custodyBankAccounts: [],
  loadingPaymentClients: false,
  errorPaymentClients: null,
  loadingPaymentClient: false,
  errorPaymentClient: null,
  paymentClient: null,
  loadingCreatePaymentClient: false,
  errorCreatePaymentClient: null,
  createPaymentClientSuccess: false,
  loadingUpdatePaymentClient: false,
  errorUpdatePaymentClient: null,
  updatePaymentClientSuccess: false,
  loadingFetchShops: false,
  errorFetchShops: null,
  fetchShopsSuccess: false,
  loadingFetchBankAccounts: false,
  errorFetchBankAccounts: null,
  fetchBankAccountsSuccess: false,

  //FETCH CUSTODY BANK ACCOUNT
  loadingFetchCustodyBankAccounts: false,
  errorFetchCustodyBankAccounts: null,
  fetchCustodyBankAccountsSuccess: false,

  //FETCH VENDORS
  vendors: [],
  loadingFetchVendors: false,
  errorFetchVendors: null,

  //FETCH VENDOR DETAIL
  vendorDetail: [],
  loadingFetchVendorDetail: false,
  errorFetchVendorDetail: null,
  //CREATE VENDOR
  loadingCreateVendor: false,
  errorCreateVendor: null,
  createVendorSuccess: false,
  //UPDATE VENDOR
  loadingUpdateVendor: false,
  errorUpdateVendor: null,
  updateVendorSuccess: false,
  //DELETE VENDOR
  loadingDeleteVendor: false,
  deleteVendorSuccess: false,
  errorDeleteVendor: null,

  //NOTIFICATION

  loadingNotificationRequestFormat: false,
  successNotificationRequestFormat: false,
  errorNotificationRequestFormat: null,
  notificationRequestFormat: [],

  //FETCH MERCHANT TYPES
  loadingFetchMerchantTypes: false,
  errorFetchMerchantTypes: false,
  merchantTypes: [],
  //FETCH MERCHANT TYPE DETAIL
  merchantType: [],
  loadingFetchMerchantTypeDetail: false,
  errorFetchMerchantTypeDetail: null,
  //CREATE MERCHANT TYPE
  loadingCreateMerchantType: false,
  errorCreateMerchantType: '',
  createMerchantTypeSuccess: false,
  //UPDATE MERCHANT TYPE
  loadingUpdateMerchantType: false,
  errorUpdateMerchantType: '',
  updateMerchantTypeSuccess: false,
  //DELETE MERCHANT TYPE
  loadingDeleteMerchantType: false,
  deleteMerchantTypeSuccess: false,
  errorDeleteMerchantType: '',

  //CLIENTS TYPE
  fetchClientSuccess:false,
  fetchClientError: false,
}

////////////////////// FETCH ALL PAYMENTS //////////////////////
const fetchPaymentClientsSuccess = (state, action) => {
  return updateObject(state, {
    paymentClients: action.paymentClients,
    loadingPaymentClients: false,
    fetchClientSuccess:true
  })
}

const fetchPaymentClientsFail = (state, action) => {
  return updateObject(state, {
    loadingPaymentClients: false,
    errorPaymentClients: action.errorPaymentClients,
    fetchClientError:true,
  })
}

const fetchPaymentClientsStart = (state) => {
  return updateObject(state, {
    loadingPaymentClients: true,
    errorPaymentClients: null,
    fetchClientSuccess:false
  })
}

const fetchPaymentClientsReset = (state, action) => {
  return updateObject(state, {
    loadingPaymentClients: false,
    fetchClientSuccess:false,
    errorPaymentClients: null
  })
}

const updateGrid = (state, action) => {
  return updateObject(state, {
    page: action.page,
    pageSize: action.pageSize,
    total: action.total,
    orderColumn: action.orderColumn,
    orderDirection: action.orderDirection,
    searchText: action.searchText,
    getNews: action.getNews
  })
}

////////////////////// FETCH PAYMENT //////////////////////
const fetchPaymentClientSuccess = (state, action) => {
  return updateObject(state, {
    paymentClient: action.paymentClient,
    loadingPaymentClient: false
  })
}

const fetchPaymentClientFail = (state, action) => {
  return updateObject(state, {
    loadingPaymentClient: false,
    errorPaymentClient: action.errorPaymentClient
  })
}

const fetchPaymentClientStart = (state) => {
  return updateObject(state, {
    loadingPaymentClient: true,
    errorPaymentClient: null
  })
}

////////////////////// CREATE PAYMENT //////////////////////
const createPaymentClientSuccess = (state, action) => {
  return updateObject(state, {
    paymentClient: action.paymentClient,
    loadingCreatePaymentClient: false,
    createPaymentClientSuccess: true,
    errorCreatePaymentClient: null,
  })
}

const createPaymentClientFail = (state, action) => {
  return updateObject(state, {
    loadingCreatePaymentClient: false,
    errorCreatePaymentClient: action.errorPaymentClient,
    createPaymentClientSuccess: false
  })
}

const createPaymentClientStart = (state) => {
  return updateObject(state, {
    loadingCreatePaymentClient: true,
    errorCreatePaymentClient: null,
    createPaymentClientSuccess: false
  })
}

const createPaymentClientReset = state => {
  return updateObject(state, {
    loadingCreatePaymentClient: false,
    errorCreatePaymentClient: null,
    createPaymentClientSuccess: false
  })
}

////////////////////// UPDATE PAYMENT //////////////////////
const updatePaymentClientSuccess = (state, action) => {
  return updateObject(state, {
    paymentClient: action.paymentClient,
    loadingUpdatePaymentClient: false,
    updatePaymentClientSuccess: true
  })
}

const updatePaymentClientFail = (state, action) => {
  return updateObject(state, {
    loadingUpdatePaymentClient: false,
    errorUpdatePaymentClient: action.errorPaymentClient,
    updatePaymentClientSuccess: false
  })
}

const updatePaymentClientStart = (state) => {
  return updateObject(state, {
    loadingUpdatePaymentClient: true,
    errorUpdatePaymentClient: null,
    updatePaymentClientSuccess: false
  })
}

const updatePaymentClientReset = state => {
  return updateObject(state, {
    loadingUpdatePaymentClient: false,
    errorUpdatePaymentClient: null,
    updatePaymentClientSuccess: false
  })
}

///////////////FETCH SHOPS///////////////////////
const fetchShopsSuccess = (state, action) => {
  return updateObject(state, {
    shops: action.shops,
    loadingFetchShops: false,
    fetchShopsSuccess: true
  })
}

const fetchShopsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchShops: false,
    errorFetchShops: action.errorFetchShops,
    fetchShopsSuccess: false
  })
}

const fetchShopsStart = (state) => {
  return updateObject(state, {
    loadingFetchShops: true,
    errorFetchShops: null,
    fetchShopsSuccess: false
  })
}

///////////////FETCH BANK ACCOUNTS///////////////////////

const fetchBankAccountsReset = state => {
  return updateObject(state, {
    loadingFetchBankAccounts: false,
    fetchBankAccountsSuccess: null,
    errorFetchBankAccounts: null,
    bankAccounts: []
  })
}
const fetchBankAccountsSuccess = (state, action) => {
  return updateObject(state, {
    bankAccounts: action.bankAccounts,
    loadingFetchBankAccounts: false,
    fetchBankAccountsSuccess: true
  })
}

const fetchBankAccountsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchBankAccounts: false,
    errorFetchBankAccounts: action.error,
    fetchBankAccountsSuccess: false
  })
}

const fetchBankAccountsStart = (state) => {
  return updateObject(state, {
    loadingFetchBankAccounts: true,
    errorFetchBankAccounts: null,
    fetchBankAccountsSuccess: false
  })
}


////////// FETCH CUSTODY BANK ACCOUNT //////////////////

const fetchCustodyBankAccountsReset = state => {
  return updateObject(state, {
    loadingFetchCustodyBankAccounts: false,
    fetchCustodyBankAccountsSuccess: null,
    errorFetchCustodyBankAccounts: null,
    custodyBankAccounts: []
  })
}
const fetchCustodyBankAccountsSuccess = (state, action) => {
  return updateObject(state, {
    custodyBankAccounts: action.custodyBankAccounts,
    loadingFetchCustodyBankAccounts: false,
    fetchCustodyBankAccountsSuccess: true
  })
}

const fetchCustodyBankAccountsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchCustodyBankAccounts: false,
    errorFetchCustodyBankAccounts: action.error,
    fetchCustodyBankAccountsSuccess: false
  })
}

const fetchCustodyBankAccountsStart = (state) => {
  return updateObject(state, {
    loadingFetchCustodyBankAccounts: true,
    errorFetchCustodyBankAccounts: null,
    fetchCustodyBankAccountsSuccess: false
  })
}

////////////////////// FETCH VENDORS //////////////////////
const fetchVendorsSuccess = (state, action) => {
  return updateObject(state, {
    vendors: action.vendors,
    loadingFetchVendors: false
  })
}

const fetchVendorsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchVendors: false,
    errorFetchVendors: action.errorFetchVendors,
    vendors: [],
  })
}

const fetchVendorsStart = (state) => {
  return updateObject(state, {
    loadingFetchVendors: true,
    errorFetchVendors: null
  })
}
const fetchVendorsReset = (state) => {
  return updateObject(state, {
    loadingFetchVendors: false,
    errorFetchVendors: null,
    vendors: []
  })
}

////////////////////// FETCH VENDOR DETAIL //////////////////////
const fetchVendorDetailSuccess = (state, action) => {
  return updateObject(state, {
    vendorDetail: action.vendorDetail,
    loadingFetchVendorDetail: false
  })
}

const fetchVendorDetailFail = (state, action) => {
  return updateObject(state, {
    loadingFetchVendorDetail: false,
    errorFetchVendorDetail: action.errorFetchVendorDetail,
  })
}

const fetchVendorDetailStart = (state) => {
  return updateObject(state, {
    loadingFetchVendorDetail: true,
    errorFetchVendorDetail: null
  })
}

const fetchVendorDetailReset = (state) => {
  return updateObject(state, {
    loadingFetchVendorDetail: false,
    errorFetchVendorDetail: null,
    vendorDetail: []
  })
}

////////////////////// CREATE VENDOR //////////////////////
const createVendorSuccess = (state, action) => {
  return updateObject(state, {
    createVendorSuccess: true,
    loadingCreateVendor: false
  })
}

const createVendorFail = (state, action) => {
  return updateObject(state, {
    loadingCreateVendor: false,
    createVendorSuccess: false,
    errorCreateVendor: action.errorCreateVendor
  })
}

const createVendorStart = (state) => {
  return updateObject(state, {
    loadingCreateVendor: true,
    createVendorSuccess: false,
    errorCreateVendor: null
  })
}

const createVendorReset = (state) => {
  return updateObject(state, {
    loadingCreateVendor: false,
    createVendorSuccess: false,
    errorCreateVendor: null
  })
}
////////////////////// UPDATE VENDOR //////////////////////
const updateVendorSuccess = (state, action) => {
  return updateObject(state, {
    updateVendorSuccess: true,
    loadingUpdateVendor: false
  })
}

const updateVendorFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateVendor: false,
    updateVendorSuccess: false,
    errorUpdateVendor: action.errorUpdateVendor
  })
}

const updateVendorStart = (state) => {
  return updateObject(state, {
    loadingUpdateVendor: true,
    updateVendorSuccess: false,
    errorUpdateVendor: null
  })
}

const updateVendorReset = (state) => {
  return updateObject(state, {
    loadingUpdateVendor: false,
    updateVendorSuccess: false,
    errorUpdateVendor: null
  })
}

////////////////////// DELETE VENDOR //////////////////////
const deleteVendorSuccess = (state, action) => {
  return updateObject(state, {
    deleteVendorSuccess: true,
    loadingDeleteVendor: false
  })
}

const deleteVendorFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteVendor: false,
    deleteVendorSuccess: false
    // errorDeleteVendor: action.errorDeleteVendor
  })
}

const deleteVendorStart = (state) => {
  return updateObject(state, {
    loadingDeleteVendor: true,
    deleteVendorSuccess: false,
    errorDeleteVendor: null
  })
}



const deleteVendorReset = (state) => {
  return updateObject(state, {
    loadingDeleteVendor: false,
    deleteVendorSuccess: false
    // errorDeleteVendor: null
  })
}


////////// FETCH NOTIFICATION //////////




const fetchNotificationRequestFormatStart = (state) => {
  return updateObject(state, {
    loadingNotificationRequestFormat: true,
    errorNotificationRequestFormat: null
  })
}

const fetchNotificationRequestFormatSuccess = (state, action) => {
  return updateObject(state, {
    notificationRequestFormat: action.notificationRequestFormat,
    loadingNotificationRequestFormat: false
  })
}

const fetchNotificationRequestFormatFail = (state, action) => {
  return updateObject(state, {
    loadingNotificationRequestFormat: false,
    errorNotificationRequestFormat: action.errorNotificationRequestFormat
  })
}

//// FETCH MERCHANT TYPES ////

const fetchMerchantTypesSuccess = (state, action) => {
  return updateObject(state, {
    merchantTypes: action.merchantTypes,
    loadingFetchMerchantTypes: false
  })
}

const fetchMerchantTypesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchMerchantTypes: false,
    errorFetchMerchantTypes: action.errorFetchMerchantTypes,
    merchantTypes:[]
  })
}

const fetchMerchantTypesStart = (state) => {
  return updateObject(state, {
    loadingFetchMerchantTypes: true,
    errorFetchMerchantTypes: null
  })
}

////////////////////// FETCH MERCHANT TYPE DETAIL //////////////////////
const fetchMerchantTypeDetailSuccess = (state, action) => {
  return updateObject(state, {
    merchantType: action.merchantType,
    loadingFetchMerchantTypeDetail: false
  })
}

const fetchMerchantTypeDetailFail = (state, action) => {
  return updateObject(state, {
    loadingFetchMerchantTypeDetail: false,
    errorFetchMerchantTypeDetail: action.errorFetchMerchantTypeDetail,
  })
}

const fetchMerchantTypeDetailStart = (state) => {
  return updateObject(state, {
    loadingFetchMerchantTypeDetail: true,
    errorFetchMerchantTypeDetail: null
  })
}

const fetchMerchantTypeDetailReset = (state) => {
  return updateObject(state, {
    loadingFetchMerchantTypeDetail: false,
    errorFetchMerchantTypeDetail: null,
    merchantType: []
  })
}


////////////////////// CREATE MERCHANT TYPE //////////////////////
const createMerchantTypeSuccess = (state, action) => {
  return updateObject(state, {
    createMerchantTypeSuccess: true,
    loadingCreateMerchantType: false
  })
}

const createMerchantTypeFail = (state, action) => {
  return updateObject(state, {
    loadingCreateMerchantType: false,
    createMerchantTypeSuccess: false,
    errorCreateMerchantType: action.errorCreateMerchantType
  })
}

const createMerchantTypeStart = (state) => {
  return updateObject(state, {
    loadingCreateMerchantType: true,
    createMerchantTypeSuccess: false,
    errorCreateMerchantType: ''
  })
}

const createMerchantTypeReset = (state) => {
  return updateObject(state, {
    loadingCreateMerchantType: false,
    createMerchantTypeSuccess: false,
    errorCreateMerchantType: ''
  })
}
////////////////////// UPDATE  MERCHANTTYPE //////////////////////
const updateMerchantTypeSuccess = (state, action) => {
  return updateObject(state, {
    updateMerchantTypeSuccess: true,
    loadingUpdateMerchantType: false
  })
}

const updateMerchantTypeFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateMerchantType: false,
    updateMerchantTypeSuccess: false,
    errorUpdateMerchantType: action.errorUpdateMerchantType
  })
}

const updateMerchantTypeStart = (state) => {
  return updateObject(state, {
    loadingUpdateMerchantType: true,
    updateMerchantTypeSuccess: false,
    errorUpdateMerchantType: ''
  })
}

const updateMerchantTypeReset = (state) => {
  return updateObject(state, {
    loadingUpdateMerchantType: false,
    updateMerchantTypeSuccess: false,
    errorUpdateMerchantType: ''
  })
}

////////////////////// DELETE MERCHANT TYPE //////////////////////
const deleteMerchantTypeSuccess = (state, action) => {
  return updateObject(state, {
    deleteMerchantTypeSuccess: true,
    loadingDeleteMerchantType: false
  })
}

const deleteMerchantTypeFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteMerchantType: false,
    deleteMerchantTypeSuccess: false,
    errorDeleteMerchantType: action.errorDeleteMerchantType
  })
}

const deleteMerchantTypeStart = (state) => {
  return updateObject(state, {
    loadingDeleteMerchantType: true,
    deleteMerchantTypeSuccess: false,
    errorDeleteMerchantType: ''
  })
}

const deleteMerchantTypeReset = (state) => {
  return updateObject(state, {
    loadingDeleteMerchantType: false,
    deleteMerchantTypeSuccess: false,
    errorDeleteMerchantType: ''
  })
}



const reducer = (state = initialState, action) => {
  switch (action.type) {
    ////////////////////// FETCH ALL PAYMENTS //////////////////////
    case actionTypes.FETCH_PAYMENT_CLIENTS_SUCCESS:
      return fetchPaymentClientsSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_CLIENTS_FAIL:
      return fetchPaymentClientsFail(state, action)
    case actionTypes.FETCH_PAYMENT_CLIENTS_START:
      return fetchPaymentClientsStart(state, action)
    case actionTypes.PAYMENT_CLIENTS_UPDATEGRID:
      return updateGrid(state, action)
      case actionTypes.FETCH_PAYMENT_CLIENTS_RESET:
        return fetchPaymentClientsReset(state, action)
    ////////////////////// FETCH PAYMENT //////////////////////
    case actionTypes.FETCH_PAYMENT_CLIENT_SUCCESS:
      return fetchPaymentClientSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_CLIENT_FAIL:
      return fetchPaymentClientFail(state, action)
    case actionTypes.FETCH_PAYMENT_CLIENT_START:
      return fetchPaymentClientStart(state, action)
    ////////////////////// CREATE# PAYMENT //////////////////////
    case actionTypes.CREATE_PAYMENT_CLIENT_SUCCESS:
      return createPaymentClientSuccess(state, action)
    case actionTypes.CREATE_PAYMENT_CLIENT_FAIL:
      return createPaymentClientFail(state, action)
    case actionTypes.CREATE_PAYMENT_CLIENT_START:
      return createPaymentClientStart(state, action)
    case actionTypes.CREATE_PAYMENT_CLIENT_RESET:
      return createPaymentClientReset(state)
    ////////////////////// UPDATE# PAYMENT //////////////////////
    case actionTypes.UPDATE_PAYMENT_CLIENT_SUCCESS:
      return updatePaymentClientSuccess(state, action)
    case actionTypes.UPDATE_PAYMENT_CLIENT_FAIL:
      return updatePaymentClientFail(state, action)
    case actionTypes.UPDATE_PAYMENT_CLIENT_START:
      return updatePaymentClientStart(state, action)
    case actionTypes.UPDATE_PAYMENT_CLIENT_RESET:
      return updatePaymentClientReset(state)
    ////////////////////// FETCH SHOPS //////////////////////
    case actionTypes.FETCH_SHOPS_SUCCESS:
      return fetchShopsSuccess(state, action)
    case actionTypes.FETCH_SHOPS_FAIL:
      return fetchShopsFail(state, action)
    case actionTypes.FETCH_SHOPS_START:
      return fetchShopsStart(state, action)
    ////////////////////// FETCH BANK ACCOUNTS //////////////////////
    case actionTypes.FETCH_BANK_ACCOUNTS_SUCCESS:
      return fetchBankAccountsSuccess(state, action)
    case actionTypes.FETCH_BANK_ACCOUNTS_START:
      return fetchBankAccountsStart(state, action)
    case actionTypes.FETCH_BANK_ACCOUNTS_FAIL:
      return fetchBankAccountsFail(state, action)
    case actionTypes.FETCH_BANK_ACCOUNTS_START:
      return fetchBankAccountsStart(state, action)
    case actionTypes.FETCH_BANK_ACCOUNTS_RESET:
      return fetchBankAccountsReset(state, action)
    ////////////////////// FETCH VENDORS //////////////////////
    case actionTypes.FETCH_VENDORS_FAIL:
      return fetchVendorsFail(state, action)
    case actionTypes.FETCH_VENDORS_START:
      return fetchVendorsStart(state, action)
    case actionTypes.FETCH_VENDORS_SUCCESS:
      return fetchVendorsSuccess(state, action)
    case actionTypes.FETCH_VENDORS_RESET:
      return fetchVendorsReset(state, action)
    ////////////////////// FETCH VENDOR DETAIL //////////////////////
    case actionTypes.FETCH_VENDOR_DETAIL_FAIL:
      return fetchVendorDetailFail(state, action)
    case actionTypes.FETCH_VENDOR_DETAIL_START:
      return fetchVendorDetailStart(state, action)
    case actionTypes.FETCH_VENDOR_DETAIL_SUCCESS:
      return fetchVendorDetailSuccess(state, action)
    case actionTypes.FETCH_VENDOR_DETAIL_RESET:
      return fetchVendorDetailReset(state, action)
    ////////////////////// CREATE VENDOR //////////////////////
    case actionTypes.CREATE_VENDOR_SUCCESS:
      return createVendorSuccess(state, action)
    case actionTypes.CREATE_VENDOR_FAIL:
      return createVendorFail(state, action)
    case actionTypes.CREATE_VENDOR_START:
      return createVendorStart(state, action)
    case actionTypes.CREATE_VENDOR_RESET:
      return createVendorReset(state, action)
    ////////////////////// UPDATE VENDOR //////////////////////
    case actionTypes.UPDATE_VENDOR_SUCCESS:
      return updateVendorSuccess(state, action)
    case actionTypes.UPDATE_VENDOR_FAIL:
      return updateVendorFail(state, action)
    case actionTypes.UPDATE_VENDOR_START:
      return updateVendorStart(state, action)
    case actionTypes.UPDATE_VENDOR_RESET:
      return updateVendorReset(state, action)
    ////////////////////// DELETE VENDOR //////////////////////
    case actionTypes.DELETE_VENDOR_SUCCESS:
      return deleteVendorSuccess(state, action)
    case actionTypes.DELETE_VENDOR_FAIL:
      return deleteVendorFail(state, action)
    case actionTypes.DELETE_VENDOR_START:
      return deleteVendorStart(state, action)
    case actionTypes.DELETE_VENDOR_RESET:
      return deleteVendorReset(state, action)
    ////////////////////// FETCH CUSTODY BANK ACCOUNTS //////////////////////
    case actionTypes.FETCH_CUSTODY_BANK_ACCOUNTS_SUCCESS:
      return fetchCustodyBankAccountsSuccess(state, action)
    case actionTypes.FETCH_CUSTODY_BANK_ACCOUNTS_START:
      return fetchCustodyBankAccountsStart(state, action)
    case actionTypes.FETCH_CUSTODY_BANK_ACCOUNTS_FAIL:
      return fetchCustodyBankAccountsFail(state, action)
    case actionTypes.FETCH_CUSTODY_BANK_ACCOUNTS_START:
      return fetchCustodyBankAccountsStart(state, action)
    case actionTypes.FETCH_CUSTODY_BANK_ACCOUNTS_RESET:
      return fetchCustodyBankAccountsReset(state, action)
    ////////// FETCH NOTIFICATION //////////
    case actionTypes.FETCH_NOTIFICATION_REQUEST_FORMAT_FAIL:
      return fetchNotificationRequestFormatFail(state, action)
    case actionTypes.FETCH_NOTIFICATION_REQUEST_FORMAT_START:
      return fetchNotificationRequestFormatStart(state, action)
    case actionTypes.FETCH_NOTIFICATION_REQUEST_FORMAT_SUCCESS:
      return fetchNotificationRequestFormatSuccess(state, action)
    ////////////////////// FETCH MERCHANT TYPES ////////////////
    case actionTypes.FETCH_MERCHANT_TYPES_FAIL:
      return fetchMerchantTypesFail(state, action)
    case actionTypes.FETCH_MERCHANT_TYPES_START:
      return fetchMerchantTypesStart(state, action)
    case actionTypes.FETCH_MERCHANT_TYPES_SUCCESS:
      return fetchMerchantTypesSuccess(state, action)
    ////////////////////// CREATE MERCHANT TYPE //////////////////////
    case actionTypes.CREATE_MERCHANT_TYPE_SUCCESS:
      return createMerchantTypeSuccess(state, action)
    case actionTypes.CREATE_MERCHANT_TYPE_FAIL:
      return createMerchantTypeFail(state, action)
    case actionTypes.CREATE_MERCHANT_TYPE_START:
      return createMerchantTypeStart(state, action)
    case actionTypes.CREATE_MERCHANT_TYPE_RESET:
      return createMerchantTypeReset(state, action)
    ////////////////////// UPDATE MERCHANT TYPE //////////////////////
    case actionTypes.UPDATE_MERCHANT_TYPE_SUCCESS:
      return updateMerchantTypeSuccess(state, action)
    case actionTypes.UPDATE_MERCHANT_TYPE_FAIL:
      return updateMerchantTypeFail(state, action)
    case actionTypes.UPDATE_MERCHANT_TYPE_START:
      return updateMerchantTypeStart(state, action)
    case actionTypes.UPDATE_MERCHANT_TYPE_RESET:
      return updateMerchantTypeReset(state, action)
    ////////////////////// DELETE MERCHANT TYPE //////////////////////
    case actionTypes.DELETE_MERCHANT_TYPE_SUCCESS:
      return deleteMerchantTypeSuccess(state, action)
    case actionTypes.DELETE_MERCHANT_TYPE_FAIL:
      return deleteMerchantTypeFail(state, action)
    case actionTypes.DELETE_MERCHANT_TYPE_START:
      return deleteMerchantTypeStart(state, action)
    case actionTypes.DELETE_MERCHANT_TYPE_RESET:
      return deleteMerchantTypeReset(state, action)
    ////////////////////// FETCH MERCHANT TYPE DETAIL //////////////////////
    case actionTypes.FETCH_MERCHANT_TYPE_DETAIL_FAIL:
      return fetchMerchantTypeDetailFail(state, action)
    case actionTypes.FETCH_MERCHANT_TYPE_DETAIL_START:
      return fetchMerchantTypeDetailStart(state, action)
    case actionTypes.FETCH_MERCHANT_TYPE_DETAIL_SUCCESS:
      return fetchMerchantTypeDetailSuccess(state, action)
    case actionTypes.FETCH_MERCHANT_TYPE_DETAIL_RESET:
      return fetchMerchantTypeDetailReset(state, action)
    default:
      return state
  }
}

export default reducer