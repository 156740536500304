import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import CrossIcon from '../../assets/icons/close.svg'

import {
  FormContainer,
  Row,
  Title,
  SubTitle,
  ErrorLabel,
  StyledButton,
  CrossImage,
  LabelReSend,
  InputWrapper,
  InputStyled
} from './styled'

class popUpAuth extends React.Component {
    state={
        submit: false
    }

    
   
  componentDidUpdate(prevProps) {
    if(this.props.error === false && this.props.loading === false && this.state.submit === true){
      this.props.onClose()
    }
  }
    

    getInitialValues = () => ({
    token: '',
    })

    getSignUpSchema = () =>
        Yup.object().shape({
            token: Yup.string().required('El campo token es requerido').min(6, 'El token debe ser de 6 digitos').max(6, 'El token debe ser de 6 digitos'),
        })

    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
        <Row justify={"center"}> <Title>{'Autorizar con Token'}</Title> </Row>
        <Row justify={"center"} > <SubTitle>{'Ingresá el codigo de 6 digitos que hemos enviado a tu celular'}</SubTitle> </Row>
        <br/>
        <InputWrapper>
            <InputStyled
                autoFocus={true}
                name={'tokenInput'}
                label={'tokenInput'}
                value={values.token}
                error={errors.token}
                placeholder={"000000"}
                onChange={({ target: value }) => setFieldValue('token', value.value)}
                margin="normal"
                weight="700"
            />
            {this.props.fetchReSendToken ? <LabelReSend onClick={this.props.fetchReSendToken}>{'Reenviar Token'}</LabelReSend>  : <></>}
        </InputWrapper>
        <Row >
            {errors.token || this.props.error && <ErrorLabel>{errors.token ? errors.token : this.props.errorMessage}</ErrorLabel>}
        </Row>
        <Button type="submit" >{'Cargar Token'}</Button>
    </FormContainer>
    )

  handleSubmit = async data => {
    await this.props.chargeToken(data.token)
    this.setState({submit: true})
  }

  render() {
    return (
      <Modal onClose={this.props.onClose}>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}


export default popUpAuth
