/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../components/Loader";
import CardContainer from "./Card";
import copyClipboard from '../../../assets/icons/copy.svg';
import doubleCheck from '../../../assets/icons/check.png';
import {
  fetchGetTransferPending,
  fetchBalance,
  fetchGetAccountDebit,
  fetchTransactionList,
  fetchGetNumberOfTransferPending,
  fetchDebitAccounts,
  setBankAccount,
  fetchDownloadSummary,
  resetDownloadSummary,
  setPageTransactionList,
} from "../../../store/actions/billetera.actions";
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
import MovementList from "./MovementList";
import {
  SelectWrapper,
  TitleWrapper,
  HeaderMovementsWrapper,
  ListMovementsWrapper,
  ContentWrapper,
  AccountInfoData,
  TituloAccountInfoData,
  DetalleAccountInfoData,
  Wrapper,
  SelectBankAccountWrapper,
  RowContentWrapper,
  Label,
  AuthWrapper,
  ErrorLabel,
  LoaderWrapper
} from "./styled";
import Notification from "../../../assets/icons/notification.png"

var moment = require("moment");
var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });

class MiiiiBilletera extends React.Component {
  formatLabel = (bankAccount) => {
    return bankAccount.ClientAppUserName + ' - CUIT: ' + bankAccount.HolderCuit;
  }

  state = {
    selectedOption: null,
    isNewTransferOpen: false,
    isNewDestinatarioOpen: false,
    isNewGeneratePayOpen: false,
    isNewGenerateQROpen: false,
    isNewAllMovementsOpen: false,
    isNewAuthMovementsOpen: false,
    isDownloadSummaryOpen: false,
    isPrintQR: false,
    isChargeWithCard: false,
    movementsFiltered: [],
    setCopyAliasSuccess: false,
    setCopyCVUSuccess: false,


    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    },

    bankAccountSelected: {
      value: this.props.selectedBankAccount != null ? this.props.selectedBankAccount.Id : null,
      label: this.props.selectedBankAccount != null ? this.formatLabel(this.props.selectedBankAccount) : null,
    }

  };

  componentDidMount() {
    this.props.setBankAccount(null);
    this.props.fetchDebitAccounts();
    this.props.fetchGetNumberOfTransferPending();
    this.props.fetchGetTransferPending();
    this.props.resetDownloadSummary();

    let formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      let neighbourhood = {
        value: item.name,
        label: item.name,
        guid: item.guid
      };
      return neighbourhood;
    });

    this.setState({
      neighbourhoods: formattedNeighbourhoods
    });
  }


  componentWillReceiveProps(nextProps) {
    if (this.state.movementsFiltered !== nextProps.billetera.TransactionsList) {
      this.setState({
        movementsFiltered: nextProps.billetera.TransactionsList
      });
    }
    if (this.props.selectedBankAccount != null) {
      this.setState({
        bankAccountSelected: {
          value: this.props.selectedBankAccount.Id,
          label: this.formatLabel(this.props.selectedBankAccount),
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.summary !== this.props.summary) {
      this.downloadSumary() 
    }
  }
  


  copyToClipboard = (data) => {
    data === "alias" ? this.setState({ setCopyAliasSuccess: !this.state.setCopyAliasSuccess }) : this.setState({ setCopyCVUSuccess: !this.state.setCopyCVUSuccess })
    var textField = document.createElement('textarea')
    data === "alias" ? (
      textField.innerText = this.props.selectedBankAccount.Alias
    ) : (
      textField.innerText = this.props.selectedBankAccount.Cvbu
    )
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  };


  handleChange = neighbourhoodSelected => {
    this.props.setNeighbourhood(neighbourhoodSelected);
    this.setState({ neighbourhoodSelected: neighbourhoodSelected });
    this.props.resetDownloadSummary()
    this.props.fetchDebitAccounts();
    this.props.fetchGetNumberOfTransferPending();
    this.props.fetchGetTransferPending();
    this.props.setPageTransactionList(0);

  };


  getBankAccountsOptions = () => {
    if (this.props.billetera.bankAccounts != null && this.props.billetera.bankAccounts.length > 0)
      return this.props.billetera.bankAccounts.map(n => ({ label: this.formatLabel(n), value: n.Id }))
  }


  handleChangeBankAccount = selectedBankAccount => {
    
    this.setState({ selectedBankAccount: selectedBankAccount });
    this.props.setBankAccount(this.props.bankAccounts.filter(f => f.Id == selectedBankAccount.value)[0]);
    this.props.fetchBalance(selectedBankAccount.value);
    this.props.fetchTransactionList(false, selectedBankAccount.value);
    this.props.setPageTransactionList(0);

  };

  fetchAll = () => {
    this.props.fetchDebitAccounts();
    this.props.fetchGetNumberOfTransferPending();
    this.props.fetchGetTransferPending();

  };

  /*isLoadingContent = () => this.props.billetera.loading;*/

  formatNumber(num) {
    if (num) {
      return "ARS " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return "ARS 0";
    }
  }

  handleResourceClick = resource => this.props.setSelectedResource(resource);

  filterMovements = filtercharge => {
    const { billetera } = this.props;
    if (filtercharge.value === "todo") {
      this.setState({ movementsFiltered: billetera.TransactionsList });
    } else {
      let movementsFiltered = billetera.TransactionsList.filter(movement => {
        // if(movement.description === filtercharge.value){
        //   movementsFiltered.push(movement)
        // }
        return movement.description === filtercharge.value;
      });
      this.setState({ movementsFiltered: movementsFiltered });
    }
  };

  onOpenNewAllMovements = () => {
    this.setState({ isNewAllMovementsOpen: true });
  };
  onCloseNewAllMovements = () => {
    this.setState({ isNewAllMovementsOpen: false });
  };

  toggleDownloadSummaryOpen = () => {
    this.setState({ isDownloadSummaryOpen: !this.state.isDownloadSummaryOpen });
  };

  //moment().month() + 1,
  fetchSumary =() => {
    const currentMonth = moment().month();
    const currentYear = moment().year();
    var monthBefore = currentMonth === 0 ? 12 : currentMonth -1
    //Validacion para que enero (0) sea 1.
    this.props.fetchDownloadSummary(monthBefore === 12? currentYear - 1:currentYear, monthBefore,this.props.HolderCuit)
    this.toggleDownloadSummaryOpen();

  };

  downloadSumary=()=>{
    document.getElementById('download').click()
  }

  render() {
    const props = this.props;
    const state = this.state;
    console.log(this.props.summary )
    return (
      <ContentWrapper>
        <Wrapper id={"scrollContainer"}>
          <HeaderMovementsWrapper>
            <TitleWrapper>
              <h2>Tu Cuenta</h2>
            </TitleWrapper>
          </HeaderMovementsWrapper>
          <a href={props.summary[0]} target="_blank" id="download" hidden></a>
          <RowContentWrapper>
            <SelectWrapper>
              <Select
                value={this.state.neighbourhoodSelected}
                onChange={this.handleChange}
                options={this.state.neighbourhoods}
              />
            </SelectWrapper>
          </RowContentWrapper>
          {(props.bankAccounts != null && props.bankAccounts.length > 1) ? (
            <RowContentWrapper>
              <SelectBankAccountWrapper>
                <Select
                  value={this.state.bankAccountSelected}
                  onChange={this.handleChangeBankAccount}
                  options={this.getBankAccountsOptions()}
                  isLoading={props.billetera.loadingfetchDebitBankAccounts}
                  isDisabled={props.billetera.loadingfetchDebitBankAccounts}
                />
              </SelectBankAccountWrapper>

            </RowContentWrapper>) : (null)}
          <>
            <CardContainer
              amount={formatter.format(props.billetera.balance)}
              cvu={props.selectedBankAccount != null ? props.selectedBankAccount.Cvbu : null}
              error={props.billetera.errorTransactionsList}
              neighbourhoodSelected={state.neighbourhoodSelected}
            />
            {props.alias != null ?
              <AccountInfoData>
                <TituloAccountInfoData>Alias</TituloAccountInfoData>{" "}
                <DetalleAccountInfoData> {props.selectedBankAccount.Alias} </DetalleAccountInfoData>
                {state.setCopyAliasSuccess ?
                  (<img src={doubleCheck} onClick={() => this.copyToClipboard("alias")} style={{ cursor: "pointer", width: "20px", marginLeft: "2%" }} />)
                  :
                  (<img src={copyClipboard} onClick={() => this.copyToClipboard("alias")} style={{ cursor: "pointer", width: "20px", marginLeft: "2%" }} />)}
              </AccountInfoData>
              : null}
            {props.selectedBankAccount != null ?
              <AccountInfoData>
                <TituloAccountInfoData>CVU</TituloAccountInfoData>{" "}
                <DetalleAccountInfoData> {props.selectedBankAccount.Cvbu} </DetalleAccountInfoData>
                {state.setCopyCVUSuccess ?
                  (<img src={doubleCheck} onClick={() => this.copyToClipboard("cvbu")} style={{ cursor: "pointer", width: "20px", marginLeft: "2%" }} />)
                  :
                  (<img src={copyClipboard} onClick={() => this.copyToClipboard("cvbu")} style={{ cursor: "pointer", width: "20px", marginLeft: "2%" }} />)}
              </AccountInfoData>
              : null}
          </>
          {props.NumberOfTransferRequest ? (
            <AuthWrapper
              onClick={() => props.switchView("AuthMovs")}
              onClose={() => props.onCloseNewAuthMovements()}
            >
              <Label>
                Tenés <Label red> {props.NumberOfTransferRequest}</Label> autorizaciones de transferencia pendientes.
              </Label>
              <img src={Notification} style={{ marginRight: "20px", width: "25px" }} />
            </AuthWrapper>
          ) : (
            <>
              {props.NumberOfTransferRequest === 0 ? (
                <AuthWrapper>
                  <Label>No tenés autorizaciones de transferencia pendientes</Label>
                </AuthWrapper>
              ) : (
                <LoaderWrapper>
                  <Loader small />
                </LoaderWrapper>
              )}
            </>
          )}
          {props.billetera.errorTransactionsList === false ? 
            <>
              <AuthWrapper
                onClick={() => this.fetchSumary()}
                onClose={() => props.onCloseNewAuthMovements()}
              >
                {props.billetera.loadingDownloadSummary ? <Loader small/> : <Label>Descargar último resumen</Label>}
              </AuthWrapper>
              <ErrorLabel> {props.errorDownloadSummary ? props.errorDownloadSummaryMessage : null} </ErrorLabel>
            </>
          : <></>}
        </Wrapper>
        <Wrapper>
          <ListMovementsWrapper id={"scrollContainer"}>
            <HeaderMovementsWrapper>
              <TitleWrapper>
                <h2>Movimientos</h2>
              </TitleWrapper>
            </HeaderMovementsWrapper>
            <MovementList
              movements={state.movementsFiltered}
              filterMovements={this.filterMovements}
              onOpenAllMovements={this.onOpenNewAllMovements}
              onCloseAllMovements={this.onCloseNewAllMovements}
              onOpenNewAuthMovements={this.onOpenNewAuthMovements}
              onCloseNewAuthMovements={this.onCloseNewAuthMovements}
              fetchAll={this.fetchAll}
              switchView={props.switchView}
            />
          </ListMovementsWrapper>
        </Wrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood, username },
  billetera
}) => ({
  loading:
    billetera.loadingTransferRequest ||
    billetera.loadingNumberOfTransferRequest ||
    billetera.loadingTransferPending,
  billetera,
  summary: billetera.summary != [] && billetera.summary != false ? billetera.summary : [],
  neighbourhoods,
  selectedNeighbourhood,
  username,
  selectedBankAccount: billetera.selectedBankAccount,
  alias: billetera.selectedBankAccount != null ? billetera.selectedBankAccount.Alias : null,
  HolderCuit: billetera.selectedBankAccount != null ? billetera.selectedBankAccount.HolderCuit : null,
  NumberOfTransferRequest: billetera.NumberOfTransferRequest,
  bankAccounts: billetera.bankAccounts,
  errorDownloadSummary: billetera.errorDownloadSummary,
  errorDownloadSummaryMessage: billetera.errorDownloadSummaryMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators( 
    {
      fetchGetTransferPending,
      fetchBalance,
      setNeighbourhood,
      fetchGetAccountDebit,
      fetchTransactionList,
      fetchGetNumberOfTransferPending,
      fetchGetTransferPending,
      fetchDebitAccounts,
      setBankAccount,
      fetchDownloadSummary,
      resetDownloadSummary,
      setPageTransactionList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MiiiiBilletera);
