import React from "react";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";
import theme from "../config/theme";

const SwitchWrapper = styled.div`
  .MuiSwitch-colorSecondary.Mui-checked {
    color:${({ theme }) => theme.colors.midGray};;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.midGray};;
  }
`;

const StyledSwitch = props => {
  return (
    <SwitchWrapper>
      <Switch {...props} />
    </SwitchWrapper>
  );
};

export default StyledSwitch;
