import RitzTable from '../../../components/RitzTable';
import SectionHeader from '../../../components/SectionHeader';
import Button from '../../../components/Button';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ModalDelete from "../../../components/ModalDelete";
import MerchantTypeModal from './MerchantTypeModal';
import {
    fetchMerchantTypes
    , createMerchantType
    , createMerchantTypeReset
    , updateMerchantType
    , updateMerchantTypeReset
    , deleteMerchantType
    , deleteMerchantTypeReset
    , fetchMerchantTypeDetail
    , fetchMerchantTypeDetailReset
} from '../../../store/actions/paymentClients.actions';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ModalMessage from '../../../components/ModalMessage';

const mapStateToProps = state => {
    const {
        loadingFetchMerchantTypes,
        errorFetchMerchantTypes,
        merchantTypes,
        merchantType,
        loadingFetchMerchantTypeDetail,
        errorFetchMerchantTypeDetail,
        loadingCreateMerchantType,
        errorCreateMerchantType,
        createMerchantTypeSuccess,
        loadingUpdateMerchantType,
        errorUpdateMerchantType,
        updateMerchantTypeSuccess,
        loadingDeleteMerchantType,
        errorDeleteMerchantType,
        deleteMerchantTypeSuccess } = state.paymentClients;

    return {
        isLoading: loadingFetchMerchantTypes,
        loadingFetchMerchantTypes: loadingFetchMerchantTypes,
        errorFetchMerchantTypes: errorFetchMerchantTypes,
        merchantTypes: merchantTypes,
        merchantType: merchantType,
        loadingFetchMerchantTypeDetail: loadingFetchMerchantTypeDetail,
        errorFetchMerchantTypeDetail: errorFetchMerchantTypeDetail,
        loadingCreateMerchantType: loadingCreateMerchantType,
        errorCreateMerchantType: errorCreateMerchantType,
        createMerchantTypeSuccess: createMerchantTypeSuccess,
        loadingUpdateMerchantType: loadingUpdateMerchantType,
        errorUpdateMerchantType: errorUpdateMerchantType,
        updateMerchantTypeSuccess: updateMerchantTypeSuccess,
        loadingDeleteMerchantType: loadingDeleteMerchantType,
        errorDeleteMerchantType: errorDeleteMerchantType,
        deleteMerchantTypeSuccess: deleteMerchantTypeSuccess

    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createMerchantTypeReset
            , updateMerchantTypeReset
            , fetchMerchantTypes
            , createMerchantType
            , updateMerchantType
            , deleteMerchantType
            , deleteMerchantTypeReset
            , fetchMerchantTypeDetail
            , fetchMerchantTypeDetailReset
        },
        dispatch
    )


function MerchantTypes(props) {

    const {
        isLoading,
        loadingFetchMerchantTypes,
        errorFetchMerchantTypes,
        merchantTypes,
        merchantType,
        loadingFetchMerchantTypeDetail,
        errorFetchMerchantTypeDetail,
        loadingCreateMerchantType,
        errorCreateMerchantType,
        createMerchantTypeSuccess,
        loadingUpdateMerchantType,
        errorUpdateMerchantType,
        updateMerchantTypeSuccess,
        loadingDeleteMerchantType,
        errorDeleteMerchantType,
        deleteMerchantTypeSuccess } = props;

    const columns = [
        { title: 'Descripción', field: 'description', },
        { title: 'Código', field: 'code', },
    ];

    const [selectedId, setSelectedId] = useState(null);
    const [addMerchantType, setAddNew] = useState(false);
    const [editMerchantType, setEditNew] = useState(false);
    const [deleteMerchantType, setDeleteNew] = useState(false);

    useEffect(() => {
        props.fetchMerchantTypes()
        return function cleanup() {
            props.fetchMerchantTypeDetailReset();
            props.createMerchantTypeReset();
            props.updateMerchantTypeReset();
            props.deleteMerchantTypeReset();
          };
    }, []);

    useEffect(() => {
        if (!addMerchantType && !editMerchantType && !deleteMerchantType) {
            props.fetchMerchantTypes()
        }
    }, [addMerchantType, editMerchantType, deleteMerchantType]);

    const toggleAddMerchantType = async () => {
        setAddNew(!addMerchantType);
        if (!addMerchantType)
            props.createMerchantTypeReset();
    };

    const toggleEditMerchantType = async (selectedId) => {
        setEditNew(!editMerchantType);
        if (!editMerchantType) {
            await props.fetchMerchantTypeDetail(selectedId);
            props.updateMerchantTypeReset();
        }
    };

    const toggleDeleteMerchantType = async (selectedId) => {
        setDeleteNew(!deleteMerchantType);
        if (!deleteMerchantType)
            setSelectedId(selectedId);
        props.deleteMerchantTypeReset();
    };

    const onCreateMerchantTypeSubmit = async (data) => {

        let formattedData = {

            description: data.description,
            code: data.code,
        };
        await props.createMerchantType(formattedData);
    }

    const onUpdateMerchantTypeSubmit = async (data) => {
        let formattedData = {
            id: data.id,
            description: data.description,
            code: data.code,
        };
        await props.updateMerchantType(formattedData);
    }

    const onDeleteMerchantTypeSubmit = async () => {
        props.deleteMerchantType(selectedId);
    };

    const renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
        {message}
    </ModalMessage>);
    const addModal = (() => {
        if (createMerchantTypeSuccess) {
            return renderModalMessageSuccess('Se ha creado el tipo de comercio correctamente.', toggleAddMerchantType);
        }
        else if (errorCreateMerchantType != null && errorCreateMerchantType != "") {
            return renderModalMessageFail("Error: " + errorCreateMerchantType, toggleAddMerchantType);
        }
        else if (addMerchantType) {
            return (<MerchantTypeModal title="Agregar tipo de comercio"
                onClose={toggleAddMerchantType}
                isLoading={false}
                isSubmitting={loadingCreateMerchantType}
                submitSuccess={createMerchantTypeSuccess}
                onSubmit={onCreateMerchantTypeSubmit}
            />);
        }
    }
    )();

    const editModal = (() => {
        if (updateMerchantTypeSuccess) {
            return renderModalMessageSuccess('Se ha modificado el tipo de comercio correctamente.', toggleEditMerchantType);
        }
        else if (errorUpdateMerchantType != null && errorUpdateMerchantType != "") {
            return renderModalMessageFail("Error: " +errorUpdateMerchantType, toggleEditMerchantType);
        }
        else if (editMerchantType) {
            return (<MerchantTypeModal title="Editar tipo de comercio"
                merchantType={merchantType}
                onClose={toggleEditMerchantType}
                isLoading={loadingFetchMerchantTypeDetail}
                isSubmitting={loadingUpdateMerchantType}
                submitSuccess={updateMerchantTypeSuccess}
                onSubmit={onUpdateMerchantTypeSubmit}
            />);
        }
    }
    )();


    const deleteModal = (() => {
        if (deleteMerchantTypeSuccess) {
            return renderModalMessageSuccess("Se Ha eliminado el tipo de comercio", toggleDeleteMerchantType);
        } else if (errorDeleteMerchantType != null && errorDeleteMerchantType != "") {
            return renderModalMessageFail("Error: " + errorDeleteMerchantType, toggleDeleteMerchantType);
        }
        if (deleteMerchantType) {
            return (
                <ModalDelete
                    title="Eliminar tipo de comercio"
                    subTitle="¿Estás seguro que querés eliminar el tipo de comercio?"
                    buttonMessage="Eliminar"
                    isLoading={loadingDeleteMerchantType}
                    onClose={toggleDeleteMerchantType}
                    success={false}
                    onSubmit={onDeleteMerchantTypeSubmit}
                />
            );
        }
    })();

    return (<>
        {<>
            <SectionHeader title='Tipos de comercio Amex' justifyContent={"space-between"} fontWeight={"bold"}>
                <Button loading={isLoading} onClick={() => toggleAddMerchantType()}>Agregar Tipo de comercio</Button>
            </SectionHeader>
            <RitzTable
                columns={columns}
                title=""
                isLoading={isLoading}
                options={{
                    toolbar: false,
                    paging: true,
                    serverSide: false
                }}
                actions={[
                    {
                        icon: EditIcon,
                        tooltip: 'Editar',
                        isFreeAction: false,
                        onClick: (event, rowData) => { toggleEditMerchantType(rowData.id) }
                    }
                    ,
                    {
                        icon: DeleteIcon,
                        tooltip: 'Eliminar',
                        isFreeAction: false,
                        onClick: (event, rowData) => { toggleDeleteMerchantType(rowData.id) }
                    }
                ]}
                data={merchantTypes} /></>}
        {addModal}
        {editModal}
        {deleteModal}
    </>);
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MerchantTypes)