import React, { useState } from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import ProcessesList from './ProcessesList';
import StateManager from 'react-select';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

const mapStateToProps = ({
    userInfo: { menusAuth }
}) => ({
    menus: menusAuth
})

function Regulations(props) {

    const PROCESSES_LIST_VIEW = 'Comunicación D';

    const [view, setView] = useState('Comunicación D');

     var menulistItems = [
         {title: 'Comunicación D', route: PROCESSES_LIST_VIEW, available: true}
       ];

    //  Items del menú
    const menuListComponent = (menulistItems.map((item, key) => 
    <MenuItems 
        quantity={item.quantity} 
        onClick={item.available ? () => setView(item.route) : null} 
        active={view === item.route} 
        disabled={!item.available} 
        key={key}>{item.title}
    </MenuItems>));

    // Devuelvo la vista en base a la selección en el menú
    const selectedView = (() => {
        switch (view) {
            case PROCESSES_LIST_VIEW:
                return (<ProcessesList/>)
            default:
                return (<ProcessesList/>)
        }
    })();

    return (
        <NavBar menuListComponent={menuListComponent} title='Informes y Regulaciones'>
            {selectedView}
        </NavBar>);
}

// export default Regulations

export default connect(
    mapStateToProps,
    null
)(Regulations)