import React from 'react'
import {
    Wrapper, Label
} from './styled'
class ResponseLabel extends React.Component {
    state = { showing: true }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ showing: false });
            this.props.onClose()
        }, 3000);
    }

    render() {
        const { children, success, position } = this.props
        return (
            <Wrapper position={position} >
                {
                    this.state.showing ?
                        (
                            <Wrapper>
                                <Label success={this.props.success}>{children}</Label>
                            </Wrapper>
                        )
                        :
                        (
                            <></>
                        )
                }
            </Wrapper>
        )
    }
}

export default ResponseLabel
