import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchForgotPassword } from '../../../../store/actions/auth.actions'
import PropTypes from 'prop-types'
import LoginInput from '../../../../components/LoginInput';
import { Container, StyledImg, Wrapper, Title, Subtitle, ButtonStyled, ErrorLabel, ButtonBack, WrapperButton } from '../styled'
import Button from '../../../../components/Button';
import { Formik } from 'formik'
import * as Yup from 'yup'


class Forgot extends Component {
  state = {
    step: 1,
  }
  getInitialValues = () => ({
    inputUserName: '',
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      inputUserName: Yup.string().required('Usuario requerido'),
    })


  handleFormSubmitInitial = async data => {
    console.log("entro al primero", data)
    this.props.fetchForgotPassword(data.inputUserName)
    this.props.Username(data.inputUserName)
    this.props.NextStep(2)

  };


  getFormContentInitial = ({ handleSubmit, values, setFieldValue, errors, setSubmitting, OnClickBack }) => (
    <Container onSubmit={handleSubmit}>
      <Wrapper>
        <Title variant="h5" component="h2" gutterBottom>
          Recuperación de Contraseña
        </Title>
        <br />
        <Subtitle >Recibirá un codigo que ingresara en el paso siguiente.</Subtitle>
      </Wrapper>

      <Wrapper>
        <LoginInput
          value={values.inputUserName}
          error={errors.inputUserName}
          onChange={({ target: value }) => setFieldValue('inputUserName', value.value.trim().replace(/\s+/g, ''))}
          component="p"
          gutterBottom
          placeholder="Nombre de usuario"
          fullWidth
        />
        <WrapperButton>
          <Button type="submit" value="submit">
            Enviar Codigo
          </Button>
          <ButtonBack variant={"primary"} type="" onClick={this.props.OnClickBack()}>Volver al Ingreso</ButtonBack>
          {errors.inputUserName && <ErrorLabel>{errors.inputUserName}</ErrorLabel>}
          {this.props.errorForgotPassword && <ErrorLabel>{this.props.errorForgotPasswordMessage}</ErrorLabel>}
          {this.props.forgotPassword ? <ErrorLabel>{this.props.forgotPassword.message}</ErrorLabel> : null}
        </WrapperButton>

      </Wrapper>

    </Container>
  )


  render() {
    console.log(this.state)
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmitInitial}
        render={e => this.getFormContentInitial(e)}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    forgotPassword: state.auth.forgotPassword,
    errorForgotPasswordMessage: state.auth.errorForgotPasswordMessage,
    errorForgotPassword: state.auth.errorForgotPassword,
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForgotPassword,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot)
