import RitzTable from '../../../components/RitzTable';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import Button from '../../../components/Button';
import LoaderImage from '../../../components/LoaderImage';
import Loader from "../../../assets/animation/loader.gif"
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import StyledSelect from '../../../components/StyledSelect';
import {
    fetchPaymentClients
    , fetchPaymentClient
    , createPaymentClient
    , createPaymentClientReset
    , updatePaymentClient
    , fetchShops
    , fetchBankAccountsReset
    , fetchBankAccounts
    , updatePaymentClientReset
    , fetchNotificationRequestFormat
    , fetchMerchantTypes
} from '../../../store/actions/paymentClients.actions';
import { FormGroup } from '@material-ui/core';
import {
    Row, LoaderWrapper, InputWrapper,
    InputLabel,
    SelectWrapper,
} from './styled';
import ClientModal from './ClientModal';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ModalMessage from '../../../components/ModalMessage';

const mapStateToProps = state => {
    const {
        paymentClients,
        page,
        pageSize,
        total,
        loadingPaymentClients,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentClient,
        loadingPaymentClient,
        loadingCreatePaymentClient,
        errorCreatePaymentClient,
        createPaymentClientSuccess,
        loadingUpdatePaymentClient,
        errorUpdatePaymentClient,
        updatePaymentClientSuccess,
        loadingFetchShops,
        errorFetchShops,
        fetchShopsSuccess,
        loadingFetchBankAccounts,
        bankAccounts,
        errorFetchBankAccounts,
        fetchBankAccountsSuccess,
        notificationRequestFormat,
        loadingNotificationRequestFormat,
        shops,
        merchantTypes,
        loadingFetchMerchantTypes } = state.paymentClients;

    return {
        paymentClients: paymentClients,
        page: page,
        pageSize: pageSize,
        total: total,
        isLoading: loadingPaymentClients,
        orderColumn: orderColumn,
        orderDirection: orderDirection,
        searchText: searchText,
        getNews: getNews,
        paymentClient: paymentClient,
        loadingPaymentClient: loadingPaymentClient,
        loadingCreatePaymentClient: loadingCreatePaymentClient,
        errorCreatePaymentClient: errorCreatePaymentClient,
        createPaymentClientSuccess: createPaymentClientSuccess,
        loadingUpdatePaymentClient: loadingUpdatePaymentClient,
        errorUpdatePaymentClient: errorUpdatePaymentClient,
        updatePaymentClientSuccess: updatePaymentClientSuccess,
        loadingFetchShops: loadingFetchShops,
        errorFetchShops: errorFetchShops,
        loadingFetchBankAccounts: loadingFetchBankAccounts,
        fetchShopsSuccess: fetchShopsSuccess,
        bankAccounts: bankAccounts,
        errorFetchBankAccounts: errorFetchBankAccounts,
        fetchBankAccountsSuccess: fetchBankAccountsSuccess,
        shops: shops,
        notificationRequestFormat: notificationRequestFormat,
        loadingNotificationRequestFormat: loadingNotificationRequestFormat,
        merchantTypes: merchantTypes,
        loadingFetchMerchantTypes: loadingFetchMerchantTypes

    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchPaymentClients, fetchPaymentClient, createPaymentClient, createPaymentClientReset
            , updatePaymentClient
            , updatePaymentClientReset
            , fetchShops
            , fetchBankAccountsReset
            , fetchBankAccounts
            , fetchNotificationRequestFormat
            , fetchMerchantTypes
        },
        dispatch
    )


function Clients(props) {

    const {
        searchText,
        paymentClients,
        total,
        isLoading,
        orderColumn,
        orderDirection,
        getNews,
        paymentClient,
        loadingPaymentClient,
        fetchPaymentClients,
        fetchPaymentClient,
        loadingCreatePaymentClient,
        errorCreatePaymentClient,
        createPaymentClientSuccess,
        loadingUpdatePaymentClient,
        errorUpdatePaymentClient,
        updatePaymentClientSuccess,
        loadingFetchShops,
        errorFetchShops,
        fetchShopsSuccess,
        loadingFetchBankAccounts,
        bankAccounts,
        errorFetchBankAccounts,
        fetchBankAccountsSuccess,
        fetchNotificationRequestFormat,
        notificationRequestFormat,
        loadingNotificationRequestFormat,
        shops,
        merchantTypes,
        loadingFetchMerchantTypes } = props;

    const columns = [
        { title: 'Cliente', field: 'name', },
        { title: 'Descripción', field: 'description', },
    ];

    const [selectedId, setSelectedId] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [addClient, setAddNew] = useState(false);
    const [editClient, setEditNew] = useState(false);

    useEffect(() => {
        props.fetchPaymentClients(props.page, props.pageSize, orderColumn, orderDirection, searchText, getNews, '', true)
    }, []);

    useEffect(() => {
        if (!addClient && !editClient) {
            props.fetchBankAccountsReset()
            props.fetchPaymentClients(props.page, props.pageSize, orderColumn, orderDirection, searchText, getNews, '', true)
            props.fetchNotificationRequestFormat(notificationRequestFormat)
        }
    }, [addClient, editClient]);

    const onChangePageHandler = (e, page) => {
        props.fetchPaymentClients(page, props.pageSize, orderColumn, orderDirection, searchText, getNews, '', true)
    }

    const onChangeRowsPerPageHandler = (pageSize) => {
        props.fetchPaymentClients(0, pageSize, orderColumn, orderDirection, searchText, getNews, '', true)
    }

    const onKeyDownHanlder = (e) => {
        console.log(e)
        if (e.keyCode === 13) {
            props.fetchPaymentClients(props.page, props.pageSize, orderColumn, orderDirection, e.target.value, getNews, '', true)
        }
    }

    const onChangeHandler = e => {
        if (e.target.value === '') {
            props.fetchPaymentClients(props.page, props.pageSize, orderColumn, orderDirection, '', getNews, '', true)
        }
    }

    const checkBoxOnChangeHandler = e => {
        props.fetchPaymentClients(props.page, props.pageSize, orderColumn, orderDirection, searchText, e.target.checked, '', true)
    }

    const onOrderChangeHandler = (orderedColumnId, orderDirection) => {
        var columnName = 'creation_date';
        var dir = 'desc';
        if (orderedColumnId != null) {
            const column = columns[orderedColumnId];
            if (column != null)
                columnName = column.field;
        }

        if (orderDirection != null && orderDirection !== '')
            dir = orderDirection;

        props.fetchPaymentClients(props.page, props.pageSize, columnName, dir, searchText, getNews, '', true)
    }

    const toggleShowDetail = async (selectedId) => {
        setShowDetail(!showDetail);
        await props.fetchPaymentClient(selectedId);
        await props.fetchShops();
        await props.fetchMerchantTypes();
    };

    const toggleAddClient = async () => {
        setAddNew(!addClient);
        props.createPaymentClientReset();
        await props.fetchShops();
        await props.fetchNotificationRequestFormat();
        await props.fetchMerchantTypes();
    };

    const toggleEditClient = async (selectedId) => {
        setEditNew(!editClient);
        props.updatePaymentClientReset();
        await props.fetchPaymentClient(selectedId);
        await props.fetchShops();
        await props.fetchNotificationRequestFormat();
        await props.fetchMerchantTypes();
    };

    const onCreatePaymentClientSubmit = async (data) => {

        let formattedData = {

            description: data.description,
            name: data.name,
            logo: data.logo_url,
            logo_base64: data.logo_url ? data.logo_url.base64Value : null,
            logo_extension: data.logo_url ? data.logo_url.extension : null,
            notification_url: data.notification_url,
            bank_account_id: data.bank_account_id,
            bank_custody_account_id: data.bank_custody_account_id,
            neighbourhoodGuid: data.neighbourhoodGuid,
            token: data.token,
            notification_request_format_id: data.notificationRequestFormat,
            merchant_number_id: data.merchantType && data.merchantType > 0 ? data.merchantType : null,
            app_client_id: data.appClientId
        };
         await props.createPaymentClient(formattedData);
    }

    const onUpdatePaymentClientSubmit = async (data) => {

        let formattedData = {
            id: data.id,
            description: data.description,
            name: data.name,
            logo: data.logo_url,
            logo_base64: data.logo_url ? data.logo_url.base64Value : null,
            logo_extension: data.logo_url ? data.logo_url.extension : null,
            notification_url: data.notification_url,
            bank_account_id: data.bank_account_id,
            bank_custody_account_id: data.bank_custody_account_id,
            neighbourhoodGuid: data.neighbourhoodGuid,
            token: data.token,
            notification_request_format_id: data.notificationRequestFormat,
            merchant_number_id:  data.merchantType && data.merchantType > 0 ? data.merchantType : null,
            app_client_id: data.appClientId
        };
         await props.updatePaymentClient(formattedData);
    }

    const renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
        {message}
    </ModalMessage>);

    const addModal = (() => {
        if (props.createPaymentClientSuccess) {
            return renderModalMessageSuccess('Se ha creado el cliente correctamente.', toggleAddClient);
        }
        else if (props.errorCreatePaymentClient) {
            return renderModalMessageFail('Hubo un error al intentar crear el cliente.', toggleAddClient);
        }
        else if (addClient) {
            return (<ClientModal title="Agregar Cliente"
                isReadOnly={false}
                onClose={toggleAddClient}
                shops={shops}
                isLoading={loadingFetchShops || loadingNotificationRequestFormat || loadingFetchMerchantTypes}
                isSubmitting={loadingCreatePaymentClient}
                submitSuccess={createPaymentClientSuccess}
                onSubmit={onCreatePaymentClientSubmit}
                notificationRequestFormat={notificationRequestFormat}
                merchantTypes={merchantTypes} />);
        }
    }
    )();

    const editModal = (() => {
        if (props.updatePaymentClientSuccess) {
            return renderModalMessageSuccess('Se ha modificado el cliente correctamente.', toggleEditClient);
        }
        else if (props.errorUpdatePaymentClient) {
            return renderModalMessageFail('Hubo un error al intentar modificar el cliente.', toggleEditClient);
        }
        else if (editClient) {
            return (<ClientModal title="Editar Cliente"
                paymentClient={paymentClient}
                isReadOnly={false}
                shops={shops}
                bankAccounts={bankAccounts}
                onClose={toggleEditClient}
                isLoading={loadingPaymentClient || loadingFetchShops || loadingNotificationRequestFormat || loadingFetchMerchantTypes}
                isSubmitting={loadingUpdatePaymentClient}
                submitSuccess={updatePaymentClientSuccess}
                onSubmit={onUpdatePaymentClientSubmit}
                notificationRequestFormat={notificationRequestFormat}
                merchantTypes={merchantTypes}
            />);
        }
    }
    )();

    return (<>
        {<>
            <SectionHeader title='Clientes' justifyContent={"space-between"}>
                <Input
                    type="text"
                    search={true}
                    defaultValue={searchText}
                    placeholder={'Buscar'}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDownHanlder}
                    disabled={isLoading}
                />
                <Button loading={isLoading} onClick={() => toggleAddClient()}>Agregar Cliente</Button>
            </SectionHeader>
            <RitzTable
                columns={columns}
                title=""
                isLoading={isLoading}
                options={{
                    toolbar: false,
                    paging: true,
                    serverSide: true
                }}
                onChangeRowsPerPage={onChangeRowsPerPageHandler}
                onOrderChange={onOrderChangeHandler}
                pagination={{
                    totalRow: total,
                    page: props.page,
                    pageSize: props.pageSize,
                    onChangePage: onChangePageHandler,
                }}
                actions={[
                    {
                        icon: VisibilityOutlinedIcon,
                        tooltip: 'Ver detalle',
                        isFreeAction: false,
                        onClick: (event, rowData) => toggleShowDetail(rowData.id)
                    },
                    {
                        icon: EditIcon,
                        tooltip: 'Editar',
                        isFreeAction: false,
                        onClick: (event, rowData) => { toggleEditClient(rowData.id) }
                    }
                    // , 
                    // {
                    //     icon: DeleteIcon,
                    //     tooltip: 'Edit',
                    //     isFreeAction: false,
                    //     onClick: (event, rowData) => { }
                    // }                   
                ]}
                data={paymentClients} /></>}
        {showDetail ? <ClientModal title="Detalle"
            paymentClient={paymentClient}
            isReadOnly={true}
            shops={shops}
            bankAccounts={bankAccounts}
            onClose={toggleShowDetail}
            isLoading={loadingPaymentClient || loadingFetchShops}
            notificationRequestFormat={notificationRequestFormat}
            merchantTypes={merchantTypes}
        /> : null}
        {addModal}
        {editModal}
    </>);
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Clients)