import * as actionTypes from "./actionTypes";
import getApi from "../../shared/api";
import {
  bookingsNeighbourhoodAPI,
  bookingsNeighbourhoodActivitiesAPI,
  bookingsNeighbourhoodSpacesAPI,
  bookingsAvailableSpacesAPI,
  bookingsActivitiesAPI,
  bookingsAPI,
  schedulesAPI,
  bookingsActivityRulesAPI,
  ActivityRuleValuesAPI
} from "../../apiConstants";
import mapBookingsComboActivities from "../../selectors/mapBookingsComboActivities";
import mapBookings from "../../selectors/mapBookings";
import mapSchedules from "../../selectors/mapSchedules";
import mapBookingsComboSpacesToBlock from "../../selectors/mapBookingsComboSpacesToBlock";
import { ControlPointDuplicateOutlined } from "@material-ui/icons";


//Spaces
export const fetchSpacesSuccess = spaces => {
  return {
    type: actionTypes.FETCH_BOOKING_SPACES_SUCCESS,
    data: spaces
  };
};

export const fetchSpacesFail = error => {
  return {
    type: actionTypes.FETCH_BOOKING_SPACES_FAIL,
    error: error
  };
};

export const fetchSpacesStart = () => {
  return {
    type: actionTypes.FETCH_BOOKING_SPACES_START
  };
};

export const fetchSpaces = (neighbourhoodGuid, activity, searchText) => {
  return async dispatch => {
    dispatch(fetchSpacesStart());
    const api = await getApi();
    await api
      .get(
        `${bookingsNeighbourhoodSpacesAPI}?neighbourhoodGuid=${neighbourhoodGuid}&activityId=${activity ? activity.value : null
        }&searchText=${searchText}`
      )
      .then(res => {
        dispatch(fetchSpacesSuccess(res.data.spaces));
      })
      .catch(err => {
        dispatch(fetchSpacesFail(err));
      });
    dispatch(updateSpaceFilters(activity, searchText));
  };
};

//Combo Space to block
export const fetchComboSpacesToBlockSuccess = spaces => {
  return {
    type: actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_SUCCESS,
    data: spaces
  };
};

export const fetchComboSpacesToBlockFail = error => {
  return {
    type: actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_FAIL,
    error: error
  };
};

export const fetchComboSpacesToBlockReset = () => {
  return {
    type: actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_RESET
  };
};

export const fetchComboSpacesToBlockStart = () => {
  return {
    type: actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_START
  };
};

export const fetchComboSpacesToBlock = (spaceId, activityId) => {
  return async dispatch => {
    dispatch(fetchComboSpacesToBlockStart());
    const api = await getApi();
    await api
      .get(`${bookingsAvailableSpacesAPI}?id=${spaceId}&activityId=${activityId}`)
      .then(res => {
        let mappedComboSpaceToBlock = mapBookingsComboSpacesToBlock(res.data.spaces);
        dispatch(fetchComboSpacesToBlockSuccess(mappedComboSpaceToBlock));
      })
      .catch(err => {
        dispatch(fetchComboSpacesToBlockFail(err));
      });
  };
};

//Combo activities
export const fetchComboActivitiesSuccess = neighbourhoodActivities => {
  return {
    type: actionTypes.FETCH_COMBO_ACTIVITIES_SUCCESS,
    neighbourhoodActivities: neighbourhoodActivities
  };
};

export const fetchComboActivitiesFail = error => {
  return {
    type: actionTypes.FETCH_COMBO_ACTIVITIES_FAIL,
    error: error
  };
};

export const fetchComboActivitiesStart = () => {
  return {
    type: actionTypes.FETCH_COMBO_ACTIVITIES_START
  };
};

export const updateSpaceFilters = (activity, searchText) => {
  return {
    type: actionTypes.UPDATE_BOOKING_SPACE_FILTERS,
    searchText: searchText,
    activity: activity
  };
};

export const clearSpaces = () => {
  return {
    type: actionTypes.CLEAR_BOOKING_SPACES
  };
};

export const fetchComboActivities = (selectedNeighbourhood, ignoreSearchSpaces) => {
  return async dispatch => {
    dispatch(fetchComboActivitiesStart());
    let allAcvititiesOption = { value: null, label: "Todas" };
    const api = await getApi();
    await api
      .get(`${bookingsNeighbourhoodActivitiesAPI}?neighbourhoodGuid=${selectedNeighbourhood}`)
      .then(res => {
        let mappedComboActivities = mapBookingsComboActivities(res.data.activities);
        mappedComboActivities.push(allAcvititiesOption);
        dispatch(
          fetchComboActivitiesSuccess(
            mappedComboActivities.sort((a, b) => (a.value > b.value ? 1 : -1))
          )
        );
      })
      .catch(err => {
        dispatch(fetchComboActivitiesFail(err));
      });
    if (!ignoreSearchSpaces) dispatch(fetchSpaces(selectedNeighbourhood, allAcvititiesOption, ""));
  };
};

//Fetch activities
export const fetchBookingActivitiesSuccess = neighbourhoodActivities => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITIES_SUCCESS,
    neighbourhoodActivities: neighbourhoodActivities
  };
};

export const fetchBookingActivitiesFail = error => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITIES_FAIL,
    error: error
  };
};

export const fetchBookingActivitiesStart = () => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITIES_START
  };
};

export const updateActivityFilters = searchText => {
  return {
    type: actionTypes.UPDATE_BOOKING_ACTIVITIES_FILTERS,
    searchText: searchText
  };
};

export const fetchBookingActivities = (selectedNeighbourhood, searchText) => {
  return async dispatch => {
    dispatch(fetchBookingActivitiesStart());

    const api = await getApi();
    await api
      .get(
        `${bookingsNeighbourhoodActivitiesAPI}?neighbourhoodGuid=${selectedNeighbourhood}&searchText=${searchText}`
      )
      .then(res => {
        dispatch(
          fetchBookingActivitiesSuccess(
            res.data.activities.sort((a, b) => (a.value > b.value ? 1 : -1))
          )
        );
      })
      .catch(err => {
        dispatch(fetchBookingActivitiesFail(err));
      });
    dispatch(updateActivityFilters(searchText));
  };
};

//Schedules Calendar
export const fetchSchedulesCalendarSuccess = schedulesCalendar => {
  return {
    type: actionTypes.FETCH_SCHEDULES_CALENDAR_SUCCESS,
    data: schedulesCalendar
  };
};

export const fetchSchedulesCalendarFail = error => {
  return {
    type: actionTypes.FETCH_SCHEDULES_CALENDAR_FAIL,
    error: error
  };
};

export const fetchSchedulesCalendarStart = () => {
  return {
    type: actionTypes.FETCH_SCHEDULES_CALENDAR_START
  };
};

export const updateSelectedCalendarDateTime = date => {
  return {
    type: actionTypes.UPDATE_SELECTED_CALENDAR_DATE,
    data: date
  };
};

export const fetchSchedulesCalendar = (spaceId, date) => {
  return async dispatch => {
    dispatch(fetchSchedulesCalendarStart());
    let formattedDate = date.toISOString();
    const api = await getApi();
    await api
      .get(`${bookingsNeighbourhoodSpacesAPI}/${spaceId}/schedules?fromDate=${formattedDate}`)
      .then(res => {
        dispatch(fetchSchedulesCalendarSuccess(res.data));
        dispatch(createBookingNeighbourhoodReset());
      })
      .catch(err => {
        dispatch(fetchSchedulesCalendarFail(err));
      });
    dispatch(updateSelectedCalendarDateTime(date));
  };
};
// Add Activity
export const createBookingActivitySuccess = () => {
  return {
    type: actionTypes.CREATE_BOOKING_ACTIVITY_SUCCESS
  };
};

export const createBookingActivityFail = error => {
  return {
    type: actionTypes.CREATE_BOOKING_ACTIVITY_FAIL,
    errorCreateBookingActivity: error
  };
};

export const createBookingActivityStart = () => {
  return {
    type: actionTypes.CREATE_BOOKING_ACTIVITY_START
  };
};

export const createBookingActivityReset = () => {
  return {
    type: actionTypes.CREATE_BOOKING_ACTIVITY_RESET
  };
};

export const createBookingActivity = data => {
  return async dispatch => {
    dispatch(createBookingActivityStart());

    return await getApi()
      .post(bookingsNeighbourhoodActivitiesAPI, data)
      .then(res => {
        dispatch(createBookingActivitySuccess());
      })
      .catch(error => {
        dispatch(createBookingActivityFail(error));
      });
  };
};

//Delete Activity

export const deleteBookingActivitySuccess = () => {
  return {
    type: actionTypes.DELETE_BOOKING_ACTIVITY_SUCCESS
  };
};

export const deleteBookingActivityFail = error => {
  return {
    type: actionTypes.DELETE_BOOKING_ACTIVITY_FAIL,
    error: error
  };
};

export const deleteBookingActivityStart = () => {
  return {
    type: actionTypes.DELETE_BOOKING_ACTIVITY_START
  };
};

export const deleteBookingActivityReset = () => {
  return {
    type: actionTypes.DELETE_BOOKING_ACTIVITY_RESET
  };
};

export const deleteBookingActivity = activityId => {
  return async dispatch => {
    dispatch(deleteBookingActivityStart());
    return await getApi()
      .delete(`${bookingsActivitiesAPI}/${activityId}`)
      .then(res => {
        dispatch(deleteBookingActivitySuccess());
      })
      .catch(err => {
        dispatch(deleteBookingActivityFail(err));
      });
  };
};

///////////// CREATE NEIGHBOURHOOD SPACE/////////////
export const createNeighbourhoodSpaceSuccess = () => {
  return {
    type: actionTypes.CREATE_NEIGHBOURHOOD_SPACE_SUCCESS
  };
};

export const createNeighbourhoodSpaceFail = error => {
  return {
    type: actionTypes.CREATE_NEIGHBOURHOOD_SPACE_FAIL,
    errorCreateNeighbourhoodSpace: error
  };
};

export const createNeighbourhoodSpaceStart = () => {
  return {
    type: actionTypes.CREATE_NEIGHBOURHOOD_SPACE_START
  };
};

export const createNeighbourhoodSpaceReset = () => {
  return {
    type: actionTypes.CREATE_NEIGHBOURHOOD_SPACE_RESET
  };
};

export const createNeighbourhoodSpace = body => {
  return async (dispatch, getState) => {
    dispatch(createNeighbourhoodSpaceStart());
    const api = await getApi();
    return api
      .post(bookingsNeighbourhoodSpacesAPI, body)
      .then(res => {
        dispatch(createNeighbourhoodSpaceSuccess(res.data));
      })
      .catch(error => {
        dispatch(createNeighbourhoodSpaceFail(error));
      });
  };
};

/////// UPDATE NEIGHBOURHOOD SPACE ///////////////////

export const updateNeighbourhoodSpaceSuccess = () => {
  return {
    type: actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_SUCCESS
  };
};

export const updateNeighbourhoodSpaceFail = error => {
  return {
    type: actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_FAIL,
    errorUpdateNeighbourhoodSpace: error
  };
};

export const updateNeighbourhoodSpaceStart = () => {
  return {
    type: actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_START
  };
};

export const updateNeighbourhoodSpaceReset = () => {
  return {
    type: actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_RESET
  };
};

export const updateNeighbourhoodSpace = body => {
  return async (dispatch, getState) => {
    dispatch(updateNeighbourhoodSpaceStart());
    const api = await getApi();
    return api
      .put(`${bookingsNeighbourhoodSpacesAPI}/${body.id}`, body)
      .then(res => {
        dispatch(updateNeighbourhoodSpaceSuccess(res.data));
      })
      .catch(error => {
        dispatch(updateNeighbourhoodSpaceFail(error));
      });
  };
};

///////// FETCH NEIGHBOURHOOD SPACE DETAIL ////////////

export const fetchNeighbourhoodSpaceDetailSuccess = spaces => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_SUCCESS,
    neighbourhoodSpaceDetail: spaces[0]
  };
};

export const fetchNeighbourhoodSpaceDetailFail = error => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_FAIL,
    error: error
  };
};

export const fetchNeighbourhoodSpaceDetailStart = () => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_START
  };
};

export const fetchNeighbourhoodSpaceDetail = spaceId => {
  return async dispatch => {
    dispatch(fetchNeighbourhoodSpaceDetailStart());
    const api = await getApi();
    await api
      .get(`${bookingsNeighbourhoodSpacesAPI}?id=${spaceId}`)
      .then(res => {
        dispatch(fetchNeighbourhoodSpaceDetailSuccess(res.data.spaces));
        if(!res.data.spaces[0].is_blocked)
        dispatch(fetchComboSpacesToBlock(null, res.data.spaces[0].activity_id));
      })
      .catch(err => {
        dispatch(fetchNeighbourhoodSpaceDetailFail(err));
      });
  };
};

/////////////////////DELETE NEIGHBOURHOOD SPACE ///////////////////////
export const deleteNeighbourhoodSpaceSuccess = () => {
  return {
    type: actionTypes.DELETE_NEIGHBOURHOOD_SPACE_SUCCESS
  };
};

export const deleteNeighbourhoodSpaceFail = error => {
  return {
    type: actionTypes.DELETE_NEIGHBOURHOOD_SPACE_FAIL,
    errorDeleteNeighbourhoodSpace: error
  };
};

export const deleteNeighbourhoodSpaceStart = () => {
  return {
    type: actionTypes.DELETE_NEIGHBOURHOOD_SPACE_START
  };
};

export const deleteNeighbourhoodSpaceReset = () => {
  return {
    type: actionTypes.DELETE_NEIGHBOURHOOD_SPACE_RESET
  };
};

export const deleteNeighbourhoodSpace = neighbourhoodSpaceId => {
  return async dispatch => {
    dispatch(deleteNeighbourhoodSpaceStart());
    const api = await getApi();
    api
      .delete(`${bookingsNeighbourhoodSpacesAPI}/${neighbourhoodSpaceId}`)
      .then(res => {
        dispatch(deleteNeighbourhoodSpaceSuccess(res.data));
      })
      .catch(err => {
        dispatch(deleteNeighbourhoodSpaceFail(err));
      });
  };
};

/////////////////////FETCH  BOOKINGS ///////////////////////
export const fetchBookingsSuccess = data => {
  return {
    bookingsData: mapBookings(data),
    type: actionTypes.FETCH_BOOKINGS_SUCCESS
  };
};

export const fetchBookingsFail = error => {
  return {
    type: actionTypes.FETCH_BOOKINGS_FAIL,
    errorFetchBookings: error
  };
};

export const fetchBookingsStart = () => {
  return {
    type: actionTypes.FETCH_BOOKINGS_START
  };
};

export const updateBookingsGrid = (page, pageSize, totalBookings) => {
  return {
    type: actionTypes.UPDATE_BOOKINGS_GRID,
    page: page,
    pageSize: pageSize,
    totalBookings: totalBookings
  };
};

export const clearBookingsGrid = () => {
  return {
    type: actionTypes.CLEAR_BOOKINGS_GRID
  };
};

export const fetchBookings = (
  neighbourhoodGuid,
  activityId,
  spaceId,
  startDate,
  searchText,
  orderField,
  orderDirection,
  page,
  pageSize
) => {
  return async dispatch => {
    dispatch(fetchBookingsStart());
    const api = await getApi();
    api
      .get(
        `${bookingsAPI}/report?neighbourhoodGuid=${neighbourhoodGuid}&spaceId=${spaceId}&activityId=${activityId}&startDate=${startDate}&searchText=${searchText}&orderField=${orderField}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
      )
      .then(res => {
        dispatch(fetchBookingsSuccess(res.data.bookings));
        dispatch(updateBookingsGrid(page, pageSize, res.data.total_rows));
      })
      .catch(err => {
        dispatch(fetchBookingsFail(err));
        dispatch(updateBookingsGrid(0, pageSize, 0));
      });
  };
};


/////////////////////FETCH  EXPORT BOOKINGS ///////////////////////
export const fetchExportBookingsSuccess = data => {
  return {
    exportBookingsData: mapBookings(data),
    type: actionTypes.FETCH_EXPORT_BOOKINGS_SUCCESS
  };
};

export const fetchExportBookingsFail = error => {
  return {
    type: actionTypes.FETCH_EXPORT_BOOKINGS_FAIL,
    errorFetchExportBookings: error
  };
};

export const fetchExportBookingsStart = () => {
  return {
    type: actionTypes.FETCH_EXPORT_BOOKINGS_START
  };
};

export const fetchExportBookingsReset = () => {
  return {
    type: actionTypes.FETCH_EXPORT_BOOKINGS_RESET
  };
};

export const fetchExportBookings = (
  neighbourhoodGuid,
  activityId,
  spaceId,
  startDate,
  searchText,
  orderField,
  orderDirection
) => {
  return async dispatch => {
    dispatch(fetchExportBookingsStart());
    const api = await getApi();
    api
      .get(
        `${bookingsAPI}/report?neighbourhoodGuid=${neighbourhoodGuid}&spaceId=${spaceId}&activityId=${activityId}&startDate=${startDate}&searchText=${searchText}&orderField=${orderField}&orderDirection=${orderDirection}&page=&pageSize=`
      )
      .then(res => {
        dispatch(fetchExportBookingsSuccess(res.data.bookings));
      })
      .catch(err => {
        dispatch(fetchExportBookingsFail(err));
      });
  };
};

//#region CANCEL BOOKING
export const cancelBookingSuccess = () => {
  return {
    type: actionTypes.CANCEL_BOOKING_SUCCESS
  };
};

export const cancelBookingReset = () => {
  return {
    type: actionTypes.CANCEL_BOOKING_RESET
  };
};

export const cancelBookingFail = error => {
  return {
    type: actionTypes.CANCEL_BOOKING_FAIL,
    errorCancelBooking: error
  };
};

export const cancelBookingStart = () => {
  return {
    type: actionTypes.CANCEL_BOOKING_START
  };
};

export const cancelBooking = id => {
  return async (dispatch, getState) => {
    dispatch(cancelBookingStart());
    var guid = getState().bookings.selectedNeighbourhood?.value;
    const api = await getApi(guid);
    return (
      api
        // .put(bookingsAPI, body)
        .put(`${bookingsAPI}/${id}/cancel`)
        .then(res => {
          dispatch(cancelBookingSuccess(res.data));
        })
        .catch(error => {
          console.log("Hubo un error: ", error);
          dispatch(cancelBookingFail(error.response.data.error_messages[0]));
        })
    );
  };
};
//#endregion

// Update Activity

export const updateBookingActivitySuccess = () => {
  return {
    type: actionTypes.UPDATE_BOOKING_ACTIVITY_SUCCESS
  };
};

export const updateBookingActivityFail = error => {
  return {
    type: actionTypes.UPDATE_BOOKING_ACTIVITY_FAIL,
    errorUpdateBookingActivity: error
  };
};

export const updateBookingActivityStart = () => {
  return {
    type: actionTypes.UPDATE_BOOKING_ACTIVITY_START
  };
};

export const updateBookingActivityReset = () => {
  return {
    type: actionTypes.UPDATE_BOOKING_ACTIVITY_RESET
  };
};

export const updateBookingActivity = data => {
  return async (dispatch, getState) => {
    dispatch(updateBookingActivityStart());
    const api = await getApi();
    return api
      .put(`${bookingsNeighbourhoodActivitiesAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updateBookingActivitySuccess(res.data));
      })
      .catch(error => {
        dispatch(updateBookingActivityFail(error));
      });
  };
};

// Fetch Activity
export const fetchBookingActivitySuccess = neighbourhoodActivities => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITY_SUCCESS,
    neighbourhoodActivities: neighbourhoodActivities
  };
};

export const fetchBookingActivityFail = error => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITY_FAIL,
    errorFetchBookingActivity: error
  };
};

export const fetchBookingActivityStart = () => {
  return {
    type: actionTypes.FETCH_BOOKING_ACTIVITY_START
  };
};

export const fetchBookingActivity = activityId => {
  return async dispatch => {
    dispatch(fetchBookingActivityStart());
    const api = await getApi()
      .get(`${bookingsNeighbourhoodActivitiesAPI}?id=${activityId}`)
      .then(res => {
        dispatch(fetchBookingActivitySuccess(res.data.activities));
      })
      .catch(err => {
        dispatch(fetchBookingActivityFail(err));
      });
  };
};

//Create Schedule

export const createScheduleSuccess = () => {
  return {
    type: actionTypes.CREATE_SCHEDULE_SUCCESS
  };
};

export const createScheduleFail = error => {
  return {
    type: actionTypes.CREATE_SCHEDULE_FAIL,
    errorCreateSchedule: error
  };
};

export const createScheduleStart = () => {
  return {
    type: actionTypes.CREATE_SCHEDULE_START
  };
};

export const createScheduleReset = () => {
  return {
    type: actionTypes.CREATE_SCHEDULE_RESET
  };
};

export const createSchedule = body => {
  return async (dispatch, getState) => {
    dispatch(createScheduleStart());
    const api = await getApi();
    return api
      .post(schedulesAPI, body)
      .then(res => {
        dispatch(createScheduleSuccess(res.data));
      })
      .catch(error => {
        dispatch(createScheduleFail(error.response.data.error_messages[0]));
      });
  };
};

//#region BOOKING NEIGHBOURHOOD

//#region ADD BOOKING NEIGHBOURHOOD
export const createBookingNeighbourhoodSuccess = () => {
  return {
    type: actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_SUCCESS
  };
};

export const createBookingNeighbourhoodReset = () => {
  return {
    type: actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_RESET
  };
};

export const createBookingNeighbourhoodFail = error => {
  return {
    type: actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_FAIL,
    errorcreateBookingNeighbourhood: error
  };
};

export const createBookingNeighbourhoodStart = () => {
  return {
    type: actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_START
  };
};

export const createBookingNeighbourhood = body => {
  return async (dispatch, getState) => {
    var guid = getState().bookings.selectedNeighbourhood?.value;
    dispatch(createBookingNeighbourhoodStart());
    const api = await getApi(guid);
    return api
      .post(bookingsNeighbourhoodAPI, body)
      .then(res => {
        dispatch(createBookingNeighbourhoodSuccess(res.data));
      })
      .catch(error => {
        console.log(error);
        dispatch(createBookingNeighbourhoodFail(error.response.data.error_messages[0]));
      });
  };
};
//#endregion
//#endregion

///////// FETCH SCHEDULE DETAIL ////////////

export const fetchScheduleDetailSuccess = schedules => {
  return {
    type: actionTypes.FETCH_SCHEDULE_DETAIL_SUCCESS,
    scheduleDetail: mapSchedules(schedules)[0]
  };
};

export const fetchScheduleDetailFail = error => {
  return {
    type: actionTypes.FETCH_SCHEDULE_DETAIL_FAIL
  };
};

export const fetchScheduleDetailStart = () => {
  return {
    type: actionTypes.FETCH_SCHEDULE_DETAIL_START
  };
};

export const fetchScheduleDetailReset = () => {
  return {
    type: actionTypes.FETCH_SCHEDULE_DETAIL_RESET
  };
};

export const fetchScheduleDetail = scheduleId => {
  return async dispatch => {
    dispatch(fetchScheduleDetailStart());
    const api = await getApi();
    await api
      .get(`${schedulesAPI}/${scheduleId}`)
      .then(res => {
        dispatch(fetchScheduleDetailSuccess(res.data.schedules));
      })
      .catch(err => {
        dispatch(fetchScheduleDetailFail(err));
      });
  };
};

/////// UPDATE  ///////////////////

export const updateScheduleSuccess = () => {
  return {
    type: actionTypes.UPDATE_SCHEDULE_SUCCESS
  };
};

export const updateScheduleFail = error => {
  return {
    type: actionTypes.UPDATE_SCHEDULE_FAIL,
    errorUpdateSchedule: error
  };
};

export const updateScheduleStart = () => {
  return {
    type: actionTypes.UPDATE_SCHEDULE_START
  };
};

export const updateScheduleReset = () => {
  return {
    type: actionTypes.UPDATE_SCHEDULE_RESET
  };
};

export const updateSchedule = (id, body) => {
  return async (dispatch, getState) => {
    dispatch(updateScheduleStart());
    const api = await getApi();
    return api
      .put(`${schedulesAPI}/${id}`, body)
      .then(res => {
        dispatch(updateScheduleSuccess(res.data));
      })
      .catch(error => {
        dispatch(updateScheduleFail(error.response.data.error_messages[0]));
      });
  };
};
//Create Términos y condiciones
export const createTyCSuccess = () => {
  return {
    type: actionTypes.CREATE_TYC_SUCCESS
  };
};

export const createTyCFail = error => {
  return {
    type: actionTypes.CREATE_TYC_FAIL,
    errorCreateTyC: error
  };
};

export const createTyCStart = () => {
  return {
    type: actionTypes.CREATE_TYC_START
  };
};

export const createTyCReset = () => {
  return {
    type: actionTypes.CREATE_TYC_RESET
  };
};

export const createTyC = (id, data) => {
  return async dispatch => {
    dispatch(createTyCStart());
    const api = await getApi()
      .put(`${bookingsNeighbourhoodActivitiesAPI}/${id}/tyc`, data)
      .then(res => {
        dispatch(createTyCSuccess(res.data));
      })
      .catch(err => {
        dispatch(createTyCFail(err));
      });
  };
};

//Fetch Rules
export const fetchRulesSuccess = rules => {
  return {
    type: actionTypes.FETCH_RULES_SUCCESS,
    rules: rules
  };
};

export const fetchRulesFail = error => {
  return {
    type: actionTypes.FETCH_RULES_FAIL,
    errorFetchRules: error
  };
};

export const fetchRulesStart = () => {
  return {
    type: actionTypes.FETCH_RULES_START
  };
};

export const fetchRulesReset = () => {
  return {
    type: actionTypes.FETCH_RULES_RESET
  };
};

export const fetchRules = id => {
  return async dispatch => {
    dispatch(fetchRulesStart());
    const api = await getApi()
      .get(`${bookingsNeighbourhoodActivitiesAPI}/${id}/rules`)
      .then(res => {
        dispatch(fetchRulesSuccess(res.data.rules));
      })
      .catch(err => {
        dispatch(fetchRulesFail(err));
      });
  };
};

// Fetch Rule Value
export const fetchRuleValuesSuccess = ruleValues => {
  return {
    type: actionTypes.FETCH_RULE_VALUES_SUCCESS,
    ruleValues: ruleValues
  };
};

export const fetchRuleValuesFail = error => {
  return {
    type: actionTypes.FETCH_RULE_VALUES_FAIL,
    errorFetchRuleValues: error
  };
};

export const fetchRuleValuesStart = () => {
  return {
    type: actionTypes.FETCH_RULE_VALUES_START
  };
};

export const fetchRuleValuesReset = () => {
  return {
    type: actionTypes.FETCH_RULE_VALUES_RESET
  };
};

export const fetchRuleValues = activityRuleId => {
  return async (dispatch, getState) => {
    dispatch(fetchRuleValuesStart());
    const api = await getApi()
      .get(`${bookingsActivityRulesAPI}/${activityRuleId}/activity_rule_values`)
      .then(res => {
        dispatch(fetchRuleValuesSuccess(res.data.rule_values));
      })
      .catch(err => {
        dispatch(fetchRuleValuesFail(err));
      });
  };
};

// Update Rule Value

export const updateRuleValueSuccess = () => {
  return {
    type: actionTypes.UPDATE_RULE_VALUE_SUCCESS
  };
};

export const updateCurrentRuleValue = (activityRuleValueId, value) => {
  return {
    type: actionTypes.UPDATE_CURRENT_RULE_VALUE,
    activityRuleValueId: activityRuleValueId,
    value: value
  };
};

export const updateRuleValueFail = error => {
  return {
    type: actionTypes.UPDATE_RULE_VALUE_FAIL,
    responseMessage: error
  };
};

export const updateRuleValueStart = () => {
  return {
    type: actionTypes.UPDATE_RULE_VALUE_START
  };
};

export const updateRuleValueReset = () => {
  return {
    type: actionTypes.UPDATE_RULE_VALUE_RESET
  };
};

export const updateRuleValue = (activityRuleValueId, body) => {
  return async (dispatch, getState) => {
    dispatch(updateRuleValueStart());
    const api = await getApi();
    return api
      .put(
        `${ActivityRuleValuesAPI}/${activityRuleValueId}`, body
      )
      .then(res => {
        dispatch(updateCurrentRuleValue(activityRuleValueId, body.value))
        dispatch(updateRuleValueSuccess(res.data));
      })
      .catch(error => {
        dispatch(updateRuleValueFail(error.response.data.error_messages ? error.response.data.error_messages[0] : 'Hubo un error al actualizar el valor'));
      });
  };
};

//Delete Rule Value
export const deleteRuleValueSuccess = () => {
  return {
    type: actionTypes.DELETE_RULE_VALUE_SUCCESS
  };
};

export const deleteRuleValueFail = error => {
  return {
    type: actionTypes.DELETE_RULE_VALUE_FAIL,
    error: error
  };
};

export const deleteRuleValueStart = () => {
  return {
    type: actionTypes.DELETE_RULE_VALUE_START
  };
};

export const deleteRuleValueReset = () => {
  return {
    type: actionTypes.DELETE_RULE_VALUE_RESET
  };
};

export const deleteRuleValue = id => {
  return async dispatch => {
    dispatch(deleteRuleValueStart());
    return await getApi()
      .delete(`${bookingsActivityRulesAPI}/${id}`)
      .then(res => {
        dispatch(deleteRuleValueSuccess(res.data));
      })
      .catch(err => {
        dispatch(deleteRuleValueFail(err));
      });
  };
};

// Add Rule Value
export const createRuleValuesSuccess = () => {
  return {
    type: actionTypes.CREATE_RULE_VALUES_SUCCESS
  };
};

export const createRuleValuesFail = error => {
  return {
    type: actionTypes.CREATE_RULE_VALUES_FAIL,
    errorCreateRuleValues: error
  };
};

export const createRuleValuesStart = () => {
  return {
    type: actionTypes.CREATE_RULE_VALUES_START
  };
};

export const createRuleValuesReset = () => {
  return {
    type: actionTypes.CREATE_RULE_VALUES_RESET
  };
};

export const createRuleValues = body => {
  return async dispatch => {
    dispatch(createRuleValuesStart());

    return await getApi()
      .post(bookingsActivityRulesAPI, body)
      .then(res => {
        dispatch(createRuleValuesSuccess(res.data));
      })
      .catch(error => {
        dispatch(createRuleValuesFail(error));
      });
  };
};

//schedule bookings

export const fetchScheduleBookingsSuccess = bookings => {
  return {
    type: actionTypes.FETCH_SCHEDULES_BOOKINGS_SUCCESS,
    data: bookings
  };
};

export const fetchScheduleBookingsFail = error => {
  return {
    type: actionTypes.FETCH_SCHEDULES_BOOKINGS_FAIL,
    error: error
  };
};

export const fetchScheduleBookingsStart = () => {
  return {
    type: actionTypes.FETCH_SCHEDULES_BOOKINGS_START
  };
};

export const fetchScheduleBookings = (scheduleId, date) => {
  return async dispatch => {
    dispatch(fetchScheduleBookingsStart());
    const parsedDate = new Date(date);
    let formattedDate = parsedDate.toISOString();
    const api = await getApi();
    await api
      .get(`${bookingsAPI}?scheduleId=${scheduleId}&date=${formattedDate}`)
      .then(res => {
        dispatch(fetchScheduleBookingsSuccess(res.data.bookings));
      })
      .catch(err => {
        dispatch(fetchScheduleBookingsFail(err));
      });
  };
};

export const setSelectedNeighbourhood = selectedNeighbourhood => {
  return {
    type: actionTypes.SET_SELECTED_NEIGHBOURHOOD,
    selectedNeighbourhood: selectedNeighbourhood
  };
};
