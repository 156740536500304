
import React from 'react';
import Select from "react-select";
import styled from 'styled-components';
import theme from "../config/theme";   

const dropDownPrymaryColor = theme.colors.ghost;
const dropDownPrymary25Color = theme.colors.activeLabel;
const dropDownFontColor = theme.colors.black; 

const StyledSelect = props => { 
    return (
        <Select
        {...props}
        theme={theme => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary25: `${dropDownPrymaryColor}`,
            primary: `${dropDownPrymary25Color}`,
            fontColor: `${dropDownFontColor}`
          },
        })}
      />
    );
  }

  export default StyledSelect