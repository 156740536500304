import React from 'react'
import NoFile from '../../assets/icons/plus-image.png'
import CheckedImg from '../../assets/icons/check.png'
import inputTypes from '../../constants/inputTypes'
import { readImages } from '../../utils/fileManager'
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  StyledFileInput,
  FileInputContainer,
  DeleteButton,
  TooLongMessage
} from './styled'

class FileImageInput extends React.Component {
  state = {
    url: this.props.value,
    isTooLong:false,
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.value })
  }

  hasAttachedContent = () => this.state.url

  validateFile = (long) => {
    if(long>10485760){
        return 0;
    }else{
        return 1;
    }
  }
  
  fileSelectedHandler = async event => {
    this.setState({isTooLong: false})
    const attachFile = await readImages(event)
    if(this.validateFile(attachFile.ImageBase64.length)){
    if (this.props.onChange) this.props.onChange(attachFile, false)
    this.setState({ ...attachFile })
    }else{
      this.setState({
        url: null,
        isTooLong: true,
        })
    }
  }

  deleteImage = () => {
    if (this.props.onChange) this.props.onChange(this.state.url, true)
    this.setState({
      url: null,
      isTooLong: false,
      })
  }

  render() {
    const { onChange, gridArea, width, padding } = this.props;
    return (
      <FileInputContainer>
        {this.props.canBeImageDeleted && this.hasAttachedContent() && <DeleteButton onClick={() => this.deleteImage()}>x</DeleteButton>}
        <FileInputWrapper width={width} padding={padding} gridArea={gridArea ? gridArea : ""}>
          {onChange && (
            !this.props.disabled ? <StyledFileInput accept="image/x-png,image/gif,image/jpeg" type={inputTypes.FILE} onChange={this.fileSelectedHandler} /> : null
          )}
          <AttachedContainer>
            {this.hasAttachedContent() && <CheckedElement src={CheckedImg} />}
            <DocumentImg src={this.hasAttachedContent() || NoFile} />
          </AttachedContainer>
        </FileInputWrapper>
        <TooLongMessage visibility={(this.state.isTooLong?"visible":"hidden")}>El archivo es demasiado pesado</TooLongMessage>
      </FileInputContainer>
    )
  }
}

export default FileImageInput
