/* eslint-disable react/prop-types */
import RitzTable from "../../../components/RitzTable";

import SectionHeader from "../../../components/SectionHeader";

import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { } from "../../../store/actions/paymentClients.actions";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ModalMessage from "../../../components/ModalMessage";
import ModalDelete from "../../../components/ModalDelete";
import VendorModal from "./VendorModal";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import StyledSelect from "../../../components/StyledSelect";
import { LoaderWrapper, InputWrapper, InputLabel, SelectWrapper, TableWrapper } from "./styled";

import {
  fetchPaymentClients,
  fetchVendorsByClient,
  fetchVendorsByShopGuid,
  fetchVendorsReset,
  fetchVendorDetail,
  fetchVendorDetailReset,
  fetchShops,
  createVendor,
  createVendorReset,
  updateVendor,
  updateVendorReset,
  deleteVendor,
  deleteVendorReset,
  fetchBankAccountsReset,
  fetchMerchantTypes
} from "../../../store/actions/paymentClients.actions";

const mapStateToProps = state => {
  const { user } = state.auth;
  const {
    paymentClients,
    loadingPaymentClients,
    vendors,
    loadingFetchVendors,
    vendorDetail,
    loadingFetchVendorDetail,
    createVendorSuccess,
    errorCreateVendor,
    loadingCreateVendor,
    updateVendorSuccess,
    loadingUpdateVendor,
    errorUpdateVendor,
    deleteVendorSuccess,
    errorDeleteVendor,
    loadingDeleteVendor,
    bankAccounts,
    loadingFetchBankAccounts,
    shops,
    loadingFetchShops,
    loadingFetchMerchantTypes,
    merchantTypes
  } = state.paymentClients;

  return {
    paymentClients: paymentClients,
    loadingPaymentClients: loadingPaymentClients,
    vendors: vendors,
    loadingFetchVendors: loadingFetchVendors,
    vendorDetail: vendorDetail,
    loadingFetchVendorDetail: loadingFetchVendorDetail,
    createVendorSuccess: createVendorSuccess,
    loadingCreateVendor: loadingCreateVendor,
    errorCreateVendor: errorCreateVendor,
    updateVendorSuccess: updateVendorSuccess,
    loadingUpdateVendor: loadingUpdateVendor,
    errorUpdateVendor: errorUpdateVendor,
    deleteVendorSuccess: deleteVendorSuccess,
    errorDeleteVendor: errorDeleteVendor,
    loadingDeleteVendor: loadingDeleteVendor,
    bankAccounts: bankAccounts,
    loadingFetchBankAccounts: loadingFetchBankAccounts,
    shops: shops,
    loadingFetchShops: loadingFetchShops,
    neighbourhoods: user.neighbourhoods,
    loadingFetchMerchantTypes: loadingFetchMerchantTypes,
    merchantTypes: merchantTypes
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPaymentClients,
      fetchShops,
      fetchVendorsByClient,
      fetchVendorsByShopGuid,
      fetchVendorsReset,
      fetchVendorDetail,
      fetchVendorDetailReset,
      createVendor,
      createVendorReset,
      updateVendor,
      updateVendorReset,
      deleteVendor,
      deleteVendorReset,
      fetchBankAccountsReset,
      fetchMerchantTypes
    },
    dispatch
  );

function Vendors(props) {
  const {
    isPaymentAdmin,
    paymentClients,
    loadingPaymentClients,
    vendors,
    loadingFetchVendors,
    vendorDetail,
    loadingFetchVendorDetail,
    createVendorSuccess,
    loadingCreateVendor,
    errorCreateVendor,
    updateVendorSuccess,
    loadingUpdateVendor,
    errorUpdateVendor,
    deleteVendorSuccess,
    errorDeleteVendor,
    loadingDeleteVendor,
    bankAccounts,
    shops,
    loadingFetchShops,
    loadingFetchBankAccounts,
    neighbourhoods,
    loadingFetchMerchantTypes,
    merchantTypes
  } = props;

  const columns = [
    {
      title: "Nombre",
      field: "name",
      cellStyle: {
        width: isPaymentAdmin ? "30%" : "40%",
        textAlign: "left"
      },
      headerStyle: {
        width: isPaymentAdmin ? "30%" : "40%",
        textAlign: "left"
      }
    },
    {
      title: "Descripción",
      field: "description",
      cellStyle: {
        width: isPaymentAdmin ? "40%" : "50%",
        textAlign: "left"
      },
      headerStyle: {
        width: isPaymentAdmin ? "40%" : "50%",
        textAlign: "left"
      }
    },
    {
      title: "Código externo",
      field: "external_code",
      cellStyle: {
        width: isPaymentAdmin ? "40%" : "50%",
        textAlign: "left"
      },
      headerStyle: {
        width: isPaymentAdmin ? "40%" : "50%",
        textAlign: "left"
      }
    }
  ];

  if (isPaymentAdmin)
    columns.unshift({
      title: "Cliente",
      field: "clientName",
      cellStyle: {
        width: "30%",
        textAlign: "left"
      },
      headerStyle: {
        width: "30%",
        textAlign: "left"
      }
    });

  const [selectedPaymentClient, setSelectedPaymentClient] = useState({ label: "", value: 0 });
  const [addVendor, setAaddVendor] = useState(false);
  const [editVendor, setEditVendor] = useState(false);
  const [deleteVendor, setDeleteVendor] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const getPaymentClients = () => {
    if (paymentClients != null && paymentClients.length > 0)
      return paymentClients
        .map(c => ({ label: c.name, value: c.id }))
        .sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  let clients = getPaymentClients();

  const onPaymentClientChange = selectedValue => {
    setSelectedPaymentClient(selectedValue);
    props.fetchVendorsByClient(selectedValue.value);
  };

  const toggleAddVendor = async () => {
    setAaddVendor(!addVendor);
    if (!addVendor) {
      props.createVendorReset();
      props.fetchShops();
      props.fetchMerchantTypes();
    }
  };

  const toggleShowDetail = async selectedId => {
    setShowDetail(!showDetail);
    if (!showDetail) {
      props.fetchVendorDetailReset();
      props.fetchVendorDetail(selectedId);
      props.fetchMerchantTypes();
      await props.fetchMerchantTypes();
    }
  };
  const toggleEditVendor = async selectedId => {
    setEditVendor(!editVendor);
    props.updateVendorReset();
    await props.fetchMerchantTypes();
    if (!editVendor) {
      props.fetchVendorDetailReset();
      props.fetchVendorDetail(
        selectedId,
        paymentClients.filter(f => f.id == selectedPaymentClient.value)[0].neighbourhoodGuid
        );
        props.fetchMerchantTypes();
    }
  };

  const toggleDeleteVendor = async selectedId => {
    setDeleteVendor(!deleteVendor);
    setSelectedId(selectedId);
    props.deleteVendorReset();
  };

  const onCreateVendorSubmit = async data => {
    let formattedData = {
      id: data.id,
      description: data.description,
      name: data.name,
      external_code: data.external_code,
      shop: data.shop,
      bank_account_id: data.bank_account_id,
      client: data.client,
      merchant_number_id: data.merchant_type && data.merchant_type > 0 ? data.merchant_type : null
    };
    await props.createVendor(formattedData);
  };

  const onUpdateVendorSubmit = async data => {
    let formattedData = {
      id: data.id,
      description: data.description,
      name: data.name,
      external_code: data.external_code,
      shop: data.shop,
      bank_account_id: data.bank_account_id,
      client: data.client,
      merchant_number_id: data.merchant_type && data.merchant_type > 0 ? data.merchant_type : null
    };
    await props.updateVendor(formattedData);
  };

  const onDeleteVendor = async () => {
    props.deleteVendor(selectedId);
  };

  const renderModalMessageSuccess = (message, callback) => (
    <ModalMessage success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalMessageFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const addModal = (() => {
    if (createVendorSuccess) {
      return renderModalMessageSuccess("Se ha creado el canal correctamente.", toggleAddVendor);
    } else if (errorCreateVendor) {
      return renderModalMessageFail("Hubo un error al intentar crear el canal.", toggleAddVendor);
    } else if (addVendor) {
      return (
        <VendorModal
          title="Agregar Canal"
          isReadOnly={false}
          paymentClientGuid={neighbourhoods[0].guid}
          onClose={toggleAddVendor}
          isSubmitting={loadingCreateVendor || loadingFetchMerchantTypes}
          isPaymentAdmin={isPaymentAdmin}
          paymentClients={paymentClients}
          onSubmit={onCreateVendorSubmit}
          isLoading={loadingFetchShops}
          selectedPaymentClient={selectedPaymentClient}
          setSelectedPaymentClient={setSelectedPaymentClient}
          clients={clients}
          vendors={shops}
          merchantTypes={merchantTypes}
        />
      );
    }
  })();

  const editModal = (() => {
    if (updateVendorSuccess) {
      return renderModalMessageSuccess(
        "Se ha modificado el canal correctamente.",
        toggleEditVendor
      );
    } else if (errorUpdateVendor) {
      return renderModalMessageFail("Hubo un error al intentar editar el canal.", toggleEditVendor);
    } else if (editVendor) {
      return (
        <VendorModal
          title="Editar canal"
          isReadOnly={false}
          isPaymentAdmin={isPaymentAdmin}
          onClose={toggleEditVendor}
          vendorDetail={vendorDetail}
          paymentClients={paymentClients}
          bankAccounts={bankAccounts}
          isLoading={loadingFetchVendorDetail || loadingFetchShops || loadingFetchMerchantTypes}
          isSubmitting={loadingUpdateVendor}
          onSubmit={onUpdateVendorSubmit}
          selectedPaymentClient={selectedPaymentClient}
          setSelectedPaymentClient={setSelectedPaymentClient}
          clients={clients}
          vendors={shops}
          merchantTypes={merchantTypes}
        />
      );
    }
  })();

  const deleteModal = (() => {
    if (deleteVendorSuccess) {
      return renderModalMessageSuccess(
        "Se ha eliminado el canal correctamente.",
        toggleDeleteVendor
      );
    } else if (errorDeleteVendor) {
      return renderModalMessageFail(
        "Hubo un error al intentar eliminar el canal",
        toggleDeleteVendor
      );
    }
    if (deleteVendor) {
      return (
        <ModalDelete
          title="Eliminar canal"
          subTitle="¿Estás seguro que querés eliminarlo?"
          buttonMessage="Eliminar canal"
          successMessage="¡Se ha eliminado la canal!"
          isLoading={loadingDeleteVendor}
          onClose={toggleDeleteVendor}
          success={false}
          onSubmit={onDeleteVendor}
        ></ModalDelete>
      );
    }
  })();

  const detailModal = (() => {
    if (showDetail) {
      return (
        <VendorModal
          title="Detalle de canal"
          isReadOnly={true}
          isPaymentAdmin={isPaymentAdmin}
          onClose={toggleShowDetail}
          vendorDetail={vendorDetail}
          paymentClients={paymentClients}
          bankAccounts={bankAccounts}
          isLoading={loadingFetchVendorDetail || loadingFetchBankAccounts || loadingFetchShops}
          selectedPaymentClient={selectedPaymentClient}
          setSelectedPaymentClient={setSelectedPaymentClient}
          clients={clients}
          vendors={shops}
        />
      );
    }
  })();

  useEffect(() => {
    props.fetchPaymentClients(0, null, 'name', 'asc', '', false, null, isPaymentAdmin,neighbourhoods);
    return function cleanup() {
      props.fetchVendorsReset();
    };
  }, []);

  useEffect(() => {
    if (!showDetail && !addVendor && !editVendor && !deleteVendor) {
      props.fetchBankAccountsReset();
      if (selectedPaymentClient.value != 0) props.fetchVendorsByClient(selectedPaymentClient.value);
    }
  }, [showDetail, addVendor, editVendor, deleteVendor]);

  useEffect(() => {
    if (!isPaymentAdmin && paymentClients != null && paymentClients.length == 1) {
      setSelectedPaymentClient({ value: paymentClients[0].id, label: paymentClients[0].name });
    }
  }, [paymentClients]);

  return (<>
    <SectionHeader
      title="Canales"
      width="100%"
      justifyContent={"space-between"}
    >
      { (
        <InputWrapper>
          <InputLabel>{"Cliente"}</InputLabel>
          <SelectWrapper>
            <StyledSelect
              value={selectedPaymentClient.value!=0?selectedPaymentClient:undefined}
              onChange={value => onPaymentClientChange(value)}
              options={clients}
              placeholder="Seleccione un cliente"
              isLoading={loadingPaymentClients}
            />
          </SelectWrapper>
        </InputWrapper>
      )}
      <Button loading={loadingPaymentClients || loadingFetchVendors} onClick={() => toggleAddVendor()} width={"180px"}>
        Nuevo canal
      </Button>
    </SectionHeader>
    <TableWrapper>
      <RitzTable
        columns={columns}
        title=""
        isLoading={loadingFetchVendors || loadingPaymentClients}
        options={{
          toolbar: false,
          paging: true,
          serverSide: false,
        }}
        actions={[
          {
            icon: VisibilityOutlinedIcon,
            tooltip: 'Ver detalle',
            isFreeAction: false,
            onClick: (event, rowData) => toggleShowDetail(rowData.id)
          },
          {
            icon: EditIcon,
            tooltip: 'Editar',
            isFreeAction: false,
            onClick: (event, rowData) => { toggleEditVendor(rowData.id) }
          }
          ,
          {
            icon: DeleteIcon,
            tooltip: 'Borrar',
            isFreeAction: false,
            onClick: (event, rowData) => { toggleDeleteVendor(rowData.id) }
          }
        ]}
        data={vendors}
      />

      {addModal}
      {editModal}
      {detailModal}
      {deleteModal}

    </TableWrapper>

  </>);
};

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
