import { createSelector } from 'reselect'
var moment = require('moment');

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapExportInvitation = createSelector(  
  Invitation => Invitation,
  Invitation => 
  (Invitation.map(invitation => ({
    to : moment(invitation.to).format('DD/MM/YYYY') ,
    host_full_name : invitation.host_full_name,
    host_username : invitation.host_username,
    host_phone_number : invitation.host_phone_number
  })) )
)
export default mapExportInvitation