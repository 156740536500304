import styled from 'styled-components';
import Select from 'react-select';

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 1;
`
export const NewsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`
export const NewsList = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 270px;
  background: #FFFFFF;
  height: 100%;
  overflow: auto;
`
export const NewsFrame = styled.div`
  width: 100%;
  padding: 15px 5%;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  overflow-y: auto;
`
export const NewsHead = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`
export const NewsBody = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    line-height: 1.44;
    color: #4a4a4a;
  }
`
export const Article = styled.div`
  display: flex;
  white-space: nowrap;
  span {
    font-weight: 500; 
    margin-right: 5px;
  }
`
export const Actions = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 10px;
  }
`
export const Date = styled.span`
  font-weight: 500;
  color: #ff2424;
  line-height: 23px;
`
export const Title = styled.span`
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
`


// NewPost

export const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #05112c99;
`
export const NewPostContainer = styled.div`
  position: relative;
  z-index: 3;
  height: 720px;
  width: 900px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  background-color: #ffffff;
  padding: 3% 6%;
  ${({ newmessage }) => newmessage && `
    height: 490px!important
  `}
`
export const Body = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
`
export const RowForm = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  & > div {
    margin-right: 20px;
  }
  & > div:last-child {
    margin-right: 0px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  ${({ flexend }) => flexend && `
    justify-content: flex-end;
  `}
  ${({ flex }) => flex && `
    flex: ${flex};
  `}
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flex }) => flex && `
    flex: ${flex};
  `}
  & #image-label {
    margin-bottom: 20px;
  }
  input[type=text] {
    min-width: unset;
  }
`
export const Label = styled.label`
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 5px;
`
export const Close = styled.img`
  width: 21px;
  height: 21px;
  position: absolute;
  cursor: pointer;
  right: 6%;
`
export const TextArea = styled.textarea`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  background-color: #ffffff;
  border: solid 1px hsl(0,0%,80%);
  resize: none;
  padding: 5px 10px;
  height: 100%;
`
export const StyledSelect = styled(Select)` 
  & > div > div:nth-child(2) {
    background-color: rgba(244, 247, 249, 0.4);
  }
  & span {
    margin: 0px;
  }
`
export const SelectWrapper = styled.div`  
  & > div > div:first-child {
    height: 40px;
  }
`
export const ImagePlaceholder = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
`
export const ImageGroup = styled.div`
  display: flex;
  & > img {
    margin-right: 20px;
  }
  & > img:last-child {
    margin-right: 0px;
  }
`
export const SelectedImage = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
`
export const MainImage = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
`