import React from 'react';
import styled from 'styled-components';
import Close from '../assets/icons/close.png';

const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
`
const SideModalContainer = styled.div`
  z-index: 3;
  height: 100%;
  width: 420px;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`
const ModalHeader = styled.div`
  padding: 20px;
  padding-left:30px;
  font-size: 22px;
  min-height: 15%;
  font-weight: bold;
  background-color: ${({ headerBackgroundColor }) => headerBackgroundColor || '#001441'};
  position: relative;
  color: #ffffff;
  display: flex;
  align-items: center; 
`
const CloseModal = styled.div`
  position: absolute;
  top: calc(50% - 20px);
  background-color: ${({ headerBackgroundColor }) => headerBackgroundColor || '#001441'};
  padding: 10px 15px;
  border-radius: 100% 0px 0px 100%;
  width: 40px;
  display: flex;
  left: -40px;
  box-sizing: border-box;
  cursor: pointer;
`
const ModalBody = styled.div`
  padding: 23px;
  flex: 1;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  overflow-y: auto;
`
const CloseIcon = styled.img`
  width: 21px;
  height: 21px;
`
class SideWindow extends React.Component {
  render() {
    return (
      <GrayedArea>
        <SideModalContainer>
          <ModalHeader headerBackgroundColor={this.props.headerBackgroundColor} ><CloseModal headerBackgroundColor={this.props.headerBackgroundColor} onClick={() => this.props.onClose()}><CloseIcon src={Close} /></CloseModal>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
        </SideModalContainer>
      </GrayedArea>
    );
  }
}

export default SideWindow

