import React from 'react';
import styled from 'styled-components';




// const ButtonWrapper = styled.button`
//   min-width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   border: solid 1px #979797;
//   cursor: pointer;
// `
const ButtonWrapper = styled.button`
    background-color: ${({ theme }) => theme.colors.white };
    box-shadow: rgb(0 0 0 / 0.25) 0px 4px 11px -1px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border:none;
    margin: 0 10px; 
    align-text:center;
    display:flex;
    align-items: center;
`
const CircularButton = (props) => {
  return (
    <ButtonWrapper onClick={() => props.onClick()}>
      {props.children}
    </ButtonWrapper>
  );
}

export default CircularButton

