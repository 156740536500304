import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
//import PaperLayout from '../../../components/PaperLayout'
import ModalCommunity from '../../Components/ModalCommunity/ModalCommunity'
import ImgContainer from '../../../../components/Modal'
import image from '../../../../assets/icons/phprofile.jpg'
import docIcon from '../../../../assets/icons/doc-file.svg'
import checkIcon from '../../../../assets/icons/check-icon.png'
import TabInformation from '../../../../components/TabInformation';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '../../../../components/TabPanel';
import ButtonModalCommunity from '../../../../components/ButtonModalCommunity';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ModalMessage from '../../../../components/ModalMessage';
import StyledSelect from '../../../../components/StyledSelect';
import Loader from '../../../../components/Loader';
import theme from '../../../../config/theme';
import {
  activateCommunity,activateCommunityReset
} from "../../../../store/actions/communities.actions";
import {
  ActiveLabel,
  RejectedLabel,
  PendingLabel,
  EstadoLabelResuelto,
  BorderSeparator,
  SelectWrapper,
  TitleCommunityStyle,
  DocItem,
  BorderTab,
  SpinerWrapper,
  Img,
  AlignLeft,
  TabStyled,
  WrapperSelect,
  ButtonStyled
} from './styled';
import { Autorenew } from '@material-ui/icons'

const mapStateToProps = state => {

  const {
    loadingActivateCommunity,
    errorActivateCommunity,
    activateCommunitySuccess
  } = state.communities;
  return {
    loadingActivateCommunity: loadingActivateCommunity,
    errorActivateCommunity: errorActivateCommunity,
    activateCommunitySuccess: activateCommunitySuccess
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activateCommunity,
      activateCommunityReset
    },
    dispatch
  )


function ShowDetail(props) {

  const { children, value, index, communityDetail, loadingActivateCommunity, activateCommunitySuccess, errorActivateCommunity,activateCommunityReset } = props;

  const [val, setValue] = React.useState(0);
  const [selectedActivateState, setSelectedActivateState] = useState(
    {
      label: '',
      value: false
    });

  useEffect(() => {
    setSelectedActivateState({
      label: communityDetail.active ? 'Activa' : 'Inactiva',
      value: communityDetail.active
    });
  }, [communityDetail]);



  const handleTabs = (e, val) => {
    setValue(val);
  }

  const onActiveStateChange = selectedValue => {
    setSelectedActivateState({ label: selectedValue.label, value: selectedValue.value });
  }
  const saveChanges = () => {
    props.activateCommunity(props.communityDetail.id, selectedActivateState.value);
    // props.onClose();

  }
  const states = [
    { label: 'Activa', value: true },
    { label: 'Inactiva', value: false },
  ];


  const renderModalMessageSuccess = (message,callback) => (<ModalMessage success={true} onClose={() => callback()} >
    {message}
  </ModalMessage>);

  const renderModalMessageFail = (message,callback) => (<ModalMessage success={false} onClose={() => callback()} >
    {message}
  </ModalMessage>);


  const showActivateModalMessage = (() => {
    if (activateCommunitySuccess) {
   
      return renderModalMessageSuccess('Se ha ' + (selectedActivateState.value === true ? 'activado' : 'desactivado') + ' la comunidad correctamente.',props.activateCommunityReset);
     
    }
    else if (errorActivateCommunity) {
      return renderModalMessageFail('Hubo un error al intentar guardar los cambios.',props.activateCommunityReset);
    }
  }
  )();

  const form = (() => {
    if (props.isLoading) {
      return (<SpinerWrapper><Loader /></SpinerWrapper>);
    }



    return (
      <>
        <Img src={(props.communityDetail.profile_photo_url ? props.communityDetail.profile_photo_url : image)} />

        <Tabs value={val}  TabIndicatorProps={{ style: { background: theme.colors.midGray } }} onChange={handleTabs} aria-label="tabs">
          <TabStyled selected={val === 0} indicatorstyle={{ background: theme.colors.midGray }} label="Información" />
          <TabStyled selected={val === 1} label="Documentación" />
          <TabStyled selected={val === 2} label="Configuración" />
        </Tabs>

        <BorderTab />

        <TabPanel value={val} index={0}>
          <TabInformation>
            <h2>{props.communityDetail.name}</h2>
            {props.communityDetail.communityState === 'Activa' ? <ActiveLabel>{props.communityDetail.communityState}</ActiveLabel> : (props.communityDetail.communityState === 'Pendiente' ? <PendingLabel>{props.communityDetail.communityState}</PendingLabel> : <RejectedLabel>{props.communityDetail.communityState}</RejectedLabel>)}
          </TabInformation>

          <BorderSeparator />

          <TabInformation>
            <TitleCommunityStyle>Categoría</TitleCommunityStyle>
            <h4>{props.communityDetail.communityCategories}</h4>
          </TabInformation>

          <BorderSeparator />

          <TabInformation>
            <TitleCommunityStyle>Tipo de comunidad</TitleCommunityStyle>
            <h4>{props.communityDetail.private ? 'Privada' : 'Pública'}</h4>
          </TabInformation>

          <BorderSeparator />

          <TabInformation>
            <TitleCommunityStyle>Dirección</TitleCommunityStyle>
            <h4>{props.communityDetail.address}</h4>
          </TabInformation>


          <BorderSeparator />


          <TitleCommunityStyle>Descripción</TitleCommunityStyle>
          <p>{props.communityDetail.description}</p>


        </TabPanel>

        <TabPanel value={val} index={1} >
          <DocItem>
            <AlignLeft>
              <img src={docIcon} style={{ "paddingRight": '10px', width: 40, height: 45 }} />

              <a href={props.communityDetail.documentation_url} style={{ color: theme.colors.tundora }} target="_blank">Documentation</a>
            </AlignLeft>
            <img src={checkIcon} style={{ width: 30, height: 30, "alignSelf": 'center' }} />
          </DocItem>

        </TabPanel>

        <TabPanel value={val} index={2}>
          <TabInformation>
            <TitleCommunityStyle>Estado</TitleCommunityStyle>
            <WrapperSelect>
              <SelectWrapper>
                <StyledSelect
                  value={selectedActivateState}
                  options={states}
                  onChange={value => onActiveStateChange(value)}
                />
              </SelectWrapper>
              <ButtonStyled onClick={saveChanges} loading={loadingActivateCommunity}>
                Guardar
              </ButtonStyled>
            </WrapperSelect>
          </TabInformation>

          <BorderSeparator />

          <TabInformation>
            <TitleCommunityStyle>Código de aprobación</TitleCommunityStyle>
            <h4>{props.communityDetail.validationCode}</h4>
          </TabInformation>

          <BorderSeparator />
          <TitleCommunityStyle>Más acciones</TitleCommunityStyle>
          <ButtonModalCommunity>Enviar un mensaje</ButtonModalCommunity>

  
        </TabPanel>

      </>
    );
  });

  return (
    <ModalCommunity onClose={props.onClose}>
      {form()}
      {showActivateModalMessage}
    </ModalCommunity>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDetail);