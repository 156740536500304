import SectionHeader from "../../../components/SectionHeader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RitzTable from '../../../components/RitzTable';
import ShowDetail from "./ShowDetail/index"
import SearchInput from '../../../components/SearchInput';
import StyledSelect from '../../../components/StyledSelect';
import theme from "../../../config/theme"
import {
  fetchCommunities, fetchCommunity, fetchCommunityReset,clearCommunitiesPage
} from "../../../store/actions/communities.actions";
import {

  TableWrapper,
  LoaderWrapper,
  Ahref,
  LabelSection,
  InputWrapper,
  InputLabel,
  SelectWrapper,
  ActiveLabel,
  PendingLabel,
  RejectedLabel
} from "./styled";
import Loader from "../../../components/Loader";
import { string } from "yup";



const mapStateToProps = state => {
  const {
    communitiesPage,
    communitiesPageSize,
    communitiesTotal,
    communitiesOrderColumn,
    communitiesOrderDirection,
    communitiesSearchText,
    communities,
    loadingFetchCommunities,
    loadingFetchCommunity,
    communityDetail
  } = state.communities;

  return {
    communities: communities,
    isLoading: loadingFetchCommunities,
    pageNumber: communitiesPage,
    pageSize: communitiesPageSize,
    total: communitiesTotal,
    orderColumn: communitiesOrderColumn,
    orderDirection: communitiesOrderDirection,
    searchText: communitiesSearchText,
    loadingFetchCommunity: loadingFetchCommunity,
    communityDetail: communityDetail
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCommunities, fetchCommunity, fetchCommunityReset,clearCommunitiesPage
    },
    dispatch
  );

function AllCommunities(props) {
  const {
    isLoading,
    communities,
    pageNumber,
    pageSize,
    orderColumn,
    orderDirection,
    searchText,
    total,
    loadingFetchCommunity,
    communityDetail,
    communityStates,
    loadingFetchCommunityStates,
    selectedState,
    setStateDropValue
  } = props;

  var columns = [
    {
      title: "Nombre", field: "name",
      cellStyle: {
        width: "25%",
        textAlign: "left"
      },
      headerStyle: {
        width: "25%",
        textAlign: "left"
      }
    }
    ,
    {
      title: "Creada por", field: "owner_fullname",
      cellStyle: {
        width: "30%",
        textAlign: "left"
      },
      headerStyle: {
        width: "30%",
        textAlign: "left"
      }
    },
    { title: "Fecha", field: "creation_date" },

    {
      title: 'Categorias', field: 'communityCategories',
      cellStyle: {
        width: "30%",
        textAlign: "left"
      },
      headerStyle: {
        width: "30%",
        textAlign: "left"
      }
    },
    {
      title: 'Estado', field: 'communityState',
      cellStyle: {
        width: "15%",
        textAlign: "left"
      },
      headerStyle: {
        width: "15%",
        textAlign: "left"
      }, render: rowData =>
        <LabelSection>
          {(rowData.communityState === 'Activa') ? (<ActiveLabel> {rowData.communityState}</ActiveLabel>) : ((rowData.communityState === 'Rechazada') ? (<RejectedLabel> {rowData.communityState}</RejectedLabel>) : (<PendingLabel> {rowData.communityState}</PendingLabel>))}
        </LabelSection>

    },
    {
      title: "Acciones", field: "action", cellStyle: { textDecoration: 'underline' }, render: rowData =>
        <Ahref to='' onClick={() => toggleShowDetail(rowData.id)} >  Ver Detalle </Ahref>
    },
  ];

  const onStateChange = selectedValue => {

    setStateDropValue(selectedValue);
    props.fetchCommunities(0, pageSize, orderColumn, orderDirection, '', selectedValue.value);

  }
  const onChangeHandler = e => {
    if (e.target.value === '') {
      props.fetchCommunities(0, pageSize, orderColumn, orderDirection, '',selectedState.value)
    }
  }


  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      props.fetchCommunities(0, pageSize, orderColumn, orderDirection, e.target.value, selectedState.value)
    }
  }

  const onChangePageHandler = (e, page) => {
    props.fetchCommunities(page, pageSize, orderColumn, orderDirection, searchText, selectedState.value)
  }

  const onChangeRowsPerPageHandler = pageSize => {
    props.fetchCommunities(0, pageSize, orderColumn, orderDirection, searchText, selectedState.value)
  }


  const onOrderChangeHandler = (orderedColumnId, orderDirection) => {
    var columnName = 'name';
    var dir = 'asc';
    if (orderedColumnId !== null && orderedColumnId !== 3) {
      const column = columns[orderedColumnId];
      if (column != null)
        columnName = column.field;
    }

    if (orderDirection != null && orderDirection !== '')
      dir = orderDirection;

    props.fetchCommunities(pageNumber, pageSize, columnName, dir, searchText, selectedState.value)
  }
  
  const [showDetail, setShowDetailModal] = useState(false);

  const toggleShowDetail = async (communityId) => {
    setShowDetailModal(!showDetail);
    props.fetchCommunityReset();
    if (!showDetail)
      await props.fetchCommunity(communityId);
  };

  useEffect(() => {
    props.fetchCommunities(pageNumber, pageSize, orderColumn, orderDirection, '', selectedState.value)
    
    return () => {
    props.clearCommunitiesPage()
    };
  }, []);

  useEffect(() => {
    if (!showDetail)
      props.fetchCommunities(pageNumber, pageSize, orderColumn, orderDirection, searchText, selectedState.value)
  }, [showDetail]);

  return (
    <>
      <SectionHeader
        title="Comunidades"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
        alignItems={"baseline"}
      >
        <InputWrapper>
          <InputLabel>{'Estado'}</InputLabel>
          <SelectWrapper>
            <StyledSelect
              value={selectedState}
              onChange={value => onStateChange(value)}
              options={communityStates}
              isLoading={loadingFetchCommunityStates}
              placeholder="Seleccione un estado"
            />
          </SelectWrapper>
        </InputWrapper>
      </SectionHeader>
      <SearchInput
        type="text"
        search={true}
        defaultValue={searchText}
        placeholder={'Buscar'}
        disabled={isLoading}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
      />
      <TableWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}

        <RitzTable
          columns={columns}
          title=""
          isLoading={isLoading}
          options={{
            toolbar: true,
            paging: true,
            search: false,
            serverSide: true,
            selection: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'Comunidades.' + (new Date().toISOString().split('.')[0])
          }}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          onOrderChange={onOrderChangeHandler}
          pagination={{
            totalRow: total,
            page: props.pageNumber,
            pageSize: props.pageSize,
            onChangePage: onChangePageHandler
          }}
          data={communities}
        />
        {showDetail ? <ShowDetail onClose={toggleShowDetail} isLoading={loadingFetchCommunity} communityDetail={communityDetail} /> : null}
      </TableWrapper>

    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCommunities);
