/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAlerts, selectRows, resolveAlerts } from "../../../../store/actions/alerts.actions";
import OnCheckSection from "../../../../components/OnCheckSection";

const mapStateToProps = state => {
  const { loadingAlerts, selectedRows } = state.alerts;

  return {
    isLoading: loadingAlerts,
    selectedRows: selectedRows
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchAlerts, selectRows, resolveAlerts }, dispatch);

function AlertsFooter(props) {
  const { selectedRows, refreshGrids } = props;

  const resolveAlerts = async () => {
    await props.resolveAlerts(selectedRows);
    await refreshGrids();
    await props.selectRows([]);
  };

  const cancelSelection = async () => {
    await props.selectRows([]);
    await refreshGrids();
  };

  return (
    <>
      {selectedRows?.length > 0 ? (
        <OnCheckSection
          title="Marcar como resuelta"
          cancel="Cancelar"
          onClick={() => resolveAlerts()}
          onCancel={() => cancelSelection()}
          selectedRows={selectedRows.length}
          description={"alertas seleccionadas"}
        />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsFooter);
