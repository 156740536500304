/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import SectionHeader from "../../../../components/SectionHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "../../../../components/Modal";
import ReservationsTable from "../../../../components/ReservationsTable";
import DatePickerStyled from "../../../../components/DatePickerStyled";
import Loader from "../../../../components/Loader";
import theme from "../../../../config/theme";
import ScheduleModal from "./ScheduleModal";
import BookingModal from "./ScheduleModal/BookingModal";
import ColorReference from "./Components/ColorReference";

import {
  createBookingNeighbourhood,
  createBookingNeighbourhoodReset,
  fetchSchedulesCalendar,
  createSchedule,
  createScheduleReset,
  fetchScheduleDetail,
  fetchScheduleDetailReset,
  updateSchedule,
  updateScheduleReset
} from "../../../../store/actions/bookings.actions";
import { CalendarComponentsContainer, LoaderWrapper, ColorReferenceWrapper, Row } from "./styled";

const mapStateToProps = state => {
  const {
    selectedCalendarDateTime,
    schedulesCalendar,
    loadingFetchSchedulesCalendar,
    errorCreateSchedule,
    loadingCreateSchedule,
    createScheduleSuccess,
    loadingFetchScheduleDetail,
    fetchScheduleDetailSuccess,
    scheduleDetail
  } = state.bookings;
  return {
    selectedCalendarDateTime,
    schedulesCalendar,
    errorCreateSchedule,
    loadingCreateSchedule,
    createScheduleSuccess,
    loadingFetchSchedulesCalendar,
    loadingFetchScheduleDetail,
    fetchScheduleDetailSuccess,
    scheduleDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createBookingNeighbourhood,
      createBookingNeighbourhoodReset,
      fetchSchedulesCalendar,
      createSchedule,
      createScheduleReset,
      fetchScheduleDetail,
      fetchScheduleDetailReset,
      updateSchedule,
      updateScheduleReset
    },
    dispatch
  );

function SchedulesModal(props) {
  const {
    spaceId,
    selectedCalendarDateTime,
    schedulesCalendar,
    loadingFetchSchedulesCalendar,
    errorCreateSchedule,
    loadingCreateSchedule,
    createScheduleSuccess,
    loadingFetchScheduleDetail,
    fetchScheduleDetailSuccess,
    scheduleDetail
  } = props;

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);

  const [cellProps, setCellProps] = useState(null);
  useEffect(() => {
    props.fetchSchedulesCalendar(spaceId, new Date());
  }, []);

  const handleAvailableCellClick = async props => {
    setShowScheduleModal(!showScheduleModal);
    setCellProps(props);
  };

  const handleBookingCellClick = async props => {
    setShowBookingModal(!showBookingModal);
    setCellProps(props);
  };

  return (
    <>
      <SectionHeader
        onClose={props.onClose}
        title={`${props.title} - ${schedulesCalendar.space_name}`}
        fontWeight={"bold"}
        width="100%"
      ></SectionHeader>
      <CalendarComponentsContainer>
        <Row height={"45px"} justifyContent={"space-between"}>
          <DatePickerStyled
            selected={selectedCalendarDateTime}
            startDate={selectedCalendarDateTime}
            dateFormat="dd-MM-yyy"
            selectsStart
            width="200px"
            onChange={value => props.fetchSchedulesCalendar(spaceId, value)}
            placeholderText="Seleccione un día"
          />
          <ColorReferenceWrapper>
            <ColorReference color={theme.colors.appleGreen}>Disponible</ColorReference>
            <ColorReference color={theme.colors.softViolet}>Reservado</ColorReference>
            <ColorReference color={theme.colors.lightPink}>Reservado App</ColorReference>
          </ColorReferenceWrapper>
        </Row>
        <Row margin={"1em 0 0 0"} height={"calc(88vh - 145px)"}>
          {loadingFetchSchedulesCalendar ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <ReservationsTable
              data={schedulesCalendar.calendar_date_times}
              spaceId={props.spaceId}
              createSchedule={props.createSchedule}
              createScheduleReset={props.createScheduleReset}
              fetchScheduleDetail={props.fetchScheduleDetail}
              errorCreateSchedule={errorCreateSchedule}
              loadingCreateSchedule={loadingCreateSchedule}
              createScheduleSuccess={createScheduleSuccess}
              fetchSchedulesCalendar={props.fetchSchedulesCalendar}
              selectedCalendarDateTime={selectedCalendarDateTime}
              loadingFetchScheduleDetail={props.loadingFetchScheduleDetail}
              fetchScheduleDetailSuccess={props.fetchScheduleDetailSuccess}
              fetchScheduleDetailReset={props.fetchScheduleDetailReset}
              updateScheduleReset={props.updateScheduleReset}
              scheduleDetail={props.scheduleDetail}
              handleAvailableCellClick={handleAvailableCellClick}
              handleBookingCellClick={handleBookingCellClick}
              createBookingNeighbourhoodReset={props.createBookingNeighbourhoodReset}
            ></ReservationsTable>
          )}
          {showScheduleModal ? (
            <ScheduleModal
              title={!cellProps.isSchedule ? "Nuevo Horario" : "Editar Horario"}
              isEdit={cellProps.isSchedule}
              onClose={() => setShowScheduleModal(false)}
              spaceId={cellProps.spaceId}
              dayPos={cellProps.dayPos}
              timeRange={cellProps.timeRange}
              leftPopUp={cellProps.leftPopUp}
              bottomPopUp={cellProps.bottomPopUp}
              date={cellProps.date}
              startDate={cellProps.startDate}
              fetchSchedulesCalendar={cellProps.fetchSchedulesCalendar}
              selectedCalendarDateTime={cellProps.selectedCalendarDateTime}
              createScheduleReset={() => createScheduleReset()}
            />
          ) : showBookingModal ? (
            <>
              <BookingModal
                spaceId={cellProps.spaceId}
                selectedCalendarDateTime={cellProps.selectedCalendarDateTime}
                title="Nueva Reserva"
                onClose={() => setShowBookingModal(false)}
                leftPopUp={cellProps.leftPopUp}
                bottomPopUp={cellProps.bottomPopUp}
                date={cellProps.date}
                from={cellProps.originalData?.from}
                to={cellProps.originalData?.to}
                schedule_id={cellProps.originalData?.schedule_id}
                createBookingNeighbourhood={props.createBookingNeighbourhood}
                fetchSchedulesCalendar={cellProps.fetchSchedulesCalendar}
              />
            </>
          ) : null}
        </Row>
      </CalendarComponentsContainer>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesModal);
