/* eslint-disable react/prop-types */
import SectionHeader from '../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RitzTable from '../../../components/RitzTable';
import { bindActionCreators } from 'redux';
import Button from '../../../components/Button';
import {
  fetchMessages,
  deleteMessage,
  fetchAlertMotives,
  createMessage,
  createMessageReset,
  fetchNotificationTypes,
  fetchMessage,
  fetchMessageReset,
  updateMessageReset,
  updateMessage,
  deleteMessageReset
} from '../../../store/actions/alerts.actions';
import DeleteIcon from '../../../assets/icons/deletetrash.svg';
import EditIcon from '../../../assets/icons/edit.svg';
import MessageSlideBar from './MessageSlideBar/index';
import ModalDelete from '../../../components/ModalDelete';
import ModalMessage from '../../../components/ModalMessage';
import Loader from '../../../components/Loader';
import Select from 'react-select'

import {
  Imagen,
  ChannelLabel,
  TableWrapper,
  LoaderWrapper,
  LabelSection,
  InputWrapper,
  InputLabel,
  SelectWrapper
} from './styled';
const mapStateToProps = state => {
  const {
    detailMessage,
    messages,
    messagesPage,
    messagesPageSize,
    messagesTotal,
    messagesOrderColumn,
    messagesOrderDirection,
    loadingFetchMessages,
    loadingDeleteMessage,
    deleteMessageSuccess,
    loadingFetchAlertMotives,
    loadingCreateMessage,
    createMessageSuccess,
    errorCreateMessage,
    alertMotives,
    notificationTypes,
    loadingFetchNotificationTypes,
    loadingFetchMessage,
    updateMessageSuccess
  } = state.alerts;

  const {
    user
  } = state.auth

  return {
    detailMessage: detailMessage,
    isLoading: loadingFetchMessages,
    messages: messages,
    page: messagesPage,
    pageSize: messagesPageSize,
    orderColumn: messagesOrderColumn,
    orderDirection: messagesOrderDirection,
    total: messagesTotal,
    loadingDeleteMessage: loadingDeleteMessage,
    deleteMessageSuccess: deleteMessageSuccess,
    loadingFetchAlertMotives: loadingFetchAlertMotives,
    loadingCreateMessage: loadingCreateMessage,
    createMessageSuccess: createMessageSuccess,
    errorCreateMessage: errorCreateMessage,
    alertMotives: alertMotives,
    notificationTypes: notificationTypes,
    loadingFetchNotificationTypes: loadingFetchNotificationTypes,
    loadingFetchMessage: loadingFetchMessage,
    updateMessageSuccess: updateMessageSuccess,
    user: user
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMessages,
      fetchMessage,
      updateMessage,
      deleteMessage,
      fetchAlertMotives,
      createMessage,
      createMessageReset,
      fetchNotificationTypes,
      fetchMessageReset,
      updateMessageReset,
      deleteMessageReset
    },
    dispatch
  )

function Mensajes(props) {
  const {
    isLoading,
    detailMessage,
    messages,
    page,
    pageSize,
    orderColumn,
    orderDirection,
    total,
    loadingDeleteMessage,
    deleteMessageSuccess,
    loadingFetchAlertMotives,
    loadingCreateMessage,
    createMessageSuccess,
    errorCreateMessage,
    notificationTypes,
    loadingFetchNotificationTypes,
    loadingFetchMessage,
    updateMessageSuccess,
    selectedNeighbourhood,
    setNeighbourhoodDropValue,
    neighbourhoods,
    user
  } = props;

  const [addMessage, setAddMessage] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  var columns = [
    { title: 'Caso', field: 'alert_motive' },
    { title: 'Vista previa del mensaje', field: 'description', customSort: (a, b) => a.description.toLowerCase() - b.description.toLowerCase() },
    {
      title: 'Barrio', field: 'neighbourhood',
      hidden: user.neighbourhoods.length === 1,
      customSort:
        (a, b) => a.neighbourhood.toLowerCase() - b.neighbourhood.toLowerCase()
    },
    {
      title: 'Canales de envio', field: 'notification_types',
      cellStyle: {
        width: "20%",
      },
      headerStyle: {
        width: "20%",
      },
      render: rowData =>
        <LabelSection>
          {rowData.notification_types.map((item, i) => <ChannelLabel key={i}>{item.name} </ChannelLabel>)}
        </LabelSection>
    },

    {
      title: 'Acciones',
      field: 'actions',
      cellStyle: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
      render: rowData =>

        <>
          <Imagen onClick={() => toggleEdit(rowData.id)} src={EditIcon} />
          <Imagen onClick={() => toggleDelete(rowData.id)} src={DeleteIcon} />
        </>
    },
  ];

  const refreshGrid = () => {
    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, neighbourhoods.map(x => x.value));
    } else {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, selectedNeighbourhood.value);
    }
  }

  useEffect(() => {

    refreshGrid();
  }, []);

  useEffect(() => {
    if (!addMessage && !editMessage && !deleteMessage) {
      refreshGrid();
    }
  }, [addMessage, editMessage, deleteMessage]);

  const onChangePageHandler = (e, page) => {

    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, neighbourhoods.map(x => x.value));
    } else {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, selectedNeighbourhood.value);
    }
  }

  const onChangeRowsPerPageHandler = pageSize => {
    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, neighbourhoods.map(x => x.value));
    } else {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, selectedNeighbourhood.value);
    }
  }

  const onOrderChangeHandler = (orderedColumnId, orderDirection) => {
    var columnName = 'alert_motive';
    var dir = 'desc';
    if (orderedColumnId !== null && orderedColumnId !== 6) {
      const column = columns[orderedColumnId];
      if (column != null)
        columnName = column.field;
    }

    if (orderDirection != null && orderDirection !== '')
      dir = orderDirection;

    if (selectedNeighbourhood.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchMessages(columnName, dir, page, pageSize, neighbourhoods.map(x => x.value));
    } else {
      props.fetchMessages(columnName, dir, page, pageSize, selectedNeighbourhood.value);
    }
  }

  const renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  const renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  const toggleAdd = async () => {
    props.fetchAlertMotives();
    props.fetchNotificationTypes(true);
    setAddMessage(!addMessage);
    props.createMessageReset();
  };

  const onCreateMessageSubmit = async data => {
    let formattedData = {
      id: data.id,
      description: data.description,
      alert_motive_id: data.alert_motive.id,
      name: data.name,
      notification_types: data.notification_types ? data.notification_types.map(notification_type => { return notification_type.value }) : [],
      neighbourhood: data.neighbourhood ? data.neighbourhood[0] ? data.neighbourhood[0] : data.neighbourhood.value : selectedNeighbourhood.value
    };
    props.createMessage(formattedData);
  }

  const addSlideBar = (() => {
    if (createMessageSuccess) {
      return renderModalMessageSuccess(
        "Se ha creado el mensaje de alerta correctamente.",
        toggleAdd
      );
    } else if (props.errorCreateEmergencyPhone) {
      return renderModalMessageFail(
        "Hubo un error al intentar crear el mensaje de alerta.",
        toggleAdd
      );
    }
    if (addMessage) {
      return (
        <MessageSlideBar
          title="Mensaje de alerta"
          onClose={toggleAdd}
          headerBackgroundColor={'#30735f'}
          onSubmit={onCreateMessageSubmit}
          isSubmitting={loadingCreateMessage}
          isLoading={loadingFetchNotificationTypes}
          notificationTypes={notificationTypes}
          neighbourhoods={user.neighbourhoods}
        />
      );
    }
  }
  )();

  const toggleEdit = async (selectedId) => {
    setEditMessage(!editMessage);
    props.fetchMessageReset();
    props.updateMessageReset();
    if (!editMessage) {
      await props.fetchMessage(selectedId);
      props.fetchNotificationTypes(false);
    }
  };

  const onUpdateMessageSubmit = async (data) => {
    let formattedData = {
      id: data.id,
      description: data.description,
      alert_motive_id: data.alert_motive.id,
      notification_types: data.notification_types ? data.notification_types.map(notification_type => { return notification_type.value }) : [],
      neighbourhood: data.neighbourhood ? data.neighbourhood[0] ? data.neighbourhood[0] : data.neighbourhood.value : selectedNeighbourhood.value
    };

    props.updateMessage(formattedData);
  }

  const editSlideBar = (() => {
    if (updateMessageSuccess) {
      return renderModalMessageSuccess('Se ha editado el mensaje de alerta correctamente.', toggleEdit);
    }
    else if (props.errorUpdateEmergencyPhone) {
      return renderModalMessageFail('Hubo un error al intentar editar el mensaje de alerta.', toggleEdit);
    }
    if (editMessage) {
      return (
        <MessageSlideBar
          title="Mensaje de alerta"
          onClose={toggleEdit}
          headerBackgroundColor={'#30735f'}
          onSubmit={onUpdateMessageSubmit}
          isLoading={loadingFetchNotificationTypes || loadingFetchMessage}
          notificationTypes={notificationTypes}
          detailMessage={detailMessage}
          neighbourhoods={user.neighbourhoods}
        />
      );
    }
  }
  )();

  const toggleDelete = (selectedId) => {
    setSelectedId(selectedId);
    setDeleteMessage(!deleteMessage);
    props.deleteMessageReset();
  };

  const onDeleteMessageSubmit = async () => {
    await props.deleteMessage(selectedId);
    await props.fetchMessages(orderColumn, orderDirection, page, pageSize,selectedNeighbourhood.value);
  }
  const deleteModal = (() => {
    if (deleteMessageSuccess) {
      return renderModalMessageSuccess('Se ha eliminado el mensaje de alerta correctamente.', toggleDelete);
    }
    if (deleteMessage) {
      return (
        <ModalDelete
          title="Eliminar mensaje de alerta"
          subTitle="¿Estás seguro que querés eliminarlo?"
          buttonMessage="Eliminar mensaje de alerta"
          // successMessage="¡Se ha eliminado el mensaje de alerta!"
          isLoading={loadingDeleteMessage}
          // isSubmitting={loadingDeleteMessage}
          onClose={toggleDelete}
          success={false}
          onSubmit={onDeleteMessageSubmit}
        />
      );
    }
  }
  )();

  const onNeighbourhoodChange = (data) => {
    setNeighbourhoodDropValue(data);
    if (data.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, neighbourhoods.map(x => x.value));
    } else {
      props.fetchMessages(orderColumn, orderDirection, page, pageSize, data.value);
    }
  }

  return (
    <>
      <SectionHeader
        title="Mensajes predefinidos"
        width="100%"
        justifyContent={"space-between"}
        fontWeight={"bold"}
      >
        {user.neighbourhoods.length > 1 ? (
          <InputWrapper>
            <InputLabel>{"Barrio"}</InputLabel>
            <SelectWrapper>
              <Select
                value={selectedNeighbourhood}
                onChange={value => onNeighbourhoodChange(value)}
                options={neighbourhoods}
                placeholder="Seleccione un barrio"
                isLoading={props.isLoading}
              />
            </SelectWrapper>
          </InputWrapper>
        ) : null}
        <Button loading={isLoading} onClick={() => toggleAdd()}>
          Definir nuevo mensaje
        </Button>
      </SectionHeader>
      <SectionHeader
        title="Acá podes definir los mensajes que se recibirán en la guardia."
        subtitle={true}
        width="100%"
      />
      <TableWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : null}
        <RitzTable
          columns={columns}
          title=""
          isLoading={isLoading}
          options={{
            toolbar: false,
            paging: true,
            serverSide: true,
            selection: false
          }}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          onOrderChange={onOrderChangeHandler}
          pagination={{
            totalRow: total,
            page: page,
            pageSize: pageSize,
            onChangePage: onChangePageHandler
          }}
          data={messages}
        />
        {addSlideBar}
        {editSlideBar}
        {deleteModal}
      </TableWrapper>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);
