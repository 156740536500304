import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBackIos'

export const TitleContainer = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.colors.black };
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };   
    margin-top: ${({ theme }) => theme.spacing.unit }; 
    display: flex;   
`
export const Row = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3% 0%; 
    align-items: center;
`
export const IconCopy = styled.img`
    width: 20px;
    margin: 0% 5%;
`

export const Column = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0% 2%;
    padding: 2% 0%;
    cursor: pointer;
`

export const Link = styled.div`
    padding: 2%;
    width: 60%;
    border: solid 1px grey;
    border-radius: 5px;
    background-color: white;
    font-size: 11px;

`
export const Subtitle = styled.div`
    text-size: 15px;
    color: ${({ theme }) => theme.colors.warmGrey };
`

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    margin: ${({ theme }) => theme.spacing.unit };

`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    background-color: ${({ theme }) => theme.colors.white };

    ${TitleContainer}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;  
        cursor: pointer;
    }
`
export const LabelContainer = styled.label`
    color: black;
    font-size: 20px;  
    margin: 1% 0%;
`

export const Title = styled.label`
    align-self: center;
    color: black;
    font-size: 20px;
    margin: ${({ theme }) => theme.spacing.unit2 } 0px;
`

export const LabelChecked = styled.div`
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')}; 
    margin-top: ${({ theme }) => theme.spacing.unit };   
`

export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const DetailContainer = styled.div` 
    display:flex;
    flex-direction: column;
    justify-content: ${({ centered }) =>  (centered ? 'center' : '' ) };
    width: 60%;
    align-self: center;
    background-color: ${({ theme }) => theme.colors.wildSand };
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.ghost };
    border-radius: 5px;
    border-width: 0.5px;
    padding: 2% 5%;
`

export const DetailContainerError = styled.div` 
    display:flex;
    flex-direction: column;
    justify-content: ${({ centered }) =>  (centered ? 'center' : '' ) };
    width: 100%;
    background-color: ${({ theme }) => theme.colors.wildSand };
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.ghost };
    border-radius: 5px;
    border-width: 0.5px;
    padding: ${({ theme }) => theme.spacing.unit };
    margin-top: 20px;
    margin-bottom: 20px;
    color: red;
    text-align: center;
    line-height: 1.4em;
`

export const DetailItemContainer = styled.div`
    width: 100%;   
    height: 30%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: ${({ centered }) =>  (centered ? 'center' : 'space-between' ) };
    margin: 1% 0%;
    padding: 1% 5%;
`
export const DetailItem = styled.div`
    color: ${props => (props.bold ? 'black' : '#616161')} ;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    text-align: center;
    margin: ${props => (props.margin ? props.margin : '0px')} ;
    
`

export const Button = styled.button`
    background-color: ${({ theme }) =>  theme.colors.white };
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) =>  theme.colors.midGray };
    border: ${({ theme }) =>  theme.colors.midGray };
    border-width: 1px;
    border-style: solid;
    font-size: 18px;
    font-weight: 500;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    margin: 0% 24%;
    padding: 4% 0%;
    width: 50%;
    
`
export const StyledButton = styled.button`
    background-color: ${({ theme }) =>  theme.colors.midGray };  
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    margin: 0% 2%;
    padding: 2% 0%;
    width: 30%;
    ${Column}:hover {        
        background-color: ${({ theme }) =>  theme.colors.white };  
        color: ${({ theme }) =>  theme.colors.midGray };
        border: solid 1px ${({ theme }) =>  theme.colors.midGray };
    }
`


