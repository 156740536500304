import {
  SELECT_ROWS_ALERTS,
  SELECT_ROWS_EMERGENCY_PHONES,
  FETCH_ALERT_RECEIVED_FAIL,
  FETCH_ALERT_RECEIVED_START,
  FETCH_ALERT_RECEIVED_SUCCESS,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_START,
  FETCH_ALERT_FAIL,
  ACTIVATE_ALERT_MOTIVE_FAIL,
  ACTIVATE_ALERT_MOTIVE_RESET,
  ACTIVATE_ALERT_MOTIVE_START,
  ACTIVATE_ALERT_MOTIVE_SUCCESS,
  RESOLVE_ALERT_SUCCESS,
  RESOLVE_ALERT_START,
  RESOLVE_ALERT_FAIL,
  FETCH_EMERGENCY_PHONES_FAIL,
  FETCH_EMERGENCY_PHONES_SUCCESS,
  FETCH_EMERGENCY_PHONES_START,
  FETCH_EMERGENCY_PHONES_RESET,
  FETCH_EMERGENCY_PHONE_FAIL,
  FETCH_EMERGENCY_PHONE_RESET,
  FETCH_EMERGENCY_PHONE_START,
  FETCH_EMERGENCY_PHONE_SUCCESS,
  CREATE_EMERGENCY_PHONE_FAIL,
  CREATE_EMERGENCY_PHONE_START,
  CREATE_EMERGENCY_PHONE_SUCCESS,
  CREATE_EMERGENCY_PHONE_RESET,
  UPDATE_EMERGENCY_PHONE_FAIL,
  UPDATE_EMERGENCY_PHONE_START,
  UPDATE_EMERGENCY_PHONE_SUCCESS,
  UPDATE_EMERGENCY_PHONE_RESET,
  DELETE_EMERGENCY_PHONE_FAIL,
  DELETE_EMERGENCY_PHONE_RESET,
  DELETE_EMERGENCY_PHONE_SUCCESS,
  DELETE_EMERGENCY_PHONE_START,
  DELETE_EMERGENCY_PHONES_FAIL,
  DELETE_EMERGENCY_PHONES_START,
  DELETE_EMERGENCY_PHONES_SUCCESS,
  DELETE_EMERGENCY_PHONES_RESET,
  FETCH_MESSAGES_FAIL,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_RESET,
  FETCH_MESSAGE_FAIL,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_START,
  FETCH_MESSAGE_RESET,
  CREATE_MESSAGE_FAIL,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_RESET,
  UPDATE_MESSAGE_FAIL,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_RESET,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_START,
  DELETE_MESSAGE_RESET,
  FETCH_ALERT_MOTIVES_FAIL,
  FETCH_ALERT_MOTIVES_RESET,
  FETCH_ALERT_MOTIVES_SUCCESS,
  FETCH_ALERT_MOTIVES_START,
  FETCH_NOTIFICATION_TYPES_SUCCESS,
  FETCH_NOTIFICATION_TYPES_FAIL,
  FETCH_NOTIFICATION_TYPES_START
} from "./actionTypes";
import getApi from "../../shared/api";
import mapEmergencyPhones from "../../selectors/mapEmergencyPhones";
import mapMessages from "../../selectors/mapMessages";
import {
  emergencyPhonesAPI,
  alertsAPI,
  messagesAPI,
  alertMotivesAPI,
  alertMotivesNeighbourhoodsAPI,
  notificationTypesAPI
} from "../../apiConstants";

export const selectAlertRows = data => {
  return {
    type: SELECT_ROWS_ALERTS,
    ids: data
  };
};

export const selectRows = ids => {
  return async dispatch => {
    dispatch(selectAlertRows(ids));
  };
};

export const selectEmergencyPhoneIdsRows = data => {
  return {
    type: SELECT_ROWS_EMERGENCY_PHONES,
    ids: data
  };
};

export const selectEmergencyPhonesRows = ids => {
  return async dispatch => {
    dispatch(selectEmergencyPhoneIdsRows(ids));
  };
};

////////////////////// FETCH EMERGENCY PHONES //////////////////////
export const fetchEmergencyPhonesSuccess = emergencyPhones => {
  let data = mapEmergencyPhones(emergencyPhones);
  return {
    type: FETCH_EMERGENCY_PHONES_SUCCESS,
    emergencyPhones: data
  };
};

export const fetchEmergencyPhonesFail = error => {
  return {
    type: FETCH_EMERGENCY_PHONES_FAIL,
    errorFetchEmergencyPhones: error
  };
};

export const fetchEmergencyPhonesStart = () => {
  return {
    type: FETCH_EMERGENCY_PHONES_START
  };
};

export const fetchEmergencyPhones = (
  orderField,
  orderDirection,
  pageNumber,
  pageSize,
  neighbourhoodGuids
) => {
  let neighbourhoodQuery = formatNeighbourhoodQuery(neighbourhoodGuids);

  return async dispatch => {
    dispatch(fetchEmergencyPhonesStart());
    return await getApi()
      .get(
        `${emergencyPhonesAPI}?orderField=${orderField}&orderDirection=${orderDirection}&pageNumber=${pageNumber}&pageSize=${pageSize}&${neighbourhoodQuery}`
      )
      .then(res => {
        dispatch(fetchEmergencyPhonesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchEmergencyPhonesFail(err));
      });
  };
};
////////////////////// FETCH EMERGENCY PHONE //////////////////////
export const fetchEmergencyPhoneSuccess = emergencyPhone => {
  return {
    type: FETCH_EMERGENCY_PHONE_SUCCESS,
    detailEmergencyPhone: emergencyPhone
  };
};

export const fetchEmergencyPhoneFail = error => {
  return {
    type: FETCH_EMERGENCY_PHONE_FAIL,
    errorFetchEmergencyPhone: error
  };
};

export const fetchEmergencyPhoneStart = () => {
  return {
    type: FETCH_EMERGENCY_PHONE_START
  };
};

export const fetchEmergencyPhoneReset = () => {
  return {
    type: FETCH_EMERGENCY_PHONE_RESET
  };
};

export const fetchEmergencyPhone = emergencyPhoneId => {
  return async dispatch => {
    dispatch(fetchEmergencyPhoneStart());

    const api = await getApi();
    return api
      .get(`${emergencyPhonesAPI}/${emergencyPhoneId}`)
      .then(res => {
        dispatch(fetchEmergencyPhoneSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchEmergencyPhoneFail(err));
      });
  };
};

////////////////////// CREATE EMERGENCY PHONE //////////////////////
export const createEmergencyPhoneSuccess = () => {
  return {
    type: CREATE_EMERGENCY_PHONE_SUCCESS
  };
};

export const createEmergencyPhoneFail = error => {
  return {
    type: CREATE_EMERGENCY_PHONE_FAIL,
    errorCreateEmergencyPhone: error
  };
};

export const createEmergencyPhoneStart = () => {
  return {
    type: CREATE_EMERGENCY_PHONE_START
  };
};

export const createEmergencyPhoneReset = () => {
  return {
    type: CREATE_EMERGENCY_PHONE_RESET
  };
};

export const createEmergencyPhone = data => {
  return async (dispatch, getState) => {
    dispatch(createEmergencyPhoneStart());
    var neighbourhoodGuid = data.neighbourhood;
    const api = await getApi(neighbourhoodGuid);
    return api
      .post(emergencyPhonesAPI, data)
      .then(res => {
        dispatch(createEmergencyPhoneSuccess(res.data));
      })
      .catch(error => {
        dispatch(createEmergencyPhoneFail(error));
      });
  };
};

////////////////////// UPDATE EMERGENCY PHONE //////////////////////
export const updateEmergencyPhoneSuccess = () => {
  return {
    type: UPDATE_EMERGENCY_PHONE_SUCCESS
  };
};

export const updateEmergencyPhoneFail = error => {
  return {
    type: UPDATE_EMERGENCY_PHONE_FAIL,
    errorEmergencyPhone: error
  };
};

export const updateEmergencyPhoneStart = () => {
  return {
    type: UPDATE_EMERGENCY_PHONE_START
  };
};

export const updateEmergencyPhoneReset = () => {
  return {
    type: UPDATE_EMERGENCY_PHONE_RESET
  };
};

export const updateEmergencyPhone = data => {
  console.log("updatephone:", data);
  return async (dispatch, getState) => {
    dispatch(updateEmergencyPhoneStart());
    var neighbourhoodGuid = data.neighbourhood;
    const api = await getApi(neighbourhoodGuid);
    return api
      .put(`${emergencyPhonesAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updateEmergencyPhoneSuccess(res.data));
      })
      .catch(error => {
        dispatch(updateEmergencyPhoneFail(error));
      });
  };
};

////////////////////DELETE EMERGENCY PHONE//////////////////////////////

export const deleteEmergencyPhone = emergencyPhoneId => {
  return async dispatch => {
    dispatch(deleteEmergencyPhoneReset());
    dispatch(deleteEmergencyPhoneStart());
    return await getApi()
      .delete(`${emergencyPhonesAPI}/${emergencyPhoneId}`)
      .then(res => {
        dispatch(deleteEmergencyPhoneSuccess());
      })
      .catch(err => {
        dispatch(deleteEmergencyPhoneFail(err));
      });
  };
};

export const deleteEmergencyPhoneSuccess = () => {
  return {
    type: DELETE_EMERGENCY_PHONE_SUCCESS
  };
};

export const deleteEmergencyPhoneFail = error => {
  return {
    type: DELETE_EMERGENCY_PHONE_FAIL,
    error: error
  };
};

export const deleteEmergencyPhoneStart = () => {
  return {
    type: DELETE_EMERGENCY_PHONE_START
  };
};
export const deleteEmergencyPhoneReset = () => {
  return {
    type: DELETE_EMERGENCY_PHONE_RESET
  };
};

////////////////////DELETE EMERGENCY PHONES//////////////////////////////

export const deleteEmergencyPhones = emergencyPhoneIds => {
  return async dispatch => {
    dispatch(deleteEmergencyPhonesReset());
    dispatch(deleteEmergencyPhonesStart());

    return await getApi()
      .post(emergencyPhonesAPI + "/delete", { ids: emergencyPhoneIds })
      .then(res => {
        dispatch(deleteEmergencyPhonesSuccess());
      })
      .catch(err => {
        dispatch(deleteEmergencyPhonesFail(err));
      });
  };
};

export const deleteEmergencyPhonesSuccess = () => {
  return {
    type: DELETE_EMERGENCY_PHONES_SUCCESS
  };
};

export const deleteEmergencyPhonesFail = error => {
  return {
    type: DELETE_EMERGENCY_PHONES_FAIL,
    error: error
  };
};

export const deleteEmergencyPhonesStart = () => {
  return {
    type: DELETE_EMERGENCY_PHONES_START
  };
};

export const deleteEmergencyPhonesReset = () => {
  return {
    type: DELETE_EMERGENCY_PHONES_RESET
  };
};

////////////////////// ALERTS //////////////////////
export const fetchAlertsSuccess = receivedAlerts => {
  return {
    type: FETCH_ALERT_RECEIVED_SUCCESS,
    receivedAlerts: receivedAlerts
  };
};

export const fetchAlertsFail = error => {
  return {
    type: FETCH_ALERT_RECEIVED_FAIL,
    fetchAlertFail: error
  };
};

export const fetchAlertsStart = () => {
  return {
    type: FETCH_ALERT_RECEIVED_START
  };
};

export const fetchAlerts = neighbourhoodGuids => {
  let neighbourhoodQuery = formatNeighbourhoodQuery(neighbourhoodGuids);

  return async dispatch => {
    dispatch(fetchAlertsStart());
    return await getApi()
      .get(`${alertsAPI}?${neighbourhoodQuery}`)
      .then(res => {
        dispatch(fetchAlertsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAlertsFail(err));
      });
  };
};

/////////////////////////// Fetch Alert ///////////////////////////////

export const fetchAlertSuccess = detailAlert => {
  return {
    type: FETCH_ALERT_SUCCESS,
    detailAlert: detailAlert
  };
};

export const fetchAlertFail = error => {
  return {
    type: FETCH_ALERT_FAIL,
    fetchAlertFail: error
  };
};

export const fetchAlertStart = () => {
  return {
    type: FETCH_ALERT_START
  };
};

export const fetchAlert = alertId => {
  return async dispatch => {
    dispatch(fetchAlertStart());
    return await getApi()
      .get(`${alertsAPI}/${alertId}`)
      .then(res => {
        dispatch(fetchAlertSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAlertFail(err));
      });
  };
};

////////////////////////// ALERTS RESOLVE ///////////////////////////

export const resolveAlertSuccess = () => {
  return {
    type: RESOLVE_ALERT_SUCCESS
  };
};

export const resolveAlertFail = error => {
  return {
    type: RESOLVE_ALERT_FAIL,
    fetchAlertFail: error
  };
};

export const resolveAlertstart = () => {
  return {
    type: RESOLVE_ALERT_START
  };
};

export const resolveAlerts = ids => {
  return async dispatch => {
    dispatch(resolveAlertstart());
    const api = await getApi()
      .post(alertsAPI + "/resolve", { ids: ids })
      .then(res => {
        dispatch(resolveAlertSuccess());
      })
      .catch(err => {
        dispatch(resolveAlertFail(err));
      });
  };
};

////////////////////// FETCH MESSAGES //////////////////////
export const fetchMessagesSuccess = data => {
  var messages = mapMessages(data.messages);
  return {
    type: FETCH_MESSAGES_SUCCESS,
    messages: messages,
    messagesTotal: data.total
  };
};

export const fetchMessagesFail = error => {
  return {
    type: FETCH_MESSAGES_FAIL,
    errorFetchMessages: error
  };
};

export const fetchMessagesStart = () => {
  return {
    type: FETCH_MESSAGES_START
  };
};

export const fetchMessages = (
  orderField,
  orderDirection,
  pageNumber,
  pageSize,
  neighbourhoodGuid
) => {
  let neighbourhoodQuery = formatNeighbourhoodQuery(neighbourhoodGuid);
  console.log(neighbourhoodGuid);
  return async dispatch => {
    dispatch(fetchMessagesStart());
    return await getApi(neighbourhoodGuid)
      .get(
        `${messagesAPI}?orderField=${orderField}&orderDirection=${orderDirection}&pageNumber=${pageNumber}&pageSize=${pageSize}&${neighbourhoodQuery}`
      )
      .then(res => {
        dispatch(fetchMessagesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchMessagesFail(err));
      });
  };
};

///////////////////////// Fetch a Message///////////////////
export const fetchMessageSuccess = data => {
  return {
    type: FETCH_MESSAGE_SUCCESS,
    detailMessage: data.messages
  };
};

export const fetchMessageFail = error => {
  return {
    type: FETCH_MESSAGE_FAIL,
    errorFetchMessage: error
  };
};

export const fetchMessageStart = () => {
  return {
    type: FETCH_MESSAGE_START
  };
};

export const fetchMessageReset = () => {
  return {
    type: FETCH_MESSAGE_RESET
  };
};

export const fetchMessage = messageId => {
  return async dispatch => {
    dispatch(fetchMessageStart());
    const api = await getApi()
      .get(`${messagesAPI}/${messageId}`)
      .then(res => {
        dispatch(fetchMessageSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchMessageFail(err));
      });
  };
};
////////////////////// CREATE MESSAGE //////////////////////
export const createMessageSuccess = () => {
  return {
    type: CREATE_MESSAGE_SUCCESS
  };
};

export const createMessageFail = error => {
  return {
    type: CREATE_MESSAGE_FAIL,
    errorCreateMessage: error
  };
};

export const createMessageStart = () => {
  return {
    type: CREATE_MESSAGE_START
  };
};

export const createMessageReset = () => {
  return {
    type: CREATE_MESSAGE_RESET
  };
};

export const createMessage = data => {
  return async (dispatch, getState) => {
    dispatch(createMessageStart());
    var neighbourhoodGuid = data.neighbourhood;
    return await getApi(neighbourhoodGuid)
      .post(messagesAPI, data)
      .then(res => {
        dispatch(createMessageSuccess());
      })
      .catch(error => {
        dispatch(createMessageFail(error));
      });
  };
};

////////////////////// UPDATE MESSAGE //////////////////////
export const updateMessageSuccess = () => {
  return {
    type: UPDATE_MESSAGE_SUCCESS
  };
};

export const updateMessageFail = error => {
  return {
    type: UPDATE_MESSAGE_FAIL,
    errorEmergencyPhone: error
  };
};

export const updateMessageStart = () => {
  return {
    type: UPDATE_MESSAGE_START
  };
};

export const updateMessageReset = () => {
  return {
    type: UPDATE_MESSAGE_RESET
  };
};

export const updateMessage = data => {
  return async (dispatch, getState) => {
    dispatch(updateMessageStart());
    console.log(data);
    var neighbourhoodGuid = data.neighbourhood;
    return await getApi(neighbourhoodGuid)
      .put(`${messagesAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updateMessageSuccess(res.data));
      })
      .catch(error => {
        dispatch(updateMessageFail(error));
      });
  };
};

////////////////////DELETE MESSAGE//////////////////////////////

export const deleteMessage = messageId => {
  return async dispatch => {
    dispatch(deleteMessageStart());
    return await getApi()
      .delete(`${messagesAPI}/${messageId}`)
      .then(res => {
        dispatch(deleteMessageSuccess());
      })
      .catch(err => {
        dispatch(deleteMessageFail(err));
      });
  };
};

export const deleteMessageSuccess = () => {
  return {
    type: DELETE_MESSAGE_SUCCESS
  };
};

export const deleteMessageFail = error => {
  return {
    type: DELETE_MESSAGE_FAIL,
    error: error
  };
};

export const deleteMessageStart = () => {
  return {
    type: DELETE_MESSAGE_START
  };
};

export const deleteMessageReset = () => {
  return {
    type: DELETE_MESSAGE_RESET
  };
};

////////////////////// FETCH ALERT MOTIVES //////////////////////
export const fetchAlertMotivesSuccess = alertMotives => {
  return {
    type: FETCH_ALERT_MOTIVES_SUCCESS,
    alertMotives: alertMotives
  };
};

export const fetchAlertMotivesFail = error => {
  return {
    type: FETCH_ALERT_MOTIVES_FAIL,
    error: error
  };
};

export const fetchAlertMotivesStart = () => {
  return {
    type: FETCH_ALERT_MOTIVES_START
  };
};

export const fetchAlertMotivesReset = () => {
  return {
    type: FETCH_ALERT_MOTIVES_RESET
  };
};

export const fetchAlertMotives = (neighbourhoodGuid, forSelect) => {
  return async (dispatch, getState) => {
    dispatch(fetchAlertMotivesStart());
    return await getApi(neighbourhoodGuid)
      .get(`${alertMotivesNeighbourhoodsAPI}?forSelect=${forSelect}`)
      .then(res => {
        dispatch(fetchAlertMotivesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchAlertMotivesFail(err));
      });
  };
};

////////////////////// FETCH NOTIFICATION TYPES //////////////////////
export const fetchNotificationTypesSuccess = notificationTypes => {
  return {
    type: FETCH_NOTIFICATION_TYPES_SUCCESS,
    notificationTypes: notificationTypes
  };
};

export const fetchNotificationTypesFail = error => {
  return {
    type: FETCH_NOTIFICATION_TYPES_FAIL,
    error: error
  };
};

export const fetchNotificationTypesStart = () => {
  return {
    type: FETCH_NOTIFICATION_TYPES_START
  };
};

export const fetchNotificationTypes = fromApp => {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationTypesStart());
    var urlApi;
    if (!fromApp) urlApi = notificationTypesAPI + "?fromApp=false";
    else urlApi = notificationTypesAPI + "?fromApp=true";
    const api = await getApi()
      .get(urlApi)
      .then(res => {
        dispatch(fetchNotificationTypesSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchNotificationTypesFail(err));
      });
  };
};

////////////////////// ACTIVATE ALERT MOTIVE //////////////////////
export const activateAlertMotiveSuccess = () => {
  return {
    type: ACTIVATE_ALERT_MOTIVE_SUCCESS
  };
};

export const activateAlertMotiveFail = error => {
  return {
    type: ACTIVATE_ALERT_MOTIVE_FAIL
  };
};

export const activateAlertMotiveStart = () => {
  return {
    type: ACTIVATE_ALERT_MOTIVE_START
  };
};

export const activateAlertMotiveReset = () => {
  return {
    type: ACTIVATE_ALERT_MOTIVE_RESET
  };
};

export const activateAlertMotive = data => {
  return async (dispatch, getState) => {
    dispatch(activateAlertMotiveStart());
    const api = await getApi()
      .post(`${alertMotivesNeighbourhoodsAPI}/${data.id}/activate`, data)
      .then(res => {
        dispatch(activateAlertMotiveSuccess());
      })
      .catch(error => {
        dispatch(activateAlertMotiveFail(error));
      });
  };
};

///////////////////Helper//////////////////////

function formatNeighbourhoodQuery(neighbourhoodGuids) {
  if (neighbourhoodGuids !== undefined && neighbourhoodGuids !== null) {
    if (Array.isArray(neighbourhoodGuids)) {
      return neighbourhoodGuids
        ?.filter(x => x !== "00000000-0000-0000-0000-000000000000")
        .map((x, index) => `neighbourhoodGuids[${index}]=${x}&`)
        .join()
        .replace(/,/g, "")
        .replace(/&$/, "");
    } else {
      return `neighbourhoodGuids=${neighbourhoodGuids}`;
    }
  }
}
