import React from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import DetailContainer from '../../components/DetailContainer';
import SectionHeader from '../../components/SectionHeader';
import DeleteConfirmation from '../../components/DeleteConfirmation'
import Select from 'react-select';
import ResourceList from '../../components/ResourceList';
import Loader from "../../components/Loader";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Button from '../../components/Button';
import {
  fetchFeedEvent,
  fetchFeedNews,
  deleteArticleById,
  deleteEventById,
  deleteArticleReset,
  deleteEventReset

} from '../../store/actions/feed.actions'
import { setSelectedResource, resetSelectedResource } from '../../store/actions/selectedResource'
import { setNeighbourhood } from '../../store/actions/userInfo.actions';
import mapNeighbourhoods from '../../selectors/mapNeighbourhoods'
import { Wrapper, HeaderWrapper, DetailWrapper, ContentWrapper, LoaderWrapper, ContentList } from './styled'

import mapFeedToNovelty from '../../selectors/mapFeedToNovelty'
import Article from './Article'
import Seguridad from './Seguridad'
import NewPublication from './NewPublication'
import Event from './Event'

const menulistItems = [{ title: 'Artículos', route: 'articulos', available: true }, { title: 'Eventos', route: 'eventos', available: true }]

class Feed extends React.Component {
  state = {
    search: '',

    detailModal: false,
    deleteModal: false,

    addModal: false,

    addProductModal: false,
    editProductModal: false,

    fetchingProductDetail: true,
    view: "articulos",
    reasonDelete: 1,
    itemForDelete: null,
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    },

    isEditEvent: false
  };

  componentDidMount = () => {
    this.props.resetSelectedResource()
    this.props.fetchFeedEvent()
    this.props.fetchFeedNews()
  }

  searchHandleChange = (event) => {
    this.setState({ search: event.target.value });
  }

  toggleItemDelete = (idProd) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      itemForDelete: idProd
    });
  }

  toggleItemDetail = () => {
    this.setState({ detailModal: !this.state.detailModal });
  }

  toggleAddItem = () => {
    this.setState({ addModal: !this.state.addModal });
  }


  toggleAddProduct = () => {
    this.setState({ addProductModal: !this.state.addProductModal });
  }

  toggleEditProduct = () => {
    this.setState({ editProductModal: !this.state.editProductModal });
  }

  handleDetailProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    this.toggleItemDetail();
  }


  handleEditProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    //this.toggleItemEdit();
  }

  async DeleteProduct() {
    this.props.fetchDeleteProduct(this.state.itemForDelete, this.state.reasonDelete)
  }

  switchView = (view) => {
    this.setState({ view });
    this.props.resetSelectedResource()
    view === "articulos" ? this.props.fetchFeedNews() : this.props.fetchFeedEvent()
    view === "articulos" ? this.onChangeIsEditEvent(false) : this.onChangeIsEditEvent(true)
  }

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }

  handleChangeNeighbourhood = (neighbourhoodSelected) => {
    this.props.setNeighbourhood(neighbourhoodSelected)
    this.setState({ neighbourhoodSelected: neighbourhoodSelected });
    this.props.resetSelectedResource()
    this.props.fetchFeedEvent()
    this.props.fetchFeedNews()
  }

  toggleNewPublication = () => this.setState({ newPublication: (!this.state.newPublication) })

  handleResourceClick = resource => this.props.setSelectedResource(resource)

  handleDeleteConfirmationClose = () => {
    this.props.deleteArticleReset()
    this.props.deleteEventReset()
    this.setState({ deleteConfirmation: false, publicationToDelete: '' })
  }

  handleFormClose = (reset) => {
    if (reset) {
      this.props.resetSelectedResource()
      this.props.fetchFeedEvent()
      this.props.fetchFeedNews()
    }
    this.setState({ isEdit: false, newPublication: false })
  }

  onDelete = async id => {
    if (this.state.view === "articulos") {
      await this.props.deleteArticleById(id)
    } else {
      await this.props.deleteEventById(id)
    }
    if (this.props.deletedArticle || this.props.deletedEvent) {
      this.onFetchNovelties()
      this.props.setSelectedResource('')
      this.handleDeleteConfirmationClose()
    }
  }

  onFetchNovelties = () => {
    if (this.state.view === "articulos") {
      this.props.fetchFeedNews()
    }
    this.props.fetchFeedEvent()
  }
  onChangeIsEditEvent = (value) => {
    this.setState({ isEditEvent: value })
  }
  renderDetail = () => (
    <DetailWrapper>
      <DetailContainer>
        {this.props.selectedResource ? (
          this.state.view === "articulos" ? (
            <Article
              title={this.props.selectedResource.title}
              subTitle={this.props.selectedResource.subtitle}
              userName={this.props.selectedResource.creationuserfullname}
              type={'Novedad'}
              document={this.props.selectedResource.document}
              video={this.props.selectedResource.video}
              articleImage={this.props.selectedResource.headerimageurl}
              userImage={this.props.selectedResource.creationuserimageurl}
              sliderImages={this.props.selectedResource.newsimagesurls}
              content={this.props.selectedResource.body}
              boldContent={this.props.selectedResource.boldparagraph}
              publicationdate={this.props.selectedResource.publicationdate}
              publicationendshowingdate={this.props.selectedResource.publicationendshowingdate}
              onDelete={() =>
                this.setState({
                  deleteConfirmation: true,
                  publicationToDelete: this.props.selectedResource.id
                })

              }
              onEdit={() =>
                this.setState({
                  isEdit: true,
                  isEditEvent: false
                })
              }
              extended
            />
          )
            : (
              <Event
                title={this.props.selectedResource.title}
                subTitle={this.props.selectedResource.subtitle}
                userName={this.props.selectedResource.creationuserfullname}
                type={'Evento'}
                document={this.props.selectedResource.document}
                video={this.props.selectedResource.video}
                articleImage={this.props.selectedResource.headerimageurl}
                sliderImages={this.props.selectedResource.newsimagesurls}
                userImage={this.props.selectedResource.creationuserimageurl}
                content={this.props.selectedResource.body}
                publicationdate={this.props.selectedResource.publicationdate}
                publicationendshowingdate={this.props.selectedResource.publicationendshowingdate}
                //publicationstartshowingdate={this.props.selectedResource.publicationstartshowingdate}
                onDelete={() =>
                  this.setState({
                    deleteConfirmation: true,
                    publicationToDelete: this.props.selectedResource.id
                  })
                }
                onEdit={() =>
                  this.setState({
                    isEdit: true,
                    isEditEvent: true
                  })
                }
                extended
              />
            )
        ) : (
          <label>{'Seleccione un elemento'}</label>
        )}
      </DetailContainer>
    </DetailWrapper>
  )

  render() {
    if (this.props.loading) {
      return (
        <LoaderWrapper>
          <Loader small />
        </LoaderWrapper>
      )
    }
    else {
      return (
        <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Feed'>
          <Wrapper>

            <SectionHeader title={this.state.view[0].toUpperCase() + this.state.view.slice(1)}>
              <HeaderWrapper>
                <Select
                  value={this.state.neighbourhoodSelected}
                  onChange={this.handleChangeNeighbourhood}
                  options={this.props.neighbourhoods}
                />
              </HeaderWrapper>
              <Button large onClick={() => this.toggleNewPublication()}> Nueva publicación</Button>
            </SectionHeader>
            {(this.state.newPublication || this.state.isEdit) && <NewPublication isNewModal={true} onClose={this.handleFormClose} isEdit={this.state.isEdit} publicationToEdit={this.props.selectedResource} isEvent={this.state.isEditEvent} />}
            {
              this.state.deleteConfirmation && (
                <DeleteConfirmation
                  title={'¿Eliminar publicación?'}
                  onBtnClose={() => this.handleDeleteConfirmationClose()}
                  btnContent={'Eliminar'}
                  onBtnClick={() => this.onDelete(this.state.publicationToDelete)}
                  onLoading={this.state.view === "articulos"
                    ? this.props.loadingDeleteArticle
                    : this.props.loadingDeleteEvent}
                  onError={
                    this.state.view === "articulos"
                      ? this.props.errorDeleteArticle
                      : this.props.errorDeleteEvent
                  }
                  errorMessage={
                    this.state.view === "articulos"
                      ? this.props.errorDeleteArticleMessage
                      : this.props.errorDeleteEventMessage
                  }
                />
              )
            }
            <ContentWrapper>
              {(() => {
                switch (this.state.view) {
                  case 'articulos':
                    return (

                      <>
                        <ResourceList
                          elements={this.props.articles}
                          onClick={this.handleResourceClick}
                          selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
                          noMoreContentMsg={`No hay más Artículos disponibles`}
                          fetchMoreContent={() => this.props.fetchFeedNews()}
                          hasMore={this.props.hasMoreArticles}
                        />
                      </>
                    );
                  case 'eventos':
                    return (
                      <ResourceList
                        elements={this.props.events}
                        onClick={this.handleResourceClick}
                        selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
                        noMoreContentMsg={`No hay más Eventos disponibles`}
                        fetchMoreContent={() => this.props.fetchFeedNews()}
                        hasMore={this.props.hasMoreEvents}
                      />
                    );

                  default:
                    return (
                      <>
                        <ResourceList
                          elements={this.props.articles}
                          onClick={this.handleResourceClick}
                          selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
                          noMoreContentMsg={`No hay más Artículos de disponibles`}
                          fetchMoreContent={() => this.props.fetchFeedNews()}
                          hasMore={this.props.hasMoreArticles}
                        />

                      </>
                    );
                }
              })()}
              {this.renderDetail()}
            </ContentWrapper>
          </Wrapper>
        </NavBar >
      )
    }

  }
}
const mapStateToProps = ({
  feed,
  selectedResource,
  userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
}) => {
  return {
    events: mapFeedToNovelty(feed.eventList),
    articles: mapFeedToNovelty(feed.articleList),
    loadingEvents: feed.loadingEvents,
    loadingArticles: feed.loadingArticles,
    loading: feed.loadingEvents || feed.loadingArticles,
    hasMoreEvents: feed.hasMoreEvents,
    hasMoreArticles: feed.hasMoreArticles,
    loadingDeleteArticle: feed.loadingDeleteArticle,
    deletedArticle: feed.deletedArticle,
    errorDeleteArticle: feed.errorDeleteArticle,
    errorDeleteArticleMessage: feed.errorDeleteArticleMessage,
    loadingDeleteEvent: feed.loadingDeleteEvent,
    deletedEvent: feed.deletedEvent,
    errorDeleteEvent: feed.errorDeleteEvent,
    errorDeleteEventMessage: feed.errorDeleteEventMessage,
    loadingDelete: feed.loadingDeleteEvent || feed.loadingDeleteArticle,
    neighbourhoods: mapNeighbourhoods(neighbourhoods),
    selectedNeighbourhood,
    selectedResource
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFeedEvent,
      fetchFeedNews,
      setSelectedResource,
      resetSelectedResource,
      deleteArticleById,
      setNeighbourhood,
      deleteEventById,
      deleteArticleReset,
      deleteEventReset
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Feed)
)
