import styled from 'styled-components'; 
import mediaQueries from '../../../utils/media-queries'

export const SelectWrapper = styled.div`
  width: 70%;
  font-weight: normal;
`

export const Row = styled.div`
width: ${({ width }) => width || '100%'};
    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    flex-wrap: wrap;
    ${({ paddingBottom })=> paddingBottom?'padding-bottom:' + paddingBottom:''}
`

export const LabelWrapper = styled.div`
    width: 100%;
    
    justify-content: space-around;
`


export const FormContainer =  styled.div`
    width: 75%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-bottom:0.3em; 
    ${mediaQueries.laptopL` 
    width:90%
    `} 

`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || '350px'};
  display: flex;
  padding:0.3em;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  margin:0 !important;
  .react-datepicker-wrapper {
    width: 70%
    .react-datepicker__input-container {
      width: 100%;
      input{
        width:100%; 
      }
    }
  }
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-weight:${({ fontWeight }) => fontWeight || 'normal'};
  padding-bottom: 5px;
  margin-right:1em;
  display: inline;
`
export const TableWrapper = styled.div`
    position:relative;
    min-height:250px;
    margin-top:10px;
`