import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import styled from 'styled-components';
import SignOut from '../assets/icons/logout.png';
import Settings from '../assets/icons/settings.png';
import Users from '../assets/icons/users.png';
import { logout } from '../store/actions/auth.actions'
import { setNeighbourhood } from '../store/actions/userInfo.actions'
import { saveCacheUserInfo } from '../utils/session'
import {
  versionNumber
} from '../constants/versionNumber'
const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c38;
`
const SideModalContainer = styled.div`
  z-index: 99999999;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 46px;
  display: flex;
  border-radius: 6px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  width: 174px;
  height: 159px;
  &::after {
    content: '';
    top: -39px;
    width: 0px;
    height: 15px;
    position: absolute;
    left: calc(50% - 5px);
    border-bottom: 9px solid #FFFFFF;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 15px solid transparent;
  }
`
const Option = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  font-size: 13px;
  font-weight: 300;
  color: #000000;
  border-bottom: 2px solid #f2f2f2;
  &:last-child {
    border-bottom: 0px;
  }
  &:hover {
    background-color: #0000000f;
    &:last-child {
      border-radius: 0px 0px 6px 6px;
    }
    &:first-child {
      border-radius: 6px 6px 0px 0px;
    }
  }
  
`

const Label = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: #000000;
  border-bottom: 2px solid #f2f2f2;
  margin: 0px 8px; 
  &:last-child {
    border-bottom: 0px;
  }
`

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0px 8px; 
`

class KitDetails extends React.Component {

  logout = () => {
    localStorage.clear()
    this.props.logout()
  }

  componentDidUpdate = prevProps => {
    if (
      prevProps.userInfo.selectedNeighbourhood.guid !==
      this.props.userInfo.selectedNeighbourhood.guid
    ) {
      saveCacheUserInfo(this.props.userInfo)
    }
  }

  render() {
    return (
      <>
        <SideModalContainer>
          <Option><Icon src={Settings} />Ajustes de cuenta</Option>
          <Option><Icon src={Users} />Usuarios y permisos</Option>
          <Option onClick={this.logout}><Icon src={SignOut} />Cerrar sesion</Option>
          <Label>Version: {versionNumber}</Label>
        </SideModalContainer>
        <GrayedArea>
        </GrayedArea>
      </>
    );
  }
}

const mapStateToProps = ({ userInfo }) => ({
  userInfo
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNeighbourhood,
      logout
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KitDetails))


