import React from 'react';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import styled from 'styled-components';

const Container = styled.div`
    padding: 1%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    // box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 10px;
    background-color: ${({ blue, theme }) => blue ? theme.colors.blueRibbon : theme.colors.white};
`

const Header = styled.div`
    width: 100%;
    height: 150px;
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
    border-radius: 10px;
`

const Body = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
`

const Title = styled.div`
    font-size: 25px;
    font-weight: 500;
    color: ${({ green, theme }) => green ? '#30735f' : theme.colors.blueRibbon};
    align-self: center;
    margin-top: 15px;
`

const AdviceMessage = styled.label`
    font-size: 14px;
    font-weight: 400;
    color: ${({ green, theme }) => green ? '#30735f' : theme.colors.dustyGray};
    align-self: center;
`
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: ${({ green, theme }) => green ? '#30735f' : theme.colors.blueRibbon};
    align-self: center;
`
const RowItem = styled.div`
    width: 100%;
    padding: ${({ padding }) => padding ? padding : '3% 0%'};
    color: black;
    font-weight: 400;
    display: flex;
    justify-content: ${({ justify }) => justify};
`

const StyledButton = styled.button`
    background-color: ${({ theme, invert }) => invert ? theme.colors.white : '#30735f'};
    border:  ${({ theme, invert }) => invert ? ('1px solid #30735f') : theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme, invert }) => invert ? '#30735f' : theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    width: 45%;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    margin: 2%;
    padding: 2%;
   

    &:hover{
        border: ${({ theme }) => "solid 0.5px " + theme.colors.blueRibbon};
        transition: all 0.3s ease;
    }
`
const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

const CheckIcon = styled.img`
    width: 80px;
    color: white;
`

const CloseIcon = styled.img`
    width: 20px;
    color: white;
    align-self: flex-end;
    cursor: pointer;
`

const SpinerWrapper = styled.div`
  margin:auto;
  padding-top:4em;
  padding-bottom:4em;
`

function ModalDelete(props) {
    const { isLoading } = props
    return (
        <Modal onClose={props.onClose} width={'580px'} minheight={true}>
            <Container>
                <Body>
                    {isLoading ? <SpinerWrapper><Loader /></SpinerWrapper>
                        :
                        <>
                            <RowItem justify="center">
                                <Title green={true}>
                                    {props.title}
                                </Title>
                            </RowItem>
                            {props.adviceMessage &&
                                <RowItem justify="center" padding={"0px"}>
                                    <AdviceMessage green={true}>
                                        {props.adviceMessage}
                                    </AdviceMessage>
                                </RowItem>
                            }
                            <RowItem justify="center">
                                <Subtitle green={true}>
                                    {props.subTitle}
                                </Subtitle>
                            </RowItem>
                            <ButtonWrapper>
                                <StyledButton invert={true} onClick={props.onClose}>
                                    Cancelar
                            </StyledButton>
                                <StyledButton green={true} onClick={props.onSubmit}>
                                    {props.buttonMessage}
                                </StyledButton>
                            </ButtonWrapper>
                        </>
                    }
                </Body>
            </Container>
        </Modal >
    )
}

export default ModalDelete