import React from 'react';
import Webcam from 'react-webcam';
import Modal from '../Modal';
import Button from '../Button';
//import CameraIcon from '../../assets/images/group-11.svg'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearIcon from '@material-ui/icons/Clear';
import {
  ContainerWebcam,
  Header,
  Title,
  WebCapture,
  ShowCaptured,
  CloseIcon,
  Footer,
  RedoButton,
  CaptureButton,
  ContinueButton
} from './styled'

class WebcamModal extends React.Component {
  state = { imageData: null }

  setRef = (webcam) => {
    this.webcam = webcam;
  }

  captureImage = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({
      imageData: imageSrc
    })
  };

  redoImage = (e) => {
    e.persist();
    this.setState({
      imageData: null
    })
  }

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user'
    };

    return (
      <Modal traslucid={true} style={{ width: '45%' }} onClose={this.props.onClose}>

        <Title>Foto del dorso del DNI</Title>
        <WebCapture>
          {
            !this.state.imageData ?
              <Webcam
                audio={false}
                height={660}
                ref={this.setRef}
                screenshotFormat="image/jpeg"
                width={570}
                videoConstraints={videoConstraints}
              />
              :
              <ShowCaptured>
                <img src={this.state.imageData} />
              </ShowCaptured>
          }
        </WebCapture>
        <Footer>
          <Button type="button" inverted={true} onClick={this.redoImage}>Volver a sacar</Button>
          <Button type="button" disabled={this.state.imageData} onClick={this.captureImage} style={{ marginLeft: '10px', marginRight: '10px' }}>
            <CameraAltIcon />
          </Button>
          <Button type="button"
            disabled={!this.state.imageData}
            onClick={() => this.props.retrieveImage(this.state.imageData)}>
            Continuar
                  </Button>
        </Footer>

      </Modal>
    )
  }
}

export default WebcamModal;