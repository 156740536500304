import React from 'react'

import CheckedImg from '../../assets/icons/check.png'

import inputTypes from '../../constants/inputTypes'

import {
    AttachedContainer,
    CheckedElement,
    DocumentImg,
    FileInputWrapper,
    StyledFileInput
} from './styled'

class FileInput extends React.Component {

    state = {
        url: "",
        loading: false,
        acceptTypes: "",
        fileName: "",
        withName: false
    }

    componentDidUpdate = prevProps => {
        if (this.props.value !== prevProps.value) this.setState({ url: this.props.image })
    }

    componentDidMount = () =>{
        this.setState({acceptTypes : this.props.acceptTypes })
        this.setState({withName : this.props.withName })
    }

    hasAttachedContent = () => this.state.url

    fileSelectedHandler = async e => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            if(this.state.withName){
                let data = {
                    file: reader.result,
                    fileName: this.state.fileName
                }
                this.props.onChange(data)
            }else{
                this.props.onChange(text)
            }
            this.setState({ text: text })
        };
        reader.readAsText(e.target.files[0])
        this.setState({ fileName: e.target.files[0].name})
        return reader
    } 

    render() {
        return (
            <FileInputWrapper>
                {this.props.onChange && (
                    this.state.acceptTypes !== "" && this.state.acceptTypes !== undefined ? (
                        <StyledFileInput type={inputTypes.FILE} accept={this.state.acceptTypes} onChange={this.fileSelectedHandler} />
                    ) : 
                    (
                        <StyledFileInput type={inputTypes.FILE} onChange={this.fileSelectedHandler} />
                    )
                    
                )}
                <AttachedContainer>
                    {this.state.text ? <CheckedElement src={CheckedImg} /> : ""}
                    <DocumentImg src={this.state.url || this.props.nofile} />
                </AttachedContainer>
            </FileInputWrapper>
        )
    }
}

export default FileInput