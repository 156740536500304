export const convertFileInBase64 = event => {
  return new Promise((resolve, reject) => {
    const inputFile = event.target.files[0]
    let temporaryFileReader = new FileReader()
    temporaryFileReader.onloadend = () => {
      resolve({
        extension: getFileExtension(inputFile),
        url: temporaryFileReader.result,
        base64Value: getBase64Value(temporaryFileReader)
      })
    }
    temporaryFileReader.onerror = () => {
      reject()
    }
    if (inputFile) temporaryFileReader.readAsDataURL(inputFile)
  })
}

export const readFile = event => {
  return new Promise((resolve, reject) => {
    const inputFile = event.target
    let temporaryFileReader = new FileReader();
    temporaryFileReader.onload = async (e) => {
      const text = (e.target.result)
    };
    temporaryFileReader.readAsText(inputFile);
    temporaryFileReader.onerror = () => {
      reject()
    }
    if (inputFile) temporaryFileReader.readAsDataURL(inputFile)
  })
}

export const readFileTyC = event => {
  return new Promise((resolve, reject) => {
    const inputFile = event.target.files[0]
    let temporaryFileReader = new FileReader()
    temporaryFileReader.onloadend = () => {
      resolve({
        extension: getFileExtension(inputFile),
        base64Value: temporaryFileReader.result.replace(/^data:.+;base64,/, '')
      })
    }
    temporaryFileReader.onerror = () => {
      reject()
    }
    if (inputFile) temporaryFileReader.readAsDataURL(inputFile)
  })
}

export const readImages = event => {
  return new Promise((resolve, reject) => {
    const inputFile = event.target.files[0]
    let temporaryFileReader = new FileReader()
    temporaryFileReader.onloadend = () => {
      resolve({
        extension: getFileExtension(inputFile),
        url: temporaryFileReader.result,
        base64Value: getBase64Value(temporaryFileReader),
        ImageBase64: getBase64Value(temporaryFileReader),
      })
    }
    temporaryFileReader.onerror = () => {
      reject()
    }
    if (inputFile) temporaryFileReader.readAsDataURL(inputFile)
  })
}

export const readPdfFeed = event => {
  return new Promise((resolve, reject) => {
    const inputFile = event.target.files[0]
    let temporaryFileReader = new FileReader()
    temporaryFileReader.onloadend = () => {
      resolve({
        name: inputFile.name,
        extension: getFileExtension(inputFile),
        image_base_64: temporaryFileReader.result.split(',')[1],
        ImageBase64: temporaryFileReader.result.split(',')[1]
      })
    }
    temporaryFileReader.onerror = () => {
      reject()
    }
    if (inputFile) temporaryFileReader.readAsDataURL(inputFile)
  })
}


export const getFileExtension = file =>
  file.name
    .split('.')
    .pop()
    .toLowerCase()

export const getBase64Value = fileReader =>
  fileReader.result.replace(/^data:image\/[a-z]+;base64,/, '')
