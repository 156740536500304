import styled from 'styled-components'
import theme from "../../config/theme"
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color:  ${({ success }) => (success ? theme.colors.midgray : theme.colors.red)};
  font-size: 12px;
  margin: 5px 0 0 3px;
`
export const Wrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
`