import React from 'react';
import SideWindow from '../../components/SideWindow';
import StatusBadge from '../../components/StatusBadge';
import Spinner from 'react-spinner-material'
import StyledTabs from '../../components/StyledTabs'

import EmptyLoader from "../../assets/animation/loader.gif"
import LoaderImage from '../../components/LoaderImage';


import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Employees from '../../assets/icons/employees.png';
import styled from 'styled-components';

import {
  Column,
  Row,
  TypographyImg,
  Br,
  VehicleInformation,
  Header,
  ErrorMessage,
  Body, DataContainer
} from './styled';


const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0px 8px; 
`
class FamilyMembersDetail extends React.Component {

  state = {


    tabSelected: 0,


  }


  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }


  render() {
    return (
      <SideWindow
        headerBackgroundColor={'#30735f'}
        title={'Detalle del Padrón'}
        onClose={() => this.props.onClose('familyDetails')}
      >
        <Column>
          <Row>
            {this.props.rowData.avatar ? <img src={this.props.rowData.avatar} alt={this.props.rowData.fullname} /> : <> </>}

            {this.props.rowData.fullname ? <h1>{this.props.rowData.fullname}</h1> : <> </>}
          </Row>

          <StyledTabs
            value={this.state.tabSelected}
            tabs={[
              { label: 'Familia', value: -1, id: 0 },
              { label: 'Vehículo', value: -1, id: 1 }
            ]}
            handleTabChange={this.handleTabChange}
          />
          <br />
          <br />
          {(
            () => {
              switch (this.state.tabSelected) {
                case 0:
                  return (
                    this.props.padron.loadingFetchPropietaryFamily ?
                      <LoaderImage small={true} src={EmptyLoader} />
                      :
                      this.props.padron.fetchPropietaryFamilySuccess && this.props.familia.length > 0 ?
                        <>
                          {this.props.familia.map(
                            function (item, i) {
                              return (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={'panelHeader' + i}
                                    id={'panelHeader' + i}>
                                    <Typography>
                                      <TypographyImg>
                                        {item.picture_url ? <img src={item.picture_url} alt={item.name + ' ' + (item.surname ? item.surname : "")} /> : null}
                                      </TypographyImg>
                                      <h4>{item.name + ' ' + (item.surname ? item.surname : "")}{(!item.hasOwnProperty('animal_type')) ? <Icon src={Employees} /> : ''}</h4>

                                    </Typography>
                                  </AccordionSummary>

                                  <AccordionDetails>
                                    {item.hasOwnProperty('animal_type') ?
                                      (
                                        <DataContainer>
                                          <Typography>Tipo de animal</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.animal_type}</Typography>

                                          <Typography>Años</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.age}</Typography>

                                          <Typography>Tamaño</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.size}</Typography>

                                          <Typography>Color</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.color}</Typography>

                                          <Typography>Pelaje</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.pelage_type}</Typography>

                                          <Typography>Última vacunación</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.last_vaccination}</Typography>

                                          {/* <Typography>sterilized</Typography>
                                        <Typography style={{ textAlign: 'right' }}>{item.sterilized}</Typography> */}

                                        </DataContainer>
                                      ) :
                                      (
                                        <DataContainer>
                                          <Typography>DNI</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.dni}</Typography>

                                          <Typography>Teléfono</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.phone_number}</Typography>

                                          <Typography>Mail</Typography>
                                          <Typography style={{ textAlign: 'right' }}>{item.email}</Typography>
                                        </DataContainer>
                                      )
                                    }
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })
                          }
                        </>
                        :
                        <>{this.props.padron.fetchPropietaryFamilyFail ? <><Typography>{'Error:'}</Typography><ErrorMessage>{this.props.padron.fetchPropietaryFamilyFail.error.data.message}</ErrorMessage></> : 'No hay datos para familia.'}</>
                  );




                case 1:
                  return (
                    this.props.padron.loadingFetchPropietaryVehicle ?
                      <LoaderImage small={true} src={EmptyLoader} />
                      :
                      this.props.padron.fetchPropietaryVehicleSuccess && this.props.vehiculo.length > 0 ?
                        <>
                          {this.props.vehiculo.map(
                            function (item, i) {
                              return (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={'panelHeader' + i}
                                    id={'panelHeader' + i}>
                                    <Typography>
                                      {item.picture_url ? <img src={item.picture_url} alt={item.name + ' ' + item.surname} /> : null}
                                      <h4>{item.vehicle_brand_name + ' Patente: ' + item.plate_number}</h4>
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <DataContainer>
                                      <Typography>Marca</Typography>
                                      <Typography style={{ textAlign: 'right' }}>{item.vehicle_brand_name}</Typography>
                                      <Typography>Modelo</Typography>
                                      <Typography style={{ textAlign: 'right' }}>{item.vehicle_brand_name}</Typography>
                                      <Typography>Patente</Typography>
                                      <Typography style={{ textAlign: 'right' }}>{item.plate_number}</Typography>
                                      <Typography>Color</Typography>
                                      <Typography style={{ textAlign: 'right' }}>{item.color}</Typography>
                                    </DataContainer>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })
                          }
                        </>
                        :
                        <>{this.props.padron.fetchPropietaryVehicleFail ? <><Typography>{'Detalle Error:'}</Typography><Typography>{this.props.padron.fetchPropietaryVehicleFail.error.data.message}</Typography></> : 'No hay datos para vehículos.'}</>
                  );




              }
            })
            ()}
        </Column>
      </SideWindow >

    )
  }

}

export default FamilyMembersDetail