import styled, { css } from 'styled-components'



export const Container = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #F8F8F8;
`

export const Title = styled.div`
    color: #000000;
    font-family: Roboto;
    font-size: 24px;
    font-weight: ${({ bold }) => (bold ? 600 : 400)};
    width: 90%;
    height: 6vh;
    text-align: left;
    display: flex;
    align-items: center;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row ;
    align-items: center;
    width: 80%;
    justify-content: ${({ justify }) => justify || 'center'};
    background-color: ${({ background }) => background || 'white'};
    border-radius: 5px;
    margin: 1% 0%;
  
`
export const Column = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    
`
export const ColumnMain = styled.div`
    width: 49%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    
    background-color: white; border-radius: 10px;
    box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);  
  
`

export const PedidoNumber = styled.div`
  display: flex;
  background-color: rgba(210, 210, 210, 0.2);
  border-radius: 5px;
  width: 98%;
  padding: 0% 2%;
  margin: 1% 0%;
  height: 5vh;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
`

export const Total = styled.div`
    width: auto;
    font-size: 18px;
    font-weight: ${({ bold }) => bold ? "600" : '300'};
    line-height: 11.44px;
    margin: 2% 0%;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    color: ${({ color }) => color || 'black'};
`

export const ContainerTable = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 40% 35% 35%;
    grid-template-rows: auto;
    grid-template-areas: 
        "headerProduct headerCantidad headerPrice"
        "products cantidad precio"
        "subtotal . precio"
        "total . precio";

`

export const HeaderProducts = styled.div`
    grid-area: headerProduct;
    border-bottom: 3px solid #15994C;
    color: #15994C;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
`
export const HeaderCantidad = styled.div`
    grid-area: headerCantidad;
    border-bottom: 3px solid #15994C;
    color: #15994C;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
`
export const HeaderPrice = styled.div`
    grid-area: headerPrice;
    border-bottom: 3px solid #15994C;
    color: #15994C;
    font-size: 20px;
    line-height: 25px;
    text-align: right;
    width: 18vh;
`
export const Box = styled.div`
    border: 1px solid #15994C;
    border-radius: 5px;
    width: 15vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 2% 0%;
    padding: 3%;
`
export const Text = styled.div`
    font-size: 18px;
    font-weight: 600;
`
export const Name = styled.div`
    grid-area: products;
    padding: 5%;
    font-size: 18px;
    line-height: 16.44px;
    text-align: left;

`
export const Subtotal = styled.div`
    grid-area: products;
    padding: 5% 0%;
    font-size: 18px;
    line-height: 16.44px;
    text-align: left;
    font-weight: 600;
`
export const Price = styled.div`
    grid-area: precio;
    font-size: 18px;
    padding: 5% 0%;
    width: 8vw;
    line-height: 16.44px;
    text-align: right;
    color: #4A494A;  

`
export const Quantity = styled.div`
    grid-area: cantidad;
    font-size: 18px;
    padding: 5% 0%;
    line-height: 16.44px;
    text-align: left;
    color: #4A494A;  
  

`