import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchForgotPassword,fetchForgotPasswordReset } from '../../../store/actions/auth.actions'
import PropTypes from 'prop-types'
import Input from '../../../components/Input';
import {Container,StyledImg, Wrapper,Title,Subtitle,ButtonStyled,ErrorLabel,ButtonBack} from './styled'
import { Formik } from 'formik'
import SecondStep from "./secondStep"
import FirstStep from "./firstStep"
import backgroundImage from '../../../assets/images/backgroundLogin.svg';
import * as Yup from 'yup'


class Forgot extends Component {
  state={
    step: 1,
  }

  componentDidMount=()=>{
    this.props.fetchForgotPasswordReset()
  }
  getInitialValues = () => ({
    inputUserName:'',
 })
 
  getSignUpSchema = () =>
    Yup.object().shape({
      inputUserName: Yup.string().required('Usuario requerido'),
    })

  NextStep = step => {
    this.setState({step: step})
  };

  render() {
    console.log(this.state)
    return(
      <>
        <StyledImg src={backgroundImage} />
        {(() => {
          switch (this.state.step) {
            case 1:
              return <FirstStep 
                        NextStep={e => this.NextStep(e)}
                        Username={username=> this.setState({username: username})}
                        OnClickBack={()=> this.props.OnClickBack()}
                      />;
            case 2:
              return  <SecondStep 
                        NextStep={e => this.NextStep(e)}
                        Username={this.state.username}
                        OnClickBack={()=> this.props.OnClickBack()}
                      />;
            // case 3:
            //   return <>{"hola"}</>;
            // default:
            //   return null;
          }
        })()}
       
      </>
    )
  }
}


const mapStateToProps = state => {
  return {
    forgotPassword: state.auth.forgotPassword,
    errorForgotPasswordMessage: state.auth.errorForgotPasswordMessage,
    errorForgotPassword: state.auth.errorForgotPassword,
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForgotPassword,fetchForgotPasswordReset
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forgot)
