import React, { Component } from 'react'
import {
  Container,
  LabelContainer,
  DetailContainer,
  DetailItemContainer,
  DetailContainerError,
  DetailItem,
  StyledButton,
} from './styled';
import Button from '../../../../components/Button';

import Spinner from 'react-spinner-material';

class SecondStep extends Component {
  render() {

    return (
      <Container>
        <LabelContainer>Botón de pago</LabelContainer>
        <DetailContainer>

          {
            this.props.billetera.loadingTransactionQR ?
              <DetailItemContainer centered={true}>
                <Spinner />
              </DetailItemContainer>
              :
              this.props.QR ?
                <DetailItemContainer centered={true}>
                  <img  height={'400px'} width={'400px'} src={'data:image/png;base64,' + this.props.QR} />
                </DetailItemContainer>
                :
                <DetailContainerError centered={true}>
                  {this.props.billetera.errorTransactionQR}
                </DetailContainerError>
          }


          <DetailItemContainer>
            <DetailItem> Concepto: </DetailItem>
            <DetailItem bold={true} > {this.props.transaction.concept} </DetailItem>
          </DetailItemContainer>
          <DetailItemContainer>
            <DetailItem> Importe a cobrar: </DetailItem>
            <DetailItem bold={true} > ${this.props.transaction.amount} </DetailItem>
          </DetailItemContainer>
        </DetailContainer>
        {/*
            <DetailContainer centered={true}>
              <DetailItemContainer>
                <DetailItem bold={true} margin={'5% 9%;'}  > Tambien podes compartir el botón de pago al e-mail y a la App del destinatario </DetailItem>
                <Button invert={true}>Enviar botón de pago </Button> 
              </DetailItemContainer>  
            </DetailContainer>*/}
            <br/>
        <Button onClick={() => this.props.BackStep()} >{'Volver'}</Button>
        
      </Container>
    )
  }
}

export default SecondStep
