import styled from 'styled-components'

const LoaderImage = styled.img`
  ${({ small }) => small && `
    width: 25px;
  `}

  ${({ medium }) => medium && `
    width: 500px; 
  `}

  ${({ large }) => large && `width: 100px;`}

  ${({ width }) => width && `width: ${width}};`}
` 
 export default LoaderImage;