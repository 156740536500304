import styled from 'styled-components';
import theme from '../../../../config/theme';

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const Image = styled.img`
    cursor: pointer;
    padding-right: inherit;
`

export const InputWrapper = styled.div`
width: ${({ width }) => width || 100}%;
display: flex;
align-items: flex-start;
align-items: center;
`

export const InputLabel = styled.label`
display: block;
font-family: ${({ theme }) => theme.fonts.roboto};
color: ${({ theme }) => theme.colors.black};
font-size: 16px;
margin-right: 10px;
`

export const SelectWrapper = styled.div`
width: 300px;
font-weight: normal;
`

export const TableWrapper = styled.div`
    position:relative;
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`