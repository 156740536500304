import React from 'react'
import CheckedImg from '../../assets/icons/check.png'
import inputTypes from '../../constants/inputTypes'
import { readImages, readPdfFeed } from '../../utils/fileManager'
import {
    AttachedContainer,
    CheckedElement,
    DocumentImg,
    FileInputWrapper,
    StyledFileInput,
    FileInputContainer,
    DeleteButton,
    DeleteButtonContainer,
    TooLongMessage
} from './styled'


class PdfFileUpload extends React.Component {
    state = {
        url: "",
        loading: false,
        acceptTypes: this.props?.acceptTypes || "",
        fileName: "",
        withName: false,
        isTooLong: false,
        wasDeleted: false,
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.value !== prevProps.value) this.setState({ url: this.props.image })   
        if(this.state.name !== prevState.name){
            const result = this.getFileType(this.state.extension)
            if(!result){
                this.props.setHasErrorExtension(true);
            } else {
                this.props.setHasErrorExtension(false);
            }
        }    
    }


    getFileType = type => {
       return this.props.acceptTypes.includes(type);
    }

    componentDidMount = () => {
        this.setState({ acceptTypes: this.props.acceptTypes })
        this.setState({ withName: this.props.withName })
        this.setState({ acceptTypes: this.props.acceptTypes })

    }

    formatContent = (content) => content ? (content.length > 15 ? `${content.substring(0, 15)}...` : content) : null

    hasAttachedContent = () => this.state.url

    validateFile = (long) => {
        if(long>10485760){
            return 0;
        }else{
            return 1;
        }
    }

    fileSelectedHandler = async event => {
        this.setState({isTooLong: false,fileName:"",acceptTypes:"",wasDeleted: false})
        const attachFile = this.props.pdf ? await readPdfFeed(event) : await readImages(event)
        if(this.validateFile(attachFile.ImageBase64.length)){
            if (this.props.onChange) this.props.onChange(attachFile,false)
            this.setState({ ...attachFile })
        }else{
            this.setState({
            url: "",
            loading: false,
            acceptTypes: this.props.acceptTypes,
            fileName: "",
            withName: false,
            isTooLong: true,
            extension: null,
            ImageBase64:null})
        }
    }

    hasAttachedContent = () => this.state.url

    deleteFile = () =>{
        if (this.props.onChange) this.props.onChange(
            {url: "",
                loading: false,
                acceptTypes: this.props.acceptTypes,
                fileName: "",
                withName: false,
                isTooLong: false,
                extension: null,
                ImageBase64:null}
        ,true)
        this.setState(
            {
                url: "",
                loading: false,
                acceptTypes: this.props.acceptTypes,
                fileName: "",
                withName: false,
                isTooLong: false,
                extension: null,
                wasDeleted:true,
                ImageBase64: null
            }
        )
      }

    render() {

        return (
            <FileInputContainer>
                {(this.props.canBeFileDeleted && !this.state.wasDeleted && ((this.props.value != undefined && this.props.value!=null) && this.props.isEdit) || this.state.ImageBase64) && 
            <DeleteButtonContainer>
                <DeleteButton onClick={()=>this.deleteFile()}>x</DeleteButton>
            </DeleteButtonContainer>}
            <FileInputWrapper height={this.props.height}>
                {this.props.onChange && (
                    this.state.acceptTypes !== "" && this.state.acceptTypes !== undefined ? (
                        <StyledFileInput type={inputTypes.FILE} accept={`${this.state.acceptTypes}`} onChange={this.fileSelectedHandler} />
                    ) :
                        (
                            <StyledFileInput type={inputTypes.FILE} onChange={this.fileSelectedHandler} />
                        )
                )}
                <AttachedContainer>
                    {(((this.props.value != undefined && this.props.value!=null) && this.props.isEdit )|| this.state.ImageBase64) && !this.state.wasDeleted ? <CheckedElement src={CheckedImg} /> : null}
                    <DocumentImg height={this.props.height} src={this.props.nofile} />
                </AttachedContainer>
            </FileInputWrapper>
            {this.state.ImageBase64 != null ?<div style={{color:"rgb(125, 190, 85)",fontSize: "13"+"px",width: "130"+ "%",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"-10"+"px"}}> {this.formatContent(this.state.name)} </div>: null}
            <TooLongMessage margintop="100px" visibility={(this.state.isTooLong?"visible":"hidden")}>El archivo es demasiado pesado</TooLongMessage>
            <TooLongMessage  visibility={(this.props.hasErrorExtension?"visible":null)}>Formato de archivo no soportado</TooLongMessage>
            
            </FileInputContainer>
        )
    }
}

export default PdfFileUpload
