import React from 'react';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CrossIcon from '../../assets/icons/close.png'
import Modal from '../../components/Modal';
import RadioButton from '../../components/RadioButton';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { fetchLotsByNeighbourhood } from '../../store/actions/lotes.actions'
import { fetchEditPerson } from '../../store/actions/padron.actions'

import { SelectWrapper, StyledSelect, AddMore } from './styled';
import { Column } from '../NewsAndEvents/styled';
import { Title, RowForm, LabelForm } from '../Reservations/styled';
import LoaderImage from '../../components/LoaderImage';
import EmptyLoader from "../../assets/animation/loader.gif"
import {
  FormContainer,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  InputWrapperMargin
} from './styled'

class EditResident extends React.Component {
  state = { vehicleFields: false };


  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (

    <FormContainer onSubmit={handleSubmit}>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={49}>
          <InputLabel>{'Nombre'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '90%' }}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'Apellido'}</InputLabel>
          <Input
            value={values.surname}
            onChange={({ target: { value } }) => setFieldValue('surname', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '90%' }}
            error={errors.surname ? true : false}
          />
          {errors.surname && <ErrorLabel>{errors.surname}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={49}>
          <InputLabel>{'DNI'}</InputLabel>
          <Input
            value={values.dni}
            onChange={({ target: { value } }) => setFieldValue('dni', value)}
            placeholder={''}
            type={'text'}
            style={{ width: '90%' }}
            rows={1}
            error={errors.dni ? true : false}
          />
          {errors.dni && <ErrorLabel>{errors.dni}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'CUIL'}</InputLabel>
          <Input
            value={values.cuil}
            onChange={({ target: { value } }) => setFieldValue('cuil', value)}
            placeholder={''}
            style={{ width: '90%' }}
            type={'text'}
            rows={1}
            error={errors.cuil ? true : false}
          />
          {errors.cuil && <ErrorLabel>{errors.cuil}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapperMargin width={30}>
          <InputLabel>{'Barrio'}</InputLabel>
          <Select
            value={values.neighbourhood}
            onChange={option => this.fetchLotsByNeighbourhood(setFieldValue, option)}
            options={this.getNeighbourhoodOptions()}
            placeholder="Buscar..."
            style={{ width: '90%' }}
            error={errors.neighbourhood ? true : false}
          />
          {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
        </InputWrapperMargin>
        <InputWrapper width={30}>
          <InputLabel>{'Lote'}</InputLabel>
          <Select
            value={values.lot}
            style={{ width: '90%' }}
            onChange={option => {
              setFieldValue('lot', option)
            }}
            isMulti={true}
            isLoading={this.props.loadingLots}
            isDisabled={this.props.loadingLots}
            options={this.getLotsOptions()}
            placeholder="Buscar..."
            error={errors.lot ? true : false}
          />
          {errors.lot && <ErrorLabel>{errors.lot}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={30}>
          <InputLabel>{'Titular?'}</InputLabel>
          <Field type="checkbox" name="titular" />
          {errors.titular && <ErrorLabel>{errors.titular}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={49}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            style={{ width: '90%' }}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'Teléfono'}</InputLabel>
          <Input
            value={values.phone}
            onChange={({ target: { value } }) => setFieldValue('phone', value)}
            placeholder={'54 911 ********'}
            type={'text'}
            style={{ width: '90%' }}
            rows={1}
            error={errors.phone ? true : false}
          />
          {errors.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {console.log(values)}
      <ButtonWrapper>
        {this.props.errorPerson ? <ErrorMessage>{this.props.errorPerson}</ErrorMessage> : ''}
        <StyledButton type={'submit'}>
          {this.props.loadingNewProprietary ? <LoaderImage small={true} src={EmptyLoader} /> : 'Editar propietario'}
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )
  handleSubmit = async data => {
    console.log(data)
    let arrayLotes = data.lot ? data.lot.map(function (lot, i) { return { PropertyId: lot.value } }) : null

    // const editProprietaryData = {
    //   Properties: data.lot,
    //   picture_base64: null,
    //   picture_extension: null,
    //   name: data.name,
    //   gender: 2,
    //   surname: data.surname,
    //   dni: data.dni,
    //   cuil: data.cuil,
    //   email: data.email,
    //   PhoneNumber: `+${data.phone}`,
    //   property_id: data.lot,
    //   IsOwner: data.titular,
    //   username: this.props.rowData.username,
    //   id: this.props.rowData.id,
    // }
    const editProprietaryData = {
      Username: this.props.rowData.username,
      Name: data.name,
      Dni: data.dni,
      Email: data.email,
      Surname: data.surname,
      Gender: 2,
      PhoneNumber: `+${data.phone.replace(/[^0-9]/g, "")}`,
      id: this.props.rowData.id,
      ImagePairDto: {
        ImageBase64: null,
        Extension: null
      },
      properties: [{
        PropertyId: this.props.rowData.properties_id[0],
        NeighbourhoodId: parseInt(this.props.rowData.neighbourhoods_id[0])
      }]
    }



    await this.props.fetchEditPerson(editProprietaryData)


    this.props.onClose()

  }

  getSignUpSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required('Ingrese Nombre'),
      surname: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required('Ingrese Apellido'),
      dni: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'DNI invalido')
        .min(7, 'El DNI debe tener un mínimo de 7(siete) caracteres')
        .max(9, 'El DNI debe tener un máximo de 9(nueve) caracteres')
        .required('Ingrese DNI'),
      cuil: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'CUIL invalido')
        .min(11, 'El CUIL debe tener un mínimo de 11(once) caracteres')
        .max(12, 'El CUIL debe tener un máximo de 12(doce) caracteres'),
      email: Yup.string()
        .email('El Email es inválido')
        .required('Ingrese Email'),
      phone: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'Teléfono invalido')
        .required('Ingrese Teléfono'),

      //titular: Yup.boolean().required('Ingrese si es titular'),
      neighbourhood: Yup.object().required('Seleccione un barrio'),
      lot: Yup.array().of(
        Yup.object().shape({
          value: Yup.string(),
        })).min(1, 'Ingrese al menos un barrio').
        test('notNull', 'Ingrese al menos un barrio', items => {
          return items != null;
        }).nullable(),
    })

  fetchLotsByNeighbourhood = (setFieldValue, option) => {
    setFieldValue('neighbourhood', option)
    this.props.fetchLotsByNeighbourhood(option.value)
  }

  getLotsOptions = () => this.props.lotsList.map(l => ({ label: l.code, value: l.id }))

  getNeighbourhoodOptions = () =>
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }))

  formattedSelectedNeighbourhood = () => ({
    value: this.props.selectedNeighbourhood.guid,
    label: this.props.selectedNeighbourhood.name
  })

  getInitialValues = () => ({
    name: this.props.rowData.name,
    surname: this.props.rowData.surname,
    dni: this.props.rowData.dni,
    cuil: this.props.rowData.cuil,
    email: this.props.rowData.email,
    phone: this.props.rowData.phone_number,
    neighbourhood: { label: this.props.rowData.neighbourhoods_name, value: this.props.rowData.neighbourhoods_id },
    lot: this.getLotes()
  })

  getLotes() {
    this.props.rowData.property_code.map(n => (console.log(n)))
    this.props.rowData.propiedades.map(n => (console.log(n)))
    return this.props.rowData.property_code.map(n => ({ label: n, value: n }))


  }



  componentDidMount() {
    this.props.fetchLotsByNeighbourhood(this.props.rowData.neighbourhoods_id)
  }

  render() {

    console.log("props editar", this.props)
    console.log("state editar", this.state)
    console.log("getLotes editar", this.getLotes())

    return (
      <Modal onClose={() => this.props.onClose()}>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}

          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}



const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood },
  padron,
  lotes
}) => ({
  loadingPerson: padron.loadingPerson,
  modifiedPerson: padron.modifiedPerson,
  errorPerson: padron.errorPerson,
  neighbourhoods,
  selectedNeighbourhood,
  lotsList: lotes.lotsList,
  errorMessageNewProprietary: padron.errorMessageNewProprietary,
  loadingLots: lotes.loadingLots
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEditPerson,
      fetchLotsByNeighbourhood
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditResident)
