import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Accordion from '../../../../components/Accordion';
import Button from '../../../../components/Button';
import Select from 'react-select';
import CheckBox from '../../../../components/CheckboxFormik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPadron } from '../../../../store/actions/padron.actions';
import { fetchLots } from '../../../../store/actions/accesos.actions';
import mapProprietaryAcess from '../../../../selectors/mapProprietaryAccess';
import mapLots from '../../../../selectors/mapLots';


import mapNeighbourhoodsMasterData from '../../../../selectors/mapNeighbourhoodsMasterData'
import { fetchNeighbourhoods } from '../../../../store/actions/proveedores.actions';

import {
  Text,
  FormSection,
  ErrorLabel,
  InputWrapper,
  Row,
  ButtonWrapper,
  StyledButton,
  Title,
  InputLabel
} from '../styled';

class NewGuestFirstStep extends Component {
  componentDidMount() {
    console.log("buscamos lotes")

  }

  handleSubmit = (values) => {
    const { index, getFormData } = this.props;
    getFormData(values, index);
  }

  getInitialValues = () => ({
    propietario_lote: this.props.editMode && this.props.editInitialValues.funcional_unit.code ? (this.props.editInitialValues.funcional_unit.code) : (''),
    propietario_apellido_nombre: this.props.editMode && this.props.editInitialValues.username_name_owner ? (this.props.editInitialValues.username_name_owner) : (''),
    notificar_propietario: false,
    phone_number: this.props.editMode && this.props.editInitialValues.phone_number ? (this.props.editInitialValues.phone_number) : ([]),
    owners: this.props.editMode && this.props.editInitialValues.owners ? (this.props.editInitialValues.owners) : ([]),
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      propietario_lote: Yup.string().required('El campo Lote es requerido'),
      notificar_propietario: Yup.bool().oneOf([true], 'Debes notificarle al propietario antes de permitir acceso.'),
    })

  render() {
    const args = { ...this.props };
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleSubmit}
        render={props => <FormikForm {...props} {...args} />}
      />
    )
  }
}

class FormikForm extends Component {
  componentDidMount() {
    if (this.props.editMode) {
      this.props.validateForm()
      this.props.getGuestInviteId(this.props.editInitialValues.guest_invite_id);
    }
    this.props.fetchNeighbourhoods()
  }

  handleChange = (field, values) => {
    // Incoming fields are 'propietario_apellido_nombre' and 'propietario_lote'
    if (!this.props.editMode) {
      this.props.setFieldValue(field, { value: values.value, label: values.label });
      this.props.setFieldValue('phone_number', values.phone_number);
      this.props.setFieldValue('owners', values.owners);
      if (field === 'propietario_apellido_nombre') {
        this.props.setFieldValue('propietario_lote', values.label);
      } else {
        this.props.setFieldValue('propietario_apellido_nombre', values.owner);
      }
    }
  }

  render() {
    const { handleSubmit, values, setFieldValue, errors, isValid, editMode, editInitialValues, mappedLots, mappedProprietaryAcess, loading } = this.props;
    return (
      <Accordion
        title={this.props.title}
        active={this.props.active}
        index={this.props.index}
        changeIndex={this.props.changeIndex}
        formDataValidation={this.props.formDataValidation}
      >
        <form onSubmit={(values) => handleSubmit(values)}>
          <Row>

            <FormSection flex={1}>
              <InputWrapper>
                <Text>Barrio</Text>
                <Select
                  name={'barrio'}
                  value={values.barrio}
                  isSearchable={false}
                  onChange={values => {
                    this.props.fetchLots(values.guid);
                    console.log("data change barrios", values);
                    setFieldValue('barrio', values);
                  }}
                  error={errors.barrio}
                  options={this.props.listBarrios}
                  placeholder="Seleccione un barrio..."
                  isLoading={this.props.loadingListBarrios}
                  autoFocus
                />
                {errors.barrio && <ErrorLabel>{errors.barrio}</ErrorLabel>}
              </InputWrapper>
            </FormSection>


            <FormSection flex={1}>
              <InputWrapper>
                <Text>Lote</Text>
                <Select
                  options={!loading ? mappedLots : [{ value: null, label: "Loading..." }]}
                  placeholder={editMode ? editInitialValues.funcional_unit.property_code : "Lote"}
                  name={'propietario_lote'}
                  value={values.propietario_lote}
                  error={errors.propietario_lote}
                  onChange={value => { this.handleChange('propietario_lote', value) }}
                  isDisabled={editMode}
                >
                </Select>
                {errors.propietario_lote && <ErrorLabel>{errors.propietario_lote}</ErrorLabel>}
              </InputWrapper>
            </FormSection>

            <FormSection flex={2}>
              <InputWrapper>
                <Text>Punto de acceso</Text>
              </InputWrapper>
              <InputWrapper>
                <InputLabel>{this.props.userInfo.selectedNeighbourhood.name}</InputLabel>
              </InputWrapper>
            </FormSection>
          </Row>
          <Row>
            <FormSection flex={2}>
              <InputWrapper>
                <Title>Nombre y apellido: </Title>
              </InputWrapper>
              <InputWrapper>
                {values.owners.map((e, i) => (
                  <InputLabel>{e}</InputLabel>
                ))}
              </InputWrapper>
            </FormSection>
            <FormSection flex={1}>
              <InputWrapper>
                <Title>{'Teléfono'}</Title>
              </InputWrapper>
              <InputWrapper>
                {
                  values.phone_number.map((e, i) => (<InputLabel>{e == "" ? "No registra" : e}</InputLabel>))
                }
              </InputWrapper>
            </FormSection>
            <FormSection flex={2} alignItems={'center'} justifyContent={'center'}>
              <InputWrapper display='flex' margin={'0px'} alignItems={'center'}>
                <Field
                  component={CheckBox}
                  name="notificar_propietario"
                  id="notificar_propietario"
                />
                <Text>{'Notificar al propietario'}</Text>
              </InputWrapper>
              {errors.notificar_propietario && <ErrorLabel>{errors.notificar_propietario}</ErrorLabel>}
            </FormSection>
          </Row>
          <ButtonWrapper>
            {isValid ? <Button type={'submit'}>{'Aceptar'}</Button> : null}
          </ButtonWrapper>
        </form>
      </Accordion>
    );
  }
}

const mapStateToProps = ({
  padron, accesos, userInfo, proveedores
}) => ({
  mappedProprietaryAcess: mapProprietaryAcess(padron.proprietaryList),
  mappedLots: mapLots(accesos.lots),
  userInfo,
  loading: padron.loading,
  userInfo,
  listBarrios: mapNeighbourhoodsMasterData(proveedores.neighbourhoodList),
  loadingListBarrios: proveedores.loadingClientsByNeighbourhood
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPadron, fetchLots, fetchNeighbourhoods
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewGuestFirstStep)

