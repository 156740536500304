import { createSelector } from 'reselect'

let mapMessages = createSelector(  
  messages => messages,
  messages => 
  (messages.map(message => ({
    id:message.id,
    alert_motive: message.alert_motive,
    description: message.description,
    notification_types: message.notification_types,
    neighbourhood: message.neighbourhood.name
  })) )
)
export default mapMessages
