/* eslint-disable react/prop-types */
import DetailModal from "../DetailModal";
import Icon, { IconNames } from "../Icons";
import theme from "../../../config/theme";
import {
  Container,
  TableCellContainer,
  ReservedLine,
  EditButtonContainer,
  AvailablePlacesContainer,
  AvailablePlacesDesc
} from "./styled";
import React, { useEffect, useState, useRef } from "react";
import People from "@material-ui/icons/People";

function TableCell(props) {
  // state = { isDetailOpen: false, isScheduleModalOpen: false };
  const [detailOpen, setDetailOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  var hasAvailablePlaces = props.originalData?.available_places > 1;

  const toggleDetail = () => {
    // setState({ isDetailOpen: !state.isDetailOpen });
    setDetailOpen(!detailOpen);
  };

  const openDetail = () => {
    if (props.booking) {
      // setState({ isDetailOpen: true })
      setDetailOpen(true);
    }
  };

  const closeDetail = () => {
    if (props.booking) {
      // setTimeout(setState({ isDetailOpen: false }), 50000)
    }
  };

  const handleCellClick = async () => {
    // (props.isBooked === false && props.isCancelled === false) ||
    if (props.isBooked || props.edit) {
      toggleDetail();
    }

    if (!props.isBooked && !props.isSchedule && props.isValidCell) {
      props.createScheduleReset();
      props.fetchScheduleDetailReset();
      props.updateScheduleReset();
      props.handleAvailableCellClick(props);
    }

    if (!props.isBooked && props.isSchedule) {
      props.createBookingNeighbourhoodReset();
      props.handleBookingCellClick(props);
    }
  };

  const handleEditIconclick = () => {
    props.createScheduleReset();
    props.updateScheduleReset();
    props.fetchScheduleDetailReset();
    props.fetchScheduleDetail(props.timeRange.schedule_id);
    props.handleAvailableCellClick(props);
  };

  // handleBookingCellClick = async () => {
  //   props.handleBookingCellClick(props);
  // }

  const renderPrice = () => {
    return props.price ? (
      <label>{`$${props.price}`}</label>
    ) : (
      <Icon size={20} name={IconNames["Plus"]} color={theme.colors.midGray} />
    );
  };

  const availablePlacesSection = (
    <AvailablePlacesContainer
      isBooked={props.isBooked}
      fromApp={props.booking != null && props.booking.from_app ? true : false}
      isCancelled={props.isCancelled}
      isSchedule={props.isSchedule}
      initial={props.initial}
      final={props.final}
    >
      <AvailablePlacesDesc
        isBooked={props.isBooked}
        fromApp={props.booking != null && props.booking.from_app ? true : false}
        isCancelled={props.isCancelled}
        isSchedule={props.isSchedule}
        initial={props.initial}
        final={props.final}
      >
        {props.originalData?.booking_quantity + "/" + props.originalData?.available_places}
      </AvailablePlacesDesc>
    </AvailablePlacesContainer>
  );

  const renderEditButton = (
    <EditButtonContainer
      isBooked={props.isBooked}
      fromApp={props.booking != null && props.booking.from_app ? true : false}
      isCancelled={props.isCancelled}
      isSchedule={props.isSchedule}
      initial={props.initial}
      final={props.final}
      onClick={handleEditIconclick}
    >
      <Icon
        size={20}
        name={IconNames["Edit"]}
        // onClick={props.handleEditButtonclick}
        color={
          props.booking != null && props.booking.from_app
            ? theme.colors.borderPink
            : props.isBooked
            ? theme.colors.violet
            : theme.colors.midGray
        }
      />
    </EditButtonContainer>
  );

  return (
    <Container>
      {props.isSchedule && props.initial && hasAvailablePlaces && availablePlacesSection}
      <TableCellContainer
        // ref={el => (inputRef = el)}
        onClick={handleCellClick}
        onBlur={() => {
          !props.edit && closeDetail();
        }}
        isBooked={props.isBooked}
        from_app={props.booking != null ? props.booking.from_app : false}
        isCancelled={props.isCancelled}
        isSchedule={props.isSchedule}
        isEnabled={props.isBooked || (props.isBooked === false && props.isCancelled === false)}
        isAdminBooking={props.booking != null ? props.booking.is_admin_booking : false}
        initial={props.initial}
        final={props.final}
        availablePlaces={hasAvailablePlaces}
      >
        {props.edit &&
          (props.isBooked || (props.isBooked === false && props.isCancelled === false)) &&
          renderPrice()}
        {props.children}
      </TableCellContainer>
      {props.isSchedule && props.initial && renderEditButton}
      {detailOpen ? (
        <DetailModal
          onRangeChange={rangeUpdated => {
            props.onRangeChange(rangeUpdated);
            closeDetail();
          }}
          onRangeCreation={newRange => {
            props.onRangeCreation(newRange);
            closeDetail();
          }}
          onRangeDelete={rangeToDelete => {
            props.onRangeDelete(rangeToDelete);
            closeDetail();
          }}
          dayPos={props.dayPos}
          data={props.data}
          onClose={() => setDetailOpen(false)}
          timeRange={props.timeRange}
          leftPopUp={props.leftPopUp}
          bottomPopUp={props.timeRange.from > "18:30"}
          booking={props.booking}
          isBooked={props.isBooked}
          originalData={props.originalData}
          price={props.price}
          edit={props.edit}
          cerrarModalDetalle={() => handleCellClick()}
          date={props.date}
          selectedSpace={props.selectedSpace}
          startDate={props.startDate}
          selectedCalendarDateTime={props.selectedCalendarDateTime}
        />
      ) : null}
    </Container>
  );
}

export default TableCell;
