import { createSelector } from 'reselect'
var moment = require('moment');

let mapCommunities = createSelector(  
  communities => communities,
  communities => 
  (communities.map(community => ({
    ...community,
    creation_date:moment(community.creation_date).format('DD/MM/YYYY'),
    owner_fullname: community.owner_user.name + ' ' + community.owner_user.last_name,
    communityCategories: community.community_categories.reduce((resultString, category) => resultString + category.name + ',', '').slice(0,-1),
    validationCode:community.validation_code.toString().split('').join(' '),
    communityState: community.community_state.name,
  })) )
)
export default mapCommunities
