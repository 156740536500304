import { createSelector } from 'reselect'
import { getMonthLabel } from '../utils/dateParser'

const format=(tickets)=>{
  var ticketsFormatted = tickets.map(({id,categoryname,categoryid,createdbyname,title,createdbydni,openingdate, lot,stateid},i) => ({
    id: id,
    //title: `${createdbyname} | ${lot}`,
    title: createdbyname,
    titleMessage: title,
    subtitle: categoryname,
    categoryid,
    extraInfo: getMonthLabel(openingdate),
    dni: createdbydni,
    name: createdbyname,
    lote: lot,
    extraColumnsContent:[
    {label:"DNI", info:`${createdbydni? createdbydni: "Indefinido"}`},
    {label:"Lote", info:`${lot? lot: "Indefinido"}`},
    {label:"Ticket", info:`#${id}`}
    ],
    // detail: `Ticket: #${id}`,
    pos: i,
    stateid
  }))
  return(ticketsFormatted)
}

const mapTicket = createSelector(
  tickets => tickets,
  tickets =>
  {
    const pendingTickets = tickets.filter((ticket) => {   
      return ticket.stateid === 2 
     })
    
    const activeTickets = tickets.filter((ticket) => {
      return ticket.stateid === 1 
     })
     
    const closedTickets = tickets.filter((ticket) => {
      return ticket.stateid === 3
     })

     const ticketsList = [format(activeTickets),format(pendingTickets),format(closedTickets)]
     
     return ticketsList
  }
)



export default mapTicket
