import styled from 'styled-components';

const LoginInput  = styled.input`
    font-family: Roboto;
    padding: 8px 10px;
    box-sizing: border-box;
    font-size: ${({ size }) => size || 16}px;
    font-weight: ${({ weight }) => weight || 400};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
    outline: none;

    border-radius: 6px;
    border: solid 1px rgba(151, 151, 151, 0.39);
    background-color: #ffffff;  
    color: #4a4a4a;
    font-style: italic;


    margin: ${({ margin }) => margin ? margin : '2% 0%'};
    width: ${({ width }) => width || "100%"};
    height: ${({ height }) => height || "50px"};
`

export default LoginInput 
