import React from 'react';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik } from 'formik'
import * as Yup from 'yup'

import mapCategoriesByRole from '../../../selectors/mapCategoriesByRole'
import mapLots from '../../../selectors/mapLots';
import mapReportTickets from '../../../selectors/mapReportTickets';

//import { fetchAllCategories, fetchTypesByCategory } from '../../../store/actions/tickets.actions';
//import { fetchLotsByNeighbourhood } from 'store/actions/lotes.actions';
//import { fetchReporteTickets } from 'store/actions/reportes.actions';
import { fetchReporteMascotas } from '../../../store/actions/reportes.actions';


import {
  FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel
} from '../styled';


class Mascotas extends React.Component {

  state = {
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,

    properties: [],
    categories: [],
    types: [],

    typesEnabled: false
  }


  handleSubmit = data => {

    const fetchData = {
      property: data.property.value == '' ? '' : data.property.label,
      category: data.category.value,
      type: data.type.value,
      startDate: data.startDate,
      endDate: data.endDate
    }

    this.props.fetchReporteTickets(fetchData)
  }

  componentDidMount() {
    this.props.fetchReporteMascotas();
  }


  render() {
    return (
      <>
        <SectionHeader title='Mascotas'>
          <Input
            type="text"
            search={true}
            placeholder={'Buscar'}
            value={this.props.search}
            onChange={(e) => this.props.searchHandleChange(e)}
          />
        </SectionHeader>

        <RitzTable
          columns={[
            // { title: 'Fecha de empadronamiento', field: 'date', },
            { title: 'Nombre/s', field: 'name', },
            { title: 'Dueño/a', field: 'owner_name', },
            { title: 'Sexo', field: 'gender_description', },
            { title: 'Tipo', field: 'animal_type', },
            { title: 'Pelaje', field: 'pelage_type', },
            { title: 'Edad', field: 'age', },
            // { title: 'Peso', field: 'weight', },
            { title: 'Tamaño', field: 'size', },
            { title: 'Esterilizado', field: 'sterilized_description', }
          ]}
          title=""
          options={{
            toolbar: true,
            paging: true,
            selection: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: 'ReporteMascotas.' + (new Date().toISOString().split('.')[0])
          }}
          data={this.props.mascotas}

        />
      </>
    );
  }
}

const mapStateToProps = ({
  reportes, tickets, lotes
}) => ({
  reportes, tickets, lotes
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchReporteMascotas },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mascotas)