/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import Input from '../../../../components/Input'
import CloseIcon from "../../../../assets/icons/close.svg";
import Spinner from 'react-spinner-material';
// import Spinner from '../../../../components/Spinner';
import {
    FormContainer,
    Row,
    InputWrapper,
    InputLabel,
    ErrorLabel,
    ButtonWrapper,
    FlexWrapper,
    SpinerWrapper,
    GrayedArea,
    MainContainer,
    HeaderWrapper,
    TitleModal,
    Close
} from './styled'


function MerchantTypeModal(props) {
    const { merchantType, isSubmitting, onSubmit } = props;
    const getSignUpSchema = () =>
        Yup.lazy(values =>
            Yup.object().shape({
                description: Yup.string().required('Campo Obligatorio'),
                code: Yup.string().required('Campo Obligatorio')
            })
        );

    const getInitialValues = () => {
        return {
            id: merchantType ? merchantType.id : 0,
            description: merchantType ? merchantType.description : "",
            code: merchantType ? merchantType.code : ""
        };
    };
    const getFormContent = ({
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        resetForm,
        errors
    }) => {
        return (
            <Form onSubmit={handleSubmit}>
                <FlexWrapper>
                    <FlexWrapper width={100}>
                        <InputWrapper>
                            <InputLabel>{'Descripción'}</InputLabel>
                            <Input
                                name={'descriptionInput'}
                                width={'100%'}
                                label={'descriptionInput'}
                                placeholder={'Ingrese una descripción...'}
                                value={values.description}
                                error={errors.description}
                                onChange={({ target: value }) => setFieldValue('description', value.value)}
                                margin="normal"
                            />
                            {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
                        </InputWrapper>
                        <InputWrapper width={100}>
                            <InputLabel>{'Código'}</InputLabel>
                            <Input
                                name={'codeInput'}
                                width={'100%'}
                                label={'codeInput'}
                                placeholder={'Ingrese un código...'}
                                value={values.code}
                                error={errors.code}
                                onChange={({ target: value }) => setFieldValue('code', value.value)}
                                margin="normal"
                            />
                            {errors.code && <ErrorLabel>{errors.code}</ErrorLabel>}
                        </InputWrapper>
                    </FlexWrapper>
                </FlexWrapper>
                <FlexWrapper>
                    <ButtonWrapper>
                        <Button type={'submit'} loading={isSubmitting}>
                            {merchantType ? "Actualizar" : "Guardar"}
                        </Button>
                    </ButtonWrapper>
                </FlexWrapper>
            </Form>
        );
    };

    const form = () => {
        if (props.isLoading) {
            return (
                <SpinerWrapper>
                    <Spinner margin="auto"/>
                </SpinerWrapper>
            );
        }
        return (
            <Formik
                initialValues={getInitialValues()}
                validateOnChange={false}
                validationSchema={getSignUpSchema()}
                onSubmit={props.onSubmit}
                render={e => getFormContent(e)}
            />
        );
    };

    return (
        <GrayedArea>
            <FormContainer>
                <HeaderWrapper>
                    <TitleModal>{props.title}</TitleModal>
                    <Close src={CloseIcon} onClick={props.onClose} />
                </HeaderWrapper>
                {form()}
            </FormContainer>
        </GrayedArea>
    );
}

export default MerchantTypeModal;
