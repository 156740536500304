import React, { Component } from 'react'
import { InputWrapper, ColumnFormContainer, InputStyled, Container, DestinatarioDetailItem, LabelChecked, LabelContainer, SelectWrapper, DestinatarioDetailContainer, DestinatarioDetailItemContainer, IconBack, TitleContainer, ErrorLabel } from './styled';
import Select from 'react-select'
import StyledButton from '../../../../components/Button'
import CheckBox from '../../../../components/Checkbox'
import Input from '../../../../components/Input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { plusBusinessDays } from '../../../../utils/dateParser'

const options = [
  { value: '0', label: 'Inmediata' },
  { value: '7', label: '7 dias habiles' },
  { value: '10', label: '10 dias habiles' },
  { value: '20', label: '20 dias habiles' }
];

const conceptos = [
  { id: '1', codigo: 'PRE', label: 'Prestamo', value: 'Prestamo' },
  { id: '1', codigo: 'CUO', label: 'Cuota', value: 'Cuota' },
  { id: '1', codigo: 'EXP', label: 'Expensas', value: 'Expensas' },
  { id: '1', codigo: 'FAC', label: 'Factura', value: 'Factura' },
  { id: '1', codigo: 'SEG', label: 'Seguro', value: 'Seguro' },
  { id: '1', codigo: 'HON', label: 'Honorarios', value: 'Honorarios' },
  { id: '1', codigo: 'VAR', label: 'Varios', value: 'Varios ' }
];

export default class SecondStep extends Component {
  state = {
    transferTotal: false,
    amount: '0'
  }

  handleFormSubmit = formData => {
    let diasHabiles = plusBusinessDays(formData.date.value)
    let formDataParsed = formData
    formDataParsed.date = diasHabiles
    this.props.updateTransaction(formDataParsed)
    this.props.NextStep()
  };

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };

  getInitialValues = () => ({
    amount: "",
    concepto: null,
    date: null
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      // amount: Yup.string().required('El campo es requerido'),
      // date: Yup.string().required('El campo es requerido'),
      // concepto: Yup.string().required('El campo es requerido'),
      amount: Yup.string().required('Ingrese un monto'),
      date: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string()
      }).test('notVoid', 'Ingrese un plazo', item =>
        item != null
      ).required('Ingrese un plazo').nullable(),
      concepto: Yup.object().shape({
        id: Yup.string(),
        codigo: Yup.string(),
        label: Yup.string(),
        value: Yup.string()
      }).test('notVoid', 'Ingrese un concepto', item =>
        item != null
      ).required('Ingrese un concepto').nullable(),

    })

  handleCheckBoxChange = setFieldValue => {
    this.setState({
      transferTotal: !this.state.transferTotal
    });
    if (!this.state.transferTotal) {
      setFieldValue('amount', this.props.totalBalance.toString().replace(".", ","))
    } else {
      setFieldValue('amount', '0')
    }

  };

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (

    <Container onSubmit={handleSubmit}>
      <TitleContainer onClick={() => this.props.PreviousStep()}><IconBack />{'Paso anterior'}</TitleContainer>
      <ColumnFormContainer>
        <LabelContainer>Datos del destinatario</LabelContainer>
        <DestinatarioDetailContainer>
          <DestinatarioDetailItemContainer>
            <DestinatarioDetailItem>Nombre </DestinatarioDetailItem>
            <DestinatarioDetailItem bold={true} >   {this.props.transactionProgress.DestinationBankAccount.HolderName}
            </DestinatarioDetailItem>
          </DestinatarioDetailItemContainer>
          <DestinatarioDetailItemContainer>
            <DestinatarioDetailItem> Alias</DestinatarioDetailItem>
            <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Alias}</DestinatarioDetailItem>
          </DestinatarioDetailItemContainer>
          <DestinatarioDetailItemContainer>
            <DestinatarioDetailItem> Banco</DestinatarioDetailItem>
            <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.BankName}</DestinatarioDetailItem>
          </DestinatarioDetailItemContainer>
          <DestinatarioDetailItemContainer>
            <DestinatarioDetailItem> CBU/CVU </DestinatarioDetailItem>
            <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Cvbu}</DestinatarioDetailItem>
          </DestinatarioDetailItemContainer>
        </DestinatarioDetailContainer>
      </ColumnFormContainer>
      <ColumnFormContainer>
        <LabelContainer>Importe a Transferir</LabelContainer>
        <InputWrapper>
          <InputStyled
            prefix="$"
            value={values.amount}
            onChangeEvent={e => setFieldValue('amount', e.target.value.slice(1, 15))}
            thousandSeparator={'.'}
            decimalSeparator={','}
          />
          {errors.amount && <ErrorLabel>{errors.amount}</ErrorLabel>}
        </InputWrapper>
      </ColumnFormContainer>
      <ColumnFormContainer>
        <LabelContainer>Concepto</LabelContainer>
        <SelectWrapper>
          <Select
            styles={{ lineHeight: "none" }}
            value={values.concepto}
            onChange={e => setFieldValue('concepto', e)}
            options={conceptos}
            placeholder="Selecciona un concepto"
          />
        </SelectWrapper>
        {/* <ImagesGroup margin={{marginTop: 10, marginBottom: 10 }} cuentas={Conceptos} onChange={(e)  => setFieldValue('concepto', e.codigo)} /> */}
        {errors.concepto && <ErrorLabel>{errors.concepto}</ErrorLabel>}
        {this.props.totalBalance ?
          <LabelChecked checked={this.state.transferTotal}>
            <CheckBox
              type="radio"
              name="rdBtn"
              value="externo"
              checked={this.state.transferTotal}
              onChange={() => this.handleCheckBoxChange(setFieldValue)}
            />
            Transferir total de ARS {this.props.totalBalance}
          </LabelChecked> : null}
      </ColumnFormContainer>
      <ColumnFormContainer>
        <LabelContainer>Plazo de acreditacion</LabelContainer>
        <SelectWrapper>
          <Select
            styles={{ lineHeight: "none" }}

            value={values.date}
            onChange={e => setFieldValue('date', e)}
            options={options}
            placeholder="Selecciona un plazo"
          />
        </SelectWrapper>
        {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
      </ColumnFormContainer>
      <StyledButton type="submit" >Transferir</StyledButton>
    </Container>
  )

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedNeighbourhood: state.userInfo.selectedNeighbourhood.name,
    AccountInfo: state.billetera.AccountInfo,
    loadingAccountInfo: state.billetera.loadingAccountInfo,
    loadingAccountInfoByLote: state.billetera.loadingAccountInfoByLote,
    errorAccountInfoByLote: state.billetera.errorAccountInfoByLote,
    errorAccountInfoMessage: state.billetera.errorAccountInfoMessage,
    errorAccountInfoByLoteMessage: state.billetera.errorAccountInfoByLoteMessage,
  }
}
