import { Form, Formik } from "formik";
import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader";
import DeleteIcon from "@material-ui/icons/Delete";
import theme from "../../../../config/theme";
import PdfFileUpload from "../../../../components/PdfFileUpload";
import NoFilePdf from "../../../../assets/icons/upload-pdf.svg";
import {
  InputWrapper,
  InputLabel,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper,
  Ahref,
  Row,
  WrapperLink,
  Title
} from "./styled";

function TyCModal(props) {
  const { isSubmitting, bookingActivity, isSharedActivity } = props;
  const [hasErrorExtension, setHasErrorExtension] = useState(false);

  const types = "application/pdf";
 
  const getFormContent = ({
    handleSubmit,
    values,
    setFieldValue,
    setValues,
    resetForm,
    errors
  }) => { 
   
  
    return (
      <>
        <Title>{props.title}</Title>
        <Form onSubmit={handleSubmit}>
        <>
          {isSubmitting ? (  <SpinerWrapper>
          <Loader />
        </SpinerWrapper>)  : <>{bookingActivity[0].tyc_url ? (
            <WrapperLink>
              <Ahref href={bookingActivity[0].tyc_url}>{"Ver términos y condiciones"}</Ahref>
              {!isSharedActivity && bookingActivity[0].tyc_url ? (
                <Button
                type={"submit"}
                loading={isSubmitting}
                style={{ background: theme.colors.white, color: theme.colors.warmGrey }}
                > 
                  <DeleteIcon /> 
                </Button> ) : <></>
              }
            </WrapperLink>
          ) : <>{isSharedActivity ? (
              <Row>
                <InputLabel>{"La actividad no tiene disponible un archivo de términos y condiciones"}</InputLabel>
              </Row>
              ) : null }</>
          }
                
          {!isSharedActivity ? (
            <div>
            <InputWrapper>
              {bookingActivity[0].tyc_url ? (
                <InputLabel>{"Cambiar términos y condiciones (.pdf)"}</InputLabel>
              ) : (
                <InputLabel>{"Subir términos y condiciones (.pdf)"}</InputLabel>
              )}
              <div style={{ display: "flex", justifyContent: "center", margin: "10px 5px 10px 5px" }}>
                <PdfFileUpload
                  onChange={tyc => setFieldValue("tyc", tyc)}
                  pdf={true}
                  nofile={NoFilePdf}
                  acceptTypes = {types}
                  hasErrorExtension = { hasErrorExtension }
                  setHasErrorExtension = { setHasErrorExtension }
                />
              </div>
            </InputWrapper>
            <FlexWrapper>
              <ButtonWrapper>
                <Button disabled={hasErrorExtension ? true : false || JSON.stringify(values) === '{}' ? true : false} style={{ width: "10em" }} type={"submit"} loading={isSubmitting}>
                  Subir Archivo
                </Button>
              </ButtonWrapper>
            </FlexWrapper>
            </div>
          ) : null}</>}
          
        </>
        </Form>
      </>
    );
  };

  const form = () => {
    if (props.isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        validateOnChange={false}
        //validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <Modal onClose={props.onClose} height={"min-height"} width="auto">
      {form()}
    </Modal>
  );
}

export default TyCModal;
