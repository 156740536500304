import { createSelector } from 'reselect'
import { formatCurrencyNumber } from '../utils/utility';

const mapBilleterasPorBarrio = createSelector(
  billeteras => billeteras,
  billeteras =>
    billeteras.map(billetera => ({
      client_cuit: billetera.client_cuit,
      client_full_name: billetera.client_full_name,
      currency: billetera.currency,
      month_year: billetera.month_year,
      state_code: billetera.state_code,
      total_amount: billetera.total_amount,
      importe_formatedd: formatCurrencyNumber(billetera.currency, billetera.total_amount)
    }))
)

export default mapBilleterasPorBarrio