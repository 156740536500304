import React from 'react'
import HomeBaseContent from '../../../components/HomeBaseContent'
import {
  ExtendedContainer,
  ExtendedContent,
  ExtendedTitle,
  ExtendedWrapper,
  UserImage
} from './styled'
import {
  SliderWrapper,
  ElementContainer,
  Element,
} from '../Article/styled'
import Slider from 'react-slick'
import { Player, BigPlayButton } from 'video-react';

class Event extends React.Component {
  renderExtendedContent = () => {
    const { title, userImage, content, sliderImages } = this.props
    return (
      <ExtendedContainer>
        <UserImage src={userImage} />
        <ExtendedWrapper>
          <ExtendedTitle>{title}</ExtendedTitle>
          <ExtendedContent dangerouslySetInnerHTML={{ __html: content }} />
        </ExtendedWrapper>
        {this.renderSlider(sliderImages)}
      </ExtendedContainer>
    )
  }
  renderSlider = sliderImages => {
    const sliderSettings = {
      centerMode: true,
      slidesToShow: 1,
      arrows: false
    }
    if (sliderImages) {
      return (
        <SliderWrapper>
          {<Slider {...sliderSettings}>
            {this.props.video ? (
              <ElementContainer>
                <Player
                  playsInline={true}
                  src={this.props.video}
                  width={100}
                  height={100}
                >
                  <BigPlayButton position="center" />
                </Player>
              </ElementContainer>
            ) : (null)}
            {sliderImages.map((img, imgIndex) => (
              <ElementContainer key={imgIndex}>
                <Element src={img} />
              </ElementContainer>)
            )}
          </Slider>}
        </SliderWrapper>
      )
    }
    return (
      <SliderWrapper>
        <Slider {...sliderSettings}>

        </Slider>
      </SliderWrapper>
    )
  }

  render() {
    const {
      title,
      subTitle,
      userName,
      type,
      articleImage,
      userImage,
      extended,
      onClick,
      publicationdate,
      publicationendshowingdate,
      onDelete,
      video,
      document,
      onEdit
    } = this.props
    return (
      <HomeBaseContent
        title={title}
        subTitle={subTitle}
        userName={userName}
        type={type}
        articleImage={articleImage}
        userImage={userImage}
        extended={extended}
        withoutBaseInfo={extended}
        onClick={onClick}
        publicationendshowingdate={publicationendshowingdate}
        date={publicationdate}
        onDelete={onDelete}
        onEdit={onEdit}
        video={video}
        document={document}
      >
        {extended && this.renderExtendedContent()}
        <a
          href={document}
          download={document + ".pdf"}
          id="download"
          target="_blank"
          hidden
        />
      </HomeBaseContent>
    )
  }
}

export default Event
