import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SectionHeader from '../../../components/SectionHeader';
import {
    fetchGetProducts, 
} from '../../../store/actions/clasificados.actions'
import { Container,Row,Column,Title,PedidoNumber,ColumnMain,Total,ContainerTable,Box,Text,HeaderPrice,HeaderCantidad,HeaderProducts,Name,Price,Subtitle,Quantity} from "./styled"


class DetailPedido extends Component {
    state = {
       loading: false,
    }

    render() {
        return (
            <>
                <SectionHeader title='Resumen del pedido' onClose={()=> this.props.onClose()}></SectionHeader>
                <Container>
                    <ColumnMain>
                        <Column>
                            <PedidoNumber>
                                <Total>Pedido N°</Total>
                                <Total>{"#"+this.props.order.orderid}</Total>
                            </PedidoNumber>
                        </Column> 
                        <Row>
                            <ContainerTable>
                                <HeaderProducts>Producto</HeaderProducts>
                                <HeaderCantidad>Cantidad</HeaderCantidad>
                                <HeaderPrice>Precio</HeaderPrice>

                                <Name>{this.props.order.title}</Name>
                                <Quantity>{this.props.order.quantity}</Quantity>
                                <Price>{this.props.order.price}</Price>
                            </ContainerTable>
                        </Row>
                        {/* <Row justify="space-evenly">
                            <Total bold={true}>Subtotal</Total>
                            <Total >$10.000</Total>
                        </Row>
                        <Row justify="space-evenly">
                            <Total bold={true}>Envio</Total>
                            <Total >$300</Total>
                        </Row> */}

                        <Row justify="space-evenly" background="rgba(205, 205, 211, 0.45)">
                            <Total  color="#15994C" bold={true}>Total del pedido</Total>
                            <Total color="#15994C" bold={true}>{this.props.order.price}</Total>
                        </Row>

                    </ColumnMain>
                    <ColumnMain>
                        <Title>Método de pago</Title>
                        <Box>
                            <Text>{this.props.order.paymentmethodname}</Text>
                            <Text>{this.props.order.price}</Text>
                        </Box>
                        {/* <Title>Método y dirección de envío</Title>
                        <Box>
                            <Text>
                                <Text>Método de envío:</Text>
                                <Text></Text>
                            </Text>
                            <Text>{this.props.order.price}</Text>
                        </Box> */}
                    </ColumnMain>
                </Container>
            </>
        )
    }
}
const mapStateToProps = ({
    clasificados: { detailProduct },
}) => ({
    detailProduct, 
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchGetProducts }, dispatch)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(DetailPedido)
)