import styled, { css } from 'styled-components'
import theme from "../../../../../../config/theme"
export const RecurringScheduleContainer = styled.div`
  // width:auto;
  width:300px;
  padding: 10px 15px;
  position: absolute;
  
  display: ${(props) => props.display ? props.display : 'flex'};  


  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  outline: none;
  border: none;
  z-index: 10100;
  cursor: auto;
`

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const FlexWrapper = styled.div`
width: ${({ width }) => width || 100}%;
  display: ${({ hidden }) => hidden ? 'hidden' : 'flex'};
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const CancelButton = styled.a`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.dustyGray};
  font-size: 14px;
  border: none;
  font-weight: 500;
  padding: 5px 15px 0 0;
  outline: none;
  cursor: pointer;
`

export const SaveButton = styled.a`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0 0 0;
  border: none; 
  outline: none;
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
  margin-top: 15px;
`

export const Column = styled.div`
  width: 30%%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`

export const ErrorLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  margin-top: 5px;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.midGray};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  align-items: center;
`

export const HeaderWrapper = styled.div`
  font-size: 25px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'}; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom:5px;
`
export const TitleModal = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  display:inline;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '10px'};  
`

export const StyledLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
`


export const InputLabel = styled.label`
font-family: ${({ theme }) => theme.fonts.roboto};
color: ${({ theme }) => theme.colors.black};
width:  ${({ width }) => width || '30%'};
font-weight:${({ fontWeight }) => fontWeight || 'bold'};
font-size: 14px;
padding-bottom: 5px;
padding-right: 20px;
// display: block;
`
export const Message = styled.label`
font-family: ${({ theme }) => theme.fonts.roboto};
color: ${({ theme }) => theme.colors.dustyGray};
font-size: 14px;
`

export const InputWrapper = styled.div`
  // width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  width: ${(props) => props.width ? props.width : '100%'};
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const GrayedArea = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
  display: flex;
  justify-content: center;
  align-items: center;
`