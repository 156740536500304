import styled from 'styled-components'
import LoaderGif from "../assets/animation/loader.gif"
import React from 'react';

const Loader = ({
  props
}) => (
  <div class="loader" />
)

export default Loader;