import React, { useState } from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import OperationList from './Operations';
import Clients from './Clients';
import Vendors from './Vendors';
import MerchantTypes from './MerchantTypes';
import StateManager from 'react-select';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { resetPaymentOperationsPage } from '../../store/actions/paymentOperations.actions';
import { resetPaymentClientsPage } from '../../store/actions/paymentClients.actions';
const mapStateToProps = ({
    userInfo: { menusAuth }
}) => ({
    menus: menusAuth
})


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { resetPaymentOperationsPage, resetPaymentClientsPage },
        dispatch
    )

function PaymentGateway(props) {

    const PAYMENT_OPERATION_VIEW = 'Operaciones';
    const PAYMENT_CLIENT_VIEW = 'Clientes';
    const VENDORS_VIEW = 'Canales';
    const AMEX_MECHANT_TYPES = 'Tipos de comercio Amex';

    const [view, setView] = useState('Operaciones');
    const paymentAdminMenu = props.menus.find(menu => { return menu.code === 'BOTON_DE_PAGO_ADMIN_BO' })
    const isPaymentAdmin = paymentAdminMenu ? true : false;
    var menulistItems = [
        { title: 'Operaciones', route: PAYMENT_OPERATION_VIEW, available: true },
        { title: 'Canales', route: VENDORS_VIEW, available: true }
    ];
    if (isPaymentAdmin) {
        menulistItems.splice(1, 0, { title: 'Clientes', route: PAYMENT_CLIENT_VIEW, available: true });
        menulistItems.push({ title: 'Tipos de comercio Amex', route: AMEX_MECHANT_TYPES, available: true });
    }
    //  Items del menú
    const menuListComponent = (menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => setView(item.route) : null} active={view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>));

    // Devuelvo la vista en base a la selección en el menú
    const selectedView = (() => {
        if (view === PAYMENT_OPERATION_VIEW)
            props.resetPaymentOperationsPage();
        else
            props.resetPaymentClientsPage();
        switch (view) {
            case PAYMENT_OPERATION_VIEW:
                return (<OperationList />)
            case PAYMENT_CLIENT_VIEW:
                return (<Clients
                />)
            case VENDORS_VIEW:
                return (<Vendors
                    isPaymentAdmin={isPaymentAdmin}
                />)
            case AMEX_MECHANT_TYPES:
                return (<MerchantTypes
                />)
            default:
                return (<OperationList />)
        }
    })();

    return (
        <NavBar menuListComponent={menuListComponent} title='Botón de pago'>
            {selectedView}
        </NavBar>);
}

// export default PaymentGateway

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentGateway)