import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const InputLabel = styled.label`
  display: ${({ display }) => display ? display : 'block'};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'normal'};
  font-size: 16px;
  padding-bottom: 10px;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin:  ${({ margin }) => margin || 0.20}em;
  padding-right:1.5%;
`

export const SpinerWrapper = styled.div`
  margin:auto;
  padding-top:4em;
  padding-bottom:4em;
`


export const LoaderWrapper = styled.div`
    text-align:center;
    margin: auto;
`
export const FlexWrapper = styled.div`
width: ${({ width }) => width || 100}%;
  display: ${({ hidden }) => hidden ? 'hidden' : 'flex'};
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const TableWrapper = styled.div`
    position:relative;
`

export const Divider = styled.div`
width:10px;
padding:2px;
`

export const Header = styled.div`
width:100%;
font-size: 22px;
font-weight: 500;
color: ${({ headerColor }) => (headerColor ? headerColor : '#30735f')};
align-self: center;
margin: 30px 0 10px 0;
padding-bottom:10px;
border-bottom:1px solid #d2d2d2;
`