import React from 'react'
import FileImageInput from '../FileImageInput'
import { Container, InputLabel, Row, InputWrapper, ErrorLabel } from './styled'

class FileInputGroup extends React.Component {
  state = {
    images: []
  }

  componentDidMount = () => this.generateInitialState()

  generateInitialState = () => {
    if (this.props.isEdit) {
      var images = Array(this.props.imagesAmount);
      this.props.value.map((image, i) => (
        images[i] = image
      ))
      images.fill(null, this.props.value.length)
      // rellena el vector con objetos vacios apartir de la posicion que devuelve la cantidad de fotos(las cargadas anteriormente) 
      this.setState({ images })
    } else {
      var images = Array(this.props.imagesAmount).fill(null)
      this.setState({ images })
    }
  }

  setFieldValue = (key, file, isDelete) => {
    if (isDelete) {
      let images = this.state.images
      images.map((image, index) => {
        if (image == file) {
          images[index] = null;
        }
        else if (image?.url != undefined) {
          if (image?.url == file) images[index] = null;
        }
      });
      this.props.onChange(this.getOnChangeValues(images))
      this.setState({
        images
      })
    } else if (key < this.props.imagesAmount) {
      let images = this.state.images
      images[key] = file
      this.props.onChange(this.getOnChangeValues(images))
      this.setState({
        images
      })

    }
  }

  getOnChangeValues = images => Object.values(images).filter(e => e)

  render() {
    return (
      <Container>
        <InputLabel>{this.props.label}</InputLabel>
        <Row justify={'flex-start'} align={'flex-end'} error={'0px'}>
          {this.props.isEdit ? (
            this.state.images.map((image, i) => (
              <InputWrapper key={i} width={15}>
                <FileImageInput canBeImageDeleted={this.props.canBeImageDeleted} onChange={(file, isDelete) => this.setFieldValue(i, file, isDelete)} value={image} />
              </InputWrapper>
            ))
          ) :
            (<>
              {
                this.state.images.map((image, i) => (
                  <InputWrapper key={i} width={15}>
                    <FileImageInput canBeImageDeleted={this.props.canBeImageDeleted} onChange={(file, isDelete) => this.setFieldValue(i, file, isDelete)} value={image == {} ? null : image} />
                  </InputWrapper>
                ))}
            </>
            )}
        </Row>
        {this.props.error && <ErrorLabel>{this.props.error}</ErrorLabel>}
      </Container>
    )
  }
}

export default FileInputGroup
