import styled from 'styled-components';

export const KitsRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden;
    ${({ edit }) => edit && `
        border-bottom: solid 1px #f2f2f2;
    `}
`
export const BackIcon = styled.img`
    width: 12px;
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
`
export const Br = styled.div`
    width: 100%;
    border-bottom: solid 1px #f2f2f2;
`
export const Quantity = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2.3px;
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5.3px;
    height: 9.3px;
  }
  p {
    margin: 0px 10px;
  }
`
export const TrashIcon = styled.img`
    width: 18px;
    height: 20px;
`
export const PlusIcon = styled.img`
    width: 26px;
    height: 26px;
`
export const CheckIcon = styled.img`
    width: 25px;
    height: 19px;
`
export const StyledSelect = styled.div`
    width: 230px;
    & > div > div:first-child {
        height: 40px;
    }
    & span {
        margin: 0px;
    }
`
export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const Imagen = styled.img`
    cursor: pointer;
`

export const Ahref = styled.a`
    cursor: pointer;
`


export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 10vw;
    @media (max-width: 750px) {
            grid-template-columns: 1fr;
            grid-gap: 5px;
            overflow: scroll;
            z-index: 100;
          }
    `
    // @media (max-width: 800px) {
    //     grid-template-columns: 1fr;
    //     grid-gap: 5px;
    //     overflow: scroll;
    //     z-index: 100;
    //   }

export const Card = styled.div`
padding-left: 2%;
  
`
export const CardTitle = styled.div`
margin-bottom: 20px;
font-size: 22px;

`

export const CardContent = styled.div`
    background-color: white;
    border-radius: 6px;
    padding:0;
    @media (max-width: 350px ) {
        width: 92%;
      }
    @media (max-width: 400px ) and (min-width: 350px ) {
        width: 98%;
      }
    @media (max-width: 767px ) and (min-width: 450px ) {
        width: 95%;
      }
`

export const CardRow = styled.div`
    padding: 15px 20px;
    border-bottom: 1px solid #dadada;
    padding-left: 25px;
`

export const Title = styled.div`
    font-size: 36px;
    font-weight: 700;
    color: #30735f;
    margin: 2% 0%;
    
`

export const CardValue = styled.h1`
    font-size: 36px;
    FONT-WEIGHT: 700;
    COLOR: #30735f;

    
    @media (max-width: 800px) {
        font-size: 23.5px;
      }
    
`
// @media (max-width: 800px) {
//     font-size: 26px;
//   }

export const CardSubTitle = styled.h4`
    font-size: 16px;
    FONT-WEIGHT: 400;
    MARGIN-BOTTOM: 8PX;
`

export const CardRowButton = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`


export const CardRowIcon = styled.div`
    padding: 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 20% 70% 10%;
    grid-gap: 10px;
`

export const IconArrow = styled.img`
    width: 10px;
`
export const Icon = styled.img`

`