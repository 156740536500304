import React, { Component } from 'react'
import { FormContainer, RadioBtnContainer, LabelContainer, SelectWrapper, ErrorLabel, LabelChecked, BarrioLabel, BarrioWrapper } from './styled';
import CheckBox from '../../../../components/Toggle'
import StyledButton from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { fetchGetAccount, fetchGetAccountByLote } from '../../../../store/actions/billetera.actions'
import Spinner from 'react-spinner-material'

const Proveedores = [
  { id: '1', amount: '100', label: 'Proveedor 1', executionDate: "01/01/2019", originClientUserName: "jeror", destinationClientUserName: "thomvilla", externalAccountId: "", type: "InnerTransaction", description: "asd", currency: "ARS" },
  { id: '2', amount: '100', label: 'Proveedor 2', executionDate: "01/01/2019", originClientUserName: "jeror", destinationClientUserName: "thomvilla", externalAccountId: "", type: "InnerTransaction", description: "asd", currency: "ARS" },
  { id: '3', amount: '100', label: 'Proveedor 3', executionDate: "01/01/2019", originClientUserName: "jeror", destinationClientUserName: "thomvilla", externalAccountId: "", type: "InnerTransaction", description: "asd", currency: "ARS" },
  { id: '4', amount: '100', label: 'Proveedor 4', executionDate: "01/01/2019", originClientUserName: "jeror", destinationClientUserName: "thomvilla", externalAccountId: "", type: "InnerTransaction", description: "asd", currency: "ARS" },
];

const Servicios = [
  { id: '1', value: '1', label: 'Servicio 1' },
  { id: '2', value: '2', label: 'Servicio 2' },
  { id: '3', value: '3', label: 'Servicio 3' }
];

const Abonos = [
  { id: '1', value: '1', label: 'Abono 1' },
  { id: '2', value: '2', label: 'Abono 2' },
  { id: '3', value: '3', label: 'Abono 3' }
];

const Otros = [
  { id: '1', value: '1', label: 'Otros 1' },
  { id: '2', value: '2', label: 'Otros 2' },
  { id: '3', value: '3', label: 'Otros 3' }
];

const Personal = [
  { id: '1', value: '1', label: 'Personal 1' },
  { id: '2', value: '2', label: 'Personal 2' },
  { id: '3', value: '3', label: 'Personal 3' }
];

class FirstStep extends Component {
  state = {
    selectedOption: 'recurrentes',
    isNewDestinatarioOpen: false,
    selectedRecurrente: Proveedores
  }

  getInitialValues = () => ({
    cbualias: '',
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      cbualias: Yup.string(),
    })

  handleFormSubmit = async data => {
    if (data.cbualias) {
      this.props.fetchGetAccount(data.cbualias)
    } else {
      this.props.fetchGetAccountByLote(data.input)
    }
  };

  componentDidUpdate(newProps) {
    if (newProps.AccountInfo !== this.props.AccountInfo) {
      this.props.setDestinatario(this.props.AccountInfo[0])
      this.props.NextStep(2)
    }
  }

  handleCheckBoxChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };

  handleImagesChange = changeEvent => {
    switch (changeEvent) {
      case 'Proveedores':
        return this.setState({ selectedRecurrente: Proveedores })
      case 'Servicios':
        return this.setState({ selectedRecurrente: Servicios })
      case 'Abonos':
        return this.setState({ selectedRecurrente: Abonos })
      case 'Personal':
        return this.setState({ selectedRecurrente: Personal })
      case 'Otros':
        return this.setState({ selectedRecurrente: Otros })
      default:
        return null;
    }
  };

  toggleNewDestinatario = () => this.setState({ isNewDestinatarioOpen: !this.state.isNewDestinatarioOpen })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <LabelContainer>Cuenta origen</LabelContainer>
      <BarrioWrapper>
        <BarrioLabel size={"19px"} >{this.props.selectedNeighbourhood}</BarrioLabel>
      </BarrioWrapper>
      <LabelContainer>Destinatario</LabelContainer>
      <RadioBtnContainer>
        <LabelChecked checked={this.state.selectedOption === 'lote'}>
          Ingrese CBU / CVU / Alias
        </LabelChecked>
      </RadioBtnContainer>
      <SelectWrapper>
        <Input
          name={'DestinatarioCbu'}
          label={'DestinatarioCbu'}
          placeholder={'CBU/Alias del beneficiario'}
          value={values.cbualias}
          error={errors.cbualias}
          onChange={({ target: value }) => setFieldValue('cbualias', value.value)}
          margin="normal"
          weight="700"
        />
        {errors.cbualias && <ErrorLabel>{errors.cbualias}</ErrorLabel>}
      </SelectWrapper>

      {/* AGENDA DE DETINATARIO NO ENTRA EN MVP
         <NewDestinatarioLabel onClick={this.toggleNewDestinatario} >Nuevo Destinatario</NewDestinatarioLabel>
         */}
      {this.props.errorAccountInfoByLoteMessage || this.props.errorAccountInfoMessage ? (<ErrorLabel>{this.props.errorAccountInfoMessage}</ErrorLabel>) : (<></>)}
      <SelectWrapper>
        {this.props.loadingAccountInfo || this.props.loadingAccountInfoByLote ? (<StyledButton type="submit" ><Spinner /></StyledButton>) : (
          <StyledButton type="submit" >Continuar</StyledButton>)}
      </SelectWrapper>
    </FormContainer>
  )

  render() {
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedNeighbourhood: state.userInfo.selectedNeighbourhood.label ? state.userInfo.selectedNeighbourhood.label : state.userInfo.selectedNeighbourhood.name,
    AccountInfo: state.billetera.AccountInfo,
    loadingAccountInfo: state.billetera.loadingAccountInfo,
    loadingAccountInfoByLote: state.billetera.loadingAccountInfoByLote,
    errorAccountInfoByLote: state.billetera.errorAccountInfoByLote,
    errorAccountInfoMessage: state.billetera.errorAccountInfoMessage,
    errorAccountInfoByLoteMessage: state.billetera.errorAccountInfoByLoteMessage,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetAccount,
      fetchGetAccountByLote
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(FirstStep)
)
