import { createSelector } from 'reselect'

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapNeighbourhoodsMasterData = createSelector(
  barrios => barrios,
  barrios =>
    (barrios.map(barrio => ({
      value: barrio.code,
      label: barrio.name,
      guid: barrio.guid
    })))
)
export default mapNeighbourhoodsMasterData
