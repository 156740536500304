import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withWidth } from '@material-ui/core'
import axios from 'axios'
//import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { bindActionCreators } from 'redux'
import { fetchTransactionQR } from '../../../store/actions/billetera.actions'

import PaperLayout from '../../../components/PaperLayout'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep/index'

class GeneratePay extends Component {
  state = {
    step: {
      name: 'Creation',
      number: 1,
      percentage: 14,
    },
    GetCodeQRImage: 'data:image/png;base64,' + this.props.GetCodeQRImage,
    transaction: {
      'description': 'description'
    }
  }

  updateTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        amount: transaction.amount,
        destination_cuit: this.props.billetera.selectedBankAccount ? this.props.billetera.selectedBankAccount.HolderCuit : null,
        concept: transaction.concepto.value,
      }
    }), () => this.props.fetchTransactionQR(this.state.transaction))
  }

  NextStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number + 1,
        percentage: prevState.step.percentage + 90
      }
    }))
  }


  BackStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number - 1,
        percentage: prevState.step.percentage - 90
      }
    }))
  }


  render() {
    return (
      <PaperLayout title={'Nuevo QR de pago'} Step={this.state.step} style={{ bakcgroundColor: 'none' }}>

        {(() => {
          switch (this.state.step.number) {
            case 1:
              return <FirstStep NextStep={() => { this.NextStep() }} updateTransaction={this.updateTransaction} />;
            case 2:
              return <SecondStep
                BackStep={() => { this.BackStep() }}
                transaction={this.state.transaction}
                QR={this.props.GetCodeQRImage}
                billetera={this.props.billetera} />
            default:
              return null;
          }
        })()}
      </PaperLayout >
    )
  }
}

const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
    billetera: state.billetera,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(GeneratePay, axios)
)
