import React from 'react';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import PageLoader from '../../../components/Loader';
import {
    Title,
    Data,
    ButtonContent,
    ModalContainer,
    LoaderContainer
} from './styled'

class ModalProcessingOrder extends React.Component {

    render() {
        return (
                <Modal onClose={() => this.props.onClose()} height={"50%"} width={"40%"} minwidth={"370px"} minheight={"300px"}  >
                    <ModalContainer>
                        <LoaderContainer>
                            <PageLoader/>
                        </LoaderContainer>
                        <Title>Estamos procesando tu orden de cobro</Title>
                        <Data>
                            Te informaremos por correo electrónico cuando el proceso haya finalizado.
                            Podés chequear el estado del trámite en la pantalla Órdenes.
                        </Data>
                        <ButtonContent>
                            <Button medium onClick={() => this.props.onClose()} borderRadius={"20px"} width={"200px"}>Aceptar</Button>
                        </ButtonContent>
                    </ModalContainer>
                </Modal>
        );
    }
}

export default ModalProcessingOrder