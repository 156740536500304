import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 560px;
  padding: 2%;
`
export const Text = styled.span`
  font-size: 16px;
  color: #4a4a4a;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: ${({ margin }) => margin ? margin : 'inherit'};
`

export const InputStyled = styled.input`
    opacity: 0;
`
export const Container = styled.form`
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`
export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems ? props.alignItems : 'start'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};  border-radius: 4px;
  border: solid 0.5px #e9e9e9;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  &:last-child {
  margin-right: 0%;
  }
`
export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
`
export const Breakline = styled.div`
  border: solid 0.5px #e9e9e9;
  width: 100%;
  margin: 3% 0;
`
export const InputWrapper = styled.div`
  margin-right:  ${({ margin }) => margin || '2%'};
  display: ${({ display }) => display || 'block'};
  flex-direction: ${({ flexdirection }) => flexdirection || 'column'};
  flex-wrap: wrap;
  align-items: ${({ alignItems }) => alignItems || 'start'};
`
export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  &::-webkit-scrollbar { width: 0 !important }
  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
export const ErrorLabel = styled.label`
  width: 60%;
  align-self: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin:  ${({ margin }) => margin || '0'}%;

`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 40px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  margin: 1%;
`