import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import configureStore from './store'
import { Provider } from 'react-redux'
import { cacheReader } from './utils/session'
import WebFont from 'webfontloader'
import ThemeContext from './ThemeContext'
import { ThemeProvider } from 'styled-components'
import theme from "./config/theme"
import "react-datepicker/dist/react-datepicker.css";


WebFont.load({
    google: {
      families: ['Roboto:400']
    }
  })
  
const store = configureStore(cacheReader())
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ThemeContext>
          <Router>
            <App />
          </Router>
        </ThemeContext>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  )
  registerServiceWorker()
