import { createSelector } from 'reselect'

let mapCommunicationDError = createSelector(  
  data => data,
  data => 
  (data.map(item => ({
    processError:getError(item.data.line , item.data.error),
  })) )
)

function getError(line, error){
  return error != "El formato del archivo es incorrecto"? error + " en la linea: " + line:error;
}

export default mapCommunicationDError