import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import TextArea from '../../../../components/TextArea'
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import StyledSelect from '../../../../components/StyledSelect';
import {
    InputWrapper,
    InputLabel,
    ErrorLabel,
    ButtonWrapper,
    FlexWrapper,
    LoaderWrapper,
    GridContainer,
    FirstColumn,
    SecondColumn,
    AccountDetail,
    Paragraph
} from './styled'

import {
    fetchBankAccounts, fetchBankAccountsReset
} from '../../../../store/actions/paymentClients.actions';

const mapStateToProps = state => {
    const {
        loadingFetchBankAccounts,
        bankAccounts
    } = state.paymentClients;
    return {
        loadingFetchBankAccounts: loadingFetchBankAccounts,
        bankAccounts: bankAccounts
    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchBankAccounts },
        dispatch
    )

function VendorModal(props) {

    const {
        vendorDetail,
        isReadOnly,
        isSubmitting,
        paymentClients,
        isPaymentAdmin,
        paymentClientGuid,
        bankAccounts,
        loadingFetchBankAccounts,
        selectedPaymentClient,
        setSelectedPaymentClient,
        clients,
        vendors,
        merchantTypes
    } = props;
    const getPaymentClients = () => {
        if (paymentClients != null && paymentClients.length > 0)
            return paymentClients.map(c => ({ label: c.name, value: c.neighbourhoodGuid })).sort((a, b) => (a.label > b.label ? 1 : -1));
    };

    const getVendors = () => {
        if (vendors != null && vendors.length > 0)
            return vendors.map(v => ({ label: v.name, value: v.shop_store_guid })).sort((a, b) => (a.label > b.label ? 1 : -1));
    };


    const getBankAccountsOptions = () => {
        if (bankAccounts != null && bankAccounts.length > 0)
            return bankAccounts.map(n => ({ label: formatLabel(n), value: n.Id }))
    }
    const formatLabel = (bankAccount) => {
        let labelPhrase = "";
        if(bankAccount.ClientAppUserName && bankAccount.ClientAppUserName != ""){
            labelPhrase = `${bankAccount.ClientAppUserName} - CUIT: ${bankAccount.HolderCuit} - Moneda: ${bankAccount.Currency}`;
        }else {
            labelPhrase = `CUIT: ${bankAccount.HolderCuit} - Moneda: ${bankAccount.Currency}`;
        }
        return labelPhrase;
    }

    const fetchBankAccountsByVendor = async (setFieldValue, option) => {
        setFieldValue('shop', option.value);
        await props.fetchBankAccounts(option.value, vendorDetail && !vendorDetail[0].shop ? vendorDetail[0].bank_account_id : null);
        setFieldValue('bank_account_id', '');
    }

    const getMerchantTypeOptions = () => {
        var merchantTypes = [{ label: 'No aplica', value: 0 }];
        if (props.merchantTypes != null && props.merchantTypes.length > 0)
            merchantTypes = merchantTypes.concat(props.merchantTypes.map(n => ({ label: n.code + ' - ' + n.description, value: n.id })))
        return merchantTypes
    }

    const getDetailSelectedBankAccount = (id) => {
       const filterArray =  bankAccounts.filter(element => element.Id === id);   
       return filterArray[0];
    }
    const getInitialValues = () => {
        return ({
            id: vendorDetail ? vendorDetail[0].id : 0,
            name: vendorDetail ? vendorDetail[0].name : '',
            description: vendorDetail ? vendorDetail[0].description : '',
            external_code: vendorDetail ? vendorDetail[0].external_code : '',
            shop: vendorDetail ? vendorDetail[0].shop : null,
            client: vendorDetail ? vendorDetail[0].client.id : selectedPaymentClient.value,
            bank_account_id: vendorDetail ? vendorDetail[0].bank_account_id : null,
            merchant_type: vendorDetail && vendorDetail[0].merchant_number ? vendorDetail[0].merchant_number.id : 0,
            isPaymentAdmin: isPaymentAdmin ? true : false
        })
    };


    const getSignUpSchema = () =>
        Yup.lazy(values =>
            Yup.object().shape({
                description: Yup.string().required('Campo Obligatorio'),
                name: Yup.string().required('Campo Obligatorio'),
                external_code: Yup.string().required('Campo Obligatorio'),
                client: Yup.number().positive('Campo obligatorio'),
                shop: Yup.string().nullable(),
                bank_account_id: Yup.number().nullable()
            })
        );

            console.log("props", props)
    const getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => {
        let clientsOptions = getPaymentClients();
        let vendorsOptions = getVendors();
        let bankAccountOptions = getBankAccountsOptions();
        let merchantTypeOptions = getMerchantTypeOptions();
        const selectedBankAccount = bankAccountOptions && bankAccountOptions.length > 0 && values.bank_account_id ? bankAccountOptions.find(option => option.value === values.bank_account_id) : '';
        const detailSelectedBankAccount = getDetailSelectedBankAccount(selectedBankAccount.value);
        return (
            <Form onSubmit={handleSubmit}>
                <FlexWrapper>
                    <FlexWrapper width={50}>
                        <InputWrapper>
                            <InputLabel>{'Nombre'}</InputLabel>
                            <Input
                                fullwidth={true}
                                name={'nameInput'}
                                label={'nameInput'}
                                placeholder={'Ingrese un nombre...'}
                                value={values.name}
                                error={errors.name}
                                onChange={({ target: value }) => setFieldValue('name', value.value)}
                                margin="normal"
                                disabled={props.isReadOnly ? props.isReadOnly : false}
                            />
                            {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
                        </InputWrapper>
                        </FlexWrapper>
                        { !detailSelectedBankAccount ?  
                        <FlexWrapper width={50}>
                        <InputWrapper>
                                    <InputLabel>{'Shop'}</InputLabel>
                                    <StyledSelect
                                        fullwidth={true}
                                        name={'shopInput'}
                                        label={'shopInput'}
                                        value={vendorsOptions ? vendorsOptions.find(option => option.value === values.shop) : null}
                                        error={errors.shop}
                                        placeholder="Seleccione un shop..."
                                        options={vendorsOptions}
                                        isDisabled={props.isReadOnly ? true : false}
                                        isLoading={props.isLoading}
                                        onChange={option => fetchBankAccountsByVendor(setFieldValue, option, values)}
                                        margin="normal"
                                    />
                                    {errors.shop && <ErrorLabel>{errors.shop}</ErrorLabel>}
                                </InputWrapper>
                        </FlexWrapper>
                        : ''
                         } 
                    {isPaymentAdmin ?
                        <GridContainer>
                            <FirstColumn>
                                <InputWrapper>
                                    <InputLabel>{'Cliente'}</InputLabel>
                                    <StyledSelect
                                        fullwidth={true}
                                        name={'clientInput'}
                                        label={'clientInput'}
                                        placeholder={'Seleccione un cliente...'}
                                        value={clients ? clients.find(option => option.value === values.client) : ''}
                                        error={errors.client}
                                        onChange={option => setFieldValue('client', option.value)}
                                        options={clients}
                                        margin="normal"
                                        isDisabled={props.isReadOnly ? props.isReadOnly : false}
                                    />
                                    {errors.client && <ErrorLabel>{errors.client}</ErrorLabel>}
                                </InputWrapper>
                                <InputWrapper>
                                <InputLabel>{'Código externo'}</InputLabel>
                                <Input
                                    fullwidth={true}
                                    name={'ExternlaCodeInput'}
                                    label={'ExternlaCodeInput'}
                                    placeholder={'Ingrese codigo externo...'}
                                    value={values.external_code}
                                    error={errors.external_code}
                                    onChange={({ target: value }) => setFieldValue('external_code', value.value)}
                                    margin="normal"
                                    disabled={props.isReadOnly ? props.isReadOnly : false}
                                />
                                {errors.external_code && <ErrorLabel>{errors.external_code}</ErrorLabel>}
                            </InputWrapper>
                            <InputWrapper width={100}>
                                    <InputLabel>{'Tipo de comercio'}</InputLabel>
                                    <StyledSelect
                                        fullwidth={true}
                                        name={'merchantTypeInput'}
                                        label={'merchantTypeInput'}
                                        value={merchantTypeOptions ? merchantTypeOptions.find(option => option.value === values.merchant_type) : ''}
                                        error={errors.merchant_type}
                                        placeholder="Seleccione un tipo de comercio..."
                                        isDisabled={props.isReadOnly ? props.isReadOnly : false}
                                        options={merchantTypeOptions}
                                        onChange={option => setFieldValue('merchant_type', option.value)}
                                        margin="normal"
                                    />
                                    {errors.merchant_type && <ErrorLabel>{errors.merchant_type}</ErrorLabel>}
                            </InputWrapper>
                            </FirstColumn>
                            <SecondColumn>
                            <InputWrapper width={100}>
                                <InputLabel>{'Cuenta bancaria'}</InputLabel>
                                <StyledSelect
                                    fullwidth={true}
                                    name={'bankAccountInput'}
                                    label={'bankAccountInput'}
                                    value={selectedBankAccount}
                                    error={errors.bank_account_id}
                                    placeholder="Seleccione una cuenta bancaria..."
                                    options={bankAccountOptions}
                                    isDisabled={props.isReadOnly ? true : false}
                                    isLoading={props.isLoading || loadingFetchBankAccounts}
                                    enableReinitialize={false}
                                    onChange={option => setFieldValue('bank_account_id', option.value)}
                                    margin="normal"
                                />
                                {errors.bank_account_id && <ErrorLabel>{errors.bank_account_id}</ErrorLabel>}
                            </InputWrapper>
                            {!isReadOnly || vendorDetail[0].shop ?  
                                (
                                    <InputWrapper width={100}>
                                        <InputLabel>{'Detalles de la cuenta'}</InputLabel>
                                            <AccountDetail>
                                                <Paragraph>Banco:</Paragraph>
                                                <p>-</p>
                                            </AccountDetail>
                                            <AccountDetail>
                                                <Paragraph>Titular:</Paragraph>
                                                <p>-</p>
                                            </AccountDetail>
                                            <AccountDetail>
                                                <Paragraph>CUIT:</Paragraph>
                                                <p>-</p>
                                            </AccountDetail>
                                            <AccountDetail>
                                                <Paragraph>CBU:</Paragraph>
                                                <p>-</p>
                                            </AccountDetail>
                                            <AccountDetail>
                                                <Paragraph>Alias:</Paragraph><p>-</p>
                                            </AccountDetail>
                                    </InputWrapper>
                                ) :
                                (
                                    <InputWrapper width={100}>
                                        <InputLabel>{'Detalles de la cuenta'}</InputLabel>
                                            <AccountDetail>
                                            {
                                                detailSelectedBankAccount.BankName ? ( <> <Paragraph>Banco:</Paragraph><p>{detailSelectedBankAccount.BankName}</p></>) : <> <Paragraph>Banco:</Paragraph><p>-</p></> 
                                            }
                                            </AccountDetail>
                                            <AccountDetail>
                                            {
                                                detailSelectedBankAccount.ClientName ? ( <> <Paragraph>Titular:</Paragraph><p>{detailSelectedBankAccount.ClientName}</p></>) : <> <Paragraph>Titular:</Paragraph><p>-</p></> 
                                            }
                                            </AccountDetail>
                                            <AccountDetail>
                                            {
                                                detailSelectedBankAccount.ClientCuit ? ( <> <Paragraph>CUIT:</Paragraph><p>{detailSelectedBankAccount.ClientCuit}</p></>) : <> <Paragraph>CUIT:</Paragraph><p>-</p></> 
                                            }
                                            </AccountDetail>
                                            <AccountDetail>
                                            {
                                               detailSelectedBankAccount.Cvbu ? ( <> <Paragraph>CBU:</Paragraph><p>{detailSelectedBankAccount.Cvbu}</p></>) : <> <Paragraph>CBU:</Paragraph><p>-</p></> 
                                            }
                                            </AccountDetail>
                                            <AccountDetail>
                                                {detailSelectedBankAccount.Alias ? ( <> <Paragraph>Alias:</Paragraph><p>{detailSelectedBankAccount.Alias}</p></>) : <> <Paragraph>Alias:</Paragraph><p>-</p></> }
                                            </AccountDetail>
                                    </InputWrapper>
                                )      
                            }
                            </SecondColumn>
                        </GridContainer>
                        : null
                    }      
                     <InputWrapper>
                            <InputLabel>{'Descripción'}</InputLabel>
                            <TextArea
                                height={'7.5em'}
                                name={'descriptionInput'}
                                label={'descriptionInput'}
                                placeholder={'Ingrese una descripción...'}
                                value={values.description}
                                error={errors.description}
                                onChange={({ target: value }) => setFieldValue('description', value.value)}
                                margin="normal"
                                disabled={props.isReadOnly ? props.isReadOnly : false}
                            ></TextArea>
                            {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
                        </InputWrapper>    
                    <FlexWrapper>
                        
                    </FlexWrapper>             
                </FlexWrapper >
                {!isReadOnly ?
                    <FlexWrapper>
                        <ButtonWrapper>
                            <Button type={'submit'} loading={isSubmitting}>
                                Publicar
                            </Button>
                        </ButtonWrapper>
                    </FlexWrapper> : null
                }
            </Form >
        )
    }

    const form = (() => {
        if (props.isLoading) {
            return (<LoaderWrapper><Loader /></LoaderWrapper>);
        }
        return (<Formik
            initialValues={getInitialValues()}
            validateOnChange={false}
            validationSchema={getSignUpSchema()}
            onSubmit={props.onSubmit}
            render={e => getFormContent(e)}
        />);
    });

    return (
        <Modal title={props.title} onClose={props.onClose} height={'min-height'} >
            {form()}
        </Modal>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorModal)