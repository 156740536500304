import React from 'react'
import { Container, Img, ContainerImg,Subtitle,Title } from "./styled"

export default class filterBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container active={this.props.active} onClick={()=> this.props.onClick()} >
       <ContainerImg><Img src={this.props.img}/></ContainerImg>
       <Title>{this.props.title}</Title>
       <Subtitle>{this.props.subtitle}</Subtitle>
      </Container>
    );
  }
}