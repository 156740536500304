/* eslint-disable react/prop-types */
import styled from "styled-components";
import React from "react";

const MenuItem = styled.div`
  display: flex;
  max-width: 100%;
  height: 50px;
  background-color: #ffffff;
  color: ${({ disabled }) => (disabled ? "#e1e1e1" : "#05112c")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  font-weight: 500;
  ${({ active }) =>
    active &&
    `
    border-right: 3px solid #05112c;  
    background-color: #00000014; 
  `}
`;

const Wrapper = styled.div`
  border: solid 1px #f2f2f2;
`;
const Quantity = styled.div`
  color: #ffffff;
  width: 25px;
  height: 25px;
  background-color: #ff2424;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:  5px;
`

const MenuItems = props => {
  return (
    <Wrapper>
      <MenuItem {...props} onClick={props.onClick}>
        {props.children}
        {props.quantity > 0 ? <Quantity>{props.quantity}</Quantity> : null}
      </MenuItem>
    </Wrapper>
  );
};

export default MenuItems;
