import React from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../../layouts/toolbar'

const mapStateToProps = state => ({
    userInfo: state.auth.user,
  });

function ToolbarContainer(props) {
    return (
        <Toolbar {...props} />
    );
}

export default connect(
    mapStateToProps
)(ToolbarContainer)