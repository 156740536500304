import React from 'react';
import InputMask from 'react-input-mask'


const StyledInputMask = props => {
  return (
    <InputMask
      {...props}
      style={
        {
          // minWidth: props.minWidth ? props.minWidth : '230px',
          boxSizing: 'border-box',
          height: '40px',
          borderRadius: '6px',
          border: props.border ? props.border :'solid 1px hsl(0,0%,80%)',
          padding: '5px 8px',
          fontSize: '14px',
          outline: 'none',
          width: props.width ? props.width : 'auto',
        }

      }
    />
  );
}


export default StyledInputMask