import { createSelector } from "reselect";
const EMPTY = "-";
let mapPaymentMethodDetail = createSelector(
  paymentMethodDetails => paymentMethodDetails,
  paymentMethodDetails =>
    paymentMethodDetails.map(paymentMethodDetail => ({
      ...paymentMethodDetail,
      installment_quantity: paymentMethodDetail.installment_quantity
        ? paymentMethodDetail.installment_quantity
        : "-",
      amount: `$ ${paymentMethodDetail.amount}`,
      total_amount: `$ ${paymentMethodDetail.total_amount}`,
      ingenico_transaction_id: paymentMethodDetail.ingenico_transaction_id
        ? `${paymentMethodDetail.ingenico_transaction_id}`
        : "",
      document_number: paymentMethodDetail.document_number,
      description: paymentMethodDetail.description ? paymentMethodDetail.description : EMPTY
    }))
);

export default mapPaymentMethodDetail;
