import SectionHeader from '../../../../components/SectionHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RitzTable from '../../../../components/RitzTable';
import Loader from '../../../../components/Loader';
import SearchInput from '../SearchInput';
import NavBar from '../../../../components/NavBar';
import MenuItems from '../../../../components/MenuItems';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { fetchProcess } from '../../../../store/actions/regulations.actions';

import {
    SpinerWrapper,
    StateWrapper,
    StateGreenWrapper,
    StateRedWrapper,
    ReturnButton,
    TableWrapper
} from './styled';

const mapStateToProps = state => {
    const {
        orderColumn,
        orderDirection,
        searchText,
        process,
        loadingFetchProcess
    } = state.regulations;

    return {
        process: process,
        orderColumn: orderColumn,
        orderDirection: orderDirection,
        searchText: searchText,
        loadingFetchProcess: loadingFetchProcess
    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchProcess },
        dispatch
    )

function BCRAComunicacionesD(props) {
    const history = useHistory();
    const [view, setView] = useState();
    const [filteredData , setFilteredData] = useState()
    const {
        process,
        page,
        pageSize,
        total,
        loadingFetchProcess
    } = props;

    const PROCESSES_LIST_VIEW = 'Comunicación D';

    var menulistItems = [
        {title: 'Comunicación D', route: PROCESSES_LIST_VIEW, available: true}
      ];

    const menuListComponent = (menulistItems.map((item, key) => 
    <MenuItems 
        quantity={item.quantity} 
        onClick={item.available ? () => setView(item.route) : null} 
        active={true} 
        disabled={!item.available} 
        key={key}>{item.title}
    </MenuItems>));


    var columns = [
        { title: '#', field: 'id', },
        { title: 'Nombre y apellido', field: 'full_name', 
        cellStyle: {
            minWidth: 200
        },
        headerStyle: {
            minWidth: 200
        }
        },
        { title: 'DNI', field: 'dni', },
        { title: 'Fecha', field: 'date', },
        { title: 'Número de comunicado', field: 'comunication_number', },
        { title: 'Tipo de comunicado', field: 'comunication_type', },
        { title: 'Estado', field: 'state', defaultSort: "asc",
            render: rowData => rowData.state.includes("No") ?
                <StateGreenWrapper> {rowData.state} </StateGreenWrapper>
                :
                <StateRedWrapper> {rowData.state} </StateRedWrapper>
        },
        { title: 'Tipo de match', field: 'match_type',
            cellStyle: {
                minWidth: 150
            },
            headerStyle: {
                minWidth: 150
            }
        }, 

        { title: 'Motivo', field: 'motive', 
            render: rowData => rowData.motive ?
            <StateWrapper> {rowData.motive} </StateWrapper> : null
        },
    ];

    const [searchText, setSearchText] = useState('');

    const searchHandleChange = e => {
        var searchValue = e.target.value.toLowerCase(); 
        setFilteredData(process.filter(x =>
           (x.full_name?x.full_name.toLowerCase().includes(searchValue):false )
        || (x.dni?x.dni.toString().includes(searchValue):false)
        || (x.date?x.date.toString().includes(searchValue) :false)
        || (x.comunication_type?x.comunication_type.toLowerCase().includes(searchValue) :false)
        || (x.motive?x.motive.toLowerCase().includes(searchValue):false)
        || (x.match_type?x.match_type.toLowerCase().includes(searchValue):false)
        || (x.state?x.state.toLowerCase().includes(searchValue):false)
        ));
    }

    useEffect(() => {
        props.fetchProcess(props.location.state.processId)
    }, []);

    const goBack = () =>{
        history.push("/regulations")
    }

    const pageContent = (() => {
        if (loadingFetchProcess) {
            return (<SpinerWrapper><Loader /></SpinerWrapper>);
        }
        return (<>
            
            
            <SectionHeader onClose = {() => goBack()} title='Comunicaciones D' width='100%'> 
            </SectionHeader>
            <SearchInput
                    type="text"
                    search={true}
                    placeholder={'Buscar'}
                    width='100%'
                    disabled={loadingFetchProcess}
                    margin = {'0 0 0.5em 0'}
                    onChange={(e) => searchHandleChange(e)}
                />
                  <TableWrapper>
            <RitzTable
                columns={columns}
                title=""
                isLoading={loadingFetchProcess}
                options={{
                    toolbar: true,
                    search: false,
                    paging: true,
                    serverSide: false,
                    selection: true,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: 'ComunicacionesD.' + (new Date().toISOString().split('.')[0]),
                    rowsPerPageOptions: [10,20,50,100],
                }}
                pagination={{
                    pageSize: 10
                }}
                data={filteredData ? filteredData : process} />
                </TableWrapper>
        <ReturnButton onClick={() => goBack()}> Volver </ReturnButton>
        </>
        );


    })();


    const selectedView = (() => {
        switch (view) {
            case PROCESSES_LIST_VIEW:
                return (<Redirect to="/regulations"/>)
            default:
                return (pageContent)
        }
    })();

    return (
        <>
        <NavBar menuListComponent={menuListComponent} title='Pantalla de detalle'>
            {selectedView}
        </NavBar>
        </>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BCRAComunicacionesD)