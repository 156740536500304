import * as actionTypes from "./actionTypes";

import { getOrdersAPI } from "../../apiConstants"

import getApi from "../../shared/api";
import fetchData from "../../shared/fetchData";


export const setPageOrderList = page => {
    return {
      type: actionTypes.SET_ORDER_PAGE,
      page: page
    }
  }
export const setYearOrderList = year => {
    return {
      type: actionTypes.SET_ORDER_YEAR,
      year: year
    }
  }
export const fetchOrderListSuccess = orders => {
    return {
      type: actionTypes.FETCH_ORDER_LIST_SUCCESS,
      orders: orders
    };
  };
export const fetchOrderListFail = error => {
    return {
      type: actionTypes.FETCH_ORDER_LIST_FAIL,
      error: error
    };
  };
  
export const fetchOrderListStart = () => {
    return {
      type: actionTypes.FETCH_ORDER_LIST_START
    };
  };

export const fetchOrderList = (reset,username) => {
    return async (dispatch, getState) => {
      dispatch(fetchOrderListStart());
      const {
        expenses,
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid);
      let eventTake = reset ? 10 : expenses.eventTake;
      const page = expenses.page;
      const year = expenses.year;
      const fromTo = ( year ? `&datefrom=${expenses.year}-01-01&dateto=${expenses.year}-12-31` : '')
      try {
        // const response = await api.get(`${getOrdersAPI}${page}/10`);
        const response = await api.get(`${getOrdersAPI}${page}&take=10${fromTo}&CreationUserName=${username}`);

        let hasMoreUpdated = response.data.length === eventTake;
        let takeUpdated = hasMoreUpdated ? eventTake + 10 : eventTake;
        dispatch(
            fetchOrderListSuccess({
            values: response.data,
            eventTake: takeUpdated,
            hasMoreTransactions: hasMoreUpdated
          })
        );
      } catch (error) {
        dispatch(fetchOrderListFail(error));
      }
    };
  };

