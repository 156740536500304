/* eslint-disable react/prop-types */
import RitzTable from "../../../components/RitzTable";
import SectionHeader from "../../../components/SectionHeader";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ModalDelete from "../../../components/ModalDelete";
import ModalMessage from "../../../components/ModalMessage";
import Button from "../../../components/Button";
import ModalOperationDetailUnified from "../Components/modalOperationDetailUnified/index";
import { exportFromJson } from '../../../utils/xlsxExportHelper'
import StyledSelect from "../../../components/StyledSelect";
import SearchInput from "../../../components/SearchInput";
import DatePickerStyled from "../../../components/DatePickerStyled";

import Select from 'react-select';

import {
  fetchPaymentOperations,
  fetchOperationDetail,
  fetchPaymentMethodDetails,
  cancelPaymentOperation,
  cancelPaymentOperationReset,
  fetchErrorDetail,
  fetchPaymentOperationsReport,
  fetchPaymentOperationsReportReset,
  fetchPaymentStates,
  fetchPaymentStatesReset,

} from "../../../store/actions/paymentOperations.actions";

import {
  fetchPaymentClients,
  fetchPaymentClientsReset,
  fetchVendorsByClient,
  fetchVendorsReset
} from "../../../store/actions/paymentClients.actions";
import { FormGroup, FormControlLabel, IconButton, Tooltip } from "@material-ui/core";
import {
  Row,
  CustomInput,
  CustomCheckbox,
  LabelDefault,
  EstadoLabelPendiente,
  CancelledState,
  TableWrapper,
  EstadoLabelCompletado,
  EstadoLabelError,
  EstadoLabelNueva,
  LabelSection,
  Divider,
  InputWrapper,
  InputLabel,
  SelectWrapper,
  FormContainer,
  ErrorsWrapper,
  ErrorLabel
} from "./styled";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
var moment = require("moment");

const mapStateToProps = state => {
  const {
    paymentOperations,
    page,
    pageSize,
    total,
    loadingPaymentOperations,
    orderColumn,
    orderDirection,
    searchText,
    getNews,
    operationDetail,
    paymentMethodDetail,
    loadingOperationDetail,
    loadingMethodDetail,
    loadingCancelPaymentOperation,
    errorCancelPaymentOperation,
    cancelPaymentOperationSuccess,
    errorDetail,
    loadingFetchErrorDetail,
    fetchErrorDetailSuccess,
    loadingFetchPaymentOperationsReport,
    paymentOperationsReportData,
    fetchOperationsReportSuccess,
    errorFetchOperationsReport,
    paymentOperationStatesData,
    loadingFetchPaymentOperationStates,
    fetchPaymentOperationStatesSuccess,
    errorFetchPaymentOperationStates,
  } = state.paymentOperations;

  return {
    paymentOperations: paymentOperations,
    page: page,
    pageSize: pageSize,
    total: total,
    isLoading: loadingPaymentOperations,
    orderColumn: orderColumn,
    orderDirection: orderDirection,
    searchText: searchText,
    getNews: getNews,
    menus: state.userInfo.menusAuth,
    operationDetail: operationDetail,
    loadingOperationDetail: loadingOperationDetail,
    loadingMethodDetail: loadingMethodDetail,
    paymentMethodDetail: paymentMethodDetail,
    loadingCancelPaymentOperation: loadingCancelPaymentOperation,
    errorCancelPaymentOperation: errorCancelPaymentOperation,
    cancelPaymentOperationSuccess: cancelPaymentOperationSuccess,
    errorDetail: errorDetail,
    loadingFetchErrorDetail: loadingFetchErrorDetail,
    fetchErrorDetailSuccess: fetchErrorDetailSuccess,
    loadingFetchPaymentOperationsReport: loadingFetchPaymentOperationsReport,
    paymentOperationsReportData: paymentOperationsReportData,
    fetchOperationsReportSuccess: fetchOperationsReportSuccess,
    errorFetchOperationsReport: errorFetchOperationsReport,
    paymentOperationStatesData: paymentOperationStatesData,
    loadingFetchPaymentOperationStates: loadingFetchPaymentOperationStates,
    fetchPaymentOperationStatesSuccess: fetchPaymentOperationStatesSuccess,
    errorFetchPaymentOperationStates: errorFetchPaymentOperationStates,
    paymentClients: state.paymentClients.paymentClients,
    loadingPaymentClients: state.paymentClients.loadingPaymentClients,
    vendors: state.paymentClients.vendors,
    loadingFetchVendors: state.paymentClients.loadingFetchVendors,
    neighbourhoods: state.userInfo.neighbourhoods,
    fetchClientSuccess: state.paymentClients.fetchClientSuccess
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPaymentOperations,
      fetchOperationDetail,
      fetchPaymentMethodDetails,
      cancelPaymentOperation,
      cancelPaymentOperationReset,
      fetchErrorDetail,
      fetchPaymentOperationsReport,
      fetchPaymentOperationsReportReset,
      fetchPaymentStates,
      fetchPaymentStatesReset,
      fetchPaymentClients,
      fetchPaymentClientsReset,
      fetchVendorsByClient,
      fetchVendorsReset,
    },
    dispatch
  );

function OperationList(props) {
  const {
    searchText,
    paymentOperations,
    total,
    isLoading,
    orderColumn,
    orderDirection,
    getNews,
    fetchPaymentOperations,
    operationDetail,
    loadingOperationDetail,
    loadingMethodDetail,
    paymentMethodDetail,
    loadingCancelOperation,
    errorCancelPaymentOperation,
    cancelPaymentOperationSuccess,
    errorDetail,
    loadingFetchErrorDetail,
    fetchErrorDetailSuccess,
    loadingFetchPaymentOperationsReport,
    paymentOperationsReportData,
    fetchOperationsReportSuccess,
    errorFetchOperationsReport,
    loadingFetchPaymentOperationStates,
    paymentOperationStatesData,
    fetchPaymentOperationStatesSuccess,
    errorFetchPaymentOperationStates,
    paymentClients,
    loadingPaymentClients,
    vendors,
    loadingFetchVendors,
    neighbourhoods,
    fetchClientSuccess
  } = props;



  const [showCancelOperation, setShowCancelOperation] = useState(false);
  const [flagShowCancelOperation, setFlagShowCancelOperation] = useState(false)
  const [showErrorDetail, setShowErrorDetail] = useState(false);
  const [showDetailUnifiedOperation, setShowDetailUnifiedOperation] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showExportErrorModal, setShowExportErrorModal] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [selectedPaymentClient, setSelectedPaymentClient] = useState({
    label: "Todos",
    value: null
  });
  const [selectedClientVendor, setSelectedClientVendor] = useState({ label:"Todos", value:null });
  const [selectedPaymentStates, setSelectedPaymentStates] = useState([]);
  const [initialStateChange, setInitialStateChange] = useState(false);
  const [hasInitialStateSetted, sethasInitialStateSetted] = useState(false);
  
  let clients;

  useEffect(() => {
    
    const asyncFunc= async () => { 
    await props.fetchPaymentClients(0, null, 'name', 'asc', '', false, null,paymentAdmin,neighbourhoods );//paymentAdmin ultimo param
    await props.fetchPaymentStates();
  }
    asyncFunc();
  }, []);

  useEffect(() => {
    if(!hasInitialStateSetted && fetchClientSuccess && fetchPaymentOperationStatesSuccess && paymentClients){
      if(paymentAdmin)
        setSelectedPaymentClient({ label:"Todos", value:null })
      else{
        let neighborhoodsParents = neighbourhoods.filter(neighborhood => neighborhood.isparent );
        let intersectionArray = paymentClients.filter(function(n) { return neighborhoodsParents.some(neighborhood =>neighborhood.guid == n.neighbourhoodGuid) }).sort(function(a, b) {
          return a.id-b.id;});
        let firstOptionClient
        if(intersectionArray != null && intersectionArray.length > 0)
        firstOptionClient = intersectionArray[0];
        setSelectedPaymentClient({ label: firstOptionClient?.name, value: firstOptionClient?.id})
      }
      if(selectedPaymentStates == null || selectedPaymentStates.length == 0){
        const newArray = paymentOperationStatesData.filter(item => item.code === "COMPLETED" || item.code === "ERROR")
        const estadosPreSeleccionados = getPaymentStates(newArray);
        setSelectedPaymentStates(estadosPreSeleccionados);
      }
      sethasInitialStateSetted(true);
      setInitialStateChange(true);
    }
  }, [fetchClientSuccess,fetchPaymentOperationStatesSuccess])
  
  useEffect(() => {
    if(hasInitialStateSetted && !initialStateChange && fetchPaymentOperationStatesSuccess){
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentStatesId
      );
      setInitialStateChange(true);
      return function cleanup() {
        setInitialStateChange(false);
        sethasInitialStateSetted(false);
      };
    }
  }, [hasInitialStateSetted])

  useEffect(() => {
    if (!showCancelOperation && flagShowCancelOperation) {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentStatesId
      );
      props.cancelPaymentOperationReset();
    }
  }, [showCancelOperation]);

  useEffect(() => {
    if (fetchOperationsReportSuccess) {
      exportExcel(paymentOperationsReportData);
      props.fetchPaymentOperationsReportReset();
    } else if (errorFetchOperationsReport) {
      toggleShowExportErrorModal(!showExportErrorModal);
    }
  }, [fetchOperationsReportSuccess]);

  useEffect(() => {
    if (!showErrorDetail && flagShowCancelOperation) {
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews
      );
    }
  }, [showErrorDetail]);

  useEffect(() => {
    if (hasInitialStateSetted && initialStateChange && fetchPaymentOperationStatesSuccess) {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        null,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentStatesId
      );
      setInitialStateChange(false);
      return function cleanup() {
        setSelectedRow(null);
        props.fetchVendorsReset();
        props.fetchPaymentClientsReset();
        setInitialStateChange(false);
        sethasInitialStateSetted(false);
      };
    }
  }, [hasInitialStateSetted])

  const paymentAdmin = props.menus.find(menu => {
    return menu.code === "BOTON_DE_PAGO_ADMIN_BO";
  });

  const getPaymentClients = () => {
    let mappedClients = [];
    if (paymentClients != null && paymentClients.length > 0) {
      mappedClients = paymentClients.map((c,index) => ({ label: c.name, value: c.id,id:index+1 }));
    }
    if(paymentAdmin){
    mappedClients.push({ value: null, label: "Todos",id: 0 });
  }
    return mappedClients.sort((a, b) => (a.value > b.value ? 1 : -1));
    };

  const getClientVendorss = () => {
    let mappedVendors = [];
    if (vendors != null && vendors.length > 0) {
      mappedVendors = vendors.map(c => ({ label: c.name, value: c.id }));
      mappedVendors.push({ value: null, label: "Todos" });
    }
    return mappedVendors.sort((a, b) => (a.value > b.value ? 1 : -1));
  };



  const mapPaymentStatesId = (array) => {
    let newArray = [];

    if (array != undefined) {
      newArray = array.map(value => ({ id: value.value }))
    }
    return newArray;
  }


  const getPaymentStates = (array) => {
    let mapPaymentStates = [];
    if (paymentOperationStatesData != null && paymentOperationStatesData.length > 0) {
      mapPaymentStates = array.map(paymentState => ({ label: paymentState.description, value: paymentState.id }))
      return mapPaymentStates;
    }
  }

  clients = getPaymentClients();
  let mappedVendors = getClientVendorss();
  let paymentStates = getPaymentStates(paymentOperationStatesData);


  const onPaymentClientChange = selectedValue => {
    setSelectedPaymentClient(selectedValue);
    const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
    props.fetchVendorsByClient(selectedValue.value);
    setSelectedClientVendor({ label:"Todos", value:null });
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedValue.value,
      null,
      props.page,
      props.pageSize,
      orderColumn,
      orderDirection,
      searchText,
      getNews,
      paymentStatesId
    );
  };

  const onClientVendorsChange = selectedValue => {
    setSelectedClientVendor(selectedValue);
    const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedValue.value,
      props.page,
      props.pageSize,
      orderColumn,
      orderDirection,
      searchText,
      getNews,
      paymentStatesId
    );
  };

  const onPaymentStatesChange = (values) => {
    const newValues = mapPaymentStatesId(values);
    setSelectedPaymentStates(values);
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedClientVendor.value,
      props.page,
      props.pageSize,
      orderColumn,
      orderDirection,
      searchText,
      getNews,
      newValues
    );
  }


  const onStartDateChange = value => {
    setStartDate(value);

    let sDate = new Date(value);
    let eDate = new Date(endDate);

    console.log("sDate: ", sDate);
    console.log("eDate: ", eDate);

    if (sDate <= eDate) {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        value.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentStatesId
      );

      setDateError("");
    } else {
      setDateError("La fecha Desde, debe ser menor o igual a la de Hasta.");
    }
  };

  const onEndDateChange = value => {
    setEndDate(value);

    let sDate = new Date(startDate);
    let eDate = new Date(value);

    console.log("sDate: ", sDate);
    console.log("eDate: ", eDate);

    if (sDate <= eDate) {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        value.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        props.page,
        props.pageSize,
        orderColumn,
        orderDirection,
        searchText,
        getNews,
        paymentStatesId
      );
      setDateError("");
    } else {
      setDateError("La fecha Desde, debe ser menor o igual a la de Hasta.");
    }
  };

  const toggleShowCancelOperation = async rowData => {
    setShowCancelOperation(!showCancelOperation);
    setFlagShowCancelOperation(true);
    if (!showCancelOperation) {
      setSelectedRow(rowData);
    }
  };

  const toggleShowExportErrorModal = () => {
    setShowExportErrorModal(!showExportErrorModal);
  };

  const toggleShowDetailUnifiedOperation = async (operationId, error) => {
    if (showDetailUnifiedOperation) setHasError(false);
    setShowDetailUnifiedOperation(!showDetailUnifiedOperation);
    if (!showDetailUnifiedOperation) {
      await props.fetchPaymentMethodDetails(operationId);
      await props.fetchOperationDetail(operationId);
      if (error) {
        setHasError(true);
        await props.fetchErrorDetail(operationId);
      }
    }
  };

  const cancelPaymentOperation = async () => {
    const body = {
      payment_id: selectedRow.operation_token,
      client_token: selectedRow.client_token
    };
    props.cancelPaymentOperation(body);
  };
  const renderModalMessageSuccess = (message, callback) => (
    <ModalMessage success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalMessageFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const cancelModal = (() => {
    if (props.cancelPaymentOperationSuccess && props.errorCancelPaymentOperation == null) {
      return renderModalMessageSuccess(
        "Se ha cancelado el pago correctamente.",
        toggleShowCancelOperation
      );
    } else if (!props.cancelPaymentOperationSuccess && props.errorCancelPaymentOperation) {
      return renderModalMessageFail(
        "Hubo un error al cancelar el pago.",
        toggleShowCancelOperation
      );
    }
    if (showCancelOperation) {
      return (
        <ModalDelete
          title="Cancelar operación"
          subTitle="¿Estás seguro que deseas cancelar la operación?"
          buttonMessage="Aceptar"
          successMessage="¡Se ha cancelado la operación!"
          isLoading={loadingCancelOperation}
          onClose={toggleShowCancelOperation}
          onSubmit={cancelPaymentOperation}
        />
      );
    }
  })();

  const onChangePageHandler = (e, page) => {
    const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedClientVendor.value,
      page,
      props.pageSize,
      orderColumn,
      orderDirection,
      searchText,
      getNews,
      paymentStatesId
    );
  };

  const onChangeRowsPerPageHandler = pageSize => {
    const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedClientVendor.value,
      0,
      pageSize,
      orderColumn,
      orderDirection,
      searchText,
      getNews,
      paymentStatesId
    );
  };

  const onChangeHandler = e => {
    if (e.target.value === "") {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        0,
        props.pageSize,
        orderColumn,
        orderDirection,
        e.target.value,
        getNews,
        paymentStatesId
      );
    }
  };

  const onKeyDownHanlder = e => {
    if (e.keyCode === 13) {
      const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
      props.fetchPaymentOperations(
        startDate.toISOString(),
        endDate.toISOString(),
        selectedPaymentClient.value,
        selectedClientVendor.value,
        0,
        props.pageSize,
        orderColumn,
        orderDirection,
        e.target.value,
        getNews,
        paymentStatesId
      );
    }
  };

  const checkBoxOnChangeHandler = e => {
    props.fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedClientVendor.value,
      props.page,
      props.pageSize,
      orderColumn,
      orderDirection,
      searchText,
      e.target.checked
    );
  };

  const onOrderChangeHandler = (columnId, direction) => {
    var columnName = "creation_date";
    var dir = "desc";
    if (columnId != null) {
      const column = columns[columnId];
      if (column != null) columnName = column.field;
    }

    if (direction != null && direction !== "") dir = direction;
    const paymentStatesId = mapPaymentStatesId(selectedPaymentStates);
    fetchPaymentOperations(
      startDate.toISOString(),
      endDate.toISOString(),
      selectedPaymentClient.value,
      selectedClientVendor.value,
      props.page,
      props.pageSize,
      columnName,
      dir,
      searchText,
      getNews,
      paymentStatesId
    );
  };

  const fitToColumn = data => {
    const columnWidths = [];
    for (const property in data[0]) {
      columnWidths.push({
        wch: Math.max(
          property ? property.toString().length : 0,
          ...data.map(obj => obj[property] ? obj[property].toString().length : 0))
      });
    }
    return columnWidths;
  };

  const exportExcel = async data => {
    var columnsTitle = [
      { field: 'id', title: 'Nro. Transacción Miiii' },
      { field: 'creation_date', title: 'Fecha' },
      { field: 'fullname', title: 'Nombre y apellido' },
      { field: 'username', title: 'Usuario' },
      { field: 'card_holder_name', title: 'Holder name tarjeta' },
      { field: 'cuit_number', title: 'CUIT' },
      { field: 'document_number', title: 'DNI' },
      { field: 'client_name', title: 'Cliente' },
      { field: 'vendors_name', title: 'Canales' },
      { field: 'operation_id', title: 'Nro. de operación' },
      { field: 'description', title: 'Descripción' },
      { field: 'payment_methods', title: 'Métodos de pago' },
      { field: 'payment_methods_description', title: 'Descripción métodos de pago' },
      { field: 'total_amount', title: 'Importe' },
      { field: 'installments_quantity', title: 'Cant. de cuotas' },
      { field: 'payment_state', title: 'Estado' },
      { field: 'reason_for_rejection', title: 'Motivo de rechazo' }
    ];
    exportFromJson(data, columnsTitle, "PaymiiiiOperations_");
  };

  var columns = [{ title: "#", field: "id" }];
  if (paymentAdmin) {
    columns.push({
      title: "Cliente",
      field: "client_name"
    });
  }
  columns.push(
    {
      title: "Nro. operación",
      field: "operation_id",
      cellStyle: { width: "150px" }
    },
    {
      title: "Importe",
      field: "total_amount_operation",
      cellStyle: { display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }
    },
    {
      title: "Estado",
      field: "payment_state",

      // eslint-disable-next-line react/display-name
      render: rowData => {
        switch (rowData.payment_state) {
          case "Error":
            return <EstadoLabelError>{rowData.payment_state}</EstadoLabelError>;
          case "Completado":
            return <EstadoLabelCompletado>{rowData.payment_state}</EstadoLabelCompletado>;
          case "En curso":
            return <EstadoLabelNueva>{rowData.payment_state}</EstadoLabelNueva>;
          case "Pendiente de confirmación":
            return <EstadoLabelPendiente>{rowData.payment_state}</EstadoLabelPendiente>;
          case "Cancelada":
            return <CancelledState>{rowData.payment_state}</CancelledState>;
          default:
            return <LabelDefault>{rowData.payment_state}</LabelDefault>;
        }
      }
    },

    {
      title: "Fecha",
      field: "creation_date"
    },
    {
      title: "Acciones",
      field: "actions",
      cellStyle: {
        textDecoration: "underline",
        maxWidth: "84px",
        display: "flex",
        justifyContent: "center"
      },
      // eslint-disable-next-line react/display-name
      render: rowData => (
        <>
          <LabelSection>
            <Tooltip title="Ver detalle">
              <IconButton
                aria-label="Ver detalle"
                color="inherit"
                onClick={() =>
                  toggleShowDetailUnifiedOperation(rowData.id, rowData.payment_state == "Error")
                }
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          </LabelSection>
          <Divider />
          {rowData.payment_state == "Completado" ||
            rowData.payment_state == "Pendiente de confirmación" ? (
            <>
              <Divider />
              <LabelSection>
                <Tooltip title="Cancelar operación">
                  <IconButton
                    aria-label="Cancelar operación"
                    style={{ color: "#A85A2B" }}
                    onClick={() => toggleShowCancelOperation(rowData)}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </LabelSection>
            </>
          ) : null}
        </>
      )
    }
  );

  return (
    <>
      <Row justifyContent={"space-between"}>
        <SectionHeader
          title="Operaciones"
          width="100%"
          justifyContent={"space-between"}
          fontWeight={"bold"}
          alignItems={"baseline"}
        />
        <FormContainer>
          <InputWrapper width={"330px"} justifyContent={"flex-end"}>
            <InputLabel fontWeight={"bold"}>{"Desde"}</InputLabel>
            <DatePickerStyled
              width={"70%"}
              selected={startDate}
              startDate={startDate}
              onChange={value => onStartDateChange(value)}
              selectsStart
              dateFormat={"dd/MM/yyyy"}
              placeholderText={"A partir de..."}
              padding={"8px 1%"}
              popperPlacement="bottom"
              disabled={
                isLoading ||
                loadingFetchPaymentOperationsReport ||
                loadingPaymentClients ||
                loadingFetchVendors
              }
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
          </InputWrapper>
          <InputWrapper width={"330px"} justifyContent={"flex-end"}>
            <InputLabel fontWeight={"bold"}>{"Hasta"}</InputLabel>
            <DatePickerStyled
              width={"70%"}
              selected={endDate}
              startDate={endDate}
              onChange={value => onEndDateChange(value)}
              selectsStart
              dateFormat={"dd/MM/yyyy"}
              placeholderText={"Hasta..."}
              padding={"8px 1%"}
              popperPlacement="bottom"
              disabled={
                isLoading ||
                loadingFetchPaymentOperationsReport ||
                loadingPaymentClients ||
                loadingFetchVendors
              }
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
          </InputWrapper>
          
          {(
            <>
              <InputWrapper width={"330px"} justifyContent={"flex-end"}>
                <InputLabel fontWeight={"bold"}>{"Cliente"}</InputLabel>
                <SelectWrapper>
                  <StyledSelect
                    value={selectedPaymentClient}
                    onChange={value => onPaymentClientChange(value)}
                    options={clients}
                    placeholder="Seleccione un cliente"
                    isDisabled={
                      isLoading ||
                      loadingFetchPaymentOperationsReport ||
                      loadingPaymentClients ||
                      loadingFetchVendors
                    }
                  />
                </SelectWrapper>
              </InputWrapper>
              <InputWrapper width={"330px"} justifyContent={"flex-end"}>
                <InputLabel fontWeight={"bold"}>{"Canal"}</InputLabel>
                <SelectWrapper>
                  <StyledSelect
                    value={selectedClientVendor}
                    onChange={value => onClientVendorsChange(value)}
                    options={mappedVendors}
                    placeholder="Seleccione un canal"
                    isDisabled={
                      isLoading ||
                      loadingFetchPaymentOperationsReport ||
                      loadingPaymentClients ||
                      loadingFetchVendors ||
                      vendors.length === 0
                    }
                  />
                </SelectWrapper>
              </InputWrapper>
            </>
          )}
          <InputWrapper width={"660px"} justifyContent={"flex-end"}>
            <InputLabel fontWeight={"bold"}>{"Estado"}</InputLabel>
            <SelectWrapper width={"85%"}>
              <Select
                value={selectedPaymentStates}
                name={'getNews'}
                isMulti={true}
                onChange={value => onPaymentStatesChange(value)}
                placeholder={"Seleccionar"}
                isDisabled={
                  isLoading ||
                  loadingFetchPaymentOperationStates ||
                  loadingPaymentClients ||
                  loadingFetchVendors
                }
                options={paymentStates}

              />
            </SelectWrapper>

          </InputWrapper>

        </FormContainer>
      </Row>
      {(dateError !== "") && (
        <ErrorsWrapper>
          <ErrorLabel>{dateError}</ErrorLabel>
        </ErrorsWrapper>
      )}
      <Row justifyContent={'space-between'}>
        <SearchInput
          type="text"
          search={true}
          defaultValue={searchText}
          placeholder={"Buscar"}
          width={"91%"}
          disabled={
            isLoading ||
            loadingFetchPaymentOperationsReport ||
            loadingPaymentClients ||
            loadingFetchVendors
          }
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHanlder}
        />
        <InputWrapper width={'80px'} justifyContent={'flex-end'}>
          <Button
            loading={
              isLoading ||
              loadingFetchPaymentOperationsReport ||
              loadingPaymentClients ||
              loadingFetchVendors
            }
            onClick={() =>
              props.fetchPaymentOperationsReport(
                startDate.toISOString(),
                endDate.toISOString(),
                selectedPaymentClient.value,
                selectedClientVendor.value,
                "id",
                "desc",
                searchText,
                getNews,
                mapPaymentStatesId(selectedPaymentStates)
              )
            }
          >
            Exportar
          </Button>
        </InputWrapper>
      </Row>
      {/* <FormGroup>
        <Row>
          <CustomInput
            type="text"
            search={true}
            defaultValue={searchText}
            placeholder={"Buscar"}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHanlder}
            disabled={isLoading || loadingFetchPaymentOperationsReport}
          />
          <FormControlLabel
            control={<CustomCheckbox checked={getNews} onChange={checkBoxOnChangeHandler} />}
            disabled={isLoading || loadingFetchPaymentOperationsReport}
            label="Incluir operaciones iniciadas"
          />
          <Button
            loading={isLoading || loadingFetchPaymentOperationsReport}
            onClick={() => props.fetchPaymentOperationsReport("id", "desc", searchText, getNews)}
          >
            Exportar a excel
          </Button>
        </Row>
      </FormGroup> */}
      <TableWrapper>
        <RitzTable
          columns={columns}
          title=""
          isLoading={isLoading || loadingFetchPaymentOperationsReport || loadingFetchPaymentOperationStates}
          options={{
            toolbar: false,
            paging: true,
            serverSide: true,
            selection: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: "creation_date." + new Date().toISOString().split(".")[0]
          }}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          onOrderChange={onOrderChangeHandler}
          pagination={{
            totalRow: total,
            page: props.page,
            pageSize: props.pageSize,
            onChangePage: onChangePageHandler
          }}
          data={paymentOperations}
        />
        {showDetailUnifiedOperation ? (
          <ModalOperationDetailUnified
            onClose={toggleShowDetailUnifiedOperation}
            showErrorDetail={hasError}
            loadingOperationDetail={loadingOperationDetail}
            loadingMethodDetail={loadingMethodDetail}
            loadingFetchErrorDetail={loadingFetchErrorDetail}
            operationDetail={operationDetail}
            paymentMethodDetail={paymentMethodDetail}
            errorDetail={errorDetail}
            fetchErrorDetailSuccess={fetchErrorDetailSuccess}
            paymentAdmin={paymentAdmin}
          />
        ) : (
          <></>
        )}
        {cancelModal}
        {showExportErrorModal
          ? renderModalMessageFail(
            "Hubo un error al intentar exportar el archivo",
            toggleShowExportErrorModal
          )
          : null}
      </TableWrapper>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationList);
