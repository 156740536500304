import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 600px;
  
`

export const HeaderWrapper = styled.div`
  width: 150px;
  align-self: center;
`

export const DetailWrapper = styled.div`
  width: 70%;
  overflow: auto;
  box-sizing: border-box;
`


export const LoaderWrapper = styled.div`
  margin-left: 80px;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  width: 100%;
  
  &:hover {
    cursor: pointer;
  }
`

export const ContentList = styled.div`
padding: 1%;
`
