import { createSelector } from 'reselect'
import { getMonthLabel } from '../utils/dateParser'

const format = (conversations) => {

    var conversationsFormatted = conversations.map(({ active,
        auxid,
        categoryid,
        creationdate,
        finishdate,
        id,
        pictureurl,
        receiverid,
        receivername,
        senderid,
        sendername,
        title

    }, i) => ({
        id: id,
        title: title,
        titleMessage: title,
        url: pictureurl,
        categoryid: categoryid,
        extraInfo: getMonthLabel(creationdate),
        name: sendername,
productoId: auxid,
        detail: `Conversación: #${id}`,
        pos: i,
    }))
    return (conversationsFormatted)
}

const mapConversations = createSelector(
    conversations => conversations,
    conversations => {

        const activeConversations = conversations.filter((conversations) => {
            return conversations.active == true
        })

        const closedConversations = conversations.filter((conversations) => {
            return conversations.active == false
        })

        const conversationsList = [format(activeConversations), format(closedConversations)]

        return conversationsList
    }
)



export default mapConversations
