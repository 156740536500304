/* eslint-disable react/prop-types */
import React from "react";
import Spinner from "react-spinner-material";
import Modal from "../../../../components/Modal";
import RitzTable from "../../../../components/RitzTable";
import {
  SpinerWrapper,
  InputLabel,
  InputWrapper,
  FlexWrapper,
  TableWrapper,
  Divider,
  Header
} from "./styled";
import TextArea from '../../../../components/TextArea'

import {
  fetchOperationDetail,
  fetchErrorDetail,
  fetchPaymentMethodDetails
} from "../../../../store/actions/paymentOperations.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

var moment = require("moment");

// const mapStateToProps = state => {
//     const {

//     } = state.paymentOperations;

//     return {

//     }
// };

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOperationDetail,
      fetchErrorDetail,
      fetchPaymentMethodDetails
    },
    dispatch
  );

function ModalOperationDetailUnified(props) {
  const {
    showErrorDetail,
    loadingOperationDetail,
    loadingMethodDetail,
    loadingFetchErrorDetail,
    operationDetail,
    paymentMethodDetail,
    errorDetail,
    fetchErrorDetailSuccess,
    paymentAdmin
  } = props;

  var columnsOperationDetail = [
    { title: "Canal", field: "vendorName" },
    { title: "Descripción", field: "description" },
    { title: "Cantidad", field: "quantity" },
    { title: "Importe", field: "amountOperation" }
  ];

  var columnsPaymentMethodsDetail = [{ title: "#", field: "id" }];
  columnsPaymentMethodsDetail.push(
    { title: "Número Documento", field: "document_number" },
    { title: "Método de pago", field: "payment_method" },
    { title: "Descripción", field: "description" },
    { title: "Cantidad de cuotas", field: "installment_quantity" },
    { title: "Importe", field: "amount" },
    { title: "Importe total", field: "total_amount" }
  );

  if (paymentAdmin) {
    columnsPaymentMethodsDetail.push({
      title: "Transacción Ingenico",
      field: "ingenico_transaction_id"
    });
  }

  const form = (() => {
    if (loadingOperationDetail || loadingMethodDetail || loadingFetchErrorDetail)
      return (
        <SpinerWrapper>
          <Spinner />
        </SpinerWrapper>
      );
    return (
      <>
        <TableWrapper>
          {
            <>
              <RitzTable
                columns={columnsOperationDetail}
                options={{
                  toolbar: false,
                  paging: true,
                  serverSide: false,
                  selection: false
                }}
                data={operationDetail}
                loadingOperationDetail={loadingOperationDetail}
              />
              <Divider />
              <Header>Métodos de Pago</Header>
              <RitzTable
                columns={columnsPaymentMethodsDetail}
                options={{
                  toolbar: false,
                  paging: false,
                  serverSide: false,
                  selection: false
                }}
                data={paymentMethodDetail}
                loadingMethodDetail={loadingMethodDetail}
              />
              {showErrorDetail ? (
                <>
                  <Divider />
                  <Header>Detalle de error</Header>
                  <FlexWrapper width={100}>
                    <InputWrapper>
                      <InputLabel display={"inline"} fontWeight={"bold"}>
                        {"Fecha: "}
                      </InputLabel>
                      <InputLabel display={"inline"}>
                        {fetchErrorDetailSuccess
                          ? moment(errorDetail.creation_date).format("YYYY-MM-DD HH:mm")
                          : ""}
                      </InputLabel>
                    </InputWrapper>
                  </FlexWrapper>
                  <FlexWrapper width={100}>
                    <InputWrapper>
                      <InputLabel fontWeight={"bold"}>{"Descripción"}</InputLabel>
                      <TextArea
                        fullwidth={true}
                        value={
                          fetchErrorDetailSuccess
                            ? !paymentAdmin && errorDetail.normalized_ingenico_message != null
                              ? errorDetail.normalized_ingenico_message
                              : errorDetail.message
                            : "No se ha encontrado el error"
                        }
                        margin="normal"
                        disabled={true}
                      />
                    </InputWrapper>
                  </FlexWrapper>
                </>
              ) : null}
            </>
          }
        </TableWrapper>
        {/* <Divider />
            <ButtonWrapper>
                <Button onClick={props.onClose} >
                    Cerrar
            </Button>
            </ButtonWrapper> */}
      </>
    )
  }
  );
  console.log("PROPS",props)
  return (
    <Modal Modal onClose={props.onClose} width={"75%"} title={"Detalle"}>
      {form()}
    </Modal>
  );
}

export default connect(null, mapDispatchToProps)(ModalOperationDetailUnified);
