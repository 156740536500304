import React from "react";
import theme from "../../../../../../config/theme";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Color = styled.div`
  background-color: ${({ theme, color }) => (color ? color : theme.colors.midGray)};
  width: calc(35px - 5px);
  height: calc(35px - 5px);
  border: 1px solid black;
  font-weight: normal;
  margin: 5px;
`;
const Label = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
`;

function ColorReference(props) {
  return (
    <Container>
      <Color color={props.color} />
      <Label>{props.children}</Label>
    </Container>
  );
}

export default ColorReference;
