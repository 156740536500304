
import {
  FETCH_PAYMENT_CLIENTS_SUCCESS,
  FETCH_PAYMENT_CLIENTS_FAIL,
  FETCH_PAYMENT_CLIENTS_START,
  FETCH_PAYMENT_CLIENTS_RESET,
  RESET_PAYMENT_CLIENTS_PAGE,
  PAYMENT_CLIENTS_UPDATEGRID,
  FETCH_PAYMENT_CLIENT_SUCCESS,
  FETCH_PAYMENT_CLIENT_FAIL,
  FETCH_PAYMENT_CLIENT_START,
  CREATE_PAYMENT_CLIENT_SUCCESS,
  CREATE_PAYMENT_CLIENT_FAIL,
  CREATE_PAYMENT_CLIENT_START,
  CREATE_PAYMENT_CLIENT_RESET,
  UPDATE_PAYMENT_CLIENT_SUCCESS,
  UPDATE_PAYMENT_CLIENT_FAIL,
  UPDATE_PAYMENT_CLIENT_START,
  UPDATE_PAYMENT_CLIENT_RESET,
  FETCH_SHOPS_FAIL,
  FETCH_SHOPS_START,
  FETCH_SHOPS_SUCCESS,
  FETCH_BANK_ACCOUNTS_RESET,
  FETCH_BANK_ACCOUNTS_FAIL,
  FETCH_BANK_ACCOUNTS_START,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  FETCH_CUSTODY_BANK_ACCOUNTS_RESET,
  FETCH_CUSTODY_BANK_ACCOUNTS_FAIL,
  FETCH_CUSTODY_BANK_ACCOUNTS_START,
  FETCH_CUSTODY_BANK_ACCOUNTS_SUCCESS,
  FETCH_VENDORS_START,
  FETCH_VENDORS_FAIL,
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_RESET,
  FETCH_VENDOR_DETAIL_START,
  FETCH_VENDOR_DETAIL_SUCCESS,
  FETCH_VENDOR_DETAIL_FAIL,
  FETCH_VENDOR_DETAIL_RESET,
  CREATE_VENDOR_START,
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAIL,
  CREATE_VENDOR_RESET,
  UPDATE_VENDOR_RESET,
  UPDATE_VENDOR_START,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  DELETE_VENDOR_START,
  DELETE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAIL,
  DELETE_VENDOR_RESET,
  FETCH_NOTIFICATION_REQUEST_FORMAT_SUCCESS,
  FETCH_NOTIFICATION_REQUEST_FORMAT_FAIL,
  FETCH_NOTIFICATION_REQUEST_FORMAT_START,
  FETCH_MERCHANT_TYPES_FAIL,
  FETCH_MERCHANT_TYPES_RESET,
  FETCH_MERCHANT_TYPES_START,
  FETCH_MERCHANT_TYPES_SUCCESS,
  FETCH_MERCHANT_TYPE_DETAIL_FAIL,
  FETCH_MERCHANT_TYPE_DETAIL_RESET,
  FETCH_MERCHANT_TYPE_DETAIL_START,
  FETCH_MERCHANT_TYPE_DETAIL_SUCCESS,
  CREATE_MERCHANT_TYPE_FAIL,
  CREATE_MERCHANT_TYPE_START,
  CREATE_MERCHANT_TYPE_SUCCESS,
  CREATE_MERCHANT_TYPE_RESET,
  UPDATE_MERCHANT_TYPE_FAIL,
  UPDATE_MERCHANT_TYPE_START,
  UPDATE_MERCHANT_TYPE_SUCCESS,
  UPDATE_MERCHANT_TYPE_RESET,
  DELETE_MERCHANT_TYPE_FAIL,
  DELETE_MERCHANT_TYPE_START,
  DELETE_MERCHANT_TYPE_SUCCESS,
  DELETE_MERCHANT_TYPE_RESET
} from './actionTypes'
import getApi from '../../shared/api'
import {
  fetchPaymentClientsAPI,
  fetchPaymentClientAPI,
  merchantNumbersAPI,
  paymentClientsAPI,
  vendorsAPI,
  getShopsAPI,
  fetchBankAccountsAPI,
  fetchNotificationRequestFormatAPI
} from '../../apiConstants'
import mapVendors from '../../selectors/mapVendors'
import { Column } from '../../containers/Bookings/ActivitiesList/ActivityRules/RuleValuesModal/styled'

////////////////////// FETCH ALL PAYMENTS //////////////////////

export const resetPaymentClientsPage = () => {
  return {
    type: RESET_PAYMENT_CLIENTS_PAGE
  }
}
export const fetchPaymentClientsSuccess = paymentClients => {
  let data = paymentClients;
  return {
    type: FETCH_PAYMENT_CLIENTS_SUCCESS,
    paymentClients: data
  }
}

export const fetchPaymentClientsFail = error => {
  return {
    type: FETCH_PAYMENT_CLIENTS_FAIL,
    errorPaymentClients: error
  }
}

export const fetchPaymentClientsStart = () => {
  return {
    type: FETCH_PAYMENT_CLIENTS_START
  }
}


export const fetchPaymentClientsReset = () => {
  return {
    type: FETCH_PAYMENT_CLIENTS_RESET
  }
}

export function updateGrid(page, pageSize, total, orderColumn, orderDirection, searchText) {
  return {
    type: PAYMENT_CLIENTS_UPDATEGRID,
    page,
    pageSize,
    total,
    orderColumn,
    orderDirection,
    searchText
  };
}


export const fetchPaymentClients = (pageNumber, pageSize, orderColumn, orderDirection, searchText, getNews, neighbourhoodGuid, isPaymentAdmin,neighbourhoods) => {

  return async (dispatch, getState) => {
    dispatch(fetchPaymentClientsStart())
    let myArrayFiltered;
    const api = await getApi()
    return api
      .get(`${fetchPaymentClientsAPI}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&searchText=${searchText}&getNews=${getNews}&neighbourhoodGuid=${neighbourhoodGuid}`)
      .then(res => {
        if (res.data.success) {
          if (!isPaymentAdmin) {
            myArrayFiltered = res.data.data.filter((el) => {
              return neighbourhoods.some((f) => {
                return el.neighbourhoodGuid=== f.guid && f.isparent == true;
              });
            });
            dispatch(fetchVendorsByClient(myArrayFiltered[0].id))
          }else{
            myArrayFiltered=res.data.data;
          }
          dispatch(fetchPaymentClientsSuccess(myArrayFiltered))
          dispatch(updateGrid(pageNumber, pageSize? pageSize:10 , res.data.total_rows, orderColumn, orderDirection, searchText))
        }
        dispatch(fetchPaymentClientsFail(res.data.message))
      })
      .catch(error => {
        dispatch(fetchPaymentClientsFail(error))
      })
  }
}

////////////////////// FETCH PAYMENT BY ID //////////////////////
export const fetchPaymentClientSuccess = paymentClient => {
  return {
    type: FETCH_PAYMENT_CLIENT_SUCCESS,
    paymentClient: paymentClient
  }
}

export const fetchPaymentClientFail = error => {
  return {
    type: FETCH_PAYMENT_CLIENT_FAIL,
    errorPaymentClients: error
  }
}

export const fetchPaymentClientStart = () => {
  return {
    type: FETCH_PAYMENT_CLIENT_START
  }
}

export const fetchPaymentClient = (id) => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentClientStart());
    const api = await getApi();
    return api
      .get(`${fetchPaymentClientAPI}/${id}`)
      .then(res => {
        dispatch(fetchBankAccounts(res.data.client.neighbourhoodGuid));
        dispatch(fetchPaymentClientSuccess(res.data.client));
      })
      .catch(error => {
        dispatch(fetchPaymentClientFail(error));
      })
  }
}



////////////////////// CREATE PAYMENT //////////////////////
export const createPaymentClientSuccess = () => {
  return {
    type: CREATE_PAYMENT_CLIENT_SUCCESS
  }
}

export const createPaymentClientFail = error => {
  return {
    type: CREATE_PAYMENT_CLIENT_FAIL,
    errorPaymentClient: error
  }
}

export const createPaymentClientStart = () => {
  return {
    type: CREATE_PAYMENT_CLIENT_START
  }
}

export const createPaymentClientReset = () => {
  return {
    type: CREATE_PAYMENT_CLIENT_RESET
  }
}


export const createPaymentClient = (data) => {
  return async (dispatch, getState) => {
    dispatch(createPaymentClientStart());
    const api = await getApi();
    return api
      .post(paymentClientsAPI, data)
      .then(res => {
        dispatch(createPaymentClientSuccess());
      })
      .catch(error => {
        dispatch(createPaymentClientFail(error));
      })
  }
}


////////////////////// CREATE PAYMENT //////////////////////
export const updatePaymentClientSuccess = () => {
  return {
    type: UPDATE_PAYMENT_CLIENT_SUCCESS
  }
}

export const updatePaymentClientFail = error => {
  return {
    type: UPDATE_PAYMENT_CLIENT_FAIL,
    errorPaymentClient: error
  }
}

export const updatePaymentClientStart = () => {
  return {
    type: UPDATE_PAYMENT_CLIENT_START
  }
}

export const updatePaymentClientReset = () => {
  return {
    type: UPDATE_PAYMENT_CLIENT_RESET
  }
}


export const updatePaymentClient = (data) => {
  return async (dispatch, getState) => {
    dispatch(updatePaymentClientStart());
    const api = await getApi();
    return api
      .put(`${paymentClientsAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updatePaymentClientSuccess());
      })
      .catch(error => {
        dispatch(updatePaymentClientFail(error));
      })
  }
}

//--------------FETCH SHOPS------------------------//

export const fetchShopsSuccess = shops => {
  return {
    type: FETCH_SHOPS_SUCCESS,
    shops: shops
  }
}

export const fetchShopsFail = error => {
  return {
    type: FETCH_SHOPS_FAIL,
    error: error
  }
}

export const fetchShopsStart = () => {
  return {
    type: FETCH_SHOPS_START
  }
}

export const fetchShops = () => {
  return async dispatch => {
    dispatch(fetchShopsStart())
    const api = await getApi()
    api
      .get(`${getShopsAPI}`)
      .then(res => {
        dispatch(fetchShopsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchShopsFail(err))
      })
  }
}

//--------------FETCH BANK ACCOUNTS------------------------//

export const fetchBankAccountsReset = () => {
  return {
    type: FETCH_BANK_ACCOUNTS_RESET
  }
}

export const fetchBankAccountsSuccess = bankAccounts => {
  return {
    type: FETCH_BANK_ACCOUNTS_SUCCESS,
    bankAccounts: bankAccounts
  }
}

export const fetchBankAccountFail = error => {
  return {
    type: FETCH_BANK_ACCOUNTS_FAIL,
    error: error
  }
}

export const fetchBankAccountStart = () => {
  return {
    type: FETCH_BANK_ACCOUNTS_START
  }
}

export const fetchBankAccounts = (shopGuid, bankAccountId) => {
  return async dispatch => {
    dispatch(fetchBankAccountStart())

    const api = await getApi(shopGuid, true)
    api
      .get(`${fetchBankAccountsAPI}?bankAccountId=${bankAccountId}`)
      .then(res => {
        dispatch(fetchBankAccountsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchBankAccountFail(err))
      })
  }
}


/****** FETCH CUSTODY BANK ACCOUNT *******/

export const fetchCustodyBankAccountsReset = () => {
  return {
    type: FETCH_CUSTODY_BANK_ACCOUNTS_RESET
  }
}

export const fetchCustodyBankAccountsSuccess = custodyBankAccounts => {
  return {
    type: FETCH_CUSTODY_BANK_ACCOUNTS_SUCCESS,
    custodyBankAccounts: custodyBankAccounts
  }
}

export const fetchCustodyBankAccountFail = error => {
  return {
    type: FETCH_CUSTODY_BANK_ACCOUNTS_FAIL,
    error: error
  }
}

export const fetchCustodyBankAccountStart = () => {
  return {
    type: FETCH_CUSTODY_BANK_ACCOUNTS_START
  }
}

export const fetchCustodyBankAccounts = (shopGuid) => {
  return async dispatch => {
    dispatch(fetchCustodyBankAccountStart())

    const api = await getApi(shopGuid, true)
    api
      .get(`${fetchBankAccountsAPI}`)
      .then(res => {
        dispatch(fetchCustodyBankAccountsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchCustodyBankAccountFail(err))
      })
  }
}



/////////////////////FETCH VENDORS///////////////////////
export const fetchVendorsSuccess = data => {
  let vendors = mapVendors(data);
  return {
    type: FETCH_VENDORS_SUCCESS,
    vendors: vendors
  }
}

export const fetchVendorsFail = error => {
  return {
    type: FETCH_VENDORS_FAIL,
    errorFetchVendors: error,
  }
}

export const fetchVendorsStart = () => {
  return {
    type: FETCH_VENDORS_START
  }
}

export const fetchVendorsReset = () => {
  return {
    type: FETCH_VENDORS_RESET
  }
}

export const fetchVendorsByClient = (clientId) => {
  return async dispatch => {
    dispatch(fetchVendorsStart())
    const api = await getApi()
    api
      .get(`${paymentClientsAPI}/${clientId}/vendors`)
      .then(res => {
        dispatch(fetchVendorsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchVendorsFail(err))
      })
  }
}

export const fetchVendorsByShopGuid = (shopGuid) => {
  return async dispatch => {
    dispatch(fetchVendorsStart())
    const api = await getApi()
    api
      .get(`${vendorsAPI}?shopGuid=${shopGuid}`)
      .then(res => {
        dispatch(fetchVendorsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchVendorsFail(err))
      })
  }
}

/////////////////////FETCH VENDOR DETAIL///////////////////////
export const fetchVendorDetailSuccess = data => {
  let vendorDetail = mapVendors(data);
  return {
    type: FETCH_VENDOR_DETAIL_SUCCESS,
    vendorDetail: vendorDetail
  }
}

export const fetchVendorDetailFail = error => {
  return {
    type: FETCH_VENDOR_DETAIL_FAIL,
    errorFetchVendorDetail: error,
  }
}

export const fetchVendorDetailStart = () => {
  return {
    type: FETCH_VENDOR_DETAIL_START
  }
}

export const fetchVendorDetailReset = () => {
  return {
    type: FETCH_VENDOR_DETAIL_RESET
  }
}

export const fetchVendorDetail = (vendorId) => {
  return async (dispatch, getState) => {
    dispatch(fetchVendorDetailStart())
    const api = await getApi()
    api
      .get(`${vendorsAPI}/${vendorId}`)
      .then(res => {
        dispatch(fetchShops())
        dispatch(fetchBankAccounts(res.data[0].shop, !res.data[0].shop ? res.data[0].bank_account_id : null));
        dispatch(fetchVendorDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchVendorDetailFail(err))
      })
  }
}

/////////////////////CREATE VENDOR ///////////////////////
export const createVendorSuccess = () => {
  return {
    type: CREATE_VENDOR_SUCCESS
  }
}

export const createVendorFail = error => {
  return {
    type: CREATE_VENDOR_FAIL,
    errorCreateVendor: error,
  }
}


export const createVendorStart = () => {
  return {
    type: CREATE_VENDOR_START
  }
}
export const createVendorReset = () => {
  return {
    type: CREATE_VENDOR_RESET
  }
}

export const createVendor = (data) => {
  return async dispatch => {
    dispatch(createVendorStart())
    const api = await getApi()
    api
      .post(vendorsAPI, data)
      .then(res => {
        dispatch(createVendorSuccess(res.data))
      })
      .catch(err => {
        dispatch(createVendorFail(err))
      })
  }
}

/////////////////////UPDATE VENDOR ///////////////////////
export const updateVendorSuccess = () => {
  return {
    type: UPDATE_VENDOR_SUCCESS
  }
}

export const updateVendorFail = error => {
  return {
    type: UPDATE_VENDOR_FAIL,
    errorUpdateVendor: error,
  }
}

export const updateVendorStart = () => {
  return {
    type: UPDATE_VENDOR_START
  }
}


export const updateVendorReset = () => {
  return {
    type: UPDATE_VENDOR_RESET
  }
}

export const updateVendor = (data) => {
  return async dispatch => {
    dispatch(updateVendorStart())
    const api = await getApi()
    api
      .put(`${vendorsAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updateVendorSuccess(res.data))
      })
      .catch(err => {
        dispatch(updateVendorFail(err))
      })
  }
}

/////////////////////DELETE VENDOR ///////////////////////
export const deleteVendorSuccess = () => {
  return {
    type: DELETE_VENDOR_SUCCESS

  }
}

export const deleteVendorFail = error => {
  return {
    type: DELETE_VENDOR_FAIL,
    errorDeleteVendor: error,
  }
}

export const deleteVendorStart = () => {
  return {
    type: DELETE_VENDOR_START
  }
}

export const deleteVendorReset = () => {
  return {
    type: DELETE_VENDOR_RESET


  }
}

export const deleteVendor = (vendorId) => {
  return async dispatch => {
    dispatch(deleteVendorStart())
    const api = await getApi()
    api
      .delete(`${vendorsAPI}/${vendorId}`)
      .then(res => {
        dispatch(deleteVendorSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteVendorFail(err))
      })
  }

}

/********* NOTIFICATION ********/


export const fetchNotificationRequestFormatSuccess = notificationRequestFormat => {
  let data = notificationRequestFormat.data.result;
  return {
    type: FETCH_NOTIFICATION_REQUEST_FORMAT_SUCCESS,
    notificationRequestFormat: data
  }
}

export const fetchNotificationRequestFormatFail = error => {
  return {
    type: FETCH_NOTIFICATION_REQUEST_FORMAT_FAIL,
    errorNotificationRequestFormat: error
  }
}

export const fetchNotificationRequestFormatStart = () => {
  return {
    type: FETCH_NOTIFICATION_REQUEST_FORMAT_START
  }
}

export const fetchNotificationRequestFormat = () => {
  return async dispatch => {
    dispatch(fetchNotificationRequestFormatStart())
    const api = await getApi()
    api
      .get(`${fetchNotificationRequestFormatAPI}?id=`)
      .then(res => {
        dispatch(fetchNotificationRequestFormatSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchNotificationRequestFormatFail(err))
      })
  }
}

/////////////////////FETCH MERCHANT TYPES///////////////////////
export const fetchMerchantTypesSuccess = data => {
  return {
    type: FETCH_MERCHANT_TYPES_SUCCESS,
    merchantTypes: data
  }
}

export const fetchMerchantTypesFail = error => {
  return {
    type: FETCH_MERCHANT_TYPES_FAIL,
    errorFetchMerchantTypes: error,
  }
}

export const fetchMerchantTypesStart = () => {
  return {
    type: FETCH_MERCHANT_TYPES_START
  }
}

export const fetchMerchantTypesReset = () => {
  return {
    type: FETCH_MERCHANT_TYPES_RESET
  }
}

export const fetchMerchantTypes = () => {
  return async dispatch => {
    dispatch(fetchMerchantTypesStart())
    const api = await getApi()
    api
      .get(`${merchantNumbersAPI}`)
      .then(res => {
        dispatch(fetchMerchantTypesSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchMerchantTypesFail(err))
      })
  }
}

/////////////////////FETCH MERCHANT TYPE DETAIL///////////////////////
export const fetchMerchantTypeDetailSuccess = data => {
  return {
    type: FETCH_MERCHANT_TYPE_DETAIL_SUCCESS,
    merchantType: data
  }
}

export const fetchMerchantTypeDetailFail = error => {
  return {
    type: FETCH_MERCHANT_TYPE_DETAIL_FAIL,
    errorFetchMerchantTypeDetail: error,
  }
}

export const fetchMerchantTypeDetailStart = () => {
  return {
    type: FETCH_MERCHANT_TYPE_DETAIL_START
  }
}

export const fetchMerchantTypeDetailReset = () => {
  return {
    type: FETCH_MERCHANT_TYPE_DETAIL_RESET
  }
}

export const fetchMerchantTypeDetail = (merchantTypeId) => {
  return async (dispatch, getState) => {
    dispatch(fetchMerchantTypeDetailStart())
    const api = await getApi()
    api
      .get(`${merchantNumbersAPI}/${merchantTypeId}`)
      .then(res => {
        dispatch(fetchMerchantTypeDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchMerchantTypeDetailFail(err))
      })
  }
}


/////////////////////CREATE MERCHANT TYPE ///////////////////////
export const createMerchantTypeSuccess = () => {
  return {
    type: CREATE_MERCHANT_TYPE_SUCCESS
  }
}

export const createMerchantTypeFail = error => {
  return {
    type: CREATE_MERCHANT_TYPE_FAIL,
    errorCreateMerchantType: error,
  }
}


export const createMerchantTypeStart = () => {
  return {
    type: CREATE_MERCHANT_TYPE_START
  }
}

export const createMerchantTypeReset = () => {
  return {
    type: CREATE_MERCHANT_TYPE_RESET
  }
}
export const createMerchantType = (data) => {
  return async dispatch => {
    dispatch(createMerchantTypeStart())
    const api = await getApi()
    api
      .post(merchantNumbersAPI, data)
      .then(res => {
        dispatch(createMerchantTypeSuccess(res.data))
      })
      .catch(err => {
        dispatch(createMerchantTypeFail(err.response.data.error_messages[0]))
      })
  }
}

/////////////////////UPDATE MERCHANT TYPE ///////////////////////
export const updateMerchantTypeSuccess = () => {
  return {
    type: UPDATE_MERCHANT_TYPE_SUCCESS
  }
}

export const updateMerchantTypeFail = error => {
  return {
    type: UPDATE_MERCHANT_TYPE_FAIL,
    errorUpdateMerchantType: error,
  }
}

export const updateMerchantTypeStart = () => {
  return {
    type: UPDATE_MERCHANT_TYPE_START
  }
}

export const updateMerchantTypeReset = () => {
  return {
    type: UPDATE_MERCHANT_TYPE_RESET
  }
}

export const updateMerchantType = (data) => {
  return async dispatch => {
    dispatch(updateMerchantTypeStart())
    const api = await getApi()
    api
      .put(`${merchantNumbersAPI}/${data.id}`, data)
      .then(res => {
        dispatch(updateMerchantTypeSuccess(res.data))
      })
      .catch(err => {
        dispatch(updateMerchantTypeFail(err.response.data.error_messages[0]))
      })
  }
}

/////////////////////DELETE MERCHANT TYPE ///////////////////////
export const deleteMerchantTypeSuccess = () => {
  return {
    type: DELETE_MERCHANT_TYPE_SUCCESS

  }
}

export const deleteMerchantTypeFail = error => {
  return {
    type: DELETE_MERCHANT_TYPE_FAIL,
    errorDeleteMerchantType: error,
  }
}

export const deleteMerchantTypeStart = () => {
  return {
    type: DELETE_MERCHANT_TYPE_START
  }
}

export const deleteMerchantTypeReset = () => {
  return {
    type: DELETE_MERCHANT_TYPE_RESET
  }
}

export const deleteMerchantType = (merchantTypeId) => {
  return async dispatch => {
    dispatch(deleteMerchantTypeStart())
    const api = await getApi()
    api
      .delete(`${merchantNumbersAPI}/${merchantTypeId}`)
      .then(res => {
        dispatch(deleteMerchantTypeSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteMerchantTypeFail(err.response.data.error_messages[0]))
      })
  }

}