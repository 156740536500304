import React from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import MainList from './MainList';
// import AddItems from './ModalAddItem/index';
import Ventas from './Ventas';
import Mensajes from './Mensajes';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import {
  fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,
  fetchEditProductReset, fetchAddProductReset, fetchAddProduct, fetchEditProduct,
  fetchUploadProductsFile, fetchUploadProductsFileReset
} from '../../store/actions/clasificados.actions'

import mapFilteredProducts from '../../selectors/mapFilteredProducts'
import ModalDelete from './ModalDelete';
import ModalDetail from './ModalDetail';
import ModalAddItem from './ModalAddItem';

import AddProduct from './addProduct';
import EditProduct from './EditProduct';

const menulistItems = [{ title: 'Publicaciones', route: 'publicaciones', available: true }, { title: 'Ventas', route: 'ventas', available: true }, { title: 'Mensajes', route: 'mensajes', available: true },]


class Shops extends React.Component {
  state = {
    search: '',

    detailModal: false,
    deleteModal: false,

    addModal: false,

    addProductModal: false,
    editProductModal: false,

    fetchingProductDetail: true,
    view: "publicaciones",
    reasonDelete: 1,
    itemForDelete: null,
  };



  searchHandleChange = (event) => {
    this.setState({ search: event.target.value });
  }

  toggleItemDelete = (idProd) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      itemForDelete: idProd
    });
  }

  toggleItemDetail = () => {
    this.setState({ detailModal: !this.state.detailModal });
  }

  toggleAddItem = () => {
    this.setState({ addModal: !this.state.addModal });
  }


  toggleAddProduct = () => {
    this.setState({ addProductModal: !this.state.addProductModal });
  }

  toggleEditProduct = () => {
    this.setState({ editProductModal: !this.state.editProductModal });
  }



  handleDetailProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    this.toggleItemDetail();
  }


  handleEditProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    //this.toggleItemEdit();
  }


  async DeleteProduct() {
    this.props.fetchDeleteProduct(this.state.itemForDelete, this.state.reasonDelete)
  }

  switchView = (view) => {
    this.setState({ view });
  }

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }

  componentWillMount = () => {
    this.props.fetchGetProducts()
  }

  render() {

    return (
      <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Shops'>
        {(() => {
          switch (this.state.view) {

            case 'ventas':
              return (
                <Ventas {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'mensajes':
              return (
                <Mensajes {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );

            default:
              return (
                <>
                  <MainList
                    {...this.state}
                    searchHandleChange={this.searchHandleChange}
                    switchView={this.switchView}
                    handleDetailProduct={this.handleDetailProduct}
                    toggleAddItem={this.toggleAddItem}
                    toggleAddProduct={this.toggleAddProduct}
                    toggleEditProduct={this.toggleEditProduct}
                    toggleItemDelete={this.toggleItemDelete}
                    filteredProducts={this.props.filteredProducts}
                    handleEditProduct={this.handleEditProduct}
                    loading={this.props.clasificados.loadingproducts}
                  />
                  {this.state.deleteModal ? <ModalDelete DeleteProduct={() => this.DeleteProduct()} close={() => this.toggleItemDelete()} /> : null}
                  {this.state.detailModal ?
                    <ModalDetail
                      loading={this.props.clasificados.loadingDetailProduct}
                      loadingDeleting={this.props.clasificados.loadingDeleteProduct}
                      deleteStatus={this.props.clasificados.fetchDeleteProductSuccess}
                      producto={this.props.clasificados.detailProduct.values ? this.props.clasificados.detailProduct.values : null}
                      close={() => this.toggleItemDetail()} />
                    :
                    null
                  }
                  {this.state.addModal ? <ModalAddItem close={() => this.toggleAddItem()} /> : null}
                  {this.state.addProductModal ? <AddProduct toggleAddProd={() => this.toggleAddProduct()} /> : null}
                  {this.state.editProductModal ? <EditProduct producto={this.props.detailProductShow} toggleEditProd={() => this.toggleEditProduct()} /> : null}
                </>
              );
          }
        })()}
      </NavBar>
    );
  }
}
const mapStateToProps = ({
  clasificados: {
    products,
    loadingproducts,
    fetchDeleteProductSuccess,
    loadingDeleteProduct,
    detailProduct,
    fetchDetailProductSuccess,
    fetchDetailProductFail,
    fetchAddProductSuccess,
    fetchAddProductFail,
    fetchAddProductReset,
    fetchEditProductSuccess,
    fetchEditProductFail,
    fetchEditProductReset,
    loadingAddProduct,
    loadingEditProduct,
    resultUploadProductsFile,
    loadingUploadProductsFile,
    errorUploadProductsFile,
    errorMessage, loadingDetailProduct
  },
  selectedGuest,
  clasificados
}) => ({
  filteredProducts: products.length > 0 ? mapFilteredProducts(products) : [],
  loading: loadingproducts,
  succcessDelete: fetchDeleteProductSuccess,
  loadingDelete: loadingDeleteProduct,
  selectedGuest,
  detailProductShow: detailProduct,
  getDetailsSuccess: fetchDetailProductSuccess,
  getDetailsFail: fetchDetailProductFail,
  clasificados,

  fetchAddProductSuccess: fetchAddProductSuccess,
  fetchAddProductFail: fetchAddProductFail,

  fetchEditProductSuccess: fetchEditProductSuccess,
  fetchEditProductFail: fetchEditProductFail,

  loadingAddProduct: loadingAddProduct,
  loadingEditProduct: loadingEditProduct,

  resultUploadProductsFile,
  loadingUploadProductsFile,
  errorUploadProductsFile,
  errorMessage

})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchGetProducts,
    fetchDeleteProductReset,
    fetchDetailProduct,
    fetchDeleteProduct,
    fetchAddProduct,
    fetchEditProduct,
    fetchEditProductReset,
    fetchAddProductReset,
    fetchUploadProductsFile,
    fetchUploadProductsFileReset
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Shops)
)
