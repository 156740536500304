import React from 'react';
import Person from '../../../components/Person';

import StatusBadge from '../../../components/StatusBadge';
import Spinner from 'react-spinner-material'
import SectionHeader from '../../../components/SectionHeader'
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/Button';
import { exportFromJson } from '../../../utils/xlsxExportHelper'
import RitzTable from '../../../components/RitzTable';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { ContentWrapper, WrapperItems, RowItem, Description, Label, Container, CloseIcon, Row, TableContainer, Circle, ColumnItem, StyledButton, ErrorLabel, InputWrapper } from './styled'
import { formatNumber } from '../../../utils/utility'
import mapExpensasClient from '../../../selectors/mapExpensasClient'
import mapExportExpensasClient from '../../../selectors/mapExportExpensasClient'

const columns = [
  { title: 'Periodo', field: 'month' },
  { title: 'Monto', field: 'amount', type: 'currency', render: rowData => <label>{formatNumber(rowData.amount)}</label> },
  { title: 'Barrio', field: 'neighborhood_name' },
  { title: 'Fecha de Pago', field: 'payment_date', render: rowData => <label>{rowData.payment_date}</label> },
  { title: 'Banco Débito', field: 'debit_account_bank_name' },
  { title: 'Cod. Recibo', field: 'external_payment_code' },
  {
    title: 'Estado', field: 'status', type: 'text',


    render: rowData => {
      switch (rowData.status_code) {
        case "COMPLETED":
          return <StatusBadge green >{rowData.status}</StatusBadge>;
        case "PENDING":
          return <StatusBadge yellow >{rowData.status}</StatusBadge>;
        case "REJECTED":
          return <StatusBadge red >{rowData.status}</StatusBadge>;
        default:
          return null;
      }
    }
  },
  { title: 'Motivo Rechazo', field: 'motivo_rechazo' }
];

class PropietaryExpensesDetails extends React.Component {

  state = {

    selectedOption: '',
    expensaSelected: "",
    periodDownload: "",




  }


  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }


  fecthFileDetail = async period => {
    this.setState({ periodDownload: period.month + "-" + period.property_code })

    var response = await this.props.fetchgetFileDetailClient(period.id)
    var data = await response

    if (this.props.fileDetailClient) {
      this.setState({ dataResponse: this.props.fileDetailClient },
        this.downloadBlob())
    }

  }


  downloadBlob() {
    document.getElementById('download').click();
  }


  render() {
    console.log('props detalle propietario', this.props)
    return (

      <>
        <SectionHeader
          title={'Detalles del Propietario'}
          onClose={() => this.props.onClose()}
        ></SectionHeader>

        <ContentWrapper  >

          {this.props.detalle ?
            <Row justify={'space-between'}>
              <Person picture={this.props.detalle[0].client_picture_url} fullname={this.props.detalle[0].client_name + ' | Usuario: ' + this.props.detalle[0].client_user_name + ' | Lote: ' + this.props.propietarioDetalles.property_code} />
              <InputWrapper width={'80px'} justifyContent={'flex-end'}>
                <Button loading={this.props.loadingPropietaryDetail} onClick={() => exportFromJson(
                  this.props.detalle ? mapExportExpensasClient(this.props.detalle) : [],
                  columns,
                  "Reporte Barrios.")}
                >
                  Exportar</Button>
              </InputWrapper>
            </Row>
            :
            null
          }

          <RitzTable
            paging={true}
            style={{ width: '95%' }}
            columns={columns.slice(0, 6).concat({ title: 'Vista previa', field: 'imageUrl', render: rowData => <a style={{ cursor: 'pointer' }} onClick={() => this.fecthFileDetail(rowData)}><GetAppSharpIcon /></a> }).concat(columns.slice(6, 8))}
            data={this.props.detalle ? mapExpensasClient(this.props.detalle) : []}
            options={{
              toolbar: false,
              selection: false,
              search: false,
              paging: true,
              serverSide: false,
              exportButton: false,
              exportAllData: false,
            }}
            isLoading={this.props.loadingPropietaryDetail}
          />
          <a
            href={'data:application/octet-stream;base64,' + this.props.fileDetailClient}
            download={this.state.periodDownload + ".pdf"}
            id="download"
            hidden
          ></a>
        </ContentWrapper>
      </>
    )
  }

}

export default PropietaryExpensesDetails