import React from 'react'
import styled from 'styled-components';

export const KitsContainerBox = styled.div`
  min-width: 160px;
  height: ${({ collapsed }) => (collapsed ? '60px' : '100px')};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  cursor: pointer;
  border: solid 2px #FFF;
  ${({ active }) => active && `border: solid 2px #b49d75!important;`}
  ${({ alternative }) => alternative && `border: solid 1px #e9e9ec;`}
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  background-color: #ffffff;
  margin-right: 20px;
  padding: 16px 13px 14px 13px;
  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'space-between')};
  color: #001441;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
`//Change Color and Background-color Source
export const Quantity = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ff24241a;
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
`//Change Background-color Source
export const AltQuantity = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  color: #FFFFFF;
  display: flex;
  background-color: #ff2424;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`
export const KitsName = styled.div`
  
`//Change Color Source


class KitsContainer extends React.Component {
  render() {
    const { collapsed, alternative, quantity, name } = this.props;
    return (
      <KitsContainerBox {...this.props}>
        {!collapsed ? <Quantity>{quantity}</Quantity> : null}
        <KitsName>{name}</KitsName>
        {alternative && quantity > 0 ? <AltQuantity>{quantity}</AltQuantity> : null}
      </KitsContainerBox>
    )
  }
}

export default KitsContainer;