import { Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Section from '../../../components/Section'
import Button from '../../../components/Button'
import StyledTabs from '../../../components/StyledTabs'
//import SearchBar from '../../../components/SearchBar'
import ResourceList from '../../../components/ResourceListConversations'
import EmptyLoader from 'react-spinner-material'
import styled from 'styled-components'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import themeStyles from './tickets.theme.style'
import mapCategoriesByRole from '../../../selectors/mapCategoriesByRole'
import mapConversations from '../../../selectors/mapConversaciones'
import { Avatar, Grid } from '@material-ui/core'
import StyledInput from '../../../components/Input'
import { getDayHourDate } from '../../../utils/dateParser'
import { useState, useEffect, useRef } from 'react'
import ReactDOM from "react-dom";
import uuid from "uuid";

import {
  getMsg,
  sendMsg,
  newConversation,
  fetchConversations,
  setSelectedConversation, newConversationReset,
  resetSelectedConversation
} from '../../../store/actions/clasificados.actions'


import {
  fetchDetailProduct
} from '../../../store/actions/clasificados.actions'

const ScrollWrapper = styled.div`
  width: 100%;
  
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
  &::-webkit-scrollbar {
    display: none;
  }
`
const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`
const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`
const Wrapper = styled.div`
margin-top: 40px;
  
  flex-direction: column;
  min-height: 600px;
`

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 30% 70%
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  margin-top: 40px;
  width: 100%;
`

const ContentWrapper = styled.div`
  width: 100%;
  
  min-height: 600px;
`
const ListWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ghost};
  padding-right: 0px;
  max-height: 600px;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
`
const PageWrapper = styled.div`
  width:100%;
  min-height: 100%;
  align-items: normal;
  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
const ContainerSelects = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
const SelectStyled = styled(Select)`
  width: 50%;
  margin: 0px 10px;
`
const ContainerSearchBar = styled.div`
  width: 100%;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`
const SearchWrapper = styled.div`
  max-height: auto;
  display: flex;
  flex-direction: column;
  border: none;
  margin-right: 1px;
  border-radius: 10px;
`
const DataContainer = styled.div`
  width: 100%;
  padding: 25px 25px;
  border-bottom-right-radius: 10px;
`
const Row = styled.div`
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
`
const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  margin: 2% 0%;
`
const HourText = styled.div`
 font-size: 12px;
 /*color: ${({ theme }) => theme.colors.blueRibbon};*/
 color: #31735f;
 text-align: right;
`
const TextContainer = styled.div`
  width: ${({ sentbyuser }) => sentbyuser ? "300px" : "370px"};
  height: auto;
  padding: 1% 2%;
  border-radius: 5px;
  background-color: ${({ sentbyuser }) => sentbyuser ? "#31735f57" : "#ececec"}; 
  margin-left: ${({ sentbyuser }) => sentbyuser ? "" : "48%"};   
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`
const ContainerMessage = styled.div`
  height: 550px;
  flex-direction: column-reverse;
  overflow: overlay;
  scroll-behavior: smooth;
`
const ImageMessage = styled.img`
  height: 150px;
  width: 60%;
  align-self: ${({ sentbyuser }) => sentbyuser ? "flex-start" : "flex-end"};
`
const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8% 5% 0.8% 5%;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  line-height: 25px;
  max-width: 32%;
`

class Mensajes extends React.Component {

  state = {
    categoriesList: [],
    filter: '',
    msjData: '',
    conversationSeleccionada: null,
    conversationsList: {},
    categoryId: { value: 0, label: 'Todas' },
    sendText: '',
    tabSelected: 0,
    isModalState: false,
    isModalCategory: false,

    conversationCreada: this.props.clasificados.newConversationSuccess,

  }

  getInitialValues = () => ({
    filter: ''
  })

  componentDidMount = () => {
    this.props.fetchConversations()
    this.scrollAbajo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.conversationsList[this.state.tabSelected].length === 0 && prevState.filter.length !== 0 && this.state.filter.length === 0) {
      this.props.getMsg(this.state.conversationSeleccionada)
    }


    if (this.props.clasificados.newConversationSuccess == true) {
      this.props.getMsg(this.props.clasificados.newConversation.data.id)
      this.props.setSelectedConversation(this.props.clasificados.newConversation.data)
      this.setState({ conversationSeleccionada: this.props.clasificados.newConversation.data })
      this.setState({ sendText: '' })
      this.setState({ conversationCreada: false })
      this.props.newConversationReset()
    }
    this.scrollAbajo();
  }

  fetchContent = reset => {
    this.props.fetchTickets(0)
    this.props.fetchCategoriesByRole()
  }

  isLoadingContent = () => false

  handleClick = conversation => {
    this.props.setSelectedConversation(conversation)
    this.setState({ conversationSeleccionada: conversation })
    this.setState({ sendText: '' })

    this.props.getMsg(conversation.id)
  }

  handleCategory = (e) => {
    this.props.fetchTickets(e.value)
    this.setState({ selectValue: e })
  }

  handleInputOnChange = (value) => {
    this.setState({ filter: value })
    this.props.searchTickets(value)
  }

  handleInputClear = (e) => {
    this.setState({ filter: '' })
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13 && e.target.value !== '') {
      //  var filterList = this.props.conversationsList[this.state.tabSelected].filter(item => { return item.dni == this.state.filter || item.name.includes(this.state.filter) || item.lote.includes(this.state.filter) })
    }
  }

  handleSend = async () => {
    if (this.state.sendText != '') {
      const formattedBody = {
        conversation_id: this.state.conversationSeleccionada.id,
        text: this.state.sendText
      }
      await this.props.sendMsg(formattedBody)
      this.handleClick(this.state.conversationSeleccionada)
    }
  }

  handleClose = (MessageClose) => {
    this.props.closeTicket(this.state.ticket.id, MessageClose)
  }

  handleMessageOnChange = (e) => {
    this.setState({ sendText: e.target.value })
  }

  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }

  scrollAbajo = () => {
    const objDiv = document.getElementById('mensajeslistado');
    if (objDiv != null) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  renderMessagesContent = () => (
    <>
      {
        this.props.clasificados.messages.data ?
          <>
            {this.state.conversationSeleccionada ?
              <Row justify={'space-between'} align={'center'}>
                <Grid xs={1}>
                  <Avatar style={{ background: '#31735f', width: '40px', height: '40px', padding: '10px' }} />
                </Grid>
                <Grid xs={11}>
                  <Row justify={'space-between'}>
                    {
                      this.state.conversationSeleccionada.name ?
                        <label>{this.state.conversationSeleccionada.name}</label>
                        :
                        null
                    }
                    {
                      this.state.conversationSeleccionada.sendername ?
                        <label>{this.state.conversationSeleccionada.sendername}</label>
                        :
                        null
                    }
                  </Row>
                  <Row>
                    <label>Producto: {this.state.conversationSeleccionada.title}</label>
                  </Row>
                </Grid>
              </Row>
              :
              null
            }
            <ContainerMessage id="mensajeslistado">
              {this.props.clasificados.messages.data.length > 0 && this.props.clasificados.sendMsgSuccess ?
                <>
                  {this.props.clasificados.messages.data.slice(0).reverse().map((e) => (
                    <>
                      {!e.sentbyuser ? (
                        <TextContainer sentbyuser={true}>
                          <>
                            {e.picture_url ? (
                              <ImageMessage sentbyuser={true} src={e.picture_url} />
                            ) : (<></>)}
                            <Name>{e.text}</Name>
                          </>
                          <HourText>{getDayHourDate(e.date)}</HourText>
                        </TextContainer>
                      ) : (
                        <TextContainer sentbyuser={false}>
                          <>
                            {e.picture_url ? (
                              <ImageMessage sentbyuser={false} src={e.picture_url} />
                            ) : (<></>)}
                            <Name>{e.text}</Name>
                          </>
                          <HourText>{getDayHourDate(e.date)}</HourText>
                        </TextContainer>
                      )
                      }
                    </>
                  ))}
                </>
                :
                <h5 style={{ marginTop: '25px' }}>No hay mensajes ...</h5>
              }


            </ContainerMessage>
            {
              this.props.userInfo.menusAuth.map(menu => {
                if (menu.code === "SHOPS_BO") {
                  return (
                    <>
                      {/*<Row justify={'space-between'} align={'center'} style={{ width: '65%', margin: "4% 1%" }}>
                        <StyledButton onClick={this.toggleModalCategory}>{'Transferir'}</StyledButton>
                        {this.state.tabSelected === 1 ? "" : <StyledButton onClick={() => this.toggleModalState("pending")}>{'Pendiente'}</StyledButton>}   {this.state.tabSelected === 2 ? "" : <StyledButton onClick={() => this.toggleModalState("close")} >{'Terminado'}</StyledButton>}
                      </Row> */}
                      <Row justify={'space-between'} align={'center'} style={{ marginTop: '30px' }}>
                        <StyledInput value={this.state.sendText} onChange={(e) => this.handleMessageOnChange(e)} style={{ width: '92%' }}></StyledInput>
                        <Button onClick={this.handleSend} >{'Enviar'}</Button>
                      </Row>
                    </>
                  )
                }
              })
            }
          </>
          :
          null
      }


      {
        this.props.clasificados.loadingGetMsg ?
          <EmptyLoader />
          :
          null

      }


    </>
  )

  renderPageContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <Section onBack={() => this.props.onClose()} title={'Bandeja de entrada'}>

      <WrapperGrid>



        {/*<ContainerSearchBar>
            <SearchBar
              placeholder={'Busca por nombre o DNI'}
              style={'width: \'70%\' '}
              value={this.state.filter}
              onChange={(e) => this.handleInputOnChange(e)}
              onClear={this.handleInputClear}
              onKeyDown={this.handleKeyPress}
              noBorder={true}
              autofocus
            />
          </ContainerSearchBar> */}
        <div>
          <StyledTabs
            value={this.state.tabSelected}
            tabs={[
              { label: 'Activas', value: this.props.conversationsList[0].length, id: 0 },
              { label: 'Finalizadas', value: this.props.conversationsList[1].length, id: 1 }
            ]}
            handleTabChange={this.handleTabChange}
          />
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            <ResourceList
              elements={this.props.conversationsList[this.state.tabSelected]}
              onClick={this.handleClick}
              selectedPos={this.props.selectedConversation ? this.props.selectedConversation.pos : null}
              noMoreContentMsg={'No hay mas conversaciones disponibles'}
            />
          </ListWrapper>
        </div>
        <DataContainer>

          {
            this.props.clasificados.conversations && this.props.conversationsList[this.state.tabSelected].length > 0 ?
              this.renderMessagesContent()
              :
              ''
          }
        </DataContainer>


      </WrapperGrid>
    </Section>
  )

  myRef = null

  render() {
    return (
      this.props.clasificados.loadingConversations ?
        (
          <EmptyLoader />
        )
        :
        (
          <Formik
            initialValues={this.getInitialValues()}
            render={e => this.renderPageContent(e)}
          />
        )


    )
  }
}

const mapStateToProps = ({
  userInfo,
  clasificados,
  selectedConversation
}) => ({
  clasificados,
  conversationsList: mapConversations(clasificados.conversations),
  selectedConversation,
  userInfo,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMsg, sendMsg, newConversation, fetchConversations, newConversationReset,
      setSelectedConversation, resetSelectedConversation, fetchDetailProduct
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
  )(Mensajes)
)