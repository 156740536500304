/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  deleteEmergencyPhones,
  selectEmergencyPhonesRows
} from "../../../../store/actions/alerts.actions";
import OnCheckSection from "../../../../components/OnCheckSection";

const mapStateToProps = state => {
  const { selectedEmergencyPhoneRows } = state.alerts;
  return {
    selectedEmergencyPhoneRows: selectedEmergencyPhoneRows
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ deleteEmergencyPhones, selectEmergencyPhonesRows }, dispatch);

function EmergencyPhoneTableFooter(props) {
  const { selectedEmergencyPhoneRows, refreshGrid } = props;

  const resolveAlerts = async () => {
    await props.deleteEmergencyPhones(selectedEmergencyPhoneRows);
    await props.refreshGrid();
    await props.selectEmergencyPhonesRows([]);
  };

  const cancelSelection = async () => {
    await props.selectEmergencyPhonesRows([]);
    await refreshGrid();
  };

  return (
    <>
      {selectedEmergencyPhoneRows?.length > 0 ? (
        <OnCheckSection
          title="Eliminar"
          cancel="Cancelar"
          onClick={() => resolveAlerts()}
          onCancel={() => cancelSelection()}
          selectedRows={selectedEmergencyPhoneRows.length}
          description={"teléfonos seleccionados"}
        />
      ) : null}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyPhoneTableFooter);
