import { createSelector } from 'reselect'
var moment = require('moment');

let mapCommunicationD = createSelector(  
  people => people,
  people => 
  (people.map(person => ({
    ...person,
    id:person.id,
    full_name : person.data.full_name,
    dni:  person.data.dni,
    date : moment(person.data.date).format('DD-MM-YYYY'),
    comunication_number :person.data.comunication_number,
    comunication_type:person.data.comunication_type,
    motive:person.data.motive,
    state:person.data.state,
    match_type:person.data.match_type
  })) )
)

export default mapCommunicationD