import { createSelector } from 'reselect'

let mapSecurityMembers = createSelector(
  securityMembers => securityMembers,
  securityMembers =>
    securityMembers.map(
      (
        {
          birthdate,
          dni,
          email,
          family_name,
          gender,
          isunderage,
          name,
          neighbourhood_guid,
          neighbourhoodname,
          neighbourhoodpropertiesregistrationcode,
          personid,
          phone_number,
          profile_picture,
          state,
          username
        },
        i
      ) => ({
        birthdate,
        dni,
        email,
        fullname: name + ' ' + family_name,
        family_name,
        gender,
        isunderage,
        name,
        neighbourhood_guid,
        neighbourhoodname,
        neighbourhoodpropertiesregistrationcode,
        personid,
        phone_number,
        profile_picture,
        state,
        username
      })
    )
)

export default mapSecurityMembers
