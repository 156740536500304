import React from 'react';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Formik } from 'formik'
import * as Yup from 'yup'

import mapClientDataByNeighbourhoods from '../../../selectors/mapClientDataByNeighbourhoods';
import mapNeighbourhoodsMasterData from '../../../selectors/mapNeighbourhoodsMasterData'

import { fetchReporteClientsByNeighbourhood } from '../../../store/actions/reportes.actions';
import { fetchNeighbourhoods } from '../../../store/actions/proveedores.actions';

import { FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel, StatusBar, StatusCounter, LabelWrapper } from '../styled';

import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';

class ClientesByBarrio extends React.Component {

  state = {

  }

  getInitialValues = () => ({})

  handleSubmit = data => {
    const Guids = data.barrio.map((barrio, i) => (barrio.guid ))
    const formattedBody = { NeighbourhoodGuids : Guids }
    this.props.fetchReporteClientsByNeighbourhood(formattedBody)
  }

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        barrio: Yup.string().required('Campo requerido')
      })
    )

  componentDidMount() {
    this.props.fetchNeighbourhoods()
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <>
      <FormContainer onSubmit={handleSubmit}>
        <Row align="flex-end">
          <InputWrapper margin={'20px 10px'}>
            <InputLabel>{'Barrio'}</InputLabel>
            <Select
              name={'barrio'}
              value={values.barrio}
              isSearchable
              onChange={values => setFieldValue('barrio', values)}
              error={errors.barrio}
              isMulti
              options={this.props.listBarrios}
              placeholder="Seleccione un barrio..."
              isLoading={this.props.loadingListBarrios}
              autoFocus
            />
            {errors.barrio && <ErrorLabel>{errors.barrio}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputWrapper margin={'20px 15px'}>
              <Button type={'submit'} medium width={'100%'}>
                {'Filtrar'}
              </Button>
            </InputWrapper>
          </InputWrapper>
        </Row>
      </FormContainer>

    </>
  );


  render() {
    return (
      <>
        <SectionHeader title='Cuentas por Barrio'/>

        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />

        {this.props.reporteClientsByNeighbourhood ?
          <StatusBar>
            <StatusCounter>Total Debitos {this.props.reporteClientsByNeighbourhood.TotalDebits}</StatusCounter>
            <StatusCounter>Total Itau {this.props.reporteClientsByNeighbourhood.TotalItauAccounts}</StatusCounter>
            <StatusCounter>Total Billeteras {this.props.reporteClientsByNeighbourhood.TotalWallets}</StatusCounter>
          </StatusBar>
          :
          null
        }


        <RitzTable
          paging={true}
          columns={[
            { title: 'Barrio', field: 'name' },
            { title: 'Billeteras', field: 'billeteras' },
            { title: 'Cuentas Itau', field: 'cuentas_itau' },
            { title: 'Debitos', field: 'debitos' }
          ]}
          isLoading={this.props.loadingClientsByNeighbourhood}
          options={{
            toolbar: true,
            selection: false,
            search: false,
            paging: true,
            serverSide: false,
            exportButton: true,
            exportAllData: true,
            exportFileName: "reporte." + (new Date().toISOString().split('.')[0]),
          }}
          pagination={{
            pageSize: 10
          }}
          data={this.props.reporteClientsByNeighbourhood ? mapClientDataByNeighbourhoods(this.props.reporteClientsByNeighbourhood.Neighbourhoods) : []}
        />
      </>
    );
  }
}




const mapStateToProps = state => {
  return {
    reporteClientsByNeighbourhood: state.reportes.reporteClientsByNeighbourhood,
    loadingClientsByNeighbourhood: state.reportes.loadingClientsByNeighbourhood,
    listBarrios: mapNeighbourhoodsMasterData(state.proveedores.neighbourhoodList),
    loadingListBarrios: state.proveedores.loadingClientsByNeighbourhood
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReporteClientsByNeighbourhood, fetchNeighbourhoods
    },
    dispatch
  )


export default connect(mapStateToProps, mapDispatchToProps)(ClientesByBarrio)
