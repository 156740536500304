import React from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import Reservas from './Reservas/index';
import Tickets from './Tickets/index';
import Mascotas from './Mascotas/index';
import Expensas from './Expensas/index';
import Billetera from './Billetera/index';
import ClientesByBarrio from './ClientesByBarrio/index';
import UsersFunnel from './DogReport/UsersFunnel/index';
import Barrios from './Barrios/index';
import PropertiesFunnel from './DogReport/PropertiesFunnel/index';


const menulistItems = [
  { title: 'Unidades/usuarios por Barrios', route: 'barrios', available: true },
  { title: 'Reservas', route: 'reservas', available: true },
  { title: 'Tickets', route: 'tickets', available: true },
  // { title: 'Mascotas', route: 'mascotas', available: true },
  { title: 'Expensas', route: 'expensas', available: true },
  { title: 'Cuentas por Barrio', route: 'clientesByBarrio', available: true },
  { title: 'Reporte Perro - Usuarios', route: 'funnelusuarios', available: true },
  { title: 'Reporte Perro - Lotes', route: 'funnelproperties', available: true }
];


class Reportes extends React.Component {

  state = {
    search: '',
    view: 'tickets'
  };

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }


  switchView = (view) => {
    this.setState({ view });
  }

  render() {
    return (
      <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Reportes'>
        {(() => {
          switch (this.state.view) {
            case 'mascotas':
              return (
                <Mascotas {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'tickets':
              return (
                <Tickets {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'expensas':
              return (
                <Expensas {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'clientesByBarrio':
              return (
                <ClientesByBarrio {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
      

            case 'reservas':
              return (
                <Reservas {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );


              case 'billeteraxbarrio':
                return (
                  <Billetera {...this.state}
                    toggleItemDelete={this.toggleItemDelete}
                    toggleItemDetail={this.handleDetailProduct}
                    searchHandleChange={this.searchHandleChange}
                    switchView={this.switchView}
                  />
                );

                case 'funnelusuarios':
                  return (
                    <UsersFunnel {...this.state}
                      toggleItemDelete={this.toggleItemDelete}
                      toggleItemDetail={this.handleDetailProduct}
                      searchHandleChange={this.searchHandleChange}
                      switchView={this.switchView}
                    />
                  );

                  case 'funnelproperties':
                    return (
                      <PropertiesFunnel {...this.state}
                        toggleItemDelete={this.toggleItemDelete}
                        toggleItemDetail={this.handleDetailProduct}
                        searchHandleChange={this.searchHandleChange}
                        switchView={this.switchView}
                      />
                    );
            default:
              return (
                <Barrios {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
          }
        })()}
      </NavBar>
    );
  }
}




export default Reportes



