import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withWidth } from '@material-ui/core'
import axios from 'axios'
//import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { bindActionCreators } from 'redux'
import { fetchPaymentPayMi,fetchGetTokenPayment } from '../../../store/actions/billetera.actions'

import PaperLayout from '../../../components/PaperLayout'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep/index' 

class GeneratePay extends Component {
  state = {
    step: {
      name: 'Creation',
      number: 1,
      percentage: 14,
    },
    GetCodeQRImage: 'data:image/png;base64,' + this.props.GetCodeQRImage,
    transaction: {
      'description': 'description'
    }
  }

  componentWillMount=()=> {
    this.props.fetchGetTokenPayment()
  }

  parseAmount(stringValue) {
    stringValue = stringValue.trim();
    var result = stringValue.replace(/[^0-9]/g, '');
    if (/[,\.]\d{2}$/.test(stringValue)) {
        result = result.replace(/(\d{2})$/, '.$1');
    }
    return parseFloat(result);
}

  updateTransaction = (transaction) => {
    var amountNumber =  this.parseAmount(transaction.amount);
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido); //Genero ID unico fecha de ahora
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        total_amount: amountNumber,
        description: transaction.concepto,
        customer_email: "",
        item_list:  [],
        failure_url: "",
        success_url: "",
        currency_code: "ARS",
        operation_id: hoy.toISOString(),
        token: this.props.token
      }
    }), () => this.props.fetchPaymentPayMi(this.state.transaction))
  }

  NextStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number + 1,
        percentage: prevState.step.percentage + 90
      }
    }))
  }


  BackStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number: prevState.step.number - 1,
        percentage: prevState.step.percentage - 90
      }
    }))
  }


  render() {
    return (
      <PaperLayout title={'Nuevo Link de pago'} Step={this.state.step} style={{bakcgroundColor: 'none'}}>
        {(() => {
          switch (this.state.step.number) {
            case 1:
              return <FirstStep NextStep={() => { this.NextStep() }} updateTransaction={this.updateTransaction} />;
            case 2:
              return <SecondStep
                      BackStep={() => { this.props.onClose() }}
                      transaction={this.state.transaction}
                      billetera={this.props.billetera} 
                    />
            default:
              return null;
          }
        })()}
      </PaperLayout >
    )
  }
}

const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
    billetera: state.billetera,
    token: state.billetera.TokenPayment !== "" ? state.billetera.TokenPayment : ""
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetTokenPayment,fetchPaymentPayMi
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(GeneratePay, axios)
)
  