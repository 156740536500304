import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utility";
import { LocalActivityOutlined } from "@material-ui/icons";

const initialState = {
  TransactionsList: [],
  loadingTransactionsList: true,
  errorTransactionsList: false,
  hasMoreTransactionsList: true,


  balance: 0,
  loadingBalance: true,
  errorBalance: false,

  createdNewTransaction: false,
  loadingNewTransaction: false,
  errorNewTransaction: false,

  RequestedTransaction: {},
  loadingRequestedTransaction: false,
  errorRequestedTransaction: false,

  GetCodeQRImage: 0,
  loadingGetCodeQRImage: false,
  errorGetCodeQRImage: false,

  idTransaction: 0,
  loadingIdTransaction: false,
  errorIdTransaction: false,

  errorMessageCreateTransactionRequest: false,
  loadingCreateTransactionRequest: false,

  ApproveTransaction: [],
  errorApproveTransaction: false,
  loadingApproveTransaction: false,
  errorApproveTransactionMessage: false,

  DeniedTransaction: [],
  errorDeniedTransaction: false,
  loadingDeniedTransaction: false,
  errorDeniedTransactionMessage: false,

  AccountQR: [],
  errorAccountQR: "Busque para obtener resultados",

  errorAccountDebitInfo: false,
  loadingAccountDebitInfo: false,

  loadingAccountInfo: false,
  errorAccountInfoMessage: false,
  loadingAccountInfoMessage: false,

  errorAccountInfoByLote: false,
  loadingAccountInfoByLote: false,
  errorAccountInfoByLoteMessage: false,

  transactionChargeCard: false,
  errorTransactionChargeCard: false,
  errorTransactionChargeCardMessage: "",
  loadingTransactionChargeCard: false,

  TransactionData: false,
  errorTransactionData: false,
  errorTransactionDataMessage: "",
  loadingTransactionData: false,


  errorFetchDebitAccounts: false,
  loadingfetchDebitBankAccounts: false,
  bankAccounts: [],
  selectedBankAccount: null,

  loadingPaymentPayMi: false,
  PaymentPayMi: false,
  errorPaymentPayMiMessage: false,
  PaymentPayMiMessage: false,

  movementsLast24HoursReportData: null,
  fetchMovementsLast24HoursReportSuccess: false,
  loadingFetchMovementsLast24HoursReport: false,

  movementsLast7DaysReportData: null,
  fetchMovementsLast7DaysReportSuccess: false,
  loadingFetchMovementsLast7DaysReport: false,

  errorDownloadReportMessage: [],
  errorDownloadReport: false,

  transactionAuth: [],

  errorDownloadSummaryMessage: [],
  errorDownloadSummary: false,
  loadingDownloadSummary: false,
  summary: false,

  page: 0,
  hasMoreTransactions: true,
  eventTake: 10
};

//-----------------ALL MOVEMENTS TRANSACTION-------------------------
const fetchTransactionListSuccess = (state, action) => {
  return updateObject(state, {
    TransactionsList: action.transactions.values,
    loadingTransactionsList: false,
    errorTransactionsList: false
  });
};

const fetchTransactionListFail = (state, action) => {
  return updateObject(state, {
    TransactionsList: [],
    loadingTransactionsList: false,
    errorTransactionsList: action.error.response.data.message
  });
};

const fetchTransactionListStart = state => {
  return updateObject(state, {
    TransactionsList: [],
    loadingTransactionsList: true
  });
};
//-----------------HAS MORE TRANSACTIONS-------------------------
const hasMoreTransactionListSuccess = (state, action) => {

  return updateObject(state, {
    hasMoreTransactionsList: action.hasMore,
  });
};

//-----------------ALL MOVEMENTS TRANSACTION-------------------------
const fetchTransactionQRSuccess = (state, action) => {
  if (action.transactions === "RegenerateFail") {
    return updateObject(state, {
      transactionQR: null,
      loadingTransactionQR: false,
      errorTransactionQR: "RegenerateFail"
    });
  } else {
    return updateObject(state, {
      transactionQR: action.transactions.values,
      loadingTransactionQR: false,
      errorTransactionQR: false
    });
  }
};

const fetchTransactionQRFail = (state, action) => {
  return updateObject(state, {
    loadingTransactionQR: false,
    errorTransactionQR: action.error.response.data.message
  })
}

const fetchTransactionQRStart = state => {
  return updateObject(state, { loadingTransactionQR: true, GetCodeQRImage: 0 })
}


//-----------------Get Balance-------------------------
const fetchGetBalanceSuccess = (state, action) => {
  return updateObject(state, {
    balance: action.balance.data,
    loadingBalance: false,
  })
}

const fetchGetBalanceFail = state => {
  return updateObject(state, { loadingBalance: false, errorBalance: true })
}

const fetchGetBalanceStart = state => {
  return updateObject(state, { loadingBalance: true })
}

//-----------------REQUESTED TRANSACTION-------------------------
const fetchRequestedTransactionSuccess = (state, action) => {
  return updateObject(state, {
    RequestedTransaction: action,
    loadingRequestedTransaction: false,
  })
}

const fetchRequestedTransactionFail = state => {
  return updateObject(state, { loadingRequestedTransaction: false, errorRequestedTransaction: true })
}

const fetchRequestedTransactionStart = state => {
  return updateObject(state, { loadingRequestedTransaction: true })
}

//-----------------CODE QR IMAGE-------------------------
const fetchGetCodeQRImageSuccess = (state, action) => {
  return updateObject(state, {
    GetCodeQRImage: action.GetCodeQRImage,
    loadingGetCodeQRImage: false,
    errorGetCodeQRImage: false
  })
}

const fetchGetCodeQRImageFail = state => {
  return updateObject(state, {
    loadingGetCodeQRImage: false,
    errorGetCodeQRImage: true
  })
}

const fetchGetCodeQRImageStart = state => {
  return updateObject(state, {
    loadingGetCodeQRImage: true,
    errorGetCodeQRImage: false
  })
}

//-----------------  Transaction Request-------------------------

const fetchCreateTransactionRequestSuccess = (state, action) => {
  return updateObject(state, {
    idTransaction: action.id,
    loadingCreateTransactionRequest: false,
    errorMessageCreateTransactionRequest: false
  })
}

const fetchCreateTransactionRequestFail = (state, action) => {
  return updateObject(state, {
    errorCreateTransactionRequest: true,
    loadingCreateTransactionRequest: false,
    errorMessageCreateTransactionRequest: action.errorMessageCreateTransactionRequest
  })
}

const fetchCreateTransactionRequestStart = state => {
  return updateObject(state, { loadingCreateTransactionRequest: true })
}


//-----------------  Transactions Pending aprove  -------------------------

const fetchGetTransferPendingSuccess = (state, action) => {
  return updateObject(state, {
    TransferPending: action.TransferPending,
    loadingTransferPending: false
  })
}

const fetchGetTransferPendingFail = state => {
  return updateObject(state, { errorTransferPending: true, loadingTransferPending: false })
}

const fetchGetTransferPendingStart = state => {
  return updateObject(state, { loadingTransferPending: true })
}

//-----------------  Transactions Pending aprove  -------------------------

const fetchGetNumberOfTransferPendingSuccess = (state, action) => {
  return updateObject(state, {
    NumberOfTransferRequest: action.NumberOfTransferRequest.total_pending,
    loadingNumberOfTransferRequest: false,
  })
}

const fetchGetNumberOfTransferPendingFail = state => {
  return updateObject(state, { errorIdTransaction: true, loadingNumberOfTransferRequest: false })
}

const fetchGetNumberOfTransferPendingStart = state => {
  return updateObject(state, { loadingNumberOfTransferRequest: true })
}


//----------------- Approve Transaction -------------------------

const fetchApproveTransactionSuccess = (state, action) => {
  return updateObject(state, {
    ApproveTransaction: action.ApproveTransfer.ResultList,
    loadingApproveTransaction: false,
    errorApproveTransactionMessage: false,
    errorApproveTransaction: false
  })
}

const fetchApproveTransactionFail = (state, action) => {
  return updateObject(state, {
    errorApproveTransaction: true,
    errorApproveTransactionMessage: action.errorApproveTransactionMessage,
    loadingApproveTransaction: false
  })
}

const fetchApproveTransactionStart = state => {
  return updateObject(state, {
    loadingApproveTransaction: true,
    errorApproveTransactionMessage: false
  })
}

//----------------- Denied Transaction -------------------------

const fetchDeniedTransactionSuccess = (state, action) => {
  return updateObject(state, {
    DeniedTransaction: action.DeniedTransfer.ResultList,
    loadingDeniedTransaction: false,
    errorDeniedTransaction: false
  })
}

const fetchDeniedTransactionFail = (state, action) => {
  return updateObject(state, {
    errorDeniedTransaction: true,
    errorDeniedTransactionMessage: action.errorDeniedTransactionMessage,
    loadingDeniedTransaction: false
  })
}

const fetchDeniedTransactionStart = state => {
  return updateObject(state, { loadingDeniedTransaction: true })
}

//----------------- Reset transfer operation response -------------

const resetTransactionOperationResponse = (state, action) => {
  return updateObject(state, {
    DeniedTransaction: [],
    errorDeniedTransaction: false,
    loadingDeniedTransaction: false,
    errorDeniedTransactionMessage: false,
    ApproveTransaction: [],
    loadingApproveTransaction: false,
    errorApproveTransactionMessage: false,
    errorApproveTransaction: false
  })
}
//----------------- Get Account info -------------------------

const fetchGetAccountSuccess = (state, action) => {
  return updateObject(state, {
    AccountInfo: action.AccountInfo,
    loadingAccountInfo: false,
    errorAccountInfo: false,
    errorAccountInfoMessage: false
  })
}

const fetchGetAccountFail = (state, action) => {
  return updateObject(state, {
    errorAccountInfo: true,
    loadingAccountInfo: false,
    errorAccountInfoMessage: action.errorAccountInfo
  })
}

const fetchGetAccountStart = state => {
  return updateObject(state, { loadingAccountInfo: true })
}


//----------------- Get Account by Lote info -------------------------

const fetchGetAccountByLoteSuccess = (state, action) => {
  return updateObject(state, {
    AccountInfoByLote: action.AccountInfoByLote,
    loadingAccountInfoByLote: false,
    errorAccountInfoByLote: false,
    errorAccountInfoByLoteMessage: false
  })
}

const fetchGetAccountByLoteFail = (state, action) => {
  return updateObject(state, {
    errorAccountInfoByLote: true,
    errorAccountInfoByLoteMessage: action.errorAccountInfoByLote,
    loadingAccountInfoByLote: false
  })
}

const fetchGetAccountByLoteStart = state => {
  return updateObject(state, { loadingAccountInfoByLote: true })
}

//----------------- Get Account by Lote info -------------------------

const fetchGetAccountQRSuccess = (state, action) => {
  return updateObject(state, {
    AccountQR: action.AccountQR,
    loadingAccountQR: false,
    errorAccountQR: false
  })
}

const fetchGetAccountQRFail = state => {
  return updateObject(state, { errorAccountQR: true, loadingAccountQR: false })
}

const fetchGetAccountQRStart = state => {
  return updateObject(state, { loadingAccountQR: true })
}


//----------------- Get Account DEBIT info -------------------------

const fetchGetAccountDebitSuccess = (state, action) => {
  return updateObject(state, {
    AccountDebitInfo: action.AccountDebitInfo,
    errorAccountDebitInfo: false,
    loadingAccountDebitInfo: false
  })
}

const fetchGetAccountDebitFail = state => {
  return updateObject(state, { errorAccountDebitInfo: true, loadingAccountDebitInfo: false })
}

const fetchGetAccountDebitStart = state => {
  return updateObject(state, { loadingAccountDebitInfo: true })
}


//-------------Re send token--------------------------------

const fetchReSendTokenSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    errorReSendToken: false,
    loadingReSendToken: false
  })
}

const fetchReSendTokenFail = state => {
  return updateObject(state, { errorReSendToken: true, loadingReSendToken: false })
}

const fetchReSendTokenStart = state => {
  return updateObject(state, { loadingReSendToken: true })
}

//-------------Transaction Charge With CardAPI--------------------------------

const fecthTransactionChargeWithCardSuccess = (state, action) => {
  return updateObject(state, {
    transactionChargeCard: action.transactionChargeCard,
    errorTransactionChargeCard: false,
    errorTransactionChargeCardMessage: "",
    loadingTransactionChargeCard: false
  })
}

const fecthTransactionChargeWithCardFail = (state, action) => {
  return updateObject(state, {
    errorTransactionChargeCardMessage: action.errorTransactionChargeCard,
    errorTransactionChargeCard: true,
    loadingTransactionChargeCard: false
  })
}

const fecthTransactionChargeWithCardStart = state => {
  return updateObject(state, {
    loadingTransactionChargeCard: true,
    errorTransactionChargeCard: false,
    errorTransactionChargeCardMessage: "",
  })
}

//-------------Transaction Charge With CardAPI--------------------------------

const fetchTransactionDataSuccess = (state, action) => {
  return updateObject(state, {
    TransactionData: action.TransactionData,
    errorTransactionData: false,
    errorTransactionDataMessage: "",
    loadingTransactionData: false
  })
}

const fetchTransactionDataFail = (state, action) => {
  return updateObject(state, {
    errorTransactionDataMessage: action.errorTransactionChargeCard,
    errorTransactionData: true,
    loadingTransactionData: false
  })
}

const fetchTransactionDataStart = state => {
  return updateObject(state, {
    loadingTransactionData: true,
    errorTransactionData: false,
    errorTransactionDataMessage: "",
  })
}

//-------------Token paymi transaction--------------------------------

const fetchGetTokenPaymentSuccess = (state, action) => {
  return updateObject(state, {
    TokenPayment: action.tokenPayment.token,
    errorTokenPayment: false,
    errorTokenPaymentMessage: "",
    loadingTokenPayment: false
  })
}

const fetchGetTokenPaymentFail = (state, action) => {
  return updateObject(state, {
    errorTokenPaymentMessage: action.errorTokenPaymentgeCard,
    errorTokenPayment: true,
    loadingTokenPayment: false
  })
}

const fetchGetTokenPaymentStart = state => {
  return updateObject(state, {
    loadingTokenPayment: true,
    errorTokenPayment: false,
    errorTokenPaymentMessage: "",
  })
}


//-------------Payment paymi--------------------------------

const fetchPaymentPayMiSuccess = (state, action) => {
  return updateObject(state, {
    PaymentPayMi: action.tokenPayment,
    errorPaymentPayMi: false,
    errorPaymentPayMiMessage: "",
    loadingPaymentPayMi: false
  })
}

const fetchPaymentPayMiFail = (state, action) => {
  return updateObject(state, {
    errorPaymentPayMiMessage: action.errorGetTokenPayment.error_messages[0],
    errorPaymentPayMi: true,
    loadingPaymentPayMi: false,
    PaymentPayMi: false,
  })
}

const fetchPaymentPayMiStart = state => {
  return updateObject(state, {
    PaymentPayMi: false,
    loadingPaymentPayMi: true,
    errorPaymentPayMi: false,
    errorPaymentPayMiMessage: "",
  })
}


//-------------Last Summary-------------------------------

const fetchDownloadSummarySuccess = (state, action) => {
  return updateObject(state, {
    summary: action.summary,
    errorDownloadSummary: action.errorDownloadSummary.length > 0 ? true : false,
    errorDownloadSummaryMessage: action.errorDownloadSummary,
    loadingDownloadSummary: false
  })
}

const fetchDownloadSummaryFail = (state, action) => {
  return updateObject(state, {
    errorDownloadSummaryMessage: action.errorDownloadSummary.message,
    errorDownloadSummary: true,
    loadingDownloadSummary: false,
    summary: [],
  })
}

const fetchDownloadSummaryStart = state => {
  return updateObject(state, {
    summary: [],
    loadingDownloadSummary: true,
    errorDownloadSummary: false,
    errorDownloadSummaryMessage: [],
  })
}

const resetDownloadSummary = state => {
  return updateObject(state, {
    summary: [],
    loadingDownloadSummary: false,
    errorDownloadSummary: false,
    errorDownloadSummaryMessage: [],
  })
}





/**********************/



const fetchDebitAccountsSuccess = (state, action) => {
  return updateObject(state, {
    bankAccounts: action.bankAccounts,
    errorBankAccount: null,
    loadingfetchDebitBankAccounts: false,
    selectedBankAccount: action.selectedBankAccount
  })
}

const fetchDebitAccountsFail = (state, action) => {
  return updateObject(state, {
    errorFetchDebitAccounts: action.errorFetchDebitAccounts,
    loadingfetchDebitBankAccounts: false
  })
}

const fetchDebitAccountsStart = state => {
  return updateObject(state, {
    loadingfetchDebitBankAccounts: true,
    errorFetchDebitAccounts: null,

  })
}

const setBankAccount = (state, action) => {
  return updateObject(state, {
    selectedBankAccount: action.selectedBankAccount
  })
}

const setPageTransactionList = (state, action) => {
  return updateObject(state, {
    page: action.page
  })
}


const saveTransactionAuth = (state, action) => {
  return updateObject(state, {
    transactionAuth: action.transactionAuth
  })
}


//------------- Movements Last 24 Hours Report -------------------------------

const fetchMovementesLast24HoursReportSuccess = (state, action) => {
  return updateObject(state, {
    fetchMovementsLast24HoursReportSuccess: true,
    movementsLast24HoursReportData: action.movementsLast24HoursReportData,
    loadingFetchMovementsLast24HoursReport: false,
    errorDownloadReport: action.errorDownloadReport,
    errorDownloadReportMessage: action.errorDownloadReportMessage,
  })
}

const fetchMovementesLast24HoursReportStart = (state, action) => {
  return updateObject(state, {
    loadingFetchMovementsLast24HoursReport: true,
    errorDownloadReport: false,
    errorDownloadReportMessage: [],
  })
}

const fetchMovementesLast24HoursReportFail = (state, action) => {
  return updateObject(state, {
    fetchMovementsLast24HoursReportSuccess: false,
    loadingFetchMovementsLast24HoursReport: false,
    errorDownloadReportMessage: action.errorDownloadReport,
    errorDownloadReport: true,
  })
}

const fetchMovementesLast24HoursReportReset = (state) => {
  return updateObject(state, {
    fetchMovementsLast24HoursReportSuccess: false,
    movementsLast24HoursReportData: null,
    loadingFetchMovementsLast24HoursReport: false,
    errorDownloadReport: false,
    errorDownloadReportMessage: [],
  })
}

//------------- Movements Last 7 Days Report -------------------------------

const fetchMovementesLast7DaysReportSuccess = (state, action) => {
  return updateObject(state, {
    fetchMovementsLast7DaysReportSuccess: true,
    movementsLast7DaysReportData: action.movementsLast7DaysReportData,
    loadingFetchMovementsLast7DaysReport: false,
    errorDownloadReport: action.errorDownloadReport,
    errorDownloadReportMessage: action.errorDownloadReportMessage,
  })
}

const fetchMovementesLast7DaysReportStart = (state, action) => {
  return updateObject(state, {
    loadingFetchMovementsLast7DaysReport: true,
    errorDownloadReport: false,
    errorDownloadReportMessage: [],
  })
}

const fetchMovementesLast7DaysReportFail = (state, action) => {
  return updateObject(state, {
    fetchMovementsLast7DaysReportSuccess: false,
    loadingFetchMovementsLast7DaysReport: false,
    errorDownloadReportMessage: action.errorDownloadReport,
    errorDownloadReport: true,
  })
}

const fetchMovementesLast7DaysReportReset = (state) => {
  return updateObject(state, {
    fetchMovementsLast7DaysReportSuccess: false,
    movementsLast7DaysReportData: null,
    loadingFetchMovementsLast7DaysReport: false,
    errorDownloadReport: false,
    errorDownloadReportMessage: [],
  })
}



const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_TRANSACTION_LIST_SUCCESS:
      return fetchTransactionListSuccess(state, action)
    case actionTypes.FETCH_TRANSACTION_LIST_FAIL:
      return fetchTransactionListFail(state, action)
    case actionTypes.FETCH_TRANSACTION_LIST_START:
      return fetchTransactionListStart(state, action)

    case actionTypes.HAS_MORE_TRANSACTION_LIST_SUCCESS:
      return hasMoreTransactionListSuccess(state, action);
    
    case actionTypes.FETCH_TRANSACTION_QR_SUCCESS:
      return fetchTransactionQRSuccess(state, action)
    case actionTypes.FETCH_TRANSACTION_QR_FAIL:
      return fetchTransactionQRFail(state, action)
    case actionTypes.FETCH_TRANSACTION_QR_START:
      return fetchTransactionQRStart(state, action)
    case actionTypes.FETCH_REQUESTED_TRANSACTION_SUCCESS:
      return fetchRequestedTransactionSuccess(state, action)
    case actionTypes.FETCH_REQUESTED_TRANSACTION_FAIL:
      return fetchRequestedTransactionFail(state, action)
    case actionTypes.FETCH_REQUESTED_TRANSACTION_START:
      return fetchRequestedTransactionStart(state, action)
    case actionTypes.FETCH_GET_CODE_QR_IMAGE_SUCCESS:
      return fetchGetCodeQRImageSuccess(state, action)
    case actionTypes.FETCH_GET_CODE_QR_IMAGE_FAIL:
      return fetchGetCodeQRImageFail(state, action)
    case actionTypes.FETCH_GET_CODE_QR_IMAGE_START:
      return fetchGetCodeQRImageStart(state, action)
    case actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS:
      return fetchCreateTransactionRequestSuccess(state, action)
    case actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_FAIL:
      return fetchCreateTransactionRequestFail(state, action)
    case actionTypes.FETCH_CREATE_TRANSACTION_REQUEST_START:
      return fetchCreateTransactionRequestStart(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_SUCCESS:
      return fetchGetAccountSuccess(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_FAIL:
      return fetchGetAccountFail(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_START:
      return fetchGetAccountStart(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS:
      return fetchGetAccountByLoteSuccess(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_FAIL:
      return fetchGetAccountByLoteFail(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_BY_LOTE_START:
      return fetchGetAccountByLoteStart(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_DEBIT_SUCCESS:
      return fetchGetAccountDebitSuccess(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_DEBIT_FAIL:
      return fetchGetAccountDebitFail(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_DEBIT_START:
      return fetchGetAccountDebitStart(state, action)
    case actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS:
      return fetchGetNumberOfTransferPendingSuccess(state, action)
    case actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL:
      return fetchGetNumberOfTransferPendingFail(state, action)
    case actionTypes.FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START:
      return fetchGetNumberOfTransferPendingStart(state, action)
    case actionTypes.FETCH_GET_TRANSFER_PENDING_SUCCESS:
      return fetchGetTransferPendingSuccess(state, action)
    case actionTypes.FETCH_GET_TRANSFER_PENDING_FAIL:
      return fetchGetTransferPendingFail(state, action)
    case actionTypes.FETCH_GET_TRANSFER_PENDING_START:
      return fetchGetTransferPendingStart(state, action)
    case actionTypes.FETCH_APPROVE_TRANSACTION_SUCCESS:
      return fetchApproveTransactionSuccess(state, action)
    case actionTypes.FETCH_APPROVE_TRANSACTION_FAIL:
      return fetchApproveTransactionFail(state, action)
    case actionTypes.FETCH_APPROVE_TRANSACTION_START:
      return fetchApproveTransactionStart(state, action)
    case actionTypes.FETCH_DENIED_TRANSACTION_SUCCESS:
      return fetchDeniedTransactionSuccess(state, action)
    case actionTypes.FETCH_DENIED_TRANSACTION_FAIL:
      return fetchDeniedTransactionFail(state, action)
    case actionTypes.FETCH_DENIED_TRANSACTION_START:
      return fetchDeniedTransactionStart(state, action)
    case actionTypes.RESET_TRANSACTION_OPERATION_RESPONSE:
      return resetTransactionOperationResponse(state, action)
    case actionTypes.FETCH_RESEND_TOKEN_SUCCESS:
      return fetchReSendTokenSuccess(state, action)
    case actionTypes.FETCH_RESEND_TOKEN_FAIL:
      return fetchReSendTokenFail(state, action)
    case actionTypes.FETCH_RESEND_TOKEN_START:
      return fetchReSendTokenStart(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_QR_SUCCESS:
      return fetchGetAccountQRSuccess(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_QR_FAIL:
      return fetchGetAccountQRFail(state, action)
    case actionTypes.FETCH_GET_ACCOUNT_QR_START:
      return fetchGetAccountQRStart(state, action)
    case actionTypes.FETCH_BALANCE_SUCCESS:
      return fetchGetBalanceSuccess(state, action)
    case actionTypes.FETCH_BALANCE_FAIL:
      return fetchGetBalanceFail(state, action)
    case actionTypes.FETCH_BALANCE_START:
      return fetchGetBalanceStart(state, action)
    case actionTypes.FETCH_TRANSACTION_CHARGE_CARD_SUCCESS:
      return fecthTransactionChargeWithCardSuccess(state, action)
    case actionTypes.FETCH_TRANSACTION_CHARGE_CARD_FAIL:
      return fecthTransactionChargeWithCardFail(state, action)
    case actionTypes.FETCH_TRANSACTION_CHARGE_CARD_START:
      return fecthTransactionChargeWithCardStart(state, action)
    case actionTypes.FETCH_TRANSACTION_DATA_SUCCESS:
      return fetchTransactionDataSuccess(state, action)
    case actionTypes.FETCH_TRANSACTION_DATA_FAIL:
      return fetchTransactionDataFail(state, action)
    case actionTypes.FETCH_TRANSACTION_DATA_START:
      return fetchTransactionDataStart(state, action)
    case actionTypes.FETCH_DEBIT_ACCOUNTS_SUCCESS:
      return fetchDebitAccountsSuccess(state, action)
    case actionTypes.FETCH_DEBIT_ACCOUNTS_FAIL:
      return fetchDebitAccountsFail(state, action)
    case actionTypes.FETCH_DEBIT_ACCOUNTS_START:
      return fetchDebitAccountsStart(state, action)


    case actionTypes.FETCH_GET_TOKEN_PAYMENT_SUCCESS:
      return fetchGetTokenPaymentSuccess(state, action)
    case actionTypes.FETCH_GET_TOKEN_PAYMENT_FAIL:
      return fetchGetTokenPaymentFail(state, action)
    case actionTypes.FETCH_GET_TOKEN_PAYMENT_START:
      return fetchGetTokenPaymentStart(state, action)

    case actionTypes.FETCH_DOWNLOAD_SUMMARY_SUCCESS:
      return fetchDownloadSummarySuccess(state, action)
    case actionTypes.FETCH_DOWNLOAD_SUMMARY_FAIL:
      return fetchDownloadSummaryFail(state, action)
    case actionTypes.FETCH_DOWNLOAD_SUMMARY_START:
      return fetchDownloadSummaryStart(state, action) 
      case actionTypes.RESET_DOWNLOAD_SUMMARY:
        return resetDownloadSummary(state, action) 


    case actionTypes.FETCH_PAYMENT_PAYMI_SUCCESS:
      return fetchPaymentPayMiSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_PAYMI_FAIL:
      return fetchPaymentPayMiFail(state, action)
    case actionTypes.FETCH_PAYMENT_PAYMI_START:
      return fetchPaymentPayMiStart(state, action)

    case actionTypes.SET_BANK_ACCOUNT:
      return setBankAccount(state, action)

    case actionTypes.SET_PAGE:
      return setPageTransactionList(state, action)

    case actionTypes.SAVE_TRANSACTION_AUTH:
      return saveTransactionAuth(state, action)

    case actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_SUCCESS:
      return fetchMovementesLast24HoursReportSuccess(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_FAIL:
      return fetchMovementesLast24HoursReportFail(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_START:
      return fetchMovementesLast24HoursReportStart(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_RESET:
      return fetchMovementesLast24HoursReportReset(state)
  
    case actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_SUCCESS:
      return fetchMovementesLast7DaysReportSuccess(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_FAIL:
      return fetchMovementesLast7DaysReportFail(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_START:
      return fetchMovementesLast7DaysReportStart(state, action)
    case actionTypes.FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_RESET:
      return fetchMovementesLast7DaysReportReset(state)

    default:
      return state
  }
}

export default reducer