import React from 'react';
import Modal from '../../../../components/Modal';
import { connect } from 'react-redux';

import {
  StyledParagraph,
  ButtonWrapper,
  CloseButton
} from './styled'

function ErrorDetailModal(props) {
  console.log(props);
    const { errorDetail } = props;


  return (
    <Modal onClose={() => props.close()}  height={'min-height'} widht={'430px'}>
    <div style={{ textAlign: 'center' }}>
        <h2>Hubo un error al procesar el archivo</h2>
        <StyledParagraph>{errorDetail}</StyledParagraph>
        <ButtonWrapper>
          <CloseButton onClick={() => props.close()}> Ok</CloseButton>
        </ButtonWrapper>
    </div>
    </Modal>
  )
}

export default connect(
    null,
    null
)(ErrorDetailModal)