import React, { useState, useRef } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Modal from '../../../components/Modal'
import CrossIcon from '../../../assets/icons/close.png'
import Input from '../../../components/Input'
import Spinner from "../../../components/Spinner"
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select'
import { addMonths } from 'date-fns'
import FileImageInput from '../../../components/FileImageInput'
import FileInputGroup from '../../../components/FileInputGroup'
import Button from '../../../components/Button';
import PdfFileUpload from '../../../components/PdfFileUpload'
import NoFilePdf from '../../../assets/icons/pdfFile.svg'
import video from '../../../assets/icons/audio.svg'
import PopUp from '../PopUp'
import { createNewEvent, createNewArticle, preSubmitFiles, editEvent, editArticle } from '../../../store/actions/feed.actions'
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ButtonWrapper,
  ErrorLabel,
  ErrorImages,
  CrossImage,
} from './styled'
import { parseToISO } from '../../../utils/iso8601Parser'
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';

class NewActivity extends React.Component {
  state = {
    confirmNotification: false,
    content: 'content',
    isNewModal: this.props.isNewModal,

  }

  setRef = jodit => this.jodit = jodit;

  config = {
    readonly: false,
    overflow: "scroll",
    height: "150",
    buttons: "source,bold,italic,underline,strikethrough,center,justify,left,fontsize,paragraph,brush,link",
    toolbarAdaptive: false,
  }

  getInitialValues = () => this.props.isEdit && this.props.publicationToEdit ? ({
    title: this.props.publicationToEdit.title,
    startDate: new Date(this.props.publicationToEdit.publicationdate),
    endDate: this.props.publicationToEdit.publicationendshowingdate ? new Date(this.props.publicationToEdit.publicationendshowingdate) : new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
    type: !this.props.isEvent ? this.getTypeOptions()[0] : this.getTypeOptions()[1],
    boldparagraph: '',
    subtitle: this.props.publicationToEdit.subtitle,
    body: this.props.publicationToEdit.body ? this.props.publicationToEdit.body : "",
    externalurl: '',
    headerImage: this.props.publicationToEdit.headerimageurl ? this.props.publicationToEdit.headerimageurl : null,
    images: this.props.publicationToEdit.newsimagesurls ? this.props.publicationToEdit.newsimagesurls : null,
    video: this.props.publicationToEdit.video ? this.props.publicationToEdit.video : null,
    document: this.props.publicationToEdit.document ? this.props.publicationToEdit.document : null,
    neighbourhood: this.getSelectedNeighbourhoods(this.props.publicationToEdit.neighbourhoodlist),
    isEdit: true,
    isEvent: this.props.isEvent,
    editId: this.props.publicationToEdit.id
  }) : ({
    title: '',
    startDate: new Date(),
    endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 3)),
    type: !this.props.isEvent ? this.getTypeOptions()[0] : this.getTypeOptions()[1],
    boldparagraph: '',
    subtitle: '',
    body: '',
    externalurl: '',
    headerImage: null,
    images: null,
    video: this.props.video ? this.props.video : null,
    document: this.props.document ? this.props.document : null,
    neighbourhood: [],
    isEdit: false,
    isEvent: this.props.isEvent,
    editId: null
  })

  getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        title: Yup.string().required('Ingrese un título'),
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha'),
        type: Yup.object(),
        subtitle: Yup.string().required('Ingrese un subtítulo'),
        body: Yup.string().required('Ingrese un mensaje'),
        neighbourhood: Yup.array().of(
          Yup.object().shape({
            value: Yup.string(),
          })).min(1, 'Ingrese al menos un barrio').
          test('notNull', 'Ingrese al menos un barrio', items => {
            return items != null;
          }).nullable(),
        headerImage: Yup.object()
          .nullable()
          .when('isEdit', {
            is: false,
            then: Yup.object().required('Seleccione una portada'),
          }),
        video: Yup.object()
          .nullable(),
        document: Yup.object()
          .nullable(),

        images: Yup.array().required('Ingrese una foto').nullable(),
      })
    )
  limitTitleCharacters = (cad) => {
    if (cad.length > 20) {
      return cad.substring(0, 20);
    } else {
      return cad;
    }
  };

  onChange = (value, setFieldValue) => {
    this.setState({ value });
    setFieldValue("body", value.toString('html'));
  };
  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row>
        <InputWrapper width={38} margin={'0px 5px'}>
          <InputLabel>{'Título'}</InputLabel>
          <Input
            name={'title'}
            label={'Nombre'}
            placeholder={'Ingrese un título...'}
            value={values.title}
            error={errors.title}
            onChange={({ target: value }) => setFieldValue('title', this.limitTitleCharacters(value.value))}
            margin="normal"
            maxLength={20}
          />
          {errors.title && <ErrorLabel>{errors.title}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Inicio de publicación'}</InputLabel>
          <DatePickerStyled
            selected={values.startDate}
            startDate={values.startDate}
            endDate={values.endDate}
            selectsStart
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            onChange={startDate => setFieldValue('startDate', startDate)}
            minDate={new Date()}
            maxDate={addMonths(new Date(), Infinity)}
            placeholderText="Inicio de publicación"
          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Fin de publicación'}</InputLabel>
          <DatePickerStyled
            selected={values.endDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={endDate => setFieldValue('endDate', endDate)}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            placeholderText="Fin de publicación"
            {...(!this.isEventForm(values) && { disabled: true })}

          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Tipo de publicación'}</InputLabel>
          <Select
            name={'type'}
            value={values.type}
            isSearchable={false}
            onChange={values => setFieldValue('type', values)}
            error={errors.type}
            options={this.getTypeOptions()}
            placeholder="Seleccione un tipo..."
            isDisabled={values.isEdit}
          />
          {errors.type && <ErrorLabel>{errors.type}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={25} margin={'0px 5px'}>
          <InputLabel>{'Barrios asignados'}</InputLabel>
          <Select
            value={values.neighbourhood}
            onChange={value => {
              setFieldValue('neighbourhood', value)
            }}
            options={this.getNeighbourhoodOptions()}
            placeholder="Buscar..."
            error={errors.neighbourhood ? true : false}
            isMulti
          />
          {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={30} margin={'3px 5px'} center={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel>{'Subtítulo'}</InputLabel>
            <Input
              name={'subtitle'}
              label={'Subtitulo'}
              placeholder={'Ingrese un subtítulo...'}
              value={values.subtitle}
              error={errors.subtitle}
              onChange={({ target: value }) => setFieldValue('subtitle', value.value)}
              margin="normal"
            />
            {errors.subtitle && <ErrorLabel>{errors.subtitle}</ErrorLabel>}
          </div>
        </InputWrapper>
        <InputWrapper width={25} margin={'15px 5px'} center={true} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel>{'PDF'}</InputLabel>
            <PdfFileUpload canBeFileDeleted={true} height={"60px"}
              setHasErrorExtension = {(bool) => {return bool}}
              onChange={(document, isDelete) => {
                setFieldValue('document', document);
                if (isDelete && this.props.publicationToEdit?.document) {
                  this.props.publicationToEdit.document = document;
                }
              }} acceptTypes={"application/pdf"} value={values.document} isEdit={this.props?.isEdit != undefined ? this.props?.isEdit : false} pdf={true} nofile={NoFilePdf} />
          </div>
        </InputWrapper>
        <InputWrapper width={25} margin={'15px 5px'} center={true}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel>{'Video'}</InputLabel>
            <PdfFileUpload canBeFileDeleted={true} height={"60px"}
              onChange={(video, isDelete) => {
                setFieldValue('video', video);
                if (isDelete && this.props.publicationToEdit?.video) {
                  this.props.publicationToEdit.video = video;
                }
              }} value={values.video} isEdit={this.props?.isEdit != undefined ? this.props?.isEdit : false} acceptTypes={"video/mp4,video/x-m4v,video/*"} pdf={true} nofile={video} />
          </div>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputLabel>{'Mensaje'}</InputLabel>
          <JoditEditor
            editorRef={this.setRef}
            value={values.body}
            style={{ maxHeight: "200px" }}
            config={this.config}
            onChange={(e) => this.onChange(e, setFieldValue)}
          />
          {errors.body && <ErrorLabel>{errors.body}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={30}>
          <InputLabel>{'Portada'}</InputLabel>
          <FileImageInput onChange={file => setFieldValue('headerImage', file)} value={values.headerImage} marginTop="0px" />
          {errors.headerImage && <ErrorImages>{errors.headerImage}</ErrorImages>}
        </InputWrapper>
        <InputWrapper>
          <FileInputGroup
            value={values.images}
            imagesAmount={4}
            label={'Adjuntar imagenes'}
            error={<ErrorImages>{errors.images}</ErrorImages>}
            isEdit={this.props.isEdit}
            onChange={images => setFieldValue('images', images)}
            canBeImageDeleted={true}
          />
        </InputWrapper>
      </Row>
      <Row>
      </Row>
      <Row>
        <ErrorLabel>{!this.state.isNewModal && this.props.errorNew ? this.props.errorNew : null}</ErrorLabel>

        <ButtonWrapper>
          {this.props.loading ? (<Spinner />) : (
            <Button medium type={'submit'}>Publicar</Button>)}
        </ButtonWrapper>

      </Row>
    </FormContainer>
  )

  isEventForm = values => {
    if (values.type) {
      if (values.type.value === 'Event') {
        return true
      } else {
        return false
      }
    }
  }

  isSeguridadForm = values => {
    if (values.type) {
      if (values.type.value === 'seguridad') {
        return true
      } else {
        return false
      }
    }
  }

  getTypeOptions = () => [
    {
      label: 'Articulo',
      value: 'Article'
    },
    {
      label: 'Evento',
      value: 'Event'
    },
    {
      label: 'Seguridad',
      value: 'seguridad'
    },
  ]

  preHandleSubmit = async data => {
    if (this.isSeguridadForm(data)) {
      this.setState({ modalConfirmNotification: true, data: data })
    } else {
      this.handleSubmit(data)
    }
  }

  toggleModal() {
    this.setState({ modalConfirmNotification: !this.state.modalConfirmNotification })
  }

  handleSubmit = async data => {
    let formattedDataToEdit = {
      title: data.title,
      subtitle: data.subtitle,
      body: data.body,
      sendsnotification: this.isSeguridadForm(data),
      externalurl: data.externalurl,
      publicationdate: parseToISO(data.startDate),
      publicationstartshowingdate: parseToISO(data.startDate),
      publicationendshowingdate: parseToISO(data.endDate),
      neighbourhoodList: data.neighbourhood.map(n => n.value),
      headerimageurl: this.props.publicationToEdit?.headerimageurl ? this.props.publicationToEdit.headerimageurl : {},
      images: this.props.publicationToEdit?.newsimagesurls ? this.props.publicationToEdit.newsimagesurls : {},
      video: this.props.publicationToEdit?.video ? this.props.publicationToEdit.video : {},
      document: this.props.publicationToEdit?.document ? this.props.publicationToEdit.document : {},
    }
    let headerImageFormat = data.headerImage?.ImageBase64 ? {
      type: "HEADERIMAGE",
      extension: data.headerImage.extension,
      imagebase64: data.headerImage.ImageBase64,
    } : (false);

    let preArrayImagesFormat = data.images ? data.images.map((image, index) => {
      if (typeof image === "object" && image != null && image.ImageBase64 != null) {
        return ({
          type: "IMAGE",
          imagebase64: image.base64Value ? image.base64Value : null,
          extension: image.extension
        })
      } else if (typeof image === "string") {
        return {
          i: index,
          image: image
        };
      }
    }
    ) : (false);

    let videoFormat = data.video?.ImageBase64 ? {
      type: "VIDEO",
      extension: data.video.extension,
      imagebase64: data.video.ImageBase64,
    } : (false);

    let documentFormat = data.document?.ImageBase64 ? {
      type: "DOCUMENT",
      extension: data.document.extension,
      imagebase64: data.document.ImageBase64,
    } : (false);

    let finalFiles = [];
    if (headerImageFormat) {
      finalFiles.unshift(headerImageFormat)
    };
    if (videoFormat) {
      finalFiles.unshift(videoFormat)
    };
    if (documentFormat) {
      finalFiles.unshift(documentFormat)
    };
    if (preArrayImagesFormat != []) {
      preArrayImagesFormat.map((images) => finalFiles.unshift(images));
    };
    let finalFilesFiltred = finalFiles.filter(item => item?.extension != undefined || typeof item.image != undefined)
    let formattedPreSubmit = null;
    if (this.props.isEdit) {
      formattedPreSubmit = {
        articleid: data.editId,
        files: finalFilesFiltred
      }
    } else {
      formattedPreSubmit = {
        files: finalFilesFiltred
      }
    }

    let response = await this.props.preSubmitFiles(formattedPreSubmit, formattedDataToEdit, this.props.isEdit, data.type.value)
    this.setState({ isNewModal: false })
    if (this.props.isEdit) {
      if (data.type.value === 'Event') {
        this.editEvent(formattedDataToEdit, data.editId)
      } else {
        this.editArticle(formattedDataToEdit, data.editId)
      }
    } else {
      if (data.type.value === 'Event') {
        this.submitEvent(formattedDataToEdit, data)
      } else {
        this.submitArticle(formattedDataToEdit, data)
      }
    }
  }


  submitEvent = async (formattedData, data) => {
    if (!this.props.submitErrorEvent) {
      this.props.onClose(true)
    }
  }

  submitArticle = async (formattedData, data) => {
    if (!this.props.submitErrorArticle) this.props.onClose(true)
  }

  editEvent = async (formattedData, id) => {
    if (!this.props.submitErrorEvent) this.props.onClose(true)
  }

  editArticle = async (formattedData, id) => {
    if (!this.props.submitErrorArticle) this.props.onClose(true)
  }

  render() {
    if (this.state.modalConfirmNotification) {
      return (
        <PopUp
          onHandleSubmit={() => this.handleSubmit(this.state.data)}
          onClose={() => this.toggleModal()}
          neighbourhoods={this.state.data.neighbourhood}
        />
      )
    } else {
      return (
        <Modal scroll={false} width={"80%"} padding={"2%"} onClose={this.props.onClose}>
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={false}
            validationSchema={this.getSignUpSchema()}
            onSubmit={this.preHandleSubmit}
            render={e => this.getFormContent(e)}
          />
        </Modal>
      )
    }
  }

  getNeighbourhoodOptions = () =>
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }));

  getSelectedNeighbourhoods = (selectedGuids) => {
    return this.props.neighbourhoods.filter(
      element => selectedGuids.includes(element.guid)
    ).map(n => ({ label: n.name, value: n.guid }));
  }

}

const mapStateToProps = ({ userInfo: { neighbourhoods, selectedNeighbourhood }, feed }) => ({
  isSubmittingEvent: feed.loadingNewEvent,
  submitErrorEvent: feed.errorNewEvent,
  isSubmittingArticle: feed.loadingNewArticle,
  submitErrorArticle: feed.errorNewArticle,
  loading: feed.loadingNewEvent || feed.loadingNewArticle,
  errorNew: feed.errorNewEventMessage || feed.errorNewArticleMessage,
  neighbourhoods,
  selectedNeighbourhood,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createNewEvent, createNewArticle, preSubmitFiles, editEvent, editArticle }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActivity)
