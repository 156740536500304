import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'
import mapAlerts from '../../selectors/mapAlerts'


const initialState = {
    selectedRows: [],
    selectedEmergencyPhoneRows: [],
    //FETCH ALERT MOTIVES
    alertMotives: [],
    loadingFetchAlertMotives: false,
    errorFetchAlertMotives: null,
    fetchAlertMotivesSuccess: false,
    //ACTIVATE ALERT MOTIVE

    loadingActivateAlertMotive: false,
    errorActivateAlertMotive: null,
    activateAlertMotiveSuccess: false,

    //FETCH NOTIFICATION TYPES
    notificationTypes: [],
    loadingFetchNotificationTypes: false,
    errorFetchNotificationTypes: null,
    fetchANotificationTypes: false,
    //FETCH EMERGENCY PHONES
    emergencyPhonesTotal: 100,
    emergencyPhones: [],
    loadingFetchEmergencyPhones: false,
    errorFetchEmergencyPhones: null,
    fetchEmergencyPhonesSuccess: false,
    //FETCH EMERGENCY PHONE
    loadingFetchEmergencyPhone: false,
    fetchEmergencyPhoneStart: false,
    fetchEmergencyPhoneSuccess: false,
    errorFetchEmergencyPhone: null,
    detailEmergencyPhone: [],
    //CREATE EMERGENCY PHONE

    loadingCreateEmergencyPhone: false,
    errorCreateEmergencyPhone: null,
    createEmergencyPhoneSuccess: false,

    //UPDATE EMERGENCY PHONE

    loadingUpdateEmergencyPhone: false,
    errorUpdateEmergencyPhone: null,
    updateEmergencyPhoneSuccess: false,

    //DELETE EMERGENCY PHONE

    loadingDeleteEmergencyPhone: false,
    deleteEmergencyPhoneStart: false,
    deleteEmergencyPhoneSuccess: false,
    deleteEmergencyPhoneFail: false,

    //DELETE EMERGENCY PHONES

    loadingDeleteEmergencyPhones: false,
    deleteEmergencyPhonesStart: false,
    deleteEmergencyPhonesSuccess: false,
    deleteEmergencyPhonesFail: false,

    //FETCH MESSAGES

    messages: [],
    messagesPage: 0,
    messagesPageSize: 10,
    messagesTotal: 100,
    messagesOrderColumn: "alert_motive",
    messagesOrderDirection: "desc",
    loadingFetchMessages: false,
    errorFetchMessages: null,
    fetchMessagesSuccess: false,

    //FETCH MESSAGE

    loadingFetchMessage: false,
    fetchMessageStart: false,
    fetchMessageSuccess: false,
    errorFetchMessage: null,
    detailMessage: [],

    //CREATE MESSAGE

    loadingCreateMessage: false,
    errorCreateMessage: null,
    createMessageSuccess: false,

    //UPDATE MESSAGE

    loadingUpdateMessage: false,
    errorUpdateMessage: null,
    updateMessageSuccess: false,

    //DELETE MESSAGE

    loadingDeleteMessage: false,
    deleteMessageStart: false,
    deleteMessageSuccess: false,
    deleteMessageFail: false,

    //FETCH ALERTS

    receivedAlerts: [],
    fetchAlertsSuccess: false,
    fetchAlertsStart: false,
    fetchAlertsFail: false,
    loadingAlerts: false,
    orderField: 'date',
    orderDirection: 'desc',
    pageNumber: 0,
    pageSize: 10,

    //FETCH ALERT

    detailAlert: [],
    loadingAlert: false,
    fetchAlertSuccess: false,
    errorAlert: "",
    //RESOLVE ALERTS

    loadingResolveAlerts: false,
    resolveAlertErrorMessage: ""

}

const selectAlertRows = (state, action) => {
    return updateObject(state, {
        selectedRows: action.ids
    })
}

const selectEmergencyPhoneRows = (state, action) => {
    return updateObject(state, {
        selectedEmergencyPhoneRows: action.ids
    })
}

////////////////////// FETCH RECEIVED ALERTS//////////////////////
const fetchAlertsSuccess = (state, action) => {
    return updateObject(state, {
        receivedAlerts: action.receivedAlerts,
        fetchAlertsSuccess: true,
        loadingAlerts: false
    })
}

const fetchAlertsFail = (state, action) => {
    return updateObject(state, {
        fetchAlertsLoading: false,
        loadingAlerts: false,
        fetchAlertsFail: action.fetchAlertFail,
        receivedAlerts: []
    })
}

const fetchAlertsStart = (state) => {
    return updateObject(state, {
        fetchAlertsStart: true,
        fetchAlertsFail: false,
        loadingAlerts: true
    })
}

const updateGridAlert = (state, action) => {
    return updateObject(state, {
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
        orderField: action.orderColumn,
        orderDirection: action.orderDirection,

    })
}

////////////////////// FETCH ALERTS//////////////////////
const fetchAlertSuccess = (state, action) => {
    return updateObject(state, {
        detailAlert: action.detailAlert,
        loadingAlert: false,
        fetchAlertSuccess: true,
        errorAlert: ""
    })
}

const fetchAlertFail = (state, action) => {
    return updateObject(state, {
        detailAlert: [],
        loadingAlert: false,
        fetchAlertSuccess: false,
        errorAlert: action.fetchAlertFail
    })
}

const fetchAlertStart = (state) => {
    return updateObject(state, {
        detailAlert: [],
        loadingAlert: true,
        fetchAlertSuccess: false,
        errorAlert: ""
    })
}


////////////////////// RESOLVE ALERTS//////////////////////
const resolveAlertSuccess = (state, action) => {
    return updateObject(state, {
        loadingResolveAlerts: false,
        resolveAlertErrorMessage: ""
    })
}

const resolveAlertFail = (state, action) => {
    return updateObject(state, {
        loadingResolveAlerts: false,
        resolveAlertErrorMessage: action.resolveAlertErrorMessage
    })
}

const resolveAlertstart = (state) => {
    return updateObject(state, {
        loadingResolveAlerts: true,
        resolveAlertErrorMessage: ""
    })
}

////////////////////// FETCH EMERGENCY PHONES//////////////////////

const fetchEmergencyPhonesSuccess = (state, action) => {
    return updateObject(state, {
        emergencyPhones: action.emergencyPhones,
        // totalRows:action.emergencyPhones.lenght,
        loadingFetchEmergencyPhones: false
    })
}

const fetchEmergencyPhonesFail = (state, action) => {
    return updateObject(state, {
        loadingFetchEmergencyPhones: false,
        errorFetchEmergencyPhones: action.errorFetchEmergencyPhones,
        emergencyPhones: [],
    })
}

const fetchEmergencyPhonesStart = (state) => {
    return updateObject(state, {
        loadingFetchEmergencyPhones: true,
        errorFetchEmergencyPhones: null
    })
}

////////////////////// FETCH EMERGENCY PHONE//////////////////////

const fetchEmergencyPhoneSuccess = (state, action) => {
    return updateObject(state, {
        detailEmergencyPhone: action.detailEmergencyPhone,
        loadingFetchEmergencyPhone: false
    })
}

const fetchEmergencyPhoneFail = (state, action) => {
    return updateObject(state, {
        loadingFetchEmergencyPhone: false,
        errorFetchEmergencyPhone: action.errorFetchEmergencyPhone
    })
}

const fetchEmergencyPhoneStart = (state) => {
    return updateObject(state, {
        loadingFetchEmergencyPhone: true,
        errorFetchEmergencyPhone: []
    })
}

const fetchEmergencyPhoneReset = (state) => {
    return updateObject(state, {
        loadingFetchEmergencyPhone: false,
        errorFetchEmergencyPhone: null,
        detailEmergencyPhone: []
    })
}

////////////////////// CREATE EMERGENCYPHONE //////////////////////
const createEmergencyPhoneSuccess = (state, action) => {
    return updateObject(state, {
        emergencyPhone: action.emergencyPhone,
        loadingCreateEmergencyPhone: false,
        createEmergencyPhoneSuccess: true,
        errorCreateEmergencyPhone: null,
    })
}

const createEmergencyPhoneFail = (state, action) => {
    return updateObject(state, {
        loadingCreateEmergencyPhone: false,
        errorCreateEmergencyPhone: action.errorCreateEmergencyPhone,
        createEmergencyPhoneSuccess: false
    })
}

const createEmergencyPhoneStart = (state) => {
    return updateObject(state, {
        loadingCreateEmergencyPhone: true,
        errorCreateEmergencyPhone: null,
    })
}

const createEmergencyPhoneReset = state => {
    return updateObject(state, {
        loadingCreateEmergencyPhone: false,
        errorCreateEmergencyPhone: null,
        createEmergencyPhoneSuccess: false
    })
}

////////////////////// UPDATE EMERGENCY PHONE //////////////////////
const updateEmergencyPhoneSuccess = (state, action) => {
    return updateObject(state, {
        loadingUpdateEmergencyPhone: false,
        updateEmergencyPhoneSuccess: true
    })
}

const updateEmergencyPhoneFail = (state, action) => {
    return updateObject(state, {
        loadingUpdateEmergencyPhone: false,
        errorUpdateEmergencyPhone: action.errorUpdateEmergencyPhone,
        updateEmergencyPhoneSuccess: false
    })
}

const updateEmergencyPhoneStart = (state) => {
    return updateObject(state, {
        loadingUpdateEmergencyPhone: true,
        errorUpdateEmergencyPhone: null,
        updateEmergencyPhoneSuccess: false
    })
}

const updateEmergencyPhoneReset = state => {
    return updateObject(state, {
        loadingUpdateEmergencyPhone: false,
        errorUpdateEmergencyPhone: null,
        updateEmergencyPhoneSuccess: false
    })
}



//////////////////DELETE EMERGENCY PHONE//////////////////


const deleteEmergencyPhoneStart = state => {
    return updateObject(state, {
        loadingDeleteEmergencyPhone: true,
        deleteEmergencyPhoneSuccess: false,
        deleteEmergencyPhoneFail: false,
    })
}

const deleteEmergencyPhoneSuccess = (state, action) =>
    updateObject(state, {
        loadingDeleteEmergencyPhone: false,
        deleteEmergencyPhoneSuccess: true,
        deleteEmergencyPhoneFail: false,
    })

const deleteEmergencyPhoneFail = (state, action) => {
    return updateObject(state, {
        loadingDeleteEmergencyPhone: false,
        deleteEmergencyPhoneSuccess: false,
        deleteEmergencyPhoneFail: true
    })
}


const deleteEmergencyPhoneReset = (state, action) =>
    updateObject(state, {
        loadingDeleteEmergencyPhone: false,
        deleteEmergencyPhoneSuccess: false,
        deleteEmergencyPhoneFail: false,
    })


//////////////////DELETE EMERGENCY PHONES//////////////////


const deleteEmergencyPhonesStart = state => {
    return updateObject(state, {
        loadingDeleteEmergencyPhones: true,
        deleteEmergencyPhonesSuccess: false,
        deleteEmergencyPhonesFail: false,
    })
}

const deleteEmergencyPhonesSuccess = (state, action) =>
    updateObject(state, {
        loadingDeleteEmergencyPhones: false,
        deleteEmergencyPhonesSuccess: true,
        deleteEmergencyPhonesFail: false,
    })

const deleteEmergencyPhonesFail = (state, action) => {
    return updateObject(state, {
        loadingDeleteEmergencyPhones: false,
        deleteEmergencyPhonesSuccess: false,
        deleteEmergencyPhonesFail: true
    })
}


const deleteEmergencyPhonesReset = (state, action) =>
    updateObject(state, {
        loadingDeleteEmergencyPhones: false,
        deleteEmergencyPhonesSuccess: false,
        deleteEmergencyPhonesFail: false,
    })



////////////////////// FETCH MESSAGES//////////////////////

const fetchMessagesSuccess = (state, action) => {
    return updateObject(state, {
        messages: action.messages,
        messagesTotal: action.messagesTotal,
        loadingFetchMessages: false
    })
}

const fetchMessagesFail = (state, action) => {
    return updateObject(state, {
        loadingFetchMessages: false,
        errorFetchMessages: action.errorFetchMessages,
        messages: [],
        messagesTotal: 0,
    })
}

const fetchMessagesStart = (state) => {
    return updateObject(state, {
        loadingFetchMessages: true,
        errorFetchMessages: null
    })
}

////////////////////// FETCH MESSAGE//////////////////////

const fetchMessageSuccess = (state, action) => {
    return updateObject(state, {
        detailMessage: action.detailMessage,
        loadingFetchMessage: false
    })
}

const fetchMessageFail = (state, action) => {
    return updateObject(state, {
        loadingFetchMessage: false,
        errorFetchMessage: action.errorFetchMessage,
        detailMessage: []
    })
}

const fetchMessageStart = (state) => {
    return updateObject(state, {
        loadingFetchMessage: true,
        errorFetchMessage: null
    })
}

const fetchMessageReset = (state) => {
    return updateObject(state, {
        loadingFetchMessage: false,
        errorFetchMessage: null,
        detailMessage: []
    })
}

////////////////////// CREATE MESSAGE //////////////////////
const createMessageSuccess = (state, action) => {
    return updateObject(state, {
        loadingCreateMessage: false,
        createMessageSuccess: true,
        errorCreateMessage: null,
    })
}

const createMessageFail = (state, action) => {
    return updateObject(state, {
        loadingCreateMessage: false,
        errorCreateMessage: action.errorCreateMessage,
        createMessageSuccess: false
    })
}

const createMessageStart = (state) => {
    return updateObject(state, {
        loadingCreateMessage: true,
        errorCreateMessage: null,
    })
}

const createMessageReset = state => {
    return updateObject(state, {
        loadingCreateMessage: false,
        errorCreateMessage: null,
        createMessageSuccess: false
    })
}

////////////////////// UPDATE MESSAGE //////////////////////
const updateMessageSuccess = (state, action) => {
    return updateObject(state, {
        loadingUpdateMessage: false,
        updateMessageSuccess: true,
        errorUpdateMessage: null,
    })
}

const updateMessageFail = (state, action) => {
    return updateObject(state, {
        loadingUpdateMessage: false,
        errorUpdateMessage: action.errorUpdateMessage,
        updateMessageSuccess: false
    })
}

const updateMessageStart = (state) => {
    return updateObject(state, {
        loadingUpdateMessage: true,
        errorUpdateMessage: null,
        updateMessageSuccess: false
    })
}

const updateMessageReset = state => {
    return updateObject(state, {
        loadingUpdateMessage: false,
        errorUpdateMessage: null,
        updateMessageSuccess: false
    })
}

//////////////////DELETE MESSAGE//////////////////

const deleteMessageStart = state => {
    return updateObject(state, {
        loadingDeleteMessage: true,
        deleteMessageSuccess: false,
        deleteMessageFail: false,
    })
}

const deleteMessageSuccess = (state) => {
    return updateObject(state, {
        loadingDeleteMessage: false,
        deleteMessageSuccess: true,
        deleteMessageFail: false,
    })
}
const deleteMessageFail = (state) => {
    return updateObject(state, {
        loadingDeleteMessage: false,
        deleteMessageSuccess: false,
        deleteMessageFail: true
    })
}

const deleteMessageReset = (state) => {
    return updateObject(state, {
        loadingDeleteMessage: false,
        deleteMessageSuccess: false,
        deleteMessageFail: false,
    })
}


////////////////////// FETCH ALERT MOTIVES//////////////////////
const fetchAlertMotivesSuccess = (state, action) => {

    return updateObject(state, {
        loadingFetchAlertMotives: false,
        alertMotives: action.alertMotives,
        fetchAlertMotivesSuccess: true
    })
}

const fetchAlertMotivesFail = (state, action) => {
    return updateObject(state, {
        loadingFetchAlertMotives: false,
        fetchAlertMotivesSuccess: false,
        errorFetchAlertMotives: action.fetchAlertMotivesFail
    })
}

const fetchAlertMotivesStart = (state) => {
    return updateObject(state, {
        loadingFetchAlertMotives: true,
        errorFetchAlertMotives: null,
        fetchAlertMotivesSuccess: false
    })
}

const fetchAlertMotivesReset = (state) => {
    return updateObject(state, {
        loadingFetchAlertMotives: false,
        errorFetchAlertMotives: null,
        fetchAlertMotivesSuccess: false,
        alertMotives: []
    })
}

////////////////////// UPDATE ALERT MOTIVE //////////////////////
const activateAlertMotiveSuccess = (state, action) => {
    return updateObject(state, {
        loadingActivateAlertMotive: false,
        activateAlertMotiveSuccess: true,
        errorActivateAlertMotive: false,
    })
}

const activateAlertMotiveFail = (state, action) => {
    return updateObject(state, {
        loadingActivateAlertMotive: false,
        errorActivateAlertMotive: true,
        activateAlertMotiveSuccess: false
    })
}

const activateAlertMotiveStart = (state) => {
    return updateObject(state, {
        loadingActivateAlertMotive: true,
        errorActivateAlertMotive: false,
        activateAlertMotiveSuccess: false
    })
}

const ActivateAlertMotiveReset = state => {
    return updateObject(state, {
        loadingUpdateAlertMotive: false,
        errorUpdateAlertMotive: false,
        updateAlertMotiveSuccess: false
    })
}

////////////////////// FETCH NOTIFICATION TYPES//////////////////////
const fetchNotificationTypesSuccess = (state, action) => {

    return updateObject(state, {

        notificationTypes: action.notificationTypes,
        fetchNotificationTypesSuccess: true
    })
}

const fetchNotificationTypesFail = (state, action) => {
    return updateObject(state, {
        loadingFetchNotificationTypes: false,
        errorFetchNotificationTypes: action.error
    })
}

const fetchNotificationTypesStart = (state) => {
    return updateObject(state, {
        fetchNotificationTypesStart: true,
        fetchNotificationTypesFail: false
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        ////////////////////// FETCH ALERTS  //////////////////////

        case actionTypes.FETCH_ALERT_RECEIVED_SUCCESS:
            return fetchAlertsSuccess(state, action)
        case actionTypes.FETCH_ALERT_RECEIVED_FAIL:
            return fetchAlertsFail(state, action)
        case actionTypes.FETCH_ALERT_RECEIVED_START:
            return fetchAlertsStart(state, action)

        ////////////////////// FETCH ALERT  //////////////////////

        case actionTypes.FETCH_ALERT_SUCCESS:
            return fetchAlertSuccess(state, action)
        case actionTypes.FETCH_ALERT_FAIL:
            return fetchAlertFail(state, action)
        case actionTypes.FETCH_ALERT_START:
            return fetchAlertStart(state, action)

        ////////////////////// RESOLVE ALERTS  //////////////////////

        case actionTypes.RESOLVE_ALERT_SUCCESS:
            return resolveAlertSuccess(state, action)
        case actionTypes.RESOLVE_ALERT_FAIL:
            return resolveAlertFail(state, action)
        case actionTypes.RESOLVE_ALERT_START:
            return resolveAlertstart(state, action)

        ////////////////////// FETCH EMERGENCY PHONES //////////////////////
        case actionTypes.FETCH_EMERGENCY_PHONES_SUCCESS:
            return fetchEmergencyPhonesSuccess(state, action)
        case actionTypes.FETCH_EMERGENCY_PHONES_FAIL:
            return fetchEmergencyPhonesFail(state, action)
        case actionTypes.FETCH_EMERGENCY_PHONES_START:
            return fetchEmergencyPhonesStart(state, action)
        ////////////////////// FETCH EMERGENCY PHONE //////////////////////
        case actionTypes.FETCH_EMERGENCY_PHONE_SUCCESS:
            return fetchEmergencyPhoneSuccess(state, action)
        case actionTypes.FETCH_EMERGENCY_PHONE_FAIL:
            return fetchEmergencyPhoneFail(state, action)
        case actionTypes.FETCH_EMERGENCY_PHONE_START:
            return fetchEmergencyPhoneStart(state, action)
        case actionTypes.FETCH_EMERGENCY_PHONE_RESET:
            return fetchEmergencyPhoneReset(state, action)
        ////////////////////// CREATE EMERGENCY PHONE //////////////////////
        case actionTypes.CREATE_EMERGENCY_PHONE_SUCCESS:
            return createEmergencyPhoneSuccess(state, action)
        case actionTypes.CREATE_EMERGENCY_PHONE_FAIL:
            return createEmergencyPhoneFail(state, action)
        case actionTypes.CREATE_EMERGENCY_PHONE_START:
            return createEmergencyPhoneStart(state, action)
        case actionTypes.CREATE_EMERGENCY_PHONE_RESET:
            return createEmergencyPhoneReset(state, action)
        ////////////////////// UPDATE EMERGENCY PHONE //////////////////////
        case actionTypes.UPDATE_EMERGENCY_PHONE_SUCCESS:
            return updateEmergencyPhoneSuccess(state, action)
        case actionTypes.UPDATE_EMERGENCY_PHONE_FAIL:
            return updateEmergencyPhoneFail(state, action)
        case actionTypes.UPDATE_EMERGENCY_PHONE_START:
            return updateEmergencyPhoneStart(state, action)
        case actionTypes.UPDATE_EMERGENCY_PHONE_RESET:
            return updateEmergencyPhoneReset(state, action)
        ////////////////////// DELETE EMERGENCY PHONE //////////////////////

        case actionTypes.DELETE_EMERGENCY_PHONE_SUCCESS:
            return deleteEmergencyPhoneSuccess(state, action)

        case actionTypes.DELETE_EMERGENCY_PHONE_FAIL:
            return deleteEmergencyPhoneFail(state, action)

        case actionTypes.DELETE_EMERGENCY_PHONE_START:
            return deleteEmergencyPhoneStart(state, action)

        case actionTypes.DELETE_EMERGENCY_PHONE_RESET:
            return deleteEmergencyPhoneReset(state, action)

        ////////////////////// DELETE EMERGENCY PHONES //////////////////////
        case actionTypes.DELETE_EMERGENCY_PHONES_SUCCESS:
            return deleteEmergencyPhonesSuccess(state, action)
        case actionTypes.DELETE_EMERGENCY_PHONES_FAIL:
            return deleteEmergencyPhonesFail(state, action)
        case actionTypes.DELETE_EMERGENCY_PHONES_START:
            return deleteEmergencyPhonesStart(state, action)
        case actionTypes.DELETE_EMERGENCY_PHONES_RESET:
            return deleteEmergencyPhonesReset(state, action)
        ////////////////////// FETCH MESSAGES //////////////////////
        case actionTypes.FETCH_MESSAGES_SUCCESS:
            return fetchMessagesSuccess(state, action)
        case actionTypes.FETCH_MESSAGES_FAIL:
            return fetchMessagesFail(state, action)
        case actionTypes.FETCH_MESSAGES_START:
            return fetchMessagesStart(state, action)
        ////////////////////// FETCH MESSAGE //////////////////////

        case actionTypes.FETCH_MESSAGE_SUCCESS:
            return fetchMessageSuccess(state, action)

        case actionTypes.FETCH_MESSAGE_FAIL:
            return fetchMessageFail(state, action)

        case actionTypes.FETCH_MESSAGE_START:
            return fetchMessageStart(state, action)

        case actionTypes.FETCH_MESSAGE_RESET:
            return fetchMessageReset(state, action)

        ////////////////////// CREATE MESSAGE //////////////////////

        case actionTypes.CREATE_MESSAGE_SUCCESS:
            return createMessageSuccess(state, action)

        case actionTypes.CREATE_MESSAGE_FAIL:
            return createMessageFail(state, action)

        case actionTypes.CREATE_MESSAGE_START:
            return createMessageStart(state, action)

        case actionTypes.CREATE_MESSAGE_RESET:
            return createMessageReset(state, action)

        ////////////////////// UPDATE MESSAGE //////////////////////
        case actionTypes.UPDATE_MESSAGE_SUCCESS:
            return updateMessageSuccess(state, action)
        case actionTypes.UPDATE_MESSAGE_FAIL:
            return updateMessageFail(state, action)
        case actionTypes.UPDATE_MESSAGE_START:
            return updateMessageStart(state, action)
        case actionTypes.UPDATE_MESSAGE_RESET:
            return updateMessageReset(state, action)
        ////////////////////// DELETE MESSAGE //////////////////////
        case actionTypes.DELETE_MESSAGE_SUCCESS:
            return deleteMessageSuccess(state, action)
        case actionTypes.DELETE_MESSAGE_FAIL:
            return deleteMessageFail(state, action)
        case actionTypes.DELETE_MESSAGE_START:
            return deleteMessageStart(state, action)
        case actionTypes.DELETE_MESSAGE_RESET:
            return deleteMessageReset(state, action)
        ////////////////////// FETCH ALERT MOTIVES //////////////////////
        case actionTypes.FETCH_ALERT_MOTIVES_SUCCESS:
            return fetchAlertMotivesSuccess(state, action)
        case actionTypes.FETCH_ALERT_MOTIVES_FAIL:
            return fetchAlertMotivesFail(state, action)
        case actionTypes.FETCH_ALERT_MOTIVES_START:
            return fetchAlertMotivesStart(state, action)
        case actionTypes.FETCH_ALERT_MOTIVES_RESET:
            return fetchAlertMotivesReset(state, action)
        ////////////////////// ACTIVATE ALERT MOTIVE //////////////////////
        case actionTypes.ACTIVATE_ALERT_MOTIVE_SUCCESS:
            return activateAlertMotiveSuccess(state, action)
        case actionTypes.ACTIVATE_ALERT_MOTIVE_FAIL:
            return activateAlertMotiveFail(state, action)
        case actionTypes.ACTIVATE_ALERT_MOTIVE_START:
            return activateAlertMotiveStart(state, action)
        case actionTypes.ACTIVATE_ALERT_MOTIVE_RESET:
            return ActivateAlertMotiveReset(state, action)
        ////////////////////// FETCH NOTIFICATION TYPES //////////////////////
        case actionTypes.FETCH_NOTIFICATION_TYPES_SUCCESS:
            return fetchNotificationTypesSuccess(state, action)
        case actionTypes.FETCH_NOTIFICATION_TYPES_FAIL:
            return fetchNotificationTypesFail(state, action)
        case actionTypes.FETCH_NOTIFICATION_TYPES_START:
            return fetchNotificationTypesStart(state, action)


        case actionTypes.SELECT_ROWS_ALERTS:
            return selectAlertRows(state, action)
        case actionTypes.SELECT_ROWS_EMERGENCY_PHONES:
            return selectEmergencyPhoneRows(state, action)

        default:
            return state
    }
}

export default reducer