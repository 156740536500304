
import styled from 'styled-components';
import { Link  } from 'react-router-dom/'

export const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`
export const ContainerImg = styled.div`
    width: 160px;
    min-height: 10vh;
    position: sticky;
    display: flex;
    align-items: center;
    vertical-align: middle;
`
export const LinkStyled = styled(Link)`
    cursor: pointer;
`
export const TextItem = styled.div`
    display: none;
    overflow: hidden;
    opacity: 0;
    text-decoration: none;
    text-align: left;
`

export const TextAlertItem = styled.div`
    display: none;
    overflow: hidden;
    opacity: 0;
    text-decoration: none;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Box = styled.div`
    ${({ active }) => active && `border-right: 3px solid #05112c`}
`
export const MenuItemButton = styled.button`
    display: flex;
    width: 100%;
    height: 7vh;  
    background-color: white;  
    color: #000000;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border:1px solid #f2f2f2;
    &:hover {
        background-color: rgba(45,199,109,0.25);
    }
    ${({ active }) => active && `font-weight: 700`}
`
export const ImgItems = styled.img`
    max-height: 20px;
    max-width: 20px;
    margin: 5% 5%;
`
export const ImgLogo = styled.img`
    width: 160px;
    height: 7vh;
    padding: 1px 7px 2px 7px;
`
export const MiniLogo = styled.img`
    max-width: 50px;
    margin: 5%;
`

export const Quantity = styled.div`
  color: #FFFFFF;
  width: 25px;
  height: 25px;
  background-color: #ff2424;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const AlertPointer = styled.div`
  color: #ff2424;
  border-radius: 100%;
  margin-left: 5px;
  font-weight:bold;
`
export const ToolBar = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0px;
    }
    display: inline-flex;
    flex-direction: column;
    height: 100vh;
    width: 6%;
    background-color: #ffffff;  
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.09);
    transition: ease 0.3s;
    #Mini-logo {
        opacity: 1;
        transition: ease 0.3s;
        position: absolute;
    }
    #Main-logo {
        opacity: 0;
        transition: ease 0.3s;
        position: absolute;
    }
    &:hover #Mini-logo {
        opacity: 0;
    }
    &:hover #Main-logo {
        opacity: 1;
    }

    &:hover {
        a button {
            justify-content: flex-start;
        }
        ${TextItem} {
            display: flex;
            opacity: 1;
        }
        ${TextAlertItem} {
            display: flex;
            opacity: 1;
        }
        ${AlertPointer} {
            display: none;
        }

        width: 200px;
    }
`       
