import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'

const domain = document.domain
const subdomain = domain.split('.');

const initialState = {
  token: null,
  user: null,
  error: false,
  errorMessage: false,
  loading: false,
  errorForgotPassword: false,
  errorForgotPasswordMessage: false,
  errorForgotPasswordConfirmation: false,
  errorForgotPasswordConfirmationMessage: false,
  loadingForgotPasswordConfirmation: false,
  session: "",
  authRedirectPath: '/',
  subdomain: subdomain.length > 1 ? subdomain[0]+"."+subdomain[1] : subdomain[0],
  bucketUrl: "https://miiiisa-white-bo.s3.us-east-2.amazonaws.com/".concat(subdomain.length > 1 ? subdomain[0]+"."+subdomain[1] : subdomain[0]),
}

const authStart = state => {
  return updateObject(state, { error: false, loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    user: action.user,
    message: action.message,
    tokenrequired: action.tokenrequired,
    session: action.session,
    error: false,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, { errorMessage: action.errorMessage, error:true, loading: false })
}

const authLogout = state => {
  return updateObject(state, { token: null, user: null , userInfo: null})
}

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}




  //------------------Request forgot password-----------------------------------

  const fetchForgotPasswordReset = (state,action ) => {  
    return updateObject(state, {
      forgotPassword: false,
      forgotPasswordConfirmation: false,
      errorForgotPasswordConfirmation: false,
      errorForgotPasswordConfirmationMessage: false,
      loadingForgotPasswordConfirmation: false,
      errorForgotPassword: false,
      errorForgotPasswordMessage: false,
      loadingForgotPassword: false
    })
  }
  const fetchForgotPasswordSuccess = (state,action ) => {  
    return updateObject(state, {
      forgotPassword: action.forgotPassword,
      errorForgotPassword: false,
      errorForgotPasswordMessage: false,
      loadingForgotPassword: false
    })
  }
  
  const fetchForgotPasswordFail = (state,action ) => {
    return updateObject(state, { 
      errorForgotPassword: true,
      errorForgotPasswordMessage: action.error,
      loadingForgotPassword: false
    })
  }
  
  const fetchForgotPasswordStart = state => {
    return updateObject(state, { 
      loadingForgotPassword: true,
      errorForgotPassword: false
     })
  } 


  //------------------Request forgot password confrimation-----------------------------------

  const fetchForgotPasswordConfirmationSuccess = (state,action ) => {  
    return updateObject(state, {
      forgotPasswordConfirmation: true,
      errorForgotPasswordConfirmation: false,
      errorForgotPasswordConfirmationMessage: false,
      loadingForgotPasswordConfirmation: false
    })
  }
  
  const fetchForgotPasswordConfirmationFail = (state,action ) => {
    return updateObject(state, { 
      errorForgotPasswordConfirmation: true,
      errorForgotPasswordConfirmationMessage: action.error,
      loadingForgotPasswordConfirmation: false
    })
  }
  
  const fetchForgotPasswordConfirmationStart = state => {
    return updateObject(state, { loadingForgotPasswordConfirmation: true })
  } 

  //------------------Request Token Auth Login-----------------------------------

  const fetchTokenAuthLoginStart = (state,action ) => {  
    return updateObject(state, {
      tokenAuthLogin: action.forgotPassword,
    })
  }
  
  const fetchTokenAuthLoginFail = state => {
    return updateObject(state, { errorTokenAuthLogin: true })
  }
  
  const fetchTokenAuthLoginSuccess = state => {
    return updateObject(state, { loadingTokenAuthLogin: true })
  } 

  //------------------authRequireToken----------------------------------

  const authRequireToken = (state,action ) => {  
    return updateObject(state, {
      session: action.session,
      loading: false
    })
  }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action)
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_START:
      return fetchForgotPasswordConfirmationStart(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_FAIL:
      return fetchForgotPasswordConfirmationFail(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_SUCCESS:
      return fetchForgotPasswordConfirmationSuccess(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_RESET:
      return fetchForgotPasswordReset(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_START:
      return fetchForgotPasswordStart(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_FAIL:
      return fetchForgotPasswordFail(state, action)
    case actionTypes.FETCH_FORGOT_PASSWORD_SUCCESS:
      return fetchForgotPasswordSuccess(state, action) 
    case actionTypes.FETCH_TOKEN_AUTH_LOGIN_START:
      return fetchTokenAuthLoginStart(state, action)
    case actionTypes.FETCH_TOKEN_AUTH_LOGIN_FAIL:
      return fetchTokenAuthLoginFail(state, action)
    case actionTypes.FETCH_TOKEN_AUTH_LOGIN_SUCCESS:
      return fetchTokenAuthLoginSuccess(state, action)
    case actionTypes.AUTH_REQUIRE_TOKEN:
      return authRequireToken(state, action)
    default:
      return state
  }
}

export default reducer
