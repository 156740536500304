
import {
  FETCH_PAYMENT_OPERATIONS_SUCCESS,
  FETCH_PAYMENT_OPERATIONS_FAIL,
  FETCH_PAYMENT_OPERATIONS_START,
  PAYMENT_OPERATIONS_UPDATEGRID,
  RESET_PAYMENT_OPERATIONS_PAGE,
  FETCH_PAYMENT_OPERATION_DETAIL_SUCCESS,
  FETCH_PAYMENT_OPERATION_DETAIL_FAIL,
  FETCH_PAYMENT_OPERATION_DETAIL_START,
  FETCH_PAYMENT_METHOD_DETAIL_SUCCESS,
  FETCH_PAYMENT_METHOD_DETAIL_FAIL,
  FETCH_PAYMENT_METHOD_DETAIL_START,
  CANCEL_PAYMENT_OPERATION_SUCCESS,
  CANCEL_PAYMENT_OPERATION_START,
  CANCEL_PAYMENT_OPERATION_FAIL,
  CANCEL_PAYMENT_OPERATION_RESET,
  FETCH_ERROR_DETAIL_START,
  FETCH_ERROR_DETAIL_SUCCESS,
  FETCH_ERROR_DETAIL_FAIL,
  FETCH_PAYMENT_OPERATIONS_REPORT_START,
  FETCH_PAYMENT_OPERATIONS_REPORT_FAIL,
  FETCH_PAYMENT_OPERATIONS_REPORT_SUCCESS,
  FETCH_PAYMENT_OPERATIONS_REPORT_RESET,
  FETCH_PAYMENT_STATES_SUCCESS,
  FETCH_PAYMENT_STATES_FAIL,
  FETCH_PAYMENT_STATES_START,
  FETCH_PAYMENT_STATES_RESET,
  
} from './actionTypes'
import getApi from '../../shared/api'
import mapPaymentOperations from '../../selectors/mapPaymentOperations'
import mapPaymentOperationsReport from '../../selectors/mapPaymentOperationsReport'
import { fetchPaymentOperationsAPI, paymentOperationsAPI, fetchPaymentMethodDetail, fetchPaymentStatesAPI } from '../../apiConstants'
import mapOperationDetail from '../../selectors/mapOperationDetail'
import mapPaymentMethodDetail from '../../selectors/mapPaymentMethodDetail'

////////////////////// paymentOperations //////////////////////
export const resetPaymentOperationsPage = () => {
  return {
    type: RESET_PAYMENT_OPERATIONS_PAGE
  }
}


export const fetchPaymentOperationsSuccess = paymentOperations => {
  let data = mapPaymentOperations(paymentOperations);
  return {
    type: FETCH_PAYMENT_OPERATIONS_SUCCESS,
    paymentOperations: data
  }
}

export const fetchPaymentOperationsFail = error => {
  return {
    type: FETCH_PAYMENT_OPERATIONS_FAIL,
    errorPaymentOperations: error
  }
}

export const fetchPaymentOperationsStart = () => {
  return {
    type: FETCH_PAYMENT_OPERATIONS_START
  }
}

export function updateGrid(page, pageSize, total, orderColumn, orderDirection, searchText, getNews, paymentStatesId) {
  return {
    type: PAYMENT_OPERATIONS_UPDATEGRID,
    page,
    pageSize,
    total,
    orderColumn,
    orderDirection,
    searchText,
    getNews,
    paymentStatesId
  };
}

export const fetchPaymentOperations = (
  startDate,
  endDate,
  clientId,
  vendorId,
  pageNumber,
  pageSize,
  orderColumn,
  orderDirection,
  searchText,
  getNews,
  paymentStatesId
  ) => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentOperationsStart())
    
    let paramPaymentStatesId ="";
    if(paymentStatesId!== undefined){
    
      paymentStatesId.forEach((id, index) =>{
        const valor = id.id;
        paramPaymentStatesId+=`&paymentStatesId[${index}]=${valor}`;
      })
      
    }
    const {
      userInfo: {
        menusAuth,
        neighbourhoods
      } } = getState()
    let api;
      api = await getApi();
    return api
      .get(
        `${fetchPaymentOperationsAPI}?pageNumber=${pageNumber}&pageSize=${pageSize}&orderColumn=${orderColumn}&orderDirection=${orderDirection}&searchText=${searchText}&getNews=${getNews}&vendorId=${vendorId}&clientId=${clientId}${paramPaymentStatesId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then(res => {
        if (res.data.success) {
          dispatch(fetchPaymentOperationsSuccess(res.data.data))
          dispatch(
            updateGrid(
              pageNumber,
              pageSize,
              res.data.total_rows,
              orderColumn,
              orderDirection,
              searchText,
              getNews,
              paymentStatesId
            )
          );
        }
        dispatch(fetchPaymentOperationsFail(res.data.message))
      })
      .catch(error => {
        dispatch(fetchPaymentOperationsFail(error))
      })
  }
}


//*** OPERATION DETAILS ***//
export const fetchPaymentOperationDetailSuccess = data => {
  let operationDetail = mapOperationDetail(data);
  return {
    type: FETCH_PAYMENT_OPERATION_DETAIL_SUCCESS,
    operationDetail: operationDetail,
  }
}

export const fetchPaymentOperationDetailFail = error => {
  return {
    type: FETCH_PAYMENT_OPERATION_DETAIL_FAIL,
    errorDetailOperation: error
  }
}

export const fetchPaymentOperationDetailStart = () => {
  return {
    type: FETCH_PAYMENT_OPERATION_DETAIL_START
  }
}

export const fetchOperationDetail = operationId => {
  return async dispatch => {
    dispatch(fetchPaymentOperationDetailStart());

    return await getApi()
      .get(`${paymentOperationsAPI}/${operationId}/payment-items`)
      .then(res => {
        dispatch(fetchPaymentOperationDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchPaymentOperationDetailFail(err));
      });
  };
};

/*** DETALLE MEDIO DE PAGO ***/
export const fetchPaymentMethodDetailSuccess = paymentMethodDetail => {
  let paymentMethodDetailMap = mapPaymentMethodDetail(paymentMethodDetail);
  return {
    type: FETCH_PAYMENT_METHOD_DETAIL_SUCCESS,
    paymentMethodDetail: paymentMethodDetailMap,
  }
}

export const fetchPaymentMethodDetailFail = error => {
  return {
    type: FETCH_PAYMENT_METHOD_DETAIL_FAIL,
    errorMethodOperation: error
  }
}

export const fetchPaymentMethodDetailStart = () => {

  return {
    type: FETCH_PAYMENT_METHOD_DETAIL_START
  }
}

export const fetchPaymentMethodDetails = id => {
  return async dispatch => {
    dispatch(fetchPaymentMethodDetailStart());
    return await getApi()
      .get(`${paymentOperationsAPI}/${id}/payment-methods`)
      .then(res => {

        dispatch(fetchPaymentMethodDetailSuccess(res.data));

      })
      .catch(err => {
        dispatch(fetchPaymentMethodDetailFail(err));
      });
  };
};


/*** CANCELAR OPERACION ***/

export const cancelPaymentOperationSuccess = data => {
  return {
    type: CANCEL_PAYMENT_OPERATION_SUCCESS,
    data: data,
  }
}

export const cancelPaymentOperationFail = error => {
  return {
    type: CANCEL_PAYMENT_OPERATION_FAIL,
    error: error
  }
}
export const cancelPaymentOperationReset = () => {
  return {
    type: CANCEL_PAYMENT_OPERATION_RESET,
  }
}
export const cancelPaymentOperationStart = () => {
  return {
    type: CANCEL_PAYMENT_OPERATION_START
  }
}

export const cancelPaymentOperation = body => {
  return async dispatch => {
    dispatch(cancelPaymentOperationStart());
    return await getApi()
      .put(`${paymentOperationsAPI}/cancel`, body)
      .then(res => {
        dispatch(cancelPaymentOperationSuccess(res.data));
      })
      .catch(err => {
        dispatch(cancelPaymentOperationFail(err));
      });
  };
};

// FETCH ERROR DETAIL

export const fetchErrorDetailSuccess = data => {
  return {
    type: FETCH_ERROR_DETAIL_SUCCESS,
    errorDetail: data,
  }
}

export const fetchErrorDetailFail = error => {
  return {
    type: FETCH_ERROR_DETAIL_FAIL,
  }
}
export const fetchErrorDetailStart = () => {
  return {
    type: FETCH_ERROR_DETAIL_START
  }
}

export const fetchErrorDetail = id => {
  return async dispatch => {
    dispatch(fetchErrorDetailStart());
    return await getApi()
      .get(`${paymentOperationsAPI}/${id}/payment-error`)
      .then(res => {
        dispatch(fetchErrorDetailSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchErrorDetailFail(err));
      });
  };
};

// FETCH PAYMENT OPERATIONS REPORT
export const fetchPaymentOperationsReportSuccess = paymentOperations => {
  let data = mapPaymentOperationsReport(paymentOperations);
  return {
    type: FETCH_PAYMENT_OPERATIONS_REPORT_SUCCESS,
    paymentOperationsReportData: data
  };
};

export const fetchPaymentOperationsReportFail = error => {
  return {
    type: FETCH_PAYMENT_OPERATIONS_REPORT_FAIL,
    error: error
  };
};

export const fetchPaymentOperationsReportStart = () => {
  return {
    type: FETCH_PAYMENT_OPERATIONS_REPORT_START
  };
};

export const fetchPaymentOperationsReportReset = () => {
  return {
    type: FETCH_PAYMENT_OPERATIONS_REPORT_RESET
  };
};

export const fetchPaymentOperationsReport = (
  startDate,
  endDate,
  clientId,
  vendorId,
  orderColumn,
  orderDirection,
  searchText,
  getNews,
  paymentStatesId
) => {
  return async (dispatch, getState) => {
    dispatch(fetchPaymentOperationsReportStart());

    const {
      userInfo: { menusAuth, neighbourhoods }
    } = getState();
    let api;
      api = await getApi();
    let paramPaymentStatesId ="";
    if(paymentStatesId!== undefined){
      
        paymentStatesId.forEach((id, index) =>{
        const valor = id.id;
        paramPaymentStatesId+=`&paymentStatesId[${index}]=${valor}`;
      })
      
    }
    return api
      .get(
        `${paymentOperationsAPI}/report?orderColumn=${orderColumn}&orderDirection=${orderDirection}&searchText=${searchText}&getNews=${getNews}&vendorId=${vendorId}&clientId=${clientId}${paramPaymentStatesId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then(res => {
        console.log("data:", res.data);
        if (res.data.success) {
          dispatch(fetchPaymentOperationsReportSuccess(res.data.data));
        }
      })
      .catch(error => {
        console.log(error.response);
        dispatch(fetchPaymentOperationsReportFail(error));
      });
  };
};

//FETCH PAYMENT OPERATION STATES
export const fetchPaymentStatesSuccess = (data) => {
  
  return {
      type: FETCH_PAYMENT_STATES_SUCCESS,
      paymentOperationStatesData: data,
  }

}

export const fetchPaymentStatesFail = error => {
  return {
      type:FETCH_PAYMENT_STATES_FAIL,
      errorPaymentState: error
  }
}

export const fetchPaymentStatesStart = () => {
  return {
      type: FETCH_PAYMENT_STATES_START
  }
}

export const fetchPaymentStatesReset = () => {
  return {
    type: FETCH_PAYMENT_STATES_RESET
  }
}

export const fetchPaymentStates = () => {
   return async(dispatch) => {
      dispatch(fetchPaymentStatesStart())
      
      return await getApi()
      .get(fetchPaymentStatesAPI)
      .then(res => {
          
          dispatch(fetchPaymentStatesSuccess(res.data.payment_states))
      })
      .catch(err => {
          dispatch(fetchPaymentStatesFail(err))
          
      });
  } 
}


