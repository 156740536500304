import React from 'react';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CrossIcon from '../../../assets/icons/close.png'
import Modal from '../../../components/Modal';
import RadioButton from '../../../components/RadioButton';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { fetchLotsByNeighbourhood } from '../../../store/actions/lotes.actions'
import { createSecurityStaff ,fetchSecurityStaff } from '../../../store/actions/padron.actions'

import ImageUploadPlaceholder from '../../../components/FileImageInput';
import Plus from '@material-ui/icons/Add';

import { SelectWrapper, StyledSelect, AddMore } from '../styled';
import { Column } from '../../NewsAndEvents/styled';
import { Title, RowForm, LabelForm } from '../../Reservations/styled';
import LoaderImage from '../../../components/LoaderImage';
import EmptyLoader from "../../../assets/animation/loader.gif"
import {
  FormContainer,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  InputWrapperMargin
} from '../styled'

class NewStuff extends React.Component {

  state = {

    imagesArray: [],

  };

  toggleVehicleFields = () => {
    this.setState({ vehicleFields: !this.state.vehicleFields })
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={49}>
          <InputLabel>{'Nombre'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'Apellido'}</InputLabel>
          <Input
            value={values.familyName}
            onChange={({ target: { value } }) => setFieldValue('familyName', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.familyName ? true : false}
          />
          {errors.familyName && <ErrorLabel>{errors.familyName}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={49}>
          <InputLabel>{'DNI'}</InputLabel>
          <Input
            value={values.dni}
            onChange={({ target: { value } }) => setFieldValue('dni', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.dni ? true : false}
          />
          {errors.dni && <ErrorLabel>{errors.dni}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'Usuario'}</InputLabel>
          <Input
            value={values.userName}
            onChange={({ target: { value } }) => setFieldValue('userName', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.userName ? true : false}
          />
          {errors.userName && <ErrorLabel>{errors.userName}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row >
        <InputWrapper width={49}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={49}>
          <InputLabel>{'Teléfono'}</InputLabel>
          <Input
            value={values.phone}
            onChange={({ target: { value } }) => setFieldValue('phone', value)}
            placeholder={'54 911 ********'}
            type={'text'}
            rows={1}
            style={{ width: '95%' }}
            error={errors.phone ? true : false}
          />
          {errors.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}
        </InputWrapper>
      </Row>

      <Row >

        <InputWrapper width={49}>
          <InputLabel>{'Foto'}</InputLabel>
          <ImageUploadPlaceholder
            onChange={file => this.fieldImages(file, setFieldValue)}

            width="95%"

            gridArea="main"
            icon={<Plus />}
            dashed={true}
          />
           {errors.file && <ErrorLabel>{errors.file}</ErrorLabel>}
        </InputWrapper>
      </Row>



      <ButtonWrapper>
        {this.props.errorNewStaff ? <ErrorMessage>{this.props.errorNewStaff.message}</ErrorMessage> : ''}
        <StyledButton type={'submit'}>
          {this.props.loadingNewProprietary ? <LoaderImage small={true} src={EmptyLoader} /> : 'Crear'}
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )

  guessImageMime(data) {
    if (data.charAt(0) == '/') {
      return "JPEG";
    } else if (data.charAt(0) == 'R') {
      return "GIF";
    } else if (data.charAt(0) == 'i') {
      return "PNG";
    }
  }


  fieldImages = (image, setFieldValue) => {
    let imagesArray = this.state.imagesArray.concat(image.base64Value)
    this.setState({ imagesArray }, setFieldValue('images', imagesArray))
  }

  handleSubmit = async data => {
    
    console.log("post data stuff", data)
    const newStaffData = {
      picture_base64: data.images[0],
      picture_extension: this.guessImageMime(data.images[0]),
      username: data.userName,
      name: data.name,
      surname: data.familyName,
      dni: data.dni,
      email: data.email,
      phone_number: `+${data.phone}`,
      password: `Miiii_2019`,
    }

    console.log("post new stuff", newStaffData)

    await this.props.createSecurityStaff(newStaffData)

    if (this.props.createdNewStaff) {
      this.props.fetchSecurityStaff()
      this.props.onClose()
    }
  }

  getSignUpSchema = () =>
    Yup.object().shape({
      userName: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required('Ingrese Nombre de Usuario'),
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required('Ingrese Nombre'),
      familyName: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required('Ingrese Apellido'),
      dni: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'DNI invalido')
        .min(8, 'El DNI debe tener un mínimo de 8(ocho) caracteres')
        .max(9, 'El DNI debe tener un máximo de 9(nueve) caracteres')
        .required('Ingrese DNI'),
      email: Yup.string()
        .email('El Email es inválido')
        .required('Ingrese Email'),
      phone: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'Teléfono invalido')
        .length(13, 'El teléfono debe tener 13(trece) caracteres')
        .required('Ingrese Teléfono'),
      images: Yup.string().required('Seleccione Foto')

    })

  fetchLotsByNeighbourhood = (setFieldValue, option) => {
    setFieldValue('neighbourhood', option)
    this.props.fetchLotsByNeighbourhood(option.value)
  }

  getLotsOptions = () => this.props.lotsList.map(l => ({ label: l.code, value: l.id }))

  getNeighbourhoodOptions = () =>
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }))

  formattedSelectedNeighbourhood = () => ({
    value: this.props.selectedNeighbourhood.guid,
    label: this.props.selectedNeighbourhood.name
  })

  getInitialValues = () => ({
    neighbourhood: ''
  })

  render() {
    return (
      <Modal onClose={() => this.props.onClose()}>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood },
  padron,
  lotes
}) => ({
  createdNewStaff: padron.createdNewStaff,
  errorNewStaff: padron.errorNewStaff,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createSecurityStaff, fetchSecurityStaff

    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStuff)
