import { createSelector } from 'reselect'

const mapBookingsComboActivities = createSelector(
  activities => activities,
  activities =>
    activities.map(activity => ({
      value: activity.id,
      label: activity.description,
      is_shared: activity.is_shared
    }))
)

export default mapBookingsComboActivities
