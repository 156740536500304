import React from 'react';
import DayJS from 'react-dayjs';


import KitDetails from '../../../components/KitDetails';
import Input from '../../../components/Input';
import SectionHeader from '../../../components/SectionHeader';
import KitsContainer from '../../../components/KitsContainer';
import RitzTable from '../../../components/RitzTable';
import AddProductButton from '../../../components/Button';
import Woman1 from '../../../assets/images/women.png';
import Woman2 from '../../../assets/images/women2.png';
import Person from '../../../components/Person';
import StatusBadge from '../../../components/StatusBadge';
import Delete from '../../../assets/icons/deletetrash.svg';
import PageLoader from 'react-spinner-material';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {
  Imagen, Ahref
} from '../styled';

class MainList extends React.Component {


  editProduct(idProduct) {
    this.props.toggleEditProduct()
    this.props.handleEditProduct(idProduct)
  }


  render() {
    return (
      <>
        <SectionHeader title='Publicaciones'>
          <AddProductButton inverted onClick={"https://miiiisa-app.s3.us-east-2.amazonaws.com/shop/Productos+Miiii.xlsx"}>Descargar Template</AddProductButton>
          <AddProductButton onClick={() => this.props.toggleAddItem()}>Agregar Productos Masivo</AddProductButton>
          <AddProductButton onClick={() => this.props.toggleAddProduct()}>Agregar Producto</AddProductButton>
        </SectionHeader>
        {this.props.filteredProducts ?
          <RitzTable
            columns={[
              { title: 'Titulo', field: 'title' },
              { title: 'Fecha', field: 'date', cellStyle: { borderRadius: '6px 0px 0px 6px' }, render: rowData => <DayJS format="MM-DD-YY">{rowData.date}</DayJS> },
              { title: 'Usuario', field: 'user' },
              { title: 'Precio', field: 'amount' },
              { title: 'Categoria', field: 'category' },
              { title: 'Fotos', field: 'images', cellStyle: { color: '#ff2424', textDecoration: 'underline', cursor: 'pointer' }, render: rowData => rowData.images.map((item, i) => <Person picture={item} />) },
              {
                title: 'Acciones',
                field: 'actions',
                render: rowData =>

                  <>
                    <Ahref onClick={() => this.editProduct(rowData.idProduct)}><EditOutlinedIcon /></Ahref>
                    <Ahref onClick={() => this.props.handleDetailProduct(rowData.idProduct)}><VisibilityOutlinedIcon /></Ahref>
                    <Imagen onClick={() => this.props.toggleItemDelete(rowData.idProduct)} src={Delete} />
                  </>
              },
            ]}
            loading={this.props.loading}
            data={this.props.filteredProducts}

          />
          :
          <PageLoader />
        }
      </>
    );
  }
}

export default MainList
