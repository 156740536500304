import { createSelector } from 'reselect'

const mapCategoriesByRole = createSelector(
  categoriesList => categoriesList,
  categoriesList => 
  categoriesList.map(
      ({ name, id }, i) => ({
        value: id,
        label: name,
      })
    )
)

export default mapCategoriesByRole
