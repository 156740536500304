import { Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import styled from 'styled-components'
import compose from 'recompose/compose'
import classNames from 'classnames'
import SectionHeader from '../../components/SectionHeader';

//import Page from '../../../components/Page'
import Button from '../../components/Button'
import StyledTabs from '../../components/StyledTabs'
//import SearchBar from '../../components/SearchBar'
import ResourceList from '../../components/ResourceList'
import EmptyLoader from '../../components/Loader'
import StyledInput from '../../components/Input'

import mapCategoriesByRole from '../../selectors/mapCategoriesByRole'
import mapTicket from '../../selectors/mapTicket'
import mapNeighbourhoods from '../../selectors/mapNeighbourhoods'

import { Avatar, Grid } from '@material-ui/core'

import { getDayHourDate } from '../../utils/dateParser'

import ModalChangeState from './ModalChangeState'
import ModalChangeCategory from './ModalChangeCategory'

import {
  fetchTickets,
  pendingTicket,
  changeCategoryTicket,
  fetchTicketMessages,
  fetchCategoriesByRole,
  sendTicketMessage,
  closeTicket,
  setSelectedTicket,
  searchTickets,
  resetSelectedTicket,
  fetchAllCategories, resetModalsStateRedux
} from '../../store/actions/tickets.actions'
import { setNeighbourhood } from '../../store/actions/userInfo.actions'
import Loader from '../../components/Loader'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 600px;
`
const ListWrapper = styled.div`
  background-color: white;
  padding-right: 13px;
  max-height: 600px;
  overflow-y: scroll;
    width: 35%;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
`
const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
const ContainerSelects = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
const SelectStyled = styled(Select)`
  width: 50%;
  margin: 0px 10px;
`
const ContainerSearchBar = styled.div`
  width: 100%;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
  background-color: #f9f9fb;
  margin-bottom: 0px;
  padding: 10px;
`
const EmptyLoaderContainer = styled.div`
  margin-left: 80px;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const SearchWrapper = styled.div`
  max-height: auto;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 0px;
  background-color: white;
`
const DataContainer = styled.div`
  width: 77%;
  padding: 22px 25px;
  width: 65%;
  border-bottom-right-radius: 10px;
`
const Row = styled.div`
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
`
const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  margin: 2% 0%;
`
const HourText = styled.div`
 font-size: 12px;
 color: #2F2F2F;
 text-align: right;

 position: relative;
 bottom: -28px;
`
const TextContainer = styled.div`
  width: ${({ sentbyuser }) => sentbyuser ? "90%" : "63%"};
  height: auto;
  padding: 1% 2%;
  border-radius: 5px;
  margin-left: ${({ sentbyuser }) => sentbyuser ? "" : "35%"};   
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  
  background-color: ${({ sentbyuser }) => sentbyuser ? "rgba(48, 115, 95, 0.08)" : "rgba(129, 190, 59, 0.1)"}; 
  border-radius: 6px 6px;
`
const ContainerMessage = styled.div`
height: 40vh;
  overflow: overlay;
`
const SelectWrapper = styled.div`
  width: 150px; 
`
const ImageMessage = styled.img`
  height: 150px;
  width: auto;
  align-self: ${({ sentbyuser }) => sentbyuser ? "flex-start" : "flex-end"};
`

const ContainerTab = styled.div`
display: flex;
overflow: hidden;
min-height: 60px;
`

const menulistItems = [
  { title: 'Bandeja de tickets', route: 'tickets', available: true },


];


class Tickets extends React.Component {
  state = {
    categoriesList: [],
    filter: '',
    msjData: '',
    ticket: {},
    categoryId: { value: 0, label: 'Todas' },
    sendText: '',
    tabSelected: 0,
    isModalState: false,
    isModalCategory: false,
    view: 'tickets',
    neighbourhoodSelected: {

      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid


    }
  }

  getInitialValues = () => ({
    filter: ''
  })

  componentDidMount = () => {
    this.fetchContent()
    // if (this.props.tickets.ticketsList.length === 0) {
    // }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tickets.ticketsList.length === 0 && prevState.filter.length !== 0 && this.state.filter.length === 0) {
      this.fetchContent()
    }

  }

  fetchContent = reset => {
    this.props.fetchTickets(0)
    this.props.fetchCategoriesByRole()
    this.props.fetchAllCategories()
  }

  isLoadingContent = () => false

  handleTicketClick = ticket => {
    this.props.setSelectedTicket(ticket)
    this.setState({ ticket: ticket })
    this.setState({ sendText: '' })
    this.props.fetchTicketMessages(ticket.id)
  }

  handleCategory = (e) => {
    this.props.fetchTickets(e.value)
    this.setState({ selectValue: e })
  }
  handleInputOnChange = (value) => {
    console.log('handleInputOnChange', value)
    this.setState({ filter: value })
    
  }
  searchTickets = () =>{
    this.props.searchTickets(this.state.filter)
  }
  handleInputClear = (e) => {
    this.setState({ filter: '' })
  }

  showModalPendingState = () => {
    this.setState({ isModalState: 'pending' })
  }

  showModalCloseState = () => {
    this.setState({ isModalState: 'close' })
  }

  closeAllModals() {
    this.props.resetModalsStateRedux()
    this.setState({ isModalState: null })
  }

  toggleModalCategory = () => {
    this.setState({ isModalCategory: !this.state.isModalCategory })
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13 && e.target.value !== '') {
      var filterList = this.props.ticketsList.filter(item => { return item.dni == this.state.filter || item.name.includes(this.state.filter) || item.lote.includes(this.state.filter) })
    }
  }




  handleSend = async () => {
    const formattedBody = {
      ticket_id: this.state.ticket.id,
      text: this.state.sendText
    }
    await this.props.sendTicketMessage(formattedBody)
    this.props.fetchTicketMessages(this.state.ticket.id)
    this.handleTicketClick(this.state.ticket)
  }

  handleClose = (MessageClose) => {
    this.props.closeTicket(this.state.ticket.id, MessageClose)
  }

  handleMessageOnChange = (e) => {
    this.setState({ sendText: e.target.value })
  }

  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }

  handlePending = (MessageClose) => {
    const formattedBody = {
      state_id: 2,
      changestate_comment: MessageClose
    }
    this.props.pendingTicket(this.state.ticket.id, formattedBody)
  }

  handleTransfer = (categoryTransfer) => {
    const formattedBody = {
      category_id: categoryTransfer,
    }
    this.props.changeCategoryTicket(this.state.ticket.id, formattedBody)
  }


  handleChange = (neighbourhoodSelected) => {
    this.props.setNeighbourhood(neighbourhoodSelected)
    this.setState({ neighbourhoodSelected: neighbourhoodSelected });

  }

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }


  switchView = (view) => {
    this.setState({ view });
  }



  renderMessagesContent = () => (
    <div>
      <Row justify={'space-between'} align={'center'} style={{ marginBottom: '18px' }}>
        <Grid xs={1}>
          <Avatar style={{ background: '#30735f', width: '45px', height: '45px', padding: '1px' }} />
        </Grid>
        <Grid xs={11}>
          <Row justify={'space-between'} style={{ marginBottom: '10px' }} >
            <label>{this.state.ticket.name}</label>
          </Row>

          <Row>
            <label>{this.state.ticket.titleMessage}</label>
          </Row>
        </Grid>
      </Row>
      <ContainerMessage>
        {this.props.messagesRaw.map((e) => (
          <>
            {e.sentbyuser ? (
              <>
                <TextContainer sentbyuser={true}>
                  <>
                    {e.picture_url ? (
                      <ImageMessage sentbyuser={true} src={e.picture_url} />
                    ) : (<></>)}
                    <Name>{e.text}</Name>
                  </>
                  <HourText>{getDayHourDate(e.date)}</HourText>
                </TextContainer>

              </>

            ) : (
              <>
                <TextContainer sentbyuser={false}>
                  <>
                    {e.picture_url ? (
                      <ImageMessage sentbyuser={false} src={e.picture_url} />
                    ) : (<></>)}
                    <Name>{e.text}</Name>
                  </>
                  <HourText>{getDayHourDate(e.date)}</HourText>
                </TextContainer>
              </>
            )
            }
          </>
        ))}
      </ContainerMessage>
      {
        this.props.userInfo.menusAuth.map(menu => {
          if (menu.code === "TICKETS_ADMIN_BO") {
            return (
              <>
                <Row justify={'space-between'} align={'center'} style={{ width: '40%', margin: "3% 2%" }} >
                  <Button onClick={this.toggleModalCategory} width={"75px"}>{'Transferir'}</Button>
                  {this.state.tabSelected === 1 ? "" : <Button onClick={() => this.showModalPendingState()} width={"75px"}>{'Pendiente'}</Button>}
                  {this.state.tabSelected === 2 ? "" : <Button onClick={() => this.showModalCloseState()} width={"77px"}>{'Terminado'}</Button>}
                </Row>
                <Row justify={'space-between'} align={'center'}>
                  <StyledInput value={this.state.sendText} onChange={(e) => this.handleMessageOnChange(e)} style={{ width: '90%' }}></StyledInput>
                  <Button onClick={this.handleSend} >{'Enviar'}</Button>
                </Row>
              </>)
          }
        })
      }
    </div>
  )

  renderPageContent = ({ handleSubmit, values, setFieldValue, errors }) => (

    <ContentWrapper>
      <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
        <ResourceList
          elements={this.props.ticketsList[this.state.tabSelected]}
          onClick={this.handleTicketClick}
          selectedPos={this.props.selectedTicket ? this.props.selectedTicket.pos : null}
          hasMoreColumnInfo={true}
          noMoreContentMsg={'No hay mas tickets disponibles'}
          titlePadding={"0"}
        />
      </ListWrapper>
      <DataContainer>
        {this.props.messagesRaw.length > 0 ? this.renderMessagesContent() : ''}
      </DataContainer>
    </ContentWrapper>
  )

  render() {

    if (this.props.loading) {
      return (<EmptyLoaderContainer>
        <EmptyLoader />
      </EmptyLoaderContainer>
      )
    } else {
      return (

        <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Tickets'>
          {(() => {
            switch (this.state.view) {


              case 'tickets':
                return (

                  <>

                    <SectionHeader title='Bandeja de tickets'>
                      <SelectWrapper>
                        <Select

                          value={this.state.neighbourhoodSelected}
                          onChange={this.handleChange}
                          options={this.props.neighbourhoods}

                        />

                      </SelectWrapper>


                    </SectionHeader>

                    <Wrapper>
                      <ContainerTab>
                        <StyledTabs
                          value={this.state.tabSelected}
                          tabs={[
                            { label: 'Nuevos', value: this.props.ticketsList[0].length, id: 0 },
                            { label: 'Pendiente', value: this.props.ticketsList[1].length, id: 1 },
                            { label: 'Resueltos', value: this.props.ticketsList[2].length, id: 2 }
                          ]}
                          handleTabChange={this.handleTabChange}

                        />
                      </ContainerTab>
                      <SearchWrapper>
                        <ContainerSearchBar>
                          <StyledInput
                            placeholder={'Busca por nombre, DNI o numero de lote'}
                            style={{ width: '100%', marginRight: 10 + "px" }}
                            value={this.state.filter}
                            onChange={(e) => this.handleInputOnChange(e.target.value)}
                            onClear={this.handleInputClear}
                            onKeyDown={this.handleKeyPress}
                            noBorder={true}
                            autoFocus
                          />
                          <Button borderColor="darkgreen"
                            variant="inherit"
                            medium
                            type={"submit"}
                            onClick={() => this.searchTickets()}>
                              Buscar
                          </Button>
                          <ContainerSelects>
                            <SelectStyled
                              value={this.state.selectValue}
                              onChange={this.handleCategory}
                              placeholder={'Todas las categorias'}
                              options={this.props.categoriesList}
                            />
                          </ContainerSelects>
                        </ContainerSearchBar>
                        {this.state.isModalState === "close" ?
                          <ModalChangeState
                            onBtnClick={this.handleClose}
                            onLoading={this.props.tickets.loadingFetchTickets}
                            onError={this.props.tickets.closeTicketFail}
                            onSuccess={this.props.tickets.closeTicketSuccess}
                            onBtnClose={() => this.closeAllModals()}
                            title={'Finalizar ticket'}
                          />
                          : this.state.isModalState === "pending" ?
                            <ModalChangeState
                              onBtnClick={this.handlePending}
                              onLoading={this.props.tickets.loadingPendingTicket}
                              onError={this.props.tickets.pendingTicketFail}
                              onSuccess={this.props.tickets.pendingTicketSuccess}
                              onBtnClose={() => this.closeAllModals()}
                              title={'Cambiar estado'}
                            /> : ""
                        }
                        {console.log("CATEGORIEEEES", this.props.allCategoriesList)}
                        {this.state.isModalCategory &&
                          <ModalChangeCategory
                            onBtnClick={this.handleTransfer}
                            onLoading={this.props.tickets.loadingPendingTicket}
                            onError={this.props.tickets.changeCategoryTicketFail}
                            onSuccess={this.props.tickets.changeCategoryTicketSuccess}
                            onBtnClose={this.toggleModalCategory}
                            actualCategory={this.state.ticket.categoryid}
                            categoryList={this.props.categoriesList}
                            title={'Cambiar categoria ticket'}

                          />
                        }

                        <Formik
                          initialValues={this.getInitialValues()}
                          render={e => this.renderPageContent(e)}
                        />

                      </SearchWrapper>
                    </Wrapper>
                  </>
                );
            }
          })()}
        </NavBar>
      )
    }
  }
}

const mapStateToProps = ({
  tickets,
  userInfo,
  tickets: {
    ticketsList,
    categoriesList,
    ticketMessagesList,
    allCategoriesList
  },
  userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },
  selectedTicket
}) => ({
  tickets,
  categoriesRaw: categoriesList,
  categoriesList: mapCategoriesByRole(categoriesList),
  allCategoriesList: mapCategoriesByRole(allCategoriesList),
  neighbourhoods: mapNeighbourhoods(neighbourhoods),
  selectedNeighbourhood,
  ticketsList: mapTicket(ticketsList),
  messagesRaw: ticketMessagesList.length > 0 ? ticketMessagesList.reverse() : "",
  selectedTicket,
  loading: tickets.loadingFetchTickets,
  userRoles: userInfo.menusAuth,
  userInfo,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setNeighbourhood,
      fetchTickets,
      fetchTicketMessages,
      fetchCategoriesByRole,
      fetchAllCategories,
      sendTicketMessage,
      setSelectedTicket,
      resetSelectedTicket,
      closeTicket,
      pendingTicket,
      changeCategoryTicket,
      searchTickets, resetModalsStateRedux
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(

  )(Tickets)
)
