import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchTransactionQR } from '../../../../store/actions/billetera.actions'
import { InputStyled , ErrorLabel, Row,FormContainer, LabelContainer, SelectWrapper } from './styled';
import { Formik } from 'formik'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button';
import * as Yup from 'yup'

const conceptos = [
  { value: 'Expensas',  label: 'Expensas' },
  { value: 'Reservas',  label: 'Reservas' },
  { value: 'Multas',  label: 'Multas'  },
  { value: 'Otros',  label: 'Otros'  },
];
  
class FirstStep extends React.Component {
  
  getSignUpSchema = () => Yup.object().shape({
    concepto: Yup.string().required('El campo es requerido'),
    amount: Yup.string().required('El campo es requerido'),
  })

  handleFormSubmit = async data => {
    this.props.updateTransaction(data)
    this.props.NextStep()
  };

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <LabelContainer>Monto a cobrar</LabelContainer>
        <InputStyled 
          autoFocus={true}
          prefix="$" 
          value={values.amount} 
          onChangeEvent={e => setFieldValue('amount', e.target.value.slice(1,15))} 
          thousandSeparator={'.'} 
          decimalSeparator={','}/>
          {errors.amount && <ErrorLabel>{errors.amount}</ErrorLabel>}
      </Row>
      <Row> 
        <LabelContainer>Concepto</LabelContainer>
        <SelectWrapper>
          <Input 
            name={'concepto'}
            label={'concepto'}
            placeholder={'Concepto'}
            value={values.cbualias}
            error={errors.concepto}
            onChange={({ target: value }) => setFieldValue('concepto', value.value)}
            margin="normal"
            weight="700"
            width="100%"
          />
        </SelectWrapper>
        {errors.concepto && <ErrorLabel>{errors.concepto}</ErrorLabel>}
      </Row>
      <br/>
      <Button type="submit" value="submit">Continuar</Button>
    </FormContainer>
  )

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={this.getSignUpSchema()}
        onSubmit={this.handleFormSubmit}
        render={e => this.getFormContent(e)}
      />
    )
  }
}
  
const mapStateToProps = state => {
  return {
    GetCodeQRImage: state.billetera.GetCodeQRImage,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionQR
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstStep)
