import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'

import Input from '../../../components/Input'
import Modal from '../../../components/Modal'

import ModalInfo from './modal-info'
import Spinner from 'react-spinner-material'

import { fetchGetGuest, fetchCheckIn } from '../../../store/actions/accesos.actions'
import spinner from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

export const InputStyled = styled(Input)`
    opacity: 0;
`

export const Container = styled.form`
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

class AccessModal extends React.Component {


  state = {
    isModalInfoOpen: false,
    isUserOnTheBrowser: true,
    timeout: 0,
  }


  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    window.addEventListener('blur', this.softcheckToClose, false);

  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
    window.removeEventListener('blur', this.softcheckToClose, false);
  }

  softcheckToClose = () => {
    if (!this.state.isModalInfoOpen && !this.props.loading) {
      this.props.onClose(true)
    }
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose(true)
    }
  }

  doSearch(evt) {
    var searchText = evt.target.value; // this is the search text
    if (this.state.timeout) clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(() => {
      this.props.fetchGetGuest(searchText)
      this.toggleModalInfo();
    }, 800);
  }

  toggleModalInfo = () => {
    console.log("*** toggleModalInfo se cambia el esado--- a ", !this.state.isModalInfoOpen)
    this.setState({ isModalInfoOpen: !this.state.isModalInfoOpen })
  }

  handleCheckIn = () => {
    this.props.fetchCheckIn(this.props.accesos.getGuestInvitation.guest_invite_id)
  }

   componentDidUpdate(prevProps) {
     if (!this.props.loading) {
       if (prevProps.checkInSucces === false && this.props.checkInSucces === true) {
         this.props.onSuccess();
         this.props.toggleSuccesModal(
           <p>El check-in fue realizado exitosamente!</p>
         );
       }
       if (prevProps.checkInFail !== this.props.checkInFail) {
         this.props.onSuccess();
         this.props.toggleFailModal(
           <p>El check-in no fue realizado.</p>
         );
       }
       if (prevProps.error === false && this.props.error) {
         this.props.onSuccess();
         this.props.toggleFailModal(
           <p>{this.props.error}</p>
         );
       }
     }
   }
 
  render() {

    console.log("modal qr state", this.state)
    console.log("modal qr porps", this.props)
    return (

      <>

        {this.state.isModalInfoOpen ?


          <ModalInfo
            accesos={this.props.accesos}
            loading={this.props.loading}
            checkin={() => this.handleCheckIn()}
            error={this.props.error}
            onClose={this.props.onClose}
            editMode={this.props.editMode}
          ></ModalInfo>
          :
          <Modal onClose={this.props.onClose}>
            <Container>
              <InputStyled
                name={'searchInput'}
                label={'searchInput'}
                id="searchInput"
                onChange={evt => this.doSearch(evt)}
                margin="normal"
                type="text"
                autoFocus="autoFocus"
              />
              <Spinner />
              {this.props.loading ? "buscando..." : ""}
            </Container>
          </Modal>
        }
      </>
    )
  }
}

const mapStateToProps = ({
  accesos,
}) => ({
  loading: accesos.loadingGuestInvitation,
  accesos: accesos,
  error: accesos.errorGuestInvitation,
  checkInSucces: accesos.checkInSuccess,
  checkInFail: accesos.checkInFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetGuest, fetchCheckIn
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(withWidth())(AccessModal)
)