import { createSelector } from 'reselect'
//import reportes from '../containers/reportes';
var moment = require('moment');

let mapBookingReport = createSelector(  
    reporteReservas => reporteReservas,
    reporteReservas => 
  (reporteReservas.map(reporteReservas => ({
    id:reporteReservas.id,
    host_username : reporteReservas.host_username,
    from : reporteReservas.from,
    to :reporteReservas.to,
    spaceId : reporteReservas.spaceId,
    activityId : reporteReservas.activityId,
    price : reporteReservas.price,
    Activity : reporteReservas.Activity,
    Space : reporteReservas.Space,
    Date : moment(reporteReservas.Date).format('YYYY-MM-DD'),
  })) )
)


export default mapBookingReport