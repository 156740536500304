
import { createSelector } from 'reselect'

const mapClientDataByNeighbourhoods = createSelector(
  data => data,
  data =>
    data.map(
      (e, i) => ({
        id: e.NeighbourhoodId,
        name: e.NeighbourhoodName,
        billeteras: e.Wallets,
        cuentas_itau: e.ItauAccounts,
        debitos: e.Debits
      })
    )
)

export default mapClientDataByNeighbourhoods