import * as actionTypes from './actionTypes'

import { 
  authAPI,
  forgotPasword,
  authTokenAPI,
  forgotPaswordConfirmation,
  configBrand
 } from '../../apiConstants'

import getApi from '../../shared/api'

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, user,message,tokenrequired,session) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    user: user,
    message: message,
    tokenrequired: tokenrequired,
    session: session
  }
}

export const authFail = error => {  
  return {
    type: actionTypes.AUTH_FAIL,
    error: true,
    errorMessage: typeof error === "object" ? false : error
  }
}


export const logout = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('tokenId')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('expirationDate')
  localStorage.removeItem('user')
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime * 1000)
  }
}

export const auth = (email, password,token,session) => {
  return async dispatch => {
    const api = await getApi(true,true)
    dispatch(authStart())

    const authData = {
      username: email,
      password: password,
      token: token,
      session: session
    }

        
    return api
      .post(authAPI, authData)
      .then(async response => {
        if(response.data.tokenrequired === false){
          const tokenid = response.data.auth.token_id
          const userInfo = response.data.user
          const message = response.message
          const tokenrequired = response.tokenrequired
          userInfo.neighbourhoods = response.data.neighbourhoods.reverse()

          const miBarrioDefaultNeighbourhood = {name: "MiBarrio",label: "MiBarrio", guid: "11111111-1111-1111-1111-111111111111"}
          userInfo.selectedNeighbourhood = response.data.neighbourhoods.length > 0 ? response.data.neighbourhoods[0] : miBarrioDefaultNeighbourhood
          userInfo.menusAuth = response.data.menus

          await localStorage.setItem('accessToken', response.data.auth.access_token)
          await localStorage.setItem('tokenId', tokenid)
          await localStorage.setItem('refreshToken', response.data.auth.refresh_token)
          await localStorage.setItem('session', response.session)
          await localStorage.setItem('loginDate', new Date())

          await localStorage.setItem('user', JSON.stringify(userInfo))
          
          await localStorage.setItem('guid', userInfo.selectedNeighbourhood.guid)

          dispatch(authSuccess(tokenid, userInfo,message,tokenrequired,session))
        }else{
          dispatch(authRequireToken(response.data.session))
        }
      }).catch(err => {
        if(err.response){
          dispatch(authFail(err.response.data.message))
        }else{
          dispatch(authFail(err))
        }
      })
  }
}


export const authRequireToken = session => {
  return {
    type: actionTypes.AUTH_REQUIRE_TOKEN,
    session: session
  }
}

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('tokenId')
    const loginDate = new Date(localStorage.getItem('loginDate'))
    const actualDate = new Date()
    if (!token || loginDate.getDay() !== actualDate.getDay()) {
      dispatch(logout())
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      dispatch(authSuccess(token, user))
    }
  }
}

  //------------------Request forgot password-----------------------------------

  export const fetchForgotPasswordReset = forgotPassword => {
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_RESET,
    }
  }
  
  export const fetchForgotPasswordSuccess = forgotPassword => {
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_SUCCESS,
      forgotPassword: forgotPassword
    }
  }
  
  export const fetchForgotPasswordFail = error => {
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_FAIL,
      error: error
    }
  }
  
  export const fetchForgotPasswordStart = () => {
    console.log("le pego al action")
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_START
    }
  }
  
  export const fetchForgotPassword = username => {
    return async (dispatch, getState) => {
      dispatch(fetchForgotPasswordStart())
      const api = await getApi(true,true)

      var urlFormat= forgotPasword+username

      try {
        const response = await api.post(urlFormat)
          dispatch(
            fetchForgotPasswordSuccess(response.data)
          )
        } catch (error) {
          dispatch(fetchForgotPasswordFail(error.response.data.message))
        }
    }
  }

  //------------------Request forgot password confirmation-----------------------------------

  export const fetchForgotPasswordConfirmationSuccess = forgotPasswordConfirmation => {
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_SUCCESS,
      forgotPasswordConfirmation: forgotPasswordConfirmation
    }
  }
  
  export const fetchForgotPasswordConfirmationFail = error => {
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_FAIL,
      error: error
    }
  }
  
  export const fetchForgotPasswordConfirmationStart = () => {
    console.log("le pego al action")
    return {
      type: actionTypes.FETCH_FORGOT_PASSWORD_CONFIRMATION_START
    }
  }
  
  export const fetchForgotPasswordConfirmation = data => {
    return async (dispatch, getState) => {
      dispatch(fetchForgotPasswordConfirmationStart())
      const api = await getApi(true,true)


      try {
        const response = await api.post(forgotPaswordConfirmation,data)
          dispatch(
            fetchForgotPasswordConfirmationSuccess(response.data)
          )
        } catch (error) {
          dispatch(fetchForgotPasswordConfirmationFail(error.response.data.message))
        }
    }
  }

  //------------------Request token auth-----------------------------------

  export const fetchTokenAuthLoginSuccess = tokenAuthLogin => {
    return {
      type: actionTypes.FETCH_TOKEN_AUTH_LOGIN_SUCCESS,
      tokenAuthLogin: tokenAuthLogin
    }
  }
  
  export const fetchTokenAuthLoginFail = error => {
    return {
      type: actionTypes.FETCH_TOKEN_AUTH_LOGIN_FAIL,
      error: error
    }
  }
  
  export const fetchTokenAuthLoginStart = () => {
    return {
      type: actionTypes.FETCH_TOKEN_AUTH_LOGIN_START
    }
  }
  
  export const fetchTokenAuthLogin = (token,user,pass) => {
    return async (dispatch, getState) => {
      dispatch(fetchTokenAuthLoginStart())
      const api = await getApi(true,true)
      const authData = {
        username: user,
        password: pass,
        token: token,

      }

      var urlFormat= authTokenAPI
      try {
        const response = await api.post(urlFormat,authData)
          dispatch(
            fetchTokenAuthLoginSuccess(response.data)
          )
        } catch (error) {
          dispatch(fetchTokenAuthLoginFail(error.response.data.message))
        }
    }
  }


  //------------------Request config-----------------------------------

  export const fetchConfigBrandSuccess = configBrand => {
    return {
      type: actionTypes.FETCH_CONFIG_BRAND_LOGIN_SUCCESS,
      configBrand: configBrand
    }
  }
  
  export const fetchConfigBrandFail = error => {
    return {
      type: actionTypes.FETCH_CONFIG_BRAND_LOGIN_FAIL,
      error: error
    }
  }
  
  export const fetchConfigBrandStart = () => {
    return {
      type: actionTypes.FETCH_CONFIG_BRAND_LOGIN_START
    }
  }
  
  export const fetchConfigBrand = (subdomain) => {
    return async (dispatch, getState) => {
      dispatch(fetchConfigBrandStart())
      const api = await getApi(true,true)

      const urlFormat = "https://miiiisa-white-bo.s3.us-east-2.amazonaws.com/"+subdomain+"/assets/image/logo.png"
      try {
        const response = await api.get(urlFormat)
          dispatch(
            fetchConfigBrandSuccess(response.data)
          )
        } catch (error) {
          dispatch(fetchConfigBrandFail(error.response.data.message))
        }
    }
  }
