import styled from 'styled-components'
import {
    Grid,
    Button,
    Paper,
    Typography
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

export const ProgressContainer = styled.div`
    flex-grow: 1;
`

export const GridButtonBack = styled(Grid)`
    margin-top: ${({ theme }) => theme.spacing.unit3};
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 1000px;
    border-radius: 10px;
    display: flex;
`

export const ButtonBack = styled.button`
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    margin: ${({ theme }) => theme.spacing.unit2} !important;
    background-color: ${({ theme }) => theme.colors.white} !important;
`

export const FormContainer = styled.form`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    padding: 30px 20px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.2s ease;
    align-items: center;
    justify-content: center;
    `

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.blueRibbon};
    background-color: ${({ theme }) => theme.colors.white};
`

export const TyTitle = styled(Typography)`
    width: 90%;
    max-width: 1000px;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 30px;
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-bottom: ${({ theme }) => theme.spacing.unit3}!important;
`

export const PaperStyled = styled(Paper)`
    border-radius: 10px !important;
    border-style: solid;
    border-width: 0.5px;
    border-color: ${({ theme }) => theme.colors.ghost};

    box-shadow: none !important;
    margin-bottom:  ${({ theme }) => theme.spacing.unit2};
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey};
    font-size: 18px;
`

export const BarrioLabel = styled.label`
    width: 80%;
    display:flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${props => (props.size ? props.size : '15px')};
`

export const SelectWrapper = styled.div`
    width: 100%;
    margin: 1% 0px 1% 0px;
    display: flex;
    justify-content: center;
`
export const BarrioWrapper = styled.div`
    width: 70%;
    height: 16%;
    margin: 1% 0 3% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.wildSand};
    border-color: ${({ theme }) => theme.colors.greyBorder};
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding:0;
    
`



export const RadioBtnContainer = styled.div`
    width: 100%;
    // margin-top: ${({ theme }) => theme.spacing.unit2};
    margin-top: 1%;
    margin-bottom: ${({ theme }) => theme.spacing.unit2};
    display:flex;
    flex-direction: row;
    justify-content: space-around;  
`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 7px 25px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 30px 0px 0px 100px;
`

export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
    cursor: pointer;
`

export const LabelChecked = styled.label`
    margin: 1% 4% -4% 0%;
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`

export const DestinatarioDetailContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin-top: ${({ theme }) => theme.spacing.unit};
`

export const DestinatarioDetailItemContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
export const DestinatarioDetailItem = styled.div`
    font-style: 15px;
`

export const InputWrapper = styled.div`
    width:  100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`