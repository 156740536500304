import { createSelector } from 'reselect'
import { getFormattedDate } from '../utils/dateParser'

const mapNovelty = createSelector(
  feedList => feedList,
  feedList =>
    feedList.map(
      (
        {
          id,
          publicationdate,
          publicationendshowingdate,
          creationusername,
          creationuserimageurl,
          creationuserfullname,
          title,
          subtitle,
          body,
          headerimageurl,
          externalurl,
          boldparagraph,
          newsimagesurls,
          eventsimagesurls,
          eventsdocumenturl,
          eventsvideourl,
          newsvideourl,
          newsdocumenturl,
          neighbourhoodlist
        },
        i
      ) => ({
        id: id,
        pos: i,
        title: title,
        subtitle: subtitle,
        extraInfo: getFormattedDate(publicationdate),
        body: body,
        headerimageurl: headerimageurl,
        externalurl: externalurl,
        boldparagraph: boldparagraph,
        creationusername: creationusername,
        creationuserfullname: creationuserfullname,
        creationuserimageurl: creationuserimageurl,
        newsimagesurls: eventsimagesurls? eventsimagesurls: newsimagesurls,
        document: eventsdocumenturl? eventsdocumenturl : newsdocumenturl,
        video: eventsvideourl ? eventsvideourl : newsvideourl,
        publicationdate,
        publicationendshowingdate,
        neighbourhoodlist
      })
    )
)

export default mapNovelty
