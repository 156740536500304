import React, { Component } from 'react'
import CrossIcon from '../../assets/icons/cross-border-round copy.png'
import Modal from '../Modal'
import Carrousel from '../Carrouselphotos'
import {
    Container,
    RowItem,
    RowLeft, RowRight, TituloLeft, DescRight, RowItemNoBorder,
    Body, TituloLeftSub, UserImg, SubtitleText,
    DireccionLabel, CrossImage,Status,ContainerLabel
} from './styled'
import Button from '../Button';

class DetailModal extends Component {  

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.props.fetchOrderQR(this.props.order.orderid);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose()
        }
    }
    
  downloadQrImage = () => {
    document.getElementById('qrAbierto').click();
  }

    render() {
        const { clasificados } = this.props;
        return (
            <Modal withOutPage={true} width="auto" height="auto" left="30%" top="10%" >
                <Container>
                    <Body>
                        <CrossImage onClick={() => this.props.onClose()} src={CrossIcon} />
                        <RowItem>
                            <RowLeft>
                                <TituloLeft>Datos del comprador</TituloLeft>
                            </RowLeft>
                        </RowItem>  
                        <RowItem >
                            <RowLeft>
                                <UserImg src={this.props.order.buyerpictureurl} />
                                <ContainerLabel>
                                    <SubtitleText>{this.props.order.buyerfullname}</SubtitleText>
                                    <SubtitleText>{this.props.order.buyerfulladdress}</SubtitleText>
                                </ContainerLabel>
                            </RowLeft>
                            <RowRight>
                            {(() => {
                            switch(this.props.order.orderstatecode) {
                                case "EN_TRANSITO":
                                return <Status background={"rgba(65, 117, 5, 0.7)"} >{this.props.order.orderstatename} </Status>;
                                case "PENDIENTE_ENTREGA":
                                return <Status background={"rgba(244, 64, 52, 0.6)"} >{this.props.order.orderstatename} </Status>;
                                
                                default:
                                return null;
                            }
                            })()}
                            </RowRight>
                        </RowItem>
                        <RowItem>
                            <RowLeft> Telefono:</RowLeft>
                            <RowRight>{this.props.order.phone}</RowRight>
                        </RowItem>
                        <RowItem>
                            <RowLeft> Dni:</RowLeft>
                            <DescRight>{this.props.order.documento}</DescRight>
                        </RowItem>
                        <RowItem>
                            <RowLeft>Correo Electronico:</RowLeft>
                            <DescRight>{this.props.order.email}</DescRight>
                           
                        </RowItem> 
                        <RowItem >
                            <RowLeft> Direccion de envio</RowLeft>
                            <RowRight>
                                <DireccionLabel>
                                    {this.props.order.buyerneighbourhoodname},{this.props.order.buyerfulladdress}
                                </DireccionLabel>
                            </RowRight>
                        </RowItem> 
                        
                        
                        <a href={'data:application/octet-stream;base64,' + this.prop.clasificados.orderQr} download="QR_Abierto.jpeg" id="qrAbierto" hidden></a>
       
                        <RowItemNoBorder >
                            <Button onClick={()=> this.props.startConversation()} width="35%" background={"rgba(24, 115, 243, 0.2)"} color={"#1873F3"} >Ver mensajes</Button>
                            <Button onClick={()=> this.props.onClose()} width="35%" background={"#1873F3"} color={"white"} >Entendido</Button>
                        </RowItemNoBorder>            
                    </Body>
                </Container>
            </Modal>
        )
    }
}


export default DetailModal;
