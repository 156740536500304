import React from 'react'
import {
  Container,
  ArticleImage,
  InfoContainer,
  InteractionContainer,
  NewArticleImage,
  OpacityWrapper,
  SubTitle,
  Title,
  TitleContainer,
  Type,
  UserName,
  ContainerButtons,
  TextHeaderContainer,
  TextHeader,
  DateHeaderContainer,
  DateHeader
} from './styled' 
import { getFormattedDate } from '../../utils/dateParser'
import PdfIcon from '../../assets/icons/uploadpdf.png'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import { Player, BigPlayButton  } from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css";
 
class HomeBaseContent extends React.Component {
  renderBaseInfo = () => {
    const { userName, type, video,userImage, onClick,eventsdocumenturl } = this.props
    return (
      <InteractionContainer onClick={onClick}>
        <InfoContainer>
          <NewArticleImage src={userImage} type={'cover'} />
          <TitleContainer>
            <UserName>{userName}</UserName>
            <Type>{type}</Type>
          </TitleContainer>
        </InfoContainer>
       
      </InteractionContainer>
    )
  }

  renderMainImage = () => {
    const { title, subTitle, articleImage, withoutBaseInfo } = this.props
    return (
      <ArticleImage src={articleImage}>
        {!withoutBaseInfo && (
          <OpacityWrapper>
            <SubTitle>{subTitle}</SubTitle>
            <Title>{title}</Title>
          </OpacityWrapper>
        )}
      </ArticleImage>
    )
  }

  renderTextHeader = () => (
    <TextHeaderContainer>
      <TextHeader>{this.props.textHeaderContent}</TextHeader>
    </TextHeaderContainer>
  )

  downloadBlob=()=> {
    document.getElementById('download').click();
  }

  renderDateHeader = () => (
    <DateHeaderContainer>
      <div>
        <DateHeader>Programado desde {getFormattedDate(this.props.date)}</DateHeader>
        {this.props.publicationendshowingdate ? <DateHeader> hasta {getFormattedDate(this.props.publicationendshowingdate)}</DateHeader> : null}
      </div>
      <ContainerButtons>
       {
        this.props.document ? (
          <button
            onClick={()=> this.downloadBlob()}
            style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer' }}
          > 
            <img src={PdfIcon} style={{ width: '50px' }} />
          </button>
        ) : (<></>)
       } 
        <button
          onClick={this.props.onEdit}
          style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer' }}
        >
          <EditIcon style={{ color: 'grey' }} />
        </button>
        <button
          onClick={this.props.onDelete}
          style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer' }}
        >
          <DeleteIcon style={{ color: 'grey' }} />
        </button>
      </ContainerButtons>
    </DateHeaderContainer>
  )

  render() {
    const { extended, children, articleImage, withoutBaseInfo, date } = this.props
    return (
      <Container>
        {date && this.renderDateHeader()}
        {!articleImage && this.renderTextHeader()}
        {articleImage && this.renderMainImage()}
        {!withoutBaseInfo && this.renderBaseInfo()}
        {extended && children}
      </Container>
    )
  }
}

export default HomeBaseContent
