import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../assets/icons/close.svg';

const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalContainer = styled.div`
  z-index: 3;
  overflow: ${({ scroll }) => scroll ? scroll : "auto"};
  width:  ${({ width }) => width ? width : "70%"};
  background: ${({ background }) => background ? background : "#ffffff;"}; 
  height: ${({ height }) => (height ? height : "auto")};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  transition: all 0.2s ease;
  padding: ${({ padding }) => padding ? padding : "3%"};
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ minheight }) => minheight && `min-height: ${minheight}; height: unset;`}
  ${({ minwidth }) => minwidth && `min-width: ${minwidth}; width: unset;`}
  ${({ minheightWD }) => minheightWD && `min-height: ${minheightWD}`}
  ${({ minwidthWD }) => minwidthWD && `min-width: ${minwidthWD}`}
  `
  // ${({ maxheightWD }) => maxheightWD && `min-height: ${maxheightWD}`}
  // ${({ maxwidthWD }) => maxwidthWD && `min-width: ${maxwidthWD}`}
const Body = styled.div`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
`
const Close = styled.img`
  width: 21px;
  height: 21px;
  position: absolute;
  cursor: pointer;
  right: 6%;
  z-index: 99;
`
const Header = styled.div`
width:100%;
font-size: 22px;
font-weight: 500;
color: ${({ headerColor }) => (headerColor ? headerColor : '#30735f')};
align-self: center;
margin: 30px 0 10px 0;
padding-bottom:10px;
border-bottom:1px solid #d2d2d2;
`


const Modal = (props) => {
  return (
    <GrayedArea>
      <ModalContainer {...props}>
        {props.title ?
          <Header headerColor={props.headerColor}>{props.title}</Header>
          : null}
        {!props.isLoading ? 
        props.onCloseWithColor?<Close style={{backgroundColor: "darkgreen",color: "white", border:1+"px solid darkgreen", borderRadius: 999+"px"}} src={CloseIcon} onClick={() => props.onClose()}></Close>:
        <Close src={CloseIcon} onClick={() => props.onClose()}></Close> : <></>}
        <Body>
          {props.children}
        </Body>
      </ModalContainer>
    </GrayedArea>
  );
}

export default Modal