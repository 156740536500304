import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 50%;
  height: 40%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.ghost};
`

export const CrossWrapper = styled.div`
  text-align: end;
`

export const ErrorWrapper = styled.div`
  text-align: end;
  flex-direction: column;
  display: flex;
`

export const SectionTitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 28px;
  font-weight: 700;
  margin: 0px 0px 4px 8px;
  color: darkgreen;
  font-weight: 300;
`

export const SectionError = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px 5px 0px;
  text-align: center;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 32px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  width: 150px;
  align-self: flex-end;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 20px 20px 0px 20px;
`
export const SelectContainer = styled.div`
  width: 100%;
  height: 20%;
  margin-bottom: 25px;
`
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 50px;
  padding-top: 40px;
`

export const ColumnDataContainer = styled.div`
  width: 99%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: -1%;
`

export const RowDataContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #DCDCDC;
  margin:10px;
`
export const RowDataTitle = styled.div`
  font-family: 'Roboto',sans-serif;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  font-weight: 2000;
  padding-bottom: 4px;
`
export const RowDataInfo = styled.div`
  font-family: 'Roboto',sans-serif;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  color: ${({ color }) => (color ? color : 'black')};
  font-weight: 2000;
  padding-bottom: 4px;
`
export const StatusBadgeWithoutBorder = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  color: ${({ color }) => (color ? color : 'white')};
  font-weight: 2000;
`

export const MessageInfo = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
`

export const ButtonContainer = styled.div`
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
export const LastRowDataContainer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin:0px 10px 10px 10px;
`
