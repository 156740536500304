import React from 'react';
import styled from 'styled-components';
import Icon from '../assets/icons/search.svg'

const StyledInput = styled.input`
  min-width:${props => (props.minWidth ? props.minWidth : '230px')} ;
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  border: ${props => (props.border ? props.border : 'solid 1px hsl(0,0%,80%)')} ;
  padding: 5px 8px;
  font-size:${props => (props.fontSize ? props.fontSize : '14px')} ;
  ${({ search }) => search && `
    padding: 5px 0px 5px 35px;
  `}
  ${({ fullwidth }) => fullwidth && `
    width: 100%;
  `} 

  width: ${props => (props.width ? props.width : 'auto')} ;
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-family: ${({ theme }) => theme.fonts.regular};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledGray};
    border: solid 2px ${({ theme }) => theme.colors.alto};
  }
`

const Container = styled.div`
 position: relative;
`

const ImgStyled = styled.img`
  position: absolute;
  left: 0;
  top: 8px;
  padding: 2.5px 10px;
  color: #aaa;
  transition: 0.3s;
`

const Input = props => props.search === true ? <Container><ImgStyled src={props.icon} /><StyledInput {...props} /></Container> : <StyledInput {...props} />

export default Input