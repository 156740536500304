import React, { Component } from 'react'
import {
  Container,
  LabelContainer,
  DetailContainer,
  DetailItemContainer,
  DetailContainerError,
  DetailItem,
  Row,
  StyledButton,
  Title,
  Subtitle,
  Column,
  IconCopy,
  Link
} from './styled';
import Button from '../../../../components/Button';
import copyClipboard from '../../../../assets/icons/copy.svg';
import doubleCheck from '../../../../assets/icons/check.png';
import Loader from "../../../../components/Loader";



var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS"});

class SecondStep extends Component {
  state={
    copySuccess: false
  }
  copyToClipboard(e) {
    var textField = document.createElement('textarea')
    textField.innerText = this.props.billetera.PaymentPayMi.url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    this.setState({copySuccess: true});
};

  render() {
    return (
      <Container>
          {
            this.props.billetera.loadingPaymentPayMi ?
              <DetailItemContainer centered={true}>
                <Loader />
              </DetailItemContainer>
              :
              this.props.billetera.PaymentPayMi ?
              <>
                <Title>¡Listo! Empezá a cobrar</Title>
                <DetailContainer>
                  <Row>
                    <LabelContainer>Ahora, compartí tu link de pago como quieras</LabelContainer>
                    <Subtitle>¡Es fácil y rápido!</Subtitle>
                  </Row> 
                  <Column onClick={()=>this.copyToClipboard()}>
                    <Link>{this.props.billetera.PaymentPayMi.url}/</Link>
                    {this.state.copySuccess ?
                      (
                        <StyledButton onClick={()=>this.copyToClipboard()}>¡Copiado! <IconCopy src={doubleCheck}/></StyledButton>
                      )
                      :
                      (
                        <StyledButton onClick={()=>this.copyToClipboard()}>Copiar <IconCopy src={copyClipboard}/></StyledButton>
                    )}

                  </Column>
                  <DetailItemContainer>
                    <DetailItem> Concepto: </DetailItem>
                    <DetailItem bold={true} > {this.props.transaction.description} </DetailItem>
                  </DetailItemContainer>
                  <DetailItemContainer>
                    <DetailItem> Importe a cobrar: </DetailItem>
                    <DetailItem bold={true} >{formatter.format(this.props.transaction.total_amount)} </DetailItem>
                  </DetailItemContainer>
                </DetailContainer>
              </>
                :
                <DetailContainerError centered={true}>
                  {this.props.billetera.errorPaymentPayMiMessage}
                </DetailContainerError>
          }


          
        {/*
            <DetailContainer centered={true}>
              <DetailItemContainer>
                <DetailItem bold={true} margin={'5% 9%;'}  > Tambien podes compartir el botón de pago al e-mail y a la App del destinatario </DetailItem>
                <Button invert={true}>Enviar botón de pago </Button> 
              </DetailItemContainer>  
            </DetailContainer>*/}
            <br/>
        <Button onClick={() => this.props.BackStep()} >{'Volver'}</Button>
        
      </Container>
    )
  }
}

export default SecondStep
