
import { createSelector } from 'reselect'

/*

expensesCabecera: Array(1)
  0:
    expenses_header:
    details: Array(4)
      0:
        amount: 15800.15
        client_id: 1176
        client_name: "Valeria Perez" 
        debit_account_cvbu: null
        id: 434
        neighborhood_name: "Los Aromos"
        property_code: "15"
        status_code: "PENDING"

*/

const mapExpensesDetail = createSelector(
  data => data,
  data => data.expense_list.map( (e, i) =>
        e.details.map((detalle, i) =>
          ({
            full_name: detalle.client_full_name,
            cuit: detalle.client_cuit,
            amount: detalle.currency + ' ' + detalle.amount,
            currency: detalle.currency,
            payment_date: detalle.payment_date,
            property_code: detalle.property_code,
            neighbourhood_name: detalle.neighbourhood_name,
          }
          )
        )
    )
)

export default mapExpensesDetail