import React from 'react'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
const TitleContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 15px;
`

class DetailTitle extends React.Component {
  render(){
    return(
      <div>
        {this.props.edit == true &&
            <StyledTitle>
            <TitleContainer>
              {this.props.children}
            </TitleContainer>
            <button
              onClick={this.props.onEdit}
              style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer', margin: "0% 0% 0% 55%"  }}
            >
              <EditIcon style={{ color: '#1873f3' }} />
            </button>
          </StyledTitle>
        || this.props.remove == true &&
            <StyledTitle>
            <TitleContainer>
              {this.props.children}
            </TitleContainer>
            <button
              onClick={this.props.onDelete}
              style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer', margin: "0% 0% 0% 55%"  }}
            >
              <DeleteIcon style={{ color: '#1873f3' }} />
            </button>
          </StyledTitle>
        || this.props.RemoveEdit == true &&
              <StyledTitle>
                <TitleContainer>
                  {this.props.children}
                </TitleContainer>
                <button
                  onClick={this.props.onEdit}
                  style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer', margin: "0% 0% 0% 50%" }}
                >
                  <EditIcon style={{ color: '#1873f3' }} />
                </button>
                <button
                  onClick={this.props.onDelete}
                  style={{ background: 'none', borderWidth: 'inherit', cursor: 'pointer',  }}
                >
                  <DeleteIcon style={{ color: '#1873f3' }} />
                </button>
              </StyledTitle> 
        ||
        <StyledTitle>
          <TitleContainer>
            {this.props.children}
          </TitleContainer>
        </StyledTitle >
        }
      </div>
    )
  }
}

export default DetailTitle
