import React from 'react';
import styled from 'styled-components';
import Icon from '../../../../assets/icons/search.svg'

const StyledInput = styled.input`
  width: 100%;
  margin: ${({ margin }) => margin};
  font-size: 1.2em;
  box-sizing: border-box;
  height: 50px;
  border-radius: 6px;
  border: solid 1px hsl(0,0%,95%);
  padding: 5px 8px;
  ${({ search }) => search && `
    padding: 5px 0px 5px 35px;
  `}
  ${({ fullwidth }) => fullwidth && `
    width: 100%;
  `}
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-family: ${({ theme }) => theme.fonts.regular};
    font-style: italic;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledGray};
    border: solid 2px ${({ theme }) => theme.colors.alto};
  }
`

const Container = styled.div`
 position: relative;
`

const ImgStyled = styled.img`
  position: absolute;
  left: 0;
  top: 20%;
  padding: 2.5px 10px;
  color: #30735F;
  transition: 0.3s;
`

const SearchInput = props => props.search === true ? <Container><ImgStyled src={Icon}/><StyledInput {...props} /></Container> : <StyledInput {...props} />

export default SearchInput;