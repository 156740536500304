import { createSelector } from 'reselect'

const mapBookingsComboSpacesToBlock = createSelector(
  spaces => spaces,
  spaces =>
  spaces.map(spaces => ({
      label: spaces.name,
      value: spaces.id
    }))
)

export default mapBookingsComboSpacesToBlock
