import React from 'react';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import DatePickerStyled from '../../../components/DatePickerStyled'
import Select from 'react-select';
import Button from '../../../components/Button';
import mapBookingReport from '../../../selectors/mapBookingReport'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import mapCategoriesByRole from '../../../selectors/mapCategoriesByRole'
import mapSpaces from '../../../selectors/mapSpaces';
import { fetchActivities } from '../../../store/actions/activities.actions'
import { fetchReporteReservas } from '../../../store/actions/reportes.actions'
import { fetchSpaces } from '../../../store/actions/spaces.actions'
import { setNeighbourhood } from "../../../store/actions/userInfo.actions";
import {
  FormContainer, Row, InputWrapper, Label, InputLabel, ErrorLabel
} from '../styled';

class Reservas extends React.Component {

  state = {
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,
    properties: [],
    categories: [],
    types: [],
    typesEnabled: false,
    neighbourhoodSelected: {
      value: this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid: this.props.selectedNeighbourhood.guid
    },
  }

  componentDidMount=()=>{
    let formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      let neighbourhood = {
        value: item.name,
        label: item.name,
        guid: item.guid
      };
      return neighbourhood;
    });

    this.setState({
      neighbourhoods: formattedNeighbourhoods,
    },this.refreshData());
  }
  
  refreshData= () => {
    this.fetchContent()
  }


  getInitialValues = (d) => ({
    property: '',
    category: '',
    type: '',
    startDate: d,
    endDate: new Date(),
  })

  handleActivitiesSelection = (setFieldValue, values) => {
    setFieldValue('activity', values);
    if (values.value == '') {
      // setFieldValue('space', this.state.types[0]);
      this.setState({ spaceEnabled: false });
    }
    else {
      this.props.fetchSpaces(values.value.id);
      this.setState({ spaceEnabled: true });
    }
  }

  handleSubmit = data => {
    const formattedBody = {
      spaceId: data.space ? data.space.value.id : "",
      activityId: data.activity ? data.activity.value.id : "",
      startDate: data.startDate,
      endDate: data.endDate
    }
    this.props.fetchReporteReservas(formattedBody)
  }

  handleChange = neighbourhoodSelected => {
    this.props.setNeighbourhood(neighbourhoodSelected);
    this.setState({ neighbourhoodSelected: neighbourhoodSelected },this.refreshData());
  };

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        // activity: Yup.string().required('Ingrese una Actividad'),
        // space: Yup.string().required('Ingrese un Espacio'),
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha')
      })
    )

  onChangeCategory(setFieldValue, values) {
    setFieldValue('category', values);
    if (values.value == '') {
      setFieldValue('type', this.state.types[0]);
      this.setState({ typesEnabled: false });
    }
    else {
      this.props.fetchTypesByCategory(values.value);
      this.setState({ typesEnabled: true });
    }
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
    {console.log(values)}
      <Row>
      <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Barrio'}</InputLabel>
            <Select
                value={this.state.neighbourhoodSelected}
                onChange={this.handleChange}
                options={this.state.neighbourhoods}
              />
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Actividad'}</InputLabel>
          <Select
            name={'activity'}
            value={values.activity}
            isSearchable={false}
            error={errors.activity}
            onChange={values => this.handleActivitiesSelection(setFieldValue, values)}
            options={this.props.formattedActivities}
            placeholder={this.props.activities.isFetching ? "Cargando " : "Seleccione una actividad..."}
            isLoading={this.props.activities.isFetching}
            isDisabled={this.props.activities.isFetching}
            autoFocus
          />
          {errors.activity && <ErrorLabel>{errors.activity}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Espacio'}</InputLabel>
          <Select
            name={'space'}
            value={values.space}
            isSearchable={false}
            onChange={values => setFieldValue('space', values)}
            error={errors.space}
            options={this.props.formattedSpaces}
            placeholder={this.props.spaces.isFetching ? "Cargando" : "Seleccione un espacio..."}
            isLoading={this.props.spaces.loadingSpaces}
            isDisabled={this.props.spaces.isFetching || !this.state.spaceEnabled}
            autoFocus
          />
          {errors.space && <ErrorLabel>{errors.space}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row align="flex-end">
        <InputWrapper margin='20px 15px'>
          <InputLabel>{'Desde'}</InputLabel>
          <DatePickerStyled
            selected={values.startDate }
            startDate={values.startDate  }
            endDate={values.endDate}
            onChange={startDate => setFieldValue('startDate', startDate)}
            selectsStart
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            placeholderText="Fecha desde"
            width="100%"
            padding="8px 1%"
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
          {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Hasta'}</InputLabel>
          <DatePickerStyled
            selected={values.endDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={endDate => setFieldValue('endDate', endDate)}
            minDate={this.state.startDate || null}
            selectsEnd
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            placeholderText="Fecha hasta"
            width="100%"
            padding="8px 1%"
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
          {errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputWrapper margin={'20px 15px'}>
            <Button type={'submit'} medium width={'100%'}>
              {'Filtrar'}
            </Button>
          </InputWrapper>
        </InputWrapper>
      </Row>
    </FormContainer>
  );


  fetchContent = () => {
    this.props.fetchActivities()
  }

  render() {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return (
      <>
        <SectionHeader title='Reservas'/>
        <Formik
          initialValues={this.getInitialValues(d)}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
        <RitzTable
          columns={[
            { title: 'Fecha', field: 'Date', cellStyle: { borderRadius: '6px 0px 0px 6px' } },
            { title: 'Usuario', field: 'host_username' },
            { title: 'Desde', field: 'from' },
            { title: 'Hasta', field: 'to' },
            { title: 'Precio', field: 'price' },
            { title: 'Actividad', field: 'Activity' },
            { title: 'Espacio', field: 'Space' },
          ]}
          options={{
              toolbar: true,
              selection: false,
              search: false,
              paging: true,
              serverSide: false,
              exportButton: true,
              exportAllData: true,
              exportFileName: "Listado Reservas ",
          }}
          data={this.props.reporteReservas}
        />
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    tickets: state.tickets,
    lotes: state.lotes,
    categories: mapCategoriesByRole(state.tickets.categoriesList),
    reportes: state.reportes,
    spaces: state.spaces,
    activities: state.activities,
    formattedActivities: ((state.activities == null || state.activities.values == null) ? [] : mapSpaces(state.activities.values).concat({label: "Todas",value: ""}).reverse()),
    formattedSpaces: (state.spaces.spacesList.length === 0 ? [] : mapSpaces(state.spaces.spacesList).concat({label: "Todos",value: ""}).reverse()),
    reporteReservas: ((state.reportes.reporteReservas == null || state.reportes.reporteReservas == null) ? [] : mapBookingReport(state.reportes.reporteReservas)),
    loadingReporteReserva: state.reportes.loadingReporteReserva,
    neighbourhoods: state.userInfo.neighbourhoods,
    selectedNeighbourhood: state.userInfo.selectedNeighbourhood,
    // formattedActivities: ((state.activities. == null || activities.values == null) ? [] : mapSpaces(activities.values)),

  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSpaces,
      fetchActivities,
      fetchReporteReservas,
      setNeighbourhood
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Reservas)