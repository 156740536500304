import React from 'react'
import styled, { css } from 'styled-components'
import DatePicker from "react-datepicker"
require('react-datepicker/dist/react-datepicker.css')

const StyledPicker = styled(DatePicker)`
  width: ${({ width }) => (width ? width : '100%')}; 
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')}; 
  font-family: ${({ theme }) => theme.fonts.roboto};
  padding: ${({ padding }) => (padding ? padding : '5% 1%')};  
  text-align: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: ${({ bold }) => bold ? 600 : 400};
  color: ${({ theme }) => theme.colors.black};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  outline: none;
  display:block !important;
  

  ${({ borderBottom }) =>
    borderBottom
      ? css`
          border-top-left-radius: 10px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border: 2px solid ${({ theme }) => theme.colors.transparent};
          border-bottom: 2px solid ${({ theme }) => theme.colors.alto};
        `
      : css`
          border: 2px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.alto)};
          border-radius: 4px;
        `}


  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.simple};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.simple};
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledGray};
    border: solid 2px ${({ theme }) => theme.colors.alto};
  }
`

const DatePickerStyled = props => <StyledPicker {...props} />

export default DatePickerStyled
