/* eslint-disable react/prop-types */
import React from "react";
import CrossIcon from "../../../assets/icons/close.svg";
// import { deleteReservationById, generateBooking } from 'store/actions/reservas.actions';
import theme from "../../../config/theme";

import {
  DetailContainer,
  Title,
  SubTitle,
  Name,
  Row,
  CrossImage,
  Ahref,
  AhrefWrapper
} from "./styled";

export default function BookingDetailModal(props) {
  return (
    <DetailContainer leftPopUp={props.leftPopUp} bottomPopUp={props.bottomPopUp}>
      <CrossImage onClick={() => props.onClose(false)} src={CrossIcon} />
      <Title>
        <SubTitle>Reservado por:</SubTitle>
      </Title>
      <Name marginBottom={"10px"}>{props.booking.full_name}</Name>
      <br />
      <Title marginBottom={"10px"}>
        <SubTitle>Teléfono:</SubTitle> <Name>{props.booking.phone_number}</Name>
      </Title>
      <Title marginBottom={"10px"}>
        <SubTitle>Lote:</SubTitle>
        <Name>{props.booking.property_code}</Name>
      </Title>
      <Title marginBottom={"10px"}>
        <SubTitle>Importe: $</SubTitle> <Name>{props.originalData.price}</Name>
      </Title>
      <Title marginBottom={"10px"}>
        <Name>
          <SubTitle>Desde</SubTitle> {props.originalData.from} <SubTitle>hasta</SubTitle>{" "}
          {props.originalData.to}
        </Name>
      </Title>
      <Title marginBottom={"10px"}>
        <Name>
          <SubTitle>Descripción:</SubTitle> {props.booking.description}
        </Name>
      </Title>
      <AhrefWrapper margin="0">
        <Ahref color={theme.colors.red} onClick={props.cancelFunc}>
          Cancelar Reserva
        </Ahref>
      </AhrefWrapper>
      {props.booking.is_admin_booking ? (
        <div>
          <Row>
            <Title>{"A nombre de:"}</Title>
          </Row>
          <Name marginBottom={"10px"}>
            {props.booking.name_booking + " " + props.booking.surname_booking}
          </Name>
          {props.booking.observations != null ? (
            <div>
              <Row>
                <Title>{"Observaciones:"}</Title>
              </Row>
              <Name marginBottom={"10px"}>
                {props.booking.observations != null ? props.booking.observations : ""}
              </Name>
            </div>
          ) : (
            ""
          )}
          {props.booking.reason != null ? (
            <div>
              <Row>
                <Title>{"Motivo:"}</Title>
              </Row>
              <Name marginBottom={"10px"}>
                {props.booking.reason != null ? props.booking.reason : ""}
              </Name>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}
      {/* <CancelButton type="button" onClick={() => toggleDeleteReserva(props.originalData.id)} >
      Bloquear Turno
    </CancelButton> */}
    </DetailContainer>
  );
}
