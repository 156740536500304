/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import MenuItems from "../../components/MenuItems";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Recibidas from "./Recibidas/index";
import Mensajes from "./Mensajes/index";
import TelefonosGuardia from "./TelefonosGuardia/index";
import Habilitadas from "./Habilitadas";
import { fetchAlerts } from "../../store/actions/alerts.actions";

const mapStateToProps = state => {
  const { receivedAlerts, loadingAlerts } = state.alerts;

  const { user } = state.auth;
  return {
    isLoading: loadingAlerts,
    receivedAlerts: receivedAlerts,
    user: user
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchAlerts }, dispatch);

function Alertas(props) {
  const { receivedAlerts, user } = props;

  const RECEIVED_VIEW = "Recibidas";
  const GUARD_PHONES_VIEW = "Telefonos de Guardia";
  const MESAGGES_VIEW = "Mensajes";
  const ENABLED_VIEW = "Alertas Habilitadas";

  const [view, setView] = useState("Recibidas");
  const [neighbourhoodDropValue, setNeighbourhoodDropValue] = useState({
    label: "Todos",
    value: "00000000-0000-0000-0000-000000000000"
  });

  const getNeighbourhoods = () => {
    let userNeigh = user.neighbourhoods;
    var neighs = userNeigh
      .map(n => ({ label: n.name, value: n.guid }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    neighs.unshift({ label: "Todos", value: "00000000-0000-0000-0000-000000000000" });

    return neighs;
  };

  let neighbourhoods = getNeighbourhoods();

  useEffect(() => {
    if (user.neighbourhoods.length === 1)
      setNeighbourhoodDropValue({
        label: user.neighbourhoods[0].name,
        value: user.neighbourhoods[0].guid
      });

    if (neighbourhoodDropValue.value === "00000000-0000-0000-0000-000000000000") {
      props.fetchAlerts(neighbourhoods.map(x => x.value));
    } else {
      props.fetchAlerts(user.neighbourhoods[0].guid)
    }
  }, []);

  const newAlerts = receivedAlerts.filter(
      receivedAlert => receivedAlert.alert_state === "En alerta"
    ),
    cantAlerts = newAlerts.length;

  var menulistItems = [
    { title: "Recibidas", route: RECEIVED_VIEW, available: true, quantity: cantAlerts },
    { title: "Teléfonos de guardia", route: GUARD_PHONES_VIEW, available: true },
    { title: "Mensajes", route: MESAGGES_VIEW, available: true },
    { title: "Alertas Habilitadas", route: ENABLED_VIEW, available: true }
  ];

  //  Items del menú
  const menuListComponent = menulistItems.map((item, key) => (
    <MenuItems
      quantity={item.quantity}
      onClick={item.available ? () => setView(item.route) : null}
      active={view === item.route}
      disabled={!item.available}
      key={key}
    >
      {item.title}
    </MenuItems>
  ));

  // Devuelvo la vista en base a la selección en el menú
  const selectedView = (() => {
    switch (view) {
      case RECEIVED_VIEW:
        return (
          <Recibidas
            selectedNeighbourhood={neighbourhoodDropValue}
            setNeighbourhoodDropValue={setNeighbourhoodDropValue}
            neighbourhoods={neighbourhoods}
          />
        );
      case GUARD_PHONES_VIEW:
        return (
          <TelefonosGuardia
            selectedNeighbourhood={neighbourhoodDropValue}
            setNeighbourhoodDropValue={setNeighbourhoodDropValue}
            neighbourhoods={neighbourhoods}
          />
        );
      case MESAGGES_VIEW:
        return (
          <Mensajes
            selectedNeighbourhood={neighbourhoodDropValue}
            setNeighbourhoodDropValue={setNeighbourhoodDropValue}
            neighbourhoods={neighbourhoods}
          />
        );
      case ENABLED_VIEW:
        return <Habilitadas />;
      default:
        return (
          <Recibidas
            selectedNeighbourhood={neighbourhoodDropValue}
            setNeighbourhoodDropValue={setNeighbourhoodDropValue}
            neighbourhoods={neighbourhoods}
          />
        );
    }
  })();

  return (
    <NavBar menuListComponent={menuListComponent} title="Alertas">
      {selectedView}
    </NavBar>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Alertas);
