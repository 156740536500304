import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBack'

export const ProgressContainer = styled.div`
    flex-grow: 1;
`

export const ButtonBack = styled.button`
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    margin: ${({ theme }) => theme.spacing.unit2  } !important;
    background-color: ${({ theme }) => theme.colors.white } !important;

`
export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin: ${({ theme }) => theme.spacing.unit };

`

export const ContainerProgressBar = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin-top: ${({ theme }) => theme.spacing.unit };

`


export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.blueRibbon };
    background-color: ${({ theme }) => theme.colors.white };
`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey };
    font-size: 20px;
    margin-top: 10px;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
`
export const SelectWrapper = styled.div`
    width: 100%;
    margin: 20px 0px 20px 0px;
`
export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };
    display:flex;
    flex-direction: row;
    justify-content: flex-start;  

`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 65px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 30px 0px 0px 100px;
`


export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const LabelChecked = styled.label`
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')}
`


export const DestinatarioDetailContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin-top: ${({ theme }) => theme.spacing.unit };
`

export const DestinatarioDetailItemContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
export const DestinatarioDetailItem = styled.div`
    font-style: 15px;
`

export const PaperLayout = styled.div`
    display:flex;
    flex-direction: row;
    width: 50%;
    align-self: center;
    padding: 2%;
    border-radius: 3%;
    background-color: #FFFFFF;
`