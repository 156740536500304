import React from 'react';
import NavBar from '../../components/NavBar';
import MenuItems from '../../components/MenuItems';
import GenerarQR from './GenerarQR/index';
import MiiiiBilletera from './MiiiiBilletera/index';
import Transferir from './Transferir/index';
import ImprimirQR from './PrintQR';
import AuthMovements from './AuthMovements';
import LinkPago from './LinkPago';
import Summary from "./Summary"

const menulistItems = [
  { title: 'Vista General', route: 'miiii', available: true },
  { title: 'Autorizariones Pendientes', route: 'AuthMovs', available: true },
  { title: 'Transferir', route: 'transferir', available: true },
  { title: 'Generar nuevo QR', route: 'generarQR', available: true },
  { title: 'Generar Link de pago', route: 'LinkPago', available: true },
  { title: 'Imprimir QR', route: 'imprimirQR', available: true },  
  { title: 'Extracto', route: 'summary', available: true }, 
]


class Billetera extends React.Component {

  state = {
    search: '',
    view: 'miiii'
  };

  buildNavBarItems = () => {
    const menuToRender = menulistItems.map((item, key) => <MenuItems quantity={item.quantity} onClick={item.available ? () => this.switchView(item.route) : null} active={this.state.view === item.route} disabled={!item.available} key={key}>{item.title}</MenuItems>)
    return menuToRender;
  }

  switchView = (view) => {
    this.setState({ view });
  }


  render() {
    return (
      <NavBar menuListComponent={this.buildNavBarItems()} switchView={() => this.switchView()} title='Billetera'>
        {(() => {
          switch (this.state.view) {
            case 'home':
              return (
                <MiiiiBilletera {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'generarQR':
              return (
                <GenerarQR {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
            case 'transferir':
              return (
                <Transferir {...this.state}

                  switchView={this.switchView}
                />
              );
            case 'imprimirQR':
              return (
                <ImprimirQR {...this.state} />
              );

            case 'AuthMovs':
              return (
                <AuthMovements onClose={this.switchView} {...this.state} />
              );

            case 'LinkPago':
              return (
                <LinkPago onClose={this.switchView} {...this.state} />
              );

            case 'summary':
              return (
                <Summary onClose={this.switchView} {...this.state} />
              );
            default:
              return (
                <MiiiiBilletera {...this.state}
                  toggleItemDelete={this.toggleItemDelete}
                  toggleItemDetail={this.handleDetailProduct}
                  searchHandleChange={this.searchHandleChange}
                  switchView={this.switchView}
                />
              );
          }
        })()}
      </NavBar>
    );
  }
}


export default Billetera



