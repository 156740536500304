/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from "../../../../../assets/icons/close.svg";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import StyledSelect from "../../../../../components/StyledSelect";
import TextArea from "../../../../../components/TextArea";
import DatePickerStyled from "../../../../../components/DatePickerStyled";
import Input from "../../../../../components/Input";
import RepetitionModal from "./RepetitionModal";
import RecurringScheduleModal from "./RecurringScheduleModal";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import StyledInputMask from "../../../../../components/StyledInputMask";
import theme from "../../../../../config/theme";
import DeleteIcon from "@material-ui/icons/Delete";
import { createSchedule, updateSchedule } from "../../../../../store/actions/bookings.actions";
import {
  ScheduleContainer,
  FormContainer,
  Container,
  CancelButton,
  Row,
  TitleModal,
  SaveButton,
  Close,
  CloseIconWrapper,
  ErrorLabel,
  ButtonWrapper,
  InputWrapper,
  FlexWrapper,
  InputLabel,
  Ahref,
  HeaderWrapper,
  LoaderWrapper,
  GrayedArea,
  DeleteIconWrapper,
  ErrorsWrapper,
  AhrefWrapper
} from "./styled";

const CANCELLED_SCHEDULE_TYPE = 2;
const RESCHEDULED_SCHEDULE_TYPE = 3;

const mapStateToProps = state => {
  const {
    errorCreateSchedule,
    loadingCreateSchedule,
    createScheduleSuccess,
    loadingFetchScheduleDetail,
    errorFetchScheduleDetail,
    fetchScheduleDetailSuccess,
    scheduleDetail,
    errorUpdateSchedule,
    loadingUpdateSchedule,
    updateScheduleSuccess
  } = state.bookings;

  return {
    errorCreateSchedule,
    loadingCreateSchedule,
    createScheduleSuccess,
    loadingFetchScheduleDetail,
    errorFetchScheduleDetail,
    fetchScheduleDetailSuccess,
    scheduleDetail,
    errorUpdateSchedule,
    loadingUpdateSchedule,
    updateScheduleSuccess
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createSchedule,
      updateSchedule
    },
    dispatch
  );

function ScheduleModal(props) {
  const {
    spaceId,
    createScheduleSuccess,
    isEdit,
    selectedCalendarDateTime,
    scheduleDetail,
    updateScheduleSuccess
  } = props;

  const [endDate, setEndDate] = useState(null);
  const [isSerie, setIsSerie] = useState(false);
  const [showAddRepetition, setShowAddRepetition] = useState(false);
  const [recurringType, setRecurringType] = useState(null);
  const [finishSerie, setFinishSerie] = useState(null);
  const [daysOfWeek, setDaysOfWeek] = useState(null);
  const [editSerie, setEditSerie] = useState(false);
  const [showRecurringScheduleModal, setShowRecurringScheduleModal] = useState(false);
  const [
    showCancelSerieRecurringScheduleModal,
    setShowCancelSerieRecurringScheduleModal
  ] = useState(false);
  const [
    showCancelIndividualRecurringScheduleModal,
    setShowCancelIndividualRecurringScheduleModal
  ] = useState(false);
  const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [recurringScheduleFormattedData, setRecurringScheduleFormattedData] = useState(null);
  const [hasRepetitionModalData, setHasRepetitionModalData] = useState(false);
  const [deleteScheduleModalData, setDeleteScheduleModalData] = useState(null);
  const availableShifts = [
    { label: "15 minutos", value: "00:15:00" },
    { label: "30 minutos", value: "00:30:00" },
    { label: "1 hora", value: "01:00:00" }
  ];

  useEffect(() => {
    if (createScheduleSuccess) {
      props.onClose();
      props.fetchSchedulesCalendar(spaceId, selectedCalendarDateTime);
    }
  }, [createScheduleSuccess]);

  useEffect(() => {
    if (updateScheduleSuccess) {
      props.onClose();
      props.fetchSchedulesCalendar(spaceId, selectedCalendarDateTime);
    }
  }, [updateScheduleSuccess]);

  const toggleShowAddRepetition = () => {
    setShowAddRepetition(!showAddRepetition);
  };

  const toggleShowRecurringScheduleModal = () => {
    setShowRecurringScheduleModal(!showRecurringScheduleModal);
  };

  const toggleShowDeleteScheduleModal = () => {
    setShowDeleteScheduleModal(!showDeleteScheduleModal);
  };

  const toggleShowCancelSerieRecurringScheduleModal = () => {
    setShowCancelSerieRecurringScheduleModal(!showCancelSerieRecurringScheduleModal);
  };

  const toggleShowCancelIndividualRecurringScheduleModal = () => {
    setShowCancelIndividualRecurringScheduleModal(!showCancelIndividualRecurringScheduleModal);
  };

  const getFormContent = ({ handleSubmit, values, setFieldValue, errors, myFormRef }) => {
    return (
      <FormContainer ref={el => (myFormRef = el)} onSubmit={handleSubmit}>
        <FlexWrapper>
          <Row>
            <InputWrapper width={"40"}>
              <InputLabel>El día</InputLabel>
              <DatePickerStyled
                selected={values.startDate}
                startDate={values.startDate}
                onChange={value => onStartDateChange(setFieldValue, value)}
                selectsStart
                dateFormat={"dd/MM/yyyy"}
                placeholderText={"A partir de..."}
                padding={"8px 1%"}
                popperPlacement="bottom"
                disabled={isEdit}
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                  }
                }}
              />
            </InputWrapper>
            <InputWrapper width={"30"}>
              <InputLabel>Inicio</InputLabel>
              <StyledInputMask
                mask={"99:99"}
                value={values.from}
                width={"100%"}
                placeholder={"HH:MM"}
                onChange={({ target: { value } }) => onFromChange(setFieldValue, value)}
                maskChar={null}
                disabled={false}
              />
            </InputWrapper>
            <InputWrapper width={"30"}>
              <InputLabel>Fin</InputLabel>
              <StyledInputMask
                mask={"99:99"}
                value={values.to}
                width={"100%"}
                placeholder={"HH:MM"}
                onChange={({ target: { value } }) => onToChange(setFieldValue, value)}
                maskChar={null}
                disabled={false}
              />
            </InputWrapper>
          </Row>
          {(errors.to || errors.startDate || errors.from) && (
            <Row>
              <ErrorsWrapper>
                {errors.to && <ErrorLabel>{errors.to}</ErrorLabel>}
                {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                {errors.from && <ErrorLabel>{errors.from}</ErrorLabel>}
              </ErrorsWrapper>
            </Row>
          )}
          {!isEdit ? (
            <Row>
              <InputWrapper>
                <Field
                  type="checkbox"
                  name="hasAppointments"
                  checked={values.hasAppointments}
                  onChange={() => onHasAppointmentsChange(setFieldValue, !values.hasAppointments)}
                  style={{ background: theme.colors.midGray }}
                />
                <InputLabel display={"inline"}>Turnos de </InputLabel>
                <StyledSelect
                  value={values.shift}
                  width={"50%"}
                  onChange={value => setFieldValue("shift", value)}
                  options={availableShifts}
                  placeholder="Seleccione un turno"
                  isDisabled={!values.hasAppointments}
                  isSearchable={false}
                />
              </InputWrapper>
            </Row>
          ) : null}
          {!isEdit || (isEdit && scheduleDetail && scheduleDetail.is_serie) ? (
            <Row justifyContent={"flex-start"}>
              <AhrefWrapper>
                <Ahref onClick={() => toggleShowAddRepetition()}>
                  {hasRepetitionModalData || isEdit ? "Editar Repetición" : "Agregar Repetición"}
                </Ahref>
              </AhrefWrapper>
              {hasRepetitionModalData && !isEdit && (
                <DeleteIconWrapper onClick={() => clearRepetitionModalData()}>
                  <DeleteIcon style={{ height: "20px", color: theme.colors.midGray }}></DeleteIcon>
                </DeleteIconWrapper>
              )}
            </Row>
          ) : null}
          <Row>
            <InputWrapper width={"50"}>
              <InputLabel>Precio</InputLabel>
              <Input
                value={values.price}
                minWidth={"50%"}
                width={"100%"}
                placeholder={"Ingrese el precio"}
                onChange={({ target: { value } }) => setFieldValue("price", value)}
              />
            </InputWrapper>
            <InputWrapper width={"50"}>
              <InputLabel>Cupos reservables</InputLabel>
              <Input
                value={values.availablePlaces}
                minWidth={"50%"}
                width={"100%"}
                placeholder={"Ingrese el los espacios disponibles"}
                onChange={({ target: { value } }) => setFieldValue("availablePlaces", value)}
              />
            </InputWrapper>
          </Row>
          {(errors.price || errors.availablePlaces) && (
            <ErrorsWrapper>
              {errors.price && <ErrorLabel>{errors.price}</ErrorLabel>}
              {errors.availablePlaces && <ErrorLabel>{errors.availablePlaces}</ErrorLabel>}
            </ErrorsWrapper>
          )}
          <Row>
            <InputWrapper>
              <InputLabel>Descripción</InputLabel>
              <TextArea
                height={"4.5em"}
                name={"descriptionInput"}
                width={"100%"}
                label={"descriptionInput"}
                placeholder={"Ingrese una descripción..."}
                value={values.description}
                onChange={({ target: { value } }) => setFieldValue("description", value)}
                margin="normal"
              ></TextArea>
            </InputWrapper>
          </Row>
          {isEdit ?
            < Row >
              <InputWrapper>
                <Ahref
                  color={theme.colors.red}
                  onClick={() => handleCancelSubmitType(values)}
                >Eliminar Horario</Ahref>
              </InputWrapper>
            </Row>
            : null}
          {!props.loadingCreateSchedule && !props.createScheduleSuccess && props.errorCreateSchedule ? (
            <ErrorLabel> {props.errorCreateSchedule}</ErrorLabel>
          )
            : null
          }
          {!props.loadingUpdateSchedule && !props.updateScheduleSuccess && props.errorUpdateSchedule ? (
            <ErrorLabel> {props.errorUpdateSchedule}</ErrorLabel>
          )
            : null
          }
          <Row alignItems={"end"} marginTop={"15px"}>
            <Button type={"button"} variant={"primary"} width={"8.8em"} onClick={props.onClose}>
              Cancelar
            </Button>
            <Button type={"submit"} width={"8.8em"}>
              Guardar
            </Button>
          </Row>
        </FlexWrapper>
      </FormContainer>
    );
  };

  const filterActiveDay = day => {
    if (day.flag) return day.value;
  };

  const handleCancelSubmitType = async data => {
    const formattedData = {
      space_id: props.spaceId,
      start_date: (new Date(data.startDate)).toISOString().split("T")[0],
      // end_date: endDate ? (endDate.toISOString().split('T')[0]) : (scheduleDetail.end_date ? new Date(scheduleDetail.end_date).toISOString().split('T')[0] : null),
      end_date: null,
      from: scheduleDetail.from,
      to: scheduleDetail.to,
      price: data.price,
      description: data.description,
      available_places: data.availablePlaces,
      recurring_type_id: recurringType
        ? recurringType.value
        : scheduleDetail.schedule_settings && scheduleDetail.schedule_settings.recurring_type_id
          ? scheduleDetail.schedule_settings.recurring_type_id
          : null,
      schedule_type_id: CANCELLED_SCHEDULE_TYPE
    };

    if (scheduleDetail.is_serie) {
      var formattedSerieData = {
        ...formattedData,
        // days_of_the_week: recurringType ? recurringType.value === 2 ? (daysOfWeek ? daysOfWeek.filter(filterActiveDay).map(day => day.value) : null) : [0, 1, 2, 3, 4, 5, 6] : scheduleDetail && scheduleDetail.schedule_settings ? scheduleDetail.schedule_settings.schedule_type_id == 1 ? [0, 1, 2, 3, 4, 5, 6] : (scheduleDetail.schedule_settings.days_of_week) : null,
        days_of_the_week: scheduleDetail && scheduleDetail.schedule_settings ? scheduleDetail.schedule_settings.schedule_type_id == 1 ? [0, 1, 2, 3, 4, 5, 6] : (scheduleDetail.schedule_settings.days_of_week) : null,
      }
      setRecurringScheduleFormattedData(formattedSerieData);
      toggleShowCancelSerieRecurringScheduleModal();
    } else {
      formattedData.end_date = formattedData.start_date;
      var formattedNoSerieData = { ...formattedData, edit_serie: false };
      setRecurringScheduleFormattedData(formattedNoSerieData);
      toggleShowCancelIndividualRecurringScheduleModal();
    }
  };

  const handleSubmitType = async (data) => {
    if (isEdit && scheduleDetail.is_serie) {
      const recurringScheduleFormattedData = {
        space_id: props.spaceId,
        start_date: (new Date(data.startDate)).toISOString().split('T')[0],
        end_date: endDate ? (endDate.toISOString().split('T')[0]) : (!isModified && scheduleDetail.end_date ? new Date(scheduleDetail.end_date).toISOString().split('T')[0] : null),
        from: data.from,
        to: data.to,
        price: data.price,
        description: data.description,
        available_places: data.availablePlaces,
        recurring_type_id: recurringType ? recurringType.value : scheduleDetail.schedule_settings.recurring_type_id ? scheduleDetail.schedule_settings.recurring_type_id : null,
        schedule_type_id: RESCHEDULED_SCHEDULE_TYPE,
        //Si es una serie diaria asigno los 7 dias de la semana, en caso contrario asigno los dias activos para la repetición semanal
        days_of_the_week: recurringType ? recurringType.value === 2 ? (daysOfWeek ? daysOfWeek.filter(filterActiveDay).map(day => day.value) : null) : [0, 1, 2, 3, 4, 5, 6] : scheduleDetail && scheduleDetail.schedule_settings ? scheduleDetail.schedule_settings.schedule_type_id == 1 ? [0, 1, 2, 3, 4, 5, 6] : (scheduleDetail.schedule_settings.days_of_week) : null,
      };
      setRecurringScheduleFormattedData(recurringScheduleFormattedData);
      toggleShowRecurringScheduleModal();
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async data => {
    const formattedData = {
      space_id: props.spaceId,
      start_date: (new Date(data.startDate)).toISOString().split('T')[0],
      end_date: endDate ? endDate.toISOString().split('T')[0] : null,
      from: data.from,
      to: data.to,
      price: data.price,
      description: data.description,
      is_serie: isSerie,
      available_places: data.availablePlaces,
      appointment_duration: data.shift ? data.shift.value : null,
      has_appointments: data.hasAppointments,
      recurring_type_id: recurringType ? recurringType.value : null,
      //Si es una serie diaria asigno los 7 dias de la semana, en caso contrario asigno los dias activos para la repetición semanal
      repetition_days: recurringType ? recurringType.value === 2 ? (daysOfWeek ? daysOfWeek.filter(filterActiveDay).map(day => day.value) : null) : [0, 1, 2, 3, 4, 5, 6] : null
    };
    if (!isEdit)
      props.createSchedule(formattedData);
    else {
      var formattedEditData = {
        ...formattedData,
        edit_serie: false,
        schedule_type_id: RESCHEDULED_SCHEDULE_TYPE
      };
      props.updateSchedule(props.scheduleDetail.id, formattedEditData);
    }
  }

  const handleRepetitionSubmit = async data => {
    setHasRepetitionModalData(true);
    setIsModified(true);
    setIsSerie(true);
    setRecurringType(data.recurringType ? data.recurringType : null);
    setEndDate(data.endDate ? data.endDate : null);
    setFinishSerie(data.finishSerie ? data.finishSerie : null);
    setDaysOfWeek(data.days ? data.days : null);
    toggleShowAddRepetition();
  }


  const clearRepetitionModalData = () => {
    setHasRepetitionModalData(false);
    setIsModified(true);
    setIsSerie(false);
    setRecurringType(null);
    setEndDate(null);
    setFinishSerie(null);
    setDaysOfWeek(null);
  }

  const onStartDateChange = (setFieldValue, value) => {
    setFieldValue('startDate', value);
  };

  const onFromChange = (setFieldValue, value) => {
    setFieldValue('from', value);
  };

  const onHasAppointmentsChange = (setFieldValue, value) => {
    if (!value)
      setFieldValue('shift', null);
    setFieldValue('hasAppointments', value);
  };
  const onToChange = (setFieldValue, value) => {
    setFieldValue('to', value);
  };
  const getInitialValues = () => {
    var startDate = new Date(props.date);
    return {
      startDate:
        props.scheduleDetail && props.scheduleDetail.date
          ? props.scheduleDetail.date
          : startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset()),
      from:
        props.scheduleDetail && props.scheduleDetail.from
          ? props.scheduleDetail.from
          : props.timeRange.from,
      to: props.scheduleDetail && props.scheduleDetail.to ? props.scheduleDetail.to : "",
      price: props.scheduleDetail && props.scheduleDetail.price ? props.scheduleDetail.price : 0,
      description:
        props.scheduleDetail && props.scheduleDetail.description
          ? props.scheduleDetail.description
          : "",
      availablePlaces:
        props.scheduleDetail && props.scheduleDetail.available_places
          ? props.scheduleDetail.available_places
          : 1,
      hasAppointments: false,
      shift: null
    };
  };
  const getSignUpSchema = () => {
    function isValidToValue(ref, msg) {
      return this.test({
        name: 'isValidToValue',
        exclusive: false,
        message: '"Fin" debe ser mayor a "Inicio"',
        params: {
          reference: ref.path
        },
        test: function (value) {
          return value === '00:00' || value > this.resolve(ref)
        }
      })
    };
    Yup.addMethod(Yup.string, 'isValidToValue', isValidToValue);

    const rangeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0,1,3,4][0,5]$/;
    const onlyNumberRegex = /^[0-9]*$/;
    return (Yup.object().shape({
      from: Yup.string()
        .matches(rangeRegex, 'Rango horario "Inicio" invalido.')
        .required('Ingrese un rango horario "Inicio".'),
      to: Yup.string()
        .matches(rangeRegex, 'Rango horario "Fin" invalido.')
        .isValidToValue(Yup.ref('from'))
        .required('Ingrese un horario "Fin"'),
      price: Yup.string()
        .matches(onlyNumberRegex, 'Precio invalido.')
        .required('Ingrese un precio'),
      availablePlaces: Yup.string()
        .matches(onlyNumberRegex, 'Cupo invalido.')
        .test(
          'availableplaces-validation',
          'El cupo ser mayor a cero.',
          value => value > 0
        )
        .required('Ingrese un Cupo.')
    }));
  }

  var isLoading =
    props.loadingCreateSchedule || props.loadingFetchScheduleDetail || props.loadingUpdateSchedule;

  const form = () => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    } else {
      return (
        <Formik
          initialValues={getInitialValues()}
          validateOnChange={false}
          validationSchema={getSignUpSchema()}
          onSubmit={handleSubmitType}
          render={e => getFormContent(e)}
        />
      );
    }
  };
  return (
    <GrayedArea>
      <ScheduleContainer leftPopUp={props.leftPopUp} bottomPopUp={props.bottomPopUp}>
        <HeaderWrapper>
          <TitleModal>{props.title}</TitleModal>
          <Close src={CloseIcon} onClick={props.onClose} />
        </HeaderWrapper>
        {form()}
        {showAddRepetition ? (
          <RepetitionModal
            title={"Repetición"}
            onClose={toggleShowAddRepetition}
            onSubmit={handleRepetitionSubmit}
            isSerie={!isModified && isEdit ? scheduleDetail.is_serie : isSerie}
            recurringType={
              !isModified &&
                isEdit &&
                scheduleDetail.schedule_settings &&
                scheduleDetail.schedule_settings.recurring_type_id
                ? scheduleDetail.schedule_settings.recurring_type_id - 1
                : recurringType
            }
            endDate={
              !isModified && isEdit && scheduleDetail.end_date
                ? new Date(scheduleDetail.end_date)
                : endDate
            }
            finishSerie={!isModified && isEdit && scheduleDetail.end_date ? "day" : finishSerie}
            daysOfWeek={
              !isModified &&
                isEdit &&
                scheduleDetail.schedule_settings &&
                scheduleDetail.schedule_settings.days_of_week &&
                scheduleDetail.schedule_settings.days_of_week
                ? scheduleDetail.schedule_settings.days_of_week
                : daysOfWeek
            }
            isModified={isModified}
          ></RepetitionModal>
        ) : null}
        {showRecurringScheduleModal ||
          showCancelSerieRecurringScheduleModal ||
          showCancelIndividualRecurringScheduleModal ? (
          <RecurringScheduleModal
            title={
              showRecurringScheduleModal
                ? "Editar Horario Recurrente"
                : showCancelSerieRecurringScheduleModal
                  ? "Eliminar Horario Recurrente"
                  : "Eliminar horario"
            }
            message={showRecurringScheduleModal ? "" : "Se cancelaran las reservas activas"}
            onClose={
              showRecurringScheduleModal
                ? toggleShowRecurringScheduleModal
                : showCancelSerieRecurringScheduleModal
                  ? toggleShowCancelSerieRecurringScheduleModal
                  : toggleShowCancelIndividualRecurringScheduleModal
            }
            updateSchedule={props.updateSchedule}
            recurringScheduleFormattedData={recurringScheduleFormattedData}
            scheduleId={scheduleDetail.id}
            isCancel={
              showCancelSerieRecurringScheduleModal || showCancelIndividualRecurringScheduleModal
            }
            isSerie={showCancelSerieRecurringScheduleModal || showRecurringScheduleModal}
          ></RecurringScheduleModal>
        ) : null}
      </ScheduleContainer>
    </GrayedArea>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(ScheduleModal));
