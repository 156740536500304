import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'

const initialState = {
    loadingDashBilletera: false,
    fetchDashBilleteraStart: false,
    fetchDashBilleteraSuccess: false,
    fetchDashBilleteraFail: false,
    dashBilleteraData: [],


    loadingDashPersona: false,
    fetchDashPersonaStart: false,
    fetchDashPersonaSuccess: false,
    fetchDashPersonaFail: false,
    dashPersonaData: [],

}

const fetchPersonaDashStart = (state, action) => {
    return updateObject(state, {
        dashPersonaData: [],
        fetchDashPersonaSuccess: false,
        fetchDashPersonaFail: false,
        fetchDashPersonaStart: true,
        loadingDashPersona: true,
    })
}

const fetchPersonaDashFail = (state, action) => {
    return updateObject(state, {
        fetchDashPersonaFail: action.error,
        fetchDashPersonaSuccess: false,
        fetchDashPersonaStart: false,
        loadingDashPersona: false,
    })
}


const fetchPersonaDashSuccess = (state, action) => {
    return updateObject(state, {
        dashPersonaData: action.data,
        fetchDashPersonaSuccess: true,
        fetchDashPersonaFail: false,
        fetchDashPersonaStart: false,
        loadingDashPersona: false,
    })
}

const fetchBilleteraDashStart = (state, action) => {
    return updateObject(state, {
        dashBilleteraData: [],
        fetchDashBilleteraSuccess: false,
        fetchDashBilleteraFail: false,
        fetchDashBilleteraStart: true,
        loadingDashBilletera: true,
    })
}

const fetchBilleteraDashFail = (state, action) => {
    return updateObject(state, {
        fetchDashBilleteraFail: action.error,
        fetchDashBilleteraSuccess: false,
        fetchDashBilleteraFail: true,
        fetchDashBilleteraStart: false,
        loadingDashBilletera: false,
    })
}

const fetchBilleteraDashSuccess = (state, action) => {
    return updateObject(state, {
        dashBilleteraData: action.data,
        fetchDashBilleteraSuccess: true,
        fetchDashBilleteraFail: false,
        fetchDashBilleteraStart: false,
        loadingDashBilletera: false,
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_BILLETERA_DASH_SUCCESS:
            return fetchBilleteraDashSuccess(state, action)
        case actionTypes.FETCH_BILLETERA_DASH_FAIL:
            return fetchBilleteraDashFail(state, action)
        case actionTypes.FETCH_BILLETERA_DASH_START:
            return fetchBilleteraDashStart(state, action)

        case actionTypes.FETCH_PERSONA_DASH_SUCCESS:
            return fetchPersonaDashSuccess(state, action)
        case actionTypes.FETCH_PERSONA_DASH_FAIL:
            return fetchPersonaDashFail(state, action)
        case actionTypes.FETCH_PERSONA_DASH_START:
            return fetchPersonaDashStart(state, action)


        default:
            return state
    }
}

export default reducer