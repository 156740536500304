import React, { useEffect, useState, useRef } from "react";
import CloseIcon from '../../../../../../assets/icons/close.svg';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'

import theme from "../../../../../../config/theme"
import { FormControl, FormControlLabel, Radio, RadioGroup, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
    RecurringScheduleContainer,
    FormContainer,
    Container,
    CancelButton,
    Row, TitleModal,
    SaveButton,
    ErrorLabel,
    ButtonWrapper,
    InputWrapper,
    FlexWrapper,
    InputLabel,
    Ahref,
    HeaderWrapper,
    LoaderWrapper,
    GrayedArea,
    CheckBoxWrapper,
    ButtonsWrapper,
    Message
} from './styled'


function RecurringScheduleModal(props) {
    const {
        title,
        onClose,
    } = props;


    const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false);
    const [deleteScheduleModalData, setDeleteScheduleModalData] = useState(null);

    const onEditSerieChange = (setFieldValue, value) => {
        setFieldValue('editSerie', value);
    };


    const StyledRadio = withStyles({
        root: {
            color: theme.colors.midGray,
            '&$checked': {
                color: theme.colors.midGray,
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const onRecurringScheduleModalSubmit = async (data) => {

        if (props.isSerie) {
            var formattedData = { ...props.recurringScheduleFormattedData, edit_serie: data.editSerie === "true" ? true : false };
            if (props.isCancel && !formattedData.edit_serie) {
                formattedData.end_date = formattedData.start_date;
            }
            props.updateSchedule(props.scheduleId, formattedData);
        }
        else
            props.updateSchedule(props.scheduleId, props.recurringScheduleFormattedData);
        props.onClose();

    }
    const getFormContent = ({ handleSubmit, values, setFieldValue, errors, myFormRef }) => {
        return (
            < FormContainer ref={el => myFormRef = el} onSubmit={handleSubmit} >
                < FlexWrapper >
                    {props.isSerie ?
                        <Row>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="editSerieForm" name="editSerie1" value={values.editSerie}
                                    onChange={({ target: { value } }) => onEditSerieChange(setFieldValue, value)}>
                                    <FormControlLabel value="false" control={<StyledRadio />}
                                        label={<Box component="div" fontSize={12}>Solo este</Box>} />
                                    <FormControlLabel value="true" control={<StyledRadio />}
                                        label={<Box component="div" fontSize={12}>Todos los futuros horarios</Box>} />
                                </RadioGroup>
                            </FormControl>
                        </Row>
                        : null}
                    <ButtonsWrapper>
                        <CancelButton type="button" onClick={props.onClose}>
                            Cancelar
                            </CancelButton>
                        <SaveButton type="submit" onClick={() => handleSubmit(values)}>
                            Aceptar
                            </SaveButton>
                    </ButtonsWrapper>
                </FlexWrapper >
            </FormContainer >
        )
    }




    const getInitialValues = () => {
        return ({
            editSerie: "false"
        })
    };
    const getSignUpSchema = () => {
        return (Yup.object().shape({

        }));
    }

    return (
        <GrayedArea>
            <RecurringScheduleContainer leftPopUp={props.leftPopUp} bottomPopUp={props.bottomPopUp}>
                <HeaderWrapper>
                    <TitleModal>{props.title}</TitleModal>
                </HeaderWrapper>
                {props.message ? <Message>{props.message}</Message> : null}
                <Formik
                    initialValues={getInitialValues()}
                    validateOnChange={false}
                    validationSchema={getSignUpSchema()}
                    onSubmit={onRecurringScheduleModalSubmit}
                    render={e => getFormContent(e)}
                />
            </RecurringScheduleContainer>
        </GrayedArea>
    )

}


export default RecurringScheduleModal
