import styled from 'styled-components';

export const KitsRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden;
    ${({ edit }) => edit && `
        border-bottom: solid 1px #f2f2f2;
    `}
`
export const BackIcon = styled.img`
    width: 12px;
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
`
export const Br = styled.div`
    width: 100%;
    border-bottom: solid 1px #f2f2f2;
`
export const Quantity = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 2.3px;
  width: 60px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 5.3px;
    height: 9.3px;
  }
  p {
    margin: 0px 10px;
  }
`
export const TrashIcon = styled.img`
    width: 18px;
    height: 20px;
`
export const PlusIcon = styled.img`
    width: 26px;
    height: 26px;
`
export const CheckIcon = styled.img`
    width: 25px;
    height: 19px;
`
export const StyledSelect = styled.div`
    width: 230px;
    & > div > div:first-child {
        height: 40px;
    }
    & span {
        margin: 0px;
    }
`
export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const Imagen = styled.img`
    cursor: pointer;
    padding-right: inherit;
    color: #646464;
`

export const Ahref = styled.a`
    cursor: pointer;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`


export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const TableWrapper = styled.div`
    position:relative;
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`

export const LabelSection = styled.div`
  display: flex;
  align-items: flex-start;
`
export const ChannelLabel = styled.div`
  color: #598f7f;
  background-color: #e0eae7;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  margin: 2px;
  width:min-content;
  border-radius:10px;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: flex-start;
  align-items: center;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  margin-right: 10px;
`

export const SelectWrapper = styled.div`
  width: 300px;
  font-weight: normal;
`