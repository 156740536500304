import styled from 'styled-components';
import Select from 'react-select';

// Modal 

export const StyledSelect = styled(Select)` 
  & > div > div:nth-child(2) {
    background-color: rgba(244, 247, 249, 0.4);
  }
  & span {
    margin: 0px;
  }
`
export const AddMore = styled.div` 
  border-radius: 4px;
  border: solid 1px #e9eff4;
  border-style: dashed;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  align-items: center;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #ff2424;
`

// Side Window

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flex }) => flex && `flex: ${flex};`}
  & > div:first-child {
    align-items: center;
    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: #000000;
    }
  }
`
export const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  & span:first-child {
    font-size: 14px;
    color: #9b9b9b;
    margin-bottom: 10px;
  }
  & span:nth-child(2) {
    font-size: 15px;
    line-height: 1.2;
    color: #000000;
  }
  img {
    margin-right: 10px;
    max-width: 80px;
    max-height: 80px;
  }
`
export const Br = styled.div`
  width: 100%
  border-bottom: solid 1px #f2f2f2;
  margin-bottom: 20px;
`
export const Header = styled.div`
  border-radius: 8px 8px 0px 0px;
  background-color: #001441;
  height: 56px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;
`
export const Body = styled.div`
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  border: solid 1px #e1e1e1;
`

export const ErrorMessage = styled.label`
  margin-right: auto;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.red};
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.midGray};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`


export const InputWrapperMargin = styled.div`
  width: ${({ width }) => width || 100}%;
  margin-right: 25px;
`
export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  padding: 10px 0;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`
export const VehicleInformation = styled.div`
  border-radius: 8px;
  span {
    margin-bottom: 0px!important;
  }
  ${Row} {
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  `

// View Family member list

export const BackIcon = styled.img`
  width: 12px;
  margin-right: 10px;
  height: 22px;
  cursor: pointer;
`
export const SelectWrapper = styled.div`
  width: 150px; 
`

export const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`
export const Label = styled.div`
  color: ${({ theme }) => theme.colors.oceanGreen};;
  textDecoration: underline; 
  cursor: pointer;
`
export const Cell = styled.div`
  display: flex;
  img {
    width: 48.2px;
    height: 48.2px;
    margin-right: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > span:first-child {
      opacity: 0.8;
      font-size: 13px;
      line-height: 1.54;
      color: #808080;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.33;
      color: #212223;
      white-space: nowrap;
    }
  }
`
export const SelectedMember = styled.div`
  display: flex;
  margin-bottom: 10px;
  img {
    width: 53px;
    height: 53px;
    margin-right: 10px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > span:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.67;
      color: #000000;
      white-space: nowrap;
    }
    & > span:nth-child(2) {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`




export const DataContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`
export const TypographyImg = styled.div`
max-width: 110px;
max-height: 110px;
`