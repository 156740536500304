import React from 'react'
import {
    SectionTitle,
    Row,
    StyledButton,
    Wrapper,
    CrossImage,
    CrossWrapper,
    SectionError,
    SelectContainer,
    ErrorWrapper,
    ModalContainer,
    ColumnDataContainer,
    RowDataContainer,
    RowDataTitle,
    RowDataInfo,
    LastRowDataContainer,
    StatusBadgeWithoutBorder
} from './styled'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import DayJS from "react-dayjs";
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress'


class ModalOrderDetail extends React.Component {
    state = {
        options: [
            { value: "El ticket a sido resuelto exitosamente", label: "El ticket a sido resuelto exitosamente" },
            { value: "Sin respuesta del propietario", label: "Sin respuesta del propietario" },
            { value: "Rechazado por administrador", label: "Rechazado por administrador" },
            { value: "Rechazado por usuario", label: "Rechazado por usuario" },
        ],
        categoryTransfer: ""
    }

    moneyFormat = (num) => {
        return '$' + num.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    render() {
        const { title, currentRowDatail, onBtnClick, btnContent, onBtnClose, onLoading, onError, onSuccess } = this.props

        return (
            //minwidth={"200px"}
            //minheight={"500px"}
            <Modal onClose={() => onBtnClose()} height={"63%"} width={"50%"} minwidthWD={"585px"} minheightWD={"490px"}>

                <SectionTitle>Detalle de orden <div style={{ display: "inline" }}><b>{title}</b></div></SectionTitle>
                <ModalContainer>
                    <ColumnDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Solicitado</RowDataTitle>
                            <RowDataInfo>
                                {currentRowDatail.creation_date ?
                                    <b><DayJS format="D MMMM YYYY h:mm a">{currentRowDatail.creation_date}</DayJS></b>
                                    :
                                    <b><StatusBadgeWithoutBorder color={"#B22222"} >No obtenido</StatusBadgeWithoutBorder></b>}
                            </RowDataInfo>
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Vencimiento</RowDataTitle>
                            <RowDataInfo>
                                {currentRowDatail.period_date ?
                                    <b><DayJS format="D MMMM YYYY h:mm a">{currentRowDatail.period_date}</DayJS></b>
                                    :
                                    <b><StatusBadgeWithoutBorder color={"#B22222"} >No obtenido</StatusBadgeWithoutBorder></b>}
                            </RowDataInfo>
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Usuario</RowDataTitle>
                            {currentRowDatail.creation_user ?
                                <RowDataInfo><b>{currentRowDatail.creation_user}</b></RowDataInfo>
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>}
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Estado</RowDataTitle>
                            {currentRowDatail.state_name ?
                                (
                                    currentRowDatail.state_name == "Aprobado" ?
                                        <RowDataInfo color={"green"}><b>{currentRowDatail.state_name}</b></RowDataInfo>
                                        :
                                        currentRowDatail.state_name == "Pendiente" ?
                                            <RowDataInfo color={"#daa520"}><b>{currentRowDatail.state_name}</b></RowDataInfo>
                                            :
                                            currentRowDatail.state_name == "Rechazado" ?
                                                <RowDataInfo color={"#B22222"}><b>{currentRowDatail.state_name}</b></RowDataInfo>
                                                :
                                                <RowDataInfo color={"#daa520"}><b>{currentRowDatail.state_name}</b></RowDataInfo>
                                )
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>
                            }
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Convenio</RowDataTitle>
                            {currentRowDatail.bank_agreement ?
                                <RowDataInfo><b>{currentRowDatail.bank_agreement}</b></RowDataInfo>
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>}
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Cantidad de Unidades</RowDataTitle>
                            {currentRowDatail.total_debits_count ?
                                <RowDataInfo><b>{currentRowDatail.total_debits_count}</b></RowDataInfo>
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>}
                        </RowDataContainer>
                        <RowDataContainer>
                            <RowDataTitle>Monto Total</RowDataTitle>
                            {currentRowDatail.total_debits_amount ?
                                <RowDataInfo><b>{this.moneyFormat(currentRowDatail.total_debits_amount)}</b></RowDataInfo>
                                // <RowDataInfo><b>{this.moneyFormat(20009.333)}</b></RowDataInfo>
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>}
                        </RowDataContainer>
                        <LastRowDataContainer >
                            <RowDataTitle >Mensaje informativo</RowDataTitle>
                            {currentRowDatail.message ?
                                <RowDataInfo><b>{currentRowDatail.message}</b></RowDataInfo>
                                :
                                <StatusBadgeWithoutBorder color={"#B22222"} ><b>No obtenido</b></StatusBadgeWithoutBorder>
                            }
                        </LastRowDataContainer>
                    </ColumnDataContainer>



                </ModalContainer>


            </Modal>
        )
    }
}

export default ModalOrderDetail

/* <SelectContainer>
                        Categoria Actual
                            <Select
                            value={this.props.categoryList[this.props.actualCategory]}
                            options={this.props.categoryList}
                            isDisabled
                        />
                    </SelectContainer>
                    <SelectContainer>
                        Categoria a transferir
                            <Select
                            value={this.state.categoryTransfer}
                            onChange={this.handleCategory}
                            options={this.props.categoryList}
                            placeholder={"Seleccione una opcion"}
                            maxMenuHeight={125}
                        />
                    </SelectContainer>
                    {onError && <SectionError>{onError}</SectionError>} */
/* <Button onClick={() => this.props.onBtnClick(this.state.categoryTransfer.value)}>
{onLoading ? <CircularProgress size={18} /> : "Descargar"}
</Button>
{onSuccess ? onBtnClose() : ""} */