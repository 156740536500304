import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import PropTypes from 'prop-types';
import Loader from "../assets/animation/loader.gif"


const backgroundColor = theme.variants('mode', 'variant', {
  default: { light: ({ theme }) => theme.colors.defaultButtonBackground, dark: 'darkgray' },
  secondary: { light: ({ theme }) => theme.colors.secondaryButtonBackground, dark: 'darkblue' },
  success: { light: 'green', dark: 'darkgreen' },
  warning: { light: 'orange', dark: 'darkorange' },
  search: { light: '#7dbe55', dark: '#7dbe55' },//fuerza a ser claro
  inherit: { light: 'inherit', dark: '#inherit' },//fuerza a ser claro
});

const textColor = theme.variants('mode', 'variant', {
  default: { light: ({ theme }) => theme.colors.defaultTextColor, dark: 'darkgray' },
  secondary: { light: ({ theme }) => theme.colors.secondaryTextColor, dark: 'darkblue' },
  success: { light: 'green', dark: 'darkgreen' },
  warning: { light: 'orange', dark: 'darkorange' },
  search: { light: 'white', dark: 'black' },
  inherit: { light: 'darkgreen', dark: 'darkgreen' },
});

const Container = styled.div`  
  display: flex;
  justify-content: center;
  `
const LoaderImage = styled.img`
  ${({ small }) => small && `
    width: 25px;
  `}

  ${({ medium }) => medium && `
    width: 50px; 
  `}

  ${({ large }) => large && `
    width: 100px;  
  `}
`
const Button = styled.button`  
  background-color: ${backgroundColor};
  color: ${textColor};
  border: ${({ inverted }) => (inverted ? 'solid 1px #30735f' : 'none')};
  box-sizing: border-box;
  font-weight: normal;
  cursor: pointer;
  text-align: center;  
  border-radius:${({ borderRadius }) => (borderRadius ? borderRadius : '6px' )}; 
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Roboto, sans-serif;
  align-items: center;
  ${({ borderColor }) => borderColor && `
    border: 1px solid ${borderColor};  `}
  ${({ small }) => small && `
    width: 54px;
    height: 31px;
    border-radius: 15.5px;
    font-size: 10px;
  `}

  ${({ medium }) => medium && `
    width: 150px; 
    height: 40px;
    font-size: 18px;
  `}

  ${({ large }) => large && `
    width: 300px;  
    height: 50px;
    font-size: 20px;
  `}
  ${({ extraLarge }) => extraLarge && `
    width: 360px;  
    height: 45px;
    font-size: 20px;
  `}
  
${({ width }) => width && `
    width: `+width
  }
`

Button.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary', 'success', 'warning'])
};

Button.defaultProps = {
  variant: 'default',
};


const ButtonCompleted = ({
  children,
  loading,
  variant,
  disabled,
  hidden,
  borderColor,
  borderRadius,
  ...otherProps
}) => (
  <Container>
    {loading ?<LoaderImage small={true} src={Loader}  {...otherProps} />:
      <Button
        borderColor={borderColor}
        borderRadius={borderRadius}
        variant={variant}
        disabled={loading || disabled}
        hidden = {hidden}
        {...otherProps}
      >
        {children}
      </Button>
    }
  </Container>
)

export default ButtonCompleted