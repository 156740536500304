import styled from 'styled-components'


export const Content = styled.div`
    width: 58%;
    margin-left: 24%;
    padding: 2%;
    heigth: 100%;
    box-shadow: 0px 0px 5px;
    border-radius: 25px;
    background : #ffffff;

`
export const ModalContainer = styled.div`
    width: 100%;
    heigth: 100%;
    display:flex;
    flex-direction: column;
    padding-top: 70px;
    justify-content: center;
    align-items: center;

`
export const LoaderContainer = styled.div`
    width: 100%;
    heigth: 40%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.8em;

`
export const Title = styled.div`
width:100%;
height: 15%;
font-family: 'Roboto',sans-serif;
color:#30735f;
font-size: 25px;
text-align: center;
font-weight: 1;
margin-top: 4%;
margin-bottom: 1em;
`
export const Data = styled.div`
display: flex;
width:100%;
height: 15%;
font-family: 'Roboto',sans-serif;
color: #000000;
font-size: 15px;
text-align: center;
font-weight: 100;
margin-bottom: 1.1em;
`



export const ButtonContent = styled.div`
width:100%;
height: 30%;
 margin-top: 4%;
 display: flex;
 align-items: center;
 justify-content: center;
`


