

import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  ticketsList: [],
  ticketMessagesList: [],
  categoriesList: [],

  typesList: [],
  
  loading: false,
  created: false,

  loadingFetchTickets: false,
  fetchTicketsFail: false,
  fetchTicketsSuccess: false,

  loadingCloseTicket: false,
  closeTicketFail: false,
  closeTicketSuccess: false,

  pendingTicketSuccess: false,
  pendingTicketStart: false,
  pendingTicketFail: false,

  loadingSearchTickets: false,
  searchTicketsFail: false,
  searchTicketsStart: false,


  allCategoriesList: [],
  loadingCategories: false,
  loadingTypes: false,

  page: 0,
  sentTake: 10,
  hasMoreSent: true,
  receivedTake: 10,
  unreadTake: 10,
  hasMoreUnread: true
}

const resetModalsStateRedux= state => {
  return updateObject(state, { 
    loadingFetchTickets: false,
    closeTicketFail: false,
    
    closeTicketSuccess: false,
    
    loadingPendingTicket: false,
    pendingTicketFail: false,
    pendingTicketSuccess: false,
    loadingPendingTicket: false,
    changeCategoryTicketFail: false,
    changeCategoryTicketSuccess: false,
  
  
  })
}

const fetchTicketsStart = state => {
  return updateObject(state, { loadingFetchTickets: true,closeTicketSuccess: false,fetchTicketsSuccess: false })
}

const fetchTicketsSuccess = (state, action) =>
  updateObject(state, {
    ticketsList: action.tickets.values,
    fetchTicketsSuccess: true,
    loadingFetchTickets: false
  })

const fetchTicketsFail = state => {
  return updateObject(state, { loadingFetchTickets: false,fetchTicketsSuccess: false, fetchTicketsFail: true })
}

//////

const fetchCategoriesByRoleStart = state => {
  return updateObject(state, { loading: true })
}

const fetchCategoriesByRoleSuccess = (state, action) =>
updateObject(state, {
    categoriesList: action.categories.values,
    loading: false
  })

  const fetchCategoriesByRoleFail = state => {
  return updateObject(state, { loading: false })
}

const fetchTicketMessagesStart = state => {
  return updateObject(state, { loading: true,closeTicketSuccess: false })
}

const fetchTicketMessagesSuccess = (state, action) =>
  updateObject(state, {
    ticketMessagesList: action.messages.values,
    loading: false
  })

const fetchTicketMessagesFail = state => {
  return updateObject(state, { loading: false})
}

//////////

const closeTicketStart = state => {
  return updateObject(state, { loadingCloseTicket: true,closeTicketSuccess: false,closeTicketFail: false })
}

const closeTicketSuccess = (state, action) =>
  updateObject(state, {
    loadingCloseTicket: false, closeTicketSuccess: true,closeTicketFail: false,ticketMessagesList: ""
  })

const closeTicketFail = (state, action) => {
  return updateObject(state, { loadingCloseTicket: false,closeTicketSuccess: false,closeTicketFail: action.error ,  })
}

//////////

const pendingTicketStart = state => {
  return updateObject(state, { loadingPendingTicket: true,pendingTicketSuccess: false,pendingTicketFail: false })
}

const pendingTicketSuccess = (state, action) =>
  updateObject(state, {
    loadingPendingTicket: false, pendingTicketSuccess: true,pendingTicketFail: false,ticketMessagesList: ""
  })

const pendingTicketFail = (state, action) => {
  return updateObject(state, { loadingPendingTicket: false,pendingTicketSuccess: false,pendingTicketFail: action.error ,  })
}

/////

const changeCategoryTicketStart = state => {
  return updateObject(state, { loadingPendingTicket: true,changeCategoryTicketSuccess: false,changeCategoryTicketFail: false })
}

const changeCategoryTicketSuccess = (state, action) =>
  updateObject(state, {
    loadingPendingTicket: false, changeCategoryTicketSuccess: true,changeCategoryTicketFail: false,ticketMessagesList: ""
  })

const changeCategoryTicketFail = (state, action) => {
  return updateObject(state, { loadingPendingTicket: false,changeCategoryTicketSuccess: false,changeCategoryTicketFail: action.error ,  })
}

/////

const searchTicketsStart = state => {
  return updateObject(state, { loadingSearchTickets: true,searchTicketsSuccess: false,searchTicketsFail: false })
}

const searchTicketsSuccess = (state, action) =>
  updateObject(state, {
    loadingSearchTickets: false, searchTicketsSuccess: true,searchTicketsFail: false, ticketsList: action.tickets.values 
  })

const searchTicketsFail = (state, action) => {
  return updateObject(state, { loadingSearchTickets: false,searchTicketsSuccess: false,searchTicketsFail: action.error ,  })
}

//////

const fetchAllCategoriesStart = state => {
  return updateObject(state, { loadingCategories: true })
}

const fetchAllCategoriesSuccess = (state, action) =>
updateObject(state, {
    allCategoriesList: action.categories.values,
    loadingCategories: false
  })

const fetchAllCategoriesFail = state => {
  return updateObject(state, { loadingCategories: false })
}

//////

const fetchTypesByCategoryStart = state => {
  return updateObject(state, { loadingTypes: true })
}

const fetchTypesByCategorySuccess = (state, action) =>
updateObject(state, {
  typesList: action.types.values,
  loadingTypes: false
})

const fetchTypesByCategoryFail = state => {
  return updateObject(state, { loadingTypes: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TICKETS_SUCCESS:
      return fetchTicketsSuccess(state, action)
    case actionTypes.FETCH_TICKETS_FAIL:
      return fetchTicketsFail(state, action)
    case actionTypes.FETCH_TICKETS_START:
      return fetchTicketsStart(state, action)
    case actionTypes.FETCH_CATEGORIES_BY_ROLE_SUCCESS:
      return fetchCategoriesByRoleSuccess(state, action)
    case actionTypes.FETCH_CATEGORIES_BY_ROLE_FAIL:
      return fetchCategoriesByRoleFail(state, action)
    case actionTypes.FETCH_CATEGORIES_BY_ROLE_START:
      return fetchCategoriesByRoleStart(state, action)
    case actionTypes.FETCH_TICKET_MESSAGES_SUCCESS:
      return fetchTicketMessagesSuccess(state, action)
    case actionTypes.FETCH_TICKET_MESSAGES_FAIL:
      return fetchTicketMessagesFail(state, action)
    case actionTypes.FETCH_TICKET_MESSAGES_START:
      return fetchTicketMessagesStart(state, action)
    case actionTypes.CLOSE_TICKET_SUCCESS:
      return closeTicketSuccess(state, action)
    case actionTypes.CLOSE_TICKET_FAIL:
      return closeTicketFail(state, action)
    case actionTypes.CLOSE_TICKET_START:
      return closeTicketStart(state, action)
    case actionTypes.PENDING_TICKET_SUCCESS:
      return pendingTicketSuccess(state, action)
    case actionTypes.PENDING_TICKET_FAIL:
      return pendingTicketFail(state, action)
    case actionTypes.PENDING_TICKET_START:
      return pendingTicketStart(state, action)
    case actionTypes.CHANGE_CATEGORY_TICKET_SUCCESS:
      return changeCategoryTicketSuccess(state, action)
    case actionTypes.CHANGE_CATEGORY_TICKET_FAIL:
      return changeCategoryTicketFail(state, action)
    case actionTypes.CHANGE_CATEGORY_TICKET_START:
      return changeCategoryTicketStart(state, action)
    case actionTypes.SEARCH_TICKETS_SUCCESS:
      return searchTicketsSuccess(state, action)
    case actionTypes.SEARCH_TICKETS_FAIL:
      return searchTicketsFail(state, action)
    case actionTypes.SEARCH_TICKETS_START:
      return searchTicketsStart(state, action)
    case actionTypes.FETCH_TICKET_CATEGORIES_SUCCESS:
      return fetchAllCategoriesSuccess(state, action)
    case actionTypes.FETCH_TICKET_CATEGORIES_FAIL:
      return fetchAllCategoriesFail(state, action)
    case actionTypes.FETCH_TICKET_CATEGORIES_START:
      return fetchAllCategoriesStart(state, action)
    case actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS:
      return fetchTypesByCategorySuccess(state, action)
    case actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_FAIL:
      return fetchTypesByCategoryFail(state, action)
    case actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_START:
      return fetchTypesByCategoryStart(state, action)


      case actionTypes.RESET_MODAL_STATE_REDUX:
        return resetModalsStateRedux(state, action)
    default:
      return state
 }
}
export default reducer
