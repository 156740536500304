import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const TableCellContainer = styled.button`
  height: 25px;
  width:  ${({ initial }) => (initial ? "85%" : "100%")};
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};

  border-right: 1px solid ${({ theme }) => theme.colors.mystic};
  cursor: ${({ isBooked }) => (isBooked ? "pointer" : "inherit")};
  outline: none;
    &:hover {
    opacity:80%;
  }



${({ isSchedule, availablePlaces, initial }) =>
  isSchedule &&
  css`
    background-color: ${({ theme }) => theme.colors.appleGreen};
    opacity: 90%;
    border-right: 1px solid ${({ theme }) => theme.colors.midGray};
    border-left: ${({ theme }) => {
      if (availablePlaces && initial) return "none";
      else return "1px solid" + theme.colors.midGray;
    }};
  `}

${({ isCancelled, availablePlaces, initial }) =>
  isCancelled &&
  css`
    background-color: ${({ theme }) => theme.colors.salmon};
    opacity: 90%;
    border-right: 1px solid ${({ theme }) => theme.colors.salmon};
    border-left: ${({ theme }) => {
      if (availablePlaces && initial) return "none";
      else return "1px solid" + theme.colors.salmon;
    }};
  `}

${({ isBooked, from_app }) =>
  isBooked &&
  css` 
      background-color: ${({ theme }) => {
        if (from_app) return theme.colors.lightPink;
        else return theme.colors.softViolet;
      }} !important; 
      opacity:90%;
      border-width:1px
      border-color: ${({ theme }) => {
        if (from_app) return theme.colors.borderPink;
        else return theme.colors.violet;
      }} !important;
    `}

${({ initial }) =>
  initial &&
  css`
    border-top: 1px solid ${({ theme }) => theme.colors.midGray};
    border-right: none;
  `}

${({ final }) =>
  final &&
  css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
  `}

    ${({ availablePlaces, initial }) =>
      availablePlaces &&
      initial &&
      css`
        border-left: 0px !important;
      `}
`;

export const EditButtonContainer = styled.div`
height: 25px;
width: 15%;
border: none;
padding: 0;
font-size: 14px;
background-color: ${({ theme }) => theme.colors.white};

border-right: 1px solid ${({ theme }) => theme.colors.mystic};
cursor:pointer;
outline: none;
  &:hover{
  opacity:80%;
}


${({ isSchedule }) =>
  isSchedule &&
  css`
    background-color: ${({ theme }) => theme.colors.appleGreen};
    opacity: 90%;
    border-right: 1px solid ${({ theme }) => theme.colors.midGray};
  `}

${({ isBooked }) =>
  isBooked &&
  css` 
      background-color: ${({ theme }) => theme.colors.softViolet} !important; 
      opacity:90%;
      border-width:1px
      border-color: ${({ theme }) => theme.colors.violet} !important;
      border-left:0px;
      border-right: 1px solid;
    `}

    ${({ fromApp }) =>
      fromApp &&
      css` 
      background-color: ${({ theme }) => theme.colors.lightPink} !important; 
      opacity:90%;
      border-width:1px
      border-color: ${({ theme }) => theme.colors.borderPink} !important;
      border-left:0px;
      border-right: 1px solid;
    `}

    ${({ initial }) =>
      initial &&
      css`
        border-top: 1px solid ${({ theme }) => theme.colors.midGray};
      `}
    ${({ final }) =>
      final &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
      `}
`;

export const AvailablePlacesContainer = styled.div`
  height: 25px;
  width: 25%;
  border: none;
  padding: 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};

  border-left: 1px solid ${({ theme }) => theme.colors.midGray};
  cursor:pointer;
  outline: none;
  &:hover{
    opacity:80%;
  }
  border-right: none;

  ${({ availablePlaces }) =>
    availablePlaces &&
    css`
      border-left: 0px !important;
    `}

  ${({ isSchedule }) =>
    isSchedule &&
    css`
      background-color: ${({ theme }) => theme.colors.appleGreen};
      opacity: 90%;
      border-left: 1px solid ${({ theme }) => theme.colors.midGray};
    `}

${({ isCancelled }) =>
  isCancelled &&
  css`
    background-color: ${({ theme }) => theme.colors.salmon};
    opacity: 90%;
    border-left: 1px solid ${({ theme }) => theme.colors.salmon};
  `}


${({ isBooked, fromApp }) =>
  isBooked &&
  css` 
      background-color: ${({ theme }) => {
        if (fromApp) return theme.colors.lightPink;
        else return theme.colors.softViolet;
      }} !important; 
      opacity:90%;
      border-width:1px
      border-color: ${({ theme }) => {
        if (fromApp) return theme.colors.borderPink;
        else return theme.colors.violet;
      }} !important;
    `}

${({ initial }) =>
  initial &&
  css`
    border-top: 1px solid ${({ theme }) => theme.colors.midGray};
  `}

${({ final }) =>
  final &&
  css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
  `}
`;

export const ReservedLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.violet};
  transform: rotate(-5deg);
`;

export const AvailablePlacesDesc = styled.div`
  font-weight: bold;
  margin-top: 5px;
  margin-left: 2px;

  ${({ isSchedule }) =>
    isSchedule &&
    css`
      color: ${({ theme }) => theme.colors.midGray};
    `}

  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: ${({ theme }) => theme.colors.salmon};
    `}


  ${({ isBooked, fromApp }) =>
    isBooked &&
    css`
      color: ${({ theme }) => {
        if (fromApp) return theme.colors.borderPink;
        else return theme.colors.violet;
      }} !important;
    `}

    ${({ initial }) =>
      initial &&
      css`
        color: ${({ theme }) => theme.colors.midGray};
      `}

  ${({ final }) =>
    final &&
    css`
      color: ${({ theme }) => theme.colors.midGray};
    `}
`;
