import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  loading: false,
  created: false,
  proprietaryList: [],

  loadingNewProprietary: false,
  createdNewProprietary: false,
  errorNewProprietary: false,
  errorMessageNewProprietary: false,

  importPropietary: false,
  loadingImportPropietary: false,
  createdimportPropietary: false,
  errorImportPropietary: false,

  loadingPerson: false,
  errorPerson: false,
  modifiedPerson: false,


  loadingFetchPropietaryFamily: false,
  fetchPropietaryFamilySuccess: false,
  fetchPropietaryFamilyFail: false,
  family: [],


  loadingFetchPropietaryVehicle: false,
  fetchPropietaryVehicleSuccess: false,
  fetchPropietaryVehicleFail: false,
  vehicle: [],


  staffList: [],
  loadingNewStaff: false,
  createdNewStaff: false,
  errorNewStaff: false,
  loadingStuff: false,
  created: false,
  filter: 'name',


  page: 0,
  take: 10,
  hasMore: true
}


const fetchSecurityStaffSuccess = (state, action) => {
  return updateObject(state, { staffList: action.staff, loadingStuff: false, error: false })
}

const fetchSecurityStaffFail = state => {
  return updateObject(state, { created: false, error: true, loadingStuff: false })
}

const fetchSecurityStaffStart = state => {
  return updateObject(state, { loadingStuff: true, error: false })
}


//----------------------------------------------------------------



const createSecurityStaffStart = state => {
  return updateObject(state, { loadingNewStaff: true, createdNewStaff: false })
}

const createSecurityStaffSuccess = state => {
  return updateObject(state, {
    loadingNewStaff: false,
    createdNewStaff: true
  })
}

const createSecurityStaffFail = (state, error) => {
  return updateObject(state, {
    createdNewStaff: false,
    loadingNewStaff: false,
    errorNewStaff: error.error.response.data
  })
}

//----------------------------------------------------------------



const fetchPropietaryFamilySuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchPropietaryFamily: false,
    fetchPropietaryFamilySuccess: true,
    fetchPropietaryFamilyFail: false,
    family: action.data,

  })
}

const fetchPropietaryFamilyFail = (state, error) => {
  return updateObject(state, {
    loadingFetchPropietaryFamily: false,
    fetchPropietaryFamilySuccess: false,
    fetchPropietaryFamilyFail: error,
    family: null,
  })
}

const fetchPropietaryFamilyStart = state => {
  return updateObject(state, {
    loadingFetchPropietaryFamily: true,
    fetchPropietaryFamilySuccess: false,
    fetchPropietaryFamilyFail: false,
    family: null,
  })
}



//----------------------------------------------------------------



const fetchPropietaryVehicleSuccess = (state, action) => {
  return updateObject(state, {


    loadingFetchPropietaryVehicle: false,
    fetchPropietaryVehicleSuccess: true,
    fetchPropietaryVehicleFail: false,
    vehicle: action.data,

  })
}

const fetchPropietaryVehicleFail = (state, error) => {
  return updateObject(state, {

    loadingFetchPropietaryVehicle: true,
    fetchPropietaryVehicleSuccess: false,
    fetchPropietaryVehicleFail: error,
    vehicle: null,
  })
}

const fetchPropietaryVehicleStart = state => {
  return updateObject(state, {

    loadingFetchPropietaryVehicle: true,
    fetchPropietaryVehicleSuccess: false,
    fetchPropietaryVehicleFail: false,
    vehicle: null,
  })
}



//----------------------------------------------------------------



const fetchPadronSuccess = (state, action) => {
  console.log("padron succes",action)
  return updateObject(state, {
    proprietaryList: action.padron.values,
    take: action.padron.take,
    hasMore: action.padron.hasMore,
    loading: false
  })
}

const fetchPadronFail = state => {
  console.log("padron fail",state)
  return updateObject(state, { created: false })
}

const fetchPadronStart = state => {
  return updateObject(state, { loading: true, modifiedPerson: false })
}



//----------------------------------------------------------------

const createProprietaryStart = state => {
  return updateObject(state, { loadingNewProprietary: true, errorNewProprietary: false })
}

const createProprietarySuccess = state => {
  return updateObject(state, {
    loadingNewProprietary: false,
    createdNewProprietary: true,
    errorNewProprietary: false
  })
}

const createProprietaryFail = (state, action) => {
  return updateObject(state, {
    createdNewProprietary: false,
    loadingNewProprietary: false,
    errorNewProprietary: true,
    errorMessageNewProprietary: action.error
  })
}


//----------------------------------------------------------------

const importProprietaryStart = state => {
  return updateObject(state, { loadingImportPropietary: true, errorImportPropietary: false })
}

const importProprietarySuccess = state => {
  return updateObject(state, {
    loadingImportPropietary: false,
    createdImportPropietary: true,
    errorImportPropietary: false
  })
}

const importProprietaryFail = state => {
  return updateObject(state, {
    createdImportPropietary: false,
    loadingImportPropietary: false,
    errorImportPropietary: true
  })
}

//---------------------Request Edit Person------------------------------

const fetchEditPersonStart = state => {
  return updateObject(state, { loadingPerson: true, modifiedPerson: false })
}

const fetchEditPersonSuccess = state => {
  return updateObject(state, {
    loadingPerson: false,
    modifiedPerson: true,
    errorPerson: false
  })
}

const fetchEditPersonFail = state => {
  return updateObject(state, {
    modifiedPerson: false,
    loadingPerson: false,
    errorPerson: true
  })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PADRON_SUCCESS:
      return fetchPadronSuccess(state, action)
    case actionTypes.FETCH_PADRON_FAIL:
      return fetchPadronFail(state, action)
    case actionTypes.FETCH_PADRON_START:
      return fetchPadronStart(state, action)

    case actionTypes.CREATE_PROPRIETARY_SUCCESS:
      return createProprietarySuccess(state, action)
    case actionTypes.CREATE_PROPRIETARY_FAIL:
      return createProprietaryFail(state, action)
    case actionTypes.CREATE_PROPRIETARY_START:
      return createProprietaryStart(state, action)

    case actionTypes.IMPORT_PROPRIETARY_SUCCESS:
      return importProprietarySuccess(state, action)
    case actionTypes.IMPORT_PROPRIETARY_FAIL:
      return importProprietaryFail(state, action)
    case actionTypes.IMPORT_PROPRIETARY_START:
      return importProprietaryStart(state, action)

    case actionTypes.FETCH_EDIT_PERSON_SUCCESS:
      return fetchEditPersonSuccess(state, action)
    case actionTypes.FETCH_EDIT_PERSON_FAIL:
      return fetchEditPersonFail(state, action)
    case actionTypes.FETCH_EDIT_PERSON_START:
      return fetchEditPersonStart(state, action)

    case actionTypes.FETCH_PROPIETARY_FAMILY_SUCCESS:
      return fetchPropietaryFamilySuccess(state, action)
    case actionTypes.FETCH_PROPIETARY_FAMILY_FAIL:
      return fetchPropietaryFamilyFail(state, action)
    case actionTypes.FETCH_PROPIETARY_FAMILY_START:
      return fetchPropietaryFamilyStart(state, action)


    case actionTypes.FETCH_PROPIETARY_VEHICLE_SUCCESS:
      return fetchPropietaryVehicleSuccess(state, action)
    case actionTypes.FETCH_PROPIETARY_VEHICLE_FAIL:
      return fetchPropietaryVehicleFail(state, action)
    case actionTypes.FETCH_PROPIETARY_VEHICLE_START:
      return fetchPropietaryVehicleStart(state, action)



    case actionTypes.FETCH_SECURITYSTAFF_SUCCESS:
      return fetchSecurityStaffSuccess(state, action)
    case actionTypes.FETCH_SECURITYSTAFF_FAIL:
      return fetchSecurityStaffFail(state, action)
    case actionTypes.FETCH_SECURITYSTAFF_START:
      return fetchSecurityStaffStart(state, action)

    case actionTypes.CREATE_SECURITYSTAFF_SUCCESS:
      return createSecurityStaffSuccess(state, action)
    case actionTypes.CREATE_SECURITYSTAFF_FAIL:
      return createSecurityStaffFail(state, action)
    case actionTypes.CREATE_SECURITYSTAFF_START:
      return createSecurityStaffStart(state, action)



    default:
      return state
  }
}

export default reducer
