import styled from 'styled-components';
import React from 'react';


const TabInformation = styled.div`
display: flex;
justify-content: space-between;
`

export default TabInformation
