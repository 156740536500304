import React, { createRef } from 'react'
import { ContentWrapper, WrapperItems, RowItem, Description, Label, Container, CloseIcon, Row, TableContainer, Circle, ColumnItem, StyledButton, ErrorLabel, LoaderContainer, RowDataContainer } from './styled'
import MaterialTable from 'material-table'
import Section from '../../../components/Section'
import arrowDown from '../../../assets/icons/right-arrow.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchGetTransferPending, fetchApproveTransaction, fetchDeniedTransaction, resetTransactionOperationResponse } from '../../../store/actions/billetera.actions'
import Spinner from 'react-spinner-material'
import mapTransferPending from '../../../selectors/mapTransferPending'
import NewTokenRequest from '../../../components/AuthPopUp';
import { fetchReSendToken } from '../../../store/actions/billetera.actions'
import cross from "../../../assets/icons/close.png"
import Modal from '../../../components/Modal'
import PageLoader from '../../../components/Loader'


var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });

class AuthMovements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue,
            transferSelected: [],
            error: "",
            newResultModal: false,
            tableRef: React.createRef()
        };
    }


    componentDidMount() {
        this.props.fetchGetTransferPending()
        window.addEventListener('keydown', ({ key }) => {
            if (key === "Escape") {
                this.state.hasPendingApproveAuthWithToken ? this.toggleNewToken() : this.toggleNewResultModal()
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', ({ key }) => {
            if (key === "Escape") {
                this.state.hasPendingApproveAuthWithToken ? this.toggleNewToken() : this.toggleNewResultModal()
            }
        })
    }


    componentDidUpdate(prevProps) {
        if (this.props.billetera.ApproveTransaction !== prevProps.billetera.ApproveTransaction) {
            this.props.fetchGetTransferPending()
        } else if (this.props.billetera.DeniedTransaction !== prevProps.billetera.DeniedTransaction) {
            this.props.fetchGetTransferPending()
        }
    }



    handleTransferSelected = (selected) => {
        if (selected.length > 1) {
            let arrayTransaction = []
            selected.map(row => {
                arrayTransaction.push({ id: row.tranfer_request_id, status: row.status })
            })
            this.state.arrayTransaction = arrayTransaction
            //this.setState({ transferSelected: arrayTransaction})

        } else {
            if (selected.length === 0) {
                //this.setState({ transferSelected: selected})
                this.state.arrayTransaction = []
            } else {
                let arrayFormat = [{ id: selected[0].tranfer_request_id, status: selected[0].status }]
                this.state.arrayTransaction = arrayFormat
                //this.setState({ transferSelected: arrayFormat})
            }
        }
    }

    confirmTransaction(token) {
        let TransferBody = {
            TransfersId: this.state.arrayTransaction.map(transaction => transaction.id),
            Token: token
        }
        if (this.state.action === 'approve') {
            this.props.fetchApproveTransaction(TransferBody)
            this.setState({ newResultModal: true })
            this.props.fetchGetTransferPending()
        } else {
            this.props.fetchDeniedTransaction(TransferBody)
            this.setState({ newResultModal: true })
            this.props.fetchGetTransferPending()
        }
    }

    toggleNewTokenRequest = (action) => {
        if (!this.state.arrayTransaction || this.state.arrayTransaction.length === 0) {
            this.setState({ error: "Debe seleccionar una transferencia" })
        } else if (this.state.arrayTransaction.length > 10) {
            this.setState({ error: "Maximo 10 transferencias por operacion" })
        }
        else {
            let TransferToApprove = {
                TransfersId: this.state.arrayTransaction.map(transaction => transaction.id),
                Token: ''
            }
            var hasPendingApproveAuthWithToken = action == 'approve' && this.state.arrayTransaction.filter((transaction, key) => transaction.status == 'PEND_APPR').length > 0
            this.setState({ hasPendingApproveAuthWithToken: hasPendingApproveAuthWithToken })
            if (hasPendingApproveAuthWithToken)
                this.setState({ newTokenRequest: !this.state.newTokenRequest, action: action, error: "" })
            if (action === 'approve')
                this.props.fetchApproveTransaction(TransferToApprove)
            else
                this.props.fetchDeniedTransaction(TransferToApprove)
            if (!hasPendingApproveAuthWithToken) {
                this.setState({ newResultModal: true })
                this.props.fetchGetTransferPending()
            }
        }
    }

    toggleNewToken = (action) => {
        this.setState({ newResultModal: false, newTokenRequest: !this.state.newTokenRequest, action: action })
        this.props.fetchGetTransferPending()
    }
    toggleNewResultModal = (action) => {
        this.setState({ newResultModal: !this.state.newResultModal })
        this.props.resetTransactionOperationResponse();
    }

    newModal = (error) => {
        if (this.state.newResultModal) {
            return (
                <Modal height="22vw" width="43vw" background="#ffffff" onClose={this.state.hasPendingApproveAuthWithToken ? this.toggleNewToken : this.toggleNewResultModal}>
                    {this.props.billetera.errorApproveTransactionMessage !== false ? (
                        <Container>
                            <CloseIcon src={cross} onClick={this.state.hasPendingApproveAuthWithToken ? this.toggleNewToken : this.toggleNewResultModal} />
                            <WrapperItems>
                                <RowItem>
                                    <Label fontSize='23px' bold={true}> Resultado: </Label>
                                </RowItem>
                                <RowItem>
                                    <Description bold={true}>{this.props.billetera.errorApproveTransactionMessage}</Description>
                                </RowItem>
                            </WrapperItems>
                        </Container>
                    ) :
                        <>
                            {!this.props.isLoading && (this.props.ApproveTransfer.length != 0 || this.props.DeniedTransfer.length != 0) ? (
                                <Container>
                                    <WrapperItems>
                                        <RowItem>
                                            <Label fontWeight='300' fontSize='23px' bold={true}> Resultado: </Label>
                                        </RowItem>
                                        {this.props.ApproveTransfer.length != 0 ? (
                                            this.props.ApproveTransfer.map((transfer) => {
                                                let [messages, state] = transfer.message.split('.', 2)
                                                return (<RowItem>
                                                    <RowDataContainer>
                                                        <Description bold={true}>ID: {transfer.transferId}</Description>
                                                    </RowDataContainer>
                                                    <RowDataContainer>
                                                        <Description color={'rgb(125, 190, 85)'} bold={true}>{messages}</Description>
                                                    </RowDataContainer>
                                                    <RowDataContainer>
                                                        <Description color={'#daa520'} bold={true}>{state}</Description>
                                                    </RowDataContainer>

                                                </RowItem>)
                                            }
                                            )) : (this.props.DeniedTransfer.map((transfer) => {
                                                let [messages, state] = transfer.message.split('.', 2)
                                                return (<RowItem>
                                                    <RowDataContainer>
                                                        <Description bold={true} >ID: {transfer.transferId}</Description>
                                                    </RowDataContainer>
                                                    <RowDataContainer>
                                                        <Description color={'#B22222'} bold={true}>{messages}</Description>
                                                    </RowDataContainer>
                                                    <RowDataContainer>
                                                        <Description color={'#daa520'} bold={true}>{state}</Description>
                                                    </RowDataContainer>
                                                </RowItem>)
                                            }
                                            ))
                                        }
                                    </WrapperItems>
                                </Container>
                            ) : (
                                <LoaderContainer>
                                    <PageLoader />
                                </LoaderContainer>
                            )}
                        </>}
                </Modal>
            )
        } else if (this.state.newTokenRequest) {
            return (
                <NewTokenRequest
                    onClose={this.toggleNewToken}
                    chargeToken={(e) => this.confirmTransaction(e)}
                    fetchReSendToken={this.props.fetchReSendToken}
                />
            )
        }
    }

    renderTableTransferPending = () => {
        if (this.props.TransferRequestFormatted) {
            return (
                <TableContainer>
                    <MaterialTable
                        columns={[

                            {
                                title: 'Importe', field: 'amount', type: 'currency',
                                currencySetting: { currencyCode: "ARS" },
                                render: rowData => formatter.format(rowData.amount),
                            },
                            {
                                title: 'Alias',
                                field: 'alias',
                            },
                            {
                                title: 'Categoría',
                                field: 'category',
                            },
                            {
                                title: 'Origen',
                                field: 'origin',
                            },
                            {
                                title: 'Destinatario',
                                field: 'destination',
                            },
                            {
                                title: 'Fecha de solicitud',
                                field: 'dateRequest',
                            },
                            {
                                title: 'Fecha de pago',
                                field: 'datePay',
                            },
                        ]}
                        data={this.props.TransferRequestFormatted}
                        tableRef={this.state.tableRef}
                        onRowClick={(event, rowData) => {
                            // Update the clicked rows checked state
                            rowData.tableData.checked = !rowData.tableData.checked;

                            // pass dataManager the current rows checked state and path/ID, the path/ID needs to be an array, ex: [1]
                            this.state.tableRef.current.dataManager.changeRowSelected(
                                rowData.tableData.checked,
                                [rowData.tableData.id]
                            );

                            // call the onSelectionChange and pass it the row selected to ensure it updates your selection properly for any custom onSelectionChange functions.
                            this.state.tableRef.current.onSelectionChange(rowData);
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}

                        onSelectionChange={(rows) => this.handleTransferSelected(rows)}
                        detailPanel={[
                            {
                                icon: () => (<CloseIcon src={arrowDown} />),
                                tooltip: 'Ver detalles',
                                render: rowData => {
                                    return (
                                        <Row>
                                            <Label style={{marginRight:"20px"}}>Creada por:<Label bold={true}> {rowData.origin} </Label></Label>
                                            <Label>Autorizantes:
                                            {(() => {
                                                switch (rowData.status) {
                                                    case 'PEND_ADMIN':
                                                        return (
                                                            <>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Administrador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Operador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Aprobador </Label>
                                                            </>
                                                        )
                                                    case 'PEND_OPER':
                                                        return (
                                                            <>
                                                                
                                                                <Label bold={true}><Circle status={'aprobado'} /> Administrador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Operador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Aprobador </Label>
                                                            </>
                                                        )
                                                    case 'PEND_APPR':
                                                        return (
                                                            <>
                                                                
                                                                <Label bold={true}><Circle status={'aprobado'} /> Administrador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'aprobado'} /> Operador </Label>
                                                                
                                                                <Label bold={true}><Circle status={'pendiente'} /> Aprobador </Label>
                                                            </>
                                                        )
                                                    default:
                                                        return null;
                                                }
                                                
                                            })()}
                                            </Label>
                                        </Row>
                                    )
                                },
                            }]}
                        style={{ padding: "0%" }}
                        options={{
                            toolbar: false,
                            paging: false,
                            height: '300px',
                            selection: true,
                            headerStyle: {
                                zIndex: 0
                            },
                            rowStyle: rowData => ({
                                backgroundColor: rowData.tableData.id % 2 == 0 ? "rgba(45,199,109,0.1)" : "#fff"
                            })
                        }} //Esconde titulo y search y pagination 
                    />
                </TableContainer>
            )
        } else {
            return (
                <TableContainer>
                    <PageLoader />
                </TableContainer>
            )
        }
    }


    render() {
        return (
            <Section
                title={'Transferencias pendientes de autorización'}
                onBack={() => this.props.onClose()}
            >
                {this.newModal()}
                <ContentWrapper  >
                    {this.renderTableTransferPending()}
                    <Row justify={'flex-end'} >
                        <StyledButton color={true} onClick={() => this.toggleNewTokenRequest('denied')}> Rechazar</StyledButton>
                        <StyledButton onClick={(row) => this.toggleNewTokenRequest('approve')}>Autorizar </StyledButton>
                    </Row>
                    <Row margin={'10px 0px'} justify={'flex-end'} >
                        <ErrorLabel>
                            {this.state.error.length > 0 ? (this.state.error) : (null)}
                        </ErrorLabel>
                    </Row>
                </ContentWrapper>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        TransferRequestFormatted: mapTransferPending(state.billetera.TransferPending),
        ApproveTransfer: state.billetera.ApproveTransaction ? state.billetera.ApproveTransaction : [],
        DeniedTransfer: state.billetera.DeniedTransaction ? state.billetera.DeniedTransaction : [],
        billetera: state.billetera,
        isLoading: state.billetera.loadingApproveTransaction || state.billetera.loadingDeniedTransaction
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchGetTransferPending,
            fetchApproveTransaction,
            fetchDeniedTransaction,
            fetchReSendToken,
            resetTransactionOperationResponse
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthMovements)

