import React from 'react';
import Routes from './routes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { saveCacheUserInfo } from './utils/session'
import { authCheckState } from './store/actions/auth.actions'
import { updateUserInfo, setNeighbourhood } from './store/actions/userInfo.actions'
import {IntlProvider} from 'react-intl';
import withClearCache from "./ClearCache"; 
import MiiiiLanguague from './lang/miiii.json';
import CatamarcaLanguague from './lang/catamarca.json';

const domain = document.domain
const subdomain = domain.split('.');
const locale = navigator.language;
let lang;

if (subdomain==="backoffice.staging") {
   lang = MiiiiLanguague;
} else {
   if (subdomain === "localhost") {
       lang = CatamarcaLanguague;
   } else {
       lang = MiiiiLanguague;
   }
}
class App extends React.Component {
  state={
    subdomain: subdomain.length > 1 ? subdomain[0]+"."+subdomain[1] : subdomain[0]
  }
  componentDidMount() {
    this.props.authCheckState()
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) this.generateInitialUserInformation()
  }

  generateInitialUserInformation = () => {
    if (this.props.userInfo.username !== this.props.user.username){
      this.props.updateUserInfo(this.props.user)
    }
    saveCacheUserInfo(this.props.user)
  }


  render() {
    const childProps = {}
    const { themeConfig, layoutConfig, isAuthenticated } = this.props
    const resolution= window.innerWidth;
    let responsive= false;
    if(resolution < 800){responsive= true}

    document.getElementsByName("description").content="Bienvenido al BackOffice de Miiii. Aqui podra administrar todo lo que pasa en el barrio."
    sessionStorage.setItem(
      'portalData',
      JSON.stringify({
        theme: {
          ...themeConfig, 
          typography: {
            useNextVariants: true,
          },
        },
        layout: {
          ...layoutConfig
        }
      })
    )

    // console.log("idioma navegador:",locale)
    // console.log("subdominio:",subdomain)
   
    return ( 
    <IntlProvider locale ={locale} messages={lang}>
      <Routes childProps={childProps} layout={layoutConfig} responsive={responsive} isAuth={isAuthenticated} />
    </IntlProvider>
    )
    
    
  }
}



function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userInfo: state.userInfo,
    isAuthenticated: state.auth.user
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authCheckState,
      updateUserInfo,
      setNeighbourhood
    },
    dispatch
  )

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(withClearCache(App))
