import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PaperLayout from '../../../components/PaperLayout'
import Modal from '../../../components/Modal'

import { getFullDateTimePlus, getFullDateTime } from '../../../utils/dateParser'

import Paso1 from './paso1';
import Paso2 from './paso2';
import Paso3 from './paso3';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,
    fetchPaymentMethods, fetchGetShippingMethods, fetchAddProductReset, fetchItemConditions, fetchGetCategories,
    fetchAddProduct, fetchEditProduct
} from '../../../store/actions/clasificados.actions'

import compose from 'recompose/compose'


const talles = [{ label: 'XS', value: '0' },
{ label: 'S', value: '1' },
{ label: 'M', value: '2' },
{ label: 'L', value: '3' },
{ label: 'X', value: '4' },
{ label: 'XL', value: '5' }];


const colores = [{ label: 'Rojo', value: '0' },
{ label: 'Verde', value: '1' },
{ label: 'Amarillo', value: '2' },
{ label: 'Azul', value: '3' },
{ label: 'Violeta', value: '4' },
{ label: 'Blanco', value: '5' }];


class AddProduct extends Component {

    state = {
        step: {
            name: 'Crear',
            number: 1,
            percentage: 33,
        },
        activeIndex: 0,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }

    NextStep = () => {
        this.setState(prevState => ({
            step: {
                ...prevState.step,
                number: prevState.step.number + 1,
                percentage: prevState.step.percentage + 33
            }
        }))
    }


    BackStep = () => {
        this.setState(prevState => ({
            step: {
                ...prevState.step,
                number: prevState.step.number - 1,
                percentage: prevState.step.percentage - 33
            }
        }))
    }

    handleContinuar = () => {
        this.setState({ step: this.state.step + 1 });
    }

    handleAddProduct = () => {
        const { formData } = this.state;
        const paso1 = formData[0];
        const paso2 = formData[1];
        const paso3 = formData[2];

        const formAddProduct = {
            'title': paso1.titulo,
            'description': paso1.descripcion,
            'shopitemconditionid': paso2.itemCondition.value,
            'shopitemcategoryid': paso1.categoria.value,
            'quantity': paso2.stock,
            'publicationstartdate': getFullDateTime(),
            'publicationenddate': getFullDateTimePlus(30),
            'neighbourhoods': this.props.neighbourhoods,
            'keywords':
                [],
            'prices':
                [{
                    'price': paso2.importe,
                    'currency': 'ARS'
                }],

        };

        //acceptedpaymentmethodlist
        formAddProduct.deliverymethods = paso3.deliverymethods.map(deliver => ({
            id: deliver.value
        }))

        //acceptedpaymentmethodlist
        formAddProduct.acceptedpaymentmethodlist = paso3.acceptedpaymentmethodlist.map(pay => ({
            paymentmethodcode: pay.value,
            paymentmethodname: pay.label
        }))


        if (paso3.images !== undefined) {
            formAddProduct.images = paso3.images.map(image => ({ ImageBase64: image, extension: this.guessImageMime(image) }))
        }

        this.props.fetchAddProduct(formAddProduct)
        // this.props.toggleAddProd()

    }

    guessImageMime(data) {
        if (data.charAt(0) == '/') {
            return "JPEG";
        } else if (data.charAt(0) == 'R') {
            return "GIF";
        } else if (data.charAt(0) == 'i') {
            return "PNG";
        }
    }

    getFormData = (data, index) => {
        const currentState = [...this.state.formData];
        currentState[index] = { validated: true, ...data };
        this.setState({
            formData: currentState
        });
        this.changeIndex(index, true);
    }

    changeIndex = (index, next) => {
        const { activeIndex } = this.state
        let newIndex;
        if (next) {
            newIndex = activeIndex;
            newIndex++;
        } else {
            newIndex = activeIndex === index ? -1 : index
        }
        this.setState({ activeIndex: newIndex })
    }

    handleVolver = () => {
        this.setState({ step: this.state.step - 1 });
    }

    openWebcamModal = () => {
        this.setState({ cameraModalOpen: !this.state.cameraModalOpen, });
    }

    closeAndResetRedux() {
        this.props.fetchAddProductReset()
        this.props.toggleAddProd()
    }

    render() {

        return (
            <Modal onClose={() => this.closeAndResetRedux()}>
                <PaperLayout title={'Agregar Nuevo Producto'} Step={this.state.step} style={{ bakcgroundColor: 'none' }}>

                    {(() => {
                        switch (this.state.step.number) {
                            case 1:
                                return (
                                    <Paso1
                                        formDataValidation={this.state.formData[0].validated}
                                        dataSaved={this.state.formData[0]}

                                        changeIndex={this.changeIndex.bind(this)}
                                        getFormData={this.getFormData}
                                        index={0}
                                        active={this.state.activeIndex === 0}
                                        handleContinuar={() => this.NextStep()}
                                        handleVolver={() => this.BackStep()}
                                    />
                                )
                            case 2:
                                return (
                                    <Paso2
                                        formDataValidation={this.state.formData[1].validated}
                                        dataSaved={this.state.formData[1]}
                                        changeIndex={this.changeIndex.bind(this)}
                                        getFormData={this.getFormData}
                                        index={1}
                                        active={this.state.activeIndex === 1}
                                        handleContinuar={() => this.NextStep()}
                                        handleVolver={() => this.BackStep()}
                                    />
                                )
                            case 3:
                                return (<Paso3
                                    dataSaved={this.state.formData[2]}
                                    formDataValidation={this.state.formData[2].validated}
                                    changeIndex={this.changeIndex.bind(this)}
                                    getFormData={this.getFormData}
                                    index={2}
                                    active={this.state.activeIndex === 2}
                                    handleAddProductAPI={() => this.handleAddProduct()}

                                    handleVolver={() => this.BackStep()}
                                    resetRedux={() => this.props.fetchAddProductReset()}
                                    backtoClasif={() => this.closeAndResetRedux()}
                                />)
                            default:
                                return null;
                        }
                    })()}
                </PaperLayout >
            </Modal>
        )
    }
}
const mapStateToProps = ({
    clasificados,
    userInfo
}) => ({
    clasificados,
    fetchAddProductSuccess: clasificados.fetchAddProductSuccess,
    fetchAddProductFail: clasificados.fetchAddProductFail,
    loadingAddProduct: clasificados.loadingAddProduct,
    neighbourhoods: userInfo.neighbourhoods

})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchAddProduct, fetchEditProduct, fetchGetShippingMethods, fetchAddProductReset }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(AddProduct)
)