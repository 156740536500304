import React from 'react';
import styled from 'styled-components';

const EmployeeContainer = styled.div`
  display: flex;
  align-items: center;
  float: left;
  ${({ bronze }) => bronze && `color: #ff2424; cursor: pointer;`}
`
const EmployeePicture = styled.img`
  width: 34px;
  height: 34px;
  margin-right: 5px;
  object-fit: contain;
`

const Person = (props) => <>{props.picture.length > 2 ? <EmployeeContainer {...props}><EmployeePicture alt={props.fullname} src={props.picture}/> {props.fullname}</EmployeeContainer>: <EmployeeContainer>{props.fullname}</EmployeeContainer>}</>

export default Person