import * as actionTypes from './actionTypes'

import {
    fetchDashBilletera, fetchDashPersona
} from '../../apiConstants'

import getApi from '../../shared/api'

export const fetchBilleteraDash = () => {
    return async (dispatch, getState) => {
        dispatch(fetchBilleteraDashStart())
        const {
            auth: {
                user: {                    
                    selectedNeighbourhood: { guid }
                }
            }
          } = getState()
          const api = await getApi(guid)

        try {
            const response = await api.get(fetchDashBilletera)
            dispatch(fetchBilleteraDashSuccess(response.data))
        } catch (error) {
            dispatch(fetchBilleteraDashFail(error.response.data.message))
        }
    }
}

export const fetchBilleteraDashSuccess = data => {
    return { type: actionTypes.FETCH_BILLETERA_DASH_SUCCESS, data: data }
}

export const fetchBilleteraDashFail = error => {
    return { type: actionTypes.FETCH_BILLETERA_DASH_FAIL, error: error }
}

export const fetchBilleteraDashStart = () => {
    return { type: actionTypes.FETCH_BILLETERA_DASH_START }
}



export const fetchPersonaDash = () => {
    return async (dispatch, getState) => {
        dispatch(fetchPersonaDashStart())
        const {
            auth: {
                user: {                    
                    selectedNeighbourhood: { guid }
                }
            }
          } = getState()
          const api = await getApi(guid)
        try {
            const response = await api.get(fetchDashPersona)
            dispatch(fetchPersonaDashSuccess(response.data))
        } catch (error) {
            dispatch(fetchPersonaDashFail(error.response.data.message))
        }
    }
}

export const fetchPersonaDashSuccess = data => {
    return { type: actionTypes.FETCH_PERSONA_DASH_SUCCESS, data: data }
}

export const fetchPersonaDashFail = error => {
    return { type: actionTypes.FETCH_PERSONA_DASH_FAIL, error: error }
}

export const fetchPersonaDashStart = () => {
    return { type: actionTypes.FETCH_PERSONA_DASH_START }
}