import styled, { css } from 'styled-components'
import theme from "../../../../../config/theme"
export const ScheduleContainer = styled.div`
  width:380px;
  padding: 10px 15px;
  position: absolute;
  z-index:999;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: auto;

`

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const FlexWrapper = styled.div`
width: ${({ width }) => width || 100}%;
  display: ${({ hidden }) => hidden ? 'hidden' : 'flex'};
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: ${({ align }) => align || 'flex-start'};
`


export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};  
`

export const SubTitle = styled.label`
font-weight: bold; 
`


export const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 14px;
  margin-top: ${(props) => props.margintop ? props.margintop : '10px'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};  
`

export const CancelButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.dustyGray};
  width: ${(props) => props.width ? props.width : '40%'};
  background-color: ${({ theme }) => theme.colors.ghost};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`

export const Row = styled.div`
  width: 100%;
  display:flex;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
  justify-content:${(props) => props.justifyContent ? props.justifyContent : 'space-between'};
  margin-top: 10px;
  margin-top:${(props) => props.marginTop ? props.marginTop : '10px'};
`
export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`

export const ErrorLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  margin: 5px;
  width: calc(100% - 1em);
`

export const SaveButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  width: ${(props) => props.width ? props.width : '40%'};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`

export const Close = styled.img`
  width: auto;
  align-self:right;
  cursor: pointer;
  margin-bottom:10px;

`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.midGray};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  align-items: center;
`
export const StyledButtonBooking = styled.button`
  background-color: ${({ theme }) => theme.colors.midGray};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  align-items: center;
`

export const CrossImage = styled.img`
width: 15px;
    height: 15px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

`


export const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'}; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom:5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.midGray};
`
export const TitleModal = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-weight: 500;
  font-size: 20px;
  width: 80%;
  text-align: left;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '10px'};  
`

export const StyledLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
`
export const StyledLabelBooking = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  width:  ${({ width }) => width ? width : '100%'};
`


export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  width:  ${({ width }) => width || '100%'};
  font-weight:${({ fontWeight }) => fontWeight || 'bold'};
  font-size: 14px;
  padding-bottom: 5px;
  padding-right: 20px;
  displayt:${({ display }) => display || 'block'};
`
export const CheckBoxWrapper = styled.div`
background-color:${({ theme }) => theme.colors.midgray};
`




export const Ahref = styled.a`
    cursor: pointer;
    color:${({ color }) => color || theme.colors.midGray};
    text-decoration:underline
`

export const InputWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input{
            border:solid 1px hsl(0,0%,80%);
      }
    }
  }
`

export const AhrefWrapper = styled.div`
  margin:  ${({ margin }) => margin || 0.5}em;
  display:inline:
`
export const ErrorsWrapper = styled.div`
  width: calc(${({ width }) => width || 100}% - ${({ margin }) => margin * 2 || 1}em); 
  margin:  ${({ margin }) => margin || 0.5}em;
`

export const Container = styled.div`
  position: relative;
`


export const LoaderWrapper = styled.div`
     margin:auto;
     padding-top:4em;
    padding-bottom:4em;
`

export const GrayedArea = styled.div`
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05112c99;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DeleteIconWrapper = styled.div`
display:inline-flex;
cursor:pointer;
`