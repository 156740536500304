import { createSelector } from 'reselect'
var moment = require('moment');

let mapLastMovementsReport = createSelector(
  movements => movements,
  movements =>
  (movements.map(movement => ({
    ...movement,
    date: moment(movement.date).format('DD-MM-YYYY HH:mm'),
  })))
)
export default mapLastMovementsReport