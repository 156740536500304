
import * as actionTypes from './actionTypes'
import {   
  fetchReporteMascotasAPI,
  fetchReporteTicketsAPI,
  fetchReporteReservasAPI, 
  fetchUserPropertyAPI,
  fetchReporteExpensasAPI,
  fetchReporteClientsByNeighbourhoodAPI,
  fetchReporteBilleterasPorBarrioAPI,
  dogReportsAPI

} from '../../apiConstants'
import getApi from '../../shared/api'

////////////////////// Billeteras por barrio //////////////////////

export const fetchReporteBilleterasPorBarrioSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_SUCCESS,
    data: data
  }
}

export const fetchReporteBilleterasPorBarrioFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_FAIL,
    error : error
  }
}

export const fetchReporteBilleterasPorBarrioStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_BILLETERAS_POR_BARRIO_START
  }
}

export const fetchReporteBilleterasPorBarrio = (dataPost) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteBilleterasPorBarrioStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)
    return api
    .post(`${fetchReporteBilleterasPorBarrioAPI}`,dataPost)
    .then(res => {
      dispatch(fetchReporteBilleterasPorBarrioSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteBilleterasPorBarrioFail(error))
    })
  }
}



////////////////////// ClientsByNeighbourhood //////////////////////

export const fetchReporteClientsByNeighbourhoodSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_SUCCESS,
    data: data
  }
}

export const fetchReporteClientsByNeighbourhoodFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_FAIL,
    error : error
  }
}

export const fetchReporteClientsByNeighbourhoodStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_START
  }
}

export const fetchReporteClientsByNeighbourhood = (dataPost) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteClientsByNeighbourhoodStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)
    return api
    .post(`${fetchReporteClientsByNeighbourhoodAPI}`,dataPost)
    .then(res => {
      dispatch(fetchReporteClientsByNeighbourhoodSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteClientsByNeighbourhoodFail(error))
    })
  }
}

//////////////////////////////////////////////////////



////////////////////// Expensas //////////////////////

export const fetchReporteExpensasSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORTE_EXPENSES_SUCCESS,
    data: data
  }
}

export const fetchReporteExpensasFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_EXPENSES_FAIL,
    error : error
  }
}

export const fetchReporteExpensasStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_EXPENSES_START
  }
}

export const fetchReporteExpensas = (dataForm) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteExpensasStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)
    return api
    .post(`${fetchReporteExpensasAPI}`,dataForm)
    .then(res => {
      dispatch(fetchReporteExpensasSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteExpensasFail(error))
    })
  }
}

//////////////////////////////////////////////////////







////////////////////// Mascotas //////////////////////

export const fetchReporteMascotasSuccess = mascotas => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_SUCCESS,
    mascotas: mascotas
  }
}

export const fetchReporteMascotasFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_FAIL,
    errorReporteMascotas : error
  }
}

export const fetchReporteMascotasStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_START
  }
}

export const fetchReporteMascotas = () => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteMascotasStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)
    return api
    .get(`${fetchReporteMascotasAPI}`)
    .then(res => {
      dispatch(fetchReporteMascotasSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteMascotasFail(error))
    })
  }
}

//////////////////////////////////////////////////////

////////////////////// Tickets //////////////////////

export const fetchReporteTicketsSuccess = tickets => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_SUCCESS,
    tickets: tickets
  }
}

export const fetchReporteTicketsFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_FAIL,
    errorReporteTickets : error
  }
}

export const fetchReporteTicketsStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_START
  }
}

export const fetchReporteTickets = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteTicketsStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    console.log("params",params)
    const api = await getApi(guid)
    return api.get(`${fetchReporteTicketsAPI}?`+
        `${params.property !== null ? 'property='+params.property+'&' : ''}`+
        `${params.type !== null && params.type !=='' ? 'type='+params.type+'&' : ''}`+
        `${params.category !== null && params.category !== '' ? 'category='+params.category+'&' : ''}`+
        `startTime=${params.startDate.toISOString()}&`+
        `endTime=${params.endDate.toISOString()}`)
    .then(res => {
      dispatch(fetchReporteTicketsSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteTicketsFail(error))
    })
  }
}

/////////// RESERVAS

export const fetchReporteReservasSuccess = reporteReservas => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_SUCCESS,
    reporteReservas: reporteReservas
  }
}

export const fetchReporteReservasFail = error => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_FAIL,
    errorReporteReserva : error
  }
}

export const fetchReporteReservasStart = () => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_START
  }
}

export const fetchReporteReservas = (formattedBody) => {
  return async (dispatch, getState) => {

    dispatch(fetchReporteReservasStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api.get(`${fetchReporteReservasAPI}/?spaceId=${formattedBody.spaceId}&activityId=${formattedBody.activityId}&stringstartdate=${formattedBody.startDate.toISOString()}&stringenddate=${formattedBody.endDate.toISOString()}`)
    .then(res => {
      dispatch(fetchReporteReservasSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteReservasFail(error))
    })
  }
}

/////////// RESERVAS

export const fetchReporteUserPropertySuccess = reporteProperty => {
  console.log("action reporte barrio ok",reporteProperty)
  return {
    type: actionTypes.FETCH_USERPROPERTY_REQUEST_SUCCESS,
    reporteProperty: reporteProperty
  }
}

export const fetchReporteUserPropertyFail = error => {
  return {
    type: actionTypes.FETCH_USERPROPERTY_REQUEST_FAIL,
    errorReporteProperty : error
  }
}

export const fetchReporteUserPropertyStart = () => {
  return {
    type: actionTypes.FETCH_USERPROPERTY_REQUEST_START
  }
}

export const fetchReporteUserProperty = (value) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteUserPropertyStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    const formattedBody = {
      NeighbourhoodParentCode: value
    }
    console.log("ejecutio action",formattedBody)
    return api.post(fetchUserPropertyAPI,formattedBody)
    .then(res => {
      dispatch(fetchReporteUserPropertySuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteUserPropertyFail(error))
    })
  }
}

/////////// DOG REPORT - USERS//////////////////////

export const fetchUsersFunnelDataSuccess = data => {
  return {
    type: actionTypes.FETCH_USERS_FUNNEL_DATA_SUCCESS,
    usersFunnelData: data
  }
}

export const fetchUsersFunnelDataFail = error => {
  return {
    type: actionTypes.FETCH_USERS_FUNNEL_DATA_FAIL,
    errorFetchUsersFunnelData : error
  }
}

export const fetchUsersFunnelDataStart = () => {
  return {
    type: actionTypes.FETCH_USERS_FUNNEL_DATA_START
  }
}

export const fetchUsersFunnelData = () => {
  return async (dispatch) => {

    dispatch(fetchUsersFunnelDataStart())

    const api = await getApi()

    return api.get(dogReportsAPI + '/users')
    .then(res => {
      dispatch(fetchUsersFunnelDataSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchUsersFunnelDataFail(error))
    })
  }
}

/////////// DOG REPORT - LOTES //////////////////////

export const fetchPropertiesFunnelDataSuccess = data => {
  return {
    type: actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_SUCCESS,
    propertiesFunnelData: data
  }
}

export const fetchPropertiesFunnelDataFail = error => {
  return {
    type: actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_FAIL,
    errorFetchPropertiesFunnelData : error
  }
}

export const fetchPropertiesFunnelDataStart = () => {
  return {
    type: actionTypes.FETCH_PROPERTIES_FUNNEL_DATA_START
  }
}

export const fetchPropertiesFunnelData = () => {
  return async (dispatch) => {
    dispatch(fetchPropertiesFunnelDataStart())

    const api = await getApi()

    return api.get(dogReportsAPI + '/properties')
    .then(res => {
      dispatch(fetchPropertiesFunnelDataSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchPropertiesFunnelDataFail(error))
    })
  }
}