import styled from 'styled-components';
import Select from 'react-select';

export const Paper = styled.div`
  background-color: #FFFFFF;
  padding: 20px;
  height: 100%;  
`
export const KitsRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  overflow-x: hidden;
  height: 60px;
  ${({ edit }) => edit && `
    border-bottom: solid 1px #f2f2f2;
  `}
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
`

// Treatment Details
export const TreatmentDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${({ flexend }) => flexend && `
    justify-content: flex-end;
  `}
`
export const Box = styled.div`
  display: flex;
  border-radius: 4px;
  border: solid 1px #e9e9ec;
  background-color: #fafafa;
  padding: 20px;
  margin-bottom: 25px;
  flex-direction: column;
`
export const Icon = styled.img`
  width: 18px;
  margin-right: 10px;
`
export const Label = styled.label`
  font-size: 14px;
  line-height: 1.86;
  color: #4a4a4a;
`
export const Bold = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
`
export const Info = styled.span`
  font-size: 14px;
  color: #001441;
  margin-bottom: 10px;
`
export const Price = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #ff2424;
`
export const Location = styled.div`
  font-size: 16px;
  line-height: 1.63;
  color: #000000;
  display: flex;
  justify-content: space-between;
`
export const Cancel = styled.span`
  font-size: 14px;
  color: #d0021b;
  cursor: pointer; 
  margin-bottom: 15px;
`
export const Client = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
    color: #4a4a4a;
  }
`

// Add Service

export const RowForm = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  & > div {
    margin-right: 20px;
  }
  & > div:last-child {
    margin-right: 0px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  ${({ flexend }) => flexend && `
    justify-content: flex-end;
  `}
  ${({ flex }) => flex && `
    flex: ${flex};
  `}
`
export const Title = styled.label`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  color: #000000;
`
export const StyledSelect = styled(Select)` 
  & > div > div:nth-child(2) {
    background-color: rgba(244, 247, 249, 0.4);
  }
  & > div > div {
    height: 40px;
  }
  & span {
    margin: 0px;
  }
`
export const LabelForm = styled.label`
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 5px;
`