import React from 'react'

import LeftArrow from '@material-ui/icons/ArrowBack';

import { TyTitle, PaperStyled, ContainerProgressBar, GridButtonBack, StyledImg, ButtonBack } from './styled'
import ProgressBar from '../ProgressBar'

class PaperLayout extends React.Component {

  state = {
    isIncomplete: false,
    onHandleClose: this.props.onClose,
  }


  onHandleClose = () => {
    this.props.onClose()
  }

  render() {

    if (this.props.created) {
      this.onHandleClose()
    }

    return (
      <div>
        <GridButtonBack item >
          <h2 style={{ marginTop: '10px', marginBottom: '20px' }}>{this.props.title}</h2>
        </GridButtonBack>
        <PaperStyled >
          <ContainerProgressBar>
            <ProgressBar Step={this.props.Step} />
          </ContainerProgressBar>

          {this.props.children}

        </PaperStyled>
      </div>
    )
  }
}


export default PaperLayout;

