import React from 'react'
import {
    ExtendedContentWrapper,
    ElementContainer,
    Element,
    SliderWrapper,
    Paragraph,
    BoldParagraph,
    ContainerVideo
} from './styled'
import HomeBaseContent from '../../../components/HomeBaseContent'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Player, BigPlayButton } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css";

class Seguridad extends React.Component {
    renderExtendedContent = () => {
        const { sliderImages, boldContent, content, } = this.props
        return (
            <ExtendedContentWrapper>

                <BoldParagraph>{boldContent}</BoldParagraph>
                <Paragraph dangerouslySetInnerHTML={{ __html: content }} />
                {this.renderSlider(sliderImages)}
            </ExtendedContentWrapper>
        )
    }

    renderSlider = sliderImages => {
        const sliderSettings = {
            centerMode: true,
            slidesToShow: 1,
            arrows: false
        }
        if (sliderImages) {
            return (
                <SliderWrapper>
                    {<Slider {...sliderSettings}>
                        <ElementContainer>
                            {this.props.video ? (
                                <Player
                                    playsInline={true}
                                    src={this.props.video}
                                    width={100}
                                    height={100}
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                            ) : (<></>)}
                        </ElementContainer>
                        {sliderImages.map((img, imgIndex) => (
                            <ElementContainer key={imgIndex}>
                                <Element src={img} />
                            </ElementContainer>
                        ))}
                    </Slider>}
                </SliderWrapper>
            )
        }
        return (
            <SliderWrapper>
                <Slider {...sliderSettings}>

                </Slider>
            </SliderWrapper>
        )
    }

    render() {
        const {
            extended,
            title,
            subTitle,
            articleImage,
            userName,
            type,
            userImage,
            onClick,
            publicationdate,
            onDelete,
            video,
            document,
            onEdit
        } = this.props
        return (
            <HomeBaseContent
                title={title}
                subTitle={subTitle}
                userName={userName}
                type={type}
                articleImage={articleImage}
                userImage={userImage}
                extended={extended}
                onClick={onClick}
                date={publicationdate}
                onDelete={onDelete}
                onEdit={onEdit}

                document={document}
                privateContent
            >
                <>
                    <a
                        href={document}
                        download="Documentos adjunto articulo.pdf"
                        id="download"
                        target="_blank"
                        hidden
                    ></a>
                    {extended && this.renderExtendedContent()}
                </>
            </HomeBaseContent>
        )
    }
}

export default Seguridad
