import React from 'react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import FileInput from '../../../../components/InputFile'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoFilePdf from '../../../../assets/icons/txt-file.svg'
import { importFile } from '../../../../store/actions/regulations.actions';
import Loader from '../../../../components/Loader';


import {
  FormContainer,
  Row,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  SpinerWrapper
} from './styled'

const mapStateToProps = state => {
  const {
    errorFile,
    uploadingFile
  } = state.regulations;

  return {
    errorFile: errorFile,
    uploadingFile: uploadingFile
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { importFile },
    dispatch
  )

function ImportFileModal(props) {
  const { errorFile, uploadingFile, importFile } = props;

  const getInitialValues = () => ({
  })

  const getSignUpSchema = () =>
    Yup.object().shape({
      file: Yup.object().required('Debe seleccionar un archivo')
    })

  const getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <InputWrapper>
          <FileInput onChange={file => setFieldValue("file", file)} withName={true} nofile={NoFilePdf} acceptTypes={"text/plain"} />
          {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {errorFile ? (
        <ButtonWrapper>
          <ErrorLabel>Error al cargar el archivo,verifique si es el correcto</ErrorLabel>
        </ButtonWrapper>
      ) : (<> </>)}
      {
        errors.file ? (
          <ButtonWrapper>
            <ErrorLabel>{errors.file}</ErrorLabel>
          </ButtonWrapper>
        ) : null
      }
      {uploadingFile ?
        (
          <SpinerWrapper><Loader /></SpinerWrapper>
        ) :
        (
          <ButtonWrapper>
            <Button medium type={'submit'} width={'180px'}>Subir archivo</Button>
          </ButtonWrapper>
        )}
    </FormContainer>
  )

  const handleSubmit = async data => {
    await importFile(data);
    if (!errorFile) props.close(true)
  }

  function encodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  }

  return (
    <Modal isLoading={uploadingFile} onClose={() => props.close()} height={'min-height'} widht={'430px'}>
      <div style={{ textAlign: 'center' }}>

        {uploadingFile ?
          (
            <SpinerWrapper><Loader /></SpinerWrapper>
          ) :
          (
            <>
              <h2>Subir informe</h2>
              <Formik
                initialValues={getInitialValues()}
                validateOnChange={false}
                validationSchema={getSignUpSchema()}
                onSubmit={handleSubmit}
                render={e => getFormContent(e)}
              />
            </>
          )}
      </div>

    </Modal>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportFileModal)