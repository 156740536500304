
import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'

import SectionHeader from '../../../components/SectionHeader'


class Reportes extends React.Component {

    state = {


    };


    render() {

        return (
            <>
                <SectionHeader title={'Reportes e Informes'} ></SectionHeader>
            </>
        );
    }

}

const mapStateToProps = ({
    userInfo: { neighbourhoods, selectedNeighbourhood, menusAuth },

}) => {
    return {


    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({



    }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Reportes)
)
