import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../../../../assets/icons/close-white.svg';

const GrayedArea = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #05112c99;
  display: flex;
  justify-content: center;
  align-items: center;
 
`
const ModalContainer = styled.div`
  z-index: 3;
  width:  ${({ width }) => width ? width : "710px"};
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  position: relative;
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ minheight }) => minheight && `min-height: ${minheight}; height: unset;`}
`
const Body = styled.div`
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
`
const Close = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  right: 2%;
  top: 3%;
  fill: #fffff;
  z-index: 99;
`
const Header = styled.div`
width:100%;
font-size: 22px;
font-weight: 500;
color: #30735f;
align-self: center;
margin: 30px 0 10px 0;
padding-bottom:10px;
border-bottom:1px solid #d2d2d2;
`


const ModalCommunity = (props) => { 
  return (
    <GrayedArea>
      <ModalContainer {...props} minheight={'632px'}>
        {!props.isLoading?<Close src={CloseIcon} onClick={() => props.onClose()}></Close>:<></>}
        <Body>
        {props.children}
        </Body>
      </ModalContainer>
    </GrayedArea>
  );
}

export default ModalCommunity