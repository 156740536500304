import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import MaterialTable from 'material-table';
import SectionHeader from '../../../components/SectionHeader';
import LoaderImage from '../../../components/LoaderImage';
import EmptyLoader from "../../../assets/animation/loader.gif"
import ModalMessage from '../../../components/ModalMessage'
import FilterBox from '../../../components/filterBox'
import DetailOrderModal from '../../../components/ModalOrderDetail'
import aprobado from '../../../assets/icons/aprobado.svg'
import cajaIcon from '../../../assets/icons/cajaIcon.svg'
import transitoIcon from '../../../assets/icons/transitoIcon.svg'
import casaIcon from '../../../assets/icons/casaIcon.svg'
import infoIcon from '../../../assets/icons/infoIcon.png'
import cross from '../../../assets/icons/cross-border-round.png'
import eyeIcon from '../../../assets/icons/eye.svg'
import borrarIcon from '../../../assets/icons/borrarIcon.svg'
import etiquetaIcon from '../../../assets/icons/etiqueta.svg'
import despacharIcon from '../../../assets/icons/despacharIcon.svg'
import enTransitoIcon from '../../../assets/icons/enTransitoIcon.svg'
import entregadoIcon from '../../../assets/icons/entregadoIcon.svg'
import facturaIcon from '../../../assets/icons/facturaIcon.svg'

import { fetchGetVentas, fetchGetDespachos, fetchGetReadyToDeliver, fetchGetReadyToDeliverReset, fetchDetailProduct, fetchOrderQR, newConversation, newConversationReset } from '../../../store/actions/clasificados.actions'
import mapVentas from '../../../selectors/mapVentas'
import DetailPedido from '../detailPedido'

import { PageWrapper, Wrapper, SearchWrapper, ContainerImg, Icon, IconBack, Title, ContainerSearchBar, StyledButton, Row, ContainerIcon, Container, CompradorLabel, DisclaimerLabel } from "./styled"

class Ventas extends React.Component {
  state = {
    orderToSee: undefined,
    filter: '',
    filterOn: "",
    productsOnTable: [],
    ventasSelected: [],
    showModal: false,
    disclaimerShow: true,
    verModalComprador: false,
    addProduct: false,
    editProducto: false,
    modalCambioEstado: false,
    modalStartConversation: false
  }

  componentDidMount = () => {
    this.props.fetchGetVentas()
    this.props.fetchGetDespachos()
    this.props.fetchGetReadyToDeliverReset()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loading) {
      if (prevProps.filteredVentas !== this.props.filteredVentas) {
        this.state.filterOn.length > 1 ? this.handleFilterChange(this.state.filterOn) : this.handleFilterChange("PENDIENTE_ENTREGA")
      }
    }
    if (prevProps.orderQr !== this.props.orderQr) {
      this.state.orderQr ? this.downloadLabel() : this.handleFilterChange(this.state.filterOn)
    }

  }


  renderPageContent = () => {
    return (
      <SearchWrapper>
        <Container margin={true}>
          <MaterialTable
            columns={[
              { title: 'Fecha', field: 'creationdate' },
              { title: 'Titulo', field: 'title' },
              { title: 'N° de Pedido', field: 'orderid' },
              { title: 'Importe', field: 'price' },
              { title: 'Comprador', field: 'buyerfullname', render: rowData => <CompradorLabel onClick={() => this.handleDetailProduct(rowData)} >{rowData.buyerfullname}</CompradorLabel>, },
              {
                title: 'Acciones', field: 'publication', cellStyle: { width: "40%" }, render: rowData =>
                  <>
                    <ContainerImg>
                      <Icon width="45px" src={eyeIcon} onClick={() => this.handleDetailOrder(rowData)} />
                    </ContainerImg>

                    {/* <ContainerImg>
                      <Icon width="20px" src={converIcon} onClick={() => this.startConversation(rowData)} />
                    </ContainerImg> */}
                    {/* <ContainerImg>
                      <Icon width="40px" src={borrarIcon}  />
                    </ContainerImg> */}
                    {(() => {
                      switch (this.state.filterOn) {
                        case 'PENDIENTE_ENTREGA':
                          return <>
                            <ContainerImg>
                              <Icon width="8vw" src={etiquetaIcon} onClick={() => this.handleDownloadLabel(rowData.orderid)} />
                            </ContainerImg>
                            <ContainerImg>
                              <Icon width="8vw" src={despacharIcon} onClick={() => this.toggleCambioEstado(rowData.orderid)} />
                            </ContainerImg>
                          </>;
                        case 'LISTO_DESPACHAR':
                          return <>
                            <ContainerImg>
                              <Icon width="12vw" src={enTransitoIcon} onClick={() => this.toggleCambioEstado(rowData.orderid)} />
                            </ContainerImg>
                          </>;
                        case 'EN_TRANSITO':
                          return <>
                            <ContainerImg>
                              <Icon width="12vw" src={entregadoIcon} />
                            </ContainerImg>
                          </>;
                        case 'ENTREGADO':
                          return <>
                            <ContainerImg>
                              <Icon width="12vw" src={facturaIcon} />
                            </ContainerImg>
                          </>;
                        default:
                          return null;
                      }
                    })()}

                  </>, width: "250px"
              }
            ]}
            data={this.state.productsOnTable}
            //onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
            // onSelectionChange={(rows) => this.onMovementClick( rows )}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay nada' }
            }}
            style={{
              backgroundColor: '#F8F8F8',
              color: '#000000'
            }}
            onSelectionChange={(rows) => this.handleVentasSelected(rows)}
            options={{
              toolbar: false,
              paging: false,
              maxBodyHeight: '600px',
              width: "40vw",
              selection: true,
              actionsColumnIndex: -1,
              tableLayout: "fixed",
              headerStyle: {
                backgroundColor: '#F8F8F8',
                color: '#9B9A9B',
                height: "2vh",
                width: "auto"
              },
              rowStyle: {
                border: "2px solid #D2D2D2",
                backgroundColor: '#FFFFFF',
                borderRadius: "6px",
                marginTop: "25px !important",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2), inset -3px 0 0 0 rgba(228, 228, 228, 0.2)",
              }
            }} //Esconde titulo y search y pagination
          />
        </Container>
      </SearchWrapper>
    )
  }


  handleFilterChange = filter => {
    if (filter === "EN_TRANSITO") {
      var productsFiltered = this.props.filteredDespachos.filter(value => (value.orderstatecode === filter))
    } else {
      var productsFiltered = this.props.filteredVentas.filter(value => (value.orderstatecode === filter))
    }
    this.setState({ productsOnTable: productsFiltered, filterOn: filter })
  }
  toggleVerModalComprador = (id) => {
    this.props.fetchGetVentas()
    this.props.fetchDetailProduct(id)
    this.setState({ verModalComprador: !this.state.verModalComprador })
  }
  handleDisclaimer = () => {
    this.setState({ disclaimerShow: !this.state.disclaimerShow })
  }
  handleDetailProduct = order => {
    this.setState({ orderToSee: order })
    this.toggleVerModalComprador(order.orderid)
  }

  toggleRow = (value) => {
    this.setState({ showRow: value })
  }

  toggleModal = (value) => {
    this.setState({ showModal: !this.state.showModal })
  }

  handleDetailOrder = order => {
    this.setState({ orderToSee: order })
    this.props.fetchDetailProduct(order.orderid)
    this.toggleVerPedido()
  }
  toggleVerPedido = (id) => {
    this.setState({ verPedido: !this.state.verPedido })
  }
  handleVentasSelected = (selected) => {
    if (selected.length > 1) {
      let arrayTransaction = []
      selected.map(row => {
        arrayTransaction.push(row.orderid)
      })
      this.setState({ ventasSelected: arrayTransaction }, this.toggleRow(true))
    } else {
      if (selected.length === 0) {
        this.setState({ ventasSelected: [] }, this.toggleRow(false))
      } else {
        let arrayFormat = [selected[0].orderid]
        this.setState({ ventasSelected: arrayFormat }, this.toggleRow(true))
      }
    }
  }

  isLoadingContent = () => this.props.loading


  toggleCambioEstado = orderId => {



    if (Array.isArray(orderId)) {
      orderId.map(order =>
        this.props.fetchGetReadyToDeliver(order)
      )
    } else {
      this.props.fetchGetReadyToDeliver(orderId)
    }
    this.toggleModal()
    this.props.fetchGetVentas()
    this.props.fetchGetDespachos()
    this.props.fetchGetReadyToDeliverReset()
  }

  downloadLabel = () => {
    document.getElementById('download').click();
  }


  async handleDownloadLabel(orderid) {

    if (Array.isArray(orderid)) {
      await orderid.map((id) => (
        this.props.fetchOrderQR(id)
      ))
      if (this.props.orderQr) {
        this.downloadLabel()
      }

    } else {
      var response = await this.props.fetchOrderQR(orderid)
      if (this.props.orderQr) {
        this.downloadLabel()
      }
    }
  }



  render() {

    const { filterOn, addProduct, editProducto } = this.state;
    if (this.state.verPedido) {
      return (
        <DetailPedido onClose={() => this.toggleVerPedido()} order={this.state.orderToSee} />
      )
    } else {
      return (
        <>
          <SectionHeader title='Ventas'>
            {/*     <Input
            type="text"
            search={true}
            placeholder={'Buscar'}
            value={this.props.search}
            onChange={(e) => this.props.searchHandleChange(e)}  
          />
     */}
          </SectionHeader>
          <Container margin={false} >
            <FilterBox title={"Aprobado"} subtitle={"Pedidos realizados"} img={aprobado} active={filterOn === "PENDIENTE_ENTREGA" ? true : false} onClick={() => this.handleFilterChange("PENDIENTE_ENTREGA")} />
            <FilterBox title={"Listos para despachar"} subtitle={"Pedidos listos para ser enviados"} img={cajaIcon} active={filterOn === "LISTO_DESPACHAR" ? true : false} onClick={() => this.handleFilterChange("LISTO_DESPACHAR")} />
            <FilterBox title={"En tránsito"} subtitle={"Pedidos viajando a destino"} img={transitoIcon} active={filterOn === "EN_TRANSITO" ? true : false} onClick={() => this.handleFilterChange("EN_TRANSITO")} />
            <FilterBox title={"Entregado"} subtitle={"Pedidos llegados a destino"} img={casaIcon} active={filterOn === "ENTREGADO" ? true : false} onClick={() => this.handleFilterChange("ENTREGADO")} />
          </Container>
          {this.state.showModal ? (
            <ModalMessage success={this.props.fetchGetReadyToDeliverSuccess} onClose={() => this.toggleModal()}>
              {this.props.fetchGetReadyToDeliverSuccess ? "Cambio de estado exitoso" : this.props.fetchGetReadyToDeliverFailMessage}
            </ModalMessage>
          ) : (<> </>)}
          {
            this.state.disclaimerShow ?
              <DisclaimerLabel out={!this.state.disclaimerShow}>
                <Icon width="25px" height="25px" margin="0% 2%" src={infoIcon} />
                Antes de marcar un pedido para el despacho, descargá y pegá la etiqueta correspondiente en el paquete.
                <Icon width="25px" height="25px" margin="0% 2% 0% 25%" src={cross} onClick={() => this.handleDisclaimer()} />
              </DisclaimerLabel> : <></>
          }


          <a href={'data:application/octet-stream;base64,' + this.props.orderQr} download={"DetalleDeOrden.pdf"} id="download" hidden></a>
          <Wrapper>
            {this.isLoadingContent() ? <LoaderImage medium={true} src={EmptyLoader} /> : this.renderPageContent()}
            {this.state.verModalComprador ? <DetailOrderModal loading={this.props.getDetailsSuccess} clasificados={this.props.clasificados} fetchOrderQR={this.props.fetchOrderQR} startConversation={() => this.startConversation(this.state.orderToSee)} onClose={() => this.toggleVerModalComprador()} success={this.props.getDetailsFalse} order={this.state.orderToSee} /> : <></>}
          </Wrapper>

        </>
      )
    };
  }
}

const mapStateToProps = ({
  clasificados,
  clasificados: { ventas, despachos, loadingVentas, fetchDetailProductSuccess, fetchGetReadyToDeliverFailMessage, loadingNewConversation, orderQr, loadingGetReadyToDeliver, fetchGetReadyToDeliverSuccess, fetchGetReadyToDeliverFail }
}) => ({
  filteredVentas: ventas.length > 0 ? mapVentas(ventas) : [],
  filteredDespachos: ventas.length > 0 ? mapVentas(despachos) : [],
  loading: loadingVentas,
  getDetailsSuccess: fetchDetailProductSuccess,
  loadingGetReadyToDeliver,
  loadingNewConversation,
  orderQr,
  clasificados,
  fetchGetReadyToDeliverSuccess,
  fetchGetReadyToDeliverFailMessage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchGetVentas, fetchGetReadyToDeliver, fetchGetDespachos, fetchGetReadyToDeliverReset, fetchDetailProduct, fetchOrderQR, newConversation, newConversationReset
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Ventas)
)
