import React, { Component } from 'react'
import styled from 'styled-components'
import ImageUploadPlaceholder from '../../../../components/FileImageInput';

import Plus from '@material-ui/icons/Add';
import { Wrapper, Title, StyledButton, Link, WrapperLink } from "../styled"
import Button from '../../../../components/Button';
import ModalMessage from '../../../../components/ModalMessage';

import { Formik } from 'formik'
import * as Yup from 'yup'
//import CheckBox from 'components/CheckboxFormik';
import Loader from 'react-spinner-material'

import Select from 'react-select';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchGetPayment, fetchDisclaimerMetodoPago, fetchGetShippingMethods, fetchAddProduct, fetchEditProduct
} from '../../../../store/actions/clasificados.actions'

import compose from 'recompose/compose'


const DisclaimerLabel = styled.div`  
    width: 100%;
    color: orange;
    font-size: 14px;
    font-weight: 500;
`


const ErrorLabel = styled.div`  
    width: 100%;
    color: red;
    font-size: 14px;
    font-weight: 500;
`

const GridContainerImagenes = styled.div`  
    display: grid;
      width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas: "main main one two" "main main three four";

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const GridContainerDataMapped = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-bottom: 15px;

    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`

const GridContainerLoader = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-bottom: 15px;
    justify-items: center;
    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`

class Paso3 extends Component {
    state = {
        step: 1,
        imagesArray: [],
    }

    fieldImages = (image, setFieldValue) => {
        let imagesArray = this.state.imagesArray.concat(image.base64Value)
        this.setState({ imagesArray }, setFieldValue('images', imagesArray))
    }
    manageMetodopago = (value, setFieldValue) => {
        var Disclaimer = value.map(item => {
            let Disclaimer = item.value.includes("INGENICO");;
            return Disclaimer;
        })
        var hasDisclaimer = Disclaimer.includes(true)
        setFieldValue('acceptedpaymentmethodlist', value)

        this.setState({ MetodoPagovalue: hasDisclaimer })
    }

    componentDidMount() {
        this.props.fetchGetShippingMethods();
        this.props.fetchGetPayment();
        this.props.fetchDisclaimerMetodoPago();
    }

    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (


        <form onSubmit={handleSubmit} key='2' style={{ width: '100%', marginTop: '4%' }}>
            <Wrapper>
                {this.props.clasificados.loadingAddProduct ?
                    <GridContainerLoader>
                        <Loader customHeight={'150px'} />
                    </GridContainerLoader>
                    :
                    <>
                        <Title>Seleccione las fotos del producto</Title>
                        <GridContainerImagenes>
                            <ImageUploadPlaceholder
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                value={values.file[0] ? values.file[0] : ""}
                                width="95%"
                                padding="5%"
                                gridArea="main"
                                icon={<Plus />}
                                dashed={true}
                            />
                            <ImageUploadPlaceholder
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                gridArea="one"
                                icon={<Plus />}
                                value={values.file[1] ? values.file[1] : ""}
                                dashed={true}
                            />
                            <ImageUploadPlaceholder
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                gridArea="two"
                                icon={<Plus />}
                                value={values.file[2] ? values.file[2] : ""}
                                dashed={true}
                            />
                            <ImageUploadPlaceholder
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                gridArea="three"
                                icon={<Plus />}
                                value={values.file[3] ? values.file[3] : ""}
                                dashed={true}
                            />
                            <ImageUploadPlaceholder
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                gridArea="four"
                                icon={<Plus />}
                                value={values.file[4] ? values.file[4] : ""}
                                dashed={true}
                            />
                        </GridContainerImagenes>
                        <>
                            <Title>Selecciona que medios de pagos ofreces</Title>
                            <GridContainerDataMapped>
                                {this.props.clasificados.payment.values.length > 0 ?
                                    <Select
                                        name={'acceptedpaymentmethodlist'}
                                        label={'acceptedpaymentmethodlist'}
                                        autosize={false}
                                        isMulti={true}
                                        isSearchable={true}
                                        value={values.acceptedpaymentmethodlist}
                                        onChange={value => this.manageMetodopago(value, setFieldValue)}
                                        placeholder="Medios de Pago"
                                        options={
                                            this.props.clasificados.payment.values.map(n =>
                                                ({ label: n.name, value: n.code }))}
                                    />
                                    :
                                    <GridContainerLoader>
                                        <Loader customHeight={'50px'} />
                                    </GridContainerLoader>
                                }
                                {this.props.clasificados.resultDisclaimerMetodoPago && this.state.MetodoPagovalue === true ? <DisclaimerLabel>{this.props.clasificados.resultDisclaimerMetodoPago.value}</DisclaimerLabel> : ""}
                                {errors.acceptedpaymentmethodlist && <ErrorLabel>{errors.acceptedpaymentmethodlist}</ErrorLabel>}
                            </GridContainerDataMapped>

                            <Title>Selecciona que metodos de envío ofreces</Title>
                            <GridContainerDataMapped>
                                {this.props.clasificados.shipping.length > 0 ?
                                    <Select
                                        name={'deliverymethods'}
                                        label={'deliverymethods'}
                                        autosize={false}
                                        isMulti={true}
                                        isSearchable={true}
                                        value={values.deliverymethods}
                                        onChange={value => setFieldValue('deliverymethods', value)}
                                        placeholder="Metodos de envios"
                                        options={
                                            this.props.clasificados.shipping.map(n =>
                                                ({ label: n.name, value: n.id }))}
                                    />
                                    :
                                    <GridContainerLoader>
                                        <Loader customHeight={'50px'} />
                                    </GridContainerLoader>
                                }
                                {errors.deliverymethods && <ErrorLabel>{errors.deliverymethods}</ErrorLabel>}
                            </GridContainerDataMapped>
                        </>

                        {this.props.clasificados.fetchEditProductSuccess ? <ModalMessage success={this.props.clasificados.fetchEditProductSuccess} onClose={() => this.props.backtoClasif()}>Producto editado correctamente !</ModalMessage> : null}
                        {this.props.clasificados.fetchEditProductFail ? <ModalMessage success={this.props.clasificados.fetchEditProductSuccess} onClose={() => this.props.resetRedux()}>Hubo un error editando tu producto. Intentalo nuevamente!</ModalMessage> : null}

                        <Button type={'submit'} >{!this.props.clasificados.loadingEditProduct ? 'Finalizar' : <Loader></Loader>}</Button>

                        <WrapperLink>
                            <Link href="#" onClick={() => this.props.handleVolver()}>{'<< Volver'}</Link>
                        </WrapperLink>
                    </>
                }
            </Wrapper>
        </form>
    )


    toggleModalAdd = () => {
        this.setState({ apiReady: !this.state.apiReady, });
    }

    openWebcamModal = () => {
        this.setState({ cameraModalOpen: !this.state.cameraModalOpen, });
    }

    handleSubmit = (values) => {
        const { index, getFormData } = this.props;
        getFormData(values, index);
        this.props.handleAddProductAPI();
    }

    getInitialValues = () => ({

        'file': this.props.dataSaved.file ? this.props.dataSaved.file : this.props.producto.values.shopitemimagesurls.map(n => n),
        'acceptedpaymentmethodlist': this.props.dataSaved.acceptedpaymentmethodlist ? this.props.dataSaved.acceptedpaymentmethodlist : this.props.producto.values.acceptedpaymentmethodlist.map(n => ({ label: n.paymentmethodname, value: n.paymentmethodcode })),
        'deliverymethods': this.props.dataSaved.deliverymethods ? this.props.dataSaved.deliverymethods : this.props.producto.values.deliverymethods.map(n => ({ label: n.name, value: n.id })),
    })


    getSignUpSchema = () =>
        Yup.object().shape({
            acceptedpaymentmethodlist: Yup.string().required('Metodos de pago es requerido'),
            deliverymethods: Yup.string().required('Metodos de envio es requerido'),
        })


    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={true}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
}

const mapStateToProps = ({
    clasificados,
    selectedGuest
}) => ({
    clasificados,
    fetchAddProductSuccess: clasificados.fetchAddProductSuccess,
    fetchAddProductFail: clasificados.fetchAddProductFail,
    loadingAddProduct: clasificados.loadingAddProduct

})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchDisclaimerMetodoPago, fetchAddProduct, fetchEditProduct, fetchGetPayment, fetchGetShippingMethods }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Paso3)
)