import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBackIos'
import CurrencyInput from 'react-currency-input';

export const Container = styled.form`
    display:flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    margin-top: 2%;
    align-items: space-between;
    justify-content: space-between;
`
export const ColumnFormContainer = styled.form`
    display:flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    align-items: space-evenly;
    justify-content: space-evenly;
    margin-bottom: 2%;
`
export const RowFormContainer = styled.form`
    display:flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    margin-top: 2%;
`

export const TitleContainer = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.colors.black };
    font-size: 20px;
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };   
    margin-top: ${({ theme }) => theme.spacing.unit }; 
    display: flex;   
`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    background-color: ${({ theme }) => theme.colors.white };

    ${TitleContainer}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;  
        cursor: pointer;
    }
`


export const gridItemForm = styled(Grid)`
    flex-direction: column;
    display: flex;
    padding-left: ${({ theme }) => theme.spacing.unit3 };
    padding-right: ${({ theme }) => theme.spacing.unit3 };
    min-height: 500px;
`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey };
    font-size: 20px;
`
export const SelectWrapper = styled.div`
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
`
export const InputWrapper = styled.div`
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
`
export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit };
    margin-bottom: ${({ theme }) => theme.spacing.unit };
    display:flex;
    flex-direction: row;
    justify-content: flex-start;  

`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 2% 15%;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 3% 0px 0px 0%;
    align-self: center;
`


export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const LabelChecked = styled.label`
    font-size: 14px;
    margin: 1% 0%;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`


export const DestinatarioDetailContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
    margin-bottom: ${({ theme }) => theme.spacing.unit };
    background-color: ${({ theme }) => theme.colors.wildSand };
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.ghost };
    border-radius: 5px;
    border-width: 0.5px;
    padding: ${({ theme }) => theme.spacing.unit };
    margin-top:1%;
`

export const DestinatarioDetailItemContainer = styled.div`
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
`
export const DestinatarioDetailItem = styled.div`
    color: ${props => (props.bold ? 'dark' : '#616161')} ;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`

export const InputStyled = styled(CurrencyInput)`
    width: 100%;
    height: 15px;
    margin-top: 5px;
    font-weight: ${({ onChangeEvent }) => onChangeEvent ? 600 : 500 };
    font-size: 20px;
    padding: 10px;
    color: black;
    border-radius: 5px;
    border-width: 0.5px;
    border-style: solid;
    border-color:  ${({ theme }) => theme.colors.ghost };
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
`