import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SectionHeader from "../../../../components/SectionHeader";
import RitzTable from "../../../../components/RitzTable";
import { TableWrapper, MainContainer } from "./styled.js";
import SettingsIcon from "@material-ui/icons/Settings";
import Switch from "@material-ui/core/Switch";
import RuleValuesModal from "./RuleValuesModal"
import ModalMessage from "../../../../components/ModalMessage";
import ModalDelete from "../../../../components/ModalDelete";
import {
  fetchRules,
  fetchRulesReset,
  fetchRuleValues,
  fetchRuleValuesReset,
  updateRuleValue,
  updateRuleValueReset,
  deleteRuleValue,
  deleteRuleValueReset,
  createRuleValues,
  createRuleValuesReset
} from "../../../../store/actions/bookings.actions";

const mapStateToProps = state => {
  const {
    rules,
    loadingFetchRules,
    errorFetchRules,
    ruleValues,
    loadingFetchRuleValues,
    errorFetchRuleValues,
    fetchRuleValuesSuccess,
    loadingUpdateRuleValue,
    errorUpdateRuleValue,
    updateRuleValueSuccess,
    responseMessage,
    loadingDeleteRuleValue,
    errorDeleteRuleValue,
    deleteRuleValueSuccess,
    loadingCreateRuleValues,
    createRuleValuesSuccess,
    errorCreateRuleValues
  } = state.bookings;

  return {
    rules,
    loadingFetchRules,
    errorFetchRules,
    ruleValues,
    loadingFetchRuleValues,
    errorFetchRuleValues,
    fetchRuleValuesSuccess,
    loadingUpdateRuleValue,
    errorUpdateRuleValue,
    updateRuleValueSuccess,
    responseMessage,
    loadingDeleteRuleValue,
    errorDeleteRuleValue,
    deleteRuleValueSuccess,
    loadingCreateRuleValues,
    createRuleValuesSuccess,
    errorCreateRuleValues
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRules,
      fetchRulesReset,
      fetchRuleValues,
      fetchRuleValuesReset,
      updateRuleValue,
      updateRuleValueReset,
      deleteRuleValue,
      deleteRuleValueReset,
      createRuleValues,
      createRuleValuesReset
    },
    dispatch
  );

function ActivityRules(props) {
  const {
    activityId,
    activityName,
    rules,
    loadingFetchRules,
    errorFetchRules,
    ruleValues,
    loadingFetchRuleValues,
    errorFetchRuleValues,
    fetchRuleValuesSuccess,
    loadingUpdateRuleValue,
    errorUpdateRuleValue,
    updateRuleValueSuccess,
    loadingDeleteRuleValue,
    responseMessage,
    errorDeleteRuleValue,
    deleteRuleValueSuccess,
    loadingCreateRuleValues,
    createRuleValuesSuccess,
    errorCreateRuleValues
  } = props;

  const [selectedRule, setSelectedRule] = useState();
  const [editRuleValue, setEditRuleValue] = useState(false);
  const [deleteRuleValue, setDeleteRuleValue] = useState(false);
  const [createRuleValue, setCreateRuleValue] = useState(false);

  useEffect(() => {
    props.fetchRules(activityId);
  }, []);

  useEffect(() => {
    if (!editRuleValue && !deleteRuleValue && !createRuleValue) {
      props.fetchRules(activityId);
    }
  }, [editRuleValue, deleteRuleValue, createRuleValue]);

  const renderModalSuccess = (message, callback) => (
    <ModalMessage success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  //Modal Rule Values
  const toggleRuleValue = async data => {
    setEditRuleValue(!editRuleValue);
    props.updateRuleValueReset();

    if (!editRuleValue) {
      setSelectedRule(data);
      if (data.active) {
        await props.fetchRuleValues(data.activity_rule_id);
      }
    }
  }

  const onEditRuleValueSubmit = async (activityRuleValue) => {
    var formattedBody = {
      value: activityRuleValue.activity_rule_value
    }
    props.updateRuleValue(activityRuleValue.id, formattedBody);
  };

  const resetResponseLabel = async () => {
    props.updateRuleValueReset();
  };

  const valuesModal = (() => {
    if (editRuleValue) {
      return (
        <RuleValuesModal
          onClose={toggleRuleValue}
          onSubmit={onEditRuleValueSubmit}
          isLoading={loadingFetchRuleValues || loadingUpdateRuleValue}
          ruleValues={ruleValues}
          title={"Configurar Regla"}
          responseMessage={responseMessage}
          errorUpdateRuleValue={errorUpdateRuleValue}
          updateRuleValueSuccess={updateRuleValueSuccess}
          resetResponseLabel={resetResponseLabel}
        />
      );
    }
  })();

  //Switch
  const toggleSwitchRuleValue = async data => {
    setSelectedRule(data);
    if (data.active) {
      toggleDeleteRuleValue(data);
    }
    else {
      toggleAddRuleValue();
    }
  }

  //Create Rule Value
  const toggleAddRuleValue = async () => {
    props.createRuleValuesReset();
    setCreateRuleValue(!createRuleValue);
  };

  const onCreateRuleValueSubmit = async ruleValues => {
    let formattedData = {
      rule_id: selectedRule.id,
      activity_id: selectedRule.activity_id,
      values_to_assign: ruleValues && ruleValues.length > 0 ? ruleValues.map(ruleValue => ({ rule_value_id: ruleValue.id, value: ruleValue.activity_rule_value })) : null
    };
    props.createRuleValues(formattedData);
  };

  const addModal = (() => {
    if (createRuleValuesSuccess) {
      return renderModalSuccess("Se ha creado la regla correctamente.", toggleAddRuleValue);
    }
    else if (errorCreateRuleValues) {
      return renderModalFail("Hubo un error al crear la regla.", toggleAddRuleValue);
    }

    if (createRuleValue) {
      return (
        <RuleValuesModal
          onClose={toggleAddRuleValue}
          onSubmit={onCreateRuleValueSubmit}
          isLoading={loadingFetchRuleValues}
          title={"Alta valor de Regla"}
          isCreate={true}
          ruleValues={selectedRule.rule_values && selectedRule.rule_values.length > 0 ? selectedRule.rule_values.map(ruleValue => ({ id: ruleValue.id, rule_value_name: ruleValue.name, activity_rule_value: "", input_mask: ruleValue.input_mask, validation_expression: ruleValue.validation_expression,placeholder:ruleValue.placeholder })) : []}
        />
      );
    }
  })();

  //Delete Rule Value
  const toggleDeleteRuleValue = async () => {
    props.deleteRuleValueReset();
    setDeleteRuleValue(!deleteRuleValue);
  };

  const onDeleteRuleValueSubmit = async () => {
    await props.deleteRuleValue(selectedRule.activity_rule_id);
  };

  const deleteModal = (() => {
    if (deleteRuleValueSuccess) {
      return renderModalSuccess("Se ha desactivado la regla.", toggleDeleteRuleValue);
    } else if (errorDeleteRuleValue) {
      return renderModalFail("Hubo un error al desactivar la regla.", toggleDeleteRuleValue);
    }
    if (deleteRuleValue) {
      return (
        <ModalDelete
          title="Desactivar Regla"
          subTitle="¿Estás seguro que querés desactivar la regla?"
          buttonMessage="Desactivar"
          isLoading={loadingDeleteRuleValue}
          onClose={toggleDeleteRuleValue}
          success={false}
          onSubmit={onDeleteRuleValueSubmit}
        />
      );
    }
  })();

  const columns = [
    { title: "Descripción", field: "description" },
    { title: "Valor", field: "value" },
    {
      title: "Activa",
      field: "active",
      cellStyle: { width: "2em" },
      render: rowData => (
        <>
          <Switch
            trackcolor={{ true: " ${({ theme }) => theme.colors.midGray}", false: "grey" }}
            checked={rowData.active}
            onChange={() => toggleSwitchRuleValue(rowData)}
          />
        </>
      )
    }
  ];

  return (
    <>
      <SectionHeader
        onClose={props.onClose}
        title={`Configuración de reglas - ${activityName}`}
        fontWeight={"bold"}
        width="100%"
      ></SectionHeader>
      <TableWrapper>
        <RitzTable
          columns={columns}
          title=""
          isLoading={loadingFetchRules}
          options={{
            toolbar: false,
            paging: false,
            serverSide: true,
            selection: false,
            search: false,
            exportAllData: false
          }}
          data={rules}
          actions={[
            rowData => ({
              icon: SettingsIcon,
              tooltip: "Configurar",
              isFreeAction: false,
              onClick: () => toggleRuleValue(rowData),
              disabled: !rowData.active
            })
          ]}
        />
      </TableWrapper>
      {valuesModal}
      {addModal}
      {deleteModal}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityRules);
