import React from 'react';

import Button from '../../../components/Button';
import { ButtonWrapper } from './styled.js';
import Modal from '../../../components/Modal';

class ModalDelete extends React.Component {
    render() {
        return (
            <Modal onClose={() => this.props.close()} height={'215px'}>
                <div style={{ textAlign: 'center' }}>
                    <h2>Eliminar Publicación</h2>
                    <br />
                    <h3>¿Estás seguro de que querés eliminar la publicación?</h3>
                    <br />
                    {this.props.loadingDeleting ?
                        <p>Eliminando el producto.</p>
                        :
                        this.props.deleteStatus ?
                            <p>Eliminado correctamente.</p>
                            :
                            null
                    }
                    <br />
                    <ButtonWrapper>
                        <Button medium inverted onClick={() => this.props.close()}>Cancelar</Button>
                        <Button medium onClick={() => this.props.DeleteProduct()}>Eliminar</Button>
                    </ButtonWrapper>
                </div>
            </Modal>
        );
    }
}

export default ModalDelete