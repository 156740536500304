import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/utility";
import { FormatListNumberedRtlOutlined } from "@material-ui/icons";

const reservationsMock = {
  space_id: 0,
  space_name: "",
  calendar_date_times: [
    {
      date: "dateString",
      day_of_week: 0,
      day_of_month: 1,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 1,
      day_of_month: 2,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 2,
      day_of_month: 3,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 3,
      day_of_month: 4,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 4,
      day_of_month: 5,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 5,
      day_of_month: 6,
      calendar_times: []
    },
    {
      date: "dateString",
      day_of_week: 6,
      day_of_month: 7,
      calendar_times: []
    }
  ]
};

const initialState = {
  isLoading: false,
  selectedNeighbourhood: null,

  //Spaces
  loadingfetchSpaces: false,
  fetchSpacesSuccess: false,
  fetchSpacesFail: {},
  fetchSpacesSearchText: "",
  spaces: [],

  //Combo Activities
  loadingfetchComboActivities: false,
  fetchComboActivitiesSuccess: false,
  fetchComboActivitiesFail: {},
  comboActivities: [],
  selectedComboActivities: null,

  //Combo Activities
  loadingFetchBookingActivities: false,
  fetchBookingActivitiesSuccess: false,
  fetchBookingActivitiesFail: {},
  bookingActivities: [],
  fetchActivitiesSearchText: "",
  //Combo spaces to block
  loadingfetchComboSpaceToBlock: false,
  fetchComboSpaceToBlockSuccess: false,
  fetchComboSpaceToBlockFail: {},
  comboSpacesToBlock: [],
  selectedComboSpaceToBlock: null,

  //Create Activity
  loadingCreateBookingActivity: false,
  createBookingActivitySuccess: false,
  errorCreateBookingActivity: null,

  //Delete Activity
  loadingDeleteBookingActivity: false,
  deleteBookingActivitySuccess: false,
  errorDeleteBookingActivity: null,

  //Fetch neighbourhood space detail
  loadingFetchNeighbourhoodSpaceDetail: false,
  fetchNeighbourhoodSpaceDetailSuccess: false,
  neighbourhoodSpaceDetail: {},

  //Create Neighbourhood Space
  loadingCreateNeighbourhoodSpace: false,
  createNeighbourhoodSpaceSuccess: false,
  errorCreateNeighbourhoodSpace: null,

  //Update Neighbourhood Space
  loadingUpdateNeighbourhoodSpace: false,
  updateNeighbourhoodSpaceSuccess: false,
  errorUpdateNeighbourhoodSpace: null,

  //Delete Neighbourhood Space
  loadingDeleteNeighbourhoodSpace: false,
  deleteNeighbourhoodSpaceSuccess: false,
  errorDeleteNeighbourhoodSpace: null,

  //Fetch Bookings
  loadingFetchBookings: false,
  bookingsData: [],
  fetchBookingsSuccess: false,
  errorFetchBookings: null,
  totalBookings: 0,
  pageSize: 10,
  page: 0,
  //Fetch Export Bookings
  loadingFetchExportBookings: false,
  exportBookingsData: [],
  fetchExportBookingsSuccess: false,
  errorFetchExportBookings: null,
  //Update Activity
  loadingUpdateBookingActivity: false,
  updateBookingActivitySuccess: false,
  errorUpdateBookingActivity: null,

  //Fetch Activity
  loadingFetchBookingActivity: false,
  fetchBookingActivitySuccess: false,
  fetchBookingActivityFail: {},
  bookingActivity: [],

  //Calendar
  selectedCalendarDateTime: new Date(),
  loadingFetchSchedulesCalendar: false,
  fetchSchedulesCalendarSuccess: false,
  fetchSchedulesCalendarFail: null,
  schedulesCalendar: reservationsMock,

  //#region BOOKING NEIGHBOURHOOD
  loadingCreateBookingNeighbourhood: false,
  createBookingNeighbourhoodSuccess: false,
  errorCreateBookingNeighbourhood: null,
  //#endregion

  //#region CANCEL BOOKING
  loadingCancelBooking: false,
  cancelBookingSuccess: false,
  errorCancelBooking: null,
  //#endregion

  //Create schedule
  loadingCreateSchedule: false,
  createScheduleSuccess: false,
  errorCreateSchedule: null,

  //Fetch Schedule Detail
  loadingFetchScheduleDetail: false,
  fetchScheduleDetailSuccess: false,
  scheduleDetail: {},

  //Update Schedule
  loadingUpdateSchedule: false,
  updateScheduleSuccess: false,
  errorUpdateSchedule: null,
  //Create TyC
  loadingCreateTyC: false,
  createTyCSuccess: false,
  errorCreateTyC: null,

  //Fetch Rules
  loadingFetchRules: false,
  fetchRulesSuccess: false,
  errorFetchRules: null,
  rules: [],

  //Fetch Rule Value
  loadingFetchRuleValues: false,
  fetchRuleValuesSuccess: false,
  errorFetchRuleValues: null,
  ruleValues: [],

  //Update Rule Value
  loadingUpdateRuleValue: false,
  updateRuleValueSuccess: false,
  errorUpdateRuleValue: false,
  responseMessage: '',

  //Delete Rule Value
  loadingDeleteRuleValue: false,
  deleteRuleValueSuccess: false,
  errorDeleteRuleValue: null,

  //Create Rule Value
  loadingCreateRuleValues: false,
  createRuleValuesSuccess: false,
  errorCreateRuleValues: null,

  //Schedule bookings
  loadingFetchScheduleBookings: false,
  scheduleBookings: [],
  errorScheduleBookings: null
};

//Spaces
const fetchSpacesSuccess = (state, action) => {
  return updateObject(state, {
    loadingfetchSpaces: false,
    fetchSpacesSuccess: true,
    fetchSpacesFail: false,
    spaces: action.data
  });
};

const fetchSpacesFail = (state, error) => {
  return updateObject(state, {
    loadingfetchSpaces: false,
    fetchSpacesSuccess: false,
    fetchSpacesFail: error,
    spaces: []
  });
};

const fetchSpacesStart = state => {
  return updateObject(state, {
    loadingfetchSpaces: true,
    fetchSpacesSuccess: false,
    fetchSpacesFail: false,
    spaces: [],
    selectedSpaces: {}
  });
};

const updateSpaceFilters = (state, action) => {
  return updateObject(state, {
    fetchSpacesSearchText: action.searchText,
    selectedComboActivities: action.activity
  });
};

const clearSpaces = state => {
  return updateObject(state, {
    loadingfetchSpaces: false,
    fetchSpacesSuccess: false,
    fetchSpacesFail: null,
    fetchSpacesSearchText: "",
    spaces: []
  });
};

//Combo Spaces a bloquear
const fetchComboSpacesToBlockSuccess = (state, action) => {
  return updateObject(state, {
    loadingfetchComboSpaceToBlock: false,
    fetchComboSpaceToBlockSuccess: true,
    fetchComboSpaceToBlockFail: false,
    comboSpacesToBlock: action.data
  });
};

const fetchComboSpacesToBlockFail = (state, error) => {
  return updateObject(state, {
    loadingfetchComboSpaceToBlock: false,
    fetchComboSpaceToBlockSuccess: false,
    fetchComboSpaceToBlockFail: error,
    comboSpacesToBlock: [],
    selectedComboSpaceToBlock: null
  });
};

const fetchComboSpacesToBlockStart = state => {
  return updateObject(state, {
    loadingfetchComboSpaceToBlock: true,
    fetchComboSpaceToBlockSuccess: false,
    fetchComboSpaceToBlockFail: false,
    comboSpacesToBlock: [],
    selectedComboSpaceToBlock: null
  });
};

const fetchComboSpacesToBlockReset = state => {
  return updateObject(state, {
    loadingfetchComboSpaceToBlock: false,
    fetchComboSpaceToBlockSuccess: false,
    fetchComboSpaceToBlockFail: false,
    comboSpacesToBlock: [],
    selectedComboSpaceToBlock: null
  });
};

//Combo activities
const fetchComboActivitiesSuccess = (state, action) => {
  return updateObject(state, {
    loadingfetchComboActivities: false,
    fetchComboActivitiesSuccess: true,
    fetchComboActivitiesFail: false,
    comboActivities: action.neighbourhoodActivities
  });
};

const fetchComboActivitiesFail = (state, error) => {
  return updateObject(state, {
    loadingfetchComboActivities: false,
    fetchComboActivitiesSuccess: false,
    fetchComboActivitiesFail: error,
    comboActivities: [],
    selectedComboActivities: null
  });
};

const fetchComboActivitiesStart = state => {
  return updateObject(state, {
    loadingfetchComboActivities: true,
    fetchComboActivitiesSuccess: false,
    fetchComboActivitiesFail: false,
    comboActivities: [],
    selectedComboActivities: null
  });
};

//Fetch Activities
const fetchBookingActivitiesSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchBookingActivities: false,
    fetchBookingActivitiesSuccess: true,
    fetchBookingActivitiesFail: false,
    bookingActivities: action.neighbourhoodActivities
  });
};

const fetchBookingActivitiesFail = (state, error) => {
  return updateObject(state, {
    loadingFetchBookingActivities: false,
    fetchBookingActivitiesSuccess: false,
    fetchBookingActivitiesFail: error,
    bookingActivities: []
  });
};

const fetchBookingActivitiesStart = state => {
  return updateObject(state, {
    loadingFetchBookingActivities: true,
    fetchBookingActivitiesSuccess: false,
    fetchBookingActivitiesFail: false,
    bookingActivities: []
  });
};

const updateActivityFilters = (state, action) => {
  return updateObject(state, {
    fetchActivitiesSearchText: action.searchText
  });
};

// Create Activity
const createBookingActivitySuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateBookingActivity: false,
    createBookingActivitySuccess: true,
    errorCreateBookingActivity: null
  });
};

const createBookingActivityFail = (state, action) => {
  return updateObject(state, {
    loadingCreateBookingActivity: false,
    errorCreateBookingActivity: action.errorCreateBookingActivity,
    createBookingActivitySuccess: false
  });
};

const createBookingActivityStart = state => {
  return updateObject(state, {
    loadingCreateBookingActivity: true,
    errorCreateBookingActivity: null
  });
};

const createBookingActivityReset = state => {
  return updateObject(state, {
    loadingCreateBookingActivity: false,
    createBookingActivitySuccess: false,
    errorCreateBookingActivity: null
  });
};

//Delete Activity

const deleteBookingActivityStart = state => {
  return updateObject(state, {
    loadingDeleteBookingActivity: true,
    deleteBookingActivityeSuccess: false
  });
};

const deleteBookingActivitySuccess = state => {
  return updateObject(state, {
    loadingDeleteBookingActivity: false,
    deleteBookingActivitySuccess: true
  });
};
const deleteBookingActivityFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteBookingActivity: false,
    deleteBookingActivitySuccess: false,
    errorDeleteBookingActivity: action.errorDeleteBookingActivity
  });
};

const deleteBookingActivityReset = state => {
  return updateObject(state, {
    loadingDeleteBookingActivity: false,
    deleteBookingActivitySuccess: false,
    errorDeleteBookingActivity: null
  });
};

//FETCH NEIGHBOURHOOD SPACE DETAIL
const fetchNeighbourhoodSpaceDetailSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchNeighbourhoodSpaceDetail: false,
    fetchNeighbourhoodSpaceDetailSuccess: true,
    neighbourhoodSpaceDetail: action.neighbourhoodSpaceDetail
  });
};

const fetchNeighbourhoodSpaceDetailFail = (state, action) => {
  return updateObject(state, {
    loadingFetchNeighbourhoodSpaceDetail: false,
    fetchNeighbourhoodSpaceDetailSuccess: false,
    // fetchSpacesFail: error,
    neighbourhoodSpaceDetail: {}
  });
};

const fetchNeighbourhoodSpaceDetailStart = state => {
  return updateObject(state, {
    loadingFetchNeighbourhoodSpaceDetail: true,
    fetchNeighbourhoodSpaceDetailSuccess: false,
    neighbourhoodSpaceDetail: []
  });
};

//CREATE NEIGHBOURHOOD SPACE
const createNeighbourhoodSpaceSuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateNeighbourhoodSpace: false,
    createNeighbourhoodSpaceSuccess: true
  });
};

const createNeighbourhoodSpaceFail = (state, action) => {
  return updateObject(state, {
    loadingCreateNeighbourhoodSpace: false,
    createNeighbourhoodSpaceSuccess: false,
    errorCreateNeighbourhoodSpace: action.errorCreateNeighbourhoodSpace
  });
};

const createNeighbourhoodSpaceStart = state => {
  return updateObject(state, {
    loadingCreateNeighbourhoodSpace: true,
    createNeighbourhoodSpaceSuccess: false
  });
};

const createNeighbourhoodSpaceReset = state => {
  return updateObject(state, {
    loadingCreateNeighbourhoodSpace: false,
    createNeighbourhoodSpaceSuccess: false,
    errorCreateNeighbourhoodSpace: null
  });
};

////////UPDATE NEIGHBOURHOOD SPACE /////
const updateNeighbourhoodSpaceSuccess = (state, action) => {
  return updateObject(state, {
    loadingUpdateNeighbourhoodSpace: false,
    updateNeighbourhoodSpaceSuccess: true
  });
};

const updateNeighbourhoodSpaceFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateNeighbourhoodSpace: false,
    updateNeighbourhoodSpaceSuccess: false,
    errorUpdateNeighbourhoodSpace: action.errorUpdateNeighbourhoodSpace
  });
};

const updateNeighbourhoodSpaceStart = state => {
  return updateObject(state, {
    loadingUpdateNeighbourhoodSpace: true,
    updateNeighbourhoodSpaceSuccess: false
  });
};

const updateNeighbourhoodSpaceReset = state => {
  return updateObject(state, {
    loadingUpdateNeighbourhoodSpace: false,
    updateNeighbourhoodSpaceSuccess: false,
    errorUpdateNeighbourhoodSpace: null,
    comboSpacesToBlock : [],

  });
};

////////////////////// DELETE NEIGHBOURHOOD SPACE //////////////////////
const deleteNeighbourhoodSpaceSuccess = (state, action) => {
  return updateObject(state, {
    deleteNeighbourhoodSpaceSuccess: true,
    loadingDeleteNeighbourhoodSpace: false
  });
};

const deleteNeighbourhoodSpaceFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteNeighbourhoodSpace: false,
    deleteNeighbourhoodSpaceSuccess: false,
    errorDeleteNeighbourhoodSpace: action.errorDeleteNeighbourhoodSpace
  });
};

const deleteNeighbourhoodSpaceStart = state => {
  return updateObject(state, {
    loadingDeleteNeighbourhoodSpace: true,
    deleteNeighbourhoodSpaceSuccess: false
  });
};

const deleteNeighbourhoodSpaceReset = state => {
  return updateObject(state, {
    loadingDeleteNeighbourhoodSpace: false,
    deleteNeighbourhoodSpaceSuccess: false,
    errorDeleteNeighbourhoodSpace: null
  });
};

//Fetch Booking Report

const fetchBookingsSuccess = (state, action) => {
  return updateObject(state, {
    fetchBookingsSuccess: true,
    loadingFetchBookings: false,
    bookingsData: action.bookingsData
  });
};

const fetchBookingsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchBookings: false,
    fetchBookingsSuccess: false,
    errorFetchBookings: action.errorFetchBookings,
    bookingsData: []
  });
};

const fetchBookingsStart = state => {
  return updateObject(state, {
    loadingFetchBookings: true,
    fetchBookingsSuccess: false
  });
};

const updateBookingsGrid = (state, action) => {
  return updateObject(state, {
    page: action.page,
    pageSize: action.pageSize,
    totalBookings: action.totalBookings
  });
};

const clearBookingsGrid = (state, action) => {
  return updateObject(state, {
    page: 0,
    pageSize: 10,
    totalBookings: 0
  });
};


//Fetch Export Booking Report

const fetchExportBookingsSuccess = (state, action) => {
  return updateObject(state, {
    fetchExportBookingsSuccess: true,
    loadingExportFetchBookings: false,
    exportBookingsData: action.exportBookingsData
  });
};

const fetchExportBookingsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchExportBookings: false,
    fetchExportBookingsSuccess: false,
    errorFetchExportBookings: action.errorFetchExportBookings,
    exportBookingsData: []
  });
};

const fetchExportBookingsStart = state => {
  return updateObject(state, {
    loadingFetchExportBookings: true,
    fetchExportBookingsSuccess: false
  });
};

const fetchExportBookingsReset = state => {
  return updateObject(state, {
    loadingFetchExportBookings: false,
    fetchExportBookingsSuccess: false,
    errorFetchExportBookings: null,
    exportBookingsData: null
  });
};

//Update Activity
const updateBookingActivitySuccess = (state, action) => {
  return updateObject(state, {
    loadingUpdateBookingActivity: false,
    updateBookingActivitySuccess: true
  });
};

const updateBookingActivityFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateBookingActivity: false,
    updateBookingActivitySuccess: false,
    errorUpdateBookingActivity: action.errorUpdateBookingActivity
  });
};

const updateBookingActivityStart = state => {
  return updateObject(state, {
    loadingUpdateBookingActivity: true,
    updateBookingActivitySuccess: false
  });
};

const updateBookingActivityReset = state => {
  return updateObject(state, {
    loadingUpdateBookingActivity: false,
    updateBookingActivitySuccess: false,
    errorUpdateBookingActivity: null
  });
};

// Fetch Activity
const fetchBookingActivitySuccess = (state, action) => {
  return updateObject(state, {
    bookingActivity: action.neighbourhoodActivities,
    loadingFetchBookingActivity: false
  });
};

const fetchBookingActivityFail = (state, action) => {
  return updateObject(state, {
    loadingFetchBookingActivity: false,
    errorFetchBookingActivity: action.errorFetchBookingActivity,
    bookingActivity: {}
  });
};

const fetchBookingActivityStart = state => {
  return updateObject(state, {
    loadingFetchBookingActivity: true,
    errorFetchBookingActivity: null
  });
};

//Schedules Calendar
const fetchSchedulesCalendarSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchSchedulesCalendar: false,
    fetchSchedulesCalendarSuccess: true,
    fetchSchedulesCalendarFail: false,
    schedulesCalendar: action.data
  });
};

const fetchSchedulesCalendarFail = (state, error) => {
  return updateObject(state, {
    loadingFetchSchedulesCalendar: false,
    fetchSchedulesCalendarSuccess: false,
    fetchSchedulesCalendarFail: error,
    schedulesCalendar: reservationsMock
  });
};

const fetchSchedulesCalendarStart = state => {
  return updateObject(state, {
    loadingFetchSchedulesCalendar: true,
    fetchSchedulesCalendarSuccess: false,
    fetchSchedulesCalendarFail: false,
    schedulesCalendar: reservationsMock
  });
};

const updateSelectedCalendarDateTime = (state, action) => {
  return updateObject(state, {
    selectedCalendarDateTime: action.data
  });
};

//Create Schedule
const createScheduleSuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateSchedule: false,
    createScheduleSuccess: true,
    errorCreateSchedule: null
  });
};

const createScheduleFail = (state, action) => {
  return updateObject(state, {
    loadingCreateSchedule: false,
    createScheduleSuccess: false,
    errorCreateSchedule: action.errorCreateSchedule
  });
};

const createScheduleStart = state => {
  return updateObject(state, {
    loadingCreateSchedule: true,
    createScheduleSuccess: false,
    errorCreateSchedule: null
  });
};

const createScheduleReset = (state, action) => {
  return updateObject(state, {
    loadingCreateSchedule: false,
    createScheduleSuccess: false,
    errorCreateSchedule: null
  });
};

//#region BOOKING NEIGHBOURHOOD
//#region ADD BOOKING NEIGHBOURHOOD
const createBookingNeighbourhoodSuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateBookingNeighbourhood: false,
    createBookingNeighbourhoodSuccess: true,
    errorCreateBookingNeighbourhood: null
  });
};

const createBookingNeighbourhoodFail = (state, action) => {
  return updateObject(state, {
    loadingCreateBookingNeighbourhood: false,
    createBookingNeighbourhoodSuccess: false,
    errorCreateBookingNeighbourhood: action.errorcreateBookingNeighbourhood
  });
};

const createBookingNeighbourhoodStart = state => {
  return updateObject(state, {
    loadingCreateBookingNeighbourhood: true,
    createBookingNeighbourhoodSuccess: false,
    errorCreateBookingNeighbourhood: null
  });
};

const createBookingNeighbourhoodReset = state => {
  return updateObject(state, {
    loadingCreateBookingNeighbourhood: false,
    createBookingNeighbourhoodSuccess: false,
    errorCreateBookingNeighbourhood: null
  });
};
//#endregion
//#endregion

//#region CANCEL BOOKING
const cancelBookingSuccess = (state, action) => {
  return updateObject(state, {
    loadingCancelBooking: false,
    cancelBookingSuccess: true,
    errorCancelBooking: null
  });
};

const cancelBookingFail = (state, action) => {
  return updateObject(state, {
    loadingCancelBooking: false,
    cancelBookingSuccess: false,
    errorCancelBooking: action.errorCancelBooking
  });
};

const cancelBookingStart = state => {
  return updateObject(state, {
    loadingCancelBooking: true,
    cancelBookingSuccess: false,
    errorCancelBooking: null
  });
};

const cancelBookingReset = state => {
  return updateObject(state, {
    loadingCancelBooking: false,
    cancelBookingSuccess: false,
    errorCancelBooking: null
  });
};
//#endregion

//FETCH SCHEDULE DETAIL
const fetchScheduleDetailSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchScheduleDetail: false,
    fetchScheduleDetailSuccess: true,
    scheduleDetail: action.scheduleDetail
  });
};

const fetchScheduleDetailFail = (state, action) => {
  return updateObject(state, {
    loadingFetchScheduleDetail: false,
    fetchScheduleDetailSuccess: false,
    scheduleDetail: {}
  });
};

const fetchScheduleDetailStart = state => {
  return updateObject(state, {
    loadingFetchScheduleDetail: true,
    fetchScheduleDetailSuccess: false,
    scheduleDetail: {}
  });
};

const fetchScheduleDetailReset = state => {
  return updateObject(state, {
    loadingFetchScheduleDetail: false,
    fetchScheduleDetailSuccess: false,
    scheduleDetail: {}
  });
};

////////UPDATE SCHEDULE /////
const updateScheduleSuccess = (state, action) => {
  return updateObject(state, {
    loadingUpdateSchedule: false,
    updateScheduleSuccess: true
  });
};

const updateScheduleFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateSchedule: false,
    updateScheduleSuccess: false,
    errorUpdateSchedule: action.errorUpdateSchedule
  });
};

const updateScheduleStart = state => {
  return updateObject(state, {
    loadingUpdateSchedule: true,
    updateScheduleSuccess: false
  });
};

const updateScheduleReset = state => {
  return updateObject(state, {
    loadingUpdateSchedule: false,
    updateScheduleSuccess: false,
    errorUpdateSchedule: null
  });
};

//Create Términos y condiciones
const createTyCSuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateTyC: false,
    createTyCSuccess: true,
    errorCreateTyC: null
  });
};

const createTyCFail = (state, action) => {
  return updateObject(state, {
    loadingCreateTyC: false,
    errorCreateTyC: action.errorCreateTyC,
    createTyCSuccess: false
  });
};

const createTyCStart = state => {
  return updateObject(state, {
    loadingCreateTyC: true,
    errorCreateTyC: null
  });
};

const createTyCReset = state => {
  return updateObject(state, {
    loadingCreateTyC: false,
    createTyCSuccess: false,
    errorCreateTyC: null
  });
};

//Fetch Rules
const fetchRulesSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchRules: false,
    fetchRulesSuccess: true,
    errorFetchRules: null,
    rules: action.rules
  });
};

const fetchRulesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchRules: false,
    errorFetchRules: action.errorFetchRules,
    fetchRulesSuccess: false
  });
};

const fetchRulesStart = state => {
  return updateObject(state, {
    loadingFetchRules: true,
    errorFetchRules: null
  });
};

const fetchRulesReset = state => {
  return updateObject(state, {
    loadingFetchRules: false,
    fetchRulesSuccess: false,
    errorFetchRules: null
  });
};

//Fetch Rule Value
const fetchRuleValuesSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchRuleValues: false,
    fetchRuleValuesSuccess: true,
    errorFetchRuleValues: null,
    ruleValues: action.ruleValues
  });
};

const fetchRuleValuesFail = (state, action) => {
  return updateObject(state, {
    loadingFetchRuleValues: false,
    errorFetchRuleValues: action.errorFetchRuleValues,
    fetchRuleValuesSuccess: false
  });
};

const fetchRuleValuesStart = state => {
  return updateObject(state, {
    loadingFetchRuleValues: true,
    errorFetchRuleValues: null
  });
};

const fetchRuleValuesReset = state => {
  return updateObject(state, {
    loadingFetchRuleValues: false,
    fetchRuleValuesSuccess: false,
    errorFetchRuleValues: null
  });
};

//Update Rule Value
const updateRuleValueSuccess = (state, action) => {
  return updateObject(state, {
    loadingUpdateRuleValue: false,
    updateRuleValueSuccess: true,
    responseMessage: 'El valor se ha actualizado.'
  });
};

const updateCurrentRuleValue = (state, action) => {
  var auxRuleValues = state.ruleValues;
  auxRuleValues[state.ruleValues.findIndex((ruleValue => ruleValue.id == action.activityRuleValueId))].activity_rule_value = action.value;
  return updateObject(state, {
    ruleValues: auxRuleValues

  });
};


const updateRuleValueFail = (state, action) => {
  return updateObject(state, {
    loadingUpdateRuleValue: false,
    updateRuleValueSuccess: false,
    errorUpdateRuleValue: true,
    responseMessage: action.responseMessage
  });
};

const updateRuleValueStart = state => {
  return updateObject(state, {
    loadingUpdateRuleValue: true,
    updateRuleValueSuccess: false
  });
};



const updateRuleValueReset = state => {
  return updateObject(state, {
    loadingUpdateRuleValue: false,
    updateRuleValueSuccess: false,
    errorUpdateRuleValue: false,
    responseMessage: ''
  });
};

//Delete Rule Value
const deleteRuleValueStart = state => {
  return updateObject(state, {
    loadingDeleteRuleValue: true,
    deleteRuleValueSuccess: false
  });
};

const deleteRuleValueSuccess = state => {
  return updateObject(state, {
    loadingDeleteRuleValue: false,
    deleteRuleValueSuccess: true
  });
};
const deleteRuleValueFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteRuleValue: false,
    deleteRuleValueSuccess: false,
    errorDeleteRuleValue: action.errorDeleteRuleValue
  });
};

const deleteRuleValueReset = state => {
  return updateObject(state, {
    loadingDeleteRuleValue: false,
    deleteRuleValueSuccess: false,
    errorDeleteRuleValue: null
  });
};

// Create Rule Value
const createRuleValuesSuccess = (state, action) => {
  return updateObject(state, {
    loadingCreateRuleValues: false,
    createRuleValuesSuccess: true,
    errorCreateRuleValues: null
  });
};

const createRuleValuesFail = (state, action) => {
  return updateObject(state, {
    loadingCreateRuleValues: false,
    errorCreateRuleValues: action.errorCreateRuleValues,
    createRuleValuesSuccess: false
  });
};

const createRuleValuesStart = state => {
  return updateObject(state, {
    loadingCreateRuleValues: true,
    errorCreateRuleValues: null
  });
};

const createRuleValuesReset = state => {
  return updateObject(state, {
    loadingCreateRuleValues: false,
    createRuleValuesSuccess: false,
    errorCreateRuleValues: null
  });
};

// Fetch ScheduleBookings
const fetchScheduleBookingsSuccess = (state, action) => {
  return updateObject(state, {
    loadingFetchScheduleBookings: false,
    scheduleBookings: action.data,
    errorScheduleBookings: null
  });
};

const fetchScheduleBookingsFail = (state, action) => {
  return updateObject(state, {
    loadingFetchScheduleBookings: false,
    errorScheduleBookings: action.error,
    scheduleBookings: []
  });
};

const fetchScheduleBookingsStart = state => {
  return updateObject(state, {
    loadingFetchScheduleBookings: true,
    errorScheduleBookings: null,
    scheduleBookings: []
  });
};

const setSelectedNeighbourhood = (state, action) => {
  return updateObject(state, {
    selectedNeighbourhood: action.selectedNeighbourhood
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    //Spaces
    case actionTypes.FETCH_BOOKING_SPACES_SUCCESS:
      return fetchSpacesSuccess(state, action);
    case actionTypes.FETCH_BOOKING_SPACES_FAIL:
      return fetchSpacesFail(state, action);
    case actionTypes.FETCH_BOOKING_SPACES_START:
      return fetchSpacesStart(state, action);
    case actionTypes.UPDATE_BOOKING_SPACE_FILTERS:
      return updateSpaceFilters(state, action);
    case actionTypes.CLEAR_BOOKING_SPACES:
      return clearSpaces(state);
    //Spaces para bloquear
    case actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_SUCCESS:
      return fetchComboSpacesToBlockSuccess(state, action);
    case actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_FAIL:
      return fetchComboSpacesToBlockFail(state, action);
    case actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_START:
      return fetchComboSpacesToBlockStart(state, action);
    case actionTypes.FETCH_COMBO_SPACE_TO_BLOCK_RESET:
      return fetchComboSpacesToBlockReset(state, action);
    //Combo Activities
    case actionTypes.FETCH_COMBO_ACTIVITIES_SUCCESS:
      return fetchComboActivitiesSuccess(state, action);
    case actionTypes.FETCH_COMBO_ACTIVITIES_FAIL:
      return fetchComboActivitiesFail(state, action);
    case actionTypes.FETCH_COMBO_ACTIVITIES_START:
      return fetchComboActivitiesStart(state, action);
    //Activities
    case actionTypes.FETCH_BOOKING_ACTIVITIES_SUCCESS:
      return fetchBookingActivitiesSuccess(state, action);
    case actionTypes.FETCH_BOOKING_ACTIVITIES_FAIL:
      return fetchBookingActivitiesFail(state, action);
    case actionTypes.FETCH_BOOKING_ACTIVITIES_START:
      return fetchBookingActivitiesStart(state, action);
    //Schedules Calendar
    case actionTypes.FETCH_SCHEDULES_CALENDAR_SUCCESS:
      return fetchSchedulesCalendarSuccess(state, action);
    case actionTypes.FETCH_SCHEDULES_CALENDAR_FAIL:
      return fetchSchedulesCalendarFail(state, action);
    case actionTypes.FETCH_SCHEDULES_CALENDAR_START:
      return fetchSchedulesCalendarStart(state, action);
    case actionTypes.UPDATE_SELECTED_CALENDAR_DATE:
      return updateSelectedCalendarDateTime(state, action);
    case actionTypes.UPDATE_BOOKING_ACTIVITIES_FILTERS:
      return updateActivityFilters(state, action);
    // Create Activities
    case actionTypes.CREATE_BOOKING_ACTIVITY_SUCCESS:
      return createBookingActivitySuccess(state, action);
    case actionTypes.CREATE_BOOKING_ACTIVITY_FAIL:
      return createBookingActivityFail(state, action);
    case actionTypes.CREATE_BOOKING_ACTIVITY_START:
      return createBookingActivityStart(state, action);
    case actionTypes.CREATE_BOOKING_ACTIVITY_RESET:
      return createBookingActivityReset(state, action);
    // Delete Activity
    case actionTypes.DELETE_BOOKING_ACTIVITY_SUCCESS:
      return deleteBookingActivitySuccess(state, action);
    case actionTypes.DELETE_BOOKING_ACTIVITY_FAIL:
      return deleteBookingActivityFail(state, action);
    case actionTypes.DELETE_BOOKING_ACTIVITY_START:
      return deleteBookingActivityStart(state, action);
    case actionTypes.DELETE_BOOKING_ACTIVITY_RESET:
      return deleteBookingActivityReset(state, action);

    //Fetch neighbourhood space detail
    case actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_SUCCESS:
      return fetchNeighbourhoodSpaceDetailSuccess(state, action);
    case actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_FAIL:
      return fetchNeighbourhoodSpaceDetailFail(state, action);
    case actionTypes.FETCH_NEIGHBOURHOOD_SPACE_DETAIL_START:
      return fetchNeighbourhoodSpaceDetailStart(state, action);

    //Create neighbourhood space
    case actionTypes.CREATE_NEIGHBOURHOOD_SPACE_SUCCESS:
      return createNeighbourhoodSpaceSuccess(state, action);
    case actionTypes.CREATE_NEIGHBOURHOOD_SPACE_FAIL:
      return createNeighbourhoodSpaceFail(state, action);
    case actionTypes.CREATE_NEIGHBOURHOOD_SPACE_START:
      return createNeighbourhoodSpaceStart(state, action);
    case actionTypes.CREATE_NEIGHBOURHOOD_SPACE_RESET:
      return createNeighbourhoodSpaceReset(state, action);
    //Update Neighbourhood Space

    case actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_SUCCESS:
      return updateNeighbourhoodSpaceSuccess(state, action);
    case actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_FAIL:
      return updateNeighbourhoodSpaceFail(state, action);
    case actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_START:
      return updateNeighbourhoodSpaceStart(state, action);
    case actionTypes.UPDATE_NEIGHBOURHOOD_SPACE_RESET:
      return updateNeighbourhoodSpaceReset(state, action);
    //Delete neighbourhood space
    case actionTypes.DELETE_NEIGHBOURHOOD_SPACE_SUCCESS:
      return deleteNeighbourhoodSpaceSuccess(state, action);
    case actionTypes.DELETE_NEIGHBOURHOOD_SPACE_FAIL:
      return deleteNeighbourhoodSpaceFail(state, action);
    case actionTypes.DELETE_NEIGHBOURHOOD_SPACE_START:
      return deleteNeighbourhoodSpaceStart(state, action);
    case actionTypes.DELETE_NEIGHBOURHOOD_SPACE_RESET:
      return deleteNeighbourhoodSpaceReset(state, action);
    //Fetch Bookings
    case actionTypes.FETCH_BOOKINGS_SUCCESS:
      return fetchBookingsSuccess(state, action);
    case actionTypes.FETCH_BOOKINGS_FAIL:
      return fetchBookingsFail(state, action);
    case actionTypes.FETCH_BOOKINGS_START:
      return fetchBookingsStart(state, action);
    case actionTypes.UPDATE_BOOKINGS_GRID:
      return updateBookingsGrid(state, action);
    case actionTypes.CLEAR_BOOKINGS_GRID:
      return clearBookingsGrid(state, action);

    //Fetch Export Bookings
    case actionTypes.FETCH_EXPORT_BOOKINGS_SUCCESS:
      return fetchExportBookingsSuccess(state, action);
    case actionTypes.FETCH_EXPORT_BOOKINGS_FAIL:
      return fetchExportBookingsFail(state, action);
    case actionTypes.FETCH_EXPORT_BOOKINGS_START:
      return fetchExportBookingsStart(state, action);
    case actionTypes.FETCH_EXPORT_BOOKINGS_RESET:
      return fetchExportBookingsReset(state, action);

    //Update Activity
    case actionTypes.UPDATE_BOOKING_ACTIVITY_SUCCESS:
      return updateBookingActivitySuccess(state, action);
    case actionTypes.UPDATE_BOOKING_ACTIVITY_FAIL:
      return updateBookingActivityFail(state, action);
    case actionTypes.UPDATE_BOOKING_ACTIVITY_START:
      return updateBookingActivityStart(state, action);
    case actionTypes.UPDATE_BOOKING_ACTIVITY_RESET:
      return updateBookingActivityReset(state, action);

    //Fetch Activity
    case actionTypes.FETCH_BOOKING_ACTIVITY_SUCCESS:
      return fetchBookingActivitySuccess(state, action);
    case actionTypes.FETCH_BOOKING_ACTIVITY_FAIL:
      return fetchBookingActivityFail(state, action);
    case actionTypes.FETCH_BOOKING_ACTIVITY_START:
      return fetchBookingActivityStart(state, action);

    //#region BOOKING NEIGHBOURHOOD
    //#region ADD BOOKING NEIGHBOURHOOD
    case actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_SUCCESS:
      return createBookingNeighbourhoodSuccess(state, action);
    case actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_FAIL:
      return createBookingNeighbourhoodFail(state, action);
    case actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_START:
      return createBookingNeighbourhoodStart(state, action);
    case actionTypes.CREATE_BOOKING_NEIGHBOURHOOD_RESET:
      return createBookingNeighbourhoodReset(state, action);
    //#endregion
    //#endregion

    //#region
    case actionTypes.CANCEL_BOOKING_SUCCESS:
      return cancelBookingSuccess(state, action);
    case actionTypes.CANCEL_BOOKING_FAIL:
      return cancelBookingFail(state, action);
    case actionTypes.CANCEL_BOOKING_START:
      return cancelBookingStart(state, action);
    case actionTypes.CANCEL_BOOKING_RESET:
      return cancelBookingReset(state, action);
    //#endregion

    //Create Schedule
    case actionTypes.CREATE_SCHEDULE_SUCCESS:
      return createScheduleSuccess(state, action);
    case actionTypes.CREATE_SCHEDULE_FAIL:
      return createScheduleFail(state, action);
    case actionTypes.CREATE_SCHEDULE_START:
      return createScheduleStart(state, action);
    case actionTypes.CREATE_SCHEDULE_RESET:
      return createScheduleReset(state, action);
    //Fetch Schedule Detail
    case actionTypes.FETCH_SCHEDULE_DETAIL_SUCCESS:
      return fetchScheduleDetailSuccess(state, action);
    case actionTypes.FETCH_SCHEDULE_DETAIL_FAIL:
      return fetchScheduleDetailFail(state, action);
    case actionTypes.FETCH_SCHEDULE_DETAIL_START:
      return fetchScheduleDetailStart(state, action);
    case actionTypes.FETCH_SCHEDULE_DETAIL_RESET:
      return fetchScheduleDetailReset(state, action);
    //Update Schedule
    case actionTypes.UPDATE_SCHEDULE_SUCCESS:
      return updateScheduleSuccess(state, action);
    case actionTypes.UPDATE_SCHEDULE_FAIL:
      return updateScheduleFail(state, action);
    case actionTypes.UPDATE_SCHEDULE_START:
      return updateScheduleStart(state, action);
    case actionTypes.UPDATE_SCHEDULE_RESET:
      return updateScheduleReset(state, action);
    // Create TyC
    case actionTypes.CREATE_TYC_SUCCESS:
      return createTyCSuccess(state, action);
    case actionTypes.CREATE_TYC_FAIL:
      return createTyCFail(state, action);
    case actionTypes.CREATE_TYC_START:
      return createTyCStart(state, action);
    case actionTypes.CREATE_TYC_RESET:
      return createTyCReset(state, action);

    // Fetch Rules
    case actionTypes.FETCH_RULES_SUCCESS:
      return fetchRulesSuccess(state, action);
    case actionTypes.FETCH_RULES_FAIL:
      return fetchRulesFail(state, action);
    case actionTypes.FETCH_RULES_START:
      return fetchRulesStart(state, action);
    case actionTypes.FETCH_RULES_RESET:
      return fetchRulesReset(state, action);

    // Fetch Rule Value
    case actionTypes.FETCH_RULE_VALUES_SUCCESS:
      return fetchRuleValuesSuccess(state, action);
    case actionTypes.FETCH_RULE_VALUES_FAIL:
      return fetchRuleValuesFail(state, action);
    case actionTypes.FETCH_RULE_VALUES_START:
      return fetchRuleValuesStart(state, action);
    case actionTypes.FETCH_RULE_VALUES_RESET:
      return fetchRuleValuesReset(state, action);

    // Update Rule Value
    case actionTypes.UPDATE_RULE_VALUE_SUCCESS:
      return updateRuleValueSuccess(state, action);
    case actionTypes.UPDATE_RULE_VALUE_FAIL:
      return updateRuleValueFail(state, action);
    case actionTypes.UPDATE_RULE_VALUE_START:
      return updateRuleValueStart(state, action);
    case actionTypes.UPDATE_RULE_VALUE_RESET:
      return updateRuleValueReset(state, action);
    case actionTypes.UPDATE_CURRENT_RULE_VALUE:
      return updateCurrentRuleValue(state, action);

    // Delete Rule Value
    case actionTypes.DELETE_RULE_VALUE_SUCCESS:
      return deleteRuleValueSuccess(state, action);
    case actionTypes.DELETE_RULE_VALUE_FAIL:
      return deleteRuleValueFail(state, action);
    case actionTypes.DELETE_RULE_VALUE_START:
      return deleteRuleValueStart(state, action);
    case actionTypes.DELETE_RULE_VALUE_RESET:
      return deleteRuleValueReset(state, action);

    // Create Rule Value
    case actionTypes.CREATE_RULE_VALUES_SUCCESS:
      return createRuleValuesSuccess(state, action);
    case actionTypes.CREATE_RULE_VALUES_FAIL:
      return createRuleValuesFail(state, action);
    case actionTypes.CREATE_RULE_VALUES_START:
      return createRuleValuesStart(state, action);
    case actionTypes.CREATE_RULE_VALUES_RESET:
      return createRuleValuesReset(state, action);

    // Create Rule Value
    case actionTypes.FETCH_SCHEDULES_BOOKINGS_SUCCESS:
      return fetchScheduleBookingsSuccess(state, action);
    case actionTypes.FETCH_SCHEDULES_BOOKINGS_FAIL:
      return fetchScheduleBookingsFail(state, action);
    case actionTypes.FETCH_SCHEDULES_BOOKINGS_START:
      return fetchScheduleBookingsStart(state, action);

    case actionTypes.SET_SELECTED_NEIGHBOURHOOD:
      return setSelectedNeighbourhood(state, action);

    default:
      return state;
  }
};

export default reducer;
