import styled from 'styled-components'

export const PageWrapper = styled.div`
width: 100%;
min-height: 100%;
align-items: normal;
&:before {
  animation: portal-profile-before 0.6s ease-out forwards 1;
  animation-delay: 0.2s;
}
&:after {
  animation: portal-profile-after 0.4s ease-out forwards 1;
  animation-delay: 0.4s;
}
`
export const Wrapper = styled.div`
 
  border-radius: 10px;
  padding: 10px;
`

export const Button = styled.div`
 
 margin-left:16px;
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
`

export const MainWrapper = styled.div`
  padding: 10px;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
  flex-grow: 1;
  order: 1;
  align-items: center;
`
export const SecondWrapper = styled.div`

  min-height: 560px;
  padding: 10px;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
  flex-grow: 1;
  order: 1;
  align-items: center;
`

export const ListMovementsWrapper = styled.div`
min-height: 560px;
padding-right: 13px;
overflow-y: scroll;
border-bottom-left-radius: 10px;
flex-grow: 2;
order: 1;
`

export const HeaderMovementsWrapper = styled.div`
min-height: 20px;
padding-right: 13px;
padding-left: 13px;

max-height: 100px;
display: flex;
justify-content: start;
flex-direction: row;
flex-wrap: nowrap;
`

export const AccountInfoData = styled.div`
display: grid;
grid-template-columns: 50% 50%;
border-radius: 4px;
background-color: white;
padding: 14px;
margin-top: 10px;
`

export const TituloAccountInfoData = styled.div`
text-align: left;

`
export const DetalleAccountInfoData = styled.div`
text-align: right;
color: #2f735d;
font-weight: 600;
`

export const VolverBtn = styled.a`
    float: left;
    cursor: pointer;
    padding: 1px 6px 21px;
`
export const TitleWrapper = styled.div`
flex-grow: 2;
order: 1;
`

export const FormControlstyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: start;
`
export const Refresh = styled.img`
  width: 30px;
  order: 3;
  margin: 0% 4%;
  
:hover {
  cursor: pointer;
}
`


export const EstadoLabelAlerta = styled.div`
  color: #FB2A2A;
  background-color: #FEE9E9;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  border-radius:20px;
`

export const EstadoLabelResuelto = styled.div`
  color: #81BE3B;
  background-color: #F2F8EB;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-align:center;
  padding:10px;
  border-radius:20px;
`


export const DisclaimerLabel = styled.div`
  display: inline-block;
  background-color: ${({ error, theme }) => error ? theme.colors.pomegranate : "#15994C"};
  border-radius: 4px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 8vh;
  margin: 0px 0px 10px 0px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 15px; 
  display: flex;
  flex-direction: row;
  justify-content: ${({ error }) => error ? "center" : "space-between"};
  align-items: center;
  visibility:  ${props => props.out ? "hidden" : "visible"};
  transition: ${props => props.out ? "visibility 0s 2s, opacity 2s linear" : "opacity 2s linear"};

}
`
export const ContainerImg = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  margin-right: 10px;
  justify-content: space-around;
`
export const Icon = styled.img`
  width: ${({ width }) => width || '25px'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || 'auto'};
  cursor: pointer;
`
export const Row = styled.div`
  position: fixed;
  bottom: 0;
  width: 86vw;
  background-color: white;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`
export const Price = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ amount,theme }) => (amount < 0 ? theme.colors.pomegranate : theme.colors.oceanGreen)};
    font-size: "18px";
    align-self: center;
    font-weight: 600;
`

export const Date = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
`


export const LineSeparator = styled.label`
  display: block;
  background-color: ${({ theme }) => theme.colors.mercury};
   height: 4px;
   margin: 35px 0px;
`


export const TableWrapper = styled.div`
    position:relative;
`

export const LoaderWrapper = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    padding-top:4em;
    padding-bottom:4em;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  display: flex;
  align-items: flex-start;
  align-items: center;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  margin-right: 10px;
`

export const SelectWrapper = styled.div`
  width: 300px;
  font-weight: normal;
`

// export const SelectWrapper = styled.div`
//   z-index: 100;
//   flex-grow: 1;
//   order: 2;
//   width: 45%;
//   margin: 0px 0px 0px 0px;
// `


export default styled


