import * as actionTypes from './actionTypes'
import {
  
  getNeighbourhoodAPI,
  
} from '../../apiConstants'

import getApi from '../../shared/api'

export const fetchNeighbourhoodsSuccess = neighbourhood => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_SUCCESS,
    neighbourhood: neighbourhood
  }
}

export const fetchNeighbourhoodsFail = error => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_FAIL,
    error: error
  }
}

export const fetchNeighbourhoodsStart = () => {
  return {
    type: actionTypes.FETCH_NEIGHBOURHOOD_START
  }
}

export const fetchNeighbourhoods = () => {
  return async dispatch => {
    dispatch(fetchNeighbourhoodsStart())
    const api = await getApi()
    api
      .get(`${getNeighbourhoodAPI}/0/1000`)
      .then(res => {
        dispatch(fetchNeighbourhoodsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchNeighbourhoodsFail(err))
      })
  }
}
