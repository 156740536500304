/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import StyledSelect from '../../../components/StyledSelect';
import SectionHeader from '../../../components/SectionHeader';
import RitzTable from '../../../components/RitzTable';
import Button from "../../../components/Button";
import { exportFromJson } from '../../../utils/xlsxExportHelper'
import DatePickerStyled from '../../../components/DatePickerStyled'
import Loader from "../../../components/Loader";
import ModalMessage from "../../../components/ModalMessage";
import SearchInput from "../../../components/SearchInput";
import { fetchComboActivities, fetchSpaces, fetchBookings, fetchExportBookings, fetchExportBookingsReset, clearBookingsGrid, clearSpaces } from '../../../store/actions/bookings.actions'
import {
  FormContainer, Row, InputWrapper, InputLabel, TableWrapper, SelectWrapper, LoaderWrapper
} from './styled';

const mapStateToProps = state => {
  const {
    loadingfetchComboActivities,
    fetchComboActivitiesSuccess,
    comboActivities,
    loadingfetchSpaces,
    fetchSpacesSuccess,
    spaces,
    loadingFetchBookings,
    bookingsData,
    fetchBookingsSuccess,
    loadingFetchExportBookings,
    exportBookingsData,
    fetchExportBookingsSuccess,
    errorFetchExportBookings,
    totalBookings,
    pageSize,
    page
  } = state.bookings;

  return {
    loadingfetchComboActivities,
    fetchComboActivitiesSuccess,
    comboActivities,
    loadingfetchSpaces,
    fetchSpacesSuccess,
    spaces,
    loadingFetchBookings,
    bookingsData,
    fetchBookingsSuccess,
    loadingFetchExportBookings,
    exportBookingsData,
    fetchExportBookingsSuccess,
    errorFetchExportBookings,
    totalBookings,
    pageSize,
    page,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchComboActivities,
      fetchSpaces,
      fetchBookings,
      fetchExportBookings,
      fetchExportBookingsReset,
      clearBookingsGrid,
      clearSpaces
    },
    dispatch
  );

function BookingsList(props) {
  const {
    neighbourhoods,
    comboActivities,
    loadingfetchComboActivities,
    fetchComboActivitiesSuccess,
    loadingfetchSpaces,
    fetchSpacesSuccess,
    spaces,
    loadingFetchBookings,
    bookingsData,
    fetchBookingsSuccess,
    loadingFetchExportBookings,
    exportBookingsData,
    fetchExportBookingsSuccess,
    errorFetchExportBookings,
    totalBookings,
    pageSize,
    page,
    selectedNeighbourhood
  } = props;

  const [searchText, setSearchText] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderField, setOrderField] = useState("date");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [showExportErrorModal, setShowExportErrorModal] = useState(false);

  useEffect(() => {
    props.clearBookingsGrid();
    if (neighbourhoods.length > 0) {
      if (!selectedNeighbourhood)
        props.setSelectedNeighbourhood(neighbourhoods[0]);
      setSelectedActivity({ label: "Todas", value: null });
      setSelectedSpace({ label: "Todos", value: null });
      props.fetchComboActivities(selectedNeighbourhood ? selectedNeighbourhood.value : neighbourhoods[0].value, true);
      props.fetchBookings(
        selectedNeighbourhood ? selectedNeighbourhood.value : neighbourhoods[0].value,
        null,
        null,
        startDate.toISOString(),
        searchText,
        orderField,
        orderDirection,
        page,
        pageSize
      );
    }
    return function cleanup() {
      props.fetchExportBookingsReset();
    };
  }, []);


  useEffect(() => {
    if (fetchExportBookingsSuccess) {
      exportFromJson(exportBookingsData, columns, "bookings.");
      props.fetchExportBookingsReset();
    }
    if (errorFetchExportBookings) {
      toggleShowExportErrorModal(!showExportErrorModal);
      props.fetchExportBookingsReset();
    }
  }, [fetchExportBookingsSuccess || errorFetchExportBookings]);

  const renderModalMessageFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const toggleShowExportErrorModal = () => {
    setShowExportErrorModal(!showExportErrorModal);
  };


  const onNeighbourhoodChange = async value => {
    props.setSelectedNeighbourhood(value);
    setSelectedActivity({ label: "Todas", value: null });
    setSelectedSpace({ label: "Todos", value: null });
    props.clearSpaces();
    await props.fetchComboActivities(value.value, true);
    await props.fetchBookings(
      value.value,
      null,
      null,
      startDate.toISOString(),
      searchText,
      orderField,
      orderDirection,
      page,
      pageSize
    );
  };

  const onActivityChange = async value => {
    setSelectedActivity(value);
    setSelectedSpace({ label: "Todos", value: null });
    if (value != null && value.value != null)
      await props.fetchSpaces(selectedNeighbourhood.value, value, "");
    await props.fetchBookings(
      selectedNeighbourhood.value,
      value.value,
      null,
      startDate.toISOString(),
      searchText,
      orderField,
      orderDirection,
      page,
      pageSize
    );
  };

  const onSpaceChange = async value => {
    setSelectedSpace(value);
    props.fetchBookings(
      selectedNeighbourhood.value,
      selectedActivity ? selectedActivity.value : null,
      value.value,
      startDate.toISOString(),
      searchText,
      orderField,
      orderDirection,
      page,
      pageSize
    );
  };

  const onStartDateChange = value => {
    setStartDate(value);
    props.fetchBookings(
      selectedNeighbourhood.value,
      selectedActivity ? selectedActivity.value : null,
      selectedSpace ? selectedSpace.value : null,
      value.toISOString(),
      searchText,
      orderField,
      orderDirection,
      page,
      pageSize
    );
  };

  const onSearchChangeHandler = e => {
    if (e.target.value === "") {
      props.fetchBookings(
        selectedNeighbourhood.value,
        selectedActivity ? selectedActivity.value : null,
        selectedSpace ? selectedSpace.value : null,
        startDate.toISOString(),
        "",
        orderField,
        orderDirection,
        page,
        pageSize
      );
    }
  };

  const onSearchKeyDownHandler = e => {
    if (e.keyCode === 13) {
      setSearchText(e.target.value);
      props.fetchBookings(
        selectedNeighbourhood.value,
        selectedActivity ? selectedActivity.value : null,
        selectedSpace ? selectedSpace.value : null,
        startDate.toISOString(),
        e.target.value,
        orderField,
        orderDirection,
        page,
        pageSize
      );
    }
  };

  const onOrderChangeHandler = (orderedColumnId, orderDirection) => {
    var columnName = "date";
    var dir = "desc";
    if (orderedColumnId != null) {
      const column = columns[orderedColumnId];
      if (column != null) {
        setOrderField(column.field);
        columnName = column.field;
      }
    }

    if (orderDirection != null && orderDirection !== "") {
      setOrderDirection(orderDirection);
      dir = orderDirection;
    }

    props.fetchBookings(
      selectedNeighbourhood.value,
      selectedActivity ? selectedActivity.value : null,
      selectedSpace ? selectedSpace.value : null,
      startDate.toISOString(),
      searchText,
      columnName,
      dir,
      props.page,
      props.pageSize
    );
  };

  const formatSpaceOptions = spaces => {
    var mappedSpaces = spaces.map(space => ({ label: space.name, value: space.id }));
    mappedSpaces.push({ value: null, label: "Todos" });
    return mappedSpaces.sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const onChangeRowsPerPageHandler = pageSize => {
    props.fetchBookings(
      selectedNeighbourhood.value,
      selectedActivity ? selectedActivity.value : null,
      selectedSpace ? selectedSpace.value : null,
      startDate.toISOString(),
      searchText,
      orderField,
      orderDirection,
      props.page,
      pageSize
    );
  };

  var columns = [
    { title: 'Fecha', field: 'date', cellStyle: { borderRadius: '6px 0px 0px 6px' } },
    { title: 'Nombre y apellido', field: 'user_full_name', cellStyle: { headerStyle: { minWidth: '110px' }, } },
    { title: 'DNI', field: 'dni' },
    { title: 'Desde', field: 'from', headerStyle: { maxWidth: '65px' },
    cellStyle: { maxWidth: '65px' } },
    { title: 'Hasta', field: 'to', headerStyle: { maxWidth: '65px' },
    cellStyle: { maxWidth: '65px' }},
    { title: 'Lote', field: 'property_code' },
    { title: 'Espacio', field: 'space' },
    { title: 'Actividad', field: 'activity' },
    {
      title: 'Realizado por', field: 'made_by'
    },
    {
      title: 'Pertenece a', field: 'neighbourhood_name', headerStyle: { minWidth: '150px' },
      cellStyle: { minWidth: '110px' }
    },
    { title: 'Precio', field: 'price' }
  ]

  const onChangePageHandler = (e, page) => {
    props.fetchBookings(
      selectedNeighbourhood.value,
      selectedActivity ? selectedActivity.value : null,
      selectedSpace ? selectedSpace.value : null,
      startDate.toISOString(),
      searchText,
      orderField,
      orderDirection,
      page,
      pageSize
    );
  };

  let isLoading = loadingFetchBookings || loadingfetchComboActivities || loadingfetchSpaces || loadingFetchExportBookings;

  return (
    <>
      <Row justifyContent={'space-between'}>
        <SectionHeader
          title="Reservas"
          width="100%"
          justifyContent={"space-between"}
          fontWeight={"bold"}
          alignItems={"baseline"}
        ></SectionHeader>
        <FormContainer>
          <InputWrapper width={'350px'} justifyContent={'flex-end'}>
            <InputLabel fontWeight={"bold"}>{"Desde"}</InputLabel>
            <DatePickerStyled
              width={'70%'}
              selected={startDate}
              startDate={startDate}
              onChange={value => onStartDateChange(value)}
              selectsStart
              dateFormat={"dd/MM/yyyy"}
              placeholderText={"A partir de..."}
              padding={"8px 1%"}
              popperPlacement="bottom"
              disabled={isLoading}
              popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />
          </InputWrapper>
          <InputWrapper width={'350px'} justifyContent={'flex-end'}>
            <InputLabel fontWeight={"bold"}>{"Barrio"}</InputLabel>
            <SelectWrapper>
              <StyledSelect
                value={selectedNeighbourhood}
                onChange={value => onNeighbourhoodChange(value)}
                options={props.neighbourhoods}
                placeholder="Seleccione un barrio"
                isDisabled={
                  isLoading
                }
              />
            </SelectWrapper>
          </InputWrapper>
          <InputWrapper width={'350px'} justifyContent={'flex-end'}>
            <InputLabel fontWeight={"bold"}>{"Actividad"}</InputLabel>
            <SelectWrapper>
              <StyledSelect
                value={selectedActivity}
                onChange={value => onActivityChange(value)}
                options={comboActivities}
                placeholder="Seleccione una actividad"
                isLoading={loadingfetchComboActivities}
                isDisabled={
                  isLoading
                }
              />
            </SelectWrapper>
          </InputWrapper>
          <InputWrapper width={'350px'} justifyContent={'flex-end'}>
            <InputLabel fontWeight={"bold"}>{"Espacio"}</InputLabel>
            <SelectWrapper>
              <StyledSelect
                value={selectedSpace}
                onChange={value => onSpaceChange(value)}
                options={formatSpaceOptions(spaces)}
                placeholder="Seleccione un espacio"
                isLoading={loadingfetchSpaces}
                isDisabled={
                  !selectedActivity || !selectedActivity.value || isLoading
                }
              />
            </SelectWrapper>
          </InputWrapper>
        </FormContainer>
      </Row>
      <Row justifyContent={'space-between'}>
        <SearchInput
          type="text"
          search={true}
          defaultValue={searchText}
          placeholder={"Buscar"}
          width={'91%'}
          disabled={isLoading}
          onChange={onSearchChangeHandler}
          onKeyDown={onSearchKeyDownHandler}
        />
        <InputWrapper width={'80px'} justifyContent={'flex-end'}>
          <Button
            loading={isLoading}
            onClick={() => props.fetchExportBookings(
              selectedNeighbourhood.value,
              selectedActivity ? selectedActivity.value : null,
              selectedSpace ? selectedSpace.value : null,
              startDate.toISOString(),
              searchText,
              orderField,
              orderDirection
            )}>
            Exportar
          </Button>
        </InputWrapper>
      </Row>
      <TableWrapper>
        <RitzTable
          columns={columns}
          paging={true}
          title=""
          isLoading={isLoading}
          options={{
            toolbar: false,
            paging: true,
            serverSide: true,
            selection: false,
            search: false,
            exportAllData: false,
            exportButton: false,
          }}
          onOrderChange={onOrderChangeHandler}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
          pagination={{
            totalRow: totalBookings,
            page: page,
            pageSize: pageSize,
            onChangePage: onChangePageHandler
          }}
          data={bookingsData}
        />
      </TableWrapper>
      {showExportErrorModal
        ? renderModalMessageFail(
          "Error: No se encontraron reservas.",
          toggleShowExportErrorModal
        )
        : null}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingsList);
