import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import compose from "recompose/compose";
import RitzTable from "../../../components/RitzTable";
import SectionHeader from "../../../components/SectionHeader";
import Button from "../../../components/Button";
import ModalOrderDetail from "./ModalOrderDetail";
import {
  setPageOrderList,
  setYearOrderList,
  fetchOrderList
} from "../../../store/actions/ordenes.actions";


import mapExpensasHome from "../../../selectors/mapExpensasHome";
import mapNeighbourhoods from "../../../selectors/mapNeighbourhoods";

import { formatCurrencyNumber } from "../../../utils/utility";

import {
  fetchHomeExpenses,
  fetchExpensesCliente,
  fetchgetFileDetailClient
} from "../../../store/actions/expenses.actions";

import Select from "react-select";

import {
  PageContainer,
  SelectWrapper,
  StatusBadgeWithoutBorder,
  NumberPagesContainer,
  TableContainer,
  NoDataMessage,
  ExtremeButton,
} from "./styled";
import NoDataIcon from '../../../assets/icons/noDataIcon.jpg'
import DayJS from "react-dayjs";
import ReactPaginate from "react-paginate";


let today = new Date();
let anio = today.getFullYear();

var anios = [];

for (var i = 4; i >= 0; i--) {
  anios.push({ label: anio - i, value: anio - i });
}

const columnsordenes = [
  {
    title: "Solicitud",
    field: "creation_date",
    render: rowData =>
      rowData.creation_date ? (
        <DayJS format="DD-MM-YYYY">{rowData.creation_date}</DayJS>
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      ),
    headerStyle: {
      borderRadius: "10px 0px 0px 0px "
    }
  },

  {
    title: "Vencimiento",
    field: "period_date",
    render: rowData =>
      rowData.period_date ? (
        <DayJS format="DD-MM-YYYY">{rowData.period_date}</DayJS>
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      ),
    headerStyle: {
      alignText: "center"
    }
  },

  {
    title: "Usuario",
    field: "creation_user",
    render: rowData =>
      rowData.creation_user ? (
        <div>{rowData.creation_user}</div>
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      )
  },

  {
    title: "Convenio",
    field: "bank_agreement",
    render: rowData =>
      rowData.bank_agreement ? (
        <div>{rowData.bank_agreement}</div>
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      ),
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: 40 + "px"
    }
  },

  {
    title: "Débitos",
    field: "total_debits_count",
    render: rowData =>
      rowData.total_debits_count ? (
        <div>{rowData.total_debits_count}</div>
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      ),
    headerStyle: {
      alignText: "center",
      justifyContent: "center",
      paddingLeft: 15 + "px"
    }
  },
  {
    title: "Monto",
    field: "total_debits_amount",
    cellStyle: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end"
    },
    render: rowData =>
      rowData.total_debits_amount ? (
        formatCurrencyNumber("$", rowData.total_debits_amount)
      ) : (
        <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>
      ),

  },

  {
    title: "Estado",
    field: "state_name",
    render: rowData => {
      switch (rowData.state_name) {
        case "Enviado":
          return <StatusBadgeWithoutBorder color={"green"}>Aprobado</StatusBadgeWithoutBorder>;
        case "Pendiente":
          return <StatusBadgeWithoutBorder color={"#daa520"}>Pendiente</StatusBadgeWithoutBorder>;
        case "Error":
          return <StatusBadgeWithoutBorder color={"#B22222"}>Rechazado</StatusBadgeWithoutBorder>;
        default:
          return <StatusBadgeWithoutBorder color={"#B22222"}>No obtenido</StatusBadgeWithoutBorder>;
      }
    },
    headerStyle: {
      borderRadius: "0px 10px 0px 0px ",
      height: 75 + "px",
      display: "flex",
      alignText: "center",
      justifyContent: "flex-end",
      paddingLeft: 15 + "px"
    }
  }
];

let initialMount = true;

class Ordenes extends React.Component {
  state = {
    lote: "",
    neighbourhoodSelected: {
      value: null,
      label: null,
      guid: null
    },
    monthSelected: {
      value: null,
      label: null
    },
    yearSelected: {
      // value: 2021,
      // label: "2021"
      value: this.props.year,
      label: this.props.year.toString()
    },
    isModalOrderDetail: false,
    currentRowDatail: {},

  };

  componentDidMount = () => {
    if (this.props.ordersList.length == 0 && initialMount) {
      this.fetchMoreContent(0);
      initialMount = false;
    }
    // if (this.props.ordersList.length == 0){
    // this.fetchMoreContent(0);
    // }
  };
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.ordersList.length === 0 && prevProps.ordersListlength !== 0) {
  //     this.fetchMoreContent(0);
  //   }

  // }
  // componentDidUpdate(prevProps){
  //   if((prevProps.page !== this.props.page ||
  //      prevProps.year !== this.props.year ||
  //       prevProps.ordersList !== this.props.ordersList
  //       ) 
  //       && this.props.ordersList.length !== 0 ){
  //     this.props.fetchOrderList(false);
  //   }
  // }
  searchOrders = year => {
    if (!this.props.loadingfetchOrders) {
      this.props.setYearOrderList(year);
      this.props.setPageOrderList(0);
      this.props.fetchOrderList(false, this.props.username);
    }
    // this.setState({yearSelected: year});
  };
  onOrderClick = rowData => {
    this.setState({ currentRowDatail: rowData });
    this.setState({ isModalOrderDetail: !this.state.isModalOrderDetail });
  };
  onCloseOrderClick = () => {
    this.setState({ isModalOrderDetail: !this.state.isModalOrderDetail });
  };
  toggleState = (state, resident) => {
    this.setState({ [state]: !this.state[state] });
    if (state === "memberDetails") {
      this.setState({ selectedResident: resident });
    } else {
      this.setState({ selectedResident: resident });
    }
  };

  handleChangeYear = value => {
    this.setState({ yearSelected: value });
  };

  fetchMoreContent = page => {
    if (!this.props.loadingfetchOrders) {
      this.props.setPageOrderList(page);
      this.props.fetchOrderList(false, this.props.username);
    }
  };

  handlePageClick = data => {
    this.fetchMoreContent((data.selected != undefined ? data.selected : data));
  };

  render() {
    // if (this.props.ordersList.length == 0) {
    //   return  <NoDataMessage>
    //             <img width={60+"px"} src={NoDataIcon}/>
    //             <div>No hay datos</div>
    //           </NoDataMessage>;
    // } else {
    return (
      <PageContainer>
        <SectionHeader justifyContent={"flex-end"}>
          <SelectWrapper>
            <Select
              value={this.state.yearSelected}
              onChange={this.handleChangeYear}
              options={anios}
              placeholder="Seleccione Año para filtrar"
            />
          </SelectWrapper>
          <Button
            borderColor="darkgreen"
            variant="inherit"
            medium
            type={"submit"}
            onClick={() => this.searchOrders(this.state.yearSelected.value)}
          >
            Buscar
          </Button>
        </SectionHeader>
        {/* <TableContainer> */}
        {this.props.ordersList.length == 0 ?
          <NoDataMessage>
            <img width={60 + "px"} src={NoDataIcon} />
            <div>No hay datos</div>
          </NoDataMessage>
          :
          <RitzTable
            isLoading={this.props.loadingOrdersList}
            columns={columnsordenes}
            onRowClick={(event, rowData, togglePanel) => this.onOrderClick(rowData)}
            onSelectionChange={rows => this.onOrderClick(rows)}
            data={this.props.ordersList}
            options={{
              headerStyle: {
                width: 100 + "%",
                backgroundColor: "#7dbe55",
                color: "#FFFFFF",
                zIndex: "0"
              },
              whiteSpace: "nowrap",
              rowStyle: rowData => {
                if (rowData.tableData.id % 2 !== 0) {
                  return { backgroundColor: "rgba(125,189,86,0.2)" };
                }
              }
            }}
          />}
        {this.props.ordersList.length == 0 ? null : <NumberPagesContainer>
          <ExtremeButton onClick={() => this.handlePageClick(0)} children={"«"} />
          <ReactPaginate
            previousLabel={'‹'}
            nextLabel={'›'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(this.props.totalOrders / 10)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={2}
            // disableInitialCallback={ false }
            // initialPage={this.props.page}
            onPageChange={this.handlePageClick}
            forcePage={this.props.page}
            containerClassName={'pagination2'}
            activeClassName={'active'}
            pageLabelBuilder={(page) => { return <div style={{ display: "flex", flexDirection: "column" }}><span style={{}} >{page}</span><span style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: 30 + "px", marginTop: -22 + "px", height: 10 }}>.</span></div> }}
          />
          <ExtremeButton onClick={() => this.handlePageClick(Math.ceil(this.props.totalOrders / 10) - 1)} children={"»"} />
        </NumberPagesContainer>}
        {/* </TableContainer> */}
        {this.state.isModalOrderDetail && (
          <ModalOrderDetail
            currentRowDatail={this.state.currentRowDatail}
            onBtnClose={this.onCloseOrderClick}
            categoryList={this.props.ordersList}
            title={`${this.state.currentRowDatail.id}`}
          />
        )}
      </PageContainer>
    );
  }
  //}
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, username, menusAuth },
  userInfo,
  expenses
}) => {
  return {
    expensesCabecera: mapExpensasHome(expenses),
    neighbourhoods: mapNeighbourhoods(neighbourhoods),
    loadingfetchHomeExpenses: expenses.loadingfetchHomeExpenses,
    //expensasDetails: mapExpensesHomeDetail(expenses),
    selectedNeighbourhoodRdx: userInfo.selectedNeighbourhood,
    fetchExpensesClienteSuccess: expenses.fetchExpensesClienteSuccess,
    fetchExpensesClienteFail: expenses.fetchExpensesClienteFail,
    loadingfetchExpensesCliente: expenses.loadingfetchExpensesCliente,
    ExpensesCliente: expenses.ExpensesCliente,
    fileDetailClient: expenses.fileDetailClient,
    ordersList: expenses.OrdersList,
    loadingfetchOrders: expenses.loadingOrdersList,
    totalOrders: expenses.totalOrders,
    page: expenses.page,
    username,
    year: expenses.year
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHomeExpenses,
      fetchExpensesCliente,
      fetchgetFileDetailClient,
      setPageOrderList,
      setYearOrderList,
      fetchOrderList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(compose()(Ordenes));
