import { createSelector } from 'reselect'

const mapAlerts = createSelector(
    receivedAlerts => receivedAlerts,
    receivedAlerts =>
    receivedAlerts.map(( {estado}, i ) => ({
      alert_state: estado
    }))
)

export default mapAlerts
