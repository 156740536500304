import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom';
import { fetchForgotPasswordConfirmation } from '../../../../store/actions/auth.actions'
import LoginInput from '../../../../components/LoginInput';
import { Container, StyledImg, Wrapper, Title, Subtitle, ButtonStyled, ErrorLabel, ButtonBack, WrapperContent } from '../styled'
import Button from '../../../../components/Button';
import { Formik } from 'formik'
import * as Yup from 'yup'


class SecondStep extends Component {
  state = {
    step: 1,
  }
  getInitialValues = () => ({
    inputUserName: '',
  })


  getSignUpSchemaSecond = () =>
    Yup.object().shape({
      code: Yup.string().required('Codigo requerido'),
    })

  handleFormSubmitSecond = async data => {
    console.log("pegarle api con codigo", data)
    var body = {
      username: this.props.Username,
      new_password: data.password,
      confirmation_code: data.code
    }
    this.props.fetchForgotPasswordConfirmation(body)

  };


  getFormContentSecond = ({ handleSubmit, values, setFieldValue, errors, setSubmitting, OnClickBack }) => (
    <Container onSubmit={handleSubmit}>
      <Wrapper>
        <Title variant="h5" component="h2" gutterBottom>
          Ingrese el codigo que le llegó
        </Title>
        <br />
      </Wrapper>
      <WrapperContent>
        <LoginInput
          value={values.code}
          error={errors.code}
          onChange={({ target: value }) => setFieldValue('code', value.value.trim().replace(/\s+/g, ''))}
          component="p"
          gutterBottom
          placeholder="Codigo de verifiación"
          fullWidth
        />
        <LoginInput
          value={values.password}
          error={errors.password}
          onChange={({ target: value }) => setFieldValue('password', value.value)}
          component="pass"
          gutterBottom
          placeholder="Nueva contraseña"
          fullWidth
        />
        <Button type="submit" value="submit"
          loading={this.props.loading}>
          Enviar Codigo
        </Button>
        <ButtonBack variant={"primary"} type="" onClick={this.props.OnClickBack()}>Volver al Ingreso</ButtonBack>
        {errors.code && <ErrorLabel>{errors.code}</ErrorLabel>}
        {this.props.errorForgotPasswordConfirmation && <ErrorLabel>{this.props.errorForgotPasswordConfirmationMessage}</ErrorLabel>}
        {this.props.forgotPassword ? <ErrorLabel>{this.props.forgotPassword.message}</ErrorLabel> : null}
      </WrapperContent>
    </Container>
  )
  render() {
    console.log(this.props)
    this.props.errorForgotPassword ? this.props.NextStep(1) : console.log("")
    if (this.props.forgotPasswordConfirmation === true) {
      return (<Redirect to={'/'} />)
    }
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validationSchema={this.getSignUpSchemaSecond()}
        onSubmit={this.handleFormSubmitSecond}
        render={e => this.getFormContentSecond(e)}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    forgotPassword: state.auth.forgotPassword,
    loading: state.auth.loadingForgotPasswordConfirmation,
    errorForgotPasswordConfirmationMessage: state.auth.errorForgotPasswordConfirmationMessage,
    errorForgotPasswordConfirmation: state.auth.errorForgotPasswordConfirmation,
    forgotPasswordConfirmation: state.auth.forgotPasswordConfirmation,
    errorForgotPassword: state.auth.errorForgotPassword,
    auth: state.auth,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForgotPasswordConfirmation,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondStep)
